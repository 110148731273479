import React, { useEffect, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormHelperText, Grid, LinearProgress, makeStyles, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useMediaQuery, useTheme
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { IMappingTemplate } from '../../../models/Placements/IMappingTemplate';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useDelete, useFetch, usePost } from '../../../utils/apiHelper';
import * as qs from 'query-string';
import * as _ from 'lodash';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { IPlacementMapping } from '../../../models/Placements/IPlacementMapping';
import { Autocomplete } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SaveTemplate from '../SaveTemplate';
import CloseIcon from '@material-ui/icons/Close';
import CurrencyInput from 'react-currency-input-field';
import { IClientList } from '../../../models/Admin/ClientPlacements/IClientList';
import { IClientCodeMapping } from '../../../models/Placements/IClientCodeMapping';
import moment from 'moment';
import { DialogTitleHeader } from '../../../components/GlobalStyles/DialogStyle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from "../../../components/GlobalStyles/LightTooltip";
import PuffLoader from "react-spinners/PuffLoader";
import Backdrop from '@material-ui/core/Backdrop';
import * as constant from '../../../constants/Constant';
import Footer from '../../../components/Footer/Footer';

const useStyles = makeStyles((theme) => ({
  tableLayout: {
    tableLayout: 'auto',
    marginBottom: "10px"
  },
  container: {
    maxWidth: "99vw",
    maxHeight: '86vh',
    minHeight: 680,
    marginTop: '0.5cm',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    // display: 'fixed',
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  actions: {
    width: "100%",
    marginRight: theme.spacing(2)
  },
  noPadding: {
    padding: '0px 0px 0px 0px'
  },
  buttons: {
    borderRadius: 20,
    fontSize: 15,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "7px",
    marginLeft: '1cm'
  },
  Nextbtn: {
    borderRadius: 20,
    fontSize: 15,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "7px",
    marginLeft: '1.5cm'
  },
  inputField: {
    width: '95%',
    height: '40px',
    fontSize: 17,
    borderRadius: '4px',
    transition: '0.3s',
  },
  inputField1: {
    height: '40px',
    fontSize: 17,
    borderRadius: '4px',
    transition: '0.3s',
    width: "80px",
    marginTop: "10px"
  },
  error: {
    color: 'red',
  },
  div: {
    flexGrow: 1,
    marginTop: "-30px"
  },
  grid: {
    padding: '15px'
  },
  smallFont: {
    fontSize: '17px'
  },
  ignoredRow: {
    textDecoration: 'line-through',
    color: 'red'
  },
  ignoredRowPassive: {
    textDecoration: 'line-through'
  },
  excelCell: {
    fontSize: 15
  },
  solidBorder: {
    border: '2px outset grey',
    borderRadius: 5,
    padding: 1
  },
  divider: {
    marginTop: '5px',
    marginBottom: '5px'
  },
  container1: {
    maxHeight: 490,
    marginBottom: '0.4cm',
    marginTop: '0.3cm'
  },
  container2: {
    maxHeight: 410,
    marginBottom: '0.4cm',
    marginTop: '0.3cm'
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  deletetemp: {
    marginTop: "15px",
    color: "black",
    textAlign: "center"
  },
  yesbutton: {
    backgroundColor: 'green',
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  title: {
    alignContent: "center",
    color: "blue",
    marginTop: '0.3cm'
  },
  selectfield: {
    width: '320px',
    fontSize: 13,
    height: '1.5cm',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  selectdropdown: {
    border: '3px solid #364F6B',
    backgroundColor: 'white',
    marginTop: '-3px',
    borderRadius: 10,
  },
  selectdropdown1: {
    border: '1px solid gray',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: 5,
    width: 280
  },
  infoicon: {
    fontSize: 25,
    color: 'gray',
    marginTop: '20px',
    marginLeft: '-20px'
  },
  ignoretop: {
    marginTop: "8px",
    marginLeft: '15px'
  },
  tablehead: {
    position: 'sticky',
    top: 0,
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
  },
  tableLayout1: {
    marginBottom: '1cm'
  },
  buttonbox: {
    float: 'right'
  },
  tableBody: {
    border: '3px solid #364F6B',
  },
  tableBody1: {
    border: '3px solid #364F6B',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  scrollbar: {
    overflowY: 'scroll',
    maxHeight: 67
  },
  footer: {
    marginTop: '-3px'
  },
  button: {
    borderRadius: 20,
  }
}))

function CSVFieldnames(props) {
  const theme = useTheme();
  let history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  let queryParams = qs.parse(location.search);

  const [placementValidationList, setPlacementValidationList] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountList ? props.location?.state?.placementAccountList : []);
  const [clientCodeMappingList, setClientCodeMappingList] = useState<IClientCodeMapping[]>(props.location?.state?.clientCodeMappingList ? props.location?.state?.clientCodeMappingList : []);
  const [placementAccountCSVData, setPlacementAccountCSVData] = useState<string[][]>(props.location?.state?.placementAccountCSVData ? props.location?.state?.placementAccountCSVData : []);
  const [placementAccountList, setPlacementAccountList] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountList ? props.location?.state?.placementAccountList : []);
  const [placementAccountCSV, setPlacementAccountCSV] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountCSV ? props.location?.state?.placementAccountCSV : []);
  const [selectedHeaderList, setSelectedHeaderList] = useState<string[]>(props.location?.state?.selectedHeaderList ? props.location?.state?.selectedHeaderList : []);
  const [filteredTemplateList, setFilteredTemplateList] = useState<ITemplateField[]>([]);
  const [mappedRequireFields, setMappedRequireFields] = useState<ITemplateField[]>([]);
  const [selectedClientList, setSelectedClientList] = useState<IClientList[]>([]);
  const [requireFields, setRequireFields] = useState<ITemplateField[]>([]);
  const [mappingList, setMappingList] = useState<IMappingTemplate[]>([]);
  const [templateList, setTemplateList] = useState<ITemplateField[]>([]);



  const [isPreview, setIsPreview] = useState(props.location?.state?.isPreview ? props.location?.state?.isPreview : false);
  const [mBalanceChecked, setMBalanceChecked] = useState(isPreview === false ? false : props.location?.state?.mBalanceChecked);
  const [ignoreRowNumber, setIgnoreRowNumber] = useState<any>(isPreview === false ? 1 : props.location?.state?.ignoreRows);
  const [mBalanceValue, setMBalanceValue] = useState<any>(props.location?.state?.mBalanceValue);
  const [feeChecked, setFeeChecked] = useState(isPreview === false ? false : props.location?.state?.feeChecked);
  const [ignoredRows, setIgnoredRows] = useState<any>(props.location?.state?.ignoreRows);
  const [mBalance, setMBalance] = useState<any>(props.location?.state?.mBalanceValue);
  const [feeValue, setFeeValue] = useState<any>(props.location?.state?.feeValue);
  let clientList: IClientList[] = props.location.state.clientList;
  let uploadedFile: any = props.location.state.uploadedFile;
  let phaseNumber: number = props.location.state.phaseNumber;
  let clientCode: string = props.location.state.clientCode;

  var formats = ['DD-MM-YYYY', 'MM-DD-YYYY', 'MM/DD/YYYY', 'DD/MM/YYYY', 'M/DD/YYYY', 'D/MM/YYYY', 'D/M/YYYY', 'dd/mm/yyyy'];
  const [checkCont, setCheckCont] = useState<boolean>(isPreview === false ? false : props.location?.state?.checkCont);
  const [check1STP, setCheck1STP] = useState<boolean>(isPreview === false ? false : props.location?.state?.check1STP);
  const [checkPrec, setCheckPrec] = useState<boolean>(isPreview === false ? false : props.location?.state?.checkPrec);
  const FeeObject = { field_name: 'fees', display_name: 'Fees (*)', data_type: 'number', is_nullable: false };
  const [secondaryStateValidation, setSecondaryStateValidation] = useState<boolean>(true);
  const [totalBalanceValidation, setTotalBalanceValidation] = useState<boolean>(true);
  const [secondaryZipValidation, setSecondaryZipValidation] = useState<boolean>(true);
  const [phoneNoValidation, setPhoneNoValidation] = useState<boolean>(true);
  const [stateValidation, setStateValidation] = useState<boolean>(true);
  const [dateValidation, setDateValidation] = useState<boolean>(true);
  const [isAutoMapping, setIsAutoMapping] = useState<boolean>(false);
  const [clientCodeVal, setClientCodeVal] = useState<boolean>(false);
  const [zipValidation, setZipValidation] = useState<boolean>(true);
  const [isRemapped, setIsRemapped] = useState<boolean>(false);
  const [ignoreRows, setIgnoreRows] = useState<boolean>(true);
  const [phaseVal, setPhaseVal] = useState<boolean>(false);
  const [savingEnable, setSavingEnable] = useState(false);
  const [feeSelected, setFeeSelected] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [Preview, setPreview] = useState(false);
  const [enable, setEnable] = useState(false);
  const [valid, setValid] = useState(false);

  const [mappedClientCodeValue, setMappedClientCodeValue] = useState<string>(isPreview === false ? "" : props.location?.state?.mappedClientCodeValue);
  const [mappingValue1STP, setMappingValue1STP] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValue1STP);
  const [mappingValuePrec, setMappingValuePrec] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValuePrec);
  const [mappingValueCont, setMappingValueCont] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValueCont);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [clientCodeMsg, setClientCodeMsg] = useState<string>("");

  const [phaseMsg, setPhaseMsg] = useState<string>("");
  const [mapped, setMapped] = useState<number>();
  const { state, dispatch } = useGlobalState();
  const UserName = state.userAccessContext?.id;

  const handleSubmit = (e) => {
    setIsSubmit(true);
    if (savingEnable === true) {
      let filteredHeaderList = _.cloneDeep(selectedHeaderList);
      let filteredCSVData = _.cloneDeep(placementAccountCSVData);
      let filteredPlacementAccountList = _.cloneDeep(placementAccountList);
      let filteredPlacementAccountListCSV = _.cloneDeep(placementAccountList);

      // Remove unmapped columns from csv data
      filteredCSVData.forEach((row, rowIndex) => {
        filteredCSVData[rowIndex] = filteredCSVData[rowIndex].filter((x, index) => filteredHeaderList[index] != undefined);
      });
      filteredHeaderList = filteredHeaderList.filter((x) => x != undefined);

      // Remove ignored rows from csv data
      if (ignoreRowNumber > 0) {
        filteredCSVData.splice(0, ignoreRowNumber);
        filteredPlacementAccountList.splice(0, ignoreRowNumber);
      }

      // Remove Invalid Phase Rows
      if (filteredHeaderList.findIndex(x => x == "phase") > -1) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) => isPhaseValid(filteredPlacementAccountList[rowIndex].phase));
        filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => isPhaseValid(filteredPlacementAccountList[rowIndex].phase));
      }

      // Remove Invalid client code Rows
      if (filteredHeaderList.findIndex(x => x == "client_code") > -1) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) => isClientCodeValid(filteredPlacementAccountList[rowIndex].client_code));
        filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => isClientCodeValid(filteredPlacementAccountList[rowIndex].client_code));
      }

      // Remove Invalid phone number Rows
      if (filteredHeaderList.findIndex(x => x == "phone_no") > -1) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) => isPhoneValid(filteredPlacementAccountList[rowIndex].phone_no));
        filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => isPhoneValid(filteredPlacementAccountList[rowIndex].phone_no));
      }

      // Remove Invalid date Rows
      if (filteredHeaderList.findIndex(x => x == "delinquency_date") > -1) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) => isDateValid(filteredPlacementAccountList[rowIndex].delinquency_date));
        filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => isDateValid(filteredPlacementAccountList[rowIndex].delinquency_date));
      }

      // Remove Invalid total balance Rows
      if (filteredHeaderList.findIndex(x => x == "total_balance") > -1) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) => isTotalBalanceValid(filteredPlacementAccountList[rowIndex].total_balance));
        filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => isTotalBalanceValid(filteredPlacementAccountList[rowIndex].total_balance));
      }

      // Remove Invalid zip Rows
      if (filteredHeaderList.findIndex(x => x == "zip") > -1) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) => isZipCodeValid(filteredPlacementAccountList[rowIndex].zip));
        filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) => isZipCodeValid(filteredPlacementAccountList[rowIndex].zip));
      }

      //Remove Invalid Balance Rows
      if (mBalanceValue && +mBalanceValue > 0) {
        filteredCSVData = filteredCSVData.filter((item, rowIndex) =>
          filteredPlacementAccountList[rowIndex]?.total_balance >= parseFloat(mBalanceValue));

        filteredPlacementAccountList = filteredPlacementAccountList.filter((item, rowIndex) =>
          filteredPlacementAccountList[rowIndex].total_balance >= parseFloat(mBalanceValue));
      }

      // Remove ignored columns from csv data
      filteredCSVData.forEach((row, rowIndex) => {
        filteredCSVData[rowIndex] = filteredCSVData[rowIndex]?.filter((x, index) => filteredHeaderList[index] != "-1");
      });
      filteredHeaderList = filteredHeaderList.filter((x) => x != "-1");

      //Add FeeValue
      if (feeValue && +feeValue > 0) {
        if (filteredHeaderList.findIndex(x => x == "fees") == -1) {
          filteredHeaderList.push("fees");
        }
        if (!filteredHeaderList.findIndex(x => x !== "fees")) {
          templateList.push(FeeObject);
        }
        filteredPlacementAccountList.forEach(x => {
          x.fees = feeValue;
        });
        filteredCSVData.forEach(x => {
          x?.push(feeValue);
        });
      }

      history.push('\csvpreviewdata', {
        clientCode: clientCode, phaseNumber: phaseNumber, placementAccountList: filteredPlacementAccountList, templateList: templateList,
        placementAccountCSVData: placementAccountCSVData, selectedHeaderList: selectedHeaderList, ignoreRows: ignoreRowNumber, placementAccountCSV: (isPreview === false ? filteredPlacementAccountListCSV : placementAccountCSV),
        filteredCSVData: filteredCSVData, filteredHeaderList: filteredHeaderList, clientList: clientList, mBalanceValue: (mBalanceValue), mappingValueCont: mappingValueCont,
        mBalanceChecked: (mBalanceChecked), feeChecked: feeChecked, feeValue: feeValue, mappingValuePrec: mappingValuePrec, mappingValue1STP: mappingValue1STP, uploadedFile: uploadedFile,
        checkCont: checkCont, check1STP: check1STP, checkPrec: checkPrec, clientCodeMappingList: clientCodeMappingList, mappedClientCodeValue: mappedClientCodeValue
      });
    }
    else {
      alert("Please provide all the necessary data.");
    }
  };

  function handleIgnoreRowsCheck(event) {
    setIgnoreRows(event.target.checked);
    setIgnoreRowNumber(0);
  }

  function handleIgnoreRowNumberChange(value: string) {
    setIgnoreRowNumber(value === '' ? '' : value || '');
  }

  useEffect(() => {
    if (!requireFields.length && phaseVal == false && clientCodeVal == false) {
      setSavingEnable(true);
    }
    else {
      setSavingEnable(false);
    }
  }, [selectedHeaderList, requireFields, isSubmit, isAutoMapping, clientCodeVal, phaseVal])

  useEffect(() => {
    if (((stateValidation === true) && (zipValidation === true) && (phoneNoValidation === true) &&
      (dateValidation === true) && (secondaryZipValidation == true) && (totalBalanceValidation === true) &&
      (secondaryStateValidation == true))) {
      setPreview(false);
      setIsSubmit(true);
    } else {
      setPreview(true);
      setIsSubmit(false);
    }
  }, [selectedHeaderList, isSubmit, isAutoMapping, stateValidation, zipValidation, phoneNoValidation, clientCodeVal,
    phaseVal, dateValidation, secondaryZipValidation, totalBalanceValidation, secondaryStateValidation])

  useEffect(() => {
    if (!selectedHeaderList.includes("phase") && +phaseNumber == 4) {
      setPhaseMsg("Phase(*)");
      setPhaseVal(true);
    } else if (selectedHeaderList.includes("phase") && phaseNumber == 4
      && !mappingValue1STP.length && !mappingValuePrec.length && !mappingValueCont.length) {
      setPhaseMsg("Phase(*)");
      setPhaseVal(true);
    } else if (selectedHeaderList.includes("phase") && phaseNumber == 4
      && mappingValue1STP.length && !mappingValuePrec.length && !mappingValueCont.length) {
      setPhaseVal(false);
    } else if (selectedHeaderList.includes("phase") && phaseNumber == 4
      && !mappingValue1STP.length && mappingValuePrec.length && !mappingValueCont.length) {
      setPhaseVal(false);
    } else if (selectedHeaderList.includes("phase") && phaseNumber == 4
      && !mappingValue1STP.length && !mappingValuePrec.length && mappingValueCont.length) {
      setPhaseVal(false);
    } else if (!selectedHeaderList.includes("phase") && +phaseNumber !== 4) {
      setPhaseVal(false);
    }
  }, [isSubmit, isAutoMapping, selectedHeaderList, mappingValue1STP, mappingValuePrec,
    mappingValueCont, check1STP, checkCont, checkPrec])

  useEffect(() => {
    if (selectedHeaderList.includes("fees")) {
      setFeeSelected(true);
      setFeeChecked(false);
      handleFeeValueChange('');
    }
    else {
      setFeeSelected(false);
    }
  }, [selectedHeaderList])

  useEffect(() => {
    if (!selectedHeaderList.includes("client_code") && clientCode == "-2") {
      setClientCodeMsg("Client Code(*),");
      setClientCodeVal(true);
    } else if (selectedHeaderList.includes("client_code") && clientCode == "-2"
      && !mappedClientCodeValue.length) {
      setClientCodeMsg("Client Code(*),");
      setClientCodeVal(true);
    } else if (selectedHeaderList.includes("client_code") && clientCode == "-2"
      && mappedClientCodeValue.length) {
      setClientCodeVal(false);
    } else if (!selectedHeaderList.includes("client_code") && clientCode !== "-2") {
      setClientCodeVal(false);
    }
  }, [isSubmit, isAutoMapping, selectedHeaderList, mappedClientCodeValue])

  useEffect(() => {
    placementValidationList.forEach(x => {
      if (selectedHeaderList.includes("phone_no")) {
        var phoneVal = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/;
        if (phoneVal.test(x?.phone_no)) {
          setPhoneNoValidation(true);
        } else {
          setPhoneNoValidation(false);
        }
      }
      else if (selectedHeaderList.includes("zip")) {
        var zipVal = /^\d{5}(-\d{4})?$/;
        if (zipVal.test(x?.zip)) {
          setZipValidation(true);
        } else {
          setZipValidation(false);
        }
      }
      else if (selectedHeaderList.includes("state")) {
        if (x?.state?.length === 2) {
          setStateValidation(true);
        } else {
          setStateValidation(false);
        }
      }
      else if (selectedHeaderList.includes("total_balance")) {
        var regex = /^(?!-0(\.0+)?$)?(0|[1-9]\d*)(\.\d{1,2})?$/;
        placementValidationList.forEach(x => {
          let balance = x.total_balance;
          if (regex.test(balance?.toString())) {
            setTotalBalanceValidation(true);
          } else {
            setTotalBalanceValidation(false);
          }
        });
      }
      else if (selectedHeaderList.includes("secondary_state")) {
        if (x?.secondary_state?.toString()?.length === 2) {
          setSecondaryStateValidation(true);
        } else {
          setSecondaryStateValidation(false);
        }
      }
      else if (selectedHeaderList.includes("secondary_zip")) {
        if (x?.secondary_zip?.match(constant.ValZipCode)) {
          setSecondaryZipValidation(true);
        } else {
          setSecondaryZipValidation(false);
        }
      }
      else if (selectedHeaderList.includes("delinquency_date") || selectedHeaderList.includes("Payments_Applied_Since_Itemization_Date")
        || selectedHeaderList.includes("Credits_Applied_Since_Itemization_Date") || selectedHeaderList.includes("Itemization_Date")
        || selectedHeaderList.includes("balance_owed_on_itemization") || selectedHeaderList.includes("fees_added_since_itemization")
        || selectedHeaderList.includes("interest_added_since_itemization")) {
        if (selectedHeaderList.includes("delinquency_date")) {
          if (moment(x?.delinquency_date, formats, true).isValid()) {
            setDateValidation(true);
          } else {
            setDateValidation(false);
          }
        } if (selectedHeaderList.includes("Payments_Applied_Since_Itemization_Date")) {
          if (moment(x?.Payments_Applied_Since_Itemization_Date, formats, true).isValid()) {
            setDateValidation(true);
          } else {
            setDateValidation(false);
          }
        } if (selectedHeaderList.includes("Credits_Applied_Since_Itemization_Date")) {
          if (moment(x?.Credits_Applied_Since_Itemization_Date, formats, true).isValid()) {
            setDateValidation(true);
          } else {
            setDateValidation(false);
          }
        } if (selectedHeaderList.includes("Itemization_Date")) {
          if (moment(x?.Itemization_Date, formats, true).isValid()) {
            setDateValidation(true);
          } else {
            setDateValidation(false);
          }
        } if (selectedHeaderList.includes("balance_owed_on_itemization")) {
          if (moment(x?.balance_owed_on_itemization, formats, true).isValid()) {
            setDateValidation(true);
          } else {
            setDateValidation(false);
          }
        } if (selectedHeaderList.includes("fees_added_since_itemization")) {
          if (moment(x?.fees_added_since_itemization, formats, true).isValid()) {
            setDateValidation(true);
          } else {
            setDateValidation(false);
          }
        } if (selectedHeaderList.includes("interest_added_since_itemization")) {
          if (moment(x?.interest_added_since_itemization, formats, true).isValid()) {
            setDateValidation(true);
          } else {
            setDateValidation(false);
          }
        }
      }
    });
  }, [selectedHeaderList, isSubmit, isAutoMapping, secondaryZipValidation])

  function handle1STPCheck(event) {
    setCheck1STP(event.target.checked);
    setMappingValue1STP("");
    set1STPValues("");
  }

  function handleMappingValue1STPChange(event) {
    let value1STP = event.target.value;
    set1STPValues(value1STP);
    setMappingValue1STP(value1STP);
  }

  function handlePrecCheck(event) {
    setCheckPrec(event.target.checked);
    setMappingValuePrec("");
    setPrecValues("");
  }

  function handleMappingValuePrecChange(event) {
    let valuePrec = event.target.value;
    setPrecValues(valuePrec);
    setMappingValuePrec(valuePrec);
  }

  function handleMappingValueContChange(event) {
    let valueCont = event.target.value;
    setContValues(valueCont);
    setMappingValueCont(valueCont);
  }

  function handleContCheck(event) {
    setCheckCont(event.target.checked);
    setMappingValueCont("");
    setContValues("");
  }

  const setPrecValues = (valuePrec: string) => {
    let phaseIndex = selectedHeaderList.findIndex((x) => x == "phase");
    if (phaseIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true ? placementAccountCSV : placementAccountList);
      currPlacementAccountList.forEach((pa, rowIndex) => {
        let phaseLiteral = placementAccountCSVData[rowIndex][phaseIndex];
        if (valuePrec == "" && phaseLiteral != constant.PREC && phaseLiteral != constant.FIRSTSTP
          && phaseLiteral != constant.CONT && pa.phase == constant.PREC) {
          pa.phase = "";
        } if (valuePrec == "" && (phaseLiteral == constant.PREC || phaseLiteral == constant.FIRSTSTP
          || phaseLiteral == constant.CONT) && pa.phase == constant.PREC) {
          pa.phase = phaseLiteral;
        } else if (valuePrec && phaseLiteral == valuePrec && pa.phase != constant.PREC) {
          pa.phase = constant.PREC;
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
  }

  const setContValues = (valueCont: string) => {
    let phaseIndex = selectedHeaderList.findIndex((x) => x == "phase");
    if (phaseIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true ? placementAccountCSV : placementAccountList);
      currPlacementAccountList.forEach((pa, rowIndex) => {
        let phaseLiteral = placementAccountCSVData[rowIndex][phaseIndex];
        if (valueCont == "" && phaseLiteral != constant.PREC && phaseLiteral != constant.FIRSTSTP
          && phaseLiteral != constant.CONT && pa.phase == constant.CONT) {
          pa.phase = "";
        }
        if (valueCont == "" && (phaseLiteral == constant.PREC || phaseLiteral == constant.FIRSTSTP
          || phaseLiteral == constant.CONT) && pa.phase == constant.CONT) {
          pa.phase = phaseLiteral;
        } else if (valueCont && phaseLiteral == valueCont && pa.phase != constant.CONT) {
          pa.phase = constant.CONT;
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
  }

  const set1STPValues = (value1STP: string) => {
    let phaseIndex = selectedHeaderList.findIndex((x) => x == "phase");
    if (phaseIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true ? placementAccountCSV : placementAccountList);
      currPlacementAccountList.forEach((pa, rowIndex) => {
        let phaseLiteral = placementAccountCSVData[rowIndex][phaseIndex];
        if (value1STP == "" && phaseLiteral != constant.PREC && phaseLiteral != constant.FIRSTSTP
          && phaseLiteral != constant.CONT && pa.phase == constant.FIRSTSTP) {
          pa.phase = "";
        }
        if (value1STP == "" && (phaseLiteral == constant.PREC || phaseLiteral == constant.FIRSTSTP
          || phaseLiteral == constant.CONT) && pa.phase == constant.FIRSTSTP) {
          pa.phase = phaseLiteral;
        } else if (value1STP && phaseLiteral == value1STP && pa.phase != constant.FIRSTSTP) {
          pa.phase = constant.FIRSTSTP;
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
  }

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const response = await useFetch<IMappingTemplate[]>(`Placements/MappingList?user_id=${UserName}`)
        response.data.unshift(getManualTemplateFields());
        setMappingList(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }, [])

  const getManualTemplateFields = () => {
    let ManualTemplateFields: IMappingTemplate = {
      balance: 0,
      client_code: 'Mapping',
      fees: 0,
      map_name: 'Manual',
      placement_map_id: -1,
      rows_to_ignore: 1,
    };
    return ManualTemplateFields;
  }

  useEffect(() => {
    (async () => {
      if (placementAccountCSVData.length > 0) {
        let headerList: string[] = [];
        let placementAccounts: IPlacementAccount[] = [];
        for (let i = 0; i < placementAccountCSVData.length; i++) {
          placementAccounts.push(getEmptyPlacementAccount());
          headerList.push("-1");
        }
        setSelectedHeaderList(isPreview === false ? headerList : selectedHeaderList);
        setPlacementAccountList(isPreview === true ? placementAccountCSV : placementAccounts);
      }
    })()
  }, [placementAccountCSVData])

  useEffect(() => {
    let mappedHeaderList = _.cloneDeep(selectedHeaderList);
    let requiredHeaderList = _.cloneDeep(mappedRequireFields);
    mappedHeaderList.forEach(header => {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== header);
    });
    if (selectedHeaderList.includes("first_name") && selectedHeaderList.includes("last_name")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "full_name");
    }
    if (feeChecked == true && feeValue?.length > 0 && requiredHeaderList.filter(x => x.field_name == "fees")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "fees");
    }
    setRequireFields(requiredHeaderList);
  }, [isSubmit, isAutoMapping, selectedHeaderList, mappedRequireFields, feeChecked, feeValue])

  useEffect(() => {
    if (templateList.length <= 0) {
      LoadTemplateList();
    }
  }, [templateList])

  async function LoadTemplateList() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const templateFieldResponse = await useFetch<ITemplateField[]>(`Placements/TemplateExclude?clientCode=${clientCode === "-2" ? null : clientCode}`);
      setRequireFields(templateFieldResponse.data.filter(x => x.is_nullable == false));
      setMappedRequireFields(templateFieldResponse.data.filter(x => x.is_nullable == false));
      if (+phaseNumber == 4) {
        const phaseObject = { data_type: 'string', display_name: 'Phase(*)', field_name: 'phase', is_nullable: false };
        templateFieldResponse.data.push(phaseObject);
        templateFieldResponse.data.forEach(x => {
          if (x.field_name == "phase") {
            x.is_required = true;
            x.is_nullable = false;
          }
        });
      }
      if (clientCode == "-2") {
        const ClientCodeObject = { data_type: 'string', display_name: 'Client Code(*)', field_name: 'client_code', is_nullable: false };
        templateFieldResponse.data.push(ClientCodeObject);
        templateFieldResponse.data.forEach(x => {
          if (x.field_name == "client_code") {
            x.is_required = true;
            x.is_nullable = false;
          }
        });
      }
      templateFieldResponse.data.unshift(getIgnoreTemplateFields());
      setTemplateList(templateFieldResponse.data);
      setFilteredTemplateList(templateFieldResponse.data);
      if (isPreview === true) {
        let clientTemplateList = templateFieldResponse.data;
        let currentTemplateList = _.cloneDeep(clientTemplateList);
        let mappedHeaderList: string[] = _.cloneDeep(selectedHeaderList);
        mappedHeaderList.forEach(header => {
          currentTemplateList = currentTemplateList.filter(x => x.field_name != header || x.field_name == "-1");
        });
        setFilteredTemplateList(currentTemplateList);
      }
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  const handleChange = (index, event, selected) => {
    event.preventDefault();
    handleHeaderChange(index, (selected?.field_name != undefined ? selected.field_name : "-1"));
  }

  const handleHeaderChange = (index: number, field_name: string) => {
    setIsRemapped(isPreview === true ? true : false);
    let headerList: string[] = _.cloneDeep(selectedHeaderList);
    headerList[index] = field_name;
    setSelectedHeaderList(headerList);
    if (field_name != "-1" && field_name != "phase" && field_name != "client_code") {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true ? placementAccountCSV : placementAccountList);
      placementAccountCSVData.map((element, rowIndex) => {
        eval('currPlacementAccountList[' + rowIndex + '].' + field_name + ' = placementAccountCSVData[' + rowIndex + '][' + index + ']');
      });
      setPlacementAccountList(currPlacementAccountList);
      let placementAccounts: IPlacementAccount[] = [];
      setPlacementValidationList(placementAccounts);
      setPlacementValidationList(currPlacementAccountList);
    } else if (field_name == "phase") {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true ? placementAccountCSV : placementAccountList);
      placementAccountCSVData.map((element, rowIndex) => {
        let phaseLiteral = placementAccountCSVData[rowIndex][index];
        currPlacementAccountList[rowIndex].phase = getPhase(phaseLiteral);
      });
      setPlacementAccountList(currPlacementAccountList);
      let placementAccounts: IPlacementAccount[] = [];
      setPlacementValidationList(placementAccounts);
      setPlacementValidationList(currPlacementAccountList);
    } else if (field_name == "client_code") {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true ? placementAccountCSV : placementAccountList);
      placementAccountCSVData.map((element, rowIndex) => {
        let clientCodeLiteral = placementAccountCSVData[rowIndex][index];
        currPlacementAccountList[rowIndex].client_code = getClientCode(clientCodeLiteral);
      });
      setPlacementAccountList(currPlacementAccountList);
      let placementAccounts: IPlacementAccount[] = [];
      setPlacementValidationList(placementAccounts);
      setPlacementValidationList(currPlacementAccountList);
    }
    let currentTemplateList = _.cloneDeep(templateList);
    headerList.forEach(header => {
      currentTemplateList = currentTemplateList.filter(x => x.field_name != header || x.field_name == "-1");
    });
    setFilteredTemplateList(currentTemplateList);
    setMappingTemplateVisibility(headerList);
  };

  function mapTemplateToHeaderList(placementMappingList: IPlacementMapping[]) {
    let headerList: string[] = _.cloneDeep(selectedHeaderList);
    let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true ? placementAccountCSV : placementAccountList);
    setSelectedHeaderList(headerList);
    placementMappingList.forEach(x => {
      headerList[x.source_ordinal_position] = x.field_name;
      if (x.field_name != "-1" && x.field_name != "phase" && x.field_name != "client_code") {
        placementAccountCSVData.map((element, rowIndex) => {
          eval('currPlacementAccountList[' + rowIndex + '].' + x.field_name + ' = placementAccountCSVData[' + rowIndex + '][' + x.source_ordinal_position + ']');
        });
        setPlacementAccountList(currPlacementAccountList);
        let placementAccounts: IPlacementAccount[] = [];
        setPlacementValidationList(placementAccounts);
        setPlacementValidationList(currPlacementAccountList);
        setIsAutoMapping(true);
      } else if (x.field_name == "phase") {
        placementAccountCSVData.map((element, rowIndex) => {
          let phaseLiteral = placementAccountCSVData[rowIndex][x.source_ordinal_position];
          currPlacementAccountList[rowIndex].phase = getPhase(phaseLiteral);
        });
        setPlacementAccountList(currPlacementAccountList);
        let placementAccounts: IPlacementAccount[] = [];
        setPlacementValidationList(placementAccounts);
        setPlacementValidationList(currPlacementAccountList);
        setIsAutoMapping(true);
      } else if (x.field_name == "client_code") {
        placementAccountCSVData.map((element, rowIndex) => {
          let clientCodeLiteral = placementAccountCSVData[rowIndex][x.source_ordinal_position];
          currPlacementAccountList[rowIndex].client_code = getClientCode(clientCodeLiteral);
        });
        let placementAccounts: IPlacementAccount[] = [];
        setPlacementValidationList(placementAccounts);
        setPlacementValidationList(currPlacementAccountList);
      }
      setPlacementAccountList(currPlacementAccountList);
      setIsAutoMapping(true);
    });
    let currentTemplateList = _.cloneDeep(templateList);
    headerList.forEach(header => {
      currentTemplateList = currentTemplateList.filter(x => x.field_name != header || x.field_name == "-1");
    });
    setFilteredTemplateList(currentTemplateList);
    setMappingTemplateVisibility(headerList);
    setIsSubmit(true);
  }

  const setMappingTemplateVisibility = (headerList: string[]) => {
    if (headerList.findIndex(x => x && x != "-1") > -1) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }

  function getPhase(phaseLiteral: string): string {
    if (phaseLiteral && mappingValue1STP && phaseLiteral == mappingValue1STP) {
      return constant.FIRSTSTP;
    }
    if (phaseLiteral && mappingValueCont && phaseLiteral == mappingValueCont) {
      return constant.CONT;
    }
    if (phaseLiteral && mappingValuePrec && phaseLiteral == mappingValuePrec) {
      return constant.PREC;
    }
    if (phaseLiteral == constant.FIRSTSTP || phaseLiteral == constant.CONT || phaseLiteral == constant.PREC) {
      return phaseLiteral;
    }
    return "";
  }

  function getClientCode(clientCodeLiteral: string): string {
    if (clientCodeMappingList && clientCodeMappingList.findIndex(x => x.client_code_literal == clientCodeLiteral) > -1) {
      return clientCodeMappingList.find(x => x.client_code_literal == clientCodeLiteral).client_code;
    } else if (clientList && clientList.findIndex(x => x.clT_CODE == clientCodeLiteral) > -1) {
      return clientList.find(x => x.clT_CODE == clientCodeLiteral).clT_CODE;
    }
    return "";
  }

  const handleMBalanceCheck = (event) => {
    setMBalanceChecked(event.target.checked);
    handleMBalanceChange('');
  };

  const handleFeeCheck = (event) => {
    setFeeChecked(event.target.checked);
    handleFeeValueChange('');
  };

  const handleFeeValueChange = (value: string): void => {
    setFeeValue(value === '0' || '' ? '' : value || '');
  };

  const handleMBalanceChange = (value: string): void => {
    setMBalanceValue(value === '0' || '' ? '' : value || '');
  };

  const getEmptyPlacementAccount = (): IPlacementAccount => {
    let placementAccount: IPlacementAccount = {
      phaseNumber: null,
      phase: null,
      client_code: null,
      account_no: null,
      first_name: null,
      last_name: null,
      full_name: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      phone_no: null,
      total_balance: null,
      delinquency_date: null,
      secondary_address1: null,
      secondary_address2: null,
      citysecondary: null,
      secondary_state: null,
      secondary_zip: null,
      secondary_phone_no: null,
      email: null,
      drivers_license: null,
      tax_id: null,
      birth_date: null,
      past_due_balance: null,
      current_balance: null,
      equipment_charges: null,
      contract_charges: null,
      fees: null,
      last_payment_date: null,
      guarantor_first_name: null,
      guarantor_last_name: null,
      guarantor_full_name: null,
      comment: null,
      account_type: null,
      other: null,
      contact_name: null,
      service_date: null,
      location: null,
      codebtor_Full_Name: null,
      codebtor_Street_Address1: null,
      codebtor_Street_Address2: null,
      codebtor_City: null,
      codebtor_State: null,
      codebtor_Zip: null,
      codebtor_BirthDate: null,
      codebtor_Tax_ID: null,
      coDebtor_First_Name: null,
      coDebtor_Last_Name: null,
      coDebtor_Phone1: null,
      coDebtor_Phone2: null,
      Payments_Applied_Since_Itemization_Date: null,
      Credits_Applied_Since_Itemization_Date: null,
      Itemization_Date: null,
      balance_owed_on_itemization: null,
      fees_added_since_itemization: null,
      interest_added_since_itemization: null
    }
    return placementAccount
  }

  const getMappingTemplate = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await useFetch<IMappingTemplate[]>(`Placements/MappingList?user_id=${UserName}`)
        setMappingList(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const handleTemplateChange = (event, selected) => {
    setMapped(selected?.placement_map_id);
    setFeeValue(selected?.fees === null ? feeValue : selected?.fees);
    setMBalanceValue(selected?.balance === null ? mBalanceValue : selected?.balance);
    setMBalanceChecked(selected?.placement_map_id ? mBalanceChecked : false);
    setFeeChecked(selected?.placement_map_id ? feeChecked : false);
    setValid(selected?.placement_map_id ? true : false);
    if (selected?.placement_map_id != -1) {
      getPlacementMapping(selected?.placement_map_id);
    } else {
      resetAutoMappingTemplate();
    }
  };

  const resetAutoMappingTemplate = () => {
    let headerList: string[] = [];
    let placementAccounts: IPlacementAccount[] = [];
    for (let i = 0; i < placementAccountCSVData.length; i++) {
      placementAccounts.push(getEmptyPlacementAccount());
      headerList.push("-1");
    }
    setSelectedHeaderList(isPreview === false ? headerList : selectedHeaderList);
    setPlacementValidationList(placementAccounts);
  }

  const getPlacementMapping = (placement_map_id: number) => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await useFetch<IPlacementMapping[]>(`Placements/MappingGet?placement_map_id=${placement_map_id}`)
        mapTemplateToHeaderList(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  async function DeleteMappingTemplate() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const request = await useDelete(`ClientPlacement/DeleteMappingDetail?placement_map_id=${mapped}`);
      getMappingTemplate();
      setOpen(false);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  const getIgnoreTemplateFields = () => {
    let ignoreTemplateField: ITemplateField = {
      field_name: "-1",
      display_name: "--Ignore Field--",
      data_type: "varchar",
      is_nullable: true,
      is_visible: true,
      is_required: false,
      field_length: -1,
    };
    return ignoreTemplateField;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  function ignoredCellClassName(rowIndex: number, columnIndex: number): string {
    let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true && isRemapped === false ? placementAccountCSV : isRemapped === true ? placementAccountList : placementAccountList);
    if (rowIndex < ignoreRowNumber) {
      return classes.ignoredRow;
    } else if (selectedHeaderList.findIndex(x => x == "phase") > -1 && !isPhaseValid(currPlacementAccountList[rowIndex].phase)) {
      if (selectedHeaderList.findIndex(x => x == "phase") == columnIndex) {
        return classes.ignoredRow;
      } else {
        return classes.ignoredRowPassive;
      }
    } else if (selectedHeaderList.findIndex(x => x == "client_code") > -1 && !isClientCodeValid(currPlacementAccountList[rowIndex].client_code)) {
      if (selectedHeaderList.findIndex(x => x == "client_code") == columnIndex) {
        return classes.ignoredRow;
      } else {
        return classes.ignoredRowPassive;
      }
    } else if (selectedHeaderList.findIndex(x => x == "zip") > -1 || selectedHeaderList.findIndex(x => x == "secondary_zip") > -1
      || selectedHeaderList.findIndex(x => x == "codebtor_Zip") > -1) {
      if (selectedHeaderList.findIndex(x => x == "zip") > -1 && !isZipCodeValid(currPlacementAccountList[rowIndex].zip)) {
        if (selectedHeaderList.findIndex(x => x == "zip") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "secondary_zip") > -1 && !isZipCodeValid(currPlacementAccountList[rowIndex].secondary_zip)) {
        if (selectedHeaderList.findIndex(x => x == "secondary_zip") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "codebtor_Zip") > -1 && !isZipCodeValid(currPlacementAccountList[rowIndex].codebtor_Zip)) {
        if (selectedHeaderList.findIndex(x => x == "codebtor_Zip") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      }
    } else if (selectedHeaderList.findIndex(x => x == "phone_no") > -1 && !isPhoneValid(currPlacementAccountList[rowIndex].phone_no)) {
      if (selectedHeaderList.findIndex(x => x == "phone_no") == columnIndex) {
        return classes.ignoredRow;
      } else {
        return classes.ignoredRowPassive;
      }
    } else if (selectedHeaderList.findIndex(x => x == "total_balance") > -1 && !isTotalBalanceValid(currPlacementAccountList[rowIndex].total_balance)) {
      if (selectedHeaderList.findIndex(x => x == "total_balance") == columnIndex) {
        return classes.ignoredRow;
      } else {
        return classes.ignoredRowPassive;
      }
    } else if (selectedHeaderList.findIndex(x => x == "state") > -1 || selectedHeaderList.findIndex(x => x == "secondary_state") > -1
      || selectedHeaderList.findIndex(x => x == "codebtor_State") > -1) {
      if (selectedHeaderList.findIndex(x => x == "state") > -1 && !isStateCodeValid(currPlacementAccountList[rowIndex].state)) {
        if (selectedHeaderList.findIndex(x => x == "state") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "secondary_state") > -1 && !isStateCodeValid(currPlacementAccountList[rowIndex].secondary_state)) {
        if (selectedHeaderList.findIndex(x => x == "secondary_state") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "codebtor_State") > -1 && !isStateCodeValid(currPlacementAccountList[rowIndex].codebtor_State)) {
        if (selectedHeaderList.findIndex(x => x == "codebtor_State") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      }
    } else if (selectedHeaderList.findIndex(x => x == "delinquency_date") > -1 || selectedHeaderList.findIndex(x => x == "Payments_Applied_Since_Itemization_Date") > -1
      || selectedHeaderList.findIndex(x => x == "Credits_Applied_Since_Itemization_Date") > -1 || selectedHeaderList.findIndex(x => x == "Itemization_Date") > -1
      || selectedHeaderList.findIndex(x => x == "balance_owed_on_itemization") > -1 || selectedHeaderList.findIndex(x => x == "fees_added_since_itemization") > -1
      || selectedHeaderList.findIndex(x => x == "interest_added_since_itemization") > -1) {
      if (selectedHeaderList.findIndex(x => x == "delinquency_date") > -1 && !isDateValid(currPlacementAccountList[rowIndex].delinquency_date)) {
        if (selectedHeaderList.findIndex(x => x == "delinquency_date") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "Payments_Applied_Since_Itemization_Date") > -1 && !isDateValid(currPlacementAccountList[rowIndex].Payments_Applied_Since_Itemization_Date)) {
        if (selectedHeaderList.findIndex(x => x == "Payments_Applied_Since_Itemization_Date") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "Credits_Applied_Since_Itemization_Date") > -1 && !isDateValid(currPlacementAccountList[rowIndex].Credits_Applied_Since_Itemization_Date)) {
        if (selectedHeaderList.findIndex(x => x == "Credits_Applied_Since_Itemization_Date") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "Itemization_Date") > -1 && !isDateValid(currPlacementAccountList[rowIndex].Itemization_Date)) {
        if (selectedHeaderList.findIndex(x => x == "Itemization_Date") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "balance_owed_on_itemization") > -1 && !isDateValid(currPlacementAccountList[rowIndex].balance_owed_on_itemization)) {
        if (selectedHeaderList.findIndex(x => x == "balance_owed_on_itemization") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "fees_added_since_itemization") > -1 && !isDateValid(currPlacementAccountList[rowIndex].fees_added_since_itemization)) {
        if (selectedHeaderList.findIndex(x => x == "fees_added_since_itemization") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      } else if (selectedHeaderList.findIndex(x => x == "interest_added_since_itemization") > -1 && !isDateValid(currPlacementAccountList[rowIndex].interest_added_since_itemization)) {
        if (selectedHeaderList.findIndex(x => x == "interest_added_since_itemization") == columnIndex) {
          return classes.ignoredRow;
        } else {
          return classes.ignoredRowPassive;
        }
      }
    }
  }

  function isPhaseValid(phaseString: string): boolean {
    return phaseString == constant.PREC || phaseString == constant.CONT
      || phaseString == constant.FIRSTSTP;
  }

  function isTotalBalanceValid(balance: any): boolean {
    var balanceVal = /^(?!-0(\.0+)?$)?(0|[1-9]\d*)(\.\d{1,2})?$/;
    if (selectedHeaderList.includes("total_balance")) {
      return (balanceVal.test(balance?.toString()) ? true : false);
    }
  }

  function isPhoneValid(phone: string): boolean {
    var phoneVal = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/;
    if (selectedHeaderList.includes("phone_no")) {
      return (phoneVal.test(phone) ? true : false);
    }
  }

  function isClientCodeValid(clientCodeString: string): boolean {
    return clientList.findIndex(x => x.clT_CODE == clientCodeString) > -1;
  }

  function isDateValid(dateString: Date): boolean {
    return (((moment(dateString, formats, true).isValid()) ? true : false));
  }

  function isZipCodeValid(zipCode: string): boolean {
    var zipVal = /^\d{5}(-\d{4})?$/;
    if (selectedHeaderList.includes("zip")) {
      return (zipVal.test(zipCode) ? true : false);
    } else if (selectedHeaderList.includes("secondary_zip")) {
      return (zipVal.test(zipCode) ? true : false);
    } else if (selectedHeaderList.includes("codebtor_Zip")) {
      return (zipVal.test(zipCode) ? true : false);
    }
  }

  function isStateCodeValid(stateCode: string): boolean {
    if (selectedHeaderList.includes("state")) {
      return (stateCode.toString().length === 2 ? true : false);
    } else if (selectedHeaderList.includes("secondary_state")) {
      return (stateCode.toString().length === 2 ? true : false);
    } else if (selectedHeaderList.includes("codebtor_State")) {
      return (stateCode.toString().length === 2 ? true : false);
    }
  }

  function handleClientCodeSelect(event, selectedValues) {
    let currentClientCodeMappingList = _.cloneDeep(clientCodeMappingList);
    let newClientCodeMapping: IClientCodeMapping = {
      client_code: "",
      client_name: "",
      client_code_literal: ""
    };
    if (selectedValues.length == 0) {
      currentClientCodeMappingList = [];
    } else if (selectedValues.length == 1 && selectedClientList.length < 1) {
      newClientCodeMapping.client_code = selectedValues[0].clT_CODE;
      newClientCodeMapping.client_name = selectedValues[0].clT_NAME_1;
      currentClientCodeMappingList.push(newClientCodeMapping);
    } else {
      let newSelectedClient: IClientList = selectedValues.find(value => !selectedClientList.includes(value));
      let newRemovedClient: IClientList = selectedClientList.find(value => !selectedValues.includes(value));
      if (newSelectedClient) {
        newClientCodeMapping.client_code = newSelectedClient.clT_CODE;
        newClientCodeMapping.client_name = newSelectedClient.clT_NAME_1;
        currentClientCodeMappingList.push(newClientCodeMapping);
      } else if (newRemovedClient) {
        currentClientCodeMappingList = currentClientCodeMappingList.filter(x => x.client_code != newRemovedClient.clT_CODE);
      }
    }
    setClientCodeMappingList(currentClientCodeMappingList);
    setSelectedClientList(selectedValues);
  }

  function handleClientCodeLiteralChange(rowIndex, event) {
    let currentClientCodeMappingList = _.cloneDeep(clientCodeMappingList);
    currentClientCodeMappingList[rowIndex].client_code_literal = event.target.value;
    setMappedClientCodeValue(event.target.value);
    setClientCodeInPlacementAccountList(currentClientCodeMappingList);
    setClientCodeMappingList(currentClientCodeMappingList);
  }

  const setClientCodeInPlacementAccountList = (currentClientCodeMappingList: IClientCodeMapping[]) => {
    let clientCodeIndex = selectedHeaderList.findIndex((x) => x == "client_code");
    if (clientCodeIndex > -1) {
      let currPlacementAccountList: IPlacementAccount[] = _.cloneDeep(isPreview === true ? placementAccountCSV : placementAccountList);
      currPlacementAccountList.forEach((pa, rowIndex) => {
        let clientCodeLiteral = placementAccountCSVData[rowIndex][clientCodeIndex];
        if (currentClientCodeMappingList.map(x => x.client_code_literal).includes(clientCodeLiteral)) {
          pa.client_code = currentClientCodeMappingList.find(x => x.client_code_literal == clientCodeLiteral).client_code;
        } else if (clientList.findIndex(x => x.clT_CODE == clientCodeLiteral) > -1) {
          pa.client_code = clientCodeLiteral;
        } else {
          pa.client_code = "";
        }
      });
      setPlacementAccountList(currPlacementAccountList);
    }
  }

  return (
    <React.Fragment >
      <div className={classes.div} style={{ marginTop: '-10px' }}>
        <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Container fixed className={classes.container} component="div" >
          <Typography variant="h4" gutterBottom className={classes.title} >
            <b> 2. CHOOSE FIELD NAMES </b>
          </Typography>
          <div className={`${classes.solidBorder}`}>
            <div className={`${classes.actions}`}>
              <Box display="flex" p={1} bgcolor="white">
                <Box p={1} bgcolor="white" >
                  <Autocomplete
                    id="template-select"
                    options={mappingList}
                    autoHighlight={true}
                    blurOnSelect={true}
                    clearOnBlur={true}
                    onChange={handleTemplateChange}
                    getOptionLabel={(option) => option.map_name + `--` + option.client_code}
                    getOptionSelected={(option, value) => option.placement_map_id === value.placement_map_id}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.map_name + `--` + option.client_code}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Save Templates"
                        className={classes.selectdropdown1}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <IconButton aria-label="delete" disabled={!valid} onClick={() => { handleClickOpen(); }}>
                    <CloseIcon fontSize="large" style={valid ? { color: "red" } : { color: "gray" }} />
                  </IconButton>
                  <Dialog
                    PaperProps={{ style: { borderRadius: 10 } }}
                    maxWidth={'sm'}
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                      <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        DELETE SAVE TEMPLATE
                      </Typography>
                    </DialogTitleHeader>
                    <DialogContent>
                      <DialogContentText>
                        <Typography variant="h6" gutterBottom className={classes.deletetemp}>
                          Are you sure, you want to delete this mapping Template?
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                      <Button id="IM_ok_btn" variant="contained" className={classes.yesbutton} onClick={() => { DeleteMappingTemplate(); }}
                        color="primary" autoFocus>
                        Yes
                      </Button>
                      <Button id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.nobutton} onClick={handleClose} color="primary">
                        No
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <FormControlLabel style={{ marginTop: "10px" }}
                    control={<Checkbox checked={feeChecked} onChange={handleFeeCheck}
                      disabled={feeSelected} name="checkedA" />}
                    label="Fees :"
                  />
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <CurrencyInput
                    maxLength={13}
                    prefix='$'
                    disabled={!feeChecked || feeSelected}
                    value={feeValue}
                    className={classes.inputField1}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    decimalScale={2}
                    onValueChange={handleFeeValueChange}
                  />
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <FormControlLabel style={{ marginTop: "10px", marginLeft: '-10px' }}
                    control={<Checkbox checked={mBalanceChecked} onChange={handleMBalanceCheck}
                      name="checkedB" />}
                    label="Minimum Balance :"
                  />
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <CurrencyInput
                    maxLength={13}
                    prefix='$'
                    value={mBalanceValue}
                    disabled={!mBalanceChecked}
                    className={classes.inputField1}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    decimalScale={2}
                    onValueChange={handleMBalanceChange}
                  />
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <FormControlLabel
                    className={classes.ignoretop}
                    control={<Checkbox checked={ignoreRows} onChange={handleIgnoreRowsCheck} name="checkedIgnoreRows" />}
                    label="Ignore Top"
                  />
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <LightTooltip title="Ignore (if first row contain headers)">
                    <InfoOutlinedIcon className={classes.infoicon} style={{ marginTop: '15px', fontSize: 30 }} />
                  </LightTooltip>
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <CurrencyInput
                    maxLength={5}
                    value={ignoreRowNumber}
                    disabled={!ignoreRows}
                    className={classes.inputField1}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    style={{ width: "60px", textAlign: "center" }}
                    decimalsLimit={0}
                    onValueChange={handleIgnoreRowNumberChange}
                  />
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white">
                  <SaveTemplate fees={feeValue} balance={mBalanceValue}
                    clientCode={clientCode} checkEnable={enable} selectedHeaderList={selectedHeaderList} savingTempEnable={savingEnable}
                    ignoreRowNumber={ignoreRowNumber} clientList={clientList} onTemplateSave={getMappingTemplate} />
                </Box>
                <Box p={1} width='8%' flexShrink={0} bgcolor="white" >
                  <Button id="PLC_Back_btn" className={classes.buttons} size="large" startIcon={<ArrowBackIosIcon />}
                    variant="contained" color="primary" component={Link} to="/placement">
                    Back
                  </Button>
                </Box>
                <Box p={1} width='8%' flexShrink={0} bgcolor="white">
                  <Button id="PLC_Next_btn" className={classes.Nextbtn} size="large" endIcon={<ArrowForwardIosIcon />}
                    variant="contained" type='submit' disabled={Preview} onClick={handleSubmit} color="primary">
                    Next
                  </Button>
                </Box>
              </Box>
            </div>
            {clientCode == "-2" ?
              <div className={`${classes.actions} ${classes.noPadding}`}>
                <Box display="flex" p={1} bgcolor="white">
                  <Box p={1} width='35%' bgcolor="white" className={`${classes.solidBorder} ${classes.scrollbar}`} >
                    <Autocomplete
                      multiple
                      id="select-clientCode-literal"
                      options={clientList}
                      onChange={handleClientCodeSelect}
                      getOptionLabel={(option) => `${option.clT_CODE}-${option.clT_NAME_1}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Client code mapping"
                        />
                      )}
                    />
                  </Box>
                  <Box p={1} width='30%' bgcolor="white" className={`${classes.solidBorder} ${classes.scrollbar}`} >
                    {clientCodeMappingList.map((row, rowIndex) => (
                      <Box display="flex" p={1} >
                        <Box p={1} width='70%' >
                          <Typography variant="h6" gutterBottom style={{ color: "black" }}>
                            {row.client_code}-{row.client_name}
                          </Typography>
                        </Box>
                        <Box p={1} width='25%' >
                          <TextField
                            value={row.client_code_literal}
                            onChange={(event) => handleClientCodeLiteralChange(rowIndex, event)}
                            inputProps={{ style: { padding: 5 } }}
                            className={`${classes.inputField} ${classes.noPadding}`}
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {+phaseNumber == 4 ?
                    <Box width='35%' display="flex" p={1} bgcolor="white" className={`${classes.solidBorder}`} style={{ maxHeight: 67 }}>
                      <Box p={1} flexShrink={0} bgcolor="white">
                        <FormControlLabel
                          control={<Checkbox checked={checkPrec} onChange={handlePrecCheck} name="checkedIgnoreRows"
                            inputProps={{ style: { padding: 5 } }} />}
                          label="PREC"
                        />
                      </Box>
                      <Box p={1} flexShrink={0} bgcolor="white" style={{ width: "100px", marginTop: "7px" }}>
                        <TextField
                          value={mappingValuePrec}
                          disabled={!checkPrec}
                          onChange={handleMappingValuePrecChange}
                          className={`${classes.inputField} ${classes.noPadding}`}
                          inputProps={{ style: { padding: 5 } }}
                          variant="outlined"
                        />
                      </Box>
                      <Box p={1} flexShrink={0} bgcolor="white">
                        <FormControlLabel
                          control={<Checkbox checked={checkCont} onChange={handleContCheck} name="checkedIgnoreRows"
                            inputProps={{ style: { padding: 5 } }} />}
                          label="CONT"
                        />
                      </Box>
                      <Box p={1} flexShrink={0} bgcolor="white" style={{ width: "100px", marginTop: "7px" }}>
                        <TextField
                          value={mappingValueCont}
                          disabled={!checkCont}
                          onChange={handleMappingValueContChange}
                          className={`${classes.inputField} ${classes.noPadding}`}
                          inputProps={{ style: { padding: 5 } }}
                          variant="outlined"
                        />
                      </Box>
                      <Box p={1} flexShrink={0} bgcolor="white">
                        <FormControlLabel
                          control={<Checkbox checked={check1STP} onChange={handle1STPCheck} name="checkedIgnoreRows"
                            inputProps={{ style: { padding: 5 } }} />}
                          label="1STP"
                        />
                      </Box>
                      <Box p={1} flexShrink={0} bgcolor="white" style={{ width: "100px", marginTop: "7px" }}>
                        <TextField
                          value={mappingValue1STP}
                          disabled={!check1STP}
                          onChange={handleMappingValue1STPChange}
                          className={`${classes.inputField} ${classes.noPadding}`}
                          inputProps={{ style: { padding: 5 } }}
                          variant="outlined"
                        />
                      </Box>
                    </Box>
                    : null}
                </Box>
              </div>
              : null}
            {+phaseNumber == 4 && clientCode == "-2" ? null
              : clientCode == "-2" && phaseNumber != 4 ? null
                : clientCode != "-2" && phaseNumber != 4 ? null
                  : <Box width='35%' display="flex" p={2} bgcolor="white" className={`${classes.solidBorder}`} style={{ maxHeight: 67, marginLeft: '0.5cm' }}>
                    <Box p={1} flexShrink={0} bgcolor="white">
                      <FormControlLabel
                        control={<Checkbox checked={checkPrec} onChange={handlePrecCheck} name="checkedIgnoreRows"
                          inputProps={{ style: { padding: 5 } }} />}
                        label="PREC"
                      />
                    </Box>
                    <Box p={1} flexShrink={0} bgcolor="white" style={{ width: "100px", marginTop: "7px" }}>
                      <TextField
                        value={mappingValuePrec}
                        disabled={!checkPrec}
                        onChange={handleMappingValuePrecChange}
                        className={`${classes.inputField} ${classes.noPadding}`}
                        inputProps={{ style: { padding: 5 } }}
                        variant="outlined"
                      />
                    </Box>
                    <Box p={1} flexShrink={0} bgcolor="white">
                      <FormControlLabel
                        control={<Checkbox checked={checkCont} onChange={handleContCheck}
                          name="checkedIgnoreRows"
                          inputProps={{ style: { padding: 5 } }} />}
                        label="CONT"
                      />
                    </Box>
                    <Box p={1} flexShrink={0} bgcolor="white" style={{ width: "100px", marginTop: "7px" }}>
                      <TextField
                        value={mappingValueCont}
                        disabled={!checkCont}
                        onChange={handleMappingValueContChange}
                        className={`${classes.inputField} ${classes.noPadding}`}
                        inputProps={{ style: { padding: 5 } }}
                        variant="outlined"
                      />
                    </Box>
                    <Box p={1} flexShrink={0} bgcolor="white">
                      <FormControlLabel
                        control={<Checkbox checked={check1STP} onChange={handle1STPCheck} name="checkedIgnoreRows"
                          inputProps={{ style: { padding: 5 } }} />}
                        label="1STP"
                      />
                    </Box>
                    <Box p={1} flexShrink={0} bgcolor="white" style={{ width: "100px", marginTop: "7px" }}>
                      <TextField
                        value={mappingValue1STP}
                        disabled={!check1STP}
                        onChange={handleMappingValue1STPChange}
                        className={`${classes.inputField} ${classes.noPadding}`}
                        inputProps={{ style: { padding: 5 } }}
                        variant="outlined"
                      />
                    </Box>
                  </Box>
            }
          </div>
          <div style={{ marginTop: "0px", height: '5.5vh' }}>
            {isSubmit === true && savingEnable === false ?
              <Typography variant="body1" gutterBottom style={{ color: "red", fontSize: 16.50 }}>
                The following fields are required:&nbsp;
                {requireFields.map((field) => (<> &nbsp;{`${field.display_name}`},  </>))}
                {clientCodeVal ? clientCodeMsg : null} &nbsp;{phaseVal ? phaseMsg : null}
              </Typography> : null}
            <Typography variant="body1" gutterBottom style={{ color: "red", fontSize: 16 }}>
              {zipValidation === false ? 'Zip code must be either 5 or 9 digits,' : null}
              &nbsp;{stateValidation === false ? 'Please specify a valid 2-character state code.' : null}
              &nbsp; {phoneNoValidation === false ? 'Phone number must be 10 digits only.' : null}
              &nbsp; {totalBalanceValidation === false ? 'Total balance must be Number' : null}
              &nbsp; {dateValidation === false ? 'Date format should be like "MM/DD/YYYY" OR "DD/MM/YYYY".' : null}
              <br /> &nbsp; {secondaryStateValidation === false ? 'Column secondary_state can not contain data with a length greater than 2.' : null}
              &nbsp; {secondaryZipValidation === false ? 'Column secondary_zip can contain data with a length greater than 10.' : null}
            </Typography>
          </div>
          <TableContainer component={Paper} className={`${clientCode == "-2" || phaseNumber == 4 ? classes.container2 : classes.container1}`} >
            <Table aria-label="collapsible table" size="small" className={classes.tableLayout}>
              <TableHead >
                <TableRow className={classes.tablehead}>
                  {placementAccountCSVData[0].map((headCell, index) => (
                    <TableCell style={{ color: "white" }} key={index}>
                      <Autocomplete
                        id={"header-field" + index}
                        className={classes.selectfield}
                        autoHighlight
                        options={filteredTemplateList}
                        onChange={(event, selected) => handleChange(index, event, selected)}
                        getOptionLabel={(option) => option.display_name.trim()}
                        value={templateList.findIndex(x => x.field_name == selectedHeaderList[index]) == -1 ? {
                          field_name: "-1",
                          display_name: "--Ignore Field--",
                          data_type: "varchar",
                          is_nullable: true,
                          is_visible: true,
                          is_required: false,
                          field_length: -1,
                        } : templateList.find(x => x.field_name == selectedHeaderList[index])}
                        renderOption={(option) => (
                          <div style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap" }}>
                            {option.display_name.trim()}
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField {...params}
                            variant="outlined"
                            className={classes.selectdropdown}
                            multiline rowsMax={4}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )} classes={{ input: classes.smallFont }} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{ padding: "60%" }}>
                {placementAccountCSVData.map((row, rowIndex) => (
                  <TableRow key={"row" + rowIndex} className={classes.tableBody}>
                    {placementAccountCSVData[rowIndex].map((cell, cellIndex) => (
                      <TableCell className={`${classes.excelCell} ${classes.tableBody1} ${ignoredCellClassName(rowIndex, cellIndex)}`}
                        align="center" key={"cell" + rowIndex + "_" + cellIndex}>
                        {cell.trim().replace('\t', '')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Footer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CSVFieldnames)
