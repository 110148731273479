import React, { useEffect, useRef, useState } from 'react';
import {
    Accordion, makeStyles, Table, TableBody, AccordionSummary,
    AccordionDetails, Box, Button, Card, Grid, Snackbar
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { INotificationCenter } from "../../models/NotificationCenter/INotificationCenter";
import { INotificationTypeList } from '../../models/NotificationRollBack/INotificationTypeList';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReadNotification from "../../components/NotificationCenterComponent/ReadNotification";
import HideNotification from "../../components/NotificationCenterComponent/HideNotification";
import CardContent from '@material-ui/core/CardContent';
import { IDownloadNotificationResult } from '../../models/NotificationCenter/IDownloadNotificationResult';
import { IDebtor } from '../../models/Inquiry/IDebtor';
import ExpandableRow from '../../components/NotificationCenterComponent/NCSettlementRequest';
import PvExpandableRow from '../../components/NotificationCenterComponent/PaymentVerification/PvRequest';
import DvExpandableRow from '../../components/NotificationCenterComponent/DisputeValidation/DVRequest';
import moment from 'moment';
import BackupDocumentDialog from "../../components/NotificationCenterComponent/BackupDocumentDialog";
import AckFileNotification from "../../components/NotificationCenterComponent/AckFile/AckFileNotification";
import NewPaymentFile from "../../components/NotificationCenterComponent/NewPaymentFile/NewPaymentFile";
import { useTheme } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LightTooltip from '../../components/GlobalStyles/LightTooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Backdrop from '@material-ui/core/Backdrop';
import PuffLoader from "react-spinners/PuffLoader";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import VideoPlayer from '../../components/YouTubePlayer/VideoPlayer';
import PaymentFile from '../../images/Icons/PaymentFile.svg';
import Acknowledgement from '../../images/Icons/Acknowledgement.svg';
import Settlement from '../../images/Icons/Settlement.svg';
import PaymentVerifications from '../../images/Icons/PaymentVerifications.svg';
import BackupDocument from '../../images/Icons/BackupDocument.svg';
import DisputeValidations from '../../images/Icons/DisputeValidations.svg';
import NewFileUploads from '../../images/Icons/NewFileUploads.svg';
import GeneralNotifications from '../../images/Icons/GeneralNotifications.svg';
import Divider from '@mui/material/Divider';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListIcon from '@mui/icons-material/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    div: {
        alignItems: "center",
        height: 'auto',
        bgcolor: "gray"
    },
    buttons: {
        borderRadius: 20,
        background: "#007FFF",
        marginTop: '20px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    details: {
        alignItems: "center"
    },
    card: {
        borderRadius: '12px',
        backgroundColor: 'white',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        border: '1px solid black',
    },
    grid: {
        padding: '10px',
        height: '50px',
        marginLeft: "1px"
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '10px',
        border: '1px solid gray',
    },
    Center: {
        justifyContent: "center",
        alignItems: "center"
    },
    box: {
        display: "flex",
        marginTop: "-8px"
    },
    Title: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: '25px',
    },
    textField1: {
        marginRight: theme.spacing(1),
        width: '320px',
    },
    typographystyle: {
        color: "grey",
        textAlign: "left",
        borderBottom: "1px solid"
    },
    buttonStyle: {
        marginLeft: '1cm',
    },
    buttonStyle1: {
        marginLeft: '1cm',
    },
    descriptionText: {
        textAlign: 'left',
        flexWrap: 'wrap',
        wordWrap: 'break-word',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    /* Side Bar Style */
    sidebar: {
        width: "18.2rem",
        padding: "1.1rem 1.1rem",
        backgroundColor: "#007FFF",
        transition: "all 0.5s ease",
        display: 'block',
        whiteSpace: 'nowrap',
    },
    sidebarCollapsed: {
        borderTopColor: 'white',
        width: "4.2rem",
        padding: "1.1rem 1.1rem",
        backgroundColor: "#007FFF",
        transition: "all 0.5s ease",
        display: 'block',
        whiteSpace: 'nowrap'
    },
    sidebarHeader: {
        display: 'flex',
        marginLeft: '-9px'

    },
    sidebarItem: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '20px'
    },
    textpropertyOut: {
        color: 'white',
        paddingLeft: '7px',
        cursor: 'pointer',
    },
    textProperty: {
        visibility: 'hidden',
    },
    transformImage: {
        transform: 'rotateY(180deg)'
    },
    imageStyle: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginLeft: '-25px',
        border: '2px solid white',
        borderRadius: "100%",
    },
    arrowIcon: {
        color: 'white'
    },
    snackbar: {
        width: "120%",
        marginTop: '-0.5cm',
    },
}))

export default function NotificationcenterClient() {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [Notification, setNotification] = useState<INotificationCenter[]>([]);
    const [HiddenNotification, setHiddenNotifiction] = useState<INotificationCenter[]>([]);
    const [GeneralNotification, setGeneralNotification] = useState<INotificationCenter[]>([]);
    const [SettlementRequest, setSettlementRequest] = useState<INotificationCenter[]>([]);
    const [FileIssue, setFileIssue] = useState<INotificationCenter[]>([]);
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [NotificationType, setNotificationType] = useState<INotificationTypeList[]>([]);
    const [SelectNotificationType, setSelectNotificationType] = useState<any>();
    const UserName = state.userAccessContext?.id;
    const ClientCode = state.userAccessContext?.clientCodes.join(",");
    const [debtors, setDebtors] = useState<IDebtor[]>([]);
    const [selected, setSelected] = React.useState<string>();
    const [video, setVideo] = React.useState<string>('Ury3Df89g7Y');
    const [title, setTitle] = React.useState<string>('A.R.M. WebView: Introducing the Notification Center');
    const [BackUpDocumentReq, setBackUpDocumentReq] = useState<INotificationCenter[]>([]);
    const [DisValidation, setDisValidation] = useState<INotificationCenter[]>([]);
    const [PaymentVerification, setPaymentVerification] = useState<INotificationCenter[]>([]);
    const [ackFile, setAckFile] = useState<INotificationCenter[]>([]);
    const [paymentFile, setpaymentFile] = useState<INotificationCenter[]>([]);
    const [GetdebtorNo, setGetdebtorNo] = useState("");
    const [mapped, setmapped] = useState(false);
    const [selectedFirstDate, setSelectedFirstDate] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(false);
    const [message, setMessage] = useState(false);
    const [istoggle, setIsToggle] = useState(false);
    const [isExpanded, setIsEpanded] = useState(false);
    const [paymentFileAccess, setpaymentFileAccess] = useState(false);
    const [AckFileAccess, setAckFileAccess] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [clearFilterOpen, setClearFilterOpen] = React.useState(false);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setClearFilterOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    //Permissions
    const MessageNotification = state.userAccessContext?.generalNotification;
    const NewFileUpload = state.userAccessContext?.fileIssuesNotification;
    const SettlementAutorization = state.userAccessContext?.settlementRequestsNotification;
    const PaymentVerificationAccess = state.userAccessContext?.paymentVerificationNotification;
    const DisputeValidation = state.userAccessContext?.disputeValidationNotification;
    const BackupDocumentRequestAccess = state.userAccessContext?.backupDocumentRequestNotification;

    const [ActiveNotificationTab, setActiveNotificationTab] = useState(0);

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    const wrapperRef = useRef(null);

    const [ProgressBar, setShowProgressBar] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            handleRelease();
        }
    };

    const Toggler = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const handleRowClick = (accountId: string) => {
        setSelected(selected == accountId ? "" : accountId);
        setGetdebtorNo(accountId);
    }

    const handleRelease = () => {
        if (GetdebtorNo !== undefined && GetdebtorNo !== null) {
            (async () => {
                dispatch({ type: GlobalStateAction.Busy });
                try {
                    let YieldRelease = {
                        "debtorNumber": GetdebtorNo,
                        "userId": state.userAccessContext?.id
                    }
                    const release = await usePost("InquiryReleaseLock", YieldRelease);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex })
                    dispatch({ type: GlobalStateAction.Idle })
                }
                finally {
                    dispatch({ type: GlobalStateAction.Idle })
                }
            })()
        } else {
            null;
        }
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            setShowProgressBar(true);
            try {
                const CheckAccess = await useFetch<IUserInfo[]>(`User/GetUser?userId=${state.userAccessContext?.id}`);
                setpaymentFileAccess(CheckAccess.data["paymentRegex"].length === 0 || CheckAccess.data["paymentRegex"] === null ? true : false);
                setAckFileAccess(CheckAccess.data["reportRegex"].length === 0 || CheckAccess.data["reportRegex"] === null ? true : false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [paymentFileAccess, AckFileAccess])

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            setShowProgressBar(true);
            try {
                let request = {
                    "isShowHidden": false,
                    "rowsPerPage": 200,
                    "userId": UserName,
                    "startDate": null ? StartDate : '',
                    "endDate": null ? EndDate : '',
                    "notificationTypeId": null ? SelectNotificationType == 10 : null,
                    "clients": ClientCode
                };
                const response = await usePost<{ notificationCenter: INotificationCenter[] }>("GetNotifications", request)
                setNotification(response.data["notificationCenter"]);
                setGeneralNotification(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 2));
                setFileIssue(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 3));
                setSettlementRequest(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 4));
                setPaymentVerification(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 5));
                setBackUpDocumentReq(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 6));
                setDisValidation(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 7));
                setpaymentFile(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 8));
                setAckFile(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 9));
                setmapped(response.data["notificationCenter"].length > 0 ? true : false);
                setActiveNotificationTab(8);
                setShowProgressBar(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }

        })()
    }, []);

    async function getNotificationsAsync(NotificationTypeId: number) {
        setActiveNotificationTab(NotificationTypeId);
        dispatch({ type: GlobalStateAction.Busy });
        setShowProgressBar(true);
        try {
            let request = {
                "isShowHidden": false,
                "rowsPerPage": 200,
                "userId": UserName,
                "startDate": (selectedFirstDate ? StartDate : null),
                "endDate": (selectedEndDate ? EndDate : null),
                "notificationTypeId": NotificationTypeId === undefined ? null : NotificationTypeId,
                "clients": ClientCode
            };
            const response = await usePost<INotificationCenter[]>("GetNotifications", request)
            setNotification(response.data["notificationCenter"]);
            setGeneralNotification(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 2));
            setFileIssue(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 3));
            setSettlementRequest(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 4));
            setPaymentVerification(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 5));
            setBackUpDocumentReq(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 6));
            setDisValidation(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 7));
            setpaymentFile(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 8));
            setAckFile(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 9));

            setmapped(response.data["notificationCenter"].length > 0 ? true : false);
            setShowProgressBar(false);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    async function getUnreadNotificationsAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            let request = {
                "isShowHidden": false,
                "rowsPerPage": 200,
                "userId": UserName,
                "startDate": null ? StartDate : '',
                "endDate": null ? EndDate : '',
                "notificationTypeId": SelectNotificationType == 10 || SelectNotificationType === undefined ? null : SelectNotificationType,
                "clients": ClientCode
            };
            const response = await usePost<INotificationCenter[]>("GetNotifications", request)
            setNotification(response.data["notificationCenter"]);
            setGeneralNotification(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 2));
            setFileIssue(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 3));
            setSettlementRequest(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 4));
            setPaymentVerification(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 5));
            setBackUpDocumentReq(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 6));
            setDisValidation(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 7));
            setpaymentFile(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 8));
            setAckFile(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 9));

            setmapped(response.data["notificationCenter"].length > 0 ? true : false);
            setIsToggle(false);
            setShowProgressBar(false);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    async function clearFilter() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            let request = {
                "isShowHidden": false,
                "rowsPerPage": 200,
                "userId": UserName,
                "startDate": '',
                "endDate": '',
                "notificationTypeId": SelectNotificationType == 10 || SelectNotificationType === undefined ? null : SelectNotificationType,
                "clients": ClientCode
            };
            const response = await usePost<INotificationCenter[]>("GetNotifications", request)
            setNotification(response.data["notificationCenter"]);
            setGeneralNotification(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 2));
            setFileIssue(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 3));
            setSettlementRequest(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 4));
            setPaymentVerification(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 5));
            setBackUpDocumentReq(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 6));
            setDisValidation(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 7));
            setpaymentFile(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 8));
            setAckFile(response.data["notificationCenter"].filter(notification => notification.notificationTypeId == 9));

            setmapped(response.data["notificationCenter"].length > 0 ? true : false);
            setIsToggle(false);
            setShowProgressBar(false);
            setClearFilterOpen(true);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    async function getHiddenNotificationsAsync(HiddenNotificationId: number) {
        dispatch({ type: GlobalStateAction.Busy });
        setShowProgressBar(true);
        try {
            let request = {
                "isShowHidden": true,
                "userId": UserName,
                "notificationTypeId": HiddenNotificationId === undefined ? null : HiddenNotificationId,
                "clients": ClientCode
            };
            const response = await usePost<INotificationCenter[]>("GetHiddenNotifications", request);
            setHiddenNotifiction(response.data["hiddenNotificationCenter"]);
            setGeneralNotification(response.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 2))
            setFileIssue(response.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 3))
            setSettlementRequest(response.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 4))
            setPaymentVerification(response.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 5))
            setBackUpDocumentReq(response.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 6))
            setDisValidation(response.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 7))
            setpaymentFile(response.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 8))
            setAckFile(response.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 9))
            setmapped(response.data["hiddenNotificationCenter"].length > 0 ? true : false);
            setIsToggle(true);
            setShowProgressBar(false);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const NotificationTypeList = await useFetch<INotificationTypeList[]>("GetNotificationtypes");
                setNotificationType(NotificationTypeList.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }, [])

    const getDownloadFileAsync = (fileId: string) => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const responseDownloadFile = await useFetch<IDownloadNotificationResult>(`DownloadNotificationFile?fileId=${fileId}`);
                const arrayBuffer = base64ToArrayBuffer(responseDownloadFile.data.fileContent);
                createAndDownloadBlobFile(arrayBuffer, responseDownloadFile.data.fileName);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const getDebtorAsync = (rid: string) => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const DebtorDetils = await useFetch<IDebtor[]>("DebtorById?DBTNumber=" + rid);
                setDebtors(DebtorDetils.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })();
    }

    const handleFilter = () => {
        getNotificationsAsync(ActiveNotificationTab);
        setShowProgressBar(true);
        setOpen(true);
    };

    const handleClearFilter = () => {
        clearFilter();
        setShowProgressBar(true);
        setStartDate(null);
        setEndDate(null);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedFirstDate(true);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedEndDate(true);
    };

    function handleToggle(event) {
        if (!istoggle) {
            getHiddenNotificationsAsync(ActiveNotificationTab);
            setShowProgressBar(true);
        } else {
            setShowProgressBar(true);
            getUnreadNotificationsAsync();
        }
    }

    const handleToggler = () => {
        setIsEpanded(s => !s);
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={100} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <div className={`${isExpanded ? classes.sidebar : classes.sidebarCollapsed}`}>
                <div className={classes.sidebarHeader}>
                    <IconButton size="medium" onClick={handleToggler}>
                        {
                            isExpanded ?
                                <DoubleArrowIcon fontSize="inherit" style={{ color: 'white' }} className={classes.transformImage} />
                                :
                                <DoubleArrowIcon fontSize="inherit" className={classes.arrowIcon} />
                        }
                    </IconButton>
                </div>

                <div className={classes.sidebarItem}>
                    <AvatarTooltip title="New Payment File">
                        <img src={PaymentFile} alt="Icon for Payement File" className={classes.imageStyle} onClick={(_) => { getNotificationsAsync(8) }} />
                    </AvatarTooltip>
                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => { getNotificationsAsync(8) }}>
                        New Payment File
                    </Typography>
                </div>

                <div className={classes.sidebarItem}>
                    <AvatarTooltip title="Acknowledgement File">
                        <img src={Acknowledgement} alt="Icon for Acknowledgement File" className={classes.imageStyle} onClick={(_) => { getNotificationsAsync(9) }} />
                    </AvatarTooltip>
                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => { getNotificationsAsync(9) }}>
                        Acknowledgement File
                    </Typography>
                </div>

                {NewFileUpload ?
                    <div className={classes.sidebarItem}>
                        <AvatarTooltip title="File Issue">
                            <img src={NewFileUploads} alt="Icon for New File Upload" className={classes.imageStyle} onClick={(_) => { getNotificationsAsync(3) }} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => { getNotificationsAsync(3) }}>
                            New File Upload
                        </Typography>
                    </div>
                    :
                    null
                }
                {SettlementAutorization ?
                    <div className={classes.sidebarItem}>
                        <AvatarTooltip title=" Settlement Authorization">
                            <img src={Settlement} alt="Icon for Settlement Authorization" className={classes.imageStyle} onClick={(_) => { getNotificationsAsync(4) }} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => { getNotificationsAsync(4) }}>
                            Settlement Authorization
                        </Typography>
                    </div>
                    :
                    null
                }

                {PaymentVerificationAccess ?
                    <div className={classes.sidebarItem}>
                        <AvatarTooltip title="Payment Verification">
                            <img src={PaymentVerifications} alt="Icon for payment verification" className={classes.imageStyle} onClick={(_) => { getNotificationsAsync(5) }} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => { getNotificationsAsync(5) }}>
                            Payment Verification
                        </Typography>
                    </div>
                    :
                    null
                }

                {BackupDocumentRequestAccess ?
                    <div className={classes.sidebarItem}>
                        <AvatarTooltip title="Backup Documentation">
                            <img src={BackupDocument} alt="Icon for Backup Documentation" className={classes.imageStyle} onClick={(_) => { getNotificationsAsync(6) }} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => { getNotificationsAsync(6) }}>
                            Backup Documentation
                        </Typography>
                    </div>
                    :
                    null
                }

                {DisputeValidation ?
                    <div className={classes.sidebarItem}>
                        <AvatarTooltip title="Dispute Validation">
                            <img src={DisputeValidations} alt="Icon for Dispute Validations" className={classes.imageStyle} onClick={(_) => { getNotificationsAsync(7) }} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => { getNotificationsAsync(7) }}>
                            Dispute Validation
                        </Typography>
                    </div>
                    :
                    null
                }

                {MessageNotification ?
                    <div className={classes.sidebarItem}>
                        <AvatarTooltip title="General Notifications">
                            <img src={GeneralNotifications} alt="Icon for General Notifications" className={classes.imageStyle} onClick={(_) => { getNotificationsAsync(2) }} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => { getNotificationsAsync(2) }}>
                            General Notifications
                        </Typography>
                    </div>
                    :
                    null
                }
            </div>
            <Grid container spacing={1} className={classes.grid} style={{ backgroundColor: 'whitesmoke' }}>
                <Grid container className={classes.maingrid} justify='space-evenly'>
                    <Grid item xs={5} sm={5} md={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    label="Start Date"
                                    variant="inline"
                                    inputVariant="outlined"
                                    format={DateFormat}
                                    disableToolbar
                                    autoOk={true}
                                    margin="normal"
                                    id="NC_StartDate"
                                    value={StartDate}
                                    className={classes.textField1}
                                    onChange={handleFirstDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={6} sm={5} md={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    label="End Date"
                                    variant="inline"
                                    inputVariant="outlined"
                                    format={DateFormat}
                                    disableToolbar
                                    autoOk={true}
                                    margin="normal"
                                    className={classes.textField1}
                                    id="NC_StartDate"
                                    value={EndDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={2} className={classes.Title}>
                        {StartDate > EndDate ? <Typography variant='h6' style={{ color: 'red' }}>Invalid End Date.</Typography> : ""}
                    </Grid>
                    <Box className={classes.buttonStyle}>
                        <Button id="NoCenter_Filter_Button" className={classes.buttons} disabled={StartDate > EndDate}
                            onClick={handleFilter} variant="contained" color="primary" startIcon={<FilterAltIcon />}>
                            Filter
                        </Button>
                    </Box>
                    <Box className={classes.buttonStyle}>
                        <Button id="NoCenter_Filter_Button" className={classes.buttons} disabled={StartDate > EndDate}
                            onClick={handleClearFilter} variant="contained" color="primary" startIcon={<FilterListIcon />}>
                            Clear Filter
                        </Button>
                    </Box>
                    <Box className={classes.buttonStyle1}>
                        <Button id="NoCenter_ShowHidden_Button" className={classes.buttons} disabled={StartDate > EndDate}
                            variant="contained" color="primary" onClick={(e) => { handleToggle(e); }} startIcon={istoggle ? <VisibilityIcon /> : <VisibilityOffIcon />}>
                            {istoggle ? "SHOW UNREAD " : "SHOW HIDDEN"}
                        </Button>
                    </Box>
                    <Grid item xs={1} style={{ marginTop: '45px' }}>
                        <VideoPlayer video={video} title={title} />
                    </Grid>
                </Grid>

                {ActiveNotificationTab === 2 ?
                    <Grid item xs={12} style={{ marginTop: '5px' }}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h6" className={classes.typographystyle} gutterBottom>
                                    <Typography variant="h6" style={{ color: "black", textAlign: "left" }} gutterBottom>
                                        General Notifications
                                    </Typography>
                                </Typography>
                                <Scrollbars autoHide
                                    autoHideTimeout={1000}
                                    autoHideDuration={10}
                                    autoHeight
                                    autoHeightMin={540}
                                    autoHeightMax={540}
                                    style={{ width: "100%" }}>
                                    {mapped ?
                                        GeneralNotification.map(notification => {
                                            let CreatedDate = moment(notification.createdDate).format(state.GlobalUtils?.settingValue)
                                            return (
                                                <React.Fragment>
                                                    <Typography style={{ width: '46%' }} className={classes.descriptionText} variant="subtitle2" gutterBottom>
                                                        {CreatedDate} {notification.extraData_3}
                                                    </Typography>
                                                    <Box p={1} flexShrink={0} bgcolor="white" style={{ marginTop: "-40px" }}>
                                                        <ReadNotification Notify={notification} />
                                                        <HideNotification Notify={notification} onHide={() => { getNotificationsAsync(2) }} />
                                                    </Box>
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                            No Records to display...
                                        </Typography>
                                    }
                                </Scrollbars>
                            </CardContent>
                        </Card>
                    </Grid>

                    : ActiveNotificationTab === 3 ?
                        <Grid item xs={12}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h6" className={classes.typographystyle} gutterBottom>
                                        <Typography variant="h6" style={{ color: "black", textAlign: "left" }} gutterBottom>
                                            File Issue
                                        </Typography>
                                    </Typography>
                                    <Scrollbars autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={10}
                                        autoHeight
                                        autoHeightMin={540}
                                        autoHeightMax={540}
                                        style={{ width: "100%" }}>
                                        {mapped ?
                                            FileIssue.map((f) => {
                                                let createdDate = moment(f.createdDate).format(state.GlobalUtils?.settingValue);
                                                return (
                                                    <React.Fragment>
                                                        <Box display="flex" p={1}>
                                                            <Box p={1} width="50%">
                                                                <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                                                                    {createdDate} File Issue : {f.extraData_3}
                                                                </Typography>
                                                            </Box>
                                                            <Box p={0} width="50%">
                                                                <Typography variant="subtitle2" style={{ textAlign: "right" }} gutterBottom>
                                                                    {f.fileName}
                                                                    <LightTooltip title="Download">
                                                                        <IconButton id="NoCenter_FileNo_Download" color="primary" disabled={f.hiddenFlag ? true : false} aria-label="upload picture" component="span" onClick={() => { getDownloadFileAsync(f.fileID); }}>
                                                                            <CloudDownloadIcon />
                                                                        </IconButton>
                                                                    </LightTooltip>
                                                                </Typography>
                                                            </Box>
                                                            <Box p={0} flexShrink={0} >
                                                                <HideNotification Notify={f} onHide={() => { getNotificationsAsync(3) }} />
                                                            </Box>
                                                            <Box p={0} flexShrink={0}>
                                                            </Box>
                                                        </Box>
                                                        <Accordion style={{ marginTop: "-20px" }} onChange={(e) => { message == false ? setMessage(true) : setMessage(false) }}>
                                                            <AccordionSummary aria-controls="panel1c-content" id="panel1c-header" style={{ height: "10%" }}>
                                                                <Typography className={classes.heading}>{message ? "Hide Description" : "Show Description"}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails className={classes.details}>
                                                                {f.extraData_1}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        <Divider style={{ marginTop: "5px", height: '2px', background: "black" }} />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                No Records to display...
                                            </Typography>
                                        }
                                    </Scrollbars>
                                </CardContent>
                            </Card>
                        </Grid>

                        : ActiveNotificationTab === 7 ?
                            <Grid item xs={12}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography variant="h6" className={classes.typographystyle} gutterBottom>
                                            <Typography variant="h6" style={{ color: "black", textAlign: "left" }} gutterBottom>
                                                Dispute Validation
                                            </Typography>
                                        </Typography>
                                        <React.Fragment>
                                            <Scrollbars autoHide
                                                autoHideTimeout={1000}
                                                autoHideDuration={10}
                                                autoHeight
                                                autoHeightMin={540}
                                                autoHeightMax={540}
                                                style={{ width: "100%" }}>
                                                <Table>
                                                    <TableBody>
                                                        {mapped ?
                                                            DisValidation.map(r => <DvExpandableRow onClick={() => { handleRowClick(r.extraData_1); getDebtorAsync(r.extraData_1); }} selected={selected === r.extraData_1} key={r.extraData_1} dv={r} onHideAll={() => { getNotificationsAsync(7) }} selectedDBTNumber={selected} />)

                                                            :
                                                            <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                                No Records to display...
                                                            </Typography>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </Scrollbars>
                                        </React.Fragment>
                                    </CardContent>
                                </Card>
                            </Grid>

                            : ActiveNotificationTab === 6 ?
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography variant="h6" className={classes.typographystyle} gutterBottom>
                                                <Typography variant="h6" style={{ color: "black", textAlign: "left" }} gutterBottom>
                                                    Backup Document Requests
                                                </Typography>
                                            </Typography>
                                            <Scrollbars autoHide
                                                autoHideTimeout={1000}
                                                autoHideDuration={10}
                                                autoHeight
                                                autoHeightMin={540}
                                                autoHeightMax={540}
                                                style={{ width: "100%" }}>
                                                {mapped ?
                                                    BackUpDocumentReq.map(BackUp => {
                                                        return (
                                                            <React.Fragment>

                                                                <Box display="flex" p={1} >
                                                                    <Box p={1} width="80%">
                                                                        <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                                                                            {moment(BackUp.createdDate).format(state.GlobalUtils?.settingValue)} Backup Documentation: Client Acct #{BackUp.extraData_3.substring(0, BackUp.extraData_3.lastIndexOf("+"))} / Agency Acct #{BackUp.extraData_1}
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" style={{ textAlign: "left" }} gutterBottom>
                                                                            Client Code: {BackUp.clT_Code} = {BackUp.clT_Name} - {BackUp.clT_Name_2}
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" style={{ textAlign: "left" }} gutterBottom>
                                                                            Type: '{BackUp.extraData_2}'
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box p={0} flexShrink={0} >
                                                                        <BackupDocumentDialog BackUpInfo={BackUp} onHideAll={() => { getNotificationsAsync(6) }} />
                                                                    </Box>
                                                                </Box>

                                                                <Divider style={{ marginTop: "5px", height: '2px', background: "black" }} />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    :
                                                    <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                        No Records to display...
                                                    </Typography>
                                                }
                                            </Scrollbars>
                                        </CardContent>
                                    </Card>
                                </Grid>


                                : ActiveNotificationTab === 5 ?
                                    <Grid item xs={12}>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography variant="h6" className={classes.typographystyle} gutterBottom>
                                                    <Typography variant="h6" style={{ color: "black", textAlign: "left" }} gutterBottom>
                                                        Payment Verification
                                                    </Typography>
                                                </Typography>
                                                <React.Fragment>
                                                    <Scrollbars autoHide
                                                        autoHideTimeout={1000}
                                                        autoHideDuration={10}
                                                        autoHeight
                                                        autoHeightMin={540}
                                                        autoHeightMax={540}
                                                        style={{ width: "100%" }}>
                                                        <Table>
                                                            <TableBody>
                                                                {mapped ?
                                                                    PaymentVerification.map(r => <PvExpandableRow onClick={() => { handleRowClick(r.extraData_1.slice(0, 9)); getDebtorAsync(r.extraData_1.slice(0, 9)); }} selected={selected === r.extraData_1.slice(0, 9)} key={r.extraData_1.slice(0, 9)} pv={r} onRefresh={() => { getNotificationsAsync(5) }} selectedDBTNumber={selected} />)

                                                                    :
                                                                    <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                                        No Records to display...
                                                                    </Typography>
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </Scrollbars>
                                                </React.Fragment>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    : ActiveNotificationTab === 4 ?
                                        <Grid item xs={12}>
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <Typography variant="h6" className={classes.typographystyle} gutterBottom>
                                                        <Typography variant="h6" style={{ color: "black", textAlign: "left" }} gutterBottom>
                                                            Settlement Requests
                                                        </Typography>
                                                    </Typography>
                                                    <Scrollbars autoHide
                                                        autoHideTimeout={1000}
                                                        autoHideDuration={10}
                                                        autoHeight
                                                        autoHeightMin={540}
                                                        autoHeightMax={540}
                                                        style={{ width: "100%" }}>
                                                        <React.Fragment>
                                                            <Table>
                                                                <TableBody>
                                                                    {mapped ?
                                                                        SettlementRequest.map(r => <ExpandableRow onClick={() => { handleRowClick(r.extraData_3); getDebtorAsync(r.extraData_3); }} selected={selected === r.extraData_3} key={r.extraData_3} Notify={r} onHideAll={() => { getNotificationsAsync(4) }} onRefresh={() => { getNotificationsAsync(4) }} selectedDBTNumber={selected} />)
                                                                        :
                                                                        <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                                            No Records to display...
                                                                        </Typography>
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </React.Fragment>
                                                    </Scrollbars>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        : ActiveNotificationTab === 9 ?
                                            <Grid item xs={12} >
                                                <Card className={classes.card}>
                                                    <CardContent>
                                                        <Typography variant="h6" className={classes.typographystyle} gutterBottom>
                                                            <Typography variant="h6" style={{ color: "black", textAlign: "left" }} gutterBottom>
                                                                Acknowledgement File
                                                            </Typography>
                                                        </Typography>
                                                        {AckFileAccess === true ?
                                                            <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                                No Records to display...
                                                            </Typography>
                                                            :
                                                            <Scrollbars autoHide
                                                                autoHideTimeout={1000}
                                                                autoHideDuration={10}
                                                                autoHeight
                                                                autoHeightMin={540}
                                                                autoHeightMax={540}
                                                                style={{ width: "100%" }}>
                                                                {mapped ?
                                                                    ackFile.map(ack => {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <AckFileNotification AckInfo={ack} onRefresh={() => { getNotificationsAsync(9) }} />
                                                                                <Divider style={{ marginTop: "5px", height: '2px', background: "black" }} />
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                    :
                                                                    <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                                        No Records to display...
                                                                    </Typography>
                                                                }
                                                            </Scrollbars>
                                                        }

                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            : ActiveNotificationTab === 8 || ActiveNotificationTab === 0 ?
                                                < Grid item xs={12}>
                                                    <Card className={classes.card}>
                                                        <CardContent >
                                                            <Typography variant="h6" className={classes.typographystyle} gutterBottom>
                                                                <Typography variant="h6" style={{ color: "black", textAlign: "left" }} gutterBottom>
                                                                    New Payment File
                                                                </Typography>
                                                            </Typography>
                                                            {paymentFileAccess === true ?
                                                                <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                                    No Records to display...
                                                                </Typography>
                                                                :
                                                                <Scrollbars autoHide
                                                                    autoHideTimeout={1000}
                                                                    autoHideDuration={10}
                                                                    autoHeight
                                                                    autoHeightMin={540}
                                                                    autoHeightMax={540}
                                                                    style={{ width: "100%" }}>
                                                                    {mapped ? paymentFile.map(ack => {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <NewPaymentFile NPF={ack} onRefresh={() => { getNotificationsAsync(8) }} />
                                                                                <Divider style={{ marginTop: "5px", height: '2px', background: "black" }} />
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                        : <Typography variant="subtitle2" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                                            No Records to display...
                                                                        </Typography>
                                                                    }
                                                                </Scrollbars>
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                :
                                                null
                }
            </Grid >

            <Snackbar open={open} className={classes.snackbar} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Filter Successfully Triggered!
                </Alert>
            </Snackbar>
            <Snackbar open={clearFilterOpen} className={classes.snackbar} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Filter Cleared Successfully !
                </Alert>
            </Snackbar>
        </React.Fragment >
    )

}