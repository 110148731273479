import * as React from 'react';
import InquiryDetail from './InquiryDetail';
import { IDebtor } from '../../models/Inquiry/IDebtor';
import { Collapse, IconButton, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { Columns } from '../../models/Inquiry/config';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { useHistory, useLocation } from 'react-router-dom';
import * as constant from '../../constants/Constant'

const useRowStyles = makeStyles({
    root: {
        fontSize: 12,
        textOverflow: 'ellipsis',
    },
    root1: {
        maxHeight: 30,
    },
    rootHidden: {
        display: "none"
    },
    image: {
        width: '20px',
        height: '17px',
    },
    TextHighlight: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '14px',
        backgroundColor: "yellow",
        textOverflow: 'ellipsis'
    },
    TextNormal: {
        color: 'black',
        fontSize: 14,
        textOverflow: 'ellipsis'
    },
    selectedRowTextColor: {
        color: 'white',
    },
    overwrapcell: {
        overflow: 'break-word'
    },
    clientId: {
        maxHeight: 30,
    },
    table: {
        flexWrap: 'wrap',
        paddingTop: '10px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingLeft: '20px',
        border: '1px solid #f0efef'
    }
})

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const ExpandableRow: React.FC<{ row: IDebtor, selected: boolean, onClick: (accountId: string) => void, selectedDBTNumber: string, searchWord: string }> = (props) => {
    const { row, searchWord } = props;
    const classes = useRowStyles();
    const { state, dispatch } = useGlobalState();

    const [service, setService] = useState('');
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const [isActiveStatus, setGetActivestatus] = useState(false);

    const lastChangeDate = moment(row.dbT_LAST_CHG_DATE).format(state.GlobalUtils?.settingValue);
    const referralDate = moment(row.dbT_REFERRAL_DATE).format(state.GlobalUtils?.settingValue);

    const prevCount = usePrevious(props.selectedDBTNumber);
    const userId = state.userAccessContext?.id.toString();

    useEffect(() => {
        window.onbeforeunload = function () {
            setYieldRelease();
        };
    }, []);


    useEffect(() => {
        if (row.dbT_COMPANY === constant.CONT) {
            setService('Contingency');
        }
        if (row.dbT_COMPANY === constant.PREC) {
            setService('Flat Fee');
        }
        else if (row.dbT_COMPANY === constant.FIRSTSTP) {
            setService('1st Party');
        }
    }, [service])

    const setYieldLock = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const response = await useFetch<any>(`InquiryYieldLock?debtorNumber=${row.dbT_NO}&userId=${state.userAccessContext?.id.toString()}`);
                setGetActivestatus(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(row.dbT_NO);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [props.selectedDBTNumber, prevCount])

    const setYieldRelease = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let YieldRelease = {
                    "debtorNumber": GetDebtorNo,
                    "userId": userId
                }
                const release = await usePost("InquiryReleaseLock", YieldRelease)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <TableRow className={classes.table} style={row.isActive === true ? { backgroundColor: "white", color: "black", fontSize: '14px' } : props.selected ? { backgroundColor: "#1E90FF", color: "white" } : { backgroundColor: "#e7eff9" }}
                onClick={(_) => { props.onClick(row.dbT_NO); (row.dbT_NO !== props.selectedDBTNumber ? setYieldRelease() : setGetDebtorNo("")); (props.selected === true ? setYieldRelease() : setYieldLock()); }} key={row.dbT_NO} hover>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : { fontSize: 14 }} scope="row" className={classes.clientId}>
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === row.dbT_CLIENT ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row.dbT_CLIENT ? classes.TextHighlight : ""}`}>
                        {row.dbT_CLIENT}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : row.dbT_NO.includes(searchWord) ? { color: 'black' } : props.selected === true && searchWord === "" ? { color: 'white' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row.dbT_NO.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_NO}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : row.dbT_CLNT_ACNT1.includes(searchWord) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row.dbT_CLNT_ACNT1.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_CLNT_ACNT1}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }}>
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : row.dbT_NAME.includes(searchWord) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row.dbT_NAME.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_NAME}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === row.deM_ZIP ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row.deM_ZIP ? classes.TextHighlight : ""}`}>
                        {row.deM_ADDR1},&nbsp;{row.deM_CITY},&nbsp;{row.deM_STATE},&nbsp;{row.deM_ZIP}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : row.deM_PHONE1.includes(searchWord) ? { color: 'black' } : props.selected === true && searchWord === "" ? { color: 'white' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row.deM_PHONE1.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.deM_PHONE1}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white"} : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} align="center" >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === row.dbT_PRINCIPAL_DUE.toFixed(2) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row.dbT_PRINCIPAL_DUE.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row.dbT_PRINCIPAL_DUE.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white", fontStyle: 'bold' } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === row.dbT_MISC1_DUE.toFixed(2) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row.dbT_MISC1_DUE.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row.dbT_MISC1_DUE.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} align="center" >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === row.dbT_PRINCIPAL_RECVD.toFixed(2) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row.dbT_PRINCIPAL_RECVD.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row.dbT_PRINCIPAL_RECVD.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} align="center" >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === row.dbT_PRINCIPAL_DUE.toFixed(2) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row.dbT_PRINCIPAL_DUE.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row.dbT_PRINCIPAL_DUE.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : row.stS_DESC.includes(searchWord) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row.stS_DESC.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.stS_DESC.replace('|', " ")}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} align="center">
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === moment(row.dbT_LAST_CHG_DATE).format(state.GlobalUtils?.settingValue) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === moment(row.dbT_LAST_CHG_DATE).format(state.GlobalUtils?.settingValue) ? classes.TextHighlight : ""}`}>
                        {lastChangeDate}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white" } : row.isActive === true ? { fontSize: 12, color: "black"} : { fontSize: 12 }} align="center">
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === moment(row.dbT_REFERRAL_DATE).format(state.GlobalUtils?.settingValue) ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === moment(row.dbT_REFERRAL_DATE).format(state.GlobalUtils?.settingValue) ? classes.TextHighlight : ""}`}>
                        {referralDate}
                    </span>
                </TableCell>
                <TableCell style={props.selected && row.isActive === false ? { fontSize: 12, color: "white", fontStyle: 'bold' } : row.isActive === true ? { fontSize: 12, color: "black" } : { fontSize: 12 }} >
                    <span style={props.selected === true && row.isActive === true ? { color: 'black' } : row.isActive === true ? { color: 'black' } : searchWord === service ? { color: 'black' } : props.selected && row.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === service ? classes.TextHighlight : ""}`}>
                        {service}
                    </span>
                </TableCell>
            </TableRow>
            <TableRow className={props.selected ? classes.root : classes.rootHidden}>
                <TableCell scope="row" colSpan={Columns.length}>
                    <Collapse in={props.selected} timeout="auto" unmountOnExit >
                        <InquiryDetail inquiryDetail={row} isBlocked={isActiveStatus} onCheck={setYieldLock} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    )
}

export default ExpandableRow