import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react'
import { ITransactionHistory } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory'
import { IDebtor } from '../../../models/NotificationCenter/IDebtor';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { useGlobalState } from '../../../store/GlobalStore';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        marginRight: "2px",
        marginTop: "0%",
        width: "15%",
    },
    root: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px"
    },
}))

const TransactionAuditHistory: React.FC<{ debtors: IDebtor, transactions: ITransactionHistory[], mapped: boolean }> = (props) => {
    const { transactions, mapped } = props;
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();

    // Inspired by the microsoft spinners.
    const useProgressStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));

    function Progress(props) {
        const classes = useProgressStyles();
        return (
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={40}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={40}
                    thickness={4}
                    {...props}
                />
            </div>
        );
    }

    return (
        <React.Fragment >
            <h3 style={{ marginTop: "-20px", color: "blue" }}> Transactions</h3>
            <Box p={1} width="100%" flexShrink={0} bgcolor="white" className={classes.root} >
                <Scrollbars autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={10}
                    autoHeight
                    autoHeightMin={170}
                    autoHeightMax={150}
                    style={{ width: "100%" }}>
                    {transactions.length > 0 ?
                        transactions.map((transactionsHistories) => {
                            if (transactionsHistories.activityType !== "0") {
                                return (
                                    < Typography gutterBottom style={{ color: "#009900", fontSize: 17 }}>
                                        <p>  {moment(transactionsHistories.activityDt).format(state.GlobalUtils?.settingValue)}
                                            &nbsp; {transactionsHistories.activity}
                                        </p>
                                    </Typography>
                                )
                            } else {
                                return (
                                    < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                        No Records Available
                                    </Typography>
                                )
                            }
                        })
                        : <Progress />
                    }
                </Scrollbars>
            </Box>
        </React.Fragment>
    )
}

export default TransactionAuditHistory
