import { Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: "flex",
      minWidth: '100%',
      overflowY: 'hidden',
      alignItems: "center",
      justifyContent: 'center',
    },
    textdisplay: {
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
      marginTop:'3px'
    },
    devider: {
      marginTop: '10px'
    }
  }),
);

const Footer = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container className={classes.footer}>
        <Typography variant="body1" color="inherit" className={classes.textdisplay}>
          &copy;{new Date().getFullYear()} ARM SOLUTIONS | All rights reserved
        </Typography>
      </Container>
    </React.Fragment>
  )
}
export default Footer
