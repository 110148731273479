import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import {
    Box, Button, Card, CardContent, Checkbox, createStyles,
    FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem,
    Paper, Select, Snackbar, Tab, Tabs, TextField, Theme, Typography
} from '@material-ui/core';
import { useFetch, usePost } from '../../utils/apiHelper';
import { GlobalDateTimeUtil } from '../../models/GlobalUtil/GlobalDateTimeUtil';
import { IGlobalUtil } from '../../models/GlobalUtil/IGlobalUtil';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(2),
            width: 700,
            borderWidth: "5px",
            borderColor: "#007FFF !important"
        },
        selectEmpty: {
            marginTop: theme.spacing(0),
        },
        button: {
            borderRadius: '20px',
        },
        devMode: {
            margin: theme.spacing(2),
            width: 500,
            borderWidth: "5px",
            borderColor: "#007FFF !important"
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    }),
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStylesTabs = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        color: 'black'
    },
    paper: {
        color: theme.palette.text.secondary,
    },
}));

function GSettings() {
    const classes = useStylesTabs();
    const classesUI = useStyles();
    const { state, dispatch } = useGlobalState();
    const [value, setValue] = React.useState(0);
    const [GetGlobalSettings, setGetGlobalSettings] = useState([]);
    const [updateDate, setUpdateDate] = useState<string>('');
    const [updateTime, setUpdateTime] = useState<string>('');
    const [UpdDocEmail, setUpdDocEmail] = useState<string>('');
    const [BeginEmail, setBeginEmail] = useState<string>('');
    const [SettlementEmail, setSettlementEmail] = useState<string>('');
    const [NoPaymentEmail, setNoPaymentEmail] = useState<string>('');
    const [UpdFileEmail, setUpdFileEmail] = useState<string>('');
    const [SupportEmail, setSupportEmail] = useState<string>('');
    const [ClientConfirmationEmail, setClientConfirmationEmail] = useState<string>('');
    const [UnmappedFileEmail, setUnmappedFileEmail] = useState<string>('');
    const [UatEmail, setUatEmail] = useState<string>('');
    const [blockEmail, setBlockEmail] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [isError, setIsError] = useState(false);
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCreate(false);
    };

    const ErrorMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsError(false);
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeDate = (event) => {
        setUpdateDate(event.target.value);
    };

    useEffect(() => {
        (async () => {
            const response = await useFetch<IGlobalUtil[]>("GlobalSettings/GetAllSettings");
            setGetGlobalSettings(response.data);
            setUpdateDate(response.data[0].settingValue)
            setUpdDocEmail(response.data[2].settingValue);
            setBeginEmail(response.data[3].settingValue);
            setSettlementEmail(response.data[4].settingValue);
            setNoPaymentEmail(response.data[5].settingValue);
            setUpdFileEmail(response.data[6].settingValue);
            setSupportEmail(response.data[7].settingValue);
            setUatEmail(response.data[8].settingValue);
            setClientConfirmationEmail(response.data[9].settingValue);
            setUnmappedFileEmail(response.data[10].settingValue);
        })()
    }, [])


    const updateDateSettings = () => {
        (async () => {
            let request = {
                "programKey": 1,
                "value": updateDate
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            UpdateGlobalStateSettings();
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateTimeSettings = () => {
        (async () => {
            let request = {
                "programKey": 2,
                "value": updateTime
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            UpdateGlobalStateTimeSettings();
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateDocEmailSettings = () => {
        (async () => {
            let request = {
                "programKey": 3,
                "value": UpdDocEmail
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateBeginReorderEmailSettings = () => {
        (async () => {
            let request = {
                "programKey": 4,
                "value": BeginEmail
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateSettlementEmailSettings = () => {
        (async () => {
            let request = {
                "programKey": 5,
                "value": SettlementEmail
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateFileEmailSettings = () => {
        (async () => {
            let request = {
                "programKey": 7,
                "value": UpdFileEmail
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateSupportEmailSettings = () => {
        (async () => {
            let request = {
                "programKey": 8,
                "value": SupportEmail
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateUATEmail = () => {
        (async () => {
            let request = {
                "programKey": 9,
                "value": UatEmail
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            BlockEmail();
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateClientConfirmationEmail = () => {
        (async () => {
            let request = {
                "programKey": 10,
                "value": ClientConfirmationEmail
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const updateUnmappedEmail = () => {
        (async () => {
            let request = {
                "programKey": 12,
                "value": UnmappedFileEmail
            }
            const response = await usePost<any>("GlobalSettings/UpdateSettings", request);
            if (response.status === 202) {
                setIsCreate(true);
            }
            else {
                setIsError(true);
            }
        })()
    }

    const UpdateGlobalStateSettings = () => {
        (async () => {
            await useFetch<GlobalDateTimeUtil>("GlobalSettings/GetDateSettings").then((r) => {
                dispatch({
                    type: GlobalStateAction.GlobalUtils, GlobalUtils: {
                        enumKey: r.data.enumKey,
                        settingName: r.data.settingName,
                        settingValue: r.data.settingValue
                    }
                });
            })
        })()
    }

    const handleCheckboxChange = (event) => {
        setBlockEmail(event.target.checked)
    }

    const UpdateGlobalStateTimeSettings = () => {
        (async () => {
            await useFetch<GlobalDateTimeUtil>("GlobalSettings/GetAllSettings").then((r) => {
                dispatch({
                    type: GlobalStateAction.GlobalUtils, GlobalUtils: {
                        enumKey: r.data[1].enumKey,
                        settingName: r.data[1].settingName,
                        settingValue: r.data[1].settingValue
                    }
                });
            })
        })()
    }

    const BlockEmail = () => {
        (async () => {
            let request = {
                "activate": blockEmail
            };
            await usePost<any>("GlobalSettings/BlockEmailService", request);
            const response = await useFetch<IGlobalUtil[]>("GlobalSettings/GetAllSettings");
            setGetGlobalSettings(response.data);
            setUpdateDate(response.data[0].settingValue)
            setUpdDocEmail(response.data[2].settingValue);
            setBeginEmail(response.data[3].settingValue);
            setSettlementEmail(response.data[4].settingValue);
            setNoPaymentEmail(response.data[5].settingValue);
            setUpdFileEmail(response.data[6].settingValue);
            setSupportEmail(response.data[7].settingValue);
            setUatEmail(response.data[8].settingValue);
            setClientConfirmationEmail(response.data[9].settingValue);
            setUnmappedFileEmail(response.data[10].settingValue);
        })()
    }

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.paper} >
                    <Grid item xs={2} >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                            style={{ borderLeft: '10px solid blue' }}
                        >
                            <Tab label="Change Date Format" {...a11yProps(0)} />
                            <Tab label="Document Upload Email" {...a11yProps(1)} />
                            <Tab label="Begin Reorder Email" {...a11yProps(2)} />
                            <Tab label="Settlement Email" {...a11yProps(3)} />
                            <Tab label="Upload File Email" {...a11yProps(4)} />
                            <Tab label="Support Email" {...a11yProps(5)} />
                            <Tab label="Client Confirmation Email" {...a11yProps(6)} />
                            <Tab label="Upload Unmapped File Email " {...a11yProps(7)} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={6} style={{ borderRight: '1px solid gray', marginBottom: '10px', marginTop: '10px' }}>
                        <React.Fragment>
                            <TabPanel value={value} index={0}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '60px', color: 'black' }}>
                                        Global Date format for WebView
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <FormControl variant="outlined" className={classesUI.formControl} style={{ borderBottom: '5px solid blue', borderRadius: '10px', marginLeft: '60px' }}>
                                        <InputLabel> Date </InputLabel>
                                        <Select
                                            id="Gsettings_Date_DropDown"
                                            value={updateDate}
                                            name={GetGlobalSettings[0]?.settingName}
                                            onChange={(event) => { handleChangeDate(event); }}
                                            label="Date"
                                        >
                                            <MenuItem value={"DD/MM/YYYY"}>DD/MM/YYYY</MenuItem>
                                            <MenuItem value={"MM/DD/YYYY"}>MM/DD/YYYY</MenuItem>
                                            <MenuItem value={"YYYY/MM/DD"}>YYYY/MM/DD</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <div>
                                        <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classesUI.button} onClick={updateDateSettings} style={{ marginLeft: '380px' }}>
                                            Update
                                        </Button>
                                    </div>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '60px', color: 'black' }}>
                                        Override Document Email
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '15px', color: 'black', marginTop: '30px' }}>
                                        To :
                                    </Typography>
                                    <TextField label="Document Upload Email" name={GetGlobalSettings[2]?.settingName} value={UpdDocEmail} variant="outlined" className={classesUI.formControl} onChange={(e) => setUpdDocEmail(e.target.value)} style={{ borderBottom: '5px solid blue', borderRadius: '10px' }} />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classesUI.button} onClick={updateDocEmailSettings} style={{ marginLeft: '380px' }}>
                                        Update
                                    </Button>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '60px', color: 'black' }}>
                                        Override Begin Reorder Email
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                        <Typography variant="h6" style={{ marginLeft: '15px', color: 'black', marginTop: '30px' }}>
                                            To :
                                        </Typography>
                                    </Box>
                                    <TextField label="Begin Reorder Email" variant="outlined" name={GetGlobalSettings[3]?.settingName} value={BeginEmail} className={classesUI.formControl} onChange={(e) => setBeginEmail(e.target.value)} style={{ borderBottom: '5px solid blue', borderRadius: '10px' }} />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classesUI.button} onClick={updateBeginReorderEmailSettings} style={{ marginLeft: '380px' }}>
                                        Update
                                    </Button>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '60px', color: 'black' }}>
                                        Override Settlement Request Email
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '15px', color: 'black', marginTop: '30px' }}>
                                        To :
                                    </Typography>
                                    <TextField label="Settlement Request Email" variant="outlined" className={classesUI.formControl} name={GetGlobalSettings[4]?.settingName} value={SettlementEmail} onChange={(e) => setSettlementEmail(e.target.value)} style={{ borderBottom: '5px solid blue', borderRadius: '10px' }} />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Button variant="contained" color="primary" startIcon={<DoneAllIcon />} className={classesUI.button} onClick={updateSettlementEmailSettings} style={{ marginLeft: '380px' }}>
                                        Update
                                    </Button>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '60px', color: 'black' }}>
                                        Override Upload File Email
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '15px', color: 'black', marginTop: '30px' }}>
                                        To :
                                    </Typography>
                                    <TextField label="Upload File Email" variant="outlined" className={classesUI.formControl} name={GetGlobalSettings[6]?.settingName} value={UpdFileEmail} onChange={(e) => setUpdFileEmail(e.target.value)} style={{ borderBottom: '5px solid blue', borderRadius: '10px' }} />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classesUI.button} onClick={updateFileEmailSettings} style={{ marginLeft: '380px' }}>
                                        Update
                                    </Button>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '60px', color: 'black' }}>
                                        Override Upload Support Email
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '15px', color: 'black', marginTop: '30px' }}>
                                        To :
                                    </Typography>
                                    <TextField label="Upload Support Email" variant="outlined" className={classesUI.formControl} name="OverrideSupportEmail" value={SupportEmail} onChange={(e) => setSupportEmail(e.target.value)} style={{ borderBottom: '5px solid blue', borderRadius: '10px' }} />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classesUI.button} onClick={updateSupportEmailSettings} style={{ marginLeft: '380px' }}>
                                        Update
                                    </Button>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '60px', color: 'black' }}>
                                        Override Client Confirmation Email
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '15px', color: 'black', marginTop: '30px' }}>
                                        To :
                                    </Typography>
                                    <TextField label="Client Confirmation Email" variant="outlined" className={classesUI.formControl} name="OverrideClientConfirmationEmail" value={ClientConfirmationEmail} onChange={(e) => setClientConfirmationEmail(e.target.value)} style={{ borderBottom: '5px solid blue', borderRadius: '10px' }} />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classesUI.button} onClick={updateClientConfirmationEmail} style={{ marginLeft: '380px' }}>
                                        Update
                                    </Button>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={7}>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '60px', color: 'black' }}>
                                        Override Upload Unmapped File Email
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Typography variant="h6" style={{ marginLeft: '15px', color: 'black', marginTop: '30px' }}>
                                        To :
                                    </Typography>
                                    <TextField label="Upload Unmapped File Email" variant="outlined" className={classesUI.formControl} name="OverrideUnmappedFileEmail" value={UnmappedFileEmail} onChange={(e) => setUnmappedFileEmail(e.target.value)} style={{ borderBottom: '5px solid blue', borderRadius: '10px' }} />
                                </Box>
                                <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                                    <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classesUI.button} onClick={updateUnmappedEmail} style={{ marginLeft: '380px' }}>
                                        Update
                                    </Button>
                                </Box>
                            </TabPanel>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={4}>.
                        <Typography style={{ color: 'black' }}><b>BLOCK EMAIL TO CUSTOMERS</b></Typography>
                        <Box display="flex" alignContent="flex-start" justifyContent="flex-start" style={{ marginLeft: '50px' }} >
                            <FormControlLabel
                                id="GS_UAT_MODE_Checkbox"
                                control={<Checkbox
                                    checked={blockEmail}
                                    value={GetGlobalSettings[8]?.is_Active}
                                    onChange={handleCheckboxChange}
                                    name="checked"
                                    color="primary" />}
                                label="Activate"
                            />
                        </Box>
                        <TextField label="Block With Temporary Test Email-ID" variant="outlined" className={classesUI.devMode} name="OverrideUploadFileToEmail" value={UatEmail} onChange={(e) => setUatEmail(e.target.value)} style={{ borderBottom: '5px solid blue', borderRadius: '10px' }} />
                        <div>
                            <Button variant="contained" color="primary" startIcon={<DoneAllIcon />} className={classesUI.button} onClick={updateUATEmail}>
                                Update
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={isCreate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={UpdateMessage}>
                <Alert onClose={UpdateMessage} severity="success" style={{ fontSize: 16 }}>
                    Settings Updated Successfully.
                </Alert>
            </Snackbar>
            <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={isError} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={ErrorMessage}>
                <Alert onClose={ErrorMessage} severity="error" style={{ fontSize: 16 }}>
                    Error Occurred
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default GSettings
