import * as React from 'react';
import RollClientDetails from './RollClientDetail';
import { IDebtor } from '../../models/Roll/IDebtor';
import { Backdrop, Button, Collapse, IconButton, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { columns } from '../../models/Roll/RollConfig';
import { useEffect, useState } from 'react';
import ReplayIcon from "@material-ui/icons/Replay";
import { useDelete, useFetch, usePost } from '../../utils/apiHelper';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { IPlacedAccountContract } from '../../models/Inquiry/IPlacedAccountContract';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import * as moment from "moment";
import { extendMoment } from "moment-range";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import roll from '../../images/buttonIcons/roll.svg'
import PuffLoader from 'react-spinners/PuffLoader';

const useRowStyles = makeStyles((theme) => ({
    root: {
        fontSize: '10px',
        textOverflow: 'ellipsis',
    },
    rootHidden: {
        display: "none"
    },
    button: {
        width: 100,
        borderRadius: 20,
    },
    TextHighlight: {
        color: 'black',
        fontWeight: 'bold',
        background: "yellow",
        fontSize: '16px',
    },
    TextNormal: {
        color: 'black',
        fontSize: 14
    },
    removeStyle: {
        color: 'red',
        fontSize: '14px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}))

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}
export interface RollMessageState extends SnackbarOrigin {
    open: boolean;
}

const RollExpandableRow: React.FC<{ row: IDebtor, selected: boolean, onClick: (accountId: string) => void, onDelete: () => void, onRollRemove: () => void, onUndoCloseRemove: () => void, onEroll: () => void, selectedDBTNumber: string, searchWord: string }> = (props) => {
    const { row, searchWord } = props;
    const classes = useRowStyles();
    const { state, dispatch } = useGlobalState();
    const [clicked, setClicked] = useState(false);
    const [showUndoButton, setShowUndoButton] = useState(false);
    const [textColor, setTextColor] = useState(row.removed ? '#FF0000' : 'black');
    const [isDelete, setIsDelete] = useState(false);
    const [textLine, setTextLine] = useState(row.removed ? 'line-through' : 'none');
    const [BtnColor, setBtnColor] = useState(row.removed ? '#FF0000' : 'primary');
    const [Message, setMessage] = useState(false);
    const UserName = state.userAccessContext?.id;
    const StatusDate = moment.utc(row.dbT_STATUS_DATE).format(state.GlobalUtils?.settingValue);
    const { range } = extendMoment(moment);
    const start = row.dbT_REFERRAL_DATE;
    const end = Date.now();
    const timeRange = range(moment.utc(start), moment.utc(end));
    const RefferalDate = timeRange.diff('days');
    const [RollUndo, setRollUndo] = useState(false);
    const [RemoveAccount, setRemoveAccount] = useState(false);
    const [RemoveUndo, setRemoveUndo] = useState(false);
    const [isActiveStatus, setGetActiveStatus] = useState(false);
    const prevCount = usePrevious(props.selectedDBTNumber);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const [ProgressBar, setShowProgressBar] = useState(false);

    const handleClose = () => {
        setMessage(false);
    };

    const handleChangeTextColor = () => {
        setTextColor(textColor === 'black' ? '#FF0000' : 'black');
        setTextLine(textLine === 'none' ? 'line-through' : 'none');
        setBtnColor(BtnColor === 'primary' ? '#FF0000' : 'primary');
    }

    const [AdvanceToContengency, setAdvanceToContengency] = useState(row.rolled);
    const [RollRemoved, setRollRemoved] = useState(row.removed);

    function OnClickAdvanceToContengency() {
        setIsDelete(!isDelete);
        Roll();
    }

    function OnClickRowDelete() {
        handleChangeTextColor();
        setIsDelete(!isDelete);
        setClicked(!clicked);
        RollDeleteRow();
    }

    const Roll = () => {
        (async () => {
            setShowProgressBar(true);
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "dbt_No": row.dbT_NO,
                    "dbt_Client": row.dbT_CLIENT,
                    "user_Id": UserName
                };
                let loadHistory = {
                    "updatedBy": UserName,
                    "dbt_No": row.dbT_NO,
                    "operationType": 3,//Roll
                    "transactionText": "Debtor rolled manually"
                };
                const response = await usePost<IPlacedAccountContract[]>('RolledPlacement', request, state.user?.userToken ?? "")
                setAdvanceToContengency(true);
                setShowUndoButton(true);
                props.onEroll();
                const TransHistory = await usePost("Log/TransactionHistoy", loadHistory)
                setShowProgressBar(false);
                setMessage(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const RollDeleteRow = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            setShowProgressBar(true);
            try {
                let request = {
                    "dbt_No": row.dbT_NO,
                    "dbt_Client": row.dbT_CLIENT,
                    "user_Id": UserName
                };
                const response = await usePost<IPlacedAccountContract>('RollDeleteRow', request)
                setRollRemoved(true);
                setTextColor('#FF0000');
                setTextLine('line-through');
                setBtnColor('#FF0000');
                props.onRollRemove();
                setShowProgressBar(false);
                setRemoveAccount(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const undoRoll = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            setShowProgressBar(true);
            try {
                const response = await useDelete('RolledPlacement?DBTNumber=' + row.dbT_NO).then((r) => {
                    if (r.status == 400) {
                        dispatch({ type: GlobalStateAction.Error, error: r.statusText })
                    }
                });
                setAdvanceToContengency(false);
                setShowUndoButton(false);
                props.onDelete();
                setShowProgressBar(false);
                setRollUndo(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const undoCloseandRemove = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = { "dbtNumber": row.dbT_NO };
                const response = await usePost<IPlacedAccountContract>('Account/UndoCloseRemove', request).then((r) => {
                    if (r.status == 400) {
                        dispatch({ type: GlobalStateAction.Error, error: r.statusText })
                    }
                });
                setTextLine('none');
                setTextColor('black');
                setRollRemoved(false);
                props.onUndoCloseRemove();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }



    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setRollUndo(false);
        setRemoveAccount(false);
        setRemoveUndo(false);
        setMessage(false);
    };

    const setYieldLock = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const response = await useFetch<any>(`InquiryYieldLock?debtorNumber=${row.dbT_NO}&userId=${state.userAccessContext?.id.toString()}`);
                setGetActiveStatus(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(row.dbT_NO);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [props.selectedDBTNumber])

    const setYieldRelease = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let YieldRelease = {
                    "debtorNumber": GetDebtorNo,
                    "userId": state.userAccessContext?.id
                }
                await usePost("InquiryReleaseLock", YieldRelease);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={100} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={Message} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={closeSnackbar} severity="success">
                    You will receive an acknowledgement report for your rolled accounts within 24-48- hours.
                </Alert>
            </Snackbar>
            <TableRow className={classes.root} hover key={row.dbT_NO} onClick={(_) => { (row.dbT_NO !== props.selectedDBTNumber ? setYieldRelease() : setGetDebtorNo("")); (props.selected === true ? setYieldRelease() : setYieldLock()); }}>
                <TableCell scope="row" onClick={(_) => { props.onClick(row.dbT_NO); }} style={{ color: textColor, textDecorationLine: textLine }} align='left'>
                    <span className={`${searchWord.toUpperCase() === row.dbT_CLIENT && RollRemoved ? classes.removeStyle : searchWord.toUpperCase() === "" ? classes.TextNormal : row.dbT_CLIENT.includes(searchWord.toUpperCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_CLIENT}
                    </span>
                </TableCell>
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='center'>
                    <span className={`${searchWord === "" && RollRemoved ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.dbT_NO.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_NO}
                    </span>
                </TableCell>
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='center'>
                    <span className={`${searchWord === "" && RollRemoved ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.dbT_CLNT_ACNT1.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_CLNT_ACNT1}
                    </span>
                </TableCell>
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='left'>
                    <span className={`${searchWord === "" && RollRemoved ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.dbT_NAME.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.dbT_NAME}
                    </span>
                </TableCell>
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='center'>
                    <span className={`${searchWord === "" && RollRemoved ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.deM_PHONE1?.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row?.deM_PHONE1 == undefined ? '' : row.deM_PHONE1}
                    </span>
                </TableCell>
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine, width: '150px' }} align='center'>
                    {RefferalDate} days
                </TableCell>
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='center'>
                    ${row.dbT_REFERRAL_AMT.toFixed(2)}
                </TableCell>
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='center'>
                    <span className={`${searchWord === row.dbT_PRINCIPAL_DUE.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row.dbT_PRINCIPAL_DUE.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='left'>
                    <span className={`${searchWord === "" && RollRemoved ? classes.removeStyle : searchWord === "" ? classes.TextNormal : row.stS_DESC.includes(searchWord) ? classes.TextHighlight : classes.TextNormal}`}>
                        {row.stS_DESC.replace('|', " ")}
                    </span>
                </TableCell >
                <TableCell onClick={(_) => props.onClick(row.dbT_NO)} style={{ color: textColor, textDecorationLine: textLine }} align='center'>
                    <span className={`${searchWord === moment.utc(row.dbT_STATUS_DATE).format(state.GlobalUtils?.settingValue) ? classes.TextHighlight : ""}`}>
                        {StatusDate}
                    </span>
                </TableCell >
                <TableCell align='left'><Button startIcon={<img src={roll} alt="Roll account icon for button" width="25px" height="25px" />} id="Roll_btn" variant="contained" color="primary" className={classes.button} onClick={() => { OnClickAdvanceToContengency(); }} disabled={AdvanceToContengency || RollRemoved}>{RollRemoved ? "Removed" : AdvanceToContengency ? "Rolled" : "Roll"}</Button>
                </TableCell>
                <TableCell>{(AdvanceToContengency && !RollRemoved) && (
                    <IconButton id="Roll_Undo_btn" color="primary" aria-label="add to shopping cart"
                        onClick={(_) => {
                            setIsDelete(!isDelete);
                            undoRoll();
                            setAdvanceToContengency(false);
                        }}
                        disabled={state?.busy}
                    >
                        <ReplayIcon />
                    </IconButton>
                )}
                    {(RollRemoved) && (
                        <IconButton id="RollUndoRemove_btn" color="primary" aria-label="add to shopping cart"
                            onClick={(_) => {
                                setIsDelete(!isDelete);
                                setTextLine('none');
                                setTextColor('black');
                                undoRoll();
                                setRollRemoved(false);
                                setRemoveUndo(true);
                            }}
                            disabled={state?.busy}
                        >
                            <ReplayIcon />
                        </IconButton>
                    )}</TableCell>
                <TableCell> <IconButton id="RollRemove_btn" onClick={(_) => { OnClickRowDelete(); }} disabled={RollRemoved || AdvanceToContengency} ><DeleteIcon style={{ color: 'red' }} /></IconButton></TableCell>
            </TableRow >
            <TableRow className={props.selected ? classes.root : classes.rootHidden}>
                <TableCell scope="row" colSpan={columns.length}>
                    <Collapse in={props.selected} timeout="auto" unmountOnExit >
                        <RollClientDetails DebtorDetails={row} onRollRemove={RollDeleteRow} onUndoCloseRemove={undoCloseandRemove} onEroll={Roll} onDelete={undoRoll} isBlocked={isActiveStatus} onCheck={setYieldLock} />
                    </Collapse>
                </TableCell>
            </TableRow>
            <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={RollUndo} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" style={{ fontSize: 16 }}>
                    Rolled Account has been undone successfully.
                </Alert>
            </Snackbar>
            <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={RemoveUndo} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
                    Removed Account has been undone successfully.
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default RollExpandableRow