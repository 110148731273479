import { css } from "@emotion/react";
import { AppBar, Box, Grid, IconButton, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import Autorenew from '@mui/icons-material/Autorenew';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import lock from "../../../images/inquiry/lock.svg";
import Activelock from "../../../images/inquiry/unlocked.svg";
import { IGetRolledPlacement } from '../../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccountContract } from '../../../models/Inquiry/IPlacedAccountContract';
import { IClosedLetterDetails } from '../../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../../models/Inquiry/LetterDetails/ILetterDetails';
import { ITransactionHistory } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IDebtor } from '../../../models/NotificationCenter/IDebtor';
import { IHideForAllUsers } from '../../../models/NotificationCenter/IHideForAllUsers';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import AccountDetails from './DvAccountDetails';
import AccountHistory from './DvAccountHistory';
import TransactionAuditHistory from './DvTransactionAuditHistory';

interface TabPanelProps {
    children?: React.ReactNode,
    index: any,
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}  >
            {value === index && (
                <Box p={5}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        marginRight: "2px",
        marginTop: "0%",
        width: "15%",
        boxShadow: "1px 3px 1px #9E9E9E",
        height: '1px'
    },
    root: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px",
        flexGrow: 1,
    },
    iconLabelWrapper: {
        flexDirection: "row"
    },
    labelContainer: {
        width: "auto",
        padding: 0
    },
    validationStyle: {
        border: 'red',
    },
    image1: {
        width: '40px',
        height: '40px',
    },
    image2: {
        width: '40px',
        height: '35px',
    },
    rotateIcon: {
        animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    AwaitUsers: {
        backgroundColor: 'lightgray'
    },
    indicator: {
        top: "50px",
        backgroundColor: '#C51230',
        padding: "2px",
        border: "10%"
    },
    appbarStyle:{
        borderRadius: 10,
        border: '1px black solid',
        maxHeight: 'auto',
        minHeight: 350,
    }
}))

const InquiryDetail: React.FC<{ inquiryDetail: IDebtor, disable?: IGetRolledPlacement, isBlocked: boolean, onCheck: () => void }> = (props) => {
    const { inquiryDetail, disable, isBlocked } = props
    const { state, dispatch } = useGlobalState();
    const [value, setValue] = React.useState(0);
    const classes = useStyles();

    const [closedLetterdetails, setclosedLetterdetails] = useState<IClosedLetterDetails[]>([]);
    const [Transaction, setTransaction] = useState<ITransactionHistory[]>([]);
    const [letterDetails, setLetterDetails] = useState<ILetterDetails[]>([]);
    const [isClosed, setisClosed] = useState<IGetRolledPlacement[]>([]);
    const [history, setHistory] = useState<ITransactionHistory[]>([]);

    const [checkData, setCheckData] = React.useState(false);
    const [spin, setSpin] = React.useState(false);
    const [mapped, setMapped] = useState(false);

    const UserName = state.userAccessContext?.id;
    const [ProgressBar, setshowProgressBar] = useState(true);

    /* Spinner */
    const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const transaction = await useFetch<ITransactionHistory[]>(`GetTransactionHistory?DBTNumber=${inquiryDetail.dbT_NO}`)
                setTransaction(transaction.data);
                setCheckData(transaction.data.length > 0 ? true : false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const history = await useFetch<ITransactionHistory[]>(`DebtorHistory?DBTNumber=${inquiryDetail.dbT_NO}`)
                setHistory(history.data);
                setMapped(history.data.length > 0 ? true : false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    useEffect(() => {
        if (isClosed.length <= 0) {
            getRollPlacementAsync();
        }
    }, [isClosed]);

    async function getRollPlacementAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            let request = {
                "dbt_No": inquiryDetail.dbT_NO,
                "dbt_Client": inquiryDetail.dbT_CLIENT,
                "user_Id": UserName
            };
            const isEnable = await usePost<IPlacedAccountContract[]>('RollCreatePlacement', request);
            const response = await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`);
            setshowProgressBar(true);
            setisClosed(response.data);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
            setshowProgressBar(false);
        }
    }

    useEffect(() => {
        if (closedLetterdetails.length <= 0) {
            getClosedLetterDetails();
        }
    }, [closedLetterdetails]);

    async function getClosedLetterDetails() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            const response = await useFetch<IClosedLetterDetails[]>(`ClosedLetterDetailsGet?DBTNumber=${inquiryDetail.dbT_NO.toString()}`);
            setclosedLetterdetails(response.data);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    const DisableButton = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`);
                setisClosed(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = { "debtorId": inquiryDetail.dbT_NO };
                const response = await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request);
                const data = response.data.letterDetails.map((a) => {
                    return { ...a, clT_NAME_1: a.clT_NAME_1 }
                })
                setLetterDetails(data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": inquiryDetail.dbT_NO,
                    "typeId": 5
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <Box>
            <AppBar position="static" color="transparent" className={classes.appbarStyle}>
                <Tabs id="I_Tab" style={{ marginTop: "4px", marginLeft: "10px" }}
                    value={value} onChange={handleChange}
                    classes={{ indicator: classes.indicator }} aria-label="simple tabs example">
                    <Tab id="I_AccountDetails" className={`${classes.tabs} ${classes.iconLabelWrapper}`} label="Account Details" style={{ background: "#800080", color: "white", borderRadius: 5 }} />
                    <Tab id="I_AccountHistory" className={classes.tabs} label="Account History" style={{ background: "#800080", color: "white", borderBottom: "none", borderRadius: 5 }} />
                    <Tab id="I_TransactionAuditHistory" className={classes.tabs} label="Transaction Audit History" style={{ background: "#800080", color: 'white', borderRadius: 5 }} />
                    <Grid item xs={7} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        {isBlocked ?
                            <>
                                <IconButton id="Active_Status" >
                                    <img src={lock} alt="Lock image for account Details" className={classes.image2} />
                                </IconButton>
                                <IconButton id="Active_Status" onClick={(_) => { props.onCheck(); refreshCanvas(); }}>
                                    <Autorenew fontSize="large" className={`${spin ? classes.rotateIcon : ""}`} />
                                </IconButton>
                            </>
                            :
                            <IconButton id="Active_Status" >
                                <img src={Activelock} alt="UnLock image for account Details" className={classes.image1} />
                            </IconButton>
                        }
                    </Grid>
                </Tabs>
                <TabPanel value={value} index={0}>
                    {ProgressBar ? <PuffLoader css={override} size={100} color={"blue"} speedMultiplier={1.5} /> :
                        isClosed.map((placedAccounts) => {
                            return (
                                <React.Fragment>
                                    {closedLetterdetails.map((letterDetails) => {
                                        return (
                                            <AccountDetails debtors={inquiryDetail} letterDetails={letterDetails} disablee={disable} isEnable={placedAccounts} onEroll={DisableButton} onHideAll={hidenotificationForAllUsers} BlockAwaitUsers={isBlocked} />
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    < AccountHistory debtors={inquiryDetail} histories={history} mapped={mapped} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TransactionAuditHistory debtors={inquiryDetail} transactions={Transaction} mapped={checkData} />
                </TabPanel>
            </AppBar>
        </Box >
    )
}

export default InquiryDetail
