import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import massuploadimg from '../../../images/admin/massupload.svg';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const useStyles = makeStyles((theme) => ({
    topLeftBox: {
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    topRightBox: {
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    button: {
        background: "#007FFF",
        color: "white",
        marginTop: "8px",
        borderRadius: "20px"
    },
    yesbutton: {
        backgroundColor: 'green',
        borderRadius: '20px',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
            color: 'white',
        }
    },
    nobutton: {
        background: "red",
        borderRadius: "20px",
        color: 'white',
        '&:hover': {
            backgroundColor: 'red',
            color: 'white',
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    IconButtonripple: {
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    image: {
        width: '80px',
        height: '80px',
    },
    card1: {
        display: 'block',
        width: '6.5cm',
        height: '4.5cm',
        marginLeft: '30px',
        marginTop: '1cm',
        '&:hover': {
            color: 'black',
            backgroundColor: 'white',
            borderRadius: '10px',
            transform: 'scale(1.1)',
            cursor: 'pointer',
            border: '2px blue solid',
            boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
        },
    },
    text: {
        color: "black",
        textAlign: "center",
        marginTop: '5px',
        fontWeight: 'bold',

    }
}))

const MassUpload = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;
    const [isExists, setisExists] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<any>("File/BlobFileExists");
                setisExists(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }

        })()
    }, [isExists])

    const onSubmit = () => {
        (async () => {
            try {
                let request = {
                    "file_id": "",
                    "file_name": "",
                    "file_type": "",
                    "file_size": 0,
                    "file_content": "",
                    "rid": "",
                    "userid": UserName,
                    "ClientId": "",
                    "bloburi": "",
                    "ContainerName": "debtor-file-upload"
                }
                await usePost<any>("MassFileInsert", request).then((r) => {
                    if (r.status === 202) {
                        alert('Files are uploaded successFully');
                    }
                    else {
                        alert("Error while uploading files, verify that all files are valid.");
                    }
                })
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    return (
        <React.Fragment>
            {isExists ?
                <React.Fragment>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <Box className={classes.card1} onClick={handleClickOpen}>
                            <IconButton id="Admin_Page_NotificationRollBack_Button" aria-label="notification_rollback" onClick={handleClickOpen} className={classes.IconButtonripple}>
                                <img src={massuploadimg} alt="Admin_Mass_Document_Upload" className={classes.image} />
                            </IconButton>
                            <Box>
                                <Typography variant="h6" className={classes.text} onClick={handleClickOpen}>
                                    Mass Document Upload
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </React.Fragment>
                :
                null
            }
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth={'sm'}
                PaperProps={{ style: { borderRadius: 30 } }}
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MASS DOCUMENT UPLOAD
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography variant="h5" style={{ color: 'black' }}>
                            Are you sure, you want to upload the files ?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="MU_ok_btn" variant="contained" className={classes.yesbutton} onClick={(_) => { handleClose(); onSubmit(); }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="MU_Cancel_Btn" variant="contained" autoFocus className={classes.nobutton} onClick={handleClose} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default MassUpload
