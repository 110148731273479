import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './App.css';
import { loginRequest } from './auth/authConfig';
import Content from "./components/content";
import Header from "./components/Header";
import Authorize from './images/header/401.svg';
import { AccessContext } from "./models/accesscontrol/AccessContext";
import { IWidgetInfo } from "./models/accesscontrol/IWidgetInfo";
import { WidgetMap } from "./models/accesscontrol/WidgetMap";
import Login from "./pages/Login";
import { GlobalStateAction, useGlobalState } from "./store/GlobalStore";
import { useFetch, usePost } from './utils/apiHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    image: {
      width: '95vw',
      height: '100vh',
      alignItems: "center",
    }
  }),
);

function App() {
  const { state, dispatch } = useGlobalState();
  const { accounts, instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isValidUser, setValidateUser] = useState<boolean>(null);
  const [isDisable, setIsDisable] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsDisable(inProgress == 'login'
      || inProgress == 'handleRedirect'
      || inProgress == 'startup')
  }, [inProgress]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      let account = accounts[0];
      instance.ssoSilent({ ...loginRequest, loginHint: account.username }).then(a => {
        dispatch({
          type: GlobalStateAction.LoggedIn, user: {
            id: account.homeAccountId ?? "",
            name: account.username ?? "",
            profileImage: "./images/header-logo.png",
            email: account.username ?? "",
            userToken: a.accessToken,
            homeAccountId: account.homeAccountId ?? "",
            localAccountId: account.localAccountId ?? "",
            objectId: a.uniqueId
          }
        });

        let UserValidate = { "userName": a.account.username, "objectId": a.uniqueId }
        usePost<any>("User/ValidateUserInvitation", UserValidate).then((userInvitation) => {
          setValidateUser(userInvitation.data);
        });

        let requestGuid = { "objectId": a.uniqueId }
        usePost<AccessContext>("User/GetUserByObjectId", requestGuid).then((userObj) => {
          if (userObj.status === 200) {
            useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userObj.data.id).then((clientsWidgets) => {
              for (let index = 1; index <= WidgetMap.size; index++) {
                const value = WidgetMap.get(index);
                if (value && clientsWidgets.data.filter(w => w.widgetId === index).length === 1) {
                  userObj.data[value] = true;
                }
                dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userObj.data })
              };
            });

            let request = { "userName": a.account.username, "loginDateTime": new Date() }
            usePost<any>("UserLoginHistory/InsertIAMLoginHistory", request);
          }
        }).catch((err) => {
          if (err.response === undefined) {
            let request = { "userName": a.account.username, "objectId": a.uniqueId }
            usePost<any>("User/ValidateUserInvitation", request).then((userInvitation) => {
              setValidateUser(userInvitation.data)
            });
          }
        });
      })
    }
  }, [isAuthenticated])

  if (isAuthenticated) {
    if (isValidUser == null) {
      return (<Login isLoading={isDisable} isAuth={true} />)
    }
    else if (isValidUser === false) {
      return (
        <div className={classes.root}>
          <img src={Authorize} alt="401_Authorize" width="auto" height="auto" className={classes.image} />
        </div>
      )
    }
    else if (isValidUser == true) {
      return (
        <Grid container className="App">
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Content />
          </Grid>
        </Grid>)
    }
  }
  else {
    return (<Login isLoading={isDisable} />)
  }
}

export default App