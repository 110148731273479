import { Box, Container, Grid, IconButton, Snackbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import moment from 'moment';
import React, { useState } from 'react';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { INotificationCenter } from "../../models/NotificationCenter/INotificationCenter";
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import LightTooltip from '../GlobalStyles/LightTooltip';

const useStyles = makeStyles((theme) => ({
    topRightBox: {
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    box: {
        display: "flex",
    },
    button: {
        borderRadius: '20px'
    },
    innerbox: {
        marginTop: '60px'
    },

    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    button1: {
        paddingBottom: "10px",
        paddingTop: "10px",
        width: 120,
        borderRadius: 10
    },
    snackbar: {
        width: "120%",
        marginTop: '-0.5cm',
    },
}))

const BackupDocumentDialog: React.FC<{ BackUpInfo: INotificationCenter, onHideAll: () => void }> = (props) => {
    const { BackUpInfo } = props
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;
    const [selectedFile, setSelectedFile] = useState<File>();
    const fileUpload = React.useRef(null);
    const NameofUser = state.userAccessContext?.userName;
    const [snackbarOpen, setSnackBarOpen] = useState(false);

    const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = () => {
        if (selectedFile.type === 'image/jpeg' || selectedFile.type === 'application/pdf') {
            (async () => {
                const formData = new FormData();
                let dateTime = moment(new Date()).format(state.GlobalUtils?.settingValue);
                const currentDate = moment(dateTime).format('MMDDyy');
                const fileExtension = selectedFile.name.split('.').pop();
                const fileName = `${props.BackUpInfo.extraData_1}-${currentDate}${props.BackUpInfo.clT_Code}-BU.${fileExtension}`;
                formData.append("FormFile", selectedFile);
                formData.append("ClientId", props.BackUpInfo.clT_Code);
                formData.append("file_name", fileName);
                formData.append("userid", UserName.toString());
                formData.append("rid", props.BackUpInfo.extraData_1)
                formData.append("upload_date", dateTime);
                formData.append("file_size", "100");
                formData.append("ContainerName", "clientbackup");
                try {
                    await usePost<any>("File/BackUpDocument", formData)
                    sendDocumentEmail();
                    setSnackBarOpen(true);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex });
                    dispatch({ type: GlobalStateAction.Idle });
                }
                finally {
                    hidenotificationForAllUsers();
                    dispatch({ type: GlobalStateAction.Idle });
                }
            })()
        }
        else {
            alert('Invalid File Type. Please select a PDF or JPG file.')
        }
    }

    const sendDocumentEmail = () => {
        (async () => {
            try {
                let sendEmail = {
                    "debtorNumber": BackUpInfo.extraData_1,
                    "clientId": BackUpInfo.clT_Name,
                    "clientNo": BackUpInfo.extraData_3,
                    "userName": NameofUser,
                    "fileName": selectedFile.name
                }
                await usePost<any>("SendDocumentEmail", sendEmail);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        event.target.files && setSelectedFile(event.target.files[0])

    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": BackUpInfo.userNotificationId,
                    "userid": UserName,
                    "debtorid": BackUpInfo.extraData_1,
                    "typeId": 6
                }
                await usePost<IHideForAllUsers>("HideForAllUsers", request);
                props.onHideAll();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <LightTooltip title="Open">
                <IconButton color='primary' size="medium" onClick={handleClickOpen} className={`${classes.topRightBox} ${classes.box}`}>
                    <NoteAddIcon style={{ fontSize: 40 }} />
                </IconButton>
            </LightTooltip>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth={'md'}
                PaperProps={{ style: { borderRadius: 15 } }}
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        BACKUP DOCUMENTATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Container>
                            <Grid container>
                                <Grid item xs={5} style={{ marginLeft: "0.5cm" }}>
                                    <Typography style={{ textAlign: "left", color: 'black' }} variant="subtitle2" gutterBottom>
                                        {moment(BackUpInfo.createdDate).format(state.GlobalUtils?.settingValue)} Backup Documentation: Client Acct #{BackUpInfo.extraData_3.substring(0, BackUpInfo.extraData_3.lastIndexOf("+"))} / Agency Acct #{BackUpInfo.extraData_1}
                                        <Typography style={{ textAlign: "left", color: 'black' }} variant="subtitle2" gutterBottom>
                                            Client Code: {BackUpInfo.clT_Code} = {BackUpInfo.clT_Name} - {BackUpInfo.clT_Name_2}
                                        </Typography>
                                        <Typography style={{ textAlign: "left", color: 'black' }} variant="subtitle2" gutterBottom>
                                            Type: '{BackUpInfo.extraData_2}'
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Box p={0} width="100%" flexShrink={0} bgcolor="white" style={{ marginLeft: '4cm' }} >
                                        <Box className={classes.innerbox}>
                                            <Typography variant="subtitle1" gutterBottom style={{ color: 'black' }} >
                                                {selectedFile ? selectedFile.name : ""}
                                            </Typography>
                                            <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                                                <Button style={{ borderRadius: 20, marginRight: "1cm", background: "#007FFF", color: "white" }} component="label"
                                                    variant="contained" startIcon={<CloudUploadIcon />}>
                                                    Choose File
                                                    <input
                                                        id="NF_SelectFile"
                                                        onChange={changeHandler}
                                                        name="file"
                                                        ref={fileUpload}
                                                        hidden
                                                        multiple type="file"
                                                        accept="image/jpeg,image/jpg,application/pdf"
                                                    />
                                                </Button>
                                                <Button variant="contained" color="primary" className={classes.button} onClick={(_) => { onSubmit(); setOpen(false); }} disabled={!selectedFile}>
                                                    Submit
                                                </Button>
                                            </form>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContentText>
                    <Typography variant="subtitle2" style={{ color: "red", marginLeft: '40px' }}> <b>Please Upload .jpg / .pdf files only</b></Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary" autoFocus
                        style={{ background: 'red', marginBottom: '0.2cm', marginRight: '10px', color: "white" }} className={classes.button1}>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} className={classes.snackbar} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    Filter Successfully Triggered!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default BackupDocumentDialog
