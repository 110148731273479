import React, { useState } from 'react'
import { Box, Typography, IconButton, makeStyles } from '@material-ui/core'
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore'
import SettingsBackupRestoreTwoToneIcon from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import { useFetch, usePost } from '../../utils/apiHelper';
import { IPlacedAccount } from '../../models/Inquiry/IPlacedAccount';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        border: "0.5px solid black",
        marginLeft: "0cm",
        borderRadius: 8,
        width: "27.6cm",
        height: "auto",
        marginTop: "0px"
    },
    snackbar: {
        color: 'black',
        marginTop: '-0.5cm',
    },
    snackbarfont: {
        fontSize: 16,
    }
}))

const Submit: React.FC<{ clientId: string, dbtNo: string, onSubmit: () => void, placedAccounts: IPlacedAccount[], callGetPlacement: any, BlockAwaitAccess: boolean }> = (props) => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState()
    const [count, setCount] = useState(0);
    const [undoSubmit, setundoSubmit] = React.useState(false);

    const undoAdjustment = (adjustmentAmount: number, adjustmentType: string) => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "Dbt_No": props.dbtNo,
                    "AdjustmentAmount": adjustmentAmount,
                    "AdjustmentType": adjustmentType
                }
                const response = await usePost<any>("Account/UndoAdjustment", request);
                await useFetch<any>("Account/UndoPaymentAmount");
                props.onSubmit();
                setundoSubmit(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                props.callGetPlacement();
                setCount(count + 1);
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setundoSubmit(false);
    };

    if (props.placedAccounts.find(x => x.prpcl_Pay_Amt != null || x.prcpl_Adj_Amt != null || x.fee_Pay_Amt != null || x.fee_Adj_Amt != null)) {
        return (
            <Box className={classes.root} margin={4}>
                <div style={{ marginLeft: "5px" }}>
                    <Typography variant="overline" display="block" gutterBottom>
                        Pending...
                    </Typography>
                    {props.placedAccounts.reverse().map(p => {
                        return (
                            <span>
                                {Math.abs(p.prpcl_Pay_Amt) > 0 ? <div>Principal Payment: {p.prpcl_Pay_Amt} <IconButton id="R_Undo_PrincipalPayment" disabled={props.BlockAwaitAccess} onClick={() => { setundoSubmit(true); undoAdjustment(p.prpcl_Pay_Amt, "Principal Payment") }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                {Math.abs(p.prcpl_Adj_Amt) > 0 ? <div>Principal Adjustment: {p.prcpl_Adj_Amt}<IconButton id="R_Undo_PrincipalAdjustment" disabled={props.BlockAwaitAccess} onClick={() => { setundoSubmit(true); undoAdjustment(p.prcpl_Adj_Amt, "Principal Adjustment") }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                {Math.abs(p.fee_Pay_Amt) > 0 ? <div>Late Fee Payment: {p.fee_Pay_Amt}<IconButton id="R_Undo_FeePayment" disabled={props.BlockAwaitAccess} onClick={() => { setundoSubmit(true); undoAdjustment(p.fee_Pay_Amt, "Fee Payment") }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                {Math.abs(p.fee_Adj_Amt) > 0 ? <div>Late Fee Adjustment: {p.fee_Adj_Amt}<IconButton id="R_Undo_FeeAdjustment" disabled={props.BlockAwaitAccess} onClick={() => { setundoSubmit(true); undoAdjustment(p.fee_Adj_Amt, "Fee Adjustment") }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                            </span>
                        );
                    })}
                </div>
                <Snackbar className={classes.snackbar} open={undoSubmit} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" className={classes.snackbarfont}>
                        Undo Payment Successfully!
                    </Alert>
                </Snackbar>
            </Box>
        )
    }
    else {
        return (null)
    }
}

export default Submit