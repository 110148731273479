import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import MassUpload from "../components/GlobalAdmin/MassDocUpload/MassUpload";
import AdfEnvironment from '../images/ADF/AdfEnvironment.svg';
import Adf from '../images/admin/Adf.svg';
import Authorization from '../images/admin/authorization.svg';
import Backend from '../images/admin/backend.svg';
import Faq from '../images/admin/faq.svg';
import Fileaccess from '../images/admin/fileaccess.svg';
import NewFileUpload from '../images/admin/fileupload.svg';
import Message from '../images/admin/global.svg';
import Notificationmessage from '../images/admin/messagenotification.svg';
import NotificationRollback from '../images/admin/notification.svg';
import Placement from '../images/admin/placement.svg';
import Settings from '../images/admin/settings.svg';
import UpdateHistory from '../images/admin/updatehistory.svg';
import LoginHistory from '../images/admin/userloginhistory.svg';
import User from '../images/admin/users.svg';
import { useGlobalState } from '../store/GlobalStore';

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    marginTop: '2cm',
    alignItems: "center",
    borderRadius: 30,
  },
  text: {
    color: "black",
    textAlign: "center",
    marginTop: '5px',
    fontWeight: 'bold',
  },
  card1: {
    display: 'block',
    width: '6.5cm',
    height: '4.5cm',
    marginLeft: '30px',
    marginTop: '1cm',
    textDecoration: 'none',
    '&:hover': {
      color: 'black',
      backgroundColor: 'white',
      borderRadius: '10px',
      transform: 'scale(1.1)',
      cursor: 'pointer',
      border: '2px blue solid',
      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
    },
  },
  div: {
    height: '100%',
    width: '100%',
  },
  image: {
    width: '80px',
    height: '80px',
  },
  IconButtonripple: {
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  Tooltip: {
    fontSize: '14px',
  },
  textStyle: {
    float: 'left',
    color: "blue",
    marginTop: '10px',
    marginLeft: '20px'
  }
}));

export default function NativeSelects() {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Grid container spacing={1} alignItems="center" style={{ height: "100%", alignContent: 'center' }} >
          <Grid item xs={12}>
            {state.userAccessContext?.role === 3 ?
              <Typography variant="h4" gutterBottom className={classes.textStyle}>
                <b> SUPER ADMIN</b>
              </Typography>
              :
              <Typography variant="h4" gutterBottom className={classes.textStyle}>
                <b> GLOBAL ADMIN</b>
              </Typography>
            }
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/globalmessage" className={classes.card1}>
              <Box>
                <IconButton id="Admin_Page_GlobalMessage_Button" aria-label="global_message" component={Link} to="/globalmessage" className={classes.IconButtonripple}>
                  <img src={Message} alt="Admin_Global_Message" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    Global Message
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/GAFAQ" className={classes.card1}>
              <Box>
                <IconButton id="Admin_Page_FAQ_Button" aria-label="faq" component={Link} to="/GAFAQ" className={classes.IconButtonripple}>
                  <img src={Faq} alt="Admin_Faq" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    FAQ
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/users" className={classes.card1}>
              <Box>
                <IconButton id="Admin_Page_Users_Button" aria-label="users" component={Link} to="/users" className={classes.IconButtonripple}>
                  <img src={User} alt="Admin_User" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    Users
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/clientplacementoptions" className={classes.card1}>
              <Box>
                <IconButton id="Admin_Page_ClientPlacementOptions_Button" aria-label="client_placement_options" component={Link} to="/clientplacementoptions" className={classes.IconButtonripple}>
                  <img src={Placement} alt="Admin_Client_Placement_Option" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    Client Placement Options
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/userloginhistory" className={classes.card1}>
              <Box>
                <IconButton id="Admin_Page_UserLoginHistory_Button" aria-label="user_login_history" component={Link} to="/userloginhistory" className={classes.IconButtonripple}>
                  <img src={LoginHistory} alt="Admin_User_Login_History" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    User Login History
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/updatehistory" className={classes.card1}>
              <Box>
                <IconButton id="Admin_Page_UpdateHistory_Button" aria-label="update_history" component={Link} to="/updatehistory" className={classes.IconButtonripple}>
                  <img src={UpdateHistory} alt="Admin_Update_History" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    Update History
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/newfileupload" className={classes.card1}>
              <Box>
                <IconButton id="SN_NewFileUpload_btn" aria-label="newfile_upload" component={Link} to="/newfileupload" className={classes.IconButtonripple}>
                  <img src={NewFileUpload} alt="Admin_Notificatio_File_Upload" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    Notification File Upload
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/settlementauthorization" className={classes.card1}>
              <Box>
                <IconButton id="SN_Settlement_btn" aria-label="settlement_authorization" component={Link} to="/settlementauthorization" className={classes.IconButtonripple}>
                  <img src={Authorization} alt="Admin_Settlement_Authorization" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    Settlement Authorization
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/message" className={classes.card1}>
              <Box>
                <IconButton id="SN_Message_btn" aria-label="message" component={Link} to="/message" className={classes.IconButtonripple}>
                  <img src={Notificationmessage} alt="Admin_Notification_Message" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    Notification Message
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2} >
            <Link to="/notificationrollback" className={classes.card1}>
              <Box>
                <IconButton id="Admin_Page_NotificationRollBack_Button" aria-label="notification_rollback" component={Link} to="/notificationrollback" className={classes.IconButtonripple}>
                  <img src={NotificationRollback} alt="Admin_Notification_RollBack" className={classes.image} />
                </IconButton>
                <Box>
                  <Typography variant="h6" className={classes.text}>
                    Notification RollBack
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>

          {state.userAccessContext?.isFileAccess ?
            < Grid item xs={12} sm={6} md={4} lg={2} >
              <Link to="/managefileaccess" className={classes.card1}>
                <Box>
                  <IconButton id="Admin_Page_ManageFileAccess_Button" aria-label="manage_file_access" disabled={!state?.userAccessContext?.isFileAccess} component={Link} to="/managefileaccess" className={classes.IconButtonripple}>
                    <img src={Fileaccess} alt="Admin_Manage_File_Access" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Manage File Access
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            : null
          }

          {state.userAccessContext?.role === 3 ?
            <>
              <Grid item xs={12} sm={6} md={4} lg={2} >
                <Link to="/globalsettings" className={classes.card1}>
                  <Box>
                    <IconButton id="Admin_Page_GlobalSettings_Button" aria-label="global_settings" disabled={!state?.userAccessContext?.isGlobalSettings} component={Link} to="/globalsettings" className={classes.IconButtonripple}>
                      <img src={Settings} alt="Admin_Global_Settings" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        Global Settings
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2} >
                <Link to="/adfpipelinelogs" className={classes.card1}>
                  <Box>
                    <IconButton id="Admin_Page_ADFPipeline_Button" aria-label="adf_logs" disabled={!state?.userAccessContext?.isAdfLogs} component={Link} to="/adfpipelinelogs" className={classes.IconButtonripple}>
                      <img src={Adf} alt="Admin_ADF_Logs" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        ADF Logs
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2} >
                <Link to="/azfunctionlogs" className={classes.card1}>
                  <Box>
                    <IconButton id="Admin_Page_AzFunction_Button" aria-label="azure_function" disabled={!state?.userAccessContext?.isAzureFunc} component={Link} to="/azfunctionlogs" className={classes.IconButtonripple}>
                      <img src={Backend} alt="Admin_Backend Jobs" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        Backend Jobs
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2} >
                <Link to="/adfcontrol" className={classes.card1}>
                  <Box>
                    <IconButton id="Admin_Page_AZEnvironment_Button" aria-label="azure_environment" disabled={!state?.userAccessContext?.isAzureFunc} component={Link} to="/adfcontrol" className={classes.IconButtonripple}>
                      <img src={AdfEnvironment} alt="Admin_ ADF_Environment" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        ADF Environment
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
              <MassUpload />
            </>
            : null
          }
        </Grid>
      </div>
    </React.Fragment >
  );
}