import { Backdrop, Box, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { ClientUserAccessContext } from "../../models/accesscontrol/ClientUserAccessContext";
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { IClientInfo } from '../../models/client/IClientInfo';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    multiSelectRoot: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
      },
    },
    formControl: {
      width: "425px",
      margin: theme.spacing(-5),
      minWidth: 425,
      borderBottom: '3px solid blue',
      borderRadius: '10px',
      marginTop: '20px',
      marginLeft: '17px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      marginLeft: "10px"
    },
    edit: {
      float: 'right',
      marginTop: "0.8cm",
      marginRight: '35px',
      marginBottom: '0.3cm',
      borderRadius: '50px',
      backgroundColor: 'green',
      '&:hover': {
        backgroundColor: 'green',
      }
    },
    close1: {
      float: 'right',
      marginTop: "0.8cm",
      marginBottom: '0.3cm',
      marginRight: '10px',
      borderRadius: '50px',
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'red',
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);


const EditUser: React.FC<{ userRow: IUserInfo, onSave: (userRow: IUserInfo) => void, onRemove: () => void }> = (props) => {
  const { userRow } = props
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const { state, dispatch } = useGlobalState();
  var newUser: ClientUserAccessContext = new ClientUserAccessContext();
  const [localState, setLocalState] = React.useState(newUser);
  const multiselectRef = useRef<Multiselect>();
  const [confirmedClients, setConfirmedClients] = React.useState([]);
  const [selectedClients, setSelectedClients] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [firstnameerror, setFirstNameError] = useState("");
  const [Lastnameerror, setLastNameError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);

  const handleClickOpen = () => {
    dispatch({ type: GlobalStateAction.Busy })
    setOpen(true);
    useFetch<ClientUserAccessContext>("User/GetUser?userId=" + userRow.id).then((r) => {
      var response = r.data;
      setLocalState(response);

      useFetch<IClientInfo[]>("Client/GetClientAdminCode?userId=" + state.userAccessContext?.id).then((c) => {
        setConfirmedClients(c.data);
        setSelectedClients(c.data.filter(p => {
          return response.clientCodes?.filter(r => { return r === p.client_Code }).length > 0
        }));
        dispatch({ type: GlobalStateAction.Idle })
      })
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    setShowProgressBar(true);
    let user = localState;
    user.clientCodes = multiselectRef.current.getSelectedItems().map(r => r.client_Code);

    usePost("User/SaveUser", user).then((r) => {
      if (r.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: r.statusText })
      }
      if (r.status == 200) {
        props.onSave(localState);
        setShowProgressBar(false);
        setSubmit(true);
      }
    });
  };

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;
    if (!FirstNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
      setFirstNameError("");
      setIsDisable(false);
    } else {
      setFirstNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;
    if (!LastNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
      setLastNameError("");
      setIsDisable(false);
    } else {
      setLastNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value
    });
  };

  const handleChecked = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.checked
    });
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSubmit(false);
  };

  return (
    <React.Fragment>
      <Button id="CAU_Edit" variant="contained" startIcon={<BorderColorIcon />} color="primary" onClick={handleClickOpen} style={{ borderRadius: 20, backgroundColor: 'green' }} >
        Edit
      </Button>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={100} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          PaperProps={{ style: { borderRadius: 15 } }}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'} >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
              EDIT USER
            </Typography>
          </DialogTitleHeader>
          <DialogContent >
            <Grid container>
              <Grid item xs={7} style={{ marginTop: '15px' }}>
                <div>
                  <form className={classes.root} noValidate autoComplete="off" >
                    <TextField id="CAU_E_FirstName" value={localState.firstName} onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }} helperText={firstnameerror}
                      error={!!firstnameerror} inputProps={{ maxlength: 25 }} label="First Name" name="firstName" variant="outlined" style={{ borderBottom: '3px solid blue', borderRadius: '10px' }} />
                    <TextField id="CAU_E_LastName" value={localState.lastName} onChange={(e) => { handleChange(e); HandleLastNameVal(e); }} helperText={Lastnameerror} error={!!Lastnameerror}
                      inputProps={{ maxlength: 25 }} label="Last Name" name="lastName" variant="outlined" style={{ borderBottom: '3px solid blue', borderRadius: '10px' }} />
                  </form>
                </div>
                <form className={classes.root} noValidate autoComplete="off" >
                  <TextField id="CAU_E_Email" value={localState.userName} disabled={true} label="Email" name="userName" variant="outlined" style={{ borderBottom: '3px solid blue', borderRadius: '10px', minWidth: 425 }} />
                </form>
                <form className={classes.root} noValidate autoComplete="off" >
                  <TextField id="CAU_E_Phone" value={localState.phone} disabled={true} onChange={(e) => { handleChange(e); }} name="phone" variant="outlined"
                    style={{ borderBottom: '3px solid blue', borderRadius: '10px', minWidth: 425 }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton aria-label="delete" onClick={(_) => { setLocalState({ ...localState, phone: "" }) }}>
                          <CancelIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </form>
                <FormControl variant="outlined" className={classes.formControl} >
                  <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                  <Select native
                    id="CAU_E_AdminLevel"
                    onChange={handleChange}
                    value={localState.role}
                    label="Template"
                    inputProps={{
                      name: 'Role',
                      id: 'Admin Level',
                    }}
                  >
                    <option value={0}>Regular User</option>
                    <option value={1}>Client Admin</option>
                  </Select>
                </FormControl>
                <FormControl variant="filled" className={classes.multiSelectRoot} style={{ width: "425px", marginTop: '40px' }}>
                  <Multiselect id="clientCode"
                    ref={multiselectRef}
                    selectedValues={selectedClients}
                    options={confirmedClients}
                    displayValue="client_Code"
                    placeholder="Select Client"
                    closeOnSelect={false}
                    showArrow={true}
                    showCheckbox={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: '15%' }}>{"PERMISSIONS"}</DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <FormControl component="fieldset">
                    <FormLabel component="legend" ></FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        id="CAU_E_paymentUpdates"
                        control={<Checkbox onChange={handleChecked} checked={localState.paymentUpdates} name="paymentUpdates" />}
                        labelPlacement="end"
                        label="Payment Updates"
                      />
                      <FormControlLabel
                        id="CAU_E_closingAccounts"
                        control={<Checkbox onChange={handleChecked} checked={localState.closingAccounts} name="closingAccounts" />}
                        labelPlacement="end"
                        label="Close Accounts"
                      />
                      <FormControlLabel
                        id="CAU_E_rollAccounts"
                        control={<Checkbox onChange={handleChecked} checked={localState.rollAccounts} name="rollAccounts" />}
                        labelPlacement="end"
                        label="Roll Accounts"
                      />
                      <FormControlLabel
                        id="CAU_E_placeAccounts"
                        control={<Checkbox onChange={handleChecked} checked={localState.placeAccounts} name="placeAccounts" />}
                        labelPlacement="end"
                        label="Place Accounts"
                      />
                      <FormControlLabel
                        id="CAU_E_editReports"
                        control={<Checkbox onChange={handleChecked} checked={localState.editReports} name="editReports" />}
                        labelPlacement="end"
                        label="Edit Reports"
                      />
                      <FormControlLabel
                        id="CAU_E_createNewUsers"
                        control={<Checkbox onChange={handleChecked} checked={localState.createNewUsers} name="createNewUsers" />}
                        labelPlacement="end"
                        label="Create New Users"
                      />
                      <FormControlLabel
                        id="CAU_E_UploadUnmappedFile"
                        control={<Checkbox onChange={handleChecked} checked={localState.uploadUnmappedFile} name="uploadUnmappedFile" />}
                        labelPlacement="end"
                        label="Upload Unmapped File"
                      />

                    </FormGroup>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Button className={classes.close1} id="U_CU_Close_btn" onClick={handleClose} color="primary" variant="contained" startIcon={<CloseIcon />}>
                  Close
                </Button>
                <Button className={classes.edit} id="CAU_E_UpdateUser" startIcon={<DoneAllIcon />} autoFocus onClick={(_) => { handleSave(); }} color="primary" variant="contained" >
                  Update User
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Snackbar open={submit} style={{ color: 'black', marginTop: '-0.5cm' }} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
            {localState.firstName} Details has been Updated Successfully!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}

export default EditUser