import React, { useState } from 'react';
import {
  Backdrop, Box, Button, createStyles, Grid, makeStyles, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TextField, Theme, withStyles
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { GlobalStateAction, useGlobalState } from "../../../store/GlobalStore";
import { usePost } from "../../../utils/apiHelper";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import * as constant from '../../../constants/Constant';
import PuffLoader from "react-spinners/PuffLoader";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    marginTop: "3%",
    borderRadius: 20
  },
  container: {
    maxWidth: "98vw",
    maxHeight: '78vh',
    minHeight: 680,
    marginTop: '0.5cm',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    display: 'fixed',
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  formControl: {
    minWidth: 280,
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  actions: {
    width: "100%",
    marginTop: "1cm",
    marginRight: theme.spacing(2)
  },
  buttons: {
    borderRadius: 20,
    fontSize: 15,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "0.3cm",
    float: 'right'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  div: {
    flexGrow: 1
  },
  progressBar: {
    height: '6px'
  },
  title: {
    alignContent: "center",
    color: "blue",
    marginTop: '0.2cm',
    float: 'right'
  },
  buttonbox: {
    float: 'right'
  },
  tableHead: {
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
  },
  tableRow: {
    border: '1.8px solid #364F6B',
  },
  tableRow1: {
    color: "white",
    fontSize: "130%",
    maxWidth: "1%",
    textAlign: 'center',
    whiteSpace: "nowrap",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tablebody: {
    maxHeight: 565,
    display: 'flex',
    marginTop:'0.3cm',
    marginBottom: '0.4cm',
    backgroundColor: 'whitesmoke',
  },
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#364F6B',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      width: 600
    }
  }),
)(TableCell);

const PreviewData = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  let placementAccountList: IPlacementAccount[] = props.location.state.placementAccountList;
  let templateList: ITemplateField[] = props.location.state.templateList;
  let phaseNumber: number = props.location.state.phaseNumber;
  let tablerows: number[] = props.location.state.tablerows;
  let clientCode: string = props.location.state.clientCode;
  let templateId: number = props.location.state.templateId;
  let multiCode: boolean = props.location.state.multiCode;
  const UserId = state.userAccessContext?.id;
  const [IsCSVUpload, setIsCSVUploade] = useState(false);

  const handleBackClick = () => {
    history.push('/fieldnames', {
      placementAccountList: placementAccountList, tablerows: tablerows, clientCode: clientCode,
      phaseNumber: phaseNumber, multiCode: multiCode, templateId: templateId
    });
  };

  async function handleSubmit() {
    if (+phaseNumber == 4) {
      placementAccountList.forEach(x => {
        x.phase = getPhaseString(x.phaseNumber);
      });
    } else {
      placementAccountList.forEach(x => {
        x.phase = getPhaseString(phaseNumber);
      });
    } if (clientCode != "-1") {
      placementAccountList.forEach(x => {
        x.client_code = clientCode;
      });
    }
    dispatch({ type: GlobalStateAction.Busy });
    try {
      const response = await usePost(`Placements/ClientUpload`, placementAccountList);
      if (response.status !== 202) {
        dispatch({ type: GlobalStateAction.Error, error: response.statusText });
      }
      InsertManualUploadLog();
      history.push(`/finish`, { IsCSVUpload: IsCSVUpload });
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  };

  const getPhaseString = (phaseNum: number) => {
    switch (+phaseNum) {
      case 1:
        return constant.FIRSTSTP;
      case 2:
        return constant.PREC;
      case 3:
        return constant.CONT;
      default:
        return "";
    }
  }

  const InsertManualUploadLog = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "file_name": "",
          "file_type": "",
          "file_size": 0,
          "uploadMethod": "1",
          "file_content": "",
          "rid": "",
          "userid": UserId.toString(),
          "clientId": clientCode === "-2" ? "MULC" : clientCode,
          "phase": getPhaseString(phaseNumber) === undefined ? "MULP" : getPhaseString(phaseNumber),
          "bloburi": "",
          "containerName": ""
        };
        const response = await usePost<any>("Placements/InsertclientuploadlogData", request)
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container justify="flex-start" alignContent="flex-start" alignItems="center" spacing={1}>
          <CssBaseline />
          <Container fixed className={classes.container} component="div">
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Box p={1} flexShrink={0} bgcolor="white" >
                  <Typography variant="h4" gutterBottom className={classes.title} >
                    <b>3. PREVIEW DATA</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box p={1} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="PD_Submit_btn" className={classes.buttons} variant="contained" endIcon={<ArrowForwardIosIcon />}
                    color="primary" onClick={handleSubmit}>
                    Submit Accounts
                  </Button>
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="PD_Back_btn" className={classes.buttons} variant="contained" startIcon={<ArrowBackIosIcon />}
                    color="primary" onClick={handleBackClick}>
                    Back
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <TableContainer component={Paper} className={classes.tablebody}>
              <Table size="medium" stickyHeader>
                <TableHead className={classes.tableHead} >
                  <TableRow key='table-header'>
                    {templateList.map((row) => {
                      return (row.is_visible ? (
                        <StyledTableCell className={classes.tableRow1} key={row.display_name + 'header'} >
                          {row.display_name}
                        </StyledTableCell>
                      ) : null)
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tablerows.map((r) => {
                    return (
                      <TableRow key={r}>
                        {templateList.map((row) => {
                          return (row.is_visible ? (
                            <StyledTableCell key={row.display_name + r} className={classes.tableRow}>
                              {/* <TextField id={row.field_name + r} label={row.display_name}
                                value=
                                InputProps={{ readOnly: true }} InputLabelProps={{ shrink: true }}
                                className={classes.textField} variant="outlined" /> */}
                              {eval('placementAccountList[' + (r - 1) + '].' + row.field_name)}
                            </StyledTableCell>
                          ) : null)
                        })}
                      </TableRow>)
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Grid>
      </div >
    </React.Fragment >
  )
}

export default PreviewData
