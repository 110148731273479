import { ButtonGroup, DialogContent, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment';
import React, { useState } from 'react';
import { IClosedLetterDetails } from '../../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../../models/Inquiry/LetterDetails/ILetterDetails';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
            backgroundColor: "#007FFF"
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        head: {
            display: "flex",
            flexDirection: "column",
            borderSpacing: "1cm",
            width: 375,
            color: "primary",
        },
        groupedOutlined: {
            "&:not(:first-child)": {
                marginLeft: -1
            },
            "&:not(:last-child)": {
                borderRightColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        menuButton: {
            marginRight: theme.spacing(2),
            width: 220
        },
        Letterhead: {
            marginTop: "15px"

        },
    }),
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreditReportNotMentioned: React.FC<{ dbtNumber: string, letterDetails: IClosedLetterDetails }> = (props) => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [open, setOpen] = React.useState(false);
    const [letterDetails, setLetterDetails] = useState<ILetterDetails[]>([]);

    const handleClose = () => {
        setOpen(false);
    };

    const LetterDetails = () => {
        setOpen(true);
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = { "debtorId": props.dbtNumber };
                const response = await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request)
                const data = response.data.letterDetails.map((a) => {
                    return { ...a, clT_NAME_1: a.clT_NAME_1 }
                })
                setLetterDetails(data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <div>
            <ButtonGroup
                fullWidth
                aria-label="full width outlined button group"
                className={classes.head}
                classes={{ groupedOutlined: classes.groupedOutlined }} >
                <Button style={{ marginTop: "5px", borderRadius: 20, paddingRight: "10px", color: "white" }}
                    onClick={LetterDetails} disabled={!props.letterDetails.letter_Not_Mentioned} variant="contained" color="primary" startIcon={<FileCopyIcon />} /* component={Link} to="" */>
                    Credit Reporting Not Mentioned Letter
                </Button>
            </ButtonGroup>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Credit Reporting Not Mentioned Letter
                        </Typography>
                    </Toolbar>
                </AppBar>
                {letterDetails.map((letter) => {
                    return (
                        <DialogContent dividers>
                            <img alt="ARM Solutions" className={classes.menuButton} src="https://www.armsolutions.com/website/wp-content/themes/arm-solutions/style/images/header-logo.png" />
                            <Divider style={{ marginTop: "5px", height: '2px', background: "black" }} />
                            <Typography variant="body1" align="left" style={{ fontSize: 20, marginTop: "10px" }}>
                               {`${moment(new Date()).format('MMMM DD,YYYY')}`}
                            </Typography>
                            <Typography variant="h6" align="left" style={{ marginTop: "20px", fontSize:16 }}>
                                {letter.dbT_NAME.toUpperCase()}
                            </Typography>
                            <Typography variant="h6" align="left" style={{ fontSize: 16 }}>
                                {letter.deM_ADDR1.toUpperCase()}
                            </Typography>
                            <Typography variant="h6" align="left" style={{ fontSize: 16 }}>
                                {letter.deM_CITY.toUpperCase()}, {letter.deM_STATE.toUpperCase()} , {letter.deM_ZIP.toUpperCase()}
                            </Typography>
                            <Typography variant="h6" align="left" style={{ fontSize: 16, marginTop: "30px" }}>
                                Client Name: {letter.clT_NAME_1.toUpperCase()}
                            </Typography>
                            <Typography variant="h6" align="left" style={{ fontSize: 16, marginTop: "3px" }}>
                                Client Account Number: {letter.dbT_CLNT_ACNT1.toUpperCase()}
                            </Typography>
                            <Typography variant="h6" align="left" style={{ fontSize: 16, marginTop: "3px" }}>
                                Customer Accout Number: {letter.dbT_NO.toUpperCase()}
                            </Typography>
                            <Typography variant="h6" align="left" style={{ fontSize: 16, marginTop: "40px" }}>
                                Dear {letter.dbT_NAME.toUpperCase()},
                            </Typography>
                            <Typography variant="body1" gutterBottom style={{ fontSize: 18, marginTop: "30px" }}>
                                This is to advise you that we have been informed that your account referred on {moment(letter.dbT_REFERRAL_DATE?.split('T')[0]).format(state.GlobalUtils?.settingValue)}, referenced above is now a closed account.
                                Your account with us has been updated to reflect this.<br />
                                All collection activity has been stopped.
                            </Typography>
                            <Typography variant="body1" gutterBottom style={{ fontSize: 18, marginTop: "30px" }}>
                                Should you have any questions regarding this, please feel free to contact us directly at 888-772-6468.
                            </Typography>
                            <Typography variant="h6" align="left" style={{ fontSize: 16, marginTop: "30px" }}>
                                Sincerely,<br />
                                The Customer Service Team<br />
                                A.R.M. Solutions
                            </Typography>
                            <Divider style={{ marginTop: "10px", height: '2px', background: "black" }} />
                            <Grid item xs={12}>
                                <Typography variant="body1" align="left" gutterBottom style={{ fontSize: 16, marginTop:'7px'  }}>
                                    <b>PO Box 2929, Camarillo, CA 93011 &nbsp;&nbsp;&nbsp;<b style={{ color: 'red' }}>T</b>  888.772.6468  &nbsp;&nbsp;&nbsp;<b style={{ color: 'red' }}>F</b> 805.830.1104</b>
                                    <b style={{ color: 'red', float:'right'}}>ARMSolutions.com</b>
                                </Typography>
                            </Grid>
                        </DialogContent>
                    )
                })}
            </Dialog>
        </div >
    );
}

export default CreditReportNotMentioned