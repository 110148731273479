import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, makeStyles, TableCell, TableRow, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Columns } from '../../models/Inquiry/config';
import { IDebtor } from '../../models/NotificationCenter/IDebtor';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { INotificationCenter } from '../../models/NotificationCenter/INotificationCenter';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import InquiryDetail from './NCInquiryDetail';

const useRowStyles = makeStyles({
    root: {
        fontSize: 200
    },
    rootHidden: {
        display: "none"
    },
    buttons: {
        borderRadius: 20,
        background: "#007FFF",
        color: "white",
        marginLeft: "10px",
        width: "150px",
    },
    buttons2: {
        backgroundColor: "green",
        marginLeft: "200px",
        borderRadius: "20px"
    },
    buttons3: {
        borderRadius: 20,
        background: "#007FFF",
        color: "white",
        marginLeft: "10px",
    },
    inputField: {
        width: '95%',
        height: '56px',
        fontSize: 17,
        borderRadius: '4px',
        position: 'relative',
        transition: '0.3s'
    },
    snackbar: {
        color: 'black',
        marginTop: '-0.5cm',
    },
    snackbarfont: {
        fontSize: 16,
    },
    titleHeader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    Content: {
        fontWeight: 'bold',
        color: 'black'
    },
    buttonstyle: {
        borderRadius: '15px'
    },
})

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const ExpandableRow: React.FC<{ Notify: INotificationCenter, selected: boolean, onClick: (accountId: string) => void, onHideAll: () => void, onRefresh: () => void, selectedDBTNumber: string }> = (props) => {
    const { Notify } = props
    const { state, dispatch } = useGlobalState();
    const classes = useRowStyles()
    const [showDocumentUpload, setShowDocumentUpload] = useState(true)
    const [debtors, setDebtors] = useState<IDebtor[]>([]);
    let CreatedDate = moment(Notify.createdDate).format(state.GlobalUtils?.settingValue)
    const [CounterOfferAmount, setCounterOfferAmount] = useState<any>('');
    const [offerAccept, setOfferAccept] = React.useState(false);
    const [offerReject, setOfferReject] = React.useState(false);
    const [sendOffer, setSendOffer] = React.useState(false);
    const [isActiveStatus, setGetActivestatus] = useState(false);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const componentRef = useRef();
    const prevCount = usePrevious(props.selectedDBTNumber);
    const userId = state.userAccessContext?.id;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        dispatch({ type: GlobalStateAction.Busy })
        setShowDocumentUpload(true)
    }, [props.selected])

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const DebtorDetils = await useFetch<IDebtor[]>("GetNotificationDetails?DBTNumber=" + Notify.extraData_3)
                setDebtors(DebtorDetils.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])


    const handleSettlementOfferClick = (action, debtorNo, originalOffer, counterOffer, notificationDate) => {
        let msgBody = "";
        let username = state.user?.email;
        if (action == "accept") {
            msgBody += "<p>Hello,</p><p>" + state.user.name + " has accepted settlement offer and below are the details:</p>";
        }
        if (action == "reject") {
            msgBody += "<p>Hello,</p><p>" + state.user.name + " has rejected settlement offer and below are the details:</p>";
        }
        if (action == "counterOffer") {
            msgBody += "<p>Hello,</p><p>" + state.user.name + " has sent counter settlement offer and below are the details:</p>";
            msgBody += "<tr><td><b>Counter Offer: </b></td><td>$" + CounterOfferAmount + "</td></tr>";
        }
        msgBody += "<table><tr><td><b>Notification Date:</b></td><td>" + notificationDate + "</td></tr><tr><td><b>Debtor no: </b></td><td>" + debtorNo + "</td></tr><tr><td><b>Original Offer: </b></td><td>$" + originalOffer.replace("µ", " ") + "</td></tr>";

        msgBody += "</table><p>Thanks,<br/>" + username + "</p>";
        SendSettlementEmail(username, msgBody);
    }

    const SendSettlementEmail = (username: string, body: string) => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "Username": username,
                    "Body": body
                }
                const responseSendEmail = await usePost<any>("SendSettlementEmail", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let UserName = state.userAccessContext?.id;
                let request = {
                    "usernotificationid": Notify.userNotificationId,
                    "userid": UserName,
                    "debtorid": Notify.extraData_3,
                    "typeId": 4
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
                props.onHideAll();
                props.onRefresh();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbarForAccept = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOfferAccept(false);
    };
    const closeSnackbarForReject = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOfferReject(false);
    };
    const closeSnackbarForSendOffer = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSendOffer(false);
    };
    const CounterOfferValueChange = (value: string | undefined): void => {
        setCounterOfferAmount(value === '0' || '' ? '' : value || '');
    };

    const setYieldLock = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const response = await useFetch<any>(`InquiryYieldLock?debtorNumber=${Notify.extraData_3}&userId=${state.userAccessContext?.id.toString()}`);
                setGetActivestatus(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(Notify.extraData_3);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [Notify.extraData_3, prevCount, props.selectedDBTNumber])

    const setYieldRelease = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let YieldRelease = {
                    "debtorNumber": GetDebtorNo,
                    "userId": userId
                }
                const release = await usePost("InquiryReleaseLock", YieldRelease)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const handleCloseDescription = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <TableRow className={classes.root} >
                <TableCell>
                    {CreatedDate}  Settlement offer : Client Acct #{Notify.extraData_1} / Agency Acct #{Notify.extraData_3} - ${Notify.extraData_2.substring(0, Notify.extraData_2.indexOf("µ"))}
                    <Box display="flex" p={1} bgcolor="white">
                        <Box p={1} width="10%" bgcolor="white">
                            <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                                Principal
                            </Typography>
                            <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                                ${Notify.principalAmount}
                            </Typography>
                        </Box>
                        <Box p={1} width="10%" bgcolor="white">
                            <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                                Late Fee
                            </Typography>
                            <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                                ${Notify.lateFeeAmount}
                            </Typography>
                        </Box>
                        <Box p={1} width="10%" bgcolor="white">
                            <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                                Total
                            </Typography>
                            <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                                ${Notify.principalAmount + Notify.lateFeeAmount}
                            </Typography>
                        </Box>
                        <Box p={1} width="15%" flexShrink={0} bgcolor="white">
                            <CurrencyInput
                                className={classes.inputField}
                                id="SettlementNo_Offer_TextField"
                                value={CounterOfferAmount}
                                placeholder="$ Counter Offer"
                                maxLength={13}
                                decimalsLimit={2}
                                prefix='$'
                                onValueChange={CounterOfferValueChange}
                                autoComplete='off'
                            />
                        </Box>
                        <Box p={1} width="10%" flexShrink={0} bgcolor="white">
                            <Button id="Settlment_SendOffer_Button" className={classes.buttons} startIcon={<LocalOfferIcon />} variant="contained" color="primary" onClick={() => { setSendOffer(true); handleSettlementOfferClick("counterOffer", Notify.extraData_1, Notify.extraData_2, CounterOfferAmount, Notify.createdDate); hidenotificationForAllUsers(); }}>
                                Send Offer
                            </Button>
                        </Box>
                        <Box p={1} width="30%" flexShrink={0} bgcolor="white">
                            <Button id="Settlement_Accept_Button" className={classes.buttons2} startIcon={<DoneAllIcon />} variant="contained" color="primary" onClick={() => { setOfferAccept(true); handleSettlementOfferClick("accept", Notify.extraData_1, Notify.extraData_2, "", Notify.createdDate); hidenotificationForAllUsers(); }}>
                                Accept
                            </Button>
                        </Box>
                        <Box p={1} flexShrink={0} bgcolor="white">
                            <Button id="Settlement_Reject_Button" className={classes.buttons3} startIcon={<NotInterestedIcon />} variant="contained" color="primary" onClick={() => { setOfferReject(true); handleSettlementOfferClick("reject", Notify.extraData_1, Notify.extraData_2, "", Notify.createdDate); hidenotificationForAllUsers(); }}>
                                Reject
                            </Button>
                        </Box>
                    </Box>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="subtitle2" style={{ textAlign: "left", color: 'blue', cursor: 'pointer' }} gutterBottom onClick={(e) => { setOpen(true); }}>
                            Show Description
                        </Typography>
                        OR
                        <Typography variant="subtitle2" style={{ textAlign: "left", color: 'blue', cursor: 'pointer' }} gutterBottom onClick={(_) => { props.onClick(Notify.extraData_3); (Notify.extraData_3 !== props.selectedDBTNumber ? setYieldRelease() : setGetDebtorNo("")); (props.selected === true ? setYieldRelease() : setYieldLock()); }}>
                            View Account Details
                        </Typography>
                    </div>
                </TableCell>
            </TableRow>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleCloseDescription}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDescription}>
                    <Typography variant="h5" gutterBottom className={classes.titleHeader}>
                        DESCRIPTION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="subtitle1" gutterBottom className={classes.Content}>
                        {Notify.extraData_2.split("µ")[1]}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus color="primary" variant="contained" onClick={handleCloseDescription} className={classes.buttonstyle}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {debtors.map((sr) => {
                return (
                    <TableRow className={props.selected ? classes.root : classes.rootHidden} ref={componentRef}>
                        <TableCell scope="row" colSpan={Columns.length}>
                            <Collapse in={props.selected} timeout="auto" unmountOnExit >
                                <InquiryDetail inquiryDetail={sr} isBlocked={isActiveStatus} onCheck={setYieldLock} />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                );
            })}
            <Snackbar className={classes.snackbar} open={offerAccept} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbarForAccept}>
                <Alert onClose={closeSnackbarForAccept} severity="success" className={classes.snackbarfont}>
                    Settlement Offer Accepted.
                </Alert>
            </Snackbar>
            <Snackbar className={classes.snackbar} open={offerReject} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbarForReject}>
                <Alert onClose={closeSnackbarForReject} severity="error" className={classes.snackbarfont}>
                    Settlement Offer Rejected.
                </Alert>
            </Snackbar>
            <Snackbar className={classes.snackbar} open={sendOffer} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbarForSendOffer}>
                <Alert onClose={closeSnackbarForSendOffer} severity="success" className={classes.snackbarfont}>
                    Counter settlement offer sent.
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
export default ExpandableRow