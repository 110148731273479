import React from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Box, LinearProgress } from '@material-ui/core';
import { useGlobalState } from '../../store/GlobalStore';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

//Components
import GSettings from "../../components/GlobalSettings/GSettings";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: 15
        },
        paper: {
            color: theme.palette.text.secondary,
        },
        alignFlexStart: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        progressBar: {
            height: '6px',
            marginTop: '-0.4cm'
        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);

function GlobalSettings() {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();

    return (
        <React.Fragment>
            <div className={classes.root}>
                {state.busy ? <LinearProgress style={{ backgroundColor: "#ff70b8 ", height: "6px", marginTop: "10px" }} /> : ""}
                <Paper className={classes.paper}>
                    <Box display='flex' alignItems='flex-start' justifyContent='flex-start' style={{borderLeft:'10px blue solid'}}>
                        <Typography variant='h6' gutterBottom style={{ marginLeft: '10px', marginTop: '10px', color: 'black' }}>
                            Global Settings
                        </Typography>
                    </Box>
                </Paper>
                <GSettings />
            </div>
        </React.Fragment>
    )
}

export default GlobalSettings
