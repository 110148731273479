import { css } from "@emotion/react";
import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./spinner.css";


export const Spinner = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div>
      <PuffLoader css={override} size={60} color={"blue"} speedMultiplier={1.5} />
    </div>
  );
};

export const RollSpinner = (props) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <div>
      <PuffLoader css={override} size={100} color={"blue"} speedMultiplier={1.5} />
    </div>
  );
};