import { makeStyles, Slide, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import React, { useEffect, useState } from 'react';
import { ICreateTemplate } from "../../../models/Admin/ClientPlacements/ICreateTemplate";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import SaveIcon from '@material-ui/icons/Save';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
        flexGrow: 1,
      },
    },
    formControl: {
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      color: 'black',
    },
    snackbar: {
      width: "120%"
    },
    createtemplate: {
      borderRadius: 20,
      marginRight: '20px',
      marginBottom: '0.2cm'
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateTemplate: React.FC<{ onSave: () => void }> = (props) => {
  const { onSave } = props;
  const [open, setOpen] = React.useState(false);
  const { state, dispatch } = useGlobalState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [TempName, setTempName] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setState(newTemplate);
  };

  const handleCreate = async () => {
    const response = await usePost<{ userInfo: ICreateTemplate }>("ClientPlacement/ModifyTemplate", localState);
    if (response.status == 400) {
      dispatch({ type: GlobalStateAction.Error, error: response.statusText })
    }
    setOpen(false);
    setChecked(true);
    onSave();
    setState(newTemplate);
  };

  const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setChecked(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  var newTemplate: ICreateTemplate = {
    name: '',
    account_no: false,
    account_noRequired: false,
    first_name: false,
    first_nameRequired: false,
    last_name: false,
    last_nameRequired: false,
    full_name: false,
    full_nameRequired: false,
    address1: false,
    address1Required: false,
    address2: false,
    address2Required: false,
    city: false,
    cityRequired: false,
    state: false,
    stateRequired: false,
    zip: false,
    zipRequired: false,
    phone_no: false,
    phone_noRequired: false,
    total_balance: false,
    total_balanceRequired: false,
    delinquency_date: false,
    delinquency_dateRequired: false,
    secondary_address1: false,
    secondary_address1Required: false,
    secondary_address2: false,
    secondary_address2Required: false,
    secondary_city: false,
    secondary_cityRequired: false,
    secondary_state: false,
    secondary_stateRequired: false,
    secondary_zip: false,
    secondary_zipRequired: false,
    secondary_phone_no: false,
    secondary_phone_noRequired: false,
    email: false,
    emailRequired: false,
    drivers_license: false,
    drivers_licenseRequired: false,
    tax_id: false,
    tax_idRequired: false,
    birth_date: false,
    birth_dateRequired: false,
    past_due_balance: false,
    past_due_balanceRequired: false,
    current_balance: false,
    current_balanceRequired: false,
    equipment_charges: false,
    equipment_chargesRequired: false,
    contract_charges: false,
    contract_chargesRequired: false,
    fees: false,
    feesRequired: false,
    last_payment_date: false,
    last_payment_dateRequired: false,
    guarantor_first_name: false,
    guarantor_first_nameRequired: false,
    guarantor_last_name: false,
    guarantor_last_nameRequired: false,
    guarantor_full_name: false,
    guarantor_full_nameRequired: false,
    comment: false,
    commentRequired: false,
    account_type: false,
    account_typeRequired: false,
    other: false,
    otherRequired: false,
    contact_name: false,
    contact_nameRequired: false,
    service_date: false,
    service_dateRequired: false,
    location: false,
    locationRequired: false,
    codebtor_Full_Name: false,
    codebtor_Full_NameRequired: false,
    codebtor_Street_Address1: false,
    codebtor_Street_Address1Required: false,
    codebtor_Street_Address2: false,
    codebtor_Street_Address2Required: false,
    codebtor_City: false,
    codebtor_CityRequired: false,
    codebtor_State: false,
    codebtor_StateRequired: false,
    codebtor_Zip: false,
    codebtor_ZipRequired: false,
    codebtor_BirthDate: false,
    codebtor_BirthDateRequired: false,
    codebtor_Tax_ID: false,
    codebtor_Tax_IDRequired: false,
    coDebtor_First_Name: false,
    coDebtor_First_NameRequired: false,
    coDebtor_Last_Name: false,
    coDebtor_Last_NameRequired: false,
    coDebtor_Phone1: false,
    coDebtor_Phone1Required: false,
    coDebtor_Phone2: false,
    coDebtor_Phone2Required: false,
    itemization_Date: false,
    itemization_DateRequired: false,
    balance_owed_on_itemization: false,
    balance_owed_on_itemizationRequired: false,
    fees_added_since_itemization: false,
    fees_added_since_itemizationRequired: false,
    interest_added_since_itemization: false,
    interest_added_since_itemizationRequired: false,
    payments_Applied_Since_Itemization_Date: false,
    payments_Applied_Since_Itemization_DateRequired: false,
    credits_Applied_Since_Itemization_Date: false,
    credits_Applied_Since_Itemization_DateRequired: false,
  }

  const [localState, setState] = React.useState(newTemplate);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...localState,
      [name]: event.target.value,
    });
  };

  const handleChecked = (event) => {
    const name = event.target.name;
    setState({
      ...localState,
      [name]: event.target.checked,
    });
  };
  const classes = useRowStyles()

  const HandleTempNameVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,:;{}/|[\]\\$'"]/)) {
      setError("");
      setIsValid(false);
      setTempName(newValue);
    } else {
      setError("please enter characters and numbers only");
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (TempName.length > 50) {
      setError("Max length is 50")
      setIsValid(true);
    } else {
      setError("")
      setIsValid(false);
    }
  }, [TempName])

  return (
    <React.Fragment>
      <div>
        <Button id="CreateTemplate_btn" startIcon={<NoteAddIcon />} variant="contained" color="primary" onClick={handleClickOpen} style={{ borderRadius: 20, float: 'right' }}>Create New Template</Button>
      </div>
      <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }}
        open={checked} className={classes.snackbar} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={handleClosed}>
        <Alert onClose={handleClosed} severity="success" style={{ fontSize: 16 }}>
          Template has been created successfully!
        </Alert>
      </Snackbar>
      <div>
        <Dialog fullScreen={fullScreen} open={open} PaperProps={{ style: { borderRadius: 15 } }} fullWidth={true} maxWidth={'lg'} TransitionComponent={Transition}
          onClose={handleClose} aria-labelledby="responsive-dialog-title" >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
              CREATE NEW TEMPLATE
            </Typography>
          </DialogTitleHeader>
          <DialogContent >
            <Grid container spacing={1}>
              <Grid xs={12} style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                {/*  <Typography variant="subtitle1" style={{ color: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {
                    //(((!localState.first_name) && (!localState.last_name) && (!localState.full_name)) || (localState.first_name && localState.last_name && localState.full_name)) ? "Please Select either (First Name or Last Name) or Full Name" : (localState.first_name && localState.full_name) ? "Please select either First Name or Full Name" : (localState.last_name && localState.full_name) ? "Please select either Last Name or Full Name" : <div></div>
                  }
                </Typography> */}
              </Grid>
              <Grid item xs={12}>
                <form className={classes.formControl} noValidate autoComplete="off" >
                  <TextField id="CNT_Name" label="Name" value={localState.name} name="name" variant="outlined" fullWidth margin="dense" onChange={(e) => { handleChange(e); HandleTempNameVal(e); }} helperText={error} error={!!error} style={{ boxShadow: '0px 1px 4px  rgba(0, 0, 0, 0.4)', borderRadius: '3px' }} />
                </form>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: "60%" }} >{"Permitted"}</DialogTitle>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      id="CNT_Account_No"
                      control={<Checkbox onChange={handleChecked} name="account_no" />}
                      labelPlacement="start"
                      label="AccountNumber"
                    />
                    <FormControlLabel
                      id="CNT_firstname"
                      control={<Checkbox onChange={handleChecked} name="first_name" />}
                      labelPlacement="start"
                      label="First Name"
                    />
                    <FormControlLabel
                      id="CNT_lastname"
                      control={<Checkbox onChange={handleChecked} name="last_name" />}
                      labelPlacement="start"
                      label="Last Name"
                    />
                    <FormControlLabel
                      id="CNT_fullname"
                      control={<Checkbox onChange={handleChecked} name="full_name" />}
                      labelPlacement="start"
                      label="Full Name/Business Name"
                    />
                    <FormControlLabel
                      id="CNT_streetAddress1"
                      control={<Checkbox onChange={handleChecked} name="address1" />}
                      labelPlacement="start"
                      label="Street Address 1"
                    />
                    <FormControlLabel
                      id="CNT_streetAddress2"
                      control={<Checkbox onChange={handleChecked} name="address2" />}
                      labelPlacement="start"
                      label="Street Address 2"
                    />
                    <FormControlLabel
                      id="CNT_city"
                      control={<Checkbox onChange={handleChecked} name="city" />}
                      labelPlacement="start"
                      label="City"
                    />
                    <FormControlLabel
                      id="CNT_state"
                      control={<Checkbox onChange={handleChecked} name="state" />}
                      labelPlacement="start"
                      label="State"
                    />
                    <FormControlLabel
                      id="CNT_zip"
                      control={<Checkbox onChange={handleChecked} name="zip" />}
                      labelPlacement="start"
                      label="Zip"
                    />
                    <FormControlLabel
                      id="CNT_phoneNumber"
                      control={<Checkbox onChange={handleChecked} name="phone_no" />}
                      labelPlacement="start"
                      label="Phone Number"
                    />
                    <FormControlLabel
                      id="CNT_totalBalance"
                      control={<Checkbox onChange={handleChecked} name="total_balance" />}
                      labelPlacement="start"
                      label="Total Balance"
                    />
                    <FormControlLabel
                      id="CNT_dateofDelinquency"
                      control={<Checkbox onChange={handleChecked} name="delinquency_date" />}
                      labelPlacement="start"
                      label="Date of Delinquency"
                    />
                    <FormControlLabel
                      id="CNT_streetAddress1Secondary"
                      control={<Checkbox onChange={handleChecked} name="secondary_address1" />}
                      labelPlacement="start"
                      label="Street Address 1 (Secondary)"
                    />
                    <FormControlLabel
                      id="CNT_streetAddress2secondary"
                      control={<Checkbox onChange={handleChecked} name="secondary_address2" />}
                      labelPlacement="start"
                      label="Street Address 2 (Secondary)"
                    />
                    <FormControlLabel
                      id="CNT_citysecondary"
                      control={<Checkbox onChange={handleChecked} name="secondary_city" />}
                      labelPlacement="start"
                      label="City (Secondary)"
                    />
                    <FormControlLabel
                      id="CNT_statesecondary"
                      control={<Checkbox onChange={handleChecked} name="secondary_state" />}
                      labelPlacement="start"
                      label="State (Secondary)"
                    />
                    <FormControlLabel
                      id="CNT_zipsecondary"
                      control={<Checkbox onChange={handleChecked} name="secondary_zip" />}
                      labelPlacement="start"
                      label="Zip (Secondary)"
                    />
                    <FormControlLabel
                      id="CNT_phoneNumbersecondary"
                      control={<Checkbox onChange={handleChecked} name="secondary_phone_no" />}
                      labelPlacement="start"
                      label="Phone Number (Secondary)"
                    />
                    <FormControlLabel
                      id="CNT_emailAddress"
                      control={<Checkbox onChange={handleChecked} name="email" />}
                      labelPlacement="start"
                      label="Email Address"
                    />
                    <FormControlLabel
                      id="CNT_driverslicense"
                      control={<Checkbox onChange={handleChecked} name="drivers_license" />}
                      labelPlacement="start"
                      label="Drivers License"
                    />
                    <FormControlLabel
                      id="CNT_ssTaxid"
                      control={<Checkbox onChange={handleChecked} name="tax_id" />}
                      labelPlacement="start"
                      label="SS# / Tax ID"
                    />
                    <FormControlLabel
                      id="CNT_birthdate"
                      control={<Checkbox onChange={handleChecked} name="birth_date" />}
                      labelPlacement="start"
                      label="BirthDate"
                    />
                    <FormControlLabel
                      id="balancePastDuePortion"
                      control={<Checkbox onChange={handleChecked} name="past_due_balance" />}
                      labelPlacement="start"
                      label="Balance (Past Due Portion)"
                    />
                    <FormControlLabel
                      id="CNT_balanceCurrentPortion"
                      control={<Checkbox onChange={handleChecked} name="current_balance" />}
                      labelPlacement="start"
                      label="Balance (Current Portion)"
                    />
                    <FormControlLabel
                      /*  */
                      id="CNT_equipmentcharges"
                      control={<Checkbox onChange={handleChecked} name="equipment_charges" />}
                      labelPlacement="start"
                      label="Equipment Charges"
                    />
                    <FormControlLabel
                      id="CNT_contractcharges"
                      control={<Checkbox onChange={handleChecked} name="contract_charges" />}
                      labelPlacement="start"
                      label="Contract Charges"
                    />
                    <FormControlLabel
                      id="CNT_fees"
                      control={<Checkbox onChange={handleChecked} name="fees" />}
                      labelPlacement="start"
                      label="Fees"
                    />
                    <FormControlLabel
                      id="CNT_lastpaymentdate"
                      control={<Checkbox onChange={handleChecked} name="last_payment_date" />}
                      labelPlacement="start"
                      label="Last Payment Date"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={2}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: "-10%" }}>
                  {"Required"}
                </DialogTitle>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      id="CNT_account_noRequired"
                      control={<Checkbox onChange={handleChecked} name="account_noRequired" disabled={!localState.account_no} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_firstnameRequired"
                      control={<Checkbox onChange={handleChecked} name="first_nameRequired" disabled={!localState.first_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_lastnameRequired"
                      control={<Checkbox onChange={handleChecked} name="last_nameRequired" disabled={!localState.last_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_fullnameRequired"
                      control={<Checkbox onChange={handleChecked} name="full_nameRequired" disabled={!localState.full_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_streetAddress1Required"
                      control={<Checkbox onChange={handleChecked} name="address1Required" disabled={!localState.address1} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_streetAddress2Required"
                      control={<Checkbox onChange={handleChecked} name="address2Required" disabled={!localState.address2} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_cityRequired"
                      control={<Checkbox onChange={handleChecked} name="cityRequired" disabled={!localState.city} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_stateRequired"
                      control={<Checkbox onChange={handleChecked} name="stateRequired" disabled={!localState.state} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_zipRequired"
                      control={<Checkbox onChange={handleChecked} name="zipRequired" disabled={!localState.zip} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_phoneNumberRequired"
                      control={<Checkbox onChange={handleChecked} name="phone_noRequired" disabled={!localState.phone_no} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_totalBalanceRequired"
                      control={<Checkbox onChange={handleChecked} name="total_balanceRequired" disabled={!localState.total_balance} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_dateofDelinquencyRequired"
                      control={<Checkbox onChange={handleChecked} name="delinquency_dateRequired" disabled={!localState.delinquency_date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_streetAddress1secondaryRequired"
                      control={<Checkbox onChange={handleChecked} name="secondary_address1Required" disabled={!localState.secondary_address1} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_streetAddress2secondaryRequired"
                      control={<Checkbox onChange={handleChecked} name="secondary_address2Required" disabled={!localState.secondary_address2} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_citysecondaryRequired"
                      control={<Checkbox onChange={handleChecked} name="secondary_cityRequired" disabled={!localState.secondary_city} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_statesecondaryRequired"
                      control={<Checkbox onChange={handleChecked} name="secondary_stateRequired" disabled={!localState.secondary_state} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_zipsecondaryRequired"
                      control={<Checkbox onChange={handleChecked} name="secondary_zipRequired" disabled={!localState.secondary_zip} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_phoneNumbersecondaryRequired"
                      control={<Checkbox onChange={handleChecked} name="secondary_phone_noRequired" disabled={!localState.secondary_phone_no} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_emailAddressRequired"
                      control={<Checkbox onChange={handleChecked} name="emailRequired" disabled={!localState.email} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_driverslicenseRequired"
                      control={<Checkbox onChange={handleChecked} name="drivers_licenseRequired" disabled={!localState.drivers_license} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_ssTaxidRequired"
                      control={<Checkbox onChange={handleChecked} name="tax_idRequired" disabled={!localState.tax_id} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_birthdateRequired"
                      control={<Checkbox onChange={handleChecked} name="birth_dateRequired" disabled={!localState.birth_date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_balancePastDuePortionRequired"
                      control={<Checkbox onChange={handleChecked} name="past_due_balanceRequired" disabled={!localState.past_due_balance} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_balanceCurrentPortionRequired"
                      control={<Checkbox onChange={handleChecked} name="current_balanceRequired" disabled={!localState.current_balance} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_equipmentchargesRequired"
                      control={<Checkbox onChange={handleChecked} name="equipment_chargesRequired" disabled={!localState.equipment_charges} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_contractchargesRequired"
                      control={<Checkbox onChange={handleChecked} name="contract_chargesRequired" disabled={!localState.contract_charges} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_feesRequired"
                      control={<Checkbox onChange={handleChecked} name="feesRequired" disabled={!localState.fees} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_lastpaymentdateRequired"
                      control={<Checkbox onChange={handleChecked} name="last_payment_dateRequired" disabled={!localState.last_payment_date} />}
                      labelPlacement="start"
                      label=""
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: "75%" }} >{"Permitted"}</DialogTitle>
                <FormControl component="fieldset">
                  <FormGroup>

                    <FormControlLabel
                      id="CNT_guarantorfirstname"
                      control={<Checkbox onChange={handleChecked} name="guarantor_first_name" />}
                      labelPlacement="start"
                      label="Guarantor First Name"
                    />
                    <FormControlLabel
                      id="CNT_guarantorlastname"
                      control={<Checkbox onChange={handleChecked} name="guarantor_last_name" />}
                      labelPlacement="start"
                      label="Guarantor Last Name"
                    />
                    <FormControlLabel
                      id="CNT_guarantorfullname"
                      control={<Checkbox onChange={handleChecked} name="guarantor_full_name" />}
                      labelPlacement="start"
                      label="Guarantor Full Name"
                    />
                    <FormControlLabel
                      id="CNT_comment"
                      control={<Checkbox onChange={handleChecked} name="comment" />}
                      labelPlacement="start"
                      label="Comment"
                    />
                    <FormControlLabel
                      id="CNT_accounttype"
                      control={<Checkbox onChange={handleChecked} name="account_type" />}
                      labelPlacement="start"
                      label="Account Type"
                    />
                    <FormControlLabel
                      id="CNT_other"
                      control={<Checkbox onChange={handleChecked} name="other" />}
                      labelPlacement="start"
                      label="Other"
                    />
                    <FormControlLabel
                      id="CNT_contact_name"
                      control={<Checkbox onChange={handleChecked} name="contact_name" />}
                      labelPlacement="start"
                      label="Contact Name"
                    />
                    <FormControlLabel
                      id="CNT_service_date"
                      control={<Checkbox onChange={handleChecked} name="service_date" />}
                      labelPlacement="start"
                      label="Service Date"
                    />
                    <FormControlLabel
                      id="CNT_location"
                      control={<Checkbox onChange={handleChecked} name="location" />}
                      labelPlacement="start"
                      label="Location"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Full_Name"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Full_Name" />}
                      labelPlacement="start"
                      label="Codebtor Full Name"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Street_Address1"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Street_Address1" />}
                      labelPlacement="start"
                      label="Codebtor Street Address 1"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Street_Address2"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Street_Address2" />}
                      labelPlacement="start"
                      label="Codebtor Street Address 2"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_City"
                      control={<Checkbox onChange={handleChecked} name="codebtor_City" />}
                      labelPlacement="start"
                      label="Codebtor City"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_State"
                      control={<Checkbox onChange={handleChecked} name="codebtor_State" />}
                      labelPlacement="start"
                      label="Codebtor State"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Zip"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Zip" />}
                      labelPlacement="start"
                      label="Codebtor Zip"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_BirthDate"
                      control={<Checkbox onChange={handleChecked} name="codebtor_BirthDate" />}
                      labelPlacement="start"
                      label="Codebtor BirthDate"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Tax_ID"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Tax_ID" />}
                      labelPlacement="start"
                      label="Codebtor SS# / Tax ID"
                    />
                    <FormControlLabel
                      id="CNT_codebtor_First_Name"
                      control={<Checkbox onChange={handleChecked} name="coDebtor_First_Name" />}
                      labelPlacement="start"
                      label="CoDebtor First Name"
                    />
                    <FormControlLabel
                      id="CNT_coDebtor_Last_Name"
                      control={<Checkbox onChange={handleChecked} name="coDebtor_Last_Name" />}
                      labelPlacement="start"
                      label="CoDebtor Last Name"
                    />
                    <FormControlLabel
                      id="CNT_coDebtor_Phone1"
                      control={<Checkbox onChange={handleChecked} name="coDebtor_Phone1" />}
                      labelPlacement="start"
                      label="CoDebtor Phone1"
                    />
                    <FormControlLabel
                      id="CNT_coDebtor_Phone2"
                      control={<Checkbox onChange={handleChecked} name="coDebtor_Phone2" />}
                      labelPlacement="start"
                      label="CoDebtor Phone2"
                    />
                    <FormControlLabel
                      id="CNT_itemization_Date"
                      control={<Checkbox onChange={handleChecked} name="itemization_Date" />}
                      labelPlacement="start"
                      label="Itemization Date"
                    />
                    <FormControlLabel
                      id="CNT_balance_owed_on_itemization"
                      control={<Checkbox onChange={handleChecked} name="balance_owed_on_itemization" />}
                      labelPlacement="start"
                      label="Balance Owed On Itemization"
                    />
                    <FormControlLabel
                      id="CNT_fees_added_since_itemization"
                      control={<Checkbox onChange={handleChecked} name="fees_added_since_itemization" />}
                      labelPlacement="start"
                      label="Fees Added Since Itemization"
                    />
                    <FormControlLabel
                      id="CNT_interest_added_since_itemization"
                      control={<Checkbox onChange={handleChecked} name="interest_added_since_itemization" />}
                      labelPlacement="start"
                      label="Interest Added Since Itemization"
                    />
                    <FormControlLabel
                      id="CNT_payments_Applied_Since_Itemization_Date"
                      control={<Checkbox onChange={handleChecked} name="payments_Applied_Since_Itemization_Date" />}
                      labelPlacement="start"
                      label="Payments Applied Since Itemization Date"
                    />
                    <FormControlLabel
                      id="CNT_credits_Applied_Since_Itemization_Date"
                      control={<Checkbox onChange={handleChecked} name="credits_Applied_Since_Itemization_Date" />}
                      labelPlacement="start"
                      label="Credits Applied Since Itemization Date"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={2}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: "-10%" }}>{"Required"}</DialogTitle>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      id="CNT_guarantorfirstnameRequired"
                      control={<Checkbox onChange={handleChecked} name="guarantor_first_nameRequired" disabled={!localState.guarantor_first_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_guarantorlastnameRequired"
                      control={<Checkbox onChange={handleChecked} name="guarantor_last_nameRequired" disabled={!localState.guarantor_last_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_guarantorfullnameRequired"
                      control={<Checkbox onChange={handleChecked} name="guarantor_full_nameRequired" disabled={!localState.guarantor_full_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_commentRequired"
                      control={<Checkbox onChange={handleChecked} name="commentRequired" disabled={!localState.comment} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_accounttypeRequired"
                      control={<Checkbox onChange={handleChecked} name="account_typeRequired" disabled={!localState.account_type} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_otherRequired"
                      control={<Checkbox onChange={handleChecked} name="otherRequired" disabled={!localState.other} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_contact_nameRequired"
                      control={<Checkbox onChange={handleChecked} name="contact_nameRequired" disabled={!localState.contact_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_service_dateRequired"
                      control={<Checkbox onChange={handleChecked} name="service_dateRequired" disabled={!localState.service_date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_locationRequired"
                      control={<Checkbox onChange={handleChecked} name="locationRequired" disabled={!localState.location} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Full_NameRequired"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Full_NameRequired" disabled={!localState.codebtor_Full_Name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Street_Address1Required"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Street_Address1Required" disabled={!localState.codebtor_Street_Address1} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Street_Address2Required"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Street_Address2Required" disabled={!localState.codebtor_Street_Address2} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_CityRequired"
                      control={<Checkbox onChange={handleChecked} name="codebtor_CityRequired" disabled={!localState.codebtor_City} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_StateRequired"
                      control={<Checkbox onChange={handleChecked} name="codebtor_StateRequired" disabled={!localState.codebtor_State} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_ZipRequired"
                      control={<Checkbox onChange={handleChecked} name="codebtor_ZipRequired" disabled={!localState.codebtor_Zip} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_BirthDateRequired"
                      control={<Checkbox onChange={handleChecked} name="codebtor_BirthDateRequired" disabled={!localState.codebtor_BirthDate} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Tax_IDRequired"
                      control={<Checkbox onChange={handleChecked} name="codebtor_Tax_IDRequired" disabled={!localState.codebtor_Tax_ID} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_First_NameRequired"
                      control={<Checkbox onChange={handleChecked} name="coDebtor_First_NameRequired" disabled={!localState.coDebtor_First_Name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Last_NameRequired"
                      control={<Checkbox onChange={handleChecked} name="coDebtor_Last_NameRequired" disabled={!localState.coDebtor_Last_Name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Phone1Required"
                      control={<Checkbox onChange={handleChecked} name="coDebtor_Phone1Required" disabled={!localState.coDebtor_Phone1} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_codebtor_Phone2Required"
                      control={<Checkbox onChange={handleChecked} name="coDebtor_Phone2Required" disabled={!localState.coDebtor_Phone2} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_itemization_DateRequired"
                      control={<Checkbox onChange={handleChecked} name="itemization_DateRequired" disabled={!localState.itemization_Date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_balance_owed_on_itemizationRequired"
                      control={<Checkbox onChange={handleChecked} name="balance_owed_on_itemizationRequired" disabled={!localState.balance_owed_on_itemization} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_fees_added_since_itemizationRequired"
                      control={<Checkbox onChange={handleChecked} name="fees_added_since_itemizationRequired" disabled={!localState.fees_added_since_itemization} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_interest_added_since_itemizationRequired"
                      control={<Checkbox onChange={handleChecked} name="interest_added_since_itemizationRequired" disabled={!localState.interest_added_since_itemization} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_payments_Applied_Since_Itemization_DateRequired"
                      control={<Checkbox onChange={handleChecked} name="payments_Applied_Since_Itemization_DateRequired" disabled={!localState.payments_Applied_Since_Itemization_Date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="CNT_credits_Applied_Since_Itemization_DateRequired"
                      control={<Checkbox onChange={handleChecked} name="credits_Applied_Since_Itemization_DateRequired" disabled={!localState.credits_Applied_Since_Itemization_Date} />}
                      labelPlacement="start"
                      label=""
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="CNT_CreateTemplate_Btn" startIcon={<SaveIcon />} className={classes.createtemplate} variant="contained" autoFocus disabled={isValid || !TempName} onClick={() => { handleCreate(); }} color="primary">
              Submit New Template
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment >
  );
}

export default CreateTemplate