import React, { useState } from 'react'
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore'
import SettingsBackupRestoreTwoToneIcon from '@material-ui/icons/SettingsBackupRestoreTwoTone';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { IPlacedAccount } from '../../../models/Inquiry/IPlacedAccount';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Backdrop } from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';

const useStyles = makeStyles((theme) => ({
    root: {
        border: "0.5px solid black",
        marginLeft: "0cm",
        borderRadius: 8,
        width: "26.4cm",
        height: "auto",
        marginTop: "0px"
    },
    snackBarStyle: {
        marginTop: '-0.5cm',
    },
    alertBoxStyle: {
        fontSize: 16,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}))

const Submit: React.FC<{ clientId: string, dbtNo: string, placedAccounts: IPlacedAccount[], callGetPlacement: any, onSubmit: () => void, BlockAwaitAccess: boolean }> = (props) => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [count, setCount] = useState(0);
    const [undoSubmit, setundoSubmit] = React.useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);

    const undoAdjustment = (adjustmentAmount: number, adjustmentType: string) => {
        (async () => {
            setShowProgressBar(true);
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "Dbt_No": props.dbtNo,
                    "AdjustmentAmount": adjustmentAmount.toFixed(2),
                    "AdjustmentType": adjustmentType
                }
                const response = await usePost<any>("Account/UndoAdjustment", request);
                await useFetch<any>("Account/UndoPaymentAmount");
                props.onSubmit();
                setundoSubmit(false);
                setShowProgressBar(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                props.callGetPlacement();
                dispatch({ type: GlobalStateAction.Idle });
                setCount(count + 1);
            }
        })()
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setundoSubmit(false);
    };

    if (props.placedAccounts.find(x => x.prpcl_Pay_Amt != null || x.prcpl_Adj_Amt != null || x.fee_Pay_Amt != null || x.fee_Adj_Amt != null)) {
        return (
            <React.Fragment>
                <Box className={classes.root} margin={2} >
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <div style={{ marginLeft: "5px" }}>
                        <Typography variant="overline" display="block" gutterBottom>
                            Pending...
                        </Typography>
                        {props.placedAccounts.reverse().map(p => {
                            return (
                                <React.Fragment>
                                    <span>
                                        {Math.abs(p.prpcl_Pay_Amt) > 0 ? <div>Principal Payment: {p.prpcl_Pay_Amt.toFixed(2)} <IconButton disabled={props.BlockAwaitAccess} id="NC_Undo_PrincipalPayment" onClick={() => { setundoSubmit(true); undoAdjustment(p.prpcl_Pay_Amt, "Principal Payment"); }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                        {Math.abs(p.prcpl_Adj_Amt) > 0 ? <div>Principal Adjustment: {p.prcpl_Adj_Amt.toFixed(2)}<IconButton disabled={props.BlockAwaitAccess} id="NC_Undo_PrincipalAdjustment" onClick={() => { setundoSubmit(true); undoAdjustment(p.prcpl_Adj_Amt, "Principal Adjustment"); }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                        {Math.abs(p.fee_Pay_Amt) > 0 ? <div>Late Fee Payment: {p.fee_Pay_Amt.toFixed(2)}<IconButton disabled={props.BlockAwaitAccess} id="NC_Undo_FeePayment" onClick={() => { setundoSubmit(true); undoAdjustment(p.fee_Pay_Amt, "Fee Payment"); }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                        {Math.abs(p.fee_Adj_Amt) > 0 ? <div>Late Fee Adjustment: {p.fee_Adj_Amt.toFixed(2)}<IconButton disabled={props.BlockAwaitAccess} id="NC_Undo_FeeAdjustment" onClick={() => { setundoSubmit(true); undoAdjustment(p.fee_Adj_Amt, "Fee Adjustment"); }}><SettingsBackupRestoreTwoToneIcon /></IconButton></div> : null}
                                    </span>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <Snackbar className={classes.snackBarStyle} open={undoSubmit} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} autoHideDuration={4000} onClose={closeSnackbar}>
                        <Alert onClose={closeSnackbar} severity="error" className={classes.alertBoxStyle}>
                            Undo Payment Successfully!
                        </Alert>
                    </Snackbar>
                </Box>
            </React.Fragment>
        )
    }
    else {
        return (null)
    }
}

export default Submit