import { AppBar, Box, Grid, IconButton, LinearProgress, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { IDebtor } from '../../models/NotificationCenter/IDebtor';
import AccountDetails from './NCAccountDetails';
import AccountHistory from './NCAccountHistory';
import TransactionAuditHistory from './NCTransactionAuditHistory';
import { IGetRolledPlacement } from '../../models/Inquiry/IGetRolledPlacement';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { IPlacedAccountContract } from '../../models/Inquiry/IPlacedAccountContract';
import { ITransactionHistory } from '../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { ILetterDetails } from '../../models/Inquiry/LetterDetails/ILetterDetails';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { RollSpinner } from '../GlobalStyles/Loading';
import Autorenew from '@mui/icons-material/Autorenew';
import lock from "../../images/inquiry/lock.svg";
import Activelock from "../../images/inquiry/unlocked.svg";

interface TabPanelProps {
    children?: React.ReactNode,
    index: any,
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}  >
            {value === index && (
                <Box p={5}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        marginRight: "2px",
        marginTop: "0%",
        width: "15%",
        height: '1px',
    },
    root: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px"
    },
    rowexpand: {
        borderRadius: 10,
        border: '1px black solid',
        maxHeight: 'auto',
        minHeight: 300
    },
    image1: {
        width: '40px',
        height: '40px',
    },
    image2: {
        width: '40px',
        height: '35px',
    },
    rotateIcon: {
        animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    indicator: {
        top: "50px",
        backgroundColor: '#C51230',
        padding: "2px",
        border: "10%"
    },
}))

const InquiryDetail: React.FC<{ inquiryDetail: IDebtor, disable?: IGetRolledPlacement, onCheck: () => void, isBlocked: boolean }> = (props) => {
    const { inquiryDetail, disable, onCheck, isBlocked } = props
    const [value, setValue] = React.useState(0);
    const { state, dispatch } = useGlobalState();
    const classes = useStyles()
    const UserName = state.userAccessContext?.id;
    const [isClosed, setisClosed] = useState<IGetRolledPlacement[]>([]);
    const [Transaction, setTransaction] = useState<ITransactionHistory[]>([]);
    const [letterDetails, setLetterDetails] = useState<ILetterDetails[]>([]);
    const [closedLetterdetails, setclosedLetterdetails] = useState<IClosedLetterDetails[]>([]);
    const [mapped, setMapped] = useState(false);
    const [history, setHistory] = useState<ITransactionHistory[]>([]);
    const [checkData, setCheckData] = React.useState(false);
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [spin, setSpin] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const history = await useFetch<ITransactionHistory[]>(`DebtorHistory?DBTNumber=${inquiryDetail.dbT_NO}`)
                setHistory(history.data);
                setMapped(history.data.length > 0 ? true : false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const transaction = await useFetch<ITransactionHistory[]>(`GetTransactionHistory?DBTNumber=${inquiryDetail.dbT_NO}`)
                setTransaction(transaction.data);
                setCheckData(transaction.data.length > 0 ? true : false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    useEffect(() => {
        if (isClosed.length <= 0) {
            getRollPlacementAsync();
        }
    }, [isClosed]);

    async function getRollPlacementAsync() {
        dispatch({ type: GlobalStateAction.Busy })
        try {
            let request = {
                "dbt_No": inquiryDetail.dbT_NO,
                "dbt_Client": inquiryDetail.dbT_CLIENT,
                "user_Id": UserName
            };
            const isEnable = await usePost<IPlacedAccountContract[]>('RollCreatePlacement', request)
            const response = await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`)
            setshowProgressBar(true);
            setisClosed(response.data);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
            setshowProgressBar(false);
        }
    }

    useEffect(() => {
        if (closedLetterdetails.length <= 0) {
            getClosedLetterDetails();
        }
    }, [closedLetterdetails]);

    async function getClosedLetterDetails() {
        dispatch({ type: GlobalStateAction.Busy })
        try {
            const response = await useFetch<IClosedLetterDetails[]>(`ClosedLetterDetailsGet?DBTNumber=${inquiryDetail.dbT_NO.toString()}`)
            setclosedLetterdetails(response.data);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    const DisableButton = () => {
        (async () => {
            try {
                const response = await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`)
                setisClosed(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    useEffect(() => {
        (async () => {
            try {
                let request = { "debtorId": inquiryDetail.dbT_NO };
                const response = await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request)
                const data = response.data.letterDetails.map((a) => {
                    return { ...a, clT_NAME_1: a.clT_NAME_1 }
                })
                setLetterDetails(data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }, [])


    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": inquiryDetail.dbT_NO,
                    "typeId": 5
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };

    return (
        <Box>
            <AppBar position="static" color="transparent" className={classes.rowexpand} >
                <Tabs id="NC_Tab" style={{ marginTop: "4px", marginLeft: "10px" }}
                    value={value} onChange={handleChange}
                    classes={{ indicator: classes.indicator }} aria-label="simple tabs example">
                    <Tab id="NC_AccountDetails" className={classes.tabs} label="Account Details" style={{ background: "#800080", color: "white", borderRadius: 5 }} />
                    <Tab id="NC_AccountHistory" className={classes.tabs} label="Account History" style={{ background: "#800080", color: "white", borderRadius: 5, borderBottom: "none" }} />
                    <Tab id="NC_TransactionAuditHistory" className={classes.tabs} label="Transaction Audit History" style={{ background: "#800080", color: 'white', borderRadius: 5 }} />
                    <Grid item xs={7} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        {isBlocked ?
                            <React.Fragment>
                                <IconButton id="Active_Status" >
                                    <img src={lock} alt="Lock image for account Details" className={classes.image2} />
                                </IconButton>
                                <IconButton id="Active_Status" onClick={(_) => { props.onCheck(); refreshCanvas(); }}>
                                    <Autorenew fontSize="large" className={`${spin ? classes.rotateIcon : ""}`} />
                                </IconButton>
                            </React.Fragment>
                            :
                            <IconButton id="Active_Status" >
                                <img src={Activelock} alt="UnLock image for account Details" className={classes.image1} />
                            </IconButton>
                        }
                    </Grid>
                </Tabs>
                <TabPanel value={value} index={0}>
                    {ProgressBar ? <RollSpinner /> :
                        isClosed.map((placedAccounts) => {
                            return (
                                <React.Fragment>
                                    {closedLetterdetails.map((letterDetails) => {
                                        return (
                                            <AccountDetails debtors={inquiryDetail} letterDetails={letterDetails} disablee={disable} isEnable={placedAccounts} onEroll={DisableButton} onHideAll={hidenotificationForAllUsers} BlockAwaitUsers={isBlocked} />
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    < AccountHistory debtors={inquiryDetail} histories={history} mapped={mapped} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TransactionAuditHistory debtors={inquiryDetail} transactions={Transaction} mapped={checkData} />
                </TabPanel>
            </AppBar>
        </Box >
    )
}

export default InquiryDetail
