import { Box, Grid, TablePagination } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Backdrop from '@material-ui/core/Backdrop';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { IFaq } from '../models/Faq/IFaq';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch } from '../utils/apiHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(18),
      color: "#4C4CDB"
    },
    div: {
      flexGrow: 1,
      backgroundColor: 'whitesmoke'
    },
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "#E2E8EF",
        borderTop: 'none'
      },
      borderRadius: 30,
      padding: 2,
      marginTop: "10px",
      boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
    },
    grid: {
      padding: '15px'
    },
    maingrid: {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    TextHighlight: {
      color: 'black',
      fontWeight: 'bold',
      background: "yellow",
      fontSize: '16px',
    },
    TextNormal: {
      color: '#215176',
      fontSize: 18,
      fontWeight: 'bold'
    },
    expandicon: {
      backgroundColor: '#215176',
      color: 'white',
      borderRadius: 30
    },
    accordionDetails: {
      borderTop: '2px solid rgba(6, 24, 44, 0.4)'
    },
    searchBox: {
      borderRadius: 30,
      border: '2px black solid',
      width: '80%',
      height: '50px',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      fontSize: 16,
      textIndent: '10px'
    },
  }),
);

const SimpleAccordion = () => {
  const classes = useStyles();
  const [Faq, setFaq] = useState<IFaq[]>([]);
  const { state, dispatch } = useGlobalState();
  const [searchText, setSearchText] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [ProgressBar, setShowProgressBar] = useState(true);

  useEffect(() => {
    if (Faq.length <= 0) {
      getFaqListAsync();
    }
  }, [Faq]);

  async function getFaqListAsync() {
    dispatch({ type: GlobalStateAction.Busy })
    setShowProgressBar(true);
    try {
      await useFetch<IFaq[]>("Faq/SearchFaq?SearchParam=" + searchText).then((searchFaq) => {
        setFaq(searchFaq.data);
      });
      setShowProgressBar(false);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const SearchFaq = (e) => {
    setSearchText(e);
    setPage(0);
  }

  return (
    <React.Fragment>
      <div className={classes.div} >
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid} >
          <Grid xs={12} item >
            <input value={searchText}
              type='text' placeholder="Search (client code ,client name)"
              className={classes.searchBox}
              onChange={e => SearchFaq(e.target.value)}
              onKeyPress={event => { if (event.key === 'Enter') { getFaqListAsync(); } }}
              maxLength={255}
            />
          </Grid>
          <Grid xs={12} item style={{ marginTop: '10px' }}>
            {Faq.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
              (row) => {
                return (
                  <Accordion elevation={0} style={{ borderRadius: 30 }} classes={{ root: classes.MuiAccordionroot }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />}>
                      <Typography className={classes.heading} >
                        <span className={`${searchText === "" ? classes.TextNormal : row.question.includes(searchText) ? classes.TextHighlight : classes.TextNormal}`}>
                          {row.question}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Typography align="left" variant="h6" >
                        {row.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
            <Box p={2} display="flex" justifyContent="right" alignItems="center" >
              <TablePagination
                id="UpLog_TablePagination"
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={Faq.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default SimpleAccordion