import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { INotificationCenter } from '../../models/NotificationCenter/INotificationCenter';

const ReadNotification: React.FC<{ Notify: INotificationCenter }> = (props) => {
    const { Notify } = props
    const { state, dispatch } = useGlobalState()
    const [Read, setRead] = useState(Notify.readFlag)

    const ReadNotify = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "UserNotificationId": Notify.userNotificationId,
                };
                const response = await usePost("ReadNotification", request)
                setRead(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    return (
        <React.Fragment>
            <Button id="NC_Read_btn" style={{ borderRadius: 20 }} disabled={Read || Notify.readFlag} onClick={(_) => { ReadNotify(); }} variant="contained" color="primary">
                Read
            </Button>
        </React.Fragment>
    );
}

export default ReadNotification


