import DateFnsUtils from '@date-io/date-fns';
import {
    Button, CircularProgress, createStyles, Grid, IconButton, makeStyles, Paper, Table,
    TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    TextField, Theme, withStyles
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../components/Footer/Footer';
import * as constant from '../constants/Constant';
import clearIcon from '../images/buttonIcons/clearIcon.svg';
import { IAdminGetClients } from "../models/UploadLog/AdminGetClients";
import { IGetUploadLogData } from "../models/UploadLog/GetUploadLogResult";
import { IGetUsers } from "../models/UploadLog/GetUsersList";
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import DocumentPDFViewer from './UploadLog/DocumentPDFViewer';
import DownloadDocumentUploadFile from "./UploadLog/DownloadDocumentUploadFile";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "2cm",
        alignItems: "center"
    },
    actions: {
        width: "100%",
    },
    buttons: {
        borderRadius: 20,
        background: "#007FFF",
        color: "white",
        marginTop: "8px",
    },
    grid: {
        padding: '10px'
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '10px',
        border: '1px solid gray'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: 320,
        marginLeft: '-50px',
        marginTop: "12px"
    },
    filter: {
        marginTop: '20px'
    },
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    snackbar: {
        color: 'black',
        marginTop: '-0.5cm',
    },
    snackbarfont: {
        fontSize: 16,
    },
    Title: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tablebody: {
        maxHeight: 520
    }
}))

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 15,
            width: 600
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            width: "100%",
            height: 48,
        },
    }),
)(TableRow);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headCells = [
        { id: "fullusername", disablePadding: false, label: "USER", sortable: true },
        { id: "clientId", disablePadding: false, label: "CLIENT ID", sortable: true },
        { id: "phase", disablePadding: false, label: "PHASE", sortable: true },
        { id: "uploadDate", disablePadding: false, label: "DATE", sortable: true, marginLeft: "20px" },
        { id: "uploadDate", disablePadding: false, label: "TIME", sortable: true },
        { id: "uploadMethod", disablePadding: false, label: "UPLOAD METHOD", sortable: true },
        { id: "fileName", disablePadding: false, label: "FILE", sortable: true },
        { id: "fileId", disablePadding: false, label: "PREVIEW", sortable: true }
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                        style={{ background: "#007FFF", color: "white", fontSize: "120%" }} >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            style={{ marginLeft: `${headCell.marginLeft}` }}
                        >
                            {headCell.label || headCell.sortable}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const Uploadlog = () => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();

    const [clientList, setclientList] = useState<IAdminGetClients[]>([]);
    const [SelectedClientCode, setSelectedClientCode] = useState<any>();
    const [ListLog, setListLog] = useState<IGetUploadLogData[]>([]);
    const [SelectActiveUser, setSelectActiveUser] = useState<any>();
    const [userList, setuserList] = useState<IGetUsers[]>([]);

    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const UserName = state.userAccessContext?.id;

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [adminLevel, setAdminLevel] = useState<number>();
    const [page, setPage] = React.useState(0);

    const [orderBy, setOrderBy] = React.useState(" ");
    const [order, setOrder] = React.useState("asc");

    const [selectedFirstDate, setSelectedFirstDate] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(false);
    const [clearAllFilters, setClearAllFilters] = useState(false);
    const [selectedClient, setSelectedClient] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [selectedUser, setSelectedUser] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isClear, setIsClear] = useState(false);
    const [mapped, setMapped] = useState(false);

    const ClientCode = state.userAccessContext?.clientCodes.join(",");
    const clientsLoading = clientList.length === 0;
    const usersLoading = userList.length === 0;

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy"

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setClearAllFilters(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedFirstDate(true);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedEndDate(true);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            try {
                let request = {
                    "rowsPerPage": 5000,
                    "startdate": new Date(),
                    "enddate": new Date(),
                    "userid": state.userAccessContext?.role === 0 || state.userAccessContext?.role === 1 ? state.userAccessContext?.id : "",
                    "clientid": (state.userAccessContext?.role === 1 || state.userAccessContext?.role === 0 ? ClientCode : ''),
                    "isclientadmin": (state.userAccessContext?.role === 0 ? 0 : state.userAccessContext?.role),
                    "filteruserid": null
                };

                await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                    setListLog(clientUploadLogList.data["uploadLog"]);
                    setMapped(!clientUploadLogList.data["uploadLog"].length ? true : false);
                });
                setshowProgressBar(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
                    await useFetch<IGetUsers[]>("UploadLog/UserGet").then((GetUsersList) => {
                        setuserList(GetUsersList.data);
                    });
                }
                else if (state.userAccessContext.role === 1) {
                    await useFetch<IGetUsers[]>("UploadLog/Getuserforclientuploadlog?userid=" + UserName).then((GetUserForClient) => {
                        setuserList(GetUserForClient.data);
                    });
                }
                else if (state.userAccessContext.role === 0) {
                    await useFetch<IGetUsers[]>("UploadLog/Getuserforclientuploadlog?userId=" + UserName).then((GetUserForClient) => {
                        setuserList(GetUserForClient.data);
                    });
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
                    await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                        setclientList(AdminGetClients.data);
                    });
                }
                else if (state.userAccessContext.role === 1) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((AdminGetClients) => {
                        setclientList(AdminGetClients.data);
                    });
                }
                else if (state.userAccessContext.role === 0) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((AdminGetClients) => {
                        setclientList(AdminGetClients.data);
                    });
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    function getUploadLogListAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            try {
                let request = {
                    "rowsPerPage": 2000,
                    "startdate": (selectedFirstDate ? StartDate : new Date()),
                    "enddate": (selectedEndDate ? EndDate : new Date()),
                    "userid": state.userAccessContext?.id,
                    "clientid": (selectedClient ? (SelectedClientCode === null ? "" : SelectedClientCode.client_code) : ""),
                    "isclientadmin": (state.userAccessContext.role === 3 ? 3 : 2),
                    "filteruserid": (selectedUser ? (SelectActiveUser === null ? "" : SelectActiveUser.id) : null)
                };
                await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                    setListLog(clientUploadLogList.data["uploadLog"]);
                    setMapped(!clientUploadLogList.data["uploadLog"].length ? true : false);
                });
                setshowProgressBar(false);
                setOpen(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })();
    }

    function clientgetUploadLogListAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            try {
                let request = {
                    "rowsPerPage": 2000,
                    "startdate": (selectedFirstDate ? StartDate : ''),
                    "enddate": (selectedEndDate ? EndDate : ''),
                    "userid": state.userAccessContext?.id,
                    "clientid": (selectedClient ? (SelectedClientCode === null ? ClientCode : SelectedClientCode.client_code) : ClientCode),
                    "isclientadmin": 1,
                    "filteruserid": (selectedUser ? (SelectActiveUser === null ? "" : SelectActiveUser.user_id) : "")
                };
                await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                    setListLog(clientUploadLogList.data["uploadLog"]);
                    setMapped(!clientUploadLogList.data["uploadLog"].length ? true : false);
                });
                setshowProgressBar(false);
                setOpen(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })();
    }

    function ClientUserUploadLogListAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            try {
                let request = {
                    "rowsPerPage": 2000,
                    "startdate": (selectedFirstDate ? StartDate : ''),
                    "enddate": (selectedEndDate ? EndDate : ''),
                    "userid": (state.userAccessContext?.id),
                    "clientid": (selectedClient ? (SelectedClientCode === null ? ClientCode : SelectedClientCode.client_code) : ClientCode),
                    "isclientadmin": 0,
                    "filteruserid": null
                };
                await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                    setListLog(clientUploadLogList.data["uploadLog"]);
                    setMapped(!clientUploadLogList.data["uploadLog"].length ? true : false);
                });
                setshowProgressBar(false);
                setOpen(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })();
    }

    useEffect(() => {
        if (state.userAccessContext?.role === 1 || state.userAccessContext?.role === 0) {
            setIsClear(true);
        }
        else {
            setIsClear(false);
        }
    }, [isClear])

    useEffect(() => {
        if (state.userAccessContext?.role === 3) {
            setAdminLevel(3);
        }
        else if (state.userAccessContext?.role === 2) {
            setAdminLevel(2);
        }
        else if (state.userAccessContext?.role === 1) {
            setAdminLevel(1);
        }
        else {
            setAdminLevel(0);
        }
    }, [adminLevel])

    function ClearFilterAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            try {
                let request = {
                    "rowsPerPage": 2000,
                    "startdate": '',
                    "enddate": '',
                    "userid": (isClear ? state.userAccessContext?.id : ""),
                    "clientid": (state.userAccessContext?.role === 1 || state.userAccessContext?.role === 0 ? ClientCode : ''),
                    "isclientadmin": adminLevel,
                    "filteruserid": ""
                };

                await usePost<{ uploadLog: IGetUploadLogData[], no_of_rec: number }>("UploadLog/GetClientUploadlog", request).then((clientUploadLogList) => {
                    setListLog(clientUploadLogList.data["uploadLog"]);
                    setMapped(!clientUploadLogList.data["uploadLog"].length ? true : false);
                });
                setClearAllFilters(true);
                resetValues();
                setshowProgressBar(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })();
    }

    function onUsersSelect(event, value) {
        setSelectActiveUser(value);
        setSelectedUser(true);
        setPage(0);
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
                    await useFetch<IGetUsers[]>("UploadLog/UserGet?id=" + value.id).then((GetUsers) => {
                        setuserList(GetUsers.data);
                    });
                }
                else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
                    await useFetch<IGetUsers[]>("UploadLog/Getuserforclientuploadlog?userid=" + value.user_id).then((GetUserForClient) => {
                        setuserList(GetUserForClient.data);
                    });
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function onClickFilter() {
        dispatch({ type: GlobalStateAction.Busy });
        (async () => {
            if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
                getUploadLogListAsync();
            }
            else if (state.userAccessContext.role === 1) {
                clientgetUploadLogListAsync();
            }
            else {
                ClientUserUploadLogListAsync();
            }
        })()
    }

    function onClientCodeSelect(event, valueone) {
        setSelectedClientCode(valueone);
        setSelectedClient(true);
        setPage(0);
        (async () => {
            try {
                if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
                    await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((GetAdminClientCodes) => {
                        setclientList(GetAdminClientCodes.data);
                    });
                }
                else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((GetUsersClientCodes) => {
                        setclientList(GetUsersClientCodes.data);
                    });
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    function resetValues() {
        setStartDate(null);
        setEndDate(null);
        setPage(0);
        setSelectedClient(false);
        setSelectedUser(false);
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container className={classes.grid} style={{ backgroundColor: 'white' }}>
                    <CssBaseline />
                    <Grid item xs={5} className={classes.Title} >
                        <Typography variant="h4" gutterBottom style={{ color: "blue" }}>
                            <b>UPLOAD LOG</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.Title}>
                        {StartDate > EndDate ? <Typography variant='h6' style={{ color: 'red' }}>Invalid End Date.</Typography> : ""}
                    </Grid>
                    <Snackbar open={open} className={classes.snackbar} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" className={classes.snackbarfont}>
                            Filter Successfully Triggered!
                        </Alert>
                    </Snackbar><Snackbar open={clearAllFilters} className={classes.snackbar} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" className={classes.snackbarfont}>
                            Filter Cleared Successfully!
                        </Alert>
                    </Snackbar>
                    <Grid container spacing={1} className={classes.maingrid} justify='space-evenly'>
                        <Grid item xs={5} sm={6} md={2} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around" style={{}}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        autoOk={true}
                                        label="Start Date"
                                        format={DateFormat}
                                        className={classes.textField}
                                        margin="normal"
                                        id="UpLog_StartDate"
                                        value={StartDate}
                                        onChange={handleFirstDateChange}
                                        InputAdornmentProps={{ position: "end" }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={5} sm={6} md={2} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around" style={{}}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        InputAdornmentProps={{ position: "end" }}
                                        autoOk={true}
                                        label="End Date"
                                        className={classes.textField}
                                        format={DateFormat}
                                        margin="normal"
                                        id="UpLog_EndDate"
                                        value={EndDate}
                                        onChange={handleEndDateChange}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {state.userAccessContext?.role === 0 ? "" :
                            <Grid item xs={5} sm={6} md={2}>
                                <Autocomplete
                                    id="UpLog_SelectUsers_DropDown"
                                    autoComplete autoHighlight
                                    options={userList} onChange={onUsersSelect}
                                    getOptionLabel={(option) => option.fullname} clearOnEscape={true}
                                    style={{ marginTop: "-4px", width: '320px', marginLeft: '20px' }}
                                    loading={usersLoading}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span style={option.isActive === true ? { color: "green" } : { color: "#FA8072" }}> {option.fullname}</span>
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Users" margin="normal" variant="outlined" style={{ marginLeft: '-80px' }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {usersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        <Grid item xs={5} sm={5} md={2}>
                            <Autocomplete
                                id="UpLog_SelectClientCode_Dropdown"
                                autoComplete autoHighlight={true}
                                options={clientList} onChange={onClientCodeSelect}
                                getOptionLabel={(option) => option.client_code} clearOnEscape={true}
                                style={{ marginTop: "-4px", width: '320px' }}
                                loading={clientsLoading}
                                renderInput={(params) => (
                                    <TextField {...params} label="Client Code" margin="normal" variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {clientsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={5} sm={5} md={2} className={classes.filter}>
                                <Button id="UpLog_Filter_Button" className={classes.buttons} onClick={() => { onClickFilter(); }} variant="contained" color="primary" startIcon={<FilterAltIcon />} style={{ marginTop: '-1px' }} disabled={StartDate > EndDate}>
                                    Filter
                                </Button>
                                <Button id="UpLog_Filter_Button" startIcon={<img src={clearIcon} alt="Icon for clear filter button" />} className={classes.buttons} style={{ marginLeft: '50px', marginTop: '-1px' }}
                                    onClick={() => { ClearFilterAsync(); }} variant="contained" color="primary" >
                                    Clear Filter
                                </Button>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item xs={5} sm={6} md={2} alignContent='flex-end'>
                                <IconButton id="UpLog_Filter_IconButton" aria-label="Filter" onClick={() => { onClickFilter(); }} style={{ color: 'blue' }} className={classes.filter} disabled={StartDate > EndDate}>
                                    <FilterListIcon style={{ fontSize: 35 }} />
                                </IconButton>
                                <IconButton id="UpLog_Filter_IconButton" aria-label="Filter" onClick={() => { ClearFilterAsync(); }} style={{ color: 'blue' }} className={classes.filter}>
                                    <ClearRoundedIcon style={{ fontSize: 35 }} />
                                </IconButton>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Paper>
                            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`} style={{ marginBottom: "15px" }}>
                                <Table aria-label="customized table" size="small" stickyHeader>
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {stableSort(ListLog.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                                            (row) => {
                                                return (
                                                    <StyledTableRow hover>
                                                        <StyledTableCell component="th" scope="row">
                                                            {row.fullusername}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {row.clientId === "MULC" ? "Multi Client" : row.clientId}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {row.phase === constant.CONT || row.phase === constant.PREC || row.phase === constant.FIRSTSTP ? row.phase : "Multi Phase"}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {moment(row.uploadDate).format(state.GlobalUtils?.settingValue)}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {moment(row.uploadTime).format('HH:mm:ss')}
                                                        </StyledTableCell>

                                                        <StyledTableCell >
                                                            {(row.uploadMethod === 0) ? "CSV Upload" : (row.uploadMethod === 1) ? "Manual Upload" : "Unmapped File Upload"}
                                                        </StyledTableCell>

                                                        <DownloadDocumentUploadFile row={row} />

                                                        <StyledTableCell>
                                                            <DocumentPDFViewer row={row} enable={row.fileName.split('.').pop() === 'pdf' ? true : row.fileName.split('.').pop() === 'PDF' ? true : false} />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {mapped === true ?
                                    <Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                        No records to display..
                                    </Typography>
                                    : null}
                            </TableContainer>
                            <TablePagination
                                id="UpLog_TablePagination"
                                rowsPerPageOptions={[10, 15, 25]}
                                component="div"
                                count={ListLog.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <Footer />
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    )
}

export default Uploadlog
