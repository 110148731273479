import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Box, Button, Grid, InputAdornment, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { IClientCodeName } from '../../../models/SendNotification/IClientCodeName';
import { IUserForClient } from '../../../models/SendNotification/IUserForClient';
import { IInsertNotification } from '../../../models/SendNotification/IInsertNotification';
import { useFetch, usePost } from '../../../utils/apiHelper';
import PuffLoader from 'react-spinners/PuffLoader';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import Footer from '../../../components/Footer/Footer'
import SendIcon from '@material-ui/icons/Send';
import PersonIcon from '@material-ui/icons/Person';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '-0.2cm',
    backgroundColor: 'white',
    maxWidth: "90vw",
    marginBottom: "10px",
    border: '2px solid #215176',
    alignItems: "center",
    height: '693px',
    borderRadius: 20,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  paper: {
    maxwidth: 274,
    height: 300,
    maxheight: 300,
    marginTop: '5px',
    overflow: 'auto',
    overflowX: 'hidden',
    borderRadius: 10,
    borderTop: '2px #215176 solid',
  },
  gridstyle: {
    border: '2px #215176 solid',
    marginTop: "0.6cm",
    borderRadius: 10,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  description: {
    marginTop: "0.2cm",
    width: '49vw',
    marginLeft: '-1.3cm',
    height: '23.07vh',
    border: '2px #215176 solid',
    borderRadius: 10,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  div: {
    flexGrow: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  titleHeader: {
    color: "blue",
    textAlign: 'center',
  },
  agencyfiled: {
    marginTop: '0.3cm',
    width: "15vw",
    borderRadius: 10,
    border: "2px solid #215176",
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  agencyfieldnames: {
    marginTop: '0.5cm',
    width: "10vw",
    fontSize: 18,
    textAlign: 'left',
    marginLeft: '1.5cm'
  },
  agencyfieldnames1: {
    marginTop: '1.3cm',
    width: "10vw",
    fontSize: 18,
    textAlign: 'left',
    marginLeft: '1.5cm'
  },
  agencyfieldnames2: {
    marginTop: '1.3cm',
    width: "10vw",
    fontSize: 18,
    textAlign: 'left',
    marginLeft: '1.5cm'
  },
  selectallbutton: {
    marginTop: "3.5cm",
    borderRadius: 20,
    width: "9vw",
    height: "40px",
    fontSize: 16,
    fontWeight: 'bold'
  },
  removeallbutton: {
    marginTop: "1cm",
    borderRadius: 20,
    width: "9vw",
    height: "40px",
    fontSize: 16,
    fontWeight: 'bold'
  },
  searchIcon: {
    borderRadius: 50,
    background: "white",
    marginTop: '0.3cm',
    width: "15vw",
    height: '45px',
    float:'left',
    marginLeft:'1.2cm',
    border: '2px #215176 solid',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    fontSize: 16,
    textIndent: '10px'
  },
  searchIcon1: {
    borderRadius: 50,
    background: "white",
    marginTop: '0.3cm',
    width: "15vw",
    height: '45px',
    border: '2px #215176 solid',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    fontSize: 16,
    textIndent: '10px'
  },
  sendNotification: {
    height: "44px",
    marginTop: '0.3cm',
    width: "14.4vw",
    borderRadius: 50,
    marginLeft: '4.8cm',
    fontSize: 16,
    fontWeight: 'bold'
  },
  clientheader: {
    fontSize: 17,
    marginTop: '5px'
  },
  snackbar1: {
    color: 'black',
    marginTop: '-0.5cm',
  },
  snackbarFont: {
    fontSize: 16,
  },
  label: {
    '&$focused': {
      color: 'white',
      border: '0px'
    },
  },
  focused: {},
  outlinedInput: {
    '&$focused $notchedOutline': {
      color: 'white',
      border: '0px'
    },
  },
  notchedOutline: {
    color: 'white',
    border: '0px'
  },
}));

export default function NativeSelects() {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState()
  const [AgencyId, setAgencyId] = useState<string>("");
  const [AccountNo, setAccountNo] = useState<string>("");
  const [Settlement, setSettlement] = useState<string>("");
  const [Description, setDescription] = useState<string>("");
  const [ClientCodes, setClientCodes] = useState<IClientCodeName[]>([]);
  const [SelectedClientCodes, setSelectedClientCodes] = useState<IClientCodeName[]>([]);
  const [UsersForClient, setUsersForClient] = useState<IUserForClient[]>([]);
  const [SelectedUsers, setSelectedUsers] = useState<IUserForClient[]>([]);
  const [SearchTerm, setSearchTerm] = useState("");
  const [AgencyIdError, setAgencyIdError] = useState<string>("");
  const [AccountNoError, setAccountNoError] = useState<string>("");
  const [SettlementError, setSettlementError] = useState<string>("");
  const [searchText, setSearchText] = useState<string>('');
  const [isValid, setIsValid] = useState(false);
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let response = await useFetch<IClientCodeName[]>('GetClientCodeList');
        setshowProgressBar(true);
        setClientCodes(response.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  const getUsersForClient = (clientCodes) => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": clientCodes
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setshowProgressBar(true);
        setUsersForClient(responseUser.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const clearClientLists = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let response = await useFetch<IClientCodeName[]>('GetClientCodeList');
        setClientCodes(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const GetClientLists = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await useFetch<IClientCodeName[]>(`GetClientSearchList?searchText=${searchText}`);
        setClientCodes(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const GetSelectedUserID = () => {
    let userIds = "";
    SelectedUsers.forEach((user) => {
      userIds += user.id;
      userIds += ","
    })
    return userIds.substr(0, userIds.length - 1);
  }

  const getClientUsersAsync = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": selectedClient,
          "name": SearchTerm
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setUsersForClient(responseUser.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const getUsersClient = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": selectedClient
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setshowProgressBar(true);
        setUsersForClient(responseUser.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const Insert = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        if (!isValidRequest()) {
          alert("Please enter all the required details!");
          return;
        }
        const formData = new FormData();
        formData.append("TypeId", "4");
        formData.append("ExtraData1", GetSelectedUserID());
        formData.append("ExtraData2", AgencyId);
        formData.append("ExtraData3", AccountNo);
        formData.append("ExtraData4", Settlement + "µ" + Description);
        const response = await usePost<IInsertNotification>('InsertAdminNotification', formData)
          .then((r) => {
            if (r.status === 202) {
              setOpen(true);
              resetEverything();
            }
          })
      }
      catch (ex) {
        alert('Kindly check your AgencyNo, ClientAccNO, Selected Users are Valid');
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  const handleClientCodeToggle = (client: IClientCodeName) => () => {
    let newSelectedClientCodes = [...SelectedClientCodes]

    if (newSelectedClientCodes.some(c => c.clientCode == client.clientCode)) {
      newSelectedClientCodes = newSelectedClientCodes.filter(c => c.clientCode != client.clientCode);
    } else {
      newSelectedClientCodes.push(client);
    }

    let clientcodes = "";
    for (var checkClientCode in newSelectedClientCodes) {
      clientcodes += newSelectedClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    setSelectedClientCodes(newSelectedClientCodes);
    getUsersForClient(clientcodes);
    setSelectedClient(clientcodes);
    setshowProgressBar(true);
  }

  const handleUserToggle = (user: IUserForClient) => () => {
    let newSelectedUsers = [...SelectedUsers];
    if (newSelectedUsers.some(u => u.id == user.id)) {
      newSelectedUsers = newSelectedUsers.filter(u => u.id != user.id);
    } else {
      newSelectedUsers.push(user);
    }
    setSelectedUsers(newSelectedUsers);
  }

  const handleAllRight = () => {
    let clientcodes = "";
    for (var checkClientCode in ClientCodes) {
      clientcodes += ClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    getUsersForClient(clientcodes);
    setSelectedClientCodes(ClientCodes)
    setshowProgressBar(false);
  };

  const handleAllLeftClient = () => {
    let clientcodes = "";
    getUsersForClient(clientcodes);
    setSelectedClientCodes([]);
    setshowProgressBar(false);
  }

  const handleCheckedRight = () => {
    setSelectedUsers(UsersForClient);
    setshowProgressBar(false);
  };

  const handleAllLeft = () => {
    setSelectedUsers([]);
    setshowProgressBar(false);
  };

  function isValidRequest() {
    if (AgencyId != "" && AccountNo != "" && Settlement != "")
      return true;
    return false;
  }

  function resetEverything() {
    setAgencyId("");
    setAccountNo("");
    setSettlement("");
    setDescription("");
  }

  const HandleAgencyIdVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,:;a-zA-Z {}[\]/\\$'"]/)) {
      setAgencyIdError("");
      setIsValid(false);
      setAgencyId(newValue);
    } else {
      setAgencyIdError("please enter numbers only without space");
      setIsValid(true);
    }
  };

  const HandleAccountVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,:;a-zA-Z {}[\]/\\$'"]/)) {
      setAccountNoError("");
      setIsValid(false);
      setAccountNo(newValue);
    } else {
      setAccountNoError("please enter numbers only without space");
      setIsValid(true);
    }
  };

  const HandleAmountVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?:;a-zA-Z{}/|[\]\\$'"]/)) {
      setSettlementError("");
      setIsValid(false);
      setSettlement(newValue);
    } else {
      setSettlementError("please enter numbers only");
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (AgencyId.length > 9) {
      setAgencyIdError("Max length is 9")
      setIsValid(true);
    }
    else if (AccountNo.length > 9) {
      setAccountNoError("Max length is 9")
      setIsValid(true);
    }
    else if (Settlement.length > 7) {
      setSettlementError("Max length is 7")
      setIsValid(true);
    }
    else {
      setIsValid(false);
    }
  }, [AgencyId, AccountNo, Settlement])

  const clientCodeList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.clientheader} >
        <b>CLIENTS</b>
      </Typography>
      <Paper className={classes.paper} >
        <List dense component="div" role="list">
          {ClientCodes.slice(0, 150).map((client, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleClientCodeToggle(client)}>
                <ListItemIcon>
                  <Checkbox
                    size="medium"
                    style={{ color: "#00e676", width: 50, height: 38 }}
                    id="SA_ClientCode_Checkbox"
                    checked={SelectedClientCodes.some(c => c.clientCode == client.clientCode)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom style={{ textAlign: 'left', fontSize: 16 }}>
                    <b>{client.clientCode}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  const usersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientheader} gutterBottom color="secondary">
        <b>USERS</b>
      </Typography>
      <Paper className={classes.paper} >
        <List dense component="div" role="list">
          {UsersForClient.map((user, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleUserToggle(user)}>
                <ListItemIcon>
                  <Checkbox
                    style={{ color: "#00e676", width: 50, height: 38 }}
                    id="NF_Users_Checkbox"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    checked={SelectedUsers.some(u => u.id == user.id)}
                  />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom style={{ textAlign: 'left', fontSize: 16 }}>
                    <b>{user.firstName}{user.lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  const selectedUsersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientheader} gutterBottom color="secondary" >
        <b>ADD TO NOTIFICATION</b>
      </Typography>
      <Paper className={classes.paper} >
        <List dense component="div" role="list">
          {SelectedUsers.map(({ firstName, lastName }, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem">
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom style={{ textAlign: 'left', fontSize: 16 }}>
                    <b>{firstName}{lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Typography variant="h4" gutterBottom className={classes.titleHeader} >
          <b> SETTLEMENT AUTHORIZATION </b>
        </Typography>
        <Container fixed className={classes.container}>
          <Grid container spacing={0} style={{ marginTop: '0.5cm' }}>
            <Grid item xs={12} style={{ backgroundColor: 'green' }}>

            </Grid>
            <Grid item xs={2}>
              <Box>
                <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.agencyfieldnames}>
                  <b>Agency Acct# : </b>
                </Typography>
              </Box>
              <Box>
                <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.agencyfieldnames1}>
                  <b>Client Acct# : </b>
                </Typography>
              </Box>
              <Box>
                <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.agencyfieldnames2}>
                  <b>Settlement Offer : </b>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                <TextField
                  className={classes.agencyfiled}
                  id="SA_AgencyAcct" placeholder="Agency Acct#" variant="outlined" name="ExtraData2" value={AgencyId}
                  onChange={(e) => { setAgencyId(e.target.value); HandleAgencyIdVal(e); }} inputProps={{ maxlength: 9 }}
                  helperText={AgencyIdError} error={!!AgencyIdError}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }} />
              </Box>
              <Box>
                <TextField
                  className={classes.agencyfiled}
                  id="SA_ClinetAcct" placeholder="Client Acct#" variant="outlined" name="ExtraData3"
                  value={AccountNo} onChange={(e) => { setAccountNo(e.target.value); HandleAccountVal(e); }} inputProps={{ maxlength: 9 }}
                  helperText={AccountNoError} error={!!AccountNoError}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }} />
              </Box>
              <Box>
                <TextField id="SA_SettlementOffer" variant="outlined" name="ExtraData4"
                  className={classes.agencyfiled}
                  value={Settlement} onChange={(e) => { setSettlement(e.target.value); HandleAmountVal(e); }}
                  inputProps={{ maxlength: 7 }} helperText={SettlementError} error={!!SettlementError}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Amount #"
                  InputProps={{
                    startAdornment: (<InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>),
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }} />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <TextField
                placeholder="Enter the Message..."
                size="medium"
                id="SA_Description"
                multiline
                rows={9}
                value={Description} onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                inputProps={{ maxlength: 500 }}
                InputLabelProps={{ shrink: true }}
                className={classes.description}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    focused: classes.focused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: '0.3cm' }}>
            <Grid item xs={4}>
              <input value={searchText}
                type='text' placeholder="Search Clients"
                className={classes.searchIcon}
                onChange={e => setSearchText(e.target.value)}
                onKeyPress={event => { if (event.key === 'Enter') { GetClientLists(); } }}
                maxLength={255}
              />
            </Grid>
            <Grid item xs={4}>
              <input value={SearchTerm}
                type='text' placeholder="Search Users"
                className={classes.searchIcon1}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyPress={event => { if (event.key === 'Enter') { getClientUsersAsync(); } }}
                maxLength={255}
              />
            </Grid>
            <Grid item xs={4}>
              <Button id="SA_SendNotification_btn" className={classes.sendNotification} endIcon={<SendIcon />}
                variant="contained" color="primary" onClick={Insert} disabled={!AgencyId || !AccountNo || !Settlement || !Description || isValid}>
                Send Notification
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={0} justify='space-evenly'>
            <Grid item xs={5} sm={6} md={2} className={classes.gridstyle} >
              {clientCodeList()}
            </Grid>
            <Grid item xs={5} sm={4} md={2}>
              <Button
                id="NF_SelectAll_btn"
                className={classes.selectallbutton}
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAllRight}
                aria-label="move all right"
                startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />} >
                Select All
              </Button>
              <Button
                id="NF_RemoveAllClient_btn"
                variant="contained"
                color="primary"
                size="small"
                className={classes.removeallbutton}
                onClick={handleAllLeftClient}
                disabled={SelectedClientCodes.length === 0}
                aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />} >
                Remove All
              </Button>
            </Grid>
            <Grid item xs={5} sm={6} md={2} className={classes.gridstyle} >
              {usersList()}
            </Grid>
            <Grid item xs={5} sm={5} md={2}>
              <Button
                id="NF_AddAll_btn"
                variant="contained"
                color="primary"
                className={classes.selectallbutton}
                size="small"
                onClick={handleCheckedRight}
                aria-label="move selected right" startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />}>
                Add All
              </Button>
              <Button
                id="NF_RemoveAll_btn"
                variant="contained"
                color="primary"
                className={classes.removeallbutton}
                size="small"
                onClick={handleAllLeft}
                aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />}>
                Remove All
              </Button>
            </Grid>
            <Grid item xs={5} sm={5} md={2} className={classes.gridstyle} >
              {selectedUsersList()}
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
      <Snackbar open={open} className={classes.snackbar1} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" className={classes.snackbarFont}>
          Settlement Offer Sent Successfully !
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}