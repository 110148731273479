import { Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Typography, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import * as constant from '../../constants/Constant';
import { IGetRolledPlacement } from '../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccount } from '../../models/Inquiry/IPlacedAccount';
import { IPlacedAccountContract } from '../../models/Inquiry/IPlacedAccountContract';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { IDebtor } from '../../models/Reports/IDebtor';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import ReportCloseAccount from "./CloseAccount";
import DocumentUpload from './ReportDocumentUpload';
import Submit from "./ReportSubmit";
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(2),
            },
        },
        rootHidden: {
            display: "none"
        },
        button: {
            paddingBottom: "10px",
            paddingTop: "10px",
            width: 165,
            borderRadius: 10,
            marginTop: "-40px",
            marginLeft: "0px",
        },
        inputField: {
            width: '95%',
            height: '56px',
            fontSize: 20,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginTop: '5px',
            marginBottom: '10px',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold'
        },
        inputField1: {
            width: '95%',
            height: '56px',
            fontSize: 20,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginBottom: '14px',
            textDecoration: 'bold',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold'
        },
        inputField2: {
            width: '95%',
            height: '56px',
            fontSize: 20,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginTop: '5px',
            marginBottom: '10px',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold'
        },
        inputField3: {
            width: '95%',
            height: '56px',
            fontSize: 20,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginBottom: '14px',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold'
        },
        snackbar: {
            color: 'black',
            marginTop: '-0.5cm',
        },
        snackbarfont: {
            fontSize: 16,
        },
        typographystyle: {
            marginTop: "-45px",
            textAlign: 'right',
            marginRight: '20px',
            color: '#800080'
        },
        typographystyle1: {
            marginTop: "-45px",
            textAlign: 'right',
            marginRight: '20px',
            color: '#800080'
        },
        gridView: {
            marginLeft: '-50px'
        },
        currentamountStyle: {
            marginTop: "-45px",
            textAlign: 'right',
            color: '#800080',
            marginRight: '30px',
        },
        currentamountStyle1: {
            textAlign: 'right',
            marginTop: "15px",
            marginRight: '30px',
        },
        currentamountStyle2: {
            textAlign: 'right',
            marginTop: "26px",
            marginLeft: "20px",
            marginRight: '30px',
        },
        currentamountStyle3: {
            color: 'green',
            textAlign: 'right',
            marginTop: "20px",
            marginRight: '30px',
        },
        updateamountstyle: {
            marginTop: "-8px",
            textAlign: 'left',
            marginLeft: '20px',
            textDecoration: 'bold',
            color: '#800080'
        },
        updateamountStyle1: {
            textAlign: 'right',
            marginRight: '90px',
            marginTop: "15px",
        },
        updateamountStyle2: {
            textAlign: 'right',
            marginRight: '90px',
            marginTop: "25px",
        },
        updateamountStyle3: {
            color: 'green',
            textAlign: 'right',
            marginRight: '90px',
            marginTop: "20px",
        },
        buttonStyle: {
            borderRadius: 20,
            minWidth: '130px',
            marginTop: '-70px',
        },
        typographyStyle: {
            marginTop: '-7px',
            color: 'green',
        },
        typographyStyle1: {
            marginTop: '-2px', height: "10px"
        },
        typographyStyle2: {
            marginTop: '22px', height: "10px"
        },
        typographyStyle3: {
            color: 'green',
            marginTop: '-10px'
        },
        textStyle: {
            marginTop: "8px",
            marginRight: '-50px',
        },
        textStyle1: {
            marginTop: "23px",
            marginRight: '-50px',
        },
        textStyle2: {
            marginTop: "23px",
            marginRight: '-50px',
        },
        gridView1: {
            flex: 1, marginTop: "-20px",
        },
        yesButton: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            bottom: '0.1cm',
            right: '15px',
            '&:hover': {
                background: "green",
            }
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            right: '10px',
            bottom: '0.1cm',
            '&:hover': {
                background: "red",
            }
        }
    }),
)

const ReportAccountDetails: React.FC<{ debtors: IDebtor, disablee: IGetRolledPlacement, isEnable: IGetRolledPlacement, letterDetails: IClosedLetterDetails, onHideAll: () => void, BlockAwaitUsers: boolean }> = (props) => {
    const { debtors, letterDetails, disablee, isEnable, BlockAwaitUsers } = props
    const theme = useTheme();
    const classes = useStyles()
    const falseObject = {
        "DocUpload": false,
        "CloseRemove": false,
        "CloseAcc": false,
        "SubmitValue": false
    }
    const [object, setObject] = useState(falseObject);
    const { state, dispatch } = useGlobalState()
    const [isValid, setIsValid] = useState(true);
    const [inputVal, setInputVal] = useState<any>('');
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>('');
    const [principalAdjustmentAmount, setPrincipalAdjustmentAmount] = useState<any>('');
    const [latePaymentAmount, setLatePaymentAmount] = useState<any>('');
    const [lateAdjustmentAmount, setLateAdjustmentAmount] = useState<any>('');
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props.debtors.principalDue);
    const [updatedLateAmount, setUpdatedLateAmount] = useState(props.debtors.fee);
    const [updatedTotalAmount, setUpdatedTotalAmount] = useState(updatedPrincipalAmount + updatedLateAmount);
    const [placedAccounts, setPlacedAccounts] = useState<IPlacedAccount[]>([]);
    const [callGetPlacement, setCallGetPlacement] = useState(true);
    const [principalPaymentDue, setPrincipalPaymentDue] = useState<number>(props.debtors.principalDue);
    const [LateFeeDue, setLateFeeDue] = useState<number>(props.debtors.fee);
    const canUpdatePayment = state.userAccessContext?.paymentUpdates;
    const [service, setService] = useState('');
    const [submit, setSubmit] = React.useState(false);
    const [disable, setDisable] = useState(false);
    const referralDate = moment(debtors.referralDate).format(state.GlobalUtils?.settingValue);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const UserName = state.userAccessContext?.id;
    const TotalBalance = principalPaymentDue + LateFeeDue;
    const canMakePayment = state.userAccessContext?.paymentUpdates;
    const [checkFullAmount, setCheckFullAmount] = useState(false);
    const [ConfirmDialog, setConfirmDialog] = useState(false);
    const [paymentVal, setPaymentVal] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${debtors.agencyID}`)
                setPlacedAccounts(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    const handleConfirmDialogCLose = () => {
        setConfirmDialog(false);
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
    }

    const handleCheckFullAmountDialog = () => {
        setPaymentVal(true);
        setCheckFullAmount(false);
    }

    function emptyCheck() {
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
    }

    function recursiveRemove(inputAmt: string, target: number) {
        if (inputAmt == undefined) return "";
        return parseFloat(inputAmt) > target ?
            recursiveRemove(inputAmt = inputAmt.slice(0, -1), target) :
            inputAmt.toString();
    }

    const handleOnValueChange = (value: string): void => {
        setDisable(true);
        setPrincipalPaymentAmount(recursiveRemove(value, debtors.principalDue));
        setInputVal(Number(value) > 0 && parseFloat(value) <= debtors.principalDue ? value : principalAdjustmentAmount || lateAdjustmentAmount || latePaymentAmount);
    };

    const handleFlatfeeValueChange = (value: string): void => {
        setDisable(true);
        setLatePaymentAmount(recursiveRemove(value, debtors.fee));
        setInputVal(Number(value) > 0 ? value : lateAdjustmentAmount || principalAdjustmentAmount || principalPaymentAmount);
    };

    const PricipalAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setPrincipalAdjustmentAmount(value === '' ? '' : value || '');
        setInputVal(value === '' ? principalPaymentAmount : value || principalPaymentAmount || latePaymentAmount);
    };

    const LateAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setLateAdjustmentAmount(value === '' ? '' : value || '');
        setInputVal(value === '' ? latePaymentAmount : value || latePaymentAmount || principalPaymentAmount);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSubmit(false);
    };

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        let principal_amount_due = debtors.principalDue;
        for (let account of placedAccounts) {
            if (account.prcpl_Adj_Amt && account.prpcl_Pay_Amt === 0) {
                principal_amount_due = ((principal_amount_due) + (account.prpcl_Pay_Amt + account.prcpl_Adj_Amt));
            }
            else if (account.prcpl_Adj_Amt > 0 && account.prpcl_Pay_Amt > 0) {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
            else {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
        }
        let late_fee_due = debtors.fee;
        for (let account of placedAccounts) {
            if (account.fee_Adj_Amt && account.fee_Pay_Amt === 0) {
                late_fee_due = ((late_fee_due) + (account.fee_Pay_Amt + account.fee_Adj_Amt));
            }
            else if (account.fee_Adj_Amt > 0 && account.fee_Pay_Amt > 0) {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
            else {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
        }
        setPrincipalPaymentDue(Number(principal_amount_due.toFixed(2)));
        setLateFeeDue(Number(late_fee_due.toFixed(2)));
    }, [placedAccounts])

    useEffect(() => {
        setUpdatedPrincipalAmount((Number((principalPaymentDue - principalPaymentAmount) + Number(principalAdjustmentAmount))));
        setUpdatedLateAmount((Number((LateFeeDue - latePaymentAmount) + Number(lateAdjustmentAmount))));
    }, [LateFeeDue, principalPaymentDue, principalPaymentAmount, principalAdjustmentAmount, latePaymentAmount, lateAdjustmentAmount])

    useEffect(() => {
        setUpdatedTotalAmount(Number((updatedPrincipalAmount + updatedLateAmount).toFixed(2)));
        (updatedPrincipalAmount < 0 || updatedLateAmount < 0) ? setIsValid(false) : setIsValid(true);
    }, [updatedPrincipalAmount, updatedLateAmount])

    useEffect(() => {
        if (debtors.phase === constant.CONT) {
            setService('Contingency');
        }
        if (debtors.phase === constant.PREC) {
            setService('Flat Fee');
        }
        else if (debtors.phase === constant.FIRSTSTP) {
            setService('1st Party');
        }
    }, [service])

    async function CheckPreviousPayment(dbt_NO: string) {
        dispatch({ type: GlobalStateAction.Busy })
        setButtonLoading(true);
        try {
            // Check if the full account balance is adjusted to zero without payment.
            if (updatedTotalAmount === 0.00 && ((principalPaymentAmount === "0.00" || principalPaymentAmount === "") && (latePaymentAmount === "" || latePaymentAmount === "0.00"))) {
                setCheckFullAmount(true);
            } else {
                setCheckFullAmount(false);
                // Check if payment matches previous pending payment.
                const response = await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${dbt_NO}`);
                if (response.data.length === 0 ? true : false) {
                    submitPayment();
                } else {
                    setConfirmDialog(true);
                }
            }
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    const submitPayment = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                if (btnDisabled === false) {
                    let request = {
                        "dbt_No": props.debtors.agencyID,
                        "prcpl_Pay_Amt": principalPaymentAmount === "" ? 0.00 : parseFloat(principalPaymentAmount),
                        "prcpl_Adjst_Amt": principalAdjustmentAmount === "" ? 0.00 : parseFloat(principalAdjustmentAmount),
                        "fee_Pay_Amt": latePaymentAmount === "" ? 0.00 : parseFloat(latePaymentAmount),
                        "fee_Adjst_Amt": lateAdjustmentAmount === "" ? 0.00 : parseFloat(lateAdjustmentAmount),
                        "user_Id": UserName
                    };
                    const response = await usePost<any>('Account/SubmitPayment', request);
                    hidenotificationForAllUsers();
                    setDisable(true);
                    setButtonLoading(false);
                    setSubmit(true);
                    GetPayment();
                    setPaymentVal(false);
                }
                else {
                    let loadHistory = {
                        "updatedBy": UserName,
                        "dbt_No": debtors.agencyID,
                        "operationType": 2,//No Payment Made
                        "transactionText": 'No Payment Received'
                    };
                    const TransHistory = await usePost("Log/TransactionHistoy", loadHistory)

                    let request = {
                        "dbtNumber": debtors.agencyID,
                        "close_acct_note": null,
                        "close_acct_bal": null,
                        "close_acct_reason": "No Payment Received",
                        "user_Id": UserName,
                    };
                    const response = await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', request);

                    let nopaymentMadeRequest = {
                        "username": state.userAccessContext?.userName,
                        "dbtNo": debtors.agencyID
                    }
                    // await usePost("SendNopaymentMade", nopaymentMadeRequest)
                    const nopayment = await axios.post(`${constant.NoPaymentMade}/${debtors.agencyID}?`);
                    hidenotificationForAllUsers();
                    setBtnDisabled(false);
                    emptyCheck();
                    setPaymentVal(false);
                }
                props.onHideAll();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                emptyCheck();
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": debtors.agencyID,
                    "typeId": 5
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const GetPayment = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${debtors.agencyID}`)
                setPlacedAccounts(response.data);
                setCallGetPlacement(false)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const handleCheckboxChange = (event) => {
        setBtnDisabled(event.target.checked)
    }

    const hideDocNotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": debtors.agencyID,
                    "typeId": 6
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <Grid container className={classes.root} alignContent='flex-start' alignItems='flex-start'>
                <Snackbar className={classes.snackbar} open={submit} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="success" className={classes.snackbarfont}>
                        Account Submitted Successfully!
                    </Alert>
                </Snackbar>
                <Grid item xs={'auto'} direction='column' >
                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle}><b>Principal</b></Typography>
                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle1}><b>Late Fee</b></Typography>
                    <Typography variant="h6" component="h6" gutterBottom className={classes.textStyle2}><b>Total</b></Typography>
                </Grid>
                <Grid item xs={2} className={classes.gridView} direction='column' >
                    <Typography variant="h6" component="h6" gutterBottom className={classes.currentamountStyle}><b>Current Amount Due </b></Typography>
                    <Typography variant="h5" component="h5" gutterBottom className={classes.currentamountStyle1}><b>${principalPaymentDue.toFixed(2)}</b></Typography>
                    <Typography variant="h5" component="h5" gutterBottom className={classes.currentamountStyle2}><b>${LateFeeDue.toFixed(2)}</b></Typography>
                    <Typography variant="h5" component="h5" className={classes.currentamountStyle3}><b>${TotalBalance.toFixed(2)}</b></Typography>
                </Grid>
                <Grid item xs={3} style={{ flex: 1 }} direction='column'>
                    <Typography variant="h6" component="h6" gutterBottom className={classes.typographystyle}><b>Payment Amount</b></Typography>
                    <CurrencyInput
                        id="R_PrincipalPaymentAmount"
                        placeholder="$ Principal Payment Amount"
                        className={classes.inputField}
                        value={principalPaymentAmount}
                        allowNegativeValue={false}
                        prefix='$'
                        decimalsLimit={2}
                        onValueChange={handleOnValueChange}
                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                        onKeyPress={preventMinus}
                        decimalScale={2}
                        maxLength={13}
                        disabled={BlockAwaitUsers || !canMakePayment}
                    />
                    <CurrencyInput
                        className={classes.inputField1}
                        id="R_LatePaymentAmount"
                        allowNegativeValue={false}
                        value={latePaymentAmount}
                        placeholder="$ Late Fee Payment Amount"
                        prefix='$'
                        decimalsLimit={2}
                        onValueChange={handleFlatfeeValueChange}
                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                        onKeyPress={preventMinus}
                        decimalScale={2}
                        maxLength={13}
                        disabled={BlockAwaitUsers || !canMakePayment}
                    />
                </Grid>
                <Grid item xs={3} style={{ flex: 1 }} direction='column'>
                    <Typography variant="h6" component="h6" gutterBottom className={classes.typographystyle1}><b>Adjustment Amount</b></Typography>
                    <CurrencyInput
                        className={classes.inputField2}
                        id="R_principalAdjustmentAmount"
                        value={principalAdjustmentAmount}
                        placeholder="$ Principal Adjustment Amount"
                        prefix='$'
                        decimalsLimit={2}
                        onValueChange={PricipalAdjValueChange}
                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                        decimalScale={2}
                        maxLength={13}
                        disabled={BlockAwaitUsers || !canMakePayment}
                    />
                    <CurrencyInput
                        className={classes.inputField3}
                        id="R_lateAdjustmentAmount"
                        value={lateAdjustmentAmount}
                        placeholder="$ Late Fee Adjustment Amount"
                        prefix='$'
                        decimalsLimit={2}
                        onValueChange={LateAdjValueChange}
                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                        decimalScale={2}
                        maxLength={13}
                        disabled={BlockAwaitUsers || !canMakePayment}
                    />
                </Grid>
                <Grid item xs={2} className={classes.gridView1} direction='column' >
                    <Typography variant="h6" component="h6" gutterBottom className={classes.updateamountstyle}><b>Updated Amount Due</b></Typography>
                    <Typography variant="h5" component="h5" gutterBottom className={classes.updateamountStyle1}><b> ${updatedPrincipalAmount.toFixed(2)}</b></Typography>
                    <Typography variant="h5" component="h5" gutterBottom className={classes.updateamountStyle2}><b>${updatedLateAmount.toFixed(2)}</b></Typography>
                    <Typography variant="h5" component="h5" gutterBottom className={classes.updateamountStyle3}><b>${updatedTotalAmount.toFixed(2)}</b></Typography>
                </Grid>
                <Grid item xs={3} style={{ flex: 1 }} direction='column'>
                    <React.Fragment>
                        {debtors.dbT_Status === constant.AwaitingPayment338 || debtors.dbT_Status === constant.AwaitingPayment324 ?
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={btnDisabled}
                                        onChange={handleCheckboxChange}
                                        name="checkedB"
                                        color="primary"
                                        disabled={!state.userAccessContext?.paymentUpdates}
                                    />
                                }
                                label="No Payment Made"
                            />
                            :
                            null
                        }
                        <Typography variant="h4" component="h4" gutterBottom className={classes.typographyStyle3}><b>{service}</b></Typography>
                        <Typography variant="h6" component="h6" gutterBottom className={classes.typographyStyle}>{props.debtors.status.replace('|', " ")}</Typography>
                        <Typography variant="subtitle1" gutterBottom className={classes.typographyStyle1}><b>Referral Date: {referralDate}</b></Typography>
                        <Typography variant="subtitle1" gutterBottom className={classes.typographyStyle2}><b>Referral Amount: ${props.debtors.referralAmt.toFixed(2)}</b></Typography>
                    </React.Fragment>
                </Grid>
                <Grid container style={{ marginBottom: "-50px" }}>
                    <Grid item xs={6} sm={2}>
                        <Button id="R_Submit_btn" type="submit" variant="contained" color="primary" disabled={!disable || !canUpdatePayment || !isValid || !inputVal || BlockAwaitUsers}
                            startIcon={<CheckCircleIcon />}
                            onClick={(_) => { setObject({ ...falseObject, "SubmitValue": !object.SubmitValue }); CheckPreviousPayment(props.debtors.agencyID); }}
                            className={classes.button} style={{ background: "#009900" }}>
                            Submit
                            {buttonLoading === true ? <CircularProgress style={{ color: 'white', height: "25px", width: "25px", marginLeft: '20px' }} /> : null}
                        </Button>
                        <Submit clientId={props.debtors.clntAcct1} dbtNo={props.debtors.agencyID} placedAccounts={placedAccounts} callGetPlacement={() => setCallGetPlacement(true)} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} />
                        <Collapse in={object.SubmitValue} timeout="auto">
                        </Collapse>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <ReportCloseAccount dbtNumber={props.debtors.agencyID} clientId={props.debtors.client} Disable={isEnable} dbT_PRINCIPAL_DUE={debtors.principalDue} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <DocumentUpload clientId={props.debtors.client} letterDetails={props.letterDetails} agencyId={props.debtors.agencyID} accountId={props.debtors.clntAcct1} onHideNotification={hideDocNotificationForAllUsers} BlockAwaitAccess={BlockAwaitUsers} />
                    </Grid>
                </Grid>
                <span style={{ marginLeft: "1px", marginTop: "40px", marginBottom: "-30px" }}>
                    {!isValid ?
                        <Typography variant="h6" style={{ color: "red" }} display="block" gutterBottom>
                            Please enter a payment amount that is less than the amount due.
                        </Typography> : null}
                    {paymentVal ?
                        <Typography variant="h6" style={{ color: "red" }} display="block" gutterBottom>
                            Please enter any payment amounts in the payment boxes.
                        </Typography> : null}
                </span>
            </Grid>

            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'md'}
                fullScreen={fullScreen}
                open={ConfirmDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleConfirmDialogCLose}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" style={{ marginTop: "10px", color: "black" }} gutterBottom>
                            One or more of your payments/adjustments matches the value of a pending update.  <br />
                            Are you sure you want to add this additional update to the account?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" variant="contained" onClick={() => { setConfirmDialog(false); submitPayment(); }} className={classes.yesButton} color="primary" autoFocus>
                        Ok
                    </Button>
                    <Button id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.noButton} onClick={handleConfirmDialogCLose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'md'}
                fullScreen={fullScreen}
                open={checkFullAmount}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCheckFullAmountDialog}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" style={{ marginTop: "10px", color: "black" }} gutterBottom>
                            The account balance is being changed to $0.00.  Was a payment made?<br /><br />
                            If Yes, click OK.<br />
                            If No, Cancel.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" variant="contained" onClick={handleCheckFullAmountDialog} className={classes.yesButton} color="primary" autoFocus>
                        OK
                    </Button>
                    <Button id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.noButton} onClick={() => { setCheckFullAmount(false); submitPayment(); }} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default ReportAccountDetails