import { Button, Grid, makeStyles, Snackbar, TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import * as constant from '../../constants/Constant';
import { IClientCodeConfirmation } from '../../models/client/IClientCodeConfirmation';
import { IClientDetails } from '../../models/client/IClientDetails';
import { IClientTypeCount } from '../../models/client/IClientTypeCount';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  searchBox: {
    marginTop: "4%",
    borderRadius: 20
  },
  container: {
    marginTop: "1cm",
    alignItems: "center"
  },
  textfield: {
    width: "90%",
    backgroundColor: 'white',
    color: "white",
    marginLeft: "100px",
    maxWidth: "300px",
    borderBottom: '2px solid blue',
    borderRadius: '10px',
  },
  textfield1: {
    marginLeft: "-150px",
    width: "75%",
    height: "10%",
  },
  container2: {
    marginTop: "1cm",
    marginBottom: "1cm",
    alignItems: "center",
    backgroundColor: '#eef1f5'
  },
  formControl: {
    minWidth: 280,
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    marginTop: "1cm"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grid: {
    padding: '10px'
  },
  maingrid: {
    borderRadius: '10px',
  },
  secondGrid: {
    backgroundColor: 'white',
    padding: '7px',
    marginTop: '10px',
    borderRadius: '5px',
    borderStyle: 'groove',
    borderColor: 'blue',
    border: '2px blue groove',
    maxHeight: 655,
    display: 'flex',
    overflowY: 'scroll'
  },
  div: {
    flexGrow: 1,
  },
  progressBar: {
    height: '6px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  yesbutton: {
    backgroundColor: 'green',
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  title: {
    color: "blue",
    marginTop: '8px',
    float: 'left',
    marginLeft: '10px',
    fontSize: 28,
  },
  confirmText: {
    marginTop: '15px',
    float: 'right',
    marginRight: '-3cm'
  },
  confirmButton: {
    borderRadius: 20,
    marginTop: "5px",
    float: 'right',
    marginRight: '-0.5cm',
    background: "#008000",
    color: "white",
    '&:hover': {
      backgroundColor: "#008000"
    }
  },
  notconfirmText: {
    marginTop: '15px',
    float: 'right',
    marginRight: '-2cm'
  },
  notconfirmButton: {
    borderRadius: 20,
    marginTop: '10px',
    float: 'right',
    background: "#FF6666",
    color: "white",
    '&:hover': {
      backgroundColor: '#FF6666'
    }
  },
  clientCode: {
    marginTop: '4px',
    marginLeft: "1.5cm",
    width: "45vw"
  }
}))

const ClientConfirmation = () => {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const [selectedClient, setSelectedClient] = useState<IClientCodeConfirmation>();
  const [selectedClientDetails, setSelectedClientDetails] = useState<IClientDetails>();
  const [confirmedBy, setConfirmedBy] = useState<string>('');
  const [confirmedCount, setconfirmedCount] = useState<number>(0);
  const [notConfirmedCount, setNotConfirmedCount] = useState<number>(0);
  const [clientCodeConfirmations, setClientCodeConfirmation] = useState<IClientCodeConfirmation[]>([]);
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [confirmsuccessdialog, setConfirmSuccessDialog] = React.useState(false);
  const isEnabled = confirmedBy != undefined && confirmedBy?.length > 0 && selectedClient != undefined;
  const userName = state.userAccessContext?.id;
  const NameOfUser = state.userAccessContext?.userName;
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [clientConfirmation, setClientConfirmationTrigger] = useState(false);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setClientConfirmationTrigger(false);
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
          const response = await useFetch<IClientTypeCount[]>("Client/ClientConfirmationTypeCount?userId=" + userName + "&isAdmin=" + true);
          var confirmedRow = response.data.find(e => e.type === "Confirmed");
          setshowProgressBar(true);
          if (confirmedRow != null) {
            setconfirmedCount(confirmedRow.typeCount);
          }
          var notConfirmedRow = response.data.find(e => e.type === "Not confirmed");
          if (notConfirmedRow != null) {
            setNotConfirmedCount(notConfirmedRow.typeCount);
          }
        }
        else {
          const response = await useFetch<IClientTypeCount[]>("Client/ClientConfirmationTypeCount?userId=" + userName + "&isAdmin=" + false);
          var confirmedRow = response.data.find(e => e.type === "Confirmed");
          if (confirmedRow != null) {
            setconfirmedCount(confirmedRow.typeCount);
          }
          var notConfirmedRow = response.data.find(e => e.type === "Not confirmed");
          if (notConfirmedRow != null) {
            setNotConfirmedCount(notConfirmedRow.typeCount);
          }
        }
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
        setshowProgressBar(false);
      }
    })()
  }, [selectedClient, confirmedCount, notConfirmedCount])

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
          const response = await useFetch<IClientCodeConfirmation[]>("Client/ClientCodeConfirmations?userId=" + userName + "&isAdmin=" + true);
          setClientCodeConfirmation(response.data);
          setshowProgressBar(true);
        }
        else {
          const response = await useFetch<IClientCodeConfirmation[]>("Client/ClientCodeConfirmations?userId=" + userName + "&isAdmin=" + false);
          setClientCodeConfirmation(response.data);
        }
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
        setshowProgressBar(false);
      }
    })()
  }, [])

  function handleConfirm() {
    (async () => {
      if (selectedClient != null) {
        selectedClient.isConfirmed = "Yes";
        selectedClient.confirmedBy = confirmedBy ?? "";
        const response = await usePost<{ clientCodeConfirmation: IClientCodeConfirmation, totalRecordCount: number }>("Client/ClientStatusConfirmation", selectedClient)
          .finally(() => {
            setDialogOpen(false);
            setConfirmSuccessDialog(true);
            setClientConfirmationTrigger(true);
            setConfirmedBy('');
            HandleOnConfirm();
          });

        //Confirmation Email
        let request = {
          "clientCode": selectedClient.clientCode,
          "phase": selectedClient.phase,
          "confirmedBy": selectedClient.confirmedBy,
          "userName": NameOfUser
        };
        const EmailResponse = await usePost<any>("SendClientConfirmationEmail", request);
      }
    })()
  }

  function onClientSelect(event, value) {
    setSelectedClient(value);
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const response = await useFetch<IClientDetails>("Client/GetClientDetails?clientCode=" + value.clientCode + "&companyCode=" + value.phase);
        setSelectedClientDetails(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  function openConfirmDialog() {
    setDialogOpen(true);
  }

  const HandleConfirmationVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!#^&*()-+=%<>?,:/\\$'"]/)) {
      setError("");
      setIsValid(false);
      setConfirmedBy(newValue); // only set when successful
    } else {
      setError("please enter characters and numbers only");
      setIsValid(true);
    }
  };

  const HandleOnConfirm = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
          const response = await useFetch<IClientTypeCount[]>("Client/ClientConfirmationTypeCount?userId=" + userName + "&isAdmin=" + true);
          var confirmedRow = response.data.find(e => e.type === "Confirmed");
          if (confirmedRow != null) {
            setconfirmedCount(confirmedRow.typeCount);
          }
          var notConfirmedRow = response.data.find(e => e.type === "Not confirmed");
          if (notConfirmedRow != null) {
            setNotConfirmedCount(notConfirmedRow.typeCount);
          }
        }
        else {
          const response = await useFetch<IClientTypeCount[]>("Client/ClientConfirmationTypeCount?userId=" + userName + "&isAdmin=" + false);
          var confirmedRow = response.data.find(e => e.type === "Confirmed");
          if (confirmedRow != null) {
            setconfirmedCount(confirmedRow.typeCount);
          }
          var notConfirmedRow = response.data.find(e => e.type === "Not confirmed");
          if (notConfirmedRow != null) {
            setNotConfirmedCount(notConfirmedRow.typeCount);
          }
        }
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container spacing={1} className={classes.grid} wrap="wrap">
          <CssBaseline />
          <Grid container className={classes.maingrid} justify='center'>
            <Grid item xs={6} sm={3} md={2} >
              <Typography variant="h5" gutterBottom className={classes.title}>
                <b> CLIENT CONFIRMATION</b>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={5} style={{ backgroundColor: 'white' }}>
              <Autocomplete id="client-select" className={classes.clientCode}
                options={clientCodeConfirmations} onChange={onClientSelect}
                autoHighlight getOptionLabel={(option) => option.clientName}
                renderOption={(option) => (
                  <React.Fragment>
                    <span style={option.isConfirmed == "Yes" ? { color: "green" } : { color: "red" }}> {option.clientName}</span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField  {...params}
                    label="Search Client Code" variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs >
              <h2 className={classes.confirmText}>
                CONFIRMED:
              </h2>
            </Grid>
            <Grid item xs>
              <Button id="ConfCount_btn" className={classes.confirmButton} style={{ borderRadius: 20, marginTop: "12px", background: "#008000", color: "white", marginLeft: "0px" }}
                variant="contained" startIcon={<NotificationsActiveIcon />} >
                {confirmedCount}
              </Button>
            </Grid>
            <Grid item xs >
              <h2 className={classes.notconfirmText}>
                NOT CONFIRMED:
              </h2>
            </Grid>
            <Grid item xs >
              <Button id="NotConfClient_btn" className={classes.notconfirmButton}
                variant="contained" startIcon={<NotificationsActiveIcon />} >
                {notConfirmedCount}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} className={`${classes.secondGrid} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Grid item xs={4}>
              <TextField
                id="Conf_clientCode" className={classes.textfield}
                defaultValue="Client Code"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                }}
                value={selectedClientDetails?.clientCode} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="Conf_CompanyName" className={classes.textfield}
                defaultValue="Company Name"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                }}
                value={selectedClientDetails?.companyName} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="Conf_Address" className={classes.textfield}
                defaultValue="Address"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                }}
                value={selectedClientDetails?.address} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="Conf_PhoneNumber" className={classes.textfield}
                defaultValue="Phone Number"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                }}
                value={selectedClientDetails?.phoneNumber} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="Conf_Email" className={classes.textfield}
                defaultValue="Email"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                }}
                value={selectedClientDetails?.email} margin="dense" variant="outlined" />
            </Grid>
            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={4}>
                  <TextField
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Remittance Name"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={selectedClientDetails?.remittanceName} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}
            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={4}>
                  <TextField
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Remittance Address"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={selectedClientDetails?.remittanceAddress} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}
            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={4}>
                  <TextField
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Customer Contact PhoneNumber"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={selectedClientDetails?.customerContactPhoneNumber} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}
            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={4}>
                  <TextField
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Customer Payment Website"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={selectedClientDetails?.customerPaymentWebsite} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}
            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={4}>
                  <TextField
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Credit Cards Accepted"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={selectedClientDetails?.creditCardsAccepted} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}
            <Grid item xs={4}>
              <TextField
                id="Conf_BillingInformation" className={classes.textfield}
                defaultValue="Billing Information"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                }}
                value={selectedClientDetails?.billingInformation} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="Conf_BillingClientName" className={classes.textfield}
                defaultValue="Billing Client Name"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                }}
                value={selectedClientDetails?.billingClientName} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-read-only-input" className={classes.textfield}
                defaultValue="Billing Contact Name"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                }}
                value={selectedClientDetails?.billingContactName} margin="dense" variant="outlined" />
            </Grid>
            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={4}>
                  <TextField
                    id="Conf_CRP" className={classes.textfield}
                    defaultValue="Credit Reporting Preference"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField className={classes.textfield1}
                    value={selectedClientDetails?.creditReportingPreference} margin="dense" variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }} />
                </Grid>
              </React.Fragment>
              :
              null
            }
            <Grid item xs={12}>
              <TextField id="Conf_Description"
                placeholder="Please Type in your first and last name and click Confirm
                    to Confirm all information above accurated By @" value={confirmedBy} onChange={(e) => { setConfirmedBy(e.target.value); HandleConfirmationVal(e); }}
                style={{ width: "69%", marginTop: "20px", marginLeft: "-180px" }}
                variant="outlined"
                helperText={error} // error message
                error={!!error}
                inputProps={{
                  maxlength: 256
                }} />
            </Grid>
            <Grid item xs={12}>
              <Button id="Conf_Confirm_btn" style={{ borderRadius: 50, marginTop: "-80px", marginRight: "-1300px" }} disabled={!isEnabled || isValid || selectedClient.isConfirmed == "Yes"}
                variant="contained" color="primary" startIcon={<DoneAllIcon />} onClick={openConfirmDialog}>
                Confirm
              </Button>
            </Grid>
          </Grid>
          <Dialog
            PaperProps={{ style: { borderRadius: 15 } }}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
              <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                CLIENT CONFIRMATION
              </Typography>
            </DialogTitleHeader>
            <DialogContent>
              <Typography variant="h5" style={{ color: "black" }} gutterBottom>
                Are you sure, you want to confirm the Client ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button id="Conf_Okay_btn" className={classes.yesbutton} onClick={handleConfirm} autoFocus>
                Yes
              </Button>
              <Button id="Conf_Cancel_btn" className={classes.nobutton} onClick={handleCloseDialog} >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={clientConfirmation} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
            Client Confirmed Successfully.
          </Alert>
        </Snackbar>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default ClientConfirmation
