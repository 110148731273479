import { Backdrop, Card, CardContent, Grid, makeStyles, Slide, TextField, Typography, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { IGlobalMessage } from '../../../models/GlobalMessage/IGlobalMessage';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
        display: "flex",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 280,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    updateNotification: {
      borderRadius: '20px',
      marginBottom: '0.3cm',
      marginTop: '0.3cm',
      backgroundColor: 'green',
      '&:hover': {
        backgroundColor: 'green',
      }
    },
    card: {
      width: '350px',
      height: '280px',
      padding: '3px',
      marginLeft: "90px",
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    notificationName: {
      minWidth: 450,
      boxShadow: '0px 1px 4px  rgba(103, 128, 159, 1)',
      borderRadius: '3px',
      marginLeft: '20px',
    },
    notificationMessage: {
      minWidth: 450,
      boxShadow: '0px 1px 4px  rgba(103, 128, 159, 1)',
      borderRadius: '3px',
      marginLeft: '20px',
      marginTop: '20px'
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditNotification: React.FC<{ Messages: IGlobalMessage, onSave: (Messages: IGlobalMessage) => void }> = (props) => {
  const { Messages } = props
  const { state, dispatch } = useGlobalState()
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useRowStyles()
  const [messageName, setMessageName] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isValidDate, setValidDate] = useState(true);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  var GlobalMessage: IGlobalMessage = {
    id: Messages.id,
    name: Messages.name,
    message: Messages.message,
    start_date: Messages.start_date?.split('T')[0],
    end_date: Messages.end_date?.split('T')[0],
    date_created: Messages.date_created
  }

  const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsUpdate(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    useFetch<IGlobalMessage>("GlobalMessageById?id=" + Messages.id).then((r) => {
      setLocalState(r.data);
    })
  };

  const Close = () => {
    setOpen(false);
  }
  const [localState, setLocalState] = React.useState(GlobalMessage);
  const handleChange = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value
    });
  };

  useEffect(() => {
    if (localState.start_date === localState.end_date) {
      setValidDate(true);
    }
    else if (localState.end_date < localState.start_date) {
      setValidDate(true);
    }
    else {
      setValidDate(false);
    }
  }, [localState.start_date, localState.end_date])

  const handleClose = () => {
    (async () => {
      setOpen(false);
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      try {
        usePost('UpdateGlobalMessage', localState).then((r) => {
          if (r.status == 400) {
            dispatch({ type: GlobalStateAction.Error, error: r.statusText })
          }
          else if (r.status == 200) {
            props.onSave(localState);
          }
          setShowProgressBar(false);
          setIsUpdate(true);
        })
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  const HandleMessageVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,;:{}/|[\]\\$'"]/)) {
      setError("");
      setIsValid(false);
      setMessageName(newValue); // only set when successful
    } else {
      setIsValid(true);
      setError("please enter characters and numbers only");
    }
  };

  useEffect(() => {
    if (messageName.length > 50) {
      setError("Max length is 50")
      setIsValid(true);
    } else {
      setError("")
      setIsValid(false);
    }
  }, [messageName])

  return (
    <React.Fragment>
      <div>
        <Button id="GM_Edit" startIcon={<EditNotificationsIcon />} variant="contained" color="primary" onClick={handleClickOpen} style={{ marginTop: "8px", borderRadius: 20, backgroundColor: 'green' }}>Edit</Button>
      </div>
      <div>
        <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={isUpdate} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={UpdateMessage}>
          <Alert onClose={UpdateMessage} severity="success" style={{ fontSize: 16 }}>
            Notification Updated Successfully.
          </Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={Close}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
          TransitionComponent={Transition}
          PaperProps={{ style: { borderRadius: 15 } }}
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={Close}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
              EDIT NOTIFICATION
            </Typography>
          </DialogTitleHeader>
          <DialogContent >
            <Grid container style={{ marginTop: '25px' }}>
              <Grid xs={6} >
                <TextField
                  id="GM_E_NotificationName"
                  label="Notification Name"
                  variant="outlined"
                  name="name"
                  value={localState.name}
                  onChange={(e) => { handleChange(e); HandleMessageVal(e); }}
                  className={classes.notificationName}
                  helperText={error}
                  error={!!error}
                />
                <TextField id="GM_E_Message" label="Message" variant="outlined" name="message" value={localState.message} onChange={handleChange}
                  className={classes.notificationMessage} multiline rows={9} />
              </Grid>
              <Grid xs={6}>
                <Card className={classes.card}>
                  <CardContent>
                    <h3>From:</h3>
                    <TextField
                      id="GM_E_Startdate"
                      label="Start Date"
                      type="date"
                      variant="outlined"
                      name="start_date"
                      value={localState.start_date?.split('T')[0]}
                      onChange={handleChange}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <h3>To:</h3>
                    <TextField
                      id="GM_E_Enddate"
                      label="End Date"
                      type="date"
                      variant="outlined"
                      name="end_date"
                      value={localState.end_date?.split('T')[0]}
                      onChange={handleChange}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </CardContent>
                  {isValidDate ?
                    <Typography variant="subtitle2" style={{ color: 'red', textAlign: 'center' }}>
                      Please enter a date after the previous value
                    </Typography>
                    :
                    null
                  }
                </Card>
              </Grid>
            </Grid>
            <DialogActions>
              <Button id="GM_E_Update_btn" startIcon={<DoneAllIcon />} autoFocus onClick={(_) => { handleClose(); }} disabled={!localState || isValid || isValidDate} color="primary" variant="contained" className={classes.updateNotification}>
                Update Notification
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default EditNotification