import { makeStyles, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Backdrop } from '@mui/material';
import React, { useEffect, useState } from "react";
import PuffLoader from 'react-spinners/PuffLoader';
import { IAllTemplateList } from "../../../models/Admin/ClientPlacements/IAllTemplateList";
import { GlobalStateAction, useGlobalState } from "../../../store/GlobalStore";
import { useFetch } from "../../../utils/apiHelper";
import CreateNewTemplate from "./CreateNewTemplate";
import DeleteTemplate from "./DeleteTemplate";
import EditTemplate from "./EditTemplate";
import Footer from '../../../components/Footer/Footer';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    table: {
      minWidth: 700,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    grid: {
      padding: '10px',
      backgroundColor: 'whitesmoke',
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    tablebody: {
      maxHeight: 620
    },
  })
);

export default function GA_EditTemplate() {
  const classes = useRowStyles();
  const [TemplateList, setTemplateList] = useState<IAllTemplateList[]>([]);
  const { state, dispatch } = useGlobalState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsUpdate(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSave = () => {
    getTemplateAsync();
  };

  const handleDeleteSuccess = () => {
    getTemplateAsync();
  };

  useEffect(() => {
    (async () => {
      setShowProgressBar(true);
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const response = await useFetch<IAllTemplateList[]>("ClientPlacement/GetAllTemplate");
        setTemplateList(response.data);
        setShowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  function getTemplateAsync() {
    (async () => {
      setShowProgressBar(true);
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const response = await useFetch<IAllTemplateList[]>("ClientPlacement/GetAllTemplate");
        setTemplateList(response.data);
        setShowProgressBar(false);
        setIsUpdate(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })();
  }
  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }),
  )(TableCell);

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <React.Fragment>
      <CssBaseline />
      <div style={{ flexGrow: 1 }}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid} >
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography variant="h4" gutterBottom style={{ color: "blue", float: 'left' }}>
                <b>CLIENT PLACEMENT OPTIONS</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <CreateNewTemplate onSave={handleSave} />
            </Grid>
          </Grid>
          <Grid component={Paper} item xs={12}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table className={classes.table} aria-label="customized table" size="small" stickyHeader>
                <TableHead >
                  <TableRow>
                    <StyledTableCell style={{ background: "#007FFF", color: "white", fontSize: "120%" }} >TEMPLATE NAME</StyledTableCell>
                    <StyledTableCell style={{ background: "#007FFF", color: "white", fontSize: "120%" }} align="right">ACTIONS</StyledTableCell>
                    <StyledTableCell style={{ background: "#007FFF", color: "white", fontSize: "120%" }} align="right">DELETE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TemplateList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                    return (
                      <StyledTableRow>
                        <StyledTableCell align="left" scope="row">
                          <Typography variant="subtitle1" gutterBottom>
                            {row.name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <EditTemplate templateRow={row} onSave={getTemplateAsync} />
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <DeleteTemplate templateRow={row} onDelete={handleDeleteSuccess} />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              id="CPO_ET_TablePagination"
              rowsPerPageOptions={[15, 25, 50]}
              component="div"
              count={TemplateList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>

        </Grid>
        <Footer />
        <Snackbar open={isUpdate} style={{ color: 'black', marginTop: '-0.5cm' }} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
            Template Updated Successfully!
          </Alert>
        </Snackbar>
      </div>

    </React.Fragment>
  )
}
