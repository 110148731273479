import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, Grid, makeStyles, Select, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import { IClientList } from '../models/Admin/ClientPlacements/IClientList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CSVFileReader from '../components/common/CSVFileReader';
import VideoPlayer from '../components/YouTubePlayer/VideoPlayer';
import { IAdminGetClients } from '../models/UploadLog/AdminGetClients';
import { IClientPhase } from '../models/Placements/IClientPhase'
import { IUserTemplate } from '../models/Placements/IUserTemplate';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import { ISearchDuplicateFileName } from "../models/Placements/ISearchDuplicateFileName";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { IClientTemplate } from '../models/Admin/ClientPlacements/IClientTemplate';
import { DialogTitleHeader } from '../components/GlobalStyles/DialogStyle';
import InfoIcon from '@material-ui/icons/Info';
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import DvrIcon from '@mui/icons-material/Dvr';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '2cm',
    alignItems: "center",
    border: '2px solid gray',
    borderTopLeftRadius: 40,
    borderBottomRightRadius: 40,
    backgroundColor: 'white',
    height: '66vh',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  formControl: {
    minWidth: 400,
    marginRight: theme.spacing(1),
    alignItems: "left"
  },
  paper: {
    borderRadius: 10,
    paddingBottom: "10px",
    paddingTop: "10px",
    fontWeight: 'bold',
    fontSize: 16
  },
  root1: {
    flexGrow: 1,
    marginTop: '1.5cm'
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  title: {
    alignContent: "left",
    color: "blue",
    marginTop: '0.7cm'
  },
  video: {
    float: 'right',
    marginTop: '-20px'
  },
  displaymessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '60px'
  },
  textfiled: {
    color: 'green',
    float: 'left',
    marginLeft: '15px',
    wordWrap: 'inherit',
    fontSize: '17px'
  },
  infoicon: {
    fontSize: 25,
    float: 'right',
  },
  displaymessage2: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropdown: {
    marginTop: '10px'
  },
  dropdownClient: {
    width: 400,
    float: 'left',
    backgroundColor: 'white',
    border: '2px solid blue',
    borderRadius: 5,
  },
  placementTitle: {
    float: 'left',
    color: 'blue',
    marginTop: '15px',
    marginLeft: ' 4.2cm'
  },
  NoAccess: {
    color: 'red'
  }
}))

const Placements = () => {
  const theme = useTheme();
  let history = useHistory();
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();

  const [placementClientList, setPlacementClientList] = useState<IClientList[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<IUserTemplate>();
  const [userTemplate, setUserTemplate] = useState<IUserTemplate[]>([]);
  const [selectedClient, setSelectedClient] = useState<IClientList>();
  const [clientPhase, setClientPhase] = useState<IClientPhase[]>([]);
  const [clientList, setClientList] = useState<IClientList[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<number>(-1);

  const [disableEntermanually, setDisableEnterManually] = useState(false);
  const [FileUploadSnackBar, setFileUploadSnackBar] = useState(false);
  const [selectedMultiCode, setSelectedMultiCode] = useState(false);
  const [disabledUploadCSV, setDisableUploadCSV] = useState(false);
  const [displayMessage, setdisplayMessage] = useState(false);
  const [isValidCSV, setIsValidCSV] = React.useState(false);
  const [disableManual, setDisableManual] = useState(false);
  const [selectedCode, setSelectedCode] = useState(false);
  const [openCloud, setOpenCloud] = React.useState(false);
  const [selectPhase, setSelectPhase] = useState(false);
  const [showPhase, setShowPhase] = useState(true);
  const [disableAll, setDisableAll] = useState(false);

  const [title, setTitle] = React.useState<string>('A.R.M. WebView: Placing Accounts with CSV File');
  const [video, setVideo] = React.useState<string>('3L6IgGjIPwc');
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const placeAccount = state.userAccessContext?.placeAccounts;
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [fileName, setFileName] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');
  const [fileSize, setFileSize] = useState<number>();
  const [phase, setPhase] = React.useState<string>();
  const clientsLoading = clientList.length === 0;
  const UserName = state.userAccessContext?.id;


  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileUploadSnackBar(false);
  };

  useEffect(() => {
    if (clientList.length <= 0) {
      LoadClientCodeList();
      LoadPlacementClientCodeList();
    }
  }, [clientList])

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await useFetch<IUserTemplate[]>(`Placements/TemplatebyUserID?user_id=${UserName}`);
        setUserTemplate(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  async function LoadPlacementClientCodeList() {
    dispatch({ type: GlobalStateAction.Busy });
    try {
      const response = await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`)
      let currentClientList: IClientList[] = [];
      response.data.forEach(x => {
        let client: IClientList = {
          clT_CODE: x.client_code,
          clT_NAME_1: x.client_name
        };
        currentClientList.push(client);
      });
      { state.userAccessContext?.multiClientCodePlacementAccess ? currentClientList.unshift(getMultiClientOption()) : null };
      setPlacementClientList(currentClientList);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  async function LoadClientCodeList() {
    dispatch({ type: GlobalStateAction.Busy });
    try {
      if (state.userAccessContext.role === 2 || state.userAccessContext?.role === 3) {
        const response = await useFetch<IClientList[]>("ClientPlacement/ClientCodeList?last=9999");
        {
          state.userAccessContext?.multiClientCodePlacementAccess ?
            response.data.unshift(getMultiClientOption())
            : null
        }
        setClientList(response.data);
      }
      else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
        const response = await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`)
        let currentClientList: IClientList[] = [];
        response.data.forEach(x => {
          let client: IClientList = {
            clT_CODE: x.client_code,
            clT_NAME_1: x.client_name
          };
          currentClientList.push(client);
        });
        { state.userAccessContext?.multiClientCodePlacementAccess ? currentClientList.unshift(getMultiClientOption()) : null };
        setClientList(currentClientList);
      }
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  const getMultiClientOption = () => {
    let multiClientOption: IClientList = {
      clT_CODE: "-2",
      clT_NAME_1: "Multi Client",
    }
    setDisableEnterManually(true);
    return multiClientOption;
  }

  const handleClientChange = (event, selected) => {
    (async () => {
      if (selected) {
        const getList = await useFetch<IClientTemplate[]>(`ClientPlacement/ClientCodeList?query=${selected?.clT_CODE}&last=${1}`);
        const permission = getList.data.map((tempId => {
          return { 'enterManually': setDisableManual(selected?.clT_CODE === "-2" ? false : tempId.template_id === 0 ? true : false) }
        }));
        const selectedValue = clientList.find(x => x.clT_CODE === selected.clT_CODE);
        setShowPhase(false);
        setDisableAll(false);
        setSelectedClient(selectedValue);
        const response = await useFetch<IClientPhase[]>(`Placements/GetPlacementPhase?clt_code=${selected?.clT_CODE}`)
        setClientPhase(response.data);
        setSelectedCode(true);
        setDisableEnterManually(selectedValue.clT_CODE === "-2" ? true : false);
      }
      else {
        setSelectedClient({ clT_NAME_1: "", clT_CODE: "" });
        setShowPhase(true);
        setDisableAll(true);
      }
    })()
  };

  const handleTemplateChange = (event, selected) => {
    if (selected) {
      const selectedValue = userTemplate.find(x => x.placement_template_id === selected.placement_template_id);
      setSelectedTemplate(selectedValue);
      setSelectedCode(true);
      setSelectedMultiCode(true);
      setDisableEnterManually(false);
    }
    else if (selected === null) {
      setDisableEnterManually(true);
    }
    else {
      setSelectedTemplate({ placement_template_id: 0, name: "" });
      setSelectedMultiCode(false);
    }
  };

  function formInputCheck(): boolean {
    if (!selectedCode || !selectedPhase) {
      alert("Please select client to proceed.")
      return false;
    }
    if (!selectedPhase || !selectedPhase || +selectedPhase == -1) {
      alert("Please select client phase to proceed.")
      return false;
    }
    return true;
  }

  const handleCSVUpload = (csvTable) => {
    if (fileType === "application/vnd.ms-excel" || fileType === "") {
      if (!formInputCheck()) {
        return;
      }
      let placementAccountCSVData: string[][] = [];
      for (let i: number = 0; i < csvTable.length; i++) {
        placementAccountCSVData[i] = [];
        if (undefined == csvTable[i].data) {
          let flattendArray = csvTable[i]?.toString()?.replace('\t', '')?.split(',');
          for (let j: number = 0; j < flattendArray.length; j++) {
            placementAccountCSVData[i][j] = flattendArray[j];
          }
        }
        else {
          for (let j: number = 0; j < csvTable[i].data.length; j++) {
            placementAccountCSVData[i][j] = csvTable[i].data[j].toString()?.replace('\t', '');
          }
        }
      }
      let filteredClientList = _.cloneDeep(placementClientList);
      filteredClientList = filteredClientList.filter(x => x.clT_CODE != "-2");
      history.push('/csvfieldnames',
        {
          placementAccountCSVData: placementAccountCSVData, clientCode: selectedClient?.clT_CODE,
          phaseNumber: selectedPhase, clientList: filteredClientList, uploadedFile: uploadedFile
        });
    }
    else {
      setIsValidCSV(true);
      alert("Please Upload a valid CSV file");
    }
  }

  const handleFileUpload = (file) => {
    if (fileName === '' || fileName === null) {
      setFileName(file && file?.name);
      if (file !== null && fileName !== file?.name) {
        setFileSize(file && file?.size);
        setFileName(file && file?.name);
        setFileType(file && file?.type);
        setUploadedFile(file);
      }
    }
  }

  const handlePhaseChange = (event) => {
    setSelectedPhase(parseInt(event.target.value));
    setSelectPhase(true);
  };

  const uploadFileToBlob = () => {
    dispatch({ type: GlobalStateAction.Busy });
    (async () => {
      let date = new Date(Date.now());
      let filename = `${selectedClient?.clT_CODE}${moment(date).format("YYYYMMDDhhmmss")}${uploadedFile && uploadedFile?.name}`;
      const formData = new FormData();
      formData.append("FormFile", uploadedFile);
      formData.append("file_name", filename);
      formData.append("file_size", uploadedFile && uploadedFile?.size);
      formData.append("ContainerName", "upload-csv");
      formData.append("ClientId", selectedClient?.clT_CODE);
      formData.append("UploadMethod", '0');
      formData.append("Phase", selectedPhase.toString());
      formData.append("userid", UserName.toString());
      dispatch({ type: GlobalStateAction.Busy })
      try {
        await usePost<any>("File/UploadUnmappedFile", formData);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  const handleSubmit = () => {
    if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
      history.push('/fieldnames',
        { clientCode: selectedClient?.clT_CODE, phaseNumber: selectedPhase, templateId: selectedTemplate?.placement_template_id, multiCode: selectedMultiCode });
    }
    else if (state.userAccessContext.role === 1 || state.userAccessContext.role === 0) {
      history.push('/fieldnames',
        { clientCode: selectedClient?.clT_CODE, phaseNumber: selectedPhase, templateId: selectedTemplate?.placement_template_id, multiCode: selectedMultiCode });
    }
  }

  useEffect(() => {
    if (selectedPhase === 1) {
      setPhase('1STP');
    }
    if (selectedPhase === 2) {
      setPhase('PREC');
    }
    else if (selectedPhase === 3) {
      setPhase('CONT');
    }
  }, [selectedPhase])

  const onUploadFile = (selectedFile: File) => {
    if (selectedFile) {
      (async () => {
        let date = new Date(Date.now());
        let filename = `${selectedClient.clT_CODE}${moment(date).format("YYYYMMDDhhmmss")}${selectedFile.name}`;
        dispatch({ type: GlobalStateAction.Busy })
        const formData = new FormData();
        formData.append("FormFile", selectedFile);
        formData.append("file_name", filename);
        formData.append("file_size", selectedFile.size.toString());
        formData.append("ContainerName", "upload-unmappedFile");
        formData.append("ClientId", selectedClient.clT_CODE);
        formData.append("UploadMethod", '2');
        formData.append("Phase", phase);
        formData.append("userid", UserName.toString());
        try {
          const UserName = state.userAccessContext?.userName;
          let request = {
            "userName": UserName
          };
          const GetResponse = await useFetch<ISearchDuplicateFileName[]>(`Placements/SearchDuplicateFileName?Name=${filename.slice(20)}&ClientId=${selectedClient.clT_CODE}`);
          const CheckFile = GetResponse.data.filter(x => x.fileName.includes(selectedFile.name));
          if (CheckFile.length === 0) {
            await usePost<any>("File/UploadUnmappedFile", formData);
            setFileUploadSnackBar(true);
            setdisplayMessage(false);
          } else {
            const IsDuplicate = CheckFile[0].fileName.length > 0 ? true : false;
            if (IsDuplicate === false) {
              await usePost<any>("File/UploadUnmappedFile", formData);
              setdisplayMessage(false);
              setFileUploadSnackBar(true);
            } else {
              setdisplayMessage(true);
            }
          }
        }
        catch (ex) {
          dispatch({ type: GlobalStateAction.Error, error: ex })
          dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
          dispatch({ type: GlobalStateAction.Idle })
        }
      })()
    }
  }

  const handleClickOpenCloud = () => {
    setOpenCloud(true);
  };

  const handleClose = () => {
    setOpenCloud(false);
  };

  useEffect(() => {
    if (!selectedCode || !selectPhase || !placeAccount || disableManual || selectedPhase === -1 || disableAll) {
      setDisableUploadCSV(true);
    } else {
      setDisableUploadCSV(false);
    }
  }, [selectedCode, selectPhase, placeAccount, disableManual, selectedPhase, disableAll])

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.container} component="div" >
        <Typography variant="h4" gutterBottom className={classes.title}>
          <b>1. CHOOSE DATA ENTRY METHOD</b>
        </Typography>
        <Box className={classes.video}>
          <VideoPlayer video={video} title={title} />
        </Box>
        <div className={classes.root1}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} className={classes.dropdown}>
              <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                <b> CHOOSE CLIENT CODE </b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.dropdown}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  id="clientCode-select"
                  options={clientList}
                  className={classes.dropdownClient}
                  autoHighlight
                  loading={clientsLoading}
                  onChange={handleClientChange}
                  getOptionLabel={(option) => option.clT_CODE == "-2" ? option.clT_NAME_1 : option.clT_CODE + "--" + option.clT_NAME_1}
                  getOptionSelected={(option, value) => option.clT_CODE === value.clT_CODE}
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.clT_CODE == "-2" ? option.clT_NAME_1 : option.clT_CODE + "--" + option.clT_NAME_1}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select client"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.dropdown}>
              <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                <b> CHOOSE PHASE</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.dropdown}>
              <FormControl id="Select_Phase" variant="outlined" className={classes.formControl} disabled={showPhase}>
                <Select native value={isNaN(selectedPhase) ? -1 : selectedPhase} onChange={handlePhaseChange}
                  className={classes.dropdownClient}>
                  <option value={-1}>Select Phase</option>
                  {selectedClient?.clT_CODE === "-2" ?
                    <>
                      <option value={1}>1st Party</option>
                      <option value={2}>Flat Fee</option>
                      <option value={3}>Contingency</option>
                    </>
                    : clientPhase.map((phase) => {
                      return (
                        <>
                          {phase?.istParty ? <option value={1}>1st Party</option> : <option value={-1} className={classes.NoAccess}>1st Party</option>}
                          {phase?.isFlatFee ? <option value={2}>Flat Fee</option> : <option value={-1} className={classes.NoAccess}>Flat Fee</option>}
                          {phase?.isContingency ? <option value={3}>Contingency</option> : <option value={-1} className={classes.NoAccess}>Contingency</option>}
                        </>
                      )
                    })}
                  {state.userAccessContext?.multiplePlacementAccess ? <option value={4}>Multi Phase</option> : null}
                </Select>
              </FormControl>
            </Grid>
            {selectedPhase !== 4 && selectedClient?.clT_CODE == "-2" ?
              <React.Fragment>
                <Grid item xs={12} sm={6} className={classes.dropdown}>
                  <Typography variant="h6" gutterBottom className={classes.placementTitle}>
                    <b>CHOOSE TEMPLATE FOR MANUAL UPLOAD</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dropdown}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      id="template-select"
                      className={classes.dropdownClient}
                      options={userTemplate}
                      autoHighlight
                      onChange={handleTemplateChange}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.placement_template_id === value.placement_template_id}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Template"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }} />
                      )}
                    />
                  </FormControl>
                </Grid>
              </React.Fragment>
              : ""}
          </Grid>
          <Grid container spacing={5} style={{ marginTop: '50px', marginLeft: '7px' }}>
            {state?.userAccessContext?.placeAccounts ?
              <Grid item xs>
                <CSVFileReader csvUpload={handleCSVUpload} disabled={disabledUploadCSV} userId={UserName} getphase={phase}
                  getClientCode={selectedClient?.clT_CODE} fileUpload={handleFileUpload} />
              </Grid>
              : null}
            <Grid item xs>
              <Button id="EnterManually_Button" disabled={!selectedCode || !selectPhase || !placeAccount || selectedPhase === 4 || selectedPhase === -1 || disableEntermanually || disableManual || disableAll} className={classes.paper} size="large" style={{ borderRadius: 10, marginLeft: "-80px" }}
                onClick={handleSubmit} type='submit' variant="contained" startIcon={<DvrIcon style={{ fontSize: 30 }} />} color="primary">
                Enter Manually
              </Button>
            </Grid>
            {state.userAccessContext?.uploadUnmappedFile ?
              <Grid item xs>
                <Button className={classes.paper} size="large" disabled={!selectedCode || !selectPhase || selectedPhase === 4 || selectedPhase === -1 || disableAll} style={{ borderRadius: 10, marginLeft: "-160px" }}
                  variant="contained" color="primary" startIcon={<DriveFolderUploadIcon style={{ fontSize: 30 }} />} onClick={handleClickOpenCloud}>
                  Upload Unmapped File
                </Button>
              </Grid>
              : null
            }
            <Grid item xs={12}>
              {displayMessage ?
                <Typography variant="h6" className={classes.displaymessage}>
                  The file you are trying to upload is named identically to a previous file. Please rename your file and try your upload again.
                </Typography>
                : null}
            </Grid>
          </Grid>
          <Dialog fullScreen={fullScreen} open={openCloud} aria-labelledby="responsive-dialog-title"
            maxWidth={'sm'} PaperProps={{ style: { borderRadius: 10 } }}  >
            <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
              <Typography variant="h5" gutterBottom className={classes.titleheader}>
                UPLOAD UNMAPPED FILE
              </Typography>
            </DialogTitleHeader>
            <DialogContent >
              <LightTooltip title="When you drag and drop a file here, It will save automatically.">
                <InfoIcon className={classes.infoicon} />
              </LightTooltip>
              <CssBaseline />
              <DialogContentText id="alert-dialog-slide-description" >
                <Grid container spacing={5} style={{ marginTop: '-15px' }}>
                  <Grid item xs={12} >
                    <DropzoneArea
                      onChange={(files) => onUploadFile(files[0])}
                      showPreviews={false}
                      showPreviewsInDropzone={false}
                      filesLimit={1}
                      showAlerts={false}
                      onDrop={handleClose}
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Snackbar open={FileUploadSnackBar} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }} autoHideDuration={4000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity="success">
              File Uploaded Successfully.
            </Alert>
          </Snackbar>
        </div>
      </Container>
    </React.Fragment >
  )
}

export default Placements

