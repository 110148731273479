import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { DialogTitle, IconButton, Slide, Snackbar, Typography } from '@material-ui/core';
import { IAllTemplateList } from '../../../models/Admin/ClientPlacements/IAllTemplateList';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useDelete } from '../../../utils/apiHelper';
import { TransitionProps } from '@material-ui/core/transitions';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@mui/icons-material/Delete';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    yesButton: {
      background: "green",
      color: 'white',
      bottom: '0.2cm',
      borderRadius: 20,
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      display: 'flex',
      bottom: '0.2cm',
      color: 'white',
      borderRadius: 20,
      '&:hover': {
        background: "red",
      }
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DeleteTemplate: React.FC<{ templateRow: IAllTemplateList, onDelete: () => void }> = (props) => {
  const { templateRow } = props
  const [open, setOpen] = React.useState(false);
  const { state, dispatch } = useGlobalState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCreate, setIsCreate] = React.useState(false);
  const classes = useRowStyles();

  const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCreate(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setIsCreate(true);
    useDelete("ClientPlacement/DeleteTemplate?placement_template_id=" + templateRow.placement_template_id).then((r) => {
      if (r.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: r.statusText })
      }
      props.onDelete();
    });
  };

  return (
    <React.Fragment>
      <div>
        <IconButton id="CPO_Delete_btn" aria-label="delete" onClick={handleClickOpen} disabled={templateRow.placement_template_id === 1 ? true : false}>
          <DeleteIcon style={templateRow.placement_template_id === 1 ? { color: "gray" } : { color: "red" }} />
        </IconButton>
        <Dialog
          PaperProps={{ style: { borderRadius: 10 } }}
          fullScreen={fullScreen}
          open={open}
          maxWidth={"sm"}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="responsive-dialog-title" >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
              DELETE TEMPLATE
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h5" style={{ marginTop: "15px", color: "black" }} gutterBottom>
                Are you sure, you want to delete this Template ?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Button id="CPO_OK_btn" onClick={handleOk} color="primary" className={classes.yesButton} autoFocus>
              Yes
            </Button>
            <Button id="CPO_Cancel_btn" color="primary" className={classes.noButton} autoFocus onClick={handleClose} variant="contained">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={isCreate} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={UpdateMessage}>
        <Alert onClose={UpdateMessage} severity="error" style={{ fontSize: 16 }}>
          Template Deleted Successfully.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default DeleteTemplate