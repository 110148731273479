import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Fileaccess from '../images/admin/fileaccess.svg';
import Useraccess from '../images/admin/useraccess.svg';
import Conformation from '../images/settings/conformation.svg';
import Information from '../images/settings/information.svg';
import { AccessContext } from '../models/accesscontrol/AccessContext';
import { UserRole } from '../models/accesscontrol/ClientUserAccessContext';
import { useGlobalState } from '../store/GlobalStore';
import { useFetch } from '../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    marginTop: '2cm',
    alignItems: "center",
    borderRadius: 20,
  },
  text: {
    color: "black",
    textAlign: "center",
    marginTop: '5px',
    fontWeight: 'bold',

  },
  card1: {
    display: 'block',
    width: '6.5cm',
    height: '4.5cm',
    marginLeft: '30px',
    marginTop: '1cm',
    '&:hover': {
      color: 'black',
      backgroundColor: 'white',
      borderRadius: '10px',
      transform: 'scale(1.1)',
      border: '2px blue solid',
      cursor: 'pointer',
      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
    },
  },
  div: {
    height: '100%',
    width: '100%',
  },
  image: {
    width: '80px',
    height: '80px',
  },
  IconButtonripple: {
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  Tooltip: {
    fontSize: '14px',
  }
}));

const Settings = () => {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const UserAccessPermission = state.userAccessContext?.role == UserRole?.GlobalAdmin || state.userAccessContext?.role == UserRole?.ClientUser || state.userAccessContext?.role == UserRole?.ClientAdmin;
  const canAccessclientConfirmation = state.userAccessContext?.clientConfirmation;
  const [EnableAccess, setEnableAccess] = useState(false);

  useEffect(() => {
    (async () => {
      if (state.userAccessContext?.role === 1 || state.userAccessContext?.role === 0) {
        await useFetch<AccessContext>("User/GetUser?userId=" + state.userAccessContext?.id).then((r) => {
          if (r.data.isFileAccess === true) {
            setEnableAccess(true);
          }
          else {
            setEnableAccess(false);
          }
        });
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Grid container spacing={1} alignItems="center" style={{ height: "100%", alignContent: 'center' }} >
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom style={{ float: 'left', color: "blue", marginTop: '1%', marginLeft: '20px' }}>
              <b>SETTINGS </b>
            </Typography>
          </Grid>

          {state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ?
            <Grid item xs={12} sm={6} md={4} lg={2} >
              <Link to="/clientinformation" style={{ textDecoration: 'none' }}>
                <Box className={classes.card1}>
                  <IconButton id="ClientInformation_Button" aria-label="client_information" component={Link} to="/clientinformation" className={classes.IconButtonripple}>
                    <img src={Information} alt="Settings_Client_Information" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Client Information
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            :
            null
          }

          {(state.userAccessContext?.role === 0 || state.userAccessContext?.role === 1) && canAccessclientConfirmation ?
            <Grid item xs={12} sm={6} md={4} lg={2} >
              <Link to="/clientconfirmation" style={{ textDecoration: 'none' }}>
                <Box className={classes.card1}>
                  <IconButton id="ClientConfirmation_Button" aria-label="client_conformation" component={Link} to="/clientconfirmation" className={classes.IconButtonripple}>
                    <img src={Conformation} alt="Settings_Client_Confirmation" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Client Confirmation
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            :
            (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) ?
              <Grid item xs={12} sm={6} md={4} lg={2} >
                <Link to="/clientconfirmation" style={{ textDecoration: 'none' }}>
                  <Box className={classes.card1}>
                    <IconButton id="ClientConfirmation_Button" aria-label="client_conformation" component={Link} to="/clientconfirmation" className={classes.IconButtonripple}>
                      <img src={Conformation} alt="Settings_Client_Confirmation" className={classes.image} />
                    </IconButton>
                    <Box>
                      <Typography variant="h6" className={classes.text}>
                        Client Confirmation
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
              :
              null
          }

          {(state.userAccessContext?.role === 1) && UserAccessPermission ?
            <Grid item xs={12} sm={6} md={4} lg={2} >
              <Link to="/useraccesscontrol" style={{ textDecoration: 'none' }}>
                <Box className={classes.card1}>
                  <IconButton id="UserAccessContext_Button" aria-label="user_access_file" component={Link} to="/useraccesscontrol" className={classes.IconButtonripple}>
                    <img src={Useraccess} alt="Settings_User_Access_Control" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      User Access Control
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
            :
            null
          }

          {EnableAccess &&
            <Grid item xs={12} sm={6} md={4} lg={2} >
              <Link to="/managefileaccess" style={{ textDecoration: 'none' }}>
                <Box className={classes.card1}>
                  <IconButton id="Admin_Page_ManageFileAccess_Button" aria-label="manage_file_access" disabled={UserAccessPermission} component={Link} to="/managefileaccess" className={classes.IconButtonripple}>
                    <img src={Fileaccess} alt="Admin_Manage_File_Access" className={classes.image} />
                  </IconButton>
                  <Box>
                    <Typography variant="h6" className={classes.text}>
                      Manage File Access
                    </Typography>
                  </Box>
                </Box>
              </Link>
            </Grid>
          }
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default Settings