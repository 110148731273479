import {
  Box,
  Button,
  CssBaseline, Grid, makeStyles, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
import * as React from 'react';
import '../App.css';
import { useEffect, useRef, useState } from 'react';
import { usePost } from '../utils/apiHelper';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { IDebtor } from '../models/Inquiry/IDebtor';
import ExpandableRow from '../components/Inquiry/ExpandableRow';
import { Columns } from '../models/Inquiry/config';
import VideoPlayer from '../components/YouTubePlayer/VideoPlayer';
import Backdrop from '@material-ui/core/Backdrop';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../components/Footer/Footer';
import { Autocomplete } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  search: {
    position: 'relative',
    color: '#aaa',
    fontSize: '16px'
  },
  searchBox: {
    marginLeft: '0.2cm'
  },
  FilterField: {
    width: '20%',
    marginLeft: '1cm',
    paddingRight: '10px'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  div: {
    flexGrow: 1,
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  grid: {
    padding: '15px',
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  topRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  box: {
    display: "flex",
    marginTop: "-2px"
  },
  box1: {
    marginTop: "-5px",
    marginLeft: "300px",
  },
  box2: {
    display: "flex",
    marginTop: "-8px",
    marginLeft: "100px"
  },
  progressBar: {
    height: '6px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  gridStyle: {
    padding: theme.spacing(1),
    marginTop: '-7px'
  },
  tableHeader: {
    color: "white",
    fontSize: "12px",
    overflow: 'wrap',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    background: "#007FFF",
  },
  textStyle: {
    color: "blue",
    marginTop: '10px'
  },
  loadMoreStyle: {
    marginTop: '10px',
    maxHeight: '30px',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: 'white',
      borderWidth: '1px',
    },
  },
  cssFocused: {
    borderColor: 'white',
    borderWidth: '1px',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white',
  },
  dropDownStyle: {
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    borderRadius: 10,
    border: '2px solid #215176',
    backgroundColor: 'white'
  },
  loadmorebutton: {
    borderRadius: 20,
    marginTop: '10px'
  },
  tablebody: {
    maxHeight: 600,
  },
  videoplayer: {
    marginTop: '1cm'
  }
}))

export default function ComparatorSortingGrid() {
  const { state, dispatch } = useGlobalState();
  const [selected, setSelected] = React.useState<string>();
  const [debtors, setDebtors] = useState<IDebtor[]>([]);
  const [searchDebtor, setSearchDebtor] = useState<string>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const [orderBy, setOrderBy] = useState<string>(" ");
  const [order, setOrder] = useState<any>('asc');
  const [loadAll, setLoadAll] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const userName = state.userAccessContext?.id;
  const video = 'rkUp_6x6VR0';
  const title = 'A.R.M. WebView: Searching & Updating Accounts';
  const classes = useStyles();
  const [ProgressBar, setshowProgressBar] = useState(true);
  const wrapperRef = useRef(null);
  const [GetdebtorNo, setGetdebtorNo] = useState("");
  const [FieldToSearchValue, setFieldToSearchValue] = useState("");

  const handleRowClick = (accountId: string) => {
    setSelected(selected == accountId ? "" : accountId);
    setGetdebtorNo(accountId);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleRelease();
    }
  };

  const handleRelease = () => {
    if (GetdebtorNo !== undefined && GetdebtorNo !== null) {
      (async () => {
        dispatch({ type: GlobalStateAction.Busy });
        try {
          let YieldRelease = {
            "debtorNumber": GetdebtorNo,
            "userId": state.userAccessContext?.id
          }
          await usePost("InquiryReleaseLock", YieldRelease);
        }
        catch (ex) {
          dispatch({ type: GlobalStateAction.Error, error: ex })
          dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
          dispatch({ type: GlobalStateAction.Idle })
          sessionStorage.removeItem('key');
        }
      })()
    } else {
      null;
    }
  }

  const initiateSearch = () => {
    setTriggerSearch(!triggerSearch)
    setshowProgressBar(true);
    setPageNumber(0);
  }

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setPageNumber(0);
    setshowProgressBar(true);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, page) => {
    setPageNumber(page);
    setshowProgressBar(true);
  }

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "userId": userName,
          "searchText": searchDebtor ?? "",
          "searchType": FieldToSearchValue ?? "",
          "orderBy": orderBy,
          "orderDirection": order,
          "rowsPerPage": recordsPerPage,
          "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
          "viewall": loadAll
        }
        const response = await usePost<{ debtors: IDebtor[], totalRecordCount: number }>("SearchDebtorInquiry", request).then((searchDebtor) => {
          setDebtors(searchDebtor.data["debtors"]);
          setTotalRecords(searchDebtor.data.totalRecordCount);
        });
        setshowProgressBar(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
        setshowProgressBar(false);
      }

    })()
  }, [pageNumber, triggerSearch, loadAll, orderBy, order])

  const FieldToSearch = [
    {title: 'Default', value:""},
    { title: 'Client', value: "DBT_CLIENT" },
    { title: 'Agency ID', value: "DBT_NO" },
    { title: 'Account ID', value: "DBT_CLNT_ACNT1" },
    { title: 'Name', value: "DBT_NAME" },
    { title: 'Phone', value: "DEM_PHONE1" },
    { title: 'Tax ID', value: "DEM_TAX_ID" },
    { title: 'Address 1', value: "DEM_ADDR1" },
    { title: 'Address 2', value: "DEM_ADDR2" },
    { title: 'City', value: "DEM_CITY" },
    { title: 'State', value: "DEM_STATE" },
    { title: 'Zip', value: "DEM_ZIP" },
  ];

  const handleChange = (event, selectedvalue) => {
    setFieldToSearchValue(selectedvalue === "" || selectedvalue === null ? FieldToSearch[1].value : selectedvalue.value)
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.div} ref={wrapperRef}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid} style={{ backgroundColor: 'whitesmoke' }} spacing={1}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Box component="span" m={1} //margin
                className={`${classes.topLeftBox} ${classes.box}`} >
                <Typography variant="h4" gutterBottom className={classes.textStyle}>
                  <b>INQUIRY LIST</b>
                </Typography>
              </Box>
            </Grid>
            <Grid xs={8} item style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="search">
                <input value={searchDebtor}
                  type='text'
                  placeholder="Search (account number, name, SSN, phone)"
                  onChange={e => setSearchDebtor(e.target.value)}
                  onKeyPress={event => { if (event.key === 'Enter') { initiateSearch(); } }}
                  maxLength={255}
                />
              </div>

              <Autocomplete
                id="I_DropDown"
                sx={{ width: 400 }}
                className={classes.FilterField}
                options={FieldToSearch}
                defaultValue={FieldToSearch[0]}
                disableClearable={true}
                onChange={handleChange}
                getOptionLabel={(option) => option.title}
                renderInput={(params) =>
                  <TextField {...params} placeholder="Search Fields" variant="outlined" className={classes.dropDownStyle} />}
              />
            </Grid>
            {state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3 ?
              <Grid item xs={2} >
                <Button id="Inquiry_LoadAll_Button" className={classes.loadmorebutton}
                  variant="contained" disabled={loadAll} color="primary"
                  onClick={(_) => { setLoadAll(true); setshowProgressBar(true); }} >
                  Load More
                </Button>
                <VideoPlayer video={video} title={title} />
              </Grid>
              : <Grid item xs={1}>
                <Box className={classes.videoplayer}>
                  <VideoPlayer video={video} title={title} />
                </Box>
              </Grid>
            }
          </Grid>
          <Grid xs={12} item component={Paper} className={classes.gridStyle}>
            <TableContainer component={Paper} style={{ display: 'flex' }} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table aria-label="collapsible table" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    {Columns.map((headCell) => (
                      <TableCell className={classes.tableHeader}
                        align={headCell.type == "string" ? "left" : "left"}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.field}
                          {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    debtors.map(r => <ExpandableRow onClick={handleRowClick} selected={selected === r.dbT_NO} key={r.dbT_NO} row={r} selectedDBTNumber={selected} searchWord={searchDebtor} />)
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              id="Inquiry_TablePagination"
              rowsPerPageOptions={[10]}
              component="div"
              count={totalRecords}
              rowsPerPage={10}
              page={pageNumber}
              onChangePage={handlePageChange}
            />
          </Grid>
          <Footer />
        </Grid >
      </div>
    </React.Fragment>
  )
}