import { IUserPermissions } from './IUserPermissions'
import { INotificationPermissions } from './INotificationPermissions'
import { IWidgetsPermissions } from './IWidgetsPermissions'

export class ClientUserAccessContext implements IUserPermissions {
    role: UserRole = UserRole.ClientUser
    isGlobalAdmin: boolean = this.role === UserRole.GlobalAdmin
    portalUserExist: boolean = false;
    portalUserLogOn: boolean = false;
    global_admin: number = 0;

    //Permissions
    paymentUpdates: boolean = false;
    closingAccounts: boolean = false;
    rollAccounts: boolean = false;
    placeAccounts: boolean = false;
    editReports: boolean = false;
    createNewUsers: boolean = false;
    uploadUnmappedFile: boolean = false;
    sendEmailtoAdmin: boolean = false;
    sendEmailOnAttachements: boolean = false;
    multiplePlacementAccess: boolean = false;
    multiClientCodePlacementAccess: boolean = false;
    clientConfirmation: boolean = false;
    isFileAccess: boolean = false;

    //User Basic Information
    id: number = 0
    objectId: string = ''
    firstName: string = ''
    lastName: string = ''
    userName: string = ''
    phone: string = ''
    clientCodes: string[]
    ftpFolder: string = ''
    paymentFileFolder: string = ''
    ackFileFolder: string = ''
    ftpRegex: string = ''
    paymentRegex: string = ''
    reportRegex: string = ''

    constructor() {
    }
}

export enum placementPermission {
    None = 100,
    Enabled = 1,
    Disabled = 0,
}

//User Role
export enum Permission {
    None = 100,
    Enabled = 1,
    Disabled = 0,
}

//User Role
export enum UserRole {
    None = 100,
    SelectRole = 10,
    ClientUser = 0,
    ClientAdmin = 1,
    GlobalAdmin = 2,
    SuperAdmin = 3,
    FileAdmin = 4
}
export enum userDefaultValue {

}