import List from '@material-ui/core/List';
import Papa from 'papaparse';
import moment from 'moment';
import { CSVReader } from 'react-papaparse';
import ListItem from '@material-ui/core/ListItem';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useFetch, usePost } from '../../utils/apiHelper';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { makeStyles, Theme, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {
    Backdrop, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Grid, ListSubheader, Paper, Snackbar,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography, useMediaQuery
} from '@material-ui/core';

//Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';

//Models
import { DialogTitleDelete } from '../../components/GlobalStyles/DialogStyle';
import { IBlobContainerNames } from '../../models/Files/IBlobContainerNames';
import { IBlobFolders } from '../../models/Files/IBlobFolders';
import { IBlobs } from '../../models/Files/IBlobs';
import PuffLoader from 'react-spinners/PuffLoader';
import { CsvFile } from '../../constants/Constant';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(4),
            display: 'flex',
            position: 'relative'
        },
        paper: {
            color: theme.palette.text.secondary,
        },
        alignFlexStart: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        alignFlexEnd: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
        },
        div: {
            flexGrow: 1,
        },
        button: {
            borderRadius: 20,
            display: 'flex',
            margin: theme.spacing(0.5),
        },
        innerbox: {
            marginTop: '60px'
        },
        cloud_btn: {
            borderRadius: 10,
            marginTop: '0.8cm',
            marginBottom: '0.8cm',
            fontSize: 16,
            fontWeight: 600,
            marginLeft: '1cm',
        },
        csvreader: {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center'
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        delete: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            margin: theme.spacing(0.5),
            '&:hover': {
                background: "red",
            }
        },
        yesButton: {
            background: "green",
            borderRadius: 20,
            display: 'flex',
            margin: theme.spacing(0.5),
            '&:hover': {
                background: "green",
            }
        },
        ActionCell: {
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'right'
        },
        tableBoxShadow: {
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        searchBox: {
            borderRadius: 30,
            border: '2px black solid',
            width: '100%',
            height: '43px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            fontSize: 16,
            textIndent: '10px',
            marginTop: '10px',
        },
        TextHighlight: {
            color: 'black',
            fontWeight: 'bold',
            background: "yellow",
            fontSize: '16px',
        },
        TextNormal: {
            color: 'black',
            fontSize: 14
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

const CloudUpload: React.FC<{ onRemoteFileUpload: (data) => void, getPhase: string, getClients: string }> = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();

    const [SelectedContainer, setSelectedContianer] = useState<IBlobContainerNames[]>([]);
    const [ListContainers, setListContainers] = useState<IBlobContainerNames[]>([]);
    const [DeleteSelected, setDeleteSelected] = useState<IBlobContainerNames[]>([]);
    const [SelectedFolder, setSelectedFolder] = useState<IBlobFolders[]>([]);
    const [ListFolders, setListFolders] = useState<IBlobFolders[]>([]);
    const [DeleteBlobs, setDeleteBlobs] = useState<IBlobs[]>([]);
    const [selectedBlob, setSelectedBlob] = useState([]);
    const [ListBlobs, setListBlobs] = useState([]);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedFile, setSelectedFile] = useState<File>();
    const fileUpload = React.useRef(null);

    const [DeleteBlobopen, setDeleteBlobopen] = React.useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [isDeleteFile, setIsDeleteFile] = useState(false);
    const [openCloud, setOpenCloud] = React.useState(false);
    const [CheckAccess, setCheckAccess] = useState(false);
    const [open, setOpen] = React.useState(false);

    const [storeContainerName, setStoreContainerName] = useState("");
    const [GetSelectedFileId, setGetSelectedFileId] = useState("");
    const [orderBy, setOrderBy] = useState<string>("upload_Date");
    const [GetSelectedFile, setGetSelectedFile] = useState("");
    const [searchFiles, setSearchFiles] = useState("");
    const [order, setOrder] = useState<any>('desc');

    const [GetSelectedFileName, setGetSelectedFileName] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    //Permission
    const DownloadAccess = state.userAccessContext?.downloadfile;
    const DeleteFileAccess = state.userAccessContext?.deletefile;


    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteBlobClick = () => {
        GetSelectedBlob();
        setDeleteBlobopen(true);
    };

    const handleDeleteBlobClose = () => {
        setDeleteBlobopen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsDeleteFile(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setshowProgressBar(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setshowProgressBar(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function empty() {
        setSelectedContianer([]);
        setDeleteSelected([]);
    }

    function emptyBlob() {
        setDeleteBlobs([]);
    }

    const initiateSearch = () => {
        setPage(0);
        setTriggerSearch(!triggerSearch)
        getBlobs(storeContainerName);
    }

    const cancelSearch = () => {
        setSearchFiles("");
        setPage(0);
        setTriggerSearch(!triggerSearch);
        CancelSearch(storeContainerName);
    }

    useEffect(() => {
        if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
            if (ListContainers.length <= 0) {
                (async () => {
                    dispatch({ type: GlobalStateAction.Busy })
                    try {
                        const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers");
                        setListContainers(response.data);
                    }
                    catch (ex) {
                        dispatch({ type: GlobalStateAction.Error, error: ex })
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                    finally {
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                })()
            }
        }
        else {
            if (ListContainers.length <= 0) {
                (async () => {
                    dispatch({ type: GlobalStateAction.Busy })
                    try {
                        const response = await useFetch<IBlobFolders[]>(`User/GetUserFolders?Id=${state.userAccessContext?.id}`);
                        setListFolders(response.data);
                        setCheckAccess(true);
                    }
                    catch (ex) {
                        dispatch({ type: GlobalStateAction.Error, error: ex })
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                    finally {
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                })()
            }
        }
    }, [ListContainers])

    useEffect(() => {
        getBlobs(storeContainerName);
    }, [rowsPerPage, page])

    const getBlobs = (CN) => {
        (async () => {
            setshowProgressBar(true);
            if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
                let request = {
                    "containerName": CN,
                    "searchParameter": searchFiles ?? "",
                    "startingRecordNumber": (page * rowsPerPage) + 1,
                    "rowsperPage": rowsPerPage
                }
                let response = await usePost<{ files: IBlobs[], qty: number }>("File/SearchBlob", request);
                const data = response.data.files.map((a) => {
                    return { ...a, file_Name: a.file_Name }
                })
                setListBlobs(data.filter(r => r.file_Name.includes(CsvFile)));
                if (response.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: response.statusText })
                    setshowProgressBar(false);
                }
                setshowProgressBar(false);
            }
            else {
                let request = {
                    "containerName": CN,
                    "searchParameter": searchFiles ?? "",
                    "startingRecordNumber": (page * rowsPerPage) + 1,
                    "rowsperPage": rowsPerPage,
                    "userId": state.userAccessContext?.id
                }
                await usePost<IBlobs[]>("File/ListClientBlobs", request).then(r => {
                    setListBlobs(r.data.filter(r => r.file_Name.includes(CsvFile)))
                    setshowProgressBar(false);
                }).catch((err) => {
                    if (err.response.status == 400 || err.response.status == 500) {
                        setshowProgressBar(false);
                        dispatch({ type: GlobalStateAction.Error, error: err.statusText })
                    }
                })
            }
        })()
    }

    const CancelSearch = (CN) => {
        (async () => {
            setshowProgressBar(true);
            if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
                let request = {
                    "containerName": CN,
                    "searchParameter": "",
                    "startingRecordNumber": (page * rowsPerPage) + 1,
                    "rowsperPage": rowsPerPage
                }
                let response = await usePost<{ files: IBlobs[], qty: number }>("File/SearchBlob", request);
                const data = response.data.files.map((a) => {
                    return { ...a, file_Name: a.file_Name }
                })
                setListBlobs(data.filter(r => r.file_Name.includes(CsvFile)));
                if (response.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: response.statusText })
                    setshowProgressBar(false);
                }
                setshowProgressBar(false);
            }
            else {
                let request = {
                    "containerName": CN,
                    "searchParameter": "",
                    "startingRecordNumber": (page * rowsPerPage) + 1,
                    "rowsperPage": rowsPerPage,
                    "userId": state.userAccessContext?.id
                }
                await usePost<IBlobs[]>("File/ListClientBlobs", request).then(r => {
                    setListBlobs(r.data.filter(r => r.file_Name.includes(CsvFile)));
                    setshowProgressBar(false);
                }).catch((err) => {
                    if (err.response.status == 400 || err.response.status == 500) {
                        setshowProgressBar(false);
                        dispatch({ type: GlobalStateAction.Error, error: err.statusText })
                    }
                })
            }
        })()
    }

    const handleContainerToggle = (CNames: IBlobContainerNames) => () => {
        let newSelectedContainers = [...SelectedContainer].filter(r => r.containerNames == r.containerNames[""]);

        if (newSelectedContainers.some(c => c.containerNames == CNames.containerNames)) {
            newSelectedContainers = newSelectedContainers.filter(c => c.containerNames != CNames.containerNames);
        } else {
            newSelectedContainers.push(CNames);
        }

        let showBobs = "";
        for (var checkBlob in newSelectedContainers) {
            showBobs += newSelectedContainers[checkBlob].containerNames;
        }

        setSelectedContianer(newSelectedContainers);
        getBlobs(showBobs);
        setStoreContainerName(showBobs);
        setPage(0);
    }

    const handleBlobsToggle = (ListBlobs: IBlobContainerNames) => () => {
        let newSelectedBlob = [...selectedBlob].filter(r => r == r[""]);
        if (newSelectedBlob.some(u => u == ListBlobs)) {
            newSelectedBlob = newSelectedBlob.filter(u => u != ListBlobs);
        } else {
            newSelectedBlob.push(ListBlobs);
        }
        setSelectedBlob(newSelectedBlob);
        setGetSelectedFileName(newSelectedBlob);
    }

    //Client Admin view
    const handleFolderToggle = (FNames: IBlobFolders) => () => {
        let newSelectedContainers = [...SelectedFolder].filter(r => r.value == r.value[""]);

        if (newSelectedContainers.some(c => c.value == FNames.value)) {
            newSelectedContainers = newSelectedContainers.filter(c => c.value != FNames.value);
        } else {
            newSelectedContainers.push(FNames);
        }

        let showBobs = "";
        for (var checkBlob in newSelectedContainers) {
            showBobs += newSelectedContainers[checkBlob].value;
        }

        setSelectedFolder(newSelectedContainers);
        getBlobs(showBobs);
        setStoreContainerName(showBobs);
    }

    const DeleteContainer = () => {
        (async () => {
            let request = {
                "containerName": GetSelectedContainers(),
            }
            let response = await usePost<any>("File/DeleteContainer", request);
            empty();
            HandleRefresh();
            if (response.status == 400) {
                dispatch({ type: GlobalStateAction.Error, error: response.statusText })
            }
        })()
    }

    const DeleteBlob = (FileName: string, File_Id: string, ContainerName: string) => {
        (async () => {
            if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
                let request = {
                    "FileName": FileName,
                    "containerName": ContainerName,
                    "fileId": File_Id
                }
                let response = await usePost<any>("File/DeleteBlob", request);
                getBlobs(ContainerName);
                emptyBlob();
                if (response.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: response.statusText })
                }
                else {
                    setIsDeleteFile(true);
                }
            }
            else {
                let request = {
                    "FileName": FileName,
                    "containerName": ContainerName,
                    "fileId": File_Id
                }
                let response = await usePost<any>("File/DeleteBlob", request);
                getBlobs(ContainerName);
                emptyBlob();
                if (response.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: response.statusText })
                }
            }
        })()
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        const headCells = [
            { id: "", numeric: false, disablePadding: false, label: "SELECT", sortable: false },
            { id: "file_Name", numeric: false, disablePadding: false, label: "FILE Name", sortable: true },
            { id: "upload_Date", numeric: false, disablePadding: false, label: "UPLOADED DATE", sortable: true },
            { id: "", numeric: false, disablePadding: false, label: "ACTION", sortable: false }
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            style={{ background: "#007FFF", color: "white", fontSize: "100%" }} >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)} >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const StyledTableCell = withStyles((theme: Theme) =>
        createStyles({
            head: {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 15,
            },
        }),
    )(TableCell);

    const StyledTableRow = withStyles((theme: Theme) =>
        createStyles({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.action.hover,
                },
                width: "100%",
                height: 30,
            },
        }),
    )(TableRow);

    const ListofContainers = () => (
        <React.Fragment>
            <List dense component="div" role="list"
                subheader={
                    <React.Fragment>
                        <Grid container style={{ display: 'flex' }}>
                            <Grid item xs={6}>
                                <ListSubheader component="div" id="nested-list-subheader" className={classes.nested}>
                                    Folders
                                </ListSubheader>
                            </Grid>
                            <Grid item xs={6} >
                                {DeleteSelected.length > 0 ?
                                    <ListSubheader component="div" id="nested-list-subheader" style={{ display: 'flex', alignContent: 'flex-end', justifyContent: 'flex-end' }} >
                                        <Tooltip title="Delete">
                                            <IconButton aria-label="delete" color="primary" onClick={DeleteContainer}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListSubheader>
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }>

                {!CheckAccess ?
                    ListContainers.map((CName, index) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleContainerToggle(CName)}>
                                <ListItemIcon>
                                    <Checkbox
                                        id="FV_Checkbox"
                                        checked={SelectedContainer.some(c => c.containerNames == CName.containerNames)}
                                        disabled={SelectedContainer.some(c => c.containerNames != CName.containerNames)}
                                        tabIndex={-1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemIcon >
                                    {SelectedContainer.some(c => c.containerNames == CName.containerNames) ? <FolderOpenIcon /> : <FolderIcon />}
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${CName.containerNames}`} />
                            </ListItem>
                        );
                    })
                    :
                    ListFolders.map((FName, index) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleFolderToggle(FName)}>
                                <ListItemIcon>
                                    <Checkbox
                                        id="FV_Checkbox"
                                        checked={SelectedFolder.some(c => c.value == FName.value)}
                                        disabled={SelectedFolder.some(c => c.value != FName.value && FName.id)}
                                        tabIndex={-1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemIcon >
                                    {SelectedFolder.some(c => c.value == FName.value) ? <FolderOpenIcon /> : <FolderIcon />}
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${FName.value}`} />
                            </ListItem>
                        );
                    })
                }
                <ListItem />
            </List>
        </React.Fragment>
    );

    const ListBlob = () => (
        <React.Fragment>
            <TableContainer component={Paper} className={classes.tableBoxShadow}>
                <Table size="small" stickyHeader>
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        <Backdrop className={classes.backdrop} open={ProgressBar}>
                            <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                        </Backdrop>
                        {state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ?
                            stableSort(ListBlobs, getComparator(order, orderBy)).map((row, index) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell>
                                            <ListItem key={index} role="listitem" onClick={handleBlobsToggle(row)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        id="FV_Checkbox"
                                                        checked={selectedBlob.some(c => c == row)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                            </ListItem>
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            <span className={`${searchFiles === "" ? classes.TextNormal : row.file_Name.toLocaleLowerCase().includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                                {row.file_Name}
                                            </span>
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            <span className={`${searchFiles === "" ? classes.TextNormal : moment(row.upload_Date).format(state.GlobalUtils?.settingValue).includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                                {moment(row.upload_Date).format(state.GlobalUtils?.settingValue)}
                                            </span>
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {DeleteFileAccess &&
                                                <ListItemIcon>
                                                    <IconButton aria-label="delete" color="primary" onClick={(_) => { handleDeleteBlobClick(); setGetSelectedFile(row.file_Name); setGetSelectedFileId(row.file_Id); }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })
                            :
                            stableSort(ListBlobs, getComparator(order, orderBy)).map((row, index) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <ListItem key={index} role="listitem" style={{ borderBottom: '0.2px solid', marginTop: '5px' }} onClick={handleBlobsToggle(row)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        id="FV_Checkbox"
                                                        checked={selectedBlob.some(c => c == row)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                            </ListItem>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <span className={`${searchFiles === "" ? classes.TextNormal : row.file_Name.includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                                {row.file_Name}
                                            </span>
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            <span className={`${searchFiles === "" ? classes.TextNormal : row.upload_Date?.split('T')[0].includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                                {moment(row.upload_Date).format(state.GlobalUtils?.settingValue)}
                                            </span>
                                        </StyledTableCell>

                                        <StyledTableCell className={classes.ActionCell}>
                                            {DeleteFileAccess &&
                                                <ListItemIcon>
                                                    <IconButton aria-label="delete" color="primary" onClick={(_) => { handleDeleteBlobClick(); setGetSelectedFileId(row.file_Id); setGetSelectedFile(row.file_Name); }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })
                        }
                        <ListItem />
                    </TableBody>
                </Table>
                {ListBlobs.length === 0 ?
                    <Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                        No records to display..
                    </Typography> : null}
                <TablePagination
                    id="Files_TablePagination"
                    rowsPerPageOptions={[10, 15, 25]}
                    component="div"
                    count={ListBlobs.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>
        </React.Fragment>
    );

    const HandleRefresh = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers");
                setListContainers(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const GetSelectedContainers = () => {
        let CNames = "";
        SelectedContainer.forEach((C) => {
            CNames += C.containerNames;
        })
        return CNames;
    }

    const GetSelectedFolder = () => {
        let CNames = "";
        SelectedFolder.forEach((C) => {
            CNames += C.value;
        })
        return CNames;
    }

    const GetSelectedBlob = () => {
        let BlobName = "";
        selectedBlob.forEach((C) => {
            BlobName += C;
        })
        return BlobName;
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        event.target.files && setSelectedFile(event.target.files[0])
    }

    const handleClickOpenCloud = () => {
        setOpenCloud(true);
    };

    const handleCloseCloud = () => {
        setOpenCloud(false);
    };

    const HandleParseRemoteFile = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
                    let request = {
                        "containerName": GetSelectedContainers(),
                        "fileName": GetSelectedFileName[0].file_Name,
                        "userId": state.userAccessContext?.id,
                        "fileId": GetSelectedFileName[0].file_Id
                    };
                    const response = await usePost<any>("File/GetBlobFileContent", request);
                    const arrayBuffer = base64ToArrayBuffer(response.data.fileContent);
                    GetBlobFileContent(arrayBuffer, response.data.fileName);
                } else if (state.userAccessContext?.role == 0 || 1) {
                    let request = {
                        "containerName": GetSelectedFolder(),
                        "fileName": GetSelectedFileName[0].file_Name,
                        "userId": state.userAccessContext?.id,
                        "fileId": GetSelectedFileName[0].file_Id
                    };
                    const response = await usePost<any>("File/GetBlobFileContent", request);
                    const arrayBuffer = base64ToArrayBuffer(response.data.fileContent);
                    GetBlobFileContent(arrayBuffer, response.data.fileName);
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function GetBlobFileContent(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;

        const link = document.createElement('a');

        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.style.visibility = 'hidden';

            var myRows =
                Papa.parse(url, {
                    download: true,
                    skipEmptyLines: true,
                    complete: function (parsedOutput) {
                        props.onRemoteFileUpload(parsedOutput.data);
                    }
                });
            onUploadFileCSV(blob);
        }
    }


    const onUploadFileCSV = (blob) => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            const formData = new FormData();
            formData.append("FormFile", blob);
            formData.append("file_name", GetSelectedBlob());
            formData.append("file_size", "100");
            formData.append("ContainerName", "upload-csv");
            formData.append("ClientId", props.getClients === "-2" ? "MULC" : props.getClients);
            formData.append("UploadMethod", '0');
            formData.append("Phase", props.getPhase === undefined ? "MULP" : props.getPhase);
            formData.append("userid", state.userAccessContext?.id.toString());
            try {
                const response = await usePost<any>("File/CopyCSVFiles", formData)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Button size="large" variant="contained" color="primary" startIcon={<CloudUploadIcon style={{ fontSize: 30 }} />}
                    onClick={handleClickOpenCloud} className={classes.cloud_btn}>
                    Cloud
                </Button>
                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'xl'}
                    open={openCloud}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={4} className={classes.alignFlexStart}>
                                    <CSVReader
                                        onFileLoad={GetBlobFileContent}
                                        noClick
                                        noDrag
                                    >
                                        {({ file }) => (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={HandleParseRemoteFile}
                                                    startIcon={<CloudUploadIcon />}
                                                >
                                                    Upload
                                                </Button>
                                            </>
                                        )}
                                    </CSVReader>
                                </Grid>
                                <Grid item xs={6} style={{ padding: '5px' }}>
                                    <input value={searchFiles}
                                        type='text' placeholder="Search Files"
                                        className={classes.searchBox}
                                        onChange={e => setSearchFiles(e.target.value.trim())}
                                        onKeyPress={event => { if (event.key === 'Enter') { initiateSearch(); } }}
                                        maxLength={255}
                                    />
                                </Grid>
                                <Grid item xs={2} className={classes.alignFlexEnd}>
                                    <Tooltip title="Refresh">
                                        <IconButton size="small" onClick={HandleRefresh} >
                                            <RefreshIcon style={{ fontSize: 35 }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Paper>

                        <Grid container>
                            <Grid item xs={3} style={{ borderRight: '1px solid', borderBottom: '1px solid' }}>
                                <Scrollbars autoHide
                                    autoHideTimeout={1000}
                                    autoHideDuration={10}
                                    autoHeight
                                    autoHeightMin={0}
                                    autoHeightMax={800}
                                    style={{ width: "100%" }}>
                                    {ListofContainers()}
                                </Scrollbars>
                            </Grid>
                            <React.Fragment>
                                <Grid item xs={9} style={{ borderBottom: '1px solid' }}>
                                    <Scrollbars autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={10}
                                        autoHeight
                                        autoHeightMin={50}
                                        autoHeightMax={800}
                                        style={{ width: "100%" }}>
                                        {ListBlob()}
                                    </Scrollbars>
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid item xs={10} className={classes.csvreader}>
                            <CSVReader
                                onFileLoad={GetBlobFileContent}
                                noClick
                                noDrag
                            >
                                {({ file }) => (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={HandleParseRemoteFile}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload
                                    </Button>
                                )}
                            </CSVReader>
                        </Grid>
                        <Button onClick={handleCloseCloud} variant="contained" color="primary" autoFocus className={classes.button}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={'sm'}
                    PaperProps={{ style: { borderRadius: 30 } }}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="h6" gutterBottom style={{ color: 'black' }} >
                                        {selectedFile ? selectedFile.name : ""}
                                    </Typography>
                                    <Box className={`${classes.innerbox} ${classes.alignFlexEnd}`}>
                                        <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                                            <Button style={{ borderRadius: 20, background: "#007FFF", color: "white" }} component="label"
                                                variant="contained" startIcon={<CloudUploadIcon />}>
                                                Choose File
                                                <input
                                                    id="NF_SelectFile"
                                                    onChange={changeHandler}
                                                    name="file"
                                                    ref={fileUpload}
                                                    hidden
                                                    multiple type="file"
                                                    accept="image/jpeg,image/jpg,application/pdf"
                                                />
                                            </Button>
                                        </form>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className={classes.innerbox}>
                                        <Button variant="contained" color="primary" className={classes.button} onClick={(_) => { setOpen(false); }} disabled={!selectedFile}>
                                            Submit
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            close
                        </Button>
                    </DialogActions>
                    <Snackbar open={isDeleteFile} anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }} autoHideDuration={4000} onClose={closeSnackbar}>
                        <Alert onClose={closeSnackbar} severity="success">
                            File Deleted Successfully.
                        </Alert>
                    </Snackbar>
                </Dialog>

                <Dialog
                    PaperProps={{ style: { borderRadius: 10 } }}
                    fullScreen={fullScreen}
                    open={DeleteBlobopen}
                    id="FV_DeleteBlobDialog"
                >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleDeleteBlobClose}>
                        <Typography variant="h5" gutterBottom className={classes.titleheader}>
                            DOCUMENT UPLOAD
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Typography variant="h6" style={{ color: 'black' }}>
                            Are you sure you want to delete {GetSelectedFile} file ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(_) => { DeleteBlob(GetSelectedFile, GetSelectedFileId, storeContainerName); handleDeleteBlobClose(); }} color="primary" autoFocus variant="contained" className={classes.yesButton}>
                            Yes
                        </Button>
                        <Button autoFocus onClick={handleDeleteBlobClose} color="primary" variant="contained" className={classes.delete}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        </React.Fragment >
    )
}

export default CloudUpload