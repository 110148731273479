import React, { useEffect, useState } from 'react'
import {
    Button, CssBaseline, Dialog, DialogActions, DialogContent, Typography,
    DialogTitle, Grid, IconButton, makeStyles, TextField
} from '@material-ui/core'
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore'
import ShareIcon from '@material-ui/icons/Share';
import "react-multi-email/style.css";
import { usePost } from '../../utils/apiHelper';
import { IShareReport } from '../../models/Reports/IShareReport';
import { IReportsList } from '../../models/Reports/ReportsList';
import CurrencyInput from 'react-currency-input-field';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';

const useStyles = makeStyles((theme) => ({
    button: {
        paddingBottom: "10px",
        paddingTop: "10px",
        width: 165,
        borderRadius: 10,
        marginTop: "-40px"
    },
    root1: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1)
        }
    },
    customTextField: {
        "& input::placeholder": {
            fontSize: "20px"
        }
    },
    inputField: {
        width: '95%',
        height: '30px',
        fontSize: 17,
        borderRadius: '4px',
        position: 'relative',
        transition: '0.3s'
    },
    snackbar: {
        color: 'black',
        marginTop: '-0.5cm',
    },
    snackbarfont: {
        fontSize: 16,
    },
    sendButton: {
        background: "green",
        color: "white",
        marginRight: "8px",
        marginTop: "7px",
        float: 'right',
        borderRadius: 20,
        marginBottom: '0.3cm',
        '&:hover': {
            background: "green",
            color: "white",
        }
    },
    cancelButton: {
        background: "red",
        color: "white",
        marginTop: "7px",
        marginBottom: '0.3cm',
        marginRight: "20px",
        borderRadius: 20,
        '&:hover': {
            background: "red",
            color: "white",
        }
    },
}))

const ReportShare: React.FC<{ reportRow?: IReportsList, reportName: string }> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { state, dispatch } = useGlobalState();
    const { reportRow } = props;
    const [Share, setShare] = useState(false);
    const [count, setCount] = useState<any>();
    const [disable, setDisable] = useState(false);
    const [filter, setFilter] = React.useState(false);
    const [EmailName, setEmailName] = useState("");
    const [error, setError] = useState("");

    const regexValidEmail = /^([A-Za-z0-9]+(?:[._-][A-Za-z0-9]+)*)@([A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[a-z]{2,6})$/;
    const preventSpecialKeys = ["~", "!", "#", "$", "%", "^", "&", "*", "(", ")", "+", "=", "{", "}", "'", ">", "<", "?", "|", "/", " ", ";", "`"];
    const preventBackSlash = "Backslash";

    useEffect(() => {
        (async () => {
            setEmailName(props.reportRow.distro_emails);
            setCount(props.reportRow.distro_frequency);
        })()
    }, [])

    useEffect(() => {
        if (reportRow.default_report === 0) {
            setDisable(true);
        }
        else if (reportRow.default_report === 1) {
            setDisable(false);
        }
    }, [disable])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setError('');
    };

    const preventSpecialCharacters = (e) => {
        if (preventSpecialKeys.includes(e.key)) {
            e.preventDefault();
        } else if (e.code === preventBackSlash) {
            e.preventDefault();
        }
    };

    function handleOnBlur(event) {
        var emails = event.target.value;
        emails = emails.split(",");

        for (var i = 0; i < emails.length; i++) {
            if (emails[i] == "" || !regexValidEmail.test(emails[i])) {
                setShare(false);
                setError("Formatting error:make sure all email addresses are correct and separated only by commas.");
                break;
            }
            else {
                setShare(true);
                setError('');
            }
        }
    }

    function emptyCheck() {
        setOpen(false);
        setEmailName('');
    }

    const closeFilterSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setFilter(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const ShareReportAsync = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "id": reportRow.id,
                    "distro_emails": EmailName,
                    "distro_frequency": count
                };
                await usePost<IShareReport[]>('ReportDistributionSet', request)
                setShare(false);
                setFilter(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    function onCountChange(value: string | undefined) {
        setCount(value === '' ? '' : value || '');
    }

    return (
        <React.Fragment>
            <IconButton id="RS_Share" onClick={handleClickOpen}
                style={{ marginTop: "10px" }}
                color="primary"
                disabled={!disable}
                aria-label="Share Icon">
                <ShareIcon style={{ fontSize: 28 }} fontSize="large" />
            </IconButton>
            <Snackbar className={classes.snackbar} open={filter} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeFilterSnackbar}>
                <Alert onClose={closeFilterSnackbar} severity="success" className={classes.snackbarfont}>
                    Report was send out Successfully!
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={(_) => { setOpen(false); }} maxWidth={'md'}
                PaperProps={{ style: { borderRadius: 17 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                        REPORT DISTRIBUTION
                    </Typography>
                </DialogTitleHeader>
                <DialogTitle id="responsive-dialog-title" >
                    <Typography variant="h6" gutterBottom style={{ fontSize: 26 }}>
                        <b>{props.reportName}</b>
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ marginTop: "-28px" }} >
                    <Typography variant="subtitle1" gutterBottom>
                        This report will be distributed to the email addresses below. Please separate addresses with a comma.
                    </Typography>
                    <CssBaseline />
                    <Grid item xs={12} >
                        <TextField id="Email" label="Email" value={EmailName}
                            autoComplete="off"
                            onChange={(e) => { setEmailName(e.target.value); }}
                            onKeyPress={preventSpecialCharacters}
                            onBlur={(e) => { handleOnBlur(e); }}
                            placeholder="example@email.com , example2@email.com" fullWidth
                            variant="outlined" style={{ marginTop: "10px" }}
                            InputLabelProps={{
                                shrink: true,
                            }} required helperText={error} error={!!error}
                            inputProps={{ maxlength: 100 }} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Distribute this report every
                            <CurrencyInput
                                maxLength={2}
                                allowDecimals={false}
                                allowNegativeValue={false}
                                value={count}
                                style={{ width: "8%", marginLeft: "13px" }}
                                className={classes.inputField}
                                decimalsLimit={0}
                                onValueChange={(e) => { onCountChange(e); }}
                            />
                            days.
                        </Typography>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginLeft: "-3cm" }}>
                    <Button id="RS_send_btn" variant="contained" className={classes.sendButton}
                        onClick={(_) => { ShareReportAsync(); emptyCheck(); }} disabled={!Share}
                        style={!Share ? { color: "white" } : { background: "green", color: "white" }}>
                        Submit
                    </Button>
                    <Button id="RS_Cancel_btn" variant="outlined" onClick={(_) => { emptyCheck(); setShare(false); }} className={classes.cancelButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}

export default ReportShare


