import { Box, Collapse, Divider, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Columns } from '../../../models/Inquiry/config';
import { IDebtor } from '../../../models/NotificationCenter/IDebtor';
import { INotificationCenter } from '../../../models/NotificationCenter/INotificationCenter';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import InquiryDetail from './PvInquiryDetail';

const useRowStyles = makeStyles({
    root: {
        fontSize: 200
    },
    rootHidden: {
        display: "none"
    },
    buttons: {
        borderRadius: 20,
        background: "#007FFF",
        color: "white",
    },
})

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const PvExpandableRow: React.FC<{ pv: INotificationCenter, selected: boolean, onClick: (accountId: string) => void, onRefresh: () => void, selectedDBTNumber: string }> = (props) => {
    const { pv } = props
    const { state, dispatch } = useGlobalState();
    const classes = useRowStyles()
    const [debtors, setDebtors] = useState<IDebtor[]>([]);
    const UserName = state.userAccessContext?.id;
    const [isActiveStatus, setGetActivestatus] = useState(false);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const componentRef = useRef();
    const prevCount = usePrevious(props.selectedDBTNumber);

    useEffect(() => {
        dispatch({ type: GlobalStateAction.Busy })
    }, [props.selected])

    const GetAccountDetails = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const DebtorDetils = await useFetch<IDebtor[]>("GetNotificationDetails?DBTNumber=" + pv.extraData_1.slice(0, 9))
                setDebtors(DebtorDetils.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": pv.extraData_1.slice(0, 9),
                    "typeId": 5
                }
                props.onRefresh();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const setYieldLock = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const response = await useFetch<any>(`InquiryYieldLock?debtorNumber=${pv.extraData_1.slice(0, 9)}&userId=${state.userAccessContext?.id.toString()}`);
                setGetActivestatus(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(pv.extraData_1.slice(0, 9));
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [pv.extraData_1.slice(0, 9), prevCount, props.selectedDBTNumber])

    const setYieldRelease = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let YieldRelease = {
                    "debtorNumber": GetDebtorNo,
                    "userId": UserName
                }
                await usePost("InquiryReleaseLock", YieldRelease)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root} hover>
                <TableCell>
                    <Box display="flex" p={1}>
                        <Box p={1} width="90%">
                            <Typography style={{ textAlign: "left" }} variant="subtitle1" gutterBottom>
                                <b>{moment(pv.createdDate).format(state.GlobalUtils?.settingValue)} : Payment Verification : Client Acct #{pv.extraData_1.slice(10, 19)} / Agency Acct # {pv.extraData_1.slice(0, 9)} </b>
                            </Typography>
                            <Typography variant="subtitle1" style={{ textAlign: "left" }} gutterBottom>
                                <b>Client Code: {pv.clT_Code} = {pv.clT_Name}</b>
                            </Typography>
                            <Typography variant="subtitle2" style={{ textAlign: "left", color: 'blue', cursor: 'pointer' }} gutterBottom onClick={(_) => { props.onClick(pv.extraData_1.slice(0, 9)); GetAccountDetails(); (pv.extraData_1.slice(0, 9) !== props.selectedDBTNumber ? setYieldRelease() : setGetDebtorNo("")); (props.selected === true ? setYieldRelease() : setYieldLock()); }}>
                                Verify Balance / Report Payment
                            </Typography>
                        </Box>
                    </Box>
                    <Divider style={{ marginTop: "5px", height: '2px', background: "black" }} />
                </TableCell>
            </TableRow>
            {debtors.map((sr) => {
                return (
                    <TableRow className={props.selected ? classes.root : classes.rootHidden} ref={componentRef}>
                        <TableCell scope="row" colSpan={Columns.length}>
                            <Collapse in={props.selected} timeout="auto" unmountOnExit >
                                <InquiryDetail inquiryDetail={sr} onRefresh={hidenotificationForAllUsers} isBlocked={isActiveStatus} onCheck={setYieldLock} />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                );
            })}
        </React.Fragment>
    )
}

export default PvExpandableRow