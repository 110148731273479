import React, { Component, useState } from 'react';
import { CSVReader, readRemoteFile } from 'react-papaparse';
import { Button, Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloudUpload from "./CloudUpload";
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const titleHeader = {
  fontWeight: 'bold',
  marginTop: '5px'
};

const browseButton = {
  borderRadius: 10,
  marginTop: '0.8cm',
  marginBottom: '0.8cm',
  fontSize: 16,
  fontWeight: 600,
  marginLeft: '-1cm',
};

const placement = {
  display: "flex",
  alignItems: 'flex-end',
  justifyContent: 'flex-end'
}

const upload = {
  borderRadius: 10,
  paddingBottom: "10px",
  paddingTop: "10px",
  width: '185px',
  fontWeight: 'bold',
  marginLeft: '1.5cm',
  fontSize: 16
}

const buttonRef = React.createRef();
export default class CSVFileReader extends Component {

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = (data) => {
    this.props.csvUpload(data);
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log('---------------------------');
    console.log(err);
    console.log('---------------------------');
  };

  constructor(props) {
    super(props);
    this.state = {}
    this.handleOpenDialogBox = this.handleOpenDialogBox.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
    this.handleCloseDialogBox = this.handleCloseDialogBox.bind(this);
    this.state = { fileuploaded: false }
  }

  handleOpenDialogBox() {
    this.setState({
      openDialog: true
    });
  }


  handleCloseDialogBox() {
    this.setState({
      openDialog: false
    });
  }

  onUploadFile = (file) => {
    if (file !== null) {
      this.props.fileUpload(file);
    }
  }

  render() {
    return (
      <>
        <Button variant="contained" color="primary" disabled={this.props.disabled}
          size="large" style={upload} onClick={this.handleOpenDialogBox} startIcon={<CloudUploadIcon style={{ fontSize: 30 }} />}>
          Upload CSV
        </Button>
        <Dialog open={this.state.openDialog} onClose={this.handleCloseDialogBox}
          aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }}
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={this.handleCloseDialogBox}>
            <Typography variant="h6" gutterBottom style={titleHeader}>
              CHOOSE PLACEMENT METHOD
            </Typography>
          </DialogTitleHeader>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6} style={placement} >
                <CSVReader ref={buttonRef} onFileLoad={this.handleOnFileLoad}
                  onError={this.handleOnError} skipEmptyLines={true} header={false} accept={['text/*', '.csv']}
                  noClick noDrag config={{
                    delimiter: "",  // auto-detect
                    newline: "",  // auto-detect
                    quoteChar: '"',
                    escapeChar: '"',
                    header: false,
                    transformHeader: undefined,
                    dynamicTyping: false,
                    preview: 0,
                    encoding: "",
                    worker: false,
                    comments: false,
                    step: undefined,
                    complete: undefined,
                    error: undefined,
                    download: false,
                    downloadRequestHeaders: undefined,
                    skipEmptyLines: true,
                    chunk: undefined,
                    fastMode: undefined,
                    beforeFirstChunk: undefined,
                    withCredentials: undefined,
                    transform: undefined,
                    delimitersToGuess: [',', '	', '|', ';', '\t']
                  }}
                >
                  {({ file }) => (
                    <>
                      <Button size="large" style={browseButton} startIcon={<OpenInBrowserIcon style={{ fontSize: 30 }} />}
                        variant="contained" color="primary" component="span" onClick={this.handleOpenDialog}>
                        Browse File
                      </Button>
                      {this.onUploadFile(file)}
                    </>
                  )}
                </CSVReader>
              </Grid>
              <Grid item xs={6}>
                <CloudUpload onRemoteFileUpload={this.handleOnFileLoad} getPhase={this.props.getphase} getClients={this.props.getClientCode} />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
