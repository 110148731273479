import { makeStyles, Slide, Snackbar, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import React, { useEffect, useState } from 'react';
import { ITemplateCreate } from '../../../models/Admin/ClientPlacements/ITemplateCreate';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
        flexGrow: 1,
      },
    },
    formControl: {
      justifyContent: "center",
      marginTop: '20px'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0),
      color: 'black',
    },
    editbutton: {
      borderRadius: 20,
      backgroundColor: 'green',
      '&:hover': {
        background: "green",
      }
    },
    updatebutton: {
      borderRadius: 20,
      marginRight: '20px',
      marginBottom: '0.2cm',
      backgroundColor: 'green',
      '&:hover': {
        background: "green",
      }
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EditTemplate: React.FC<{ templateRow: ITemplateCreate, onSave: () => void }> = (props) => {
  const { templateRow } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useGlobalState();
  const classes = useRowStyles();
  const [TempName, setTempName] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isCreate, setIsCreate] = React.useState(false);

  const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  var newTemplate: ITemplateCreate = {
    placement_template_id: templateRow.placement_template_id,
    name: templateRow.name,
    account_no: templateRow.account_no,
    account_noRequired: templateRow.account_noRequired,
    first_name: templateRow.first_name,
    first_nameRequired: templateRow.first_nameRequired,
    last_name: templateRow.last_name,
    last_nameRequired: templateRow.last_nameRequired,
    full_name: templateRow.full_name,
    full_nameRequired: templateRow.full_nameRequired,
    address1: templateRow.address1,
    address1Required: templateRow.address1Required,
    address2: templateRow.address2,
    address2Required: templateRow.address2Required,
    city: templateRow.city,
    cityRequired: templateRow.cityRequired,
    state: templateRow.state,
    stateRequired: templateRow.stateRequired,
    zip: templateRow.zip,
    zipRequired: templateRow.zipRequired,
    phone_no: templateRow.phone_no,
    phone_noRequired: templateRow.phone_noRequired,
    total_balance: templateRow.total_balance,
    total_balanceRequired: templateRow.total_balanceRequired,
    delinquency_date: templateRow.delinquency_date,
    delinquency_dateRequired: templateRow.delinquency_dateRequired,
    secondary_address1: templateRow.secondary_address1,
    secondary_address1Required: templateRow.secondary_address1Required,
    secondary_address2: templateRow.secondary_address2,
    secondary_address2Required: templateRow.secondary_address2Required,
    secondary_city: templateRow.secondary_city,
    secondary_cityRequired: templateRow.secondary_cityRequired,
    secondary_state: templateRow.secondary_state,
    secondary_stateRequired: templateRow.secondary_stateRequired,
    secondary_zip: templateRow.secondary_zip,
    secondary_zipRequired: templateRow.secondary_zipRequired,
    secondary_phone_no: templateRow.secondary_phone_no,
    secondary_phone_noRequired: templateRow.secondary_phone_noRequired,
    email: templateRow.email,
    emailRequired: templateRow.emailRequired,
    drivers_license: templateRow.drivers_license,
    drivers_licenseRequired: templateRow.drivers_licenseRequired,
    tax_id: templateRow.tax_id,
    tax_idRequired: templateRow.tax_idRequired,
    birth_date: templateRow.birth_date,
    birth_dateRequired: templateRow.birth_dateRequired,
    past_due_balance: templateRow.past_due_balance,
    past_due_balanceRequired: templateRow.past_due_balanceRequired,
    current_balance: templateRow.current_balance,
    current_balanceRequired: templateRow.current_balanceRequired,
    equipment_charges: templateRow.equipment_charges,
    equipment_chargesRequired: templateRow.equipment_chargesRequired,
    contract_charges: templateRow.contract_charges,
    contract_chargesRequired: templateRow.contract_chargesRequired,
    fees: templateRow.fees,
    feesRequired: templateRow.feesRequired,
    last_payment_date: templateRow.last_payment_date,
    last_payment_dateRequired: templateRow.last_payment_dateRequired,
    guarantor_first_name: templateRow.guarantor_first_name,
    guarantor_first_nameRequired: templateRow.guarantor_first_nameRequired,
    guarantor_last_name: templateRow.guarantor_last_name,
    guarantor_last_nameRequired: templateRow.guarantor_last_nameRequired,
    guarantor_full_name: templateRow.guarantor_full_name,
    guarantor_full_nameRequired: templateRow.guarantor_full_nameRequired,
    comment: templateRow.comment,
    commentRequired: templateRow.commentRequired,
    account_type: templateRow.account_type,
    account_typeRequired: templateRow.account_typeRequired,
    other: templateRow.other,
    otherRequired: templateRow.otherRequired,
    contact_name: templateRow.contact_name,
    contact_nameRequired: templateRow.contact_nameRequired,
    service_date: templateRow.service_date,
    service_dateRequired: templateRow.service_dateRequired,
    location: templateRow.location,
    locationRequired: templateRow.locationRequired,
    codebtor_Full_Name: templateRow.codebtor_Full_Name,
    codebtor_Full_NameRequired: templateRow.codebtor_Full_NameRequired,
    codebtor_Street_Address1: templateRow.codebtor_Street_Address1,
    codebtor_Street_Address1Required: templateRow.codebtor_Street_Address1Required,
    codebtor_Street_Address2: templateRow.codebtor_Street_Address2,
    codebtor_Street_Address2Required: templateRow.codebtor_Street_Address2Required,
    codebtor_City: templateRow.codebtor_City,
    codebtor_CityRequired: templateRow.codebtor_CityRequired,
    codebtor_State: templateRow.codebtor_State,
    codebtor_StateRequired: templateRow.codebtor_StateRequired,
    codebtor_Zip: templateRow.codebtor_Zip,
    codebtor_ZipRequired: templateRow.codebtor_ZipRequired,
    codebtor_BirthDate: templateRow.codebtor_BirthDate,
    codebtor_BirthDateRequired: templateRow.codebtor_BirthDateRequired,
    codebtor_Tax_ID: templateRow.codebtor_Tax_ID,
    codebtor_Tax_IDRequired: templateRow.codebtor_Tax_IDRequired,
    coDebtor_First_Name: templateRow.coDebtor_First_Name,
    coDebtor_First_NameRequired: templateRow.coDebtor_First_NameRequired,
    coDebtor_Last_Name: templateRow.coDebtor_Last_Name,
    coDebtor_Last_NameRequired: templateRow.coDebtor_Last_NameRequired,
    coDebtor_Phone1: templateRow.coDebtor_Phone1,
    coDebtor_Phone1Required: templateRow.coDebtor_Phone1Required,
    coDebtor_Phone2: templateRow.coDebtor_Phone2,
    coDebtor_Phone2Required: templateRow.coDebtor_Phone2Required,
    itemization_Date: templateRow.itemization_Date,
    itemization_DateRequired: templateRow.itemization_DateRequired,
    balance_owed_on_itemization: templateRow.balance_owed_on_itemization,
    balance_owed_on_itemizationRequired: templateRow.balance_owed_on_itemizationRequired,
    fees_added_since_itemization: templateRow.fees_added_since_itemization,
    fees_added_since_itemizationRequired: templateRow.fees_added_since_itemizationRequired,
    interest_added_since_itemization: templateRow.interest_added_since_itemization,
    interest_added_since_itemizationRequired: templateRow.interest_added_since_itemizationRequired,
    payments_Applied_Since_Itemization_Date: templateRow.payments_Applied_Since_Itemization_Date,
    payments_Applied_Since_Itemization_DateRequired: templateRow.payments_Applied_Since_Itemization_DateRequired,
    credits_Applied_Since_Itemization_Date: templateRow.credits_Applied_Since_Itemization_Date,
    credits_Applied_Since_Itemization_DateRequired: templateRow.credits_Applied_Since_Itemization_DateRequired,
  }

  const handleSave = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await usePost("ClientPlacement/ModifyTemplate", localState)
        props.onSave();
        setOpen(false);
        setIsCreate(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const HandleTempNameVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,:;{}/|[\]\\$'"]/)) {
      setError("");
      setIsValid(false);
      setTempName(newValue);
    } else {
      setError("please enter characters and numbers only");
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (TempName.length > 50) {
      setError("Max length is 50")
      setIsValid(true);
    } else {
      setError("")
      setIsValid(false);
    }
  }, [TempName])

  const [localState, setlocalstate] = React.useState(newTemplate);

  const handleClose = () => {
    setOpen(false);
    setTempName("");
    setlocalstate(templateRow);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setlocalstate({
      ...localState,
      [name]: event.target.value,
    });
  };

  const handleChecked = (event) => {
    const name = event.target.name;
    setlocalstate({
      ...localState,
      [name]: event.target.checked,
    });
  };

  return (
    <React.Fragment>
      <div>
        <Button id="CPO_Edit" startIcon={<BorderColorIcon />} variant="contained" color="primary" onClick={handleClickOpen}
          className={classes.editbutton}>Edit</Button>
      </div>
      <div>
        <Dialog maxWidth={'lg'} fullWidth={true}
          PaperProps={{ style: { borderRadius: 15 } }}
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="responsive-dialog-title" >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
              EDIT TEMPLATE
            </Typography>
          </DialogTitleHeader>
          <DialogContent >
            <Grid container spacing={3}>
              <Grid xs={12} style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                {/* <Typography variant="subtitle1" style={{ color: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {
                    (((!localState.first_name) && (!localState.last_name) && (!localState.full_name)) || (localState.first_name && localState.last_name && localState.full_name)) ? "Please Select either (First Name or Last Name) or Full Name" : (localState.first_name && localState.full_name) ? "Please select either First Name or Full Name" : (localState.last_name && localState.full_name) ? "Please select either Last Name or Full Name" : <div></div>
                  }
                </Typography> */}
              </Grid>
              <Grid item xs={12}>
                <form className={classes.formControl} noValidate autoComplete="off" >
                  <TextField id="ET_Name" label="Name" value={localState.name} name="name"
                    variant="outlined" fullWidth margin="dense" onChange={(e) => { handleChange(e); HandleTempNameVal(e); }} helperText={error} error={!!error} style={{ boxShadow: '0px 1px 4px  rgba(0, 0, 0, 0.4)', borderRadius: '3px' }} />
                </form>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: "60%" }} >{"Permitted"}</DialogTitle>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      id="ET_AccountNumber"
                      control={<Checkbox onChange={handleChecked} checked={localState.account_no} name="account_no" />}
                      labelPlacement="start"
                      label="AccountNumber"
                    />
                    <FormControlLabel
                      id="ET_firstname"
                      control={<Checkbox onChange={handleChecked} checked={localState.first_name} name="first_name" />}
                      labelPlacement="start"
                      label="First Name"
                    />
                    <FormControlLabel
                      id="ET_lastname"
                      control={<Checkbox onChange={handleChecked} checked={localState.last_name} name="last_name" />}
                      labelPlacement="start"
                      label="Last Name"
                    />
                    <FormControlLabel
                      id="ET_fullname"
                      control={<Checkbox onChange={handleChecked} checked={localState.full_name} name="full_name" />}
                      labelPlacement="start"
                      label="Full Name/Business Name"
                    />
                    <FormControlLabel
                      id="ET_streetAddress1"
                      control={<Checkbox onChange={handleChecked} checked={localState.address1} name="address1" />}
                      labelPlacement="start"
                      label="Street Address 1"
                    />
                    <FormControlLabel
                      id="ET_streetAddress2"
                      control={<Checkbox onChange={handleChecked} checked={localState.address2} name="address2" />}
                      labelPlacement="start"
                      label="Street Address 2"
                    />
                    <FormControlLabel
                      id="ET_City"
                      control={<Checkbox onChange={handleChecked} checked={localState.city} name="city" />}
                      labelPlacement="start"
                      label="City"
                    />
                    <FormControlLabel
                      id="ET_State"
                      control={<Checkbox onChange={handleChecked} checked={localState.state} name="state" />}
                      labelPlacement="start"
                      label="State"
                    />
                    <FormControlLabel
                      id="ET_Zip"
                      control={<Checkbox onChange={handleChecked} checked={localState.zip} name="zip" />}
                      labelPlacement="start"
                      label="Zip"
                    />
                    <FormControlLabel
                      id="ET_phoneNumber"
                      control={<Checkbox onChange={handleChecked} checked={localState.phone_no} name="phone_no" />}
                      labelPlacement="start"
                      label="Phone Number"
                    />
                    <FormControlLabel
                      id="ET_totalBalance"
                      control={<Checkbox onChange={handleChecked} checked={localState.total_balance} name="total_balance" />}
                      labelPlacement="start"
                      label="Total Balance"
                    />
                    <FormControlLabel
                      id="ET_dateofDelinquency"
                      control={<Checkbox onChange={handleChecked} checked={localState.delinquency_date} name="delinquency_date" />}
                      labelPlacement="start"
                      label="Date of Delinquency"
                    />
                    <FormControlLabel
                      id="ET_streetAddress1secondary"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_address1} name="secondary_address1" />}
                      labelPlacement="start"
                      label="Street Address 1 (Secondary)"
                    />
                    <FormControlLabel
                      id="ET_streetAddress2secondary"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_address2} name="secondary_address2" />}
                      labelPlacement="start"
                      label="Street Address 2 (Secondary)"
                    />
                    <FormControlLabel
                      id="ET_citysecondary"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_city} name="secondary_city" />}
                      labelPlacement="start"
                      label="City (Secondary)"
                    />
                    <FormControlLabel
                      id="ET_statesecondary"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_state} name="secondary_state" />}
                      labelPlacement="start"
                      label="State (Secondary)"
                    />
                    <FormControlLabel
                      id="ET_zipsecondary"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_zip} name="secondary_zip" />}
                      labelPlacement="start"
                      label="Zip (Secondary)"
                    />
                    <FormControlLabel
                      id="ET_phoneNumbersecondary"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_phone_no} name="secondary_phone_no" />}
                      labelPlacement="start"
                      label="Phone Number (Secondary)"
                    />
                    <FormControlLabel
                      id="ET_emailAddress"
                      control={<Checkbox onChange={handleChecked} checked={localState.email} name="email" />}
                      labelPlacement="start"
                      label="Email Address"
                    />
                    <FormControlLabel
                      id="ET_driverslicense"
                      control={<Checkbox onChange={handleChecked} checked={localState.drivers_license} name="drivers_license" />}
                      labelPlacement="start"
                      label="Drivers License"
                    />
                    <FormControlLabel
                      id="ET_ssTaxid"
                      control={<Checkbox onChange={handleChecked} checked={localState.tax_id} name="tax_id" />}
                      labelPlacement="start"
                      label="SS# / Tax ID"
                    />
                    <FormControlLabel
                      id="ET_birthdate"
                      control={<Checkbox onChange={handleChecked} checked={localState.birth_date} name="birth_date" />}
                      labelPlacement="start"
                      label="BirthDate"
                    />
                    <FormControlLabel
                      id="ET_balancePastDuePortion"
                      control={<Checkbox onChange={handleChecked} checked={localState.past_due_balance} name="past_due_balance" />}
                      labelPlacement="start"
                      label="Balance (Past Due Portion)"
                    />
                    <FormControlLabel
                      id="ET_balanceCurrentPortion"
                      control={<Checkbox onChange={handleChecked} checked={localState.current_balance} name="current_balance" />}
                      labelPlacement="start"
                      label="Balance (Current Portion)"
                    />
                    <FormControlLabel
                      id="ET_EquipmentCharges"
                      control={<Checkbox onChange={handleChecked} checked={localState.equipment_charges} name="equipment_charges" />}
                      labelPlacement="start"
                      label="Equipment Charges"
                    />
                    <FormControlLabel
                      id="ET_contractcharges"
                      control={<Checkbox onChange={handleChecked} checked={localState.contract_charges} name="contract_charges" />}
                      labelPlacement="start"
                      label="Contract Charges"
                    />
                    <FormControlLabel
                      id="ET_fees"
                      control={<Checkbox onChange={handleChecked} checked={localState.fees} name="fees" />}
                      labelPlacement="start"
                      label="Fees"
                    />
                    <FormControlLabel
                      id="ET_lastpaymentdate"
                      control={<Checkbox onChange={handleChecked} checked={localState.last_payment_date} name="last_payment_date" />}
                      labelPlacement="start"
                      label="Last Payment Date"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: "-10%" }}>
                  {"Required"}
                </DialogTitle>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      id="ET_account_noRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.account_noRequired} name="account_noRequired" disabled={!localState.account_no} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_firstnameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.first_nameRequired} name="first_nameRequired" disabled={!localState.first_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_lastnameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.last_nameRequired} name="last_nameRequired" disabled={!localState.last_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_fullnameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.full_nameRequired} name="full_nameRequired" disabled={!localState.full_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_streetAddress1Required"
                      control={<Checkbox onChange={handleChecked} checked={localState.address1Required} name="address1Required" disabled={!localState.address1} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_streetAddress2Required"
                      control={<Checkbox onChange={handleChecked} checked={localState.address2Required} name="address2Required" disabled={!localState.address2} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_cityRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.cityRequired} name="cityRequired" disabled={!localState.city} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_stateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.stateRequired} name="stateRequired" disabled={!localState.state} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_zipRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.zipRequired} name="zipRequired" disabled={!localState.zip} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_phoneNumberRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.phone_noRequired} name="phone_noRequired" disabled={!localState.phone_no} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_totalBalanceRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.total_balanceRequired} name="total_balanceRequired" disabled={!localState.total_balance} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_dateofDelinquencyRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.delinquency_dateRequired} name="delinquency_dateRequired" disabled={!localState.delinquency_date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_streetAddress1secondaryRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_address1Required} name="secondary_address1Required" disabled={!localState.secondary_address1} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_streetAddress2secondaryRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_address2Required} name="secondary_address2Required" disabled={!localState.secondary_address2} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_citysecondaryRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_cityRequired} name="secondary_cityRequired" disabled={!localState.secondary_city} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_statesecondaryRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_stateRequired} name="secondary_stateRequired" disabled={!localState.secondary_state} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_zipsecondaryRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_zipRequired} name="secondary_zipRequired" disabled={!localState.secondary_zip} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_phoneNumbersecondaryRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.secondary_phone_noRequired} name="secondary_phone_noRequired" disabled={!localState.secondary_phone_no} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_emailAddressRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.emailRequired} name="emailRequired" disabled={!localState.email} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_driverslicenseRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.drivers_licenseRequired} name="drivers_licenseRequired" disabled={!localState.drivers_license} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_ssTaxidRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.tax_idRequired} name="tax_idRequired" disabled={!localState.tax_id} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_birthdateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.birth_dateRequired} name="birth_dateRequired" disabled={!localState.birth_date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_balancePastDuePortionRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.past_due_balanceRequired} name="past_due_balanceRequired" disabled={!localState.past_due_balance} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_balanceCurrentPortionRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.current_balanceRequired} name="current_balanceRequired" disabled={!localState.current_balance} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_equipmentchargesRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.equipment_chargesRequired} name="equipment_chargesRequired" disabled={!localState.equipment_charges} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_contractchargesRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.contract_chargesRequired} name="contract_chargesRequired" disabled={!localState.contract_charges} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_feesRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.feesRequired} name="feesRequired" disabled={!localState.fees} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_lastpaymentdateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.last_payment_dateRequired} name="last_payment_dateRequired" disabled={!localState.last_payment_date} />}
                      labelPlacement="start"
                      label=""
                    /> 
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: "75%" }} >{"Permitted"}</DialogTitle>
                <FormControl component="fieldset">
                  <FormGroup>                    
                    <FormControlLabel
                      id="ET_guarantorfirstname"
                      control={<Checkbox onChange={handleChecked} checked={localState.guarantor_first_name} name="guarantor_first_name" />}
                      labelPlacement="start"
                      label="Guarantor First Name"
                    />
                    <FormControlLabel
                      id="ET_guarantorlastname"
                      control={<Checkbox onChange={handleChecked} checked={localState.guarantor_last_name} name="guarantor_last_name" />}
                      labelPlacement="start"
                      label="Guarantor Last Name"
                    />
                    <FormControlLabel
                      id="ET_guarantorlastname"
                      control={<Checkbox onChange={handleChecked} checked={localState.guarantor_full_name} name="guarantor_full_name" />}
                      labelPlacement="start"
                      label="Guarantor Full Name"
                    />
                    <FormControlLabel
                      id="ET_comment"
                      control={<Checkbox onChange={handleChecked} checked={localState.comment} name="comment" />}
                      labelPlacement="start"
                      label="Comment"
                    />
                    <FormControlLabel
                      id="ET_accounttype"
                      control={<Checkbox onChange={handleChecked} checked={localState.account_type} name="account_type" />}
                      labelPlacement="start"
                      label="Account Type"
                    />
                    <FormControlLabel
                      id="ET_other"
                      control={<Checkbox onChange={handleChecked} checked={localState.other} name="other" />}
                      labelPlacement="start"
                      label="Other"
                    />
                    <FormControlLabel
                      id="ET_contact_name"
                      control={<Checkbox onChange={handleChecked} checked={localState.contact_name} name="contact_name" />}
                      labelPlacement="start"
                      label="Contact Name"
                    />
                    <FormControlLabel
                      id="ET_service_date"
                      control={<Checkbox onChange={handleChecked} checked={localState.service_date} name="service_date" />}
                      labelPlacement="start"
                      label="Service Date"
                    />
                    <FormControlLabel
                      id="ET_location"
                      control={<Checkbox onChange={handleChecked} checked={localState.location} name="location" />}
                      labelPlacement="start"
                      label="Location"
                    />
                    <FormControlLabel
                      id="ET_codebtor_Full_Name"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Full_Name} name="codebtor_Full_Name" />}
                      labelPlacement="start"
                      label="Codebtor Full Name"
                    />
                    <FormControlLabel
                      id="ET_codebtor_Street_Address1"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Street_Address1} name="codebtor_Street_Address1" />}
                      labelPlacement="start"
                      label="Codebtor Street Address 1"
                    />
                    <FormControlLabel
                      id="ET_codebtor_Street_Address2"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Street_Address2} name="codebtor_Street_Address2" />}
                      labelPlacement="start"
                      label="Codebtor Street Address 2"
                    />
                    <FormControlLabel
                      id="ET_codebtor_City"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_City} name="codebtor_City" />}
                      labelPlacement="start"
                      label="Codebtor City"
                    />
                    <FormControlLabel
                      id="ET_codebtor_State"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_State} name="codebtor_State" />}
                      labelPlacement="start"
                      label="Codebtor State"
                    />
                    <FormControlLabel
                      id="ET_codebtor_Zip"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Zip} name="codebtor_Zip" />}
                      labelPlacement="start"
                      label="Codebtor Zip"
                    />
                    <FormControlLabel
                      id="ET_codebtor_BirthDate"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_BirthDate} name="codebtor_BirthDate" />}
                      labelPlacement="start"
                      label="Codebtor BirthDate"
                    />
                    <FormControlLabel
                      id="ET_codebtor_Tax_ID"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Tax_ID} name="codebtor_Tax_ID" />}
                      labelPlacement="start"
                      label="Codebtor SS# / Tax ID"
                    />
                    <FormControlLabel
                      id="ET_codebtor_First_Name"
                      control={<Checkbox onChange={handleChecked} checked={localState.coDebtor_First_Name} name="coDebtor_First_Name" />}
                      labelPlacement="start"
                      label="CoDebtor First Name"
                    />
                    <FormControlLabel
                      id="ET_coDebtor_Last_Name"
                      control={<Checkbox onChange={handleChecked} checked={localState.coDebtor_Last_Name} name="coDebtor_Last_Name" />}
                      labelPlacement="start"
                      label="CoDebtor Last Name"
                    />
                    <FormControlLabel
                      id="ET_coDebtor_Phone1"
                      control={<Checkbox onChange={handleChecked} checked={localState.coDebtor_Phone1} name="coDebtor_Phone1" />}
                      labelPlacement="start"
                      label="CoDebtor Phone1"
                    />
                    <FormControlLabel
                      id="ET_coDebtor_Phone2"
                      control={<Checkbox onChange={handleChecked} checked={localState.coDebtor_Phone2} name="coDebtor_Phone2" />}
                      labelPlacement="start"
                      label="CoDebtor Phone2"
                    />
                    <FormControlLabel
                      id="ET_itemization_Date"
                      control={<Checkbox onChange={handleChecked} checked={localState.itemization_Date} name="itemization_Date" />}
                      labelPlacement="start"
                      label="Itemization Date"
                    />
                    <FormControlLabel
                      id="ET_balance_owed_on_itemization"
                      control={<Checkbox onChange={handleChecked} checked={localState.balance_owed_on_itemization} name="balance_owed_on_itemization" />}
                      labelPlacement="start"
                      label="Balance Owed On Itemization"
                    />
                    <FormControlLabel
                      id="ET_fees_added_since_itemization"
                      control={<Checkbox onChange={handleChecked} checked={localState.fees_added_since_itemization} name="fees_added_since_itemization" />}
                      labelPlacement="start"
                      label="Fees Added Since Itemization"
                    />
                    <FormControlLabel
                      id="ET_interest_added_since_itemization"
                      control={<Checkbox onChange={handleChecked} checked={localState.interest_added_since_itemization} name="interest_added_since_itemization" />}
                      labelPlacement="start"
                      label="Interest Added Since Itemization"
                    />
                    <FormControlLabel
                      id="ET_payments_Applied_Since_Itemization_Date"
                      control={<Checkbox onChange={handleChecked} checked={localState.payments_Applied_Since_Itemization_Date} name="payments_Applied_Since_Itemization_Date" />}
                      labelPlacement="start"
                      label="Payments Applied Since Itemization Date"
                    />
                    <FormControlLabel
                      id="ET_credits_Applied_Since_Itemization_Date"
                      control={<Checkbox onChange={handleChecked} checked={localState.credits_Applied_Since_Itemization_Date} name="credits_Applied_Since_Itemization_Date" />}
                      labelPlacement="start"
                      label="Credits Applied Since Itemization Date"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={1}>
                <DialogTitle id="responsive-dialog-title" style={{ marginLeft: "-10%" }}>{"Required"}</DialogTitle>
                <FormControl component="fieldset">
                  <FormGroup>
                    
                    <FormControlLabel
                      id="ET_guarantorfirstnameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.guarantor_first_nameRequired} name="guarantor_first_nameRequired" disabled={!localState.guarantor_first_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_guarantorlastnameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.guarantor_last_nameRequired} name="guarantor_last_nameRequired" disabled={!localState.guarantor_last_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_guarantorfullnameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.guarantor_full_nameRequired} name="guarantor_full_nameRequired" disabled={!localState.guarantor_full_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_commentRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.commentRequired} name="commentRequired" disabled={!localState.comment} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_accounttypeRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.account_typeRequired} name="account_typeRequired" disabled={!localState.account_type} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_otherRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.otherRequired} name="otherRequired" disabled={!localState.other} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_contact_nameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.contact_nameRequired} name="contact_nameRequired" disabled={!localState.contact_name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_service_dateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.service_dateRequired} name="service_dateRequired" disabled={!localState.service_date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_locationRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.locationRequired} name="locationRequired" disabled={!localState.location} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_Full_NameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Full_NameRequired} name="codebtor_Full_NameRequired" disabled={!localState.codebtor_Full_Name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_Street_Address1Required"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Street_Address1Required} name="codebtor_Street_Address1Required" disabled={!localState.codebtor_Street_Address1} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_Street_Address2Required"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Street_Address2Required} name="codebtor_Street_Address2Required" disabled={!localState.codebtor_Street_Address2} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_CityRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_CityRequired} name="codebtor_CityRequired" disabled={!localState.codebtor_City} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_StateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_StateRequired} name="codebtor_StateRequired" disabled={!localState.codebtor_State} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_ZipRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_ZipRequired} name="codebtor_ZipRequired" disabled={!localState.codebtor_Zip} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_BirthDateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_BirthDateRequired} name="codebtor_BirthDateRequired" disabled={!localState.codebtor_BirthDate} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_Tax_IDRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.codebtor_Tax_IDRequired} name="codebtor_Tax_IDRequired" disabled={!localState.codebtor_Tax_ID} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_First_NameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.coDebtor_First_NameRequired} name="coDebtor_First_NameRequired" disabled={!localState.coDebtor_First_Name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_Last_NameRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.coDebtor_Last_NameRequired} name="coDebtor_Last_NameRequired" disabled={!localState.coDebtor_Last_Name} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_Phone1Required"
                      control={<Checkbox onChange={handleChecked} checked={localState.coDebtor_Phone1Required} name="coDebtor_Phone1Required" disabled={!localState.coDebtor_Phone1} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_codebtor_Phone2Required"
                      control={<Checkbox onChange={handleChecked} checked={localState.coDebtor_Phone2Required} name="coDebtor_Phone2Required" disabled={!localState.coDebtor_Phone2} />}
                      labelPlacement="start"
                      label=""
                    />
                     <FormControlLabel
                      id="ET_itemization_DateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.itemization_DateRequired} name="itemization_DateRequired" disabled={!localState.itemization_Date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_balance_owed_on_itemizationRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.balance_owed_on_itemizationRequired} name="balance_owed_on_itemizationRequired" disabled={!localState.balance_owed_on_itemization} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_fees_added_since_itemizationRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.fees_added_since_itemizationRequired} name="fees_added_since_itemizationRequired" disabled={!localState.fees_added_since_itemization} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_interest_added_since_itemizationRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.interest_added_since_itemizationRequired} name="interest_added_since_itemizationRequired" disabled={!localState.interest_added_since_itemization} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_payments_Applied_Since_Itemization_DateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.payments_Applied_Since_Itemization_DateRequired} name="payments_Applied_Since_Itemization_DateRequired" disabled={!localState.payments_Applied_Since_Itemization_Date} />}
                      labelPlacement="start"
                      label=""
                    />
                    <FormControlLabel
                      id="ET_credits_Applied_Since_Itemization_DateRequired"
                      control={<Checkbox onChange={handleChecked} checked={localState.credits_Applied_Since_Itemization_DateRequired} name="credits_Applied_Since_Itemization_DateRequired" disabled={!localState.credits_Applied_Since_Itemization_Date} />}
                      labelPlacement="start"
                      label=""
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="ET_Update_btn" startIcon={<DoneAllIcon />} className={classes.updatebutton} variant="contained" autoFocus onClick={handleSave} disabled={isValid} color="primary">
              Update Template
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={isCreate} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={UpdateMessage}>
        <Alert onClose={UpdateMessage} severity="success" style={{ fontSize: 16 }}>
          Template Updated Successfully.
        </Alert>
      </Snackbar>
    </React.Fragment >
  );

}

export default EditTemplate
