import React, { useEffect, useState } from 'react'
import {
  Backdrop, Box, Button, createStyles, Grid, IconButton, makeStyles, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField, Theme, withStyles
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { IPlacementTemplate } from '../../../models/Placements/IPlacementTemplate';
import { ITemplateForClient } from '../../../models/Placements/ITemplateForClient';
import * as _ from 'lodash';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { IReportFieldsValues } from '../../../models/Reports/IReportFieldsValues';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PuffLoader from "react-spinners/PuffLoader";
import CurrencyInput from 'react-currency-input-field';

const useStyles = makeStyles((theme) => ({
  tableLayout: {
    tableLayout: 'auto'
  },
  searchBox: {
    marginTop: "3%",
    borderRadius: 20
  },
  container: {
    maxWidth: "98vw",
    maxHeight: '100vh',
    minHeight: 680,
    marginTop: '0.5cm',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    display: 'fixed',
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    //overflowY: 'hidden'
  },
  formControl: {
    minWidth: 280,
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  actions: {
    width: "100%",
    height: '5.5vh',
    marginRight: theme.spacing(1)
  },
  buttons: {
    borderRadius: 20,
    fontSize: 15,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "0.4cm",
    float: 'right'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
    border: '1px solid #364F6B',
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  error: {
    color: 'red',
  },
  progressBar: {
    height: '6px'
  },
  inputField: {
    height: '40px',
    fontSize: 17,
    borderRadius: '4px',
    transition: '0.3s',
    marginRight: "20px",
    width: "80px",
    marginTop: "13px"
  },
  div: {
    flexGrow: 1,
    height: '10vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  errorColor:
  {
    color: 'red',
    fontSize: '18px'
  },
  primaryerrorColor:
  {
    color: 'green'
  },
  title: {
    alignContent: "center",
    textAlign: 'center',
    color: 'blue',
    justifyItems: 'center',
    marginTop: '0.2cm',
    float: 'right'
  },
  buttonbox: {
    float: 'right'
  },
  tableHead: {
    height: '4vh',
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
  },
  tableHead1: {
    height: '4vh'
  },
  tableRow: {
    border: '1px solid #364F6B',
    alignContent: "center",
    justifyItems: 'center',
    padding: '10px'
  },
  tableRow1: {
    color: "white",
    fontSize: "130%",
    maxWidth: "1%",
    textAlign: 'center',
    whiteSpace: "nowrap",
    height: '4vh'
  },
  tablebody: {
    maxHeight: 535,
    display: 'flex',
    marginBottom: '0.6cm',
    backgroundColor: 'whitesmoke',
  },
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#364F6B',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      width: 600
    },
  }),
)(TableCell);

function Fieldnames(props) {
  let history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  const { state, dispatch } = useGlobalState();
  const { register, handleSubmit, errors } = useForm();

  const [placementAccountList, setPlacementAccountList] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountList ? props.location?.state?.placementAccountList : []);
  const [tablerows, setTableRows] = useState<number[]>(props.location?.state?.tablerows ? props.location?.state?.tablerows : [1]);
  const [templateList, setTemplateList] = useState<ITemplateField[]>([]);

  let phaseNumber: number = props.location.state.phaseNumber;
  let clientCode: string = props.location.state.clientCode;
  let templateId: number = props.location.state.templateId;
  let multiCode: boolean = props.location.state.multiCode;

  const [secondaryStateValidation, setSecondaryStateValidation] = useState(false);
  const [codebtorStateValidation, setCodebtorStateValidation] = useState(false);
  const [secondaryZipValidation, setSecondaryZipValidation] = useState(false);
  const [secondaryPhoneMessage, setSecondaryPhoneMessage] = useState(false);
  const [totalBalanceMessage, setTotalBalanceMessage] = useState(false);
  const [AccountNoValMessage, setAccountValMessage] = useState(false);
  const [firstNameMessage, setFirstNameMessage] = useState(false);
  const [lastNameMessage, setLastNameMessage] = useState(false);
  const [FullNameMessage, setFullNameMessage] = useState(false);
  const [validateFields, setValidateFields] = useState(false);
  const [DateDelMessage, setDateDelMessage] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState<any>(1);;
  const [stateMessage, setStateMessage] = useState(false);
  const [phoneMessage, setPhoneMessage] = useState(false);
  const [cityMessage, setCityMessage] = useState(false);
  const [feesMessage, setFeesMessage] = useState(false);
  const [zipMessage, setZipMessage] = useState(false);
  const [disableVal, setDisableval] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [GetStates, setGetStates] = useState([]);
  const [addRows, setAddRows] = useState(false);
  const [valid, setValid] = useState(false);

  const handleFormSubmit = (e) => {
    if (validateMandatoryFields()) {
      history.push('\previewdata', {
        clientCode: clientCode, tablerows: tablerows, phaseNumber: phaseNumber, placementAccountList: placementAccountList,
        templateList: templateList, multiCode: multiCode, templateId: templateId
      });
    } else {
      alert("Please provide all the necessary data.");
    }
  };

  function validateMandatoryFields(): boolean {
    let isMandatoryFieldsPresent: boolean = true;
    placementAccountList.forEach(x => {
      if (!x.account_no && (((!x.first_name || !x.last_name) || !x.full_name)) || !x.address1 && !x.city && !x.state && !x.zip && !x.delinquency_date && !x.total_balance) {
        isMandatoryFieldsPresent = false;
      }
    });
    return isMandatoryFieldsPresent;
  }

  const addMultipleRows = () => {
    let rows = _.cloneDeep(tablerows);
    let paList = _.cloneDeep(placementAccountList);
    for (let i = 0; i < numberOfRows; i++) {
      rows.push((rows.length + 1));
      let placementAccount: IPlacementAccount = getEmptyPlacementAccount();
      paList.push(placementAccount);
    }
    setPlacementAccountList(paList);
    setTableRows(rows);
  }

  const handleDeleteFilter = (r: any) => {
    if (tablerows.length == 1)
      return;
    let paList = _.cloneDeep(placementAccountList);
    let modifiedPAList = paList.filter((ele, index) => index != r - 1);
    setPlacementAccountList(modifiedPAList);
    let tr = _.cloneDeep(tablerows);
    let trModified = tr.filter((ele, index) => index != r - 1);
    trModified.forEach((f, indexnum) => {
      trModified[indexnum] = indexnum + 1
    });
    setTableRows(trModified);
  }

  const getTextFieldType = (datatype: string) => {
    switch (datatype) {
      case 'varchar': return 'text'
      case 'money': return 'number'
      case 'date': return 'date'
    }
  }

  const handleAddRows = (value: string): void => {
    setNumberOfRows(value === '' ? '' : Number(value) || '');
  };

  useEffect(() => {
    (async () => {
      setAddRows(numberOfRows == undefined ? true : true);
    })()
  }, [numberOfRows])

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await useFetch<IReportFieldsValues>("ReportOperatorsFieldsValues");
        setGetStates(response.data.states.map(state => { return { "id": state.id, } }));
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }, [])

  async function handleChange(field_Name, index, event) {
    event.preventDefault();
    setPlacementAccountListOnFormDataChange(field_Name, index, event.target.value);
  }

  const setPlacementAccountListOnFormDataChange = (field_Name, index, value) => {
    let accountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
    eval('accountList[' + index + '].' + field_Name + ' = value');
    setPlacementAccountList(accountList);
    setValid(true);
  }

  useEffect(() => {
    placementAccountList.forEach(x => {
      if (x.first_name?.match(/^[a-zA-Z].+$/)) {
        setFirstNameMessage(false);
      } else if (x?.first_name === null) {
        setFirstNameMessage(false);
      } else {
        setFirstNameMessage(true);
        return;
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      if (x.last_name?.match(/^[a-zA-Z].+$/)) {
        setLastNameMessage(false);
      } else if (x?.last_name === null) {
        setLastNameMessage(false);
      } else {
        setLastNameMessage(true);
        return;
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      if (x.full_name?.match(/^[a-zA-Z].+$/)) {
        setFullNameMessage(false);
      } else if (x?.full_name === null) {
        setFullNameMessage(false);
      } else {
        setFullNameMessage(true);
        return;
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      if (x.city?.match(/^[a-zA-Z -]+$/)) {
        setCityMessage(false);
      } else if (x?.city === null) {
        setCityMessage(false);
      } else {
        setCityMessage(true);
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let phoneNumber = x?.phone_no;
      if (phoneNumber == null || phoneNumber == "") {
        setPhoneMessage(false);
      } else if (phoneNumber != null || phoneNumber != "") {
        if (phoneNumber?.match(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/) === null) {
          setPhoneMessage(true);
        } else {
          setPhoneMessage(false);
        }
      }
      return;
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let zipCode = x?.zip;
      if (zipCode == null || zipCode == "") {
        setZipMessage(false);
      } else if (zipCode != null || zipCode != "") {
        if (zipCode?.match(/^\d{5}[-\s]?(?:\d{4})?$/) === null) {
          setZipMessage(true);
        } else {
          setZipMessage(false);
        }
      }
      return;
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let zipCode = x?.secondary_zip;
      if (zipCode == null || zipCode == "") {
        setSecondaryZipValidation(false);
      } else if (zipCode != null || zipCode != "") {
        if (zipCode?.match(/^\d{5}[-\s]?(?:\d{4})?$/) === null) {
          setSecondaryZipValidation(true);
        } else {
          setSecondaryZipValidation(false);
        }
      }
      return;
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let zipCode = x?.codebtor_Zip;
      if (zipCode == null || zipCode == "") {
        setSecondaryZipValidation(false);
      } else if (zipCode != null || zipCode != "") {
        if (zipCode?.match(/^\d{5}[-\s]?(?:\d{4})?$/) === null) {
          setSecondaryZipValidation(true);
        } else {
          setSecondaryZipValidation(false);
        }
      }
      return;
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    let dateTime = new Date(Date.now());
    let CurrentDate = moment(dateTime).format('yyyy-MM-DD');
    placementAccountList.forEach(x => {
      if (x?.delinquency_date?.toString() > CurrentDate) {
        setDateDelMessage(true);
      } else {
        setDateDelMessage(false);
        return;
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      if (x?.total_balance === null || x?.total_balance.toString() === "") {
        setTotalBalanceMessage(false);
      } else if (x?.total_balance > 0 && x?.total_balance < 9999999999999.99) {
        setTotalBalanceMessage(false);
      } else {
        setTotalBalanceMessage(true);
        return;
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let state = x?.state;
      if (GetStates.find(x => x?.id == state || x?.id.toLowerCase() == state)) {
        setStateMessage(false);
      } else if (state === null || state === "") {
        setStateMessage(false);
      } else {
        setStateMessage(true);
        return;
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let state = x?.secondary_state;
      if (GetStates.find(x => x?.id == state || x?.id.toLowerCase() == state)) {
        setSecondaryStateValidation(false);
      } else if (state === null || state === "") {
        setSecondaryStateValidation(false);
      } else {
        setSecondaryStateValidation(true);
        return;
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let state = x?.codebtor_State;
      if (GetStates.find(x => x?.id == state || x?.id.toLowerCase() == state)) {
        setCodebtorStateValidation(false);
      } else if (state === null || state === "") {
        setCodebtorStateValidation(false);
      } else {
        setCodebtorStateValidation(true);
        return;
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let phoneNumber = x?.secondary_phone_no;
      if (phoneNumber == null || phoneNumber == "") {
        setSecondaryPhoneMessage(false);
      } else if (phoneNumber != null || phoneNumber != "") {
        if (phoneNumber?.match(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/) === null) {
          setSecondaryPhoneMessage(true);
        } else {
          setSecondaryPhoneMessage(false);
        }
      }
    });
  }, [placementAccountList, isSubmit, valid])


  useEffect(() => {
    placementAccountList.forEach(x => {
      let phoneNumber = x?.coDebtor_Phone1;
      if (phoneNumber == null || phoneNumber == "") {
        setSecondaryPhoneMessage(false);
      } else if (phoneNumber != null || phoneNumber != "") {
        if (phoneNumber?.match(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/) === null) {
          setSecondaryPhoneMessage(true);
        } else {
          setSecondaryPhoneMessage(false);
        }
      }
    });
  }, [placementAccountList, isSubmit, valid])

  useEffect(() => {
    placementAccountList.forEach(x => {
      let phoneNumber = x?.coDebtor_Phone2;
      if (phoneNumber == null || phoneNumber == "") {
        setSecondaryPhoneMessage(false);
      } else if (phoneNumber != null || phoneNumber != "") {
        if (phoneNumber?.match(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/) === null) {
          setSecondaryPhoneMessage(true);
        } else {
          setSecondaryPhoneMessage(false);
        }
      }
    });
  }, [placementAccountList, isSubmit, valid])

  const getEmptyPlacementAccount = (): IPlacementAccount => {
    let placementAccount: IPlacementAccount = {
      phaseNumber: null,
      phase: null,
      client_code: null,
      account_no: null,
      first_name: null,
      last_name: null,
      full_name: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      phone_no: null,
      total_balance: null,
      delinquency_date: null,
      secondary_address1: null,
      secondary_address2: null,
      citysecondary: null,
      secondary_state: null,
      secondary_zip: null,
      secondary_phone_no: null,
      email: null,
      drivers_license: null,
      tax_id: null,
      birth_date: null,
      past_due_balance: null,
      current_balance: null,
      equipment_charges: null,
      contract_charges: null,
      fees: null,
      last_payment_date: null,
      guarantor_first_name: null,
      guarantor_last_name: null,
      guarantor_full_name: null,
      comment: null,
      account_type: null,
      other: null,
      contact_name: null,
      service_date: null,
      location: null,
      codebtor_Full_Name: null,
      codebtor_Street_Address1: null,
      codebtor_Street_Address2: null,
      codebtor_City: null,
      codebtor_State: null,
      codebtor_Zip: null,
      codebtor_BirthDate: null,
      codebtor_Tax_ID: null,
      coDebtor_First_Name: null,
      coDebtor_Last_Name: null,
      coDebtor_Phone1: null,
      coDebtor_Phone2: null,
      Payments_Applied_Since_Itemization_Date: null,
      Credits_Applied_Since_Itemization_Date: null,
      Itemization_Date: null,
      balance_owed_on_itemization: null,
      fees_added_since_itemization: null,
      interest_added_since_itemization: null
    }
    return placementAccount;
  }

  useEffect(() => {
    if (placementAccountList.length == 0) {
      let list: IPlacementAccount[] = [];
      let placementAccount: IPlacementAccount = getEmptyPlacementAccount();
      list.push(placementAccount);
      setPlacementAccountList(list);
    }
  }, [placementAccountList])

  useEffect(() => {
    if (templateList.length <= 0) {
      LoadTemplateList();
    }
  }, [templateList])

  async function LoadTemplateList() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const templateFieldResponse = await useFetch<ITemplateField[]>(`Placements/TemplateExclude?clientCode=${clientCode === "-2" ? null : clientCode}`);
      const clientTemplateResponse = await useFetch<ITemplateForClient[]>(`ClientPlacement/TemplateClient?client_code=${clientCode}`);
      const placementTemplateResponse = await useFetch<IPlacementTemplate[]>(`ClientPlacement/TemplateGet?placement_template_id=${multiCode ? templateId : clientTemplateResponse.data[0].template_id}`);
      if (placementTemplateResponse.data.length === 0) {
        const placementTemplateResponse = await useFetch<IPlacementTemplate[]>(`ClientPlacement/TemplateGet?placement_template_id=1`);
        setTemplateFields(templateFieldResponse.data, placementTemplateResponse.data[0]);
      } else {
        setTemplateFields(templateFieldResponse.data, placementTemplateResponse.data[0]);
        const field_name = placementTemplateResponse.data[0];
        if ((field_name.account_no === true && field_name.account_noRequired === true) && (((field_name.first_name === true && field_name.first_nameRequired === true) || (field_name.last_name === true)) || field_name.full_name) && (field_name.address1 === true && field_name.address1Required === true) && (field_name.city === true && field_name.cityRequired === true) && (field_name.state === true && field_name.stateRequired === true) && (field_name.zip === true && field_name.zipRequired) && (field_name.total_balance === true && field_name.total_balanceRequired === true) && (field_name.delinquency_date === true && field_name.delinquency_dateRequired === true)) {
          setValidateFields(false);
        } else {
          setValidateFields(true);
        }
      }
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  function setTemplateFields(templateFieldsList: ITemplateField[], placeTemplate: IPlacementTemplate) {
    let templateFields: ITemplateField[] = [];
    templateFields = _.cloneDeep(templateFieldsList);
    templateFields.forEach(x => {
      eval('if(placeTemplate.' + x.field_name + ' == true) { x.is_visible = true }');
      eval('if(placeTemplate.' + x.field_name + 'Required == true) { x.is_required = true }');
      if (x.field_name == 'state') { x.field_length = 2; }
      if (x.field_name == 'zip') { x.field_length = 10; }
      if (x.field_name == 'account_no') { x.field_length = 50; }
      if (x.field_name == 'first_name') { x.field_length = 100; }
      if (x.field_name == 'last_name') { x.field_length = 100; }
      if (x.field_name == 'city') { x.field_length = 100; }
      if (x.field_name == 'phone_no') { x.field_length = 15; }
      if (x.field_name == 'email') { x.field_length = 254; }
      if (x.field_name == 'total_balance') { x.field_length = 15; }
      if (x.field_name == "tax_id") { x.field_length = 10; }
      if (x.field_name == "codebtor_Tax_ID") { x.field_length = 10; }
      if (x.field_name == 'secondary_state') { x.field_length = 2; }
      if (x.field_name == 'secondary_zip') { x.field_length = 10; }
      if (x.field_name == 'codebtor_State') { x.field_length = 2; }
      if (x.field_name == 'codebtor_Zip') { x.field_length = 10; }
    })
    setTemplateList(templateFields);
  }

  useEffect(() => {
    if (!AccountNoValMessage && !cityMessage && !stateMessage && !zipMessage && !DateDelMessage
      && ((!firstNameMessage && !lastNameMessage) || !FullNameMessage)) {
      setDisableval(false);
    } else {
      setDisableval(true);
    }
  }, [placementAccountList])

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container justify="flex-start" alignContent="flex-start" alignItems="center" spacing={1}>
          <CssBaseline />
          <Container fixed className={classes.container} component="div" >
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Box p={1} flexShrink={0} bgcolor="white" >
                  <Typography variant="h4" gutterBottom className={classes.title} >
                    <b>2.INPUT DATA </b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box p={1} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="FN_Next_btn" endIcon={<ArrowForwardIosIcon />} className={classes.buttons}
                    variant="contained" type='submit' color="primary" onClick={handleSubmit(handleFormSubmit)}
                    disabled={validateFields || disableVal || phoneMessage || totalBalanceMessage || feesMessage || DateDelMessage || zipMessage || stateMessage} >
                    Next
                  </Button>
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id-="FN_Back_btn" startIcon={<ArrowBackIosIcon />} className={classes.buttons} variant="contained" color="primary" component={Link} to="/placement"> Back </Button>
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <CurrencyInput
                    maxLength={2}
                    value={numberOfRows}
                    className={classes.inputField}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    onValueChange={handleAddRows}
                    style={{ textAlign: 'center' }}
                  />
                  <Button id="FN_AddRows" startIcon={<QueuePlayNextIcon />} className={classes.buttons}
                    variant="contained" disabled={!addRows} color="primary" onClick={addMultipleRows}>
                    Add Rows
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <div className={classes.actions}>
              <Box display="flex" p={1} bgcolor="white">
                <Box p={1} width="100%" bgcolor="white">
                  {validateFields ?
                    <Typography variant="subtitle1" className={classes.primaryerrorColor}>
                      <b>Kindly check all the required fields AccountNo, (FirstName, LastName) or (FullName), StreetAddress1, City, State, <br />
                        Zip, Total Balance, Date of Delinquency, Phone Number</b>
                    </Typography>
                    : null}
                  {stateMessage ? <Typography variant="subtitle1" className={classes.errorColor}>Please specify a valid 2-character state code. </Typography> : ""}
                  {zipMessage ? <Typography variant="subtitle1" className={classes.errorColor}>Zip code must be either 5 or 9 digits. </Typography> : ""}
                  {DateDelMessage ? <Typography variant="subtitle1" className={classes.errorColor}>Date of Delinquency format must be "MM/DD/YYYY".</Typography> : ""}
                  {phoneMessage ? <Typography variant="subtitle1" className={classes.errorColor}>Phone number must be 10 or 12 digits only.</Typography> : ""}
                  {totalBalanceMessage ? <Typography variant="subtitle1" className={classes.errorColor}>Please Enter valid Total Balance.</Typography> : ""}
                  {feesMessage ? <Typography variant="subtitle1" className={classes.errorColor}>Please Enter valid Fees.</Typography> : ""}
                </Box>
              </Box>
            </div>
            <Paper>
              <TableContainer component={Paper} className={classes.tablebody}>
                {templateList.length > 0 ?
                  <Table stickyHeader aria-label="collapsible table" size="medium" >
                    <TableHead className={classes.tableHead}>
                      <TableRow key='table-header' className={classes.tableHead1}>
                        {templateList.map((row) =>
                        (
                          <StyledTableCell className={classes.tableRow1} key={row.display_name + 'header'} >
                            {row.display_name.trim()}
                          </StyledTableCell>
                        )
                        )}
                        <StyledTableCell className={classes.tableRow1}>
                          Delete
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tablerows.map((r) =>
                      (
                        <TableRow key={r} className={classes.tableRow}>
                          {templateList.map((row) =>
                          (
                            <StyledTableCell key={row.display_name + r} className={classes.tableRow}>
                              {row.data_type == 'date' ?
                                <TextField id={row.field_name + r} placeholder={row.display_name.replace('(*)', '')} variant="outlined"
                                  name={row.field_name + r} type={getTextFieldType(row.data_type)}
                                  className={classes.textField} InputLabelProps={{ shrink: true }}
                                  value={eval('placementAccountList[' + (r - 1) + ']?.' + row.field_name)}
                                  onChange={(e) => handleChange(row?.field_name, (r - 1), e)}
                                  inputProps={{ maxlength: row?.field_length, max: "9999-12-31" }}
                                />
                                :
                                <TextField id={row.field_name + r} placeholder={row.display_name.replace('(*)', '')} variant="outlined"
                                  name={row.field_name + r} type={getTextFieldType(row.data_type)}
                                  className={classes.textField} InputLabelProps={{ shrink: true }}
                                  value={eval('placementAccountList[' + (r - 1) + ']?.' + row.field_name)}
                                  onChange={(e) => handleChange(row?.field_name, (r - 1), e)}
                                  inputProps={{ maxlength: row?.field_length }}
                                />
                              }
                              {eval('errors.' + row.field_name + r) &&
                                <span className={classes.error}>
                                  {eval('errors.' + row.field_name + r + '.message')}
                                </span>
                              }
                            </StyledTableCell>
                          )
                          )}
                          <StyledTableCell className={classes.tableRow}>
                            <IconButton id="FR_DeleteIcon" aria-label="delete"
                              onClick={() => handleDeleteFilter(r)} style={{ marginLeft: "10px", marginTop: "12px" }} >
                              <HighlightOffIcon fontSize="large" style={{ color: "red" }} />
                            </IconButton>
                          </StyledTableCell>
                        </TableRow>
                      )
                      )}
                    </TableBody>
                  </Table> :
                  <Backdrop className={classes.backdrop} open={true}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                  </Backdrop>}
              </TableContainer>
            </Paper>
          </Container>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Fieldnames)
