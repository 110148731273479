import React, { useEffect, useState } from "react";
import {
  Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TableSortLabel, Typography,
} from '@material-ui/core';
import { createStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Template from "../../components/GlobalAdmin/ClientPlacementOptions/Template";
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { GlobalStateAction, useGlobalState } from "../../store/GlobalStore";
import { IClientTemplate } from "../../models/Admin/ClientPlacements/IClientTemplate";
import { ITemplateList } from "../../models/Admin/ClientPlacements/ITemplateList";
import { useFetch, usePost } from "../../utils/apiHelper";
import Backdrop from '@material-ui/core/Backdrop';
import PuffLoader from "react-spinners/PuffLoader";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Footer from '../../components/Footer/Footer';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import managetemplate from '../../images/template.svg';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch'
      },
    },
    table: {
      minWidth: 700
    },
    formControl: {
      minwidth: 300,
      position: "absolute",
    },
    h2: {
      marginLeft: '1%',
      marginTop: '55px'
    },
    snackbar: {
      color: 'black',
      marginTop: '-0.5cm'
    },
    snackbarfont: {
      fontSize: 16
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    searchBox: {
      borderRadius: 30,
      border: '2px black solid',
      width: '100%',
      marginLeft: '1.4cm',
      height: '43px',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      fontSize: 16,
      textIndent: '10px'
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    TextHighlight: {
      color: 'black',
      fontWeight: 'bold',
      background: "yellow",
    },
    TextNormal: {
      color: 'black',
      fontSize: 16
    },
    tablebody: {
      maxHeight: 630
    },
    searchbar: {
      marginTop: "-1.4cm",
      borderRadius: 50,
      background: "smokewhite",
      width: "58vw",
      marginLeft: "80%",
      border: '2px black solid'
    },
    GridTopAlign: {
      marginTop: '7px'
    }
  })
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const headCells = [
    { id: "clT_CODE", disablePadding: false, label: "CLIENT ID", sortable: true },
    { id: "clT_NAME_1", disablePadding: false, label: "NAME", sortable: true },
    { id: "TEMPLATE", disablePadding: false, label: "TEMPLATE", sortable: false },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id} id="CPO_TableHeader"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ background: "#007FFF", color: "white", fontSize: "120%" }}
          >
            <TableSortLabel
              id="CPO_TableSortlable"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ClientPlacementOptions() {
  const [clientTemplateList, setClientList] = useState<IClientTemplate[]>([]);
  const [templateList, setTemplateList] = useState<ITemplateList[]>([]);
  const { state, dispatch } = useGlobalState();
  const classes = useRowStyles();
  const [filteredClientTemplateList, SetFilteredClientTemplateList] = useState<IClientTemplate[]>([]);
  const [pagenumber, SetPageNumber] = useState<number>(1);
  const [totalPages, SetTotalPages] = useState<number>(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(" ");
  const [searchText, setSearchText] = useState<string>('');
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (clientTemplateList.length <= 0) {
      LoadClientCodeList();
    }
  }, [clientTemplateList]);

  useEffect(() => {
    if (templateList.length <= 0) {
      LoadTemplateList();
    }
  }, [templateList]);

  async function LoadTemplateList() {
    dispatch({ type: GlobalStateAction.Busy });
    try {
      const response = await useFetch<ITemplateList[]>("ClientPlacement/GetAllTemplate");
      setshowProgressBar(true);
      setTemplateList(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
      setshowProgressBar(false);
    }
  }

  const filterClientTemplateList = (list = clientTemplateList, startindex = 1, endIndex = 10, codeFilter = null, nameFilter = null) => {
    if (!codeFilter && !nameFilter) {
      SetFilteredClientTemplateList(list.slice(startindex - 1, endIndex - 1));
    }
  }

  async function LoadClientCodeList() {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    try {
      const response = await useFetch<IClientTemplate[]>("ClientPlacement/ClientTemplateList");
      const clientTemplates = response.data;
      setClientList(clientTemplates);
      SetTotalPages(clientTemplates.length % 10 == 0 ? clientTemplates.length / 10 : (Math.floor(clientTemplates.length / 10)) + 1);
      filterClientTemplateList(clientTemplates, (pagenumber - 1) * 10 + 1, (pagenumber) * 10);
      setshowProgressBar(false);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  async function handleTemplateSelection(templateId: number, clientCode: string) {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    try {
      const response = await usePost(`ClientPlacement/TemplateUpdate?client_code=${clientCode}&template_id=${templateId}`, null);
      LoadClientCodeList();
      setOpen(true);
      if (response.status !== 202) {
        dispatch({ type: GlobalStateAction.Error, error: response.statusText });
      }
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  const handleTriggered = () => {
    setPage(0);
    getClientCodeListAsync();
  }

  async function getClientCodeListAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    try {
      const response = await useFetch<IClientTemplate[]>(`ClientPlacement/ClientCodeList?query=${searchText}&last=${rowsPerPage}`);
      const clientTemplates = response.data;
      setClientList(clientTemplates);
      SetTotalPages(clientTemplates.length % 10 == 0 ? clientTemplates.length / 10 : (Math.floor(clientTemplates.length / 10)) + 1)
      filterClientTemplateList(clientTemplates, (pagenumber - 1) * 10 + 1, (pagenumber) * 10);
      setshowProgressBar(false);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });

    }
  }

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }),
  )(TableCell);

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl" component={Paper} >
        <div style={{ flexGrow: 1 }}>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={100} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <Grid container className={classes.GridTopAlign}>
            <Grid item xs={3}>
              <Typography variant="h4" gutterBottom style={{ color: "blue", textAlign: 'left' }}>
                <b> CLIENT PLACEMENT OPTIONS </b>
              </Typography>
            </Grid>
            <Grid xs={7} item>
              <input value={searchText}
                type='text' placeholder="Search Client ID and Client Name"
                className={classes.searchBox}
                onChange={e => setSearchText(e.target.value)}
                onKeyPress={event => { if (event.key === 'Enter') { handleTriggered(); } }}
                maxLength={255}
              />
            </Grid>
            <Grid item xs={2}>
              <Button id="CPO_EditTemp_Button" startIcon={<img src={managetemplate} width="23px" height="23px" />} variant="contained" color="primary" component={Link} to="/GA_edittemplate" style={{ borderRadius: 20, float: 'right' }}>
                Manage Templates
              </Button>
            </Grid>
          </Grid>
          <Paper>
            <TableContainer component={Paper} className={` ${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table aria-label="customized table" size="small" stickyHeader className={` ${classes.table}`}>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(clientTemplateList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                    (row, index) => {
                      const key = row.clT_CODE + row.clT_NAME_1
                      return (
                        <StyledTableRow key={key} hover>
                          <StyledTableCell component="th" scope="row">
                            <span className={`${searchText.toUpperCase() === "" ? classes.TextNormal : row.clT_CODE.includes(searchText.toUpperCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.clT_CODE}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <span className={`${searchText === "" ? classes.TextNormal : row.clT_NAME_1.includes(searchText) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.clT_NAME_1}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Template clientCode={row.clT_CODE} selectedTemplateId={row.template_id}
                              templateList={templateList} onTemplateSelected={handleTemplateSelection} />
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              id="CPO_TablePagination"
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={clientTemplateList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          <Footer />
        </div>
        <Snackbar open={open} className={classes.snackbar} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className={classes.snackbarfont}>
            Template Updated Successfully!
          </Alert>
        </Snackbar>
      </Container>
    </React.Fragment>
  )
}
