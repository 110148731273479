import { Backdrop, Box, Grid, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import { Autocomplete } from "@material-ui/lab";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { AccessContext } from '../../../models/accesscontrol/AccessContext';
import { IUserInfo } from '../../../models/accesscontrol/IUserInfo';
import { IWidgetInfo } from '../../../models/accesscontrol/IWidgetInfo';
import { WidgetMap } from '../../../models/accesscontrol/WidgetMap';
import { IClientInfo } from '../../../models/client/IClientInfo';
import { IBlobContainerNames } from "../../../models/Files/IBlobContainerNames";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '24ch',
        marginTop: '20px'
      },
    },
    multiSelectRoot: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
      },
    },
    formControl: {
      minwidth: 300,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(2),
      color: theme.palette.info[500],
    },
    boxview: {
      display: 'block',
      width: '8.2cm',
      height: '5.1cm',
      marginLeft: '10px',
      marginTop: '-0.3cm',
      marginBottom: '-5px',
      borderRadius: '10px',
      borderColor: 'black',
      //border: '1px solid blue',
      '&:hover': {
        color: 'green',
        backgroundColor: 'white',
        //background: 'linear-gradient(45deg, #a8c0ff,#3f2b96) ',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',
      },
    },
    boxview1: {
      display: 'block',
      width: '8.5cm',
      height: '3.5cm',
      marginLeft: '0px',
      marginTop: '10px',
      borderRadius: '10px',
      borderColor: 'black',
      //border: '1px solid blue',
      '&:hover': {
        color: 'green',
        backgroundColor: 'white',
        //background: 'linear-gradient(45deg, #a8c0ff,#3f2b96) ',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',
      },
    },
    boxview2: {
      display: 'block',
      width: '8.5cm',
      height: '4cm',
      marginLeft: '0px',
      marginTop: '1.4cm',
      borderRadius: '10px',
      borderColor: 'black',
      '&:hover': {
        color: 'green',
        backgroundColor: 'white',
        //background: 'linear-gradient(45deg, #a8c0ff,#3f2b96) ',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',
      },
    },
    boxviewWidgets: {
      display: 'block',
      width: '10.5cm',
      height: '8cm',
      marginLeft: '-50px',
      borderRadius: '10px',
      borderColor: 'black',
      '&:hover': {
        backgroundColor: 'white',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',

      },
    },
    boxviewNotifications: {
      display: 'block',
      width: '10.5cm',
      height: '8cm',
      marginLeft: '-50px',
      borderRadius: '10px',
      borderColor: 'black',
      '&:hover': {
        backgroundColor: 'white',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    submitbtn: {
      borderRadius: 20,
      backgroundColor: 'green',
      "&:hover": {
        backgroundColor: 'green',
      }
    },
    cancelbtn: {
      borderRadius: 20,
      backgroundColor: 'red',
      "&:hover": {
        backgroundColor: 'red',
      }
    }
  })
);

const GA_EditUser: React.FC<{ userRow: IUserInfo, onSave: (userRow: IUserInfo) => void, onRemove: () => void, checkSuperAdmin: boolean }> = (props) => {
  const userRow = props.userRow;
  const { state, dispatch } = useGlobalState();
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  var newUser: AccessContext = new AccessContext();
  const [localState, setLocalState] = React.useState(newUser);
  const [confirmedClients, setConfirmedClients] = React.useState([]);
  const multiselectRef = useRef<Multiselect>();
  const [selectedClients, setSelectedClients] = React.useState([]);
  const [firstnameerror, setFirstNameError] = useState("");
  const [Lastnameerror, setLastNameError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [phone, setPhone] = React.useState(false);
  const [ListContainers, setListContainers] = useState<IBlobContainerNames[]>([]);
  const [ListPaymentContainers, setPaymentListContainers] = useState<IBlobContainerNames[]>([]);
  const [ListACKContainers, setACKListContainers] = useState<IBlobContainerNames[]>([]);
  const [showFilePermisson, setShowFilePermission] = useState(false);
  const [showAdfLogs, setShowAdfLogs] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [multiClientOption, setMultiClientOption] = useState(false);

  const handleClickOpen = () => {
    dispatch({ type: GlobalStateAction.Busy })
    setOpen(true);
    setShowProgressBar(true);
    useFetch<AccessContext>("User/GetUser?userId=" + userRow.id).then((r) => {
      var response = r.data;
      useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userRow.id).then((r) => {
        for (let index = 1; index <= WidgetMap.size; index++) {
          const value = WidgetMap.get(index);
          if (value && r.data.filter(w => w.widgetId === index).length === 1) {
            response[value] = true;
          }
        };
        setLocalState(response);
        setShowProgressBar(false);
        useFetch<IClientInfo[]>("Client/GetClientCodeAdmin?userId=" + state?.userAccessContext?.id).then((c) => {
          setConfirmedClients(c.data);
          setSelectedClients(c.data.filter(p => {
            return response.clientCodes?.filter(r => { return r === p.client_Code }).length > 0
          }));
          (localState.role == 0) ? setMultiClientOption(false) : setMultiClientOption(true)
          dispatch({ type: GlobalStateAction.Idle })
        })
      });
    })
  }

  const handleClose = () => {
    setOpen(false);
    dispatch({ type: GlobalStateAction.Idle })
  };

  const handleSave = () => {
    dispatch({ type: GlobalStateAction.Busy })
    setShowProgressBar(true);
    setOpen(false);
    let user = localState;
    user.clientCodes = multiselectRef.current.getSelectedItems().map(r => r.client_Code);
    if (multiselectRef.current?.getSelectedItems().map(r => r.client_Code).length <= 0) {
      alert(" Please select atleast one client code");
    } else {
      usePost("User/SaveUser", localState).then((r) => {
        if (r.status == 400) {
          dispatch({ type: GlobalStateAction.Error, error: r.statusText })
          dispatch({ type: GlobalStateAction.Idle })
          setShowProgressBar(false);
        }

        if (r.status == 200) {
          let selectedWidgets: number[] = [];
          for (let index = 1; index <= WidgetMap.size; index++) {
            var value = WidgetMap.get(index);
            if (value && localState[value]) {
              selectedWidgets.push(index);
            }
          }
          const data = {
            'userId': localState.id,
            'widgets': selectedWidgets.join(',')
          };

          usePost("User/AddWidgetsForClient", data).then((r) => {
            if (r.status == 400) {
              dispatch({ type: GlobalStateAction.Error, error: r.statusText })
              dispatch({ type: GlobalStateAction.Idle })
              setShowProgressBar(false);
            }
            if (r.status == 200) {
              props.onSave(localState);
              setShowProgressBar(false);
              dispatch({ type: GlobalStateAction.Idle })
              setSubmit(true);
            }
            dispatch({ type: GlobalStateAction.Idle })
          });
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let userRole = "";
    if (event.target.value == 3 || event.target.value == 2) {
      { event.target.value == 3 ? userRole = "Super Admin" : userRole = "Global Admin" }
      if (confirm('Are you sure you want to make this user as ' + userRole)) {
        setLocalState({
          ...localState,
          [name]: event.target.value,
        })
      } else {
        setLocalState({
          ...localState,
          [name]: userRow.global_admin,
        })
      }
    } else {
      setLocalState({
        ...localState,
        [name]: event.target.value,
      });
    }
  };
  useEffect(() => {
    (localState.role == 0 || localState.role == null) ? setMultiClientOption(false) : setMultiClientOption(true)
  }, [localState.role, multiClientOption])

  const handleChecked = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.checked,
    });
  };

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;
    if (FirstNameVal === "") {
      setFirstNameError("First Name is Required");
      setIsDisable(true);
    }
    else if (!FirstNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
      setFirstNameError("");
      setIsDisable(false);
    } else {
      setFirstNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;
    if (LastNameVal === "") {
      setLastNameError("Last Name is Required");
      setIsDisable(true);
    }
    else if (!LastNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
      setLastNameError("");
      setIsDisable(false);
    } else {
      setLastNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSubmit(false);
    setPhone(false);
  };

  const getDefaultContainer = () => {
    let defaultContainer: IBlobContainerNames = {
      id: -1,
      containerNames: 'None',
      containerType: 'Default'
    };
    return defaultContainer;
  }

  async function OnOpenCloudFolders() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers?ContainerType=CloudContainer");
      response.data.unshift(getDefaultContainer());
      setListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function OnOpenPaymentFolders() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers?ContainerType=PaymentContainer");
      response.data.unshift(getDefaultContainer());
      setPaymentListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function OnOpenAckFolders() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers?ContainerType=AckContainer");
      response.data.unshift(getDefaultContainer());
      setACKListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function onContainerSelect(event, value) {
    dispatch({ type: GlobalStateAction.Busy });
    try {
      await useFetch<IBlobContainerNames[]>("File/GetAllContainers?Id=" + value.id + "&ContainerType=CloudContainer")
      setLocalState({ ...localState, ftpFolder: value?.containerNames });
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function onPaymentFileSelect(event, value) {
    dispatch({ type: GlobalStateAction.Busy });
    try {
      await useFetch<IBlobContainerNames[]>("File/GetAllContainers?Id=" + value.id + "&ContainerType=PaymentContainer")
      setLocalState({ ...localState, paymentFileFolder: value?.containerNames });

    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function onAckFileSelect(event, value) {
    dispatch({ type: GlobalStateAction.Busy });
    try {
      await useFetch<IBlobContainerNames[]>("File/GetAllContainers?Id=" + value.id + "&ContainerType=AckContainer")
      setLocalState({ ...localState, ackFileFolder: value?.containerNames });
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  const handleChangeshowpermission = (event) => {
    setShowFilePermission(event.target.checked)
  }

  const handleChangeShowAdfLogs = (event) => {
    setShowAdfLogs(event.target.checked)
  }

  useEffect(() => {
    if (localState.ftpFolder === "None") {
      setLocalState({ ...localState, ftpFolder: null });
    } else if (localState.paymentFileFolder === "None") {
      setLocalState({ ...localState, paymentFileFolder: null });
    } else if (localState.ackFileFolder === "None") {
      setLocalState({ ...localState, ackFileFolder: null });
    }
  }, [localState])

  return (
    <React.Fragment>
      <div>
        <Button id="EU_Edit" startIcon={<BorderColorIcon />} variant="contained" color="primary" onClick={handleClickOpen} style={{ borderRadius: 20, backgroundColor: 'green' }} disabled={props.checkSuperAdmin && state.userAccessContext?.role === 2} >
          Edit
        </Button>
      </div>

      <Dialog
        PaperProps={{ style: { borderRadius: 15 } }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
            EDIT USER
          </Typography>
        </DialogTitleHeader>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogContent>
          <Grid container>
            <Grid item xs={3}>
              <form className={classes.root} noValidate autoComplete="off" >
                <TextField id="EU_firstname" value={localState.firstName} label="First Name" variant="outlined" name="firstName" onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }} helperText={firstnameerror} error={!!firstnameerror} inputProps={{ maxlength: 25 }} style={{ borderBottom: '3px solid blue', borderRadius: '10px' }} />
                <TextField id="EU_lastname" value={localState.lastName} label="Last Name" variant="outlined" name="lastName" onChange={(e) => { handleChange(e); HandleLastNameVal(e); }} helperText={Lastnameerror} error={!!Lastnameerror} inputProps={{ maxlength: 25 }} style={{ borderBottom: '3px solid blue', borderRadius: '10px' }} />
              </form>
              <form className={classes.root} noValidate autoComplete="off" >
                <TextField id="EU_Username" value={localState.userName} label="Email" disabled={true} variant="outlined" name="userName" style={{ borderBottom: '3px solid blue', borderRadius: '10px', minWidth: 410 }} />
              </form>
              <form className={classes.root} noValidate autoComplete="off" >
                <TextField id="EU_phone" value={localState.phone} disabled={true} label="Phone" type="number" variant="outlined" name="phone" onChange={(e) => { handleChange(e); }} style={{ borderBottom: '3px solid blue', borderRadius: '10px', minWidth: 410 }}
                  InputProps={{
                    endAdornment: (
                      <IconButton aria-label="delete" onClick={(e) => { setLocalState({ ...localState, phone: "" }); }}>
                        <CancelIcon />
                      </IconButton>
                    ),
                  }}
                />
              </form>
              <FormControl variant="filled" className={classes.multiSelectRoot} style={{ width: '410px' }}>
                <Multiselect id="clientCode"
                  ref={multiselectRef}
                  selectedValues={selectedClients}
                  options={confirmedClients}
                  closeOnSelect={false}
                  showArrow={true}
                  displayValue="client_Code"
                  placeholder="Select Client"
                  showCheckbox={true}
                />
              </FormControl>
              {state.userAccessContext?.role === 3 && localState.role == 3 ?
                <React.Fragment>
                  <DialogTitle id="responsive-dialog-title" >{"ADMIN PERMISSION"}</DialogTitle>
                  <FormControl component="fieldset" >
                    <Box className={classes.boxview1} style={{ marginTop: '-10px', marginBottom: '-50px' }}>
                      <FormGroup >
                        {state.userAccessContext?.role === 3 ?
                          <FormControlLabel style={{ marginTop: '-5px', marginLeft: '10px' }}
                            id="U_CU_IsGlobalSettings"
                            control={<Checkbox onChange={(e) => { handleChecked(e); handleChangeShowAdfLogs(e); }} name="isGlobalSettings" checked={localState.isGlobalSettings} />}
                            label="Global Settings"
                          /> :
                          null
                        }
                        {
                          showAdfLogs && state.userAccessContext?.role === 3 || localState.isGlobalSettings ?

                            <React.Fragment>
                              <FormControlLabel style={{ marginTop: '-10px', marginLeft: '35px' }}
                                id="U_CU_IsAdfLogs"
                                control={<Checkbox onChange={handleChecked} name="isAdfLogs" checked={localState.isAdfLogs} />}
                                label="ADF Logs"
                              />
                              <FormControlLabel style={{ marginLeft: '35px', marginTop: '-10px' }}
                                id="U_CU_IsAzureFunc"
                                control={<Checkbox onChange={handleChecked} name="isAzureFunc" checked={localState.isAzureFunc} />}
                                label="Azure Functions"
                              />
                              <FormControlLabel
                                id="U_CU_IsUserLoginStatus" style={{ marginTop: '-10px', marginLeft: '35px' }}
                                control={<Checkbox onChange={handleChecked} name="isUserLoginStatus" checked={localState.isUserLoginStatus} />}
                                label="User Login Status"
                              />
                            </React.Fragment>
                            :
                            null
                        }
                      </FormGroup>
                    </Box>
                  </FormControl>
                </React.Fragment>
                : null
              }
              {showFilePermisson || localState.isFileAccess === true ?
                <React.Fragment>
                  <Box className={classes.boxview2}>
                    <DialogTitle id="responsive-dialog-title">{"FILE PERMISSIONS"}</DialogTitle>

                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel style={{ marginLeft: '5px', marginTop: '-15px' }}
                          id="U_CU_UploadFile"
                          control={<Checkbox onChange={handleChecked} checked={localState.uploadfile} name="uploadfile" />}
                          label="Upload"
                        />
                        <FormControlLabel style={{ marginLeft: '5px', marginTop: '-10px' }}
                          id="U_CU_Download"
                          control={<Checkbox onChange={handleChecked} checked={localState.downloadfile} name="downloadfile" />}
                          label="Download"
                        />
                        <FormControlLabel style={{ marginLeft: '5px', marginTop: '-10px' }}
                          id="U_CU_Create"
                          control={<Checkbox onChange={handleChecked} checked={localState.createfolder} name="createfolder" />}
                          label="Create Folder"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel style={{ marginTop: '-15px' }}
                          id="U_CU_DeleteFile"
                          control={<Checkbox onChange={handleChecked} checked={localState.deletefile} name="deletefile" />}
                          label="Delete File"
                        />
                        {state.userAccessContext?.role === 3 ?
                          <FormControlLabel
                            id="U_CU_DeleteFolder"
                            control={<Checkbox onChange={handleChecked} checked={localState.deletefolder} name="deletefolder" />}
                            label="Delete Folder"
                          />
                          : null
                        }

                      </FormGroup>
                    </FormControl>
                  </Box>
                </React.Fragment>
                :
                null
              }
            </Grid>
            <Grid item xs={3}>
              <DialogTitle id="responsive-dialog-title">{"PERMISSIONS"}</DialogTitle>
              <FormControl component="fieldset" >
                <FormGroup >
                  <FormControlLabel style={{ marginTop: '-10px' }}
                    id="EU_paymentUpdates"
                    control={<Checkbox onChange={handleChecked} checked={localState.paymentUpdates} name="paymentUpdates" />}
                    label="Payment Updates"
                  />
                  <FormControlLabel
                    id="EU_closingAccounts"
                    control={<Checkbox onChange={handleChecked} checked={localState.closingAccounts} name="closingAccounts" />}
                    label="Close Accounts"
                  />
                  <FormControlLabel
                    id="EU_rollAccounts"
                    control={<Checkbox onChange={handleChecked} checked={localState.rollAccounts} name="rollAccounts" />}
                    label="Roll Accounts"
                  />
                  <FormControlLabel
                    id="EU_placeAccounts"
                    control={<Checkbox onChange={handleChecked} checked={localState.placeAccounts ? true : false} name="placeAccounts" />}
                    label="Place Accounts"
                  />
                  <FormControlLabel
                    id="EU_editReports"
                    control={<Checkbox onChange={handleChecked} checked={localState.editReports ? true : false} name="editReports" />}
                    label="Edit Reports"
                  />
                  <FormControlLabel
                    id="EU_createNewUsers"
                    control={<Checkbox onChange={handleChecked} checked={localState.createNewUsers} name="createNewUsers" />}
                    label="Create New Users"
                  />
                  <FormControlLabel
                    id="EU_uploadUnmappedFile"
                    control={<Checkbox onChange={handleChecked} checked={localState.uploadUnmappedFile} name="uploadUnmappedFile" />}
                    label="Upload Unmapped File"
                  />
                  <FormControlLabel
                    id="EU_sendEmailOnAttachements"
                    control={<Checkbox onChange={handleChecked} name="sendEmailOnAttachements" checked={localState.sendEmailOnAttachements} />}
                    label="Send Email on attachments"
                  />
                  <FormControlLabel
                    id="EU_SendEmailToAdmin"
                    control={<Checkbox onChange={handleChecked} name="sendEmailtoAdmin" checked={localState.sendEmailtoAdmin} />}
                    label="Send Email To Admin"
                  />
                  <FormControlLabel
                    id="EU_multiplePlacementAccess"
                    control={<Checkbox onChange={handleChecked} name="multiplePlacementAccess" checked={localState.multiplePlacementAccess} />}
                    label="Multiphase Placement Access"
                  />
                  <FormControlLabel
                    id="EU_multiClientCodePlacementAccess"
                    control={<Checkbox onChange={handleChecked} name="multiClientCodePlacementAccess" checked={!multiClientOption ? localState.multiClientCodePlacementAccess = false : localState.multiClientCodePlacementAccess} disabled={!multiClientOption} />}
                    label="Multi-Client Code Placement Access"
                  />
                  <FormControlLabel
                    id="EU_clientConfirmation"
                    control={<Checkbox onChange={handleChecked} name="clientConfirmation" checked={localState.clientConfirmation} />}
                    label="Client Confirmation"
                  />
                  <FormControlLabel
                    id="EU_FileAccess"
                    control={<Checkbox onChange={(e) => { handleChecked(e); handleChangeshowpermission(e) }} name="isFileAccess" checked={localState.isFileAccess} />}
                    label="Manage File Access"
                  />
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                    <Select native
                      id="EU_AdminLevel"
                      onChange={handleChange}
                      value={localState.role}
                      label="Template"
                      inputProps={{
                        name: 'role',
                        id: 'outlined-age-native-simple',
                      }}
                    >
                      <option value={0}>Regular User</option>
                      <option value={1}>Client Admin</option>
                      <option value={2}>Global Admin</option>
                      {state.userAccessContext?.role === 3 ?
                        <React.Fragment>
                          <option value={3}>Super Admin</option>
                          <option value={4}>File Admin</option>
                        </React.Fragment>
                        :
                        null
                      }
                    </Select>
                  </FormControl>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.boxviewNotifications}>
                <DialogTitle id="responsive-dialog-title">{"NOTIFICATIONS"}</DialogTitle>
                <FormControl component="fieldset" >
                  <FormGroup>
                    <FormControlLabel style={{ marginLeft: '5px', marginTop: '-20px' }}
                      id="EU_disputeValidationNotification"
                      control={<Checkbox onChange={handleChecked} name="disputeValidationNotification" checked={localState.disputeValidationNotification} />}
                      label="Dispute Validation Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_paymentVerificationNotification"
                      control={<Checkbox onChange={handleChecked} name="paymentVerificationNotification" checked={localState.paymentVerificationNotification} />}
                      label="Payment Verification Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_fileIssuesNotification"
                      control={<Checkbox onChange={handleChecked} name="fileIssuesNotification" checked={localState.fileIssuesNotification} />}
                      label="File Issues Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_backupDocumentRequestNotification"
                      control={<Checkbox onChange={handleChecked} name="backupDocumentRequestNotification" checked={localState.backupDocumentRequestNotification} />}
                      label="Backup Document Request Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_settlementRequestsNotification"
                      control={<Checkbox onChange={handleChecked} name="settlementRequestsNotification" checked={localState.settlementRequestsNotification} />}
                      label="Settlement Requests Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_generalNotification"
                      control={<Checkbox onChange={handleChecked} name="generalNotification" checked={localState.generalNotification} />}
                      label="General Notifications"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box className={classes.boxviewWidgets}>
                <DialogTitle id="responsive-dialog-title" >{"WIDGETS"}</DialogTitle>
                <FormControl component="fieldset" >
                  <FormGroup>
                    <FormControlLabel style={{ marginLeft: '5px', marginTop: '-20px' }}
                      id="EU_flatFeeAcRem"
                      control={<Checkbox onChange={handleChecked} name="flatFeeAcRem" checked={localState.flatFeeAcRem} />}
                      label="Flat Fee A/C Rem"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_acWaitingtoMove"
                      control={<Checkbox onChange={handleChecked} checked={localState.acWaitingtoMove} name="acWaitingtoMove" />}
                      label="A/C waiting to move"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_daysSincePlacmentofAc"
                      control={<Checkbox onChange={handleChecked} checked={localState.daysSincePlacmentofAc} name="daysSincePlacmentofAc" />}
                      label="Days Since Placement of A/C"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_placementHistoryofMonthAc"
                      control={<Checkbox onChange={handleChecked} checked={localState.placementHistoryofMonthAc} name="placementHistoryofMonthAc" />}
                      label="Placement History of 6 Month A/C"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_ageImpactofonAcResolution"
                      control={<Checkbox onChange={handleChecked} checked={localState.ageImpactofonAcResolution} name="ageImpactofonAcResolution" />}
                      label="Age Impact of on A/C resolution"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="EU_unReadNotifications"
                      control={<Checkbox onChange={handleChecked} checked={localState.unReadNotifications} name="unReadNotifications" />}
                      label="Unread Notifications"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={3} >
              <DialogTitle id="responsive-dialog-title">{"FOLDER PERMISSIONS"}</DialogTitle>
              <Box className={classes.boxview}>
                <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px', marginLeft: '15px' }}>Cloud File Folder</Typography>
                <Autocomplete
                  id="U_CU_Cloud_Dropdown"
                  style={{ width: '280px', marginTop: '-20px', marginLeft: '15px' }}
                  options={ListContainers} value={localState.ftpFolder} onChange={onContainerSelect} onOpen={OnOpenCloudFolders} loading={true} disableClearable={true}
                  getOptionLabel={(option) => option.containerNames || localState.ftpFolder} getOptionSelected={(option, value) => option.containerNames === value.containerNames}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Cloud Folder" margin="normal" variant="filled" name="ftpFolder"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField id="outlined-basic" label="Cloud File Contains" variant="outlined" name="ftpRegex" value={localState.ftpRegex} onChange={(e) => { handleChange(e); }} style={{ width: '280px', marginTop: '20px', borderBottom: '3px solid blue', borderRadius: '10px', marginLeft: '15px' }} />
              </Box>

              <Box className={classes.boxview}>
                <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px', marginLeft: '15px' }}>Payment File Folder</Typography>
                <Autocomplete
                  id="U_CU_Payment_Dropdown"
                  style={{ width: '280px', marginTop: '-20px', marginLeft: '15px' }}
                  options={ListPaymentContainers} value={localState.paymentFileFolder} onChange={onPaymentFileSelect} onOpen={OnOpenPaymentFolders} loading={true} disableClearable={true}
                  getOptionLabel={(option) => option.containerNames || localState.paymentFileFolder} getOptionSelected={(option, value) => option.containerNames === value.containerNames}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Payment Folder" margin="normal" variant="filled"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField id="outlined-basic" label="Payment File Contains" variant="outlined" name="paymentRegex" value={localState.paymentRegex} onChange={(e) => { handleChange(e); }} style={{ width: '280px', marginTop: '20px', borderBottom: '3px solid blue', borderRadius: '10px', marginLeft: '15px' }} />
              </Box>

              <Box className={classes.boxview}>
                <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px', marginLeft: '15px' }}>Acknowledgement File Folder</Typography>
                <Autocomplete
                  id="U_CU_ACK_Dropdown"
                  style={{ width: '280px', marginTop: '-20px', marginLeft: '15px' }}
                  options={ListACKContainers} value={localState.ackFileFolder} onChange={onAckFileSelect} onOpen={OnOpenAckFolders} loading={true} disableClearable={true}
                  getOptionLabel={(option) => option.containerNames || localState.ackFileFolder} getOptionSelected={(option, value) => option.containerNames === value.containerNames}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Payment Folder" margin="normal" variant="filled"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField id="outlined-basic" label="Acknowledgement File Contains" variant="outlined" name="reportRegex" value={localState.reportRegex} onChange={(e) => { handleChange(e); }} style={{ width: '280px', marginTop: '20px', borderBottom: '3px solid blue', borderRadius: '10px', marginLeft: '15px' }} />
              </Box>
            </Grid>
          </Grid>
          <DialogActions>
            <Button id="EU_Submit_btn" startIcon={<DoneAllIcon />} onClick={(_) => { handleSave(); }} color="primary" variant="contained"
              disabled={state.busy || isDisable} className={classes.submitbtn}>
              Update
            </Button>
            <Button id="EU_Close_btn" startIcon={<CancelPresentationIcon />} onClick={handleClose} className={classes.cancelbtn} color="primary" variant="contained" >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={submit} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
          {localState.firstName} Details Updated Successfully.
        </Alert>
      </Snackbar>

      <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={phone} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="error" style={{ fontSize: 16 }}>
          {localState.firstName} Phone Number Removed Successfully!
        </Alert>
      </Snackbar>
    </React.Fragment >
  );
}

export default GA_EditUser