import React from 'react';
import Faq from './pages/Faq';
import Roll from './pages/Roll';
import Admin from "./pages/Admin";
import Report from './pages/Report';
import User from "./pages/Admin/User";
import Inquiry from './pages/Inquiry';
import Settings from './pages/Settings';
import GAFAQ from "./pages/Admin/GAFAQ";
import Dashboard from './pages/Dashboard';
import Uploadlog from './pages/Uploadlog';
import Placements from './pages/Placements';
import UpdateHistory from './pages/Admin/UpdateHistory';
import GlobalMessage from "./pages/Admin/GlobalMessage";
import Finish from './pages/Placements/CSVUpload/Finish';
import DocumentUploadlog from './pages/DocumentUploadlog';
import GlobalSettings from "./pages/Admin/GlobalSettings";
import UserLoginStatus from "./pages/Admin/UserLoginStatus";
import ManageFileAccess from "./pages/Admin/ManageFileAccess";
import UserloginHistory from './pages/Admin/UserloginHistory';
import Sendnotification from './pages/Admin/Sendnotification';
import ReportsBuilder from './components/Report/ReportsBuilder';
import ClientInformation from './pages/Settings/ClientInformation';
import UserAccessControl from "./pages/Settings/UserAccessControl";
import Fieldnames from './pages/Placements/ManualEntry/Fieldnames';
import PreviewData from './pages/Placements/ManualEntry/PreviewData';
import CSVFieldNames from './pages/Placements/CSVUpload/CSVFieldNames';
import ClientConfirmation from './pages/Settings/ClientConfirmation';
import NotificationRollback from './pages/Admin/NotificationRollback';
import Message from './components/GlobalAdmin/SendNotification/Message';
import CSVPreviewData from './pages/Placements/CSVUpload/CSVPreviewData';
import ClientPlacementOptions from "./pages/Admin/ClientPlacementOptions";
import { UserRole } from './models/accesscontrol/ClientUserAccessContext';
import MassUpload from './components/GlobalAdmin/MassDocUpload/MassUpload';
import { Permission } from './models/accesscontrol/ClientUserAccessContext';
import AdfControl from './components/GlobalSettings/AdfEnvironment/AdfControl';
import AdfPipelineLogs from "./components/GlobalSettings/AdfLogs/AdfPipelineLogs";
import Newfileupload from './components/GlobalAdmin/SendNotification/Newfileupload';
import { placementPermission } from './models/accesscontrol/ClientUserAccessContext';
import ManageClientFolderAccess from "./components/FileAccess/ManageClientFolderAccess";
import AzFunctionLogs from "./components/GlobalSettings/Az-FunctionsLogs/AzFunctionLogs";
import NotificationcenterClient from "./pages/NotificationCenter/NotificationcenterClient";
import GA_EditTemplate from "./components/GlobalAdmin/ClientPlacementOptions/GA_EditTemplate";
import SettlementAuthorization from './components/GlobalAdmin/SendNotification/SettlementAuthorization';

export interface IRoute {
  path: string,
  title: string,
  DisplayTitle: string,
  component: React.ComponentType,
  isPrivate?: boolean,
  noNavLink?: boolean,
  UserRole?: UserRole[],
  Permission?: Permission[],
  placementPermission?: placementPermission[],
}

const Routes: IRoute[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    DisplayTitle: 'Dashboard',
    component: Dashboard,
    UserRole: [UserRole.ClientAdmin, UserRole.ClientUser]
  },
  {
    path: '/roll',
    title: 'Roll',
    DisplayTitle: 'Roll List',
    component: Roll,
    UserRole: [UserRole.ClientAdmin, UserRole.ClientUser]
  },
  {
    path: '/inquiry',
    title: 'Inquiry',
    DisplayTitle: 'Inquiry List',
    component: Inquiry,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/reportsBuilder/:reportId/:reportName',
    title: 'ReportsBuilder',
    DisplayTitle: 'Report Builder',
    component: ReportsBuilder,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin],
    noNavLink: true
  },
  {
    path: '/report',
    title: 'Reports',
    DisplayTitle: 'Report List',
    component: Report,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/placement',
    title: 'Placement',
    DisplayTitle: 'Placement',
    component: Placements,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/settings',
    title: 'Settings',
    DisplayTitle: 'Settings',
    component: Settings,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/faq',
    title: 'Faq',
    DisplayTitle: 'Faq',
    component: Faq,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin, UserRole.FileAdmin]
  },
  {
    path: '/uploadlog',
    title: 'Upload Log',
    DisplayTitle: 'Upload Log',
    component: Uploadlog,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/documentuploadlog',
    title: 'Document Upload Log',
    DisplayTitle: 'Document Upload Log',
    component: DocumentUploadlog,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {

    path: '/useraccesscontrol',
    title: 'UserAccessControl',
    DisplayTitle: 'User Access Control',
    component: UserAccessControl,
    noNavLink: true,
    UserRole: [UserRole.ClientAdmin]
  },
  {
    path: '/adfpipelinelogs',
    title: 'AdfPipelineLogs',
    DisplayTitle: 'ADF Pipeline Log',
    component: AdfPipelineLogs,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/azfunctionlogs',
    title: 'AzFunctionLogs',
    DisplayTitle: 'Azure Function Log',
    component: AzFunctionLogs,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin]
  },
  {
    path: '/admin',
    title: 'Admin',
    DisplayTitle: 'Admin',
    component: Admin,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/globalmessage',
    title: 'GlobalMessage',
    DisplayTitle: 'Global Message',
    component: GlobalMessage,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/users',
    title: 'User',
    DisplayTitle: 'User',
    component: User,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/clientplacementoptions',
    title: 'ClientPlacementOptions',
    DisplayTitle: 'Client Placement Options',
    component: ClientPlacementOptions,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/GA_edittemplate',
    title: 'EditTemplate',
    DisplayTitle: 'Edit Template',
    component: GA_EditTemplate,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/UserLoginStatus',
    title: 'UserLoginStatus',
    DisplayTitle: 'User Login Status',
    component: UserLoginStatus,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/clientconfirmation',
    title: 'Client Confirmation',
    DisplayTitle: 'Client Confirmation',
    component: ClientConfirmation,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/clientinformation',
    title: 'Client Information',
    DisplayTitle: 'Client Information',
    component: ClientInformation,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/userloginhistory',
    title: 'UserloginHistory',
    DisplayTitle: 'User Login History',
    component: UserloginHistory,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/updatehistory',
    title: 'UpdateHistory',
    DisplayTitle: 'Update History',
    component: UpdateHistory,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/notificationrollback',
    title: 'NotificationRollback',
    DisplayTitle: 'Notification RollBack',
    component: NotificationRollback,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/settlementauthorization',
    title: 'SettlementAuthorization',
    DisplayTitle: 'Settlement Authorization',
    component: SettlementAuthorization,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/message',
    title: 'Message',
    DisplayTitle: 'Message',
    component: Message,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/newfileupload',
    title: 'NewfileUpload',
    DisplayTitle: 'New File Upload',
    component: Newfileupload,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/finish',
    title: 'Finish',
    DisplayTitle: 'Finish',
    component: Finish,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/fieldnames',
    title: 'Fieldnames',
    DisplayTitle: 'Field Names',
    component: Fieldnames,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/csvfieldnames',
    title: 'BulkUploadFieldnames',
    DisplayTitle: '',
    component: CSVFieldNames,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/csvpreviewdata',
    title: 'BulkUploadPreviewData',
    component: CSVPreviewData,
    DisplayTitle: '',
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/previewdata',
    title: 'PreviewData',
    component: PreviewData,
    DisplayTitle: 'Preview Data',
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/sendnotification',
    title: 'Sendnotification',
    component: Sendnotification,
    DisplayTitle: 'Send Notification',
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/gafaq',
    title: 'GA_FAQ',
    component: GAFAQ,
    DisplayTitle: 'Faq',
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/notifications',
    title: 'Notification Center',
    DisplayTitle: 'Notification Center',
    component: NotificationcenterClient,
    UserRole: [UserRole.ClientAdmin, UserRole.ClientUser]
  },
  {
    path: '/managefileaccess',
    title: 'ManageFileAccess',
    DisplayTitle: 'Manage File Access',
    component: ManageFileAccess,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },

  {
    path: '/globalsettings',
    title: 'Global Settings',
    DisplayTitle: 'Global Settings',
    component: GlobalSettings,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin]
  },

  {
    path: '/ManageClientFolderAccess',
    title: 'Manage File Access',
    DisplayTitle: 'Manage File Access',
    component: ManageClientFolderAccess,
    UserRole: [UserRole.FileAdmin]
  },
  {
    path: '/Massupload',
    title: 'Mass Document Upload',
    DisplayTitle: 'Mass Document Upload',
    component: MassUpload
  },
  {
    path: '/adfcontrol',
    title: 'ADF Environment',
    DisplayTitle: 'ADF Environment',
    component: AdfControl,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin]
  },
]
export default Routes
