import React, { createContext, useContext, useReducer } from 'react'
import { IGlobalState } from '../models/IGlobalState'
import { IUser } from '../models/IUser'
import { AccessContext } from '../models/accesscontrol/AccessContext'
import { GlobalDateTimeUtil } from "../models/GlobalUtil/GlobalDateTimeUtil";

export enum GlobalStateAction {
  Busy,
  Idle,
  Error,
  LoggedIn,
  LoggedOut,
  SetUsers,
  SetPermissions,
  GlobalUtils,
}

export type Action =
  | { type: GlobalStateAction.Busy }
  | { type: GlobalStateAction.Idle }
  | { type: GlobalStateAction.Error; error: any }
  | { type: GlobalStateAction.LoggedIn; user: IUser }
  | { type: GlobalStateAction.LoggedOut }
  | { type: GlobalStateAction.SetUsers; users: IUser[] }
  | { type: GlobalStateAction.SetPermissions; userAccessContext: AccessContext }
  | { type: GlobalStateAction.GlobalUtils; GlobalUtils: GlobalDateTimeUtil }

const initialUserState: IGlobalState = {
  hasError: false,
  busy: false,
}

const globalStore = createContext<{
  state: IGlobalState,
  dispatch: React.Dispatch<Action>
}>({
  state: initialUserState,
  dispatch: () => { },
})

const reducer: React.Reducer<IGlobalState, Action> = (state, action) => {
  switch (action.type) {
    case GlobalStateAction.Busy:
      return { ...state, busy: true }
    case GlobalStateAction.Idle:
      return { ...state, busy: false }
    case GlobalStateAction.Error:
      return { ...state, busy: false, error: action.error }
    case GlobalStateAction.LoggedIn:
      return { ...state, user: { ...action.user } }
    case GlobalStateAction.LoggedOut:
      return { ...state, user: undefined }
    case GlobalStateAction.SetUsers:
      return { ...state, users: action.users }
    case GlobalStateAction.SetPermissions:
      return { ...state, userAccessContext: action.userAccessContext }
    case GlobalStateAction.GlobalUtils:
      return { ...state, GlobalUtils: { ...action.GlobalUtils } }
    default:
      return state
  }
}

const GlobalStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<React.Reducer<IGlobalState, Action>>(
    reducer,
    initialUserState
  )

  return (
    <globalStore.Provider value={{ state, dispatch }}>
      {children}
    </globalStore.Provider>
  )
}

const useGlobalState = () => useContext(globalStore)

export { GlobalStateProvider, useGlobalState }