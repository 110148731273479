import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { Backdrop, Box, DialogActions, DialogContentText, DialogTitle, IconButton, Slide, Typography } from '@material-ui/core';
import { IUpdateFaq } from '../../../models/GA_Faq/IUpdateFaq';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useDelete } from '../../../utils/apiHelper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { TransitionProps } from '@material-ui/core/transitions';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import PuffLoader from 'react-spinners/PuffLoader';
import DeleteIcon from '@mui/icons-material/Delete';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: "flex",
    },
    centerBox: {
      justifyContent: "flex-end",
      alignItems: "flex-end"
    },
    DialogBottom: {
      marginRight: '20px'
    },
    snackbar: {
      color: 'black',
      marginTop: '-0.5cm',
    },
    snackbarfont: {
      fontSize: 16,
    },
    yesButton: {
      background: "green",
      color: 'white',
      borderRadius: 20,
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      borderRadius: 20,
      display: 'flex',
      '&:hover': {
        background: "red",
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteFAQ: React.FC<{ faqRow: IUpdateFaq, onDelete: () => void }> = (props) => {
  const { faqRow } = props
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const classes = useRowStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useGlobalState();
  const [show, setShow] = React.useState(false);
  const [ProgressBar, setShowProgressBar] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShow(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setShowProgressBar(true);
    useDelete("Faq/DeleteFaq?Id=" + faqRow.id).then((r) => {
      if (r.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: r.statusText })
      }
      props.onDelete();
      setShowProgressBar(false);
      setShow(true);
    });
  };

  return (
    <React.Fragment>
      <div>
        <div>
          <IconButton id="GAFAQ_Delete_btn" aria-label="delete" onClick={handleClickOpen}>
            <DeleteIcon style={{color:'red'}}/>
          </IconButton>
        </div>
        <div>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={100} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <Dialog
            fullScreen={fullScreen} open={open} onClose={handleClose}
            aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 15, width: 580 } }} TransitionComponent={Transition}>
            <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
              <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                DELETE FAQ
              </Typography>
            </DialogTitleDelete>
            <DialogContent>
              <DialogContentText>
                <Typography variant="h5" style={{ marginTop: "10px", color: "black" }} gutterBottom>
                  Are you sure, you want to delete this faq ?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.DialogBottom}>
              <Button id="GAFAQ_ok_btn" variant="contained" onClick={handleOk} className={classes.yesButton}>
                Yes
              </Button>
              <Button id="GAFAQ_Cancel_btn" variant="contained" autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div >
      </div>
      <Snackbar open={show} className={classes.snackbar} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success" className={classes.snackbarfont}>
          Faq Deleted Successfully!!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
export default DeleteFAQ