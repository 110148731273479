import React, { useEffect, useState } from 'react'
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFetch, usePost } from '../../utils/apiHelper';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DialogContentText from '@material-ui/core/DialogContentText';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { IActiveUsers } from "../../models/NotificationRollBack/IActiveUsers";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { INotificationTypeList } from '../../models/NotificationRollBack/INotificationTypeList';
import { INotificationContract } from '../../models/NotificationRollBack/INotificationContract';
import NotificationTable from "../../components/GlobalAdmin/NotificationRollBack/NotificationTable";
import { IRollbackNotificationList } from "../../models/NotificationRollBack/IRollbackNotificationList";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, makeStyles, Paper,
  Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, CssBaseline
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import PuffLoader from "react-spinners/PuffLoader";
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import Footer from '../../components/Footer/Footer';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const useStyles = makeStyles((theme) => ({
  searchbar: {
    width: '60vw',
    borderRadius: 50,
    marginTop: '-8px',
    border: '2px black solid'
  },
  container: {
    marginTop: "2cm",
    alignItems: "center"
  },
  root: {
    flexGrow: 1,
  },
  actions: {
    width: "100%",
    marginTop: "0.5cm"
  },
  buttons: {
    borderRadius: 20,
    background: "#007FFF",
    color: "white",
    marginTop: "10px"
  },
  textField: {
    marginRight: theme.spacing(1),
    width: '280px',
    marginLeft: '-20px',
    marginTop: "12px"
  },
  formControl: {
    minWidth: 150,
    margintop: "1cm"
  },
  grid: {
    padding: '8px'
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid gray',
    marginTop: '5px'
  },
  filter: {
    marginTop: '10px'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  snackbar: {
    width: "120%",
    marginTop: '-0.5cm',
  },
  Title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  yesbutton: {
    backgroundColor: 'green',
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  descriptionText: {
    textAlign: 'left',
    flexWrap: 'wrap',
    wordWrap: 'break-word',
  },
  TextHighlight: {
    color: 'black',
    fontWeight: 'bold',
    background: "yellow",
  },
  TextNormal: {
    color: 'black',
    fontSize: 16
  },
  rollbutton: {
    float: 'left',
    marginLeft: 50,
    borderRadius: "20px"
  },
  tablebody: {
    maxHeight: 520
  },
  searchBox: {
    borderRadius: 30,
    border: '2px black solid',
    width: '80%',
    height: '45px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    fontSize: 16,
    textIndent: '10px'
  },
}))


function NotificationRollback() {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const [mapped, setMapped] = useState(false);
  const [EndDate, setEndDate] = useState(new Date());
  const [StartDate, setStartDate] = useState(new Date());

  const [SelectActiveUser, setSelectActiveUser] = useState<any>();
  const [activeUsers, setactiveUsers] = useState<IActiveUsers[]>([]);
  const usersLoading = activeUsers.length === 0;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [ActionTaken, setActionTaken] = useState<INotificationContract>({});
  const [NotificationType, setNotificationType] = useState<INotificationTypeList[]>([]);
  const [SelectNotificationType, setSelectNotificationType] = useState<any>();
  const [RolledBack, setRolledBack] = useState<INotificationContract>({});
  const [searchNotification, setSearchNotification] = useState<string>();

  const [orderBy, setOrderBy] = useState<string>("createdDate");
  const [order, setOrder] = useState<any>('desc');
  const [open, setOpen] = React.useState(false);
  const [rollbackNotification, setRollbackNotification] = useState<IRollbackNotificationList[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [NotifyId, setNotifyId] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(false);
  const [selectedFirstDate, setSelectedFirstDate] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [rollBack, setRollBack] = useState(false);
  const [action, setAction] = useState(false);

  const [ProgressBar, setShowProgressBar] = useState(true);
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy"

  const headCells = [
    { id: "createdDate", numeric: false, disablePadding: false, label: "DATE", sortable: true, marginLeft: '10px' },
    { id: "notificationName", numeric: false, disablePadding: false, label: "NOTIFICATION TYPE", sortable: true },
    { id: "firstname " + " " + "lastname", numeric: false, disablePadding: false, label: "USER", sortable: true },
    { id: "extraData_3", numeric: false, disablePadding: false, label: "SUBJECT", sortable: true },
    { id: "", numeric: false, disablePadding: false, label: "REMOVE", sortable: false }
  ];

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort,
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ background: "#007FFF", color: "white", fontSize: "100%" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ marginLeft: `${headCell.marginLeft}` }}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id || headCell.sortable ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }


  const initiateSearch = () => {
    setTriggerSearch(!triggerSearch)
    getRollbackNotificationListAsync();
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setShowProgressBar(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setShowProgressBar(true);
  };

  const handleFirstDateChange = (date: Date | null) => {
    setStartDate(date);
    setSelectedFirstDate(true);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  const handleActionChange = (event) => {
    const name = event.target.name;
    setAction(true);
    setActionTaken({
      ...ActionTaken,
      [name]: event.target.value
    });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleRolledbackChange = (event) => {
    const name = event.target.name;
    setRollBack(true);
    setRolledBack({
      ...RolledBack,
      [name]: event.target.value
    });
  };

  useEffect(() => {
    getRollbackNotificationListAsync();
  }, [activeUsers, page, rowsPerPage])

  const handleFilter = () => {
    getRollbackNotificationListAsync();
    setOpen(true);
  }

  function getRollbackNotificationListAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      try {
        let request = {
          "rowsPerPage": rowsPerPage,
          "searchtext": searchNotification ?? "",
          "startdate": (selectedFirstDate ? StartDate : ''),
          "enddate": (selectedEndDate ? EndDate : ''),
          "userid": (selectedUser ? SelectActiveUser.id : 0),
          "notificationtypeid": (NotifyId ? 0 : SelectNotificationType.notificationTypeId),
          "actiontaken": (action ? parseInt(ActionTaken.actiontaken.toString()) : 0),
          "rolledback": (rollBack ? RolledBack.rolledback : 0),
          "startingRecordNumber": (page * rowsPerPage) + 1,
        };
        const response = await usePost<{ rollbackNotification: IRollbackNotificationList[], totalCount: number }>("GetRollbackNotificationList", request)
        const data = response.data.rollbackNotification.map((a) => {
          return { ...a, createdDate: a.createdDate }
        })
        setRollbackNotification(data);
        setMapped(!data.length ? true : false);
        setTotalRecords(response.data.totalCount);
        setShowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })();
  }

  useEffect(() => {
    (async () => {
      try {
        const ActiveUsersList = await useFetch<IActiveUsers[]>("GetActiveUsers")
        setactiveUsers(ActiveUsersList.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const NotificationTypeList = await useFetch<INotificationTypeList[]>("GetNotificationTypeList")
        setNotificationType(NotificationTypeList.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }, [])

  function onUsersSelect(event, value) {
    setSelectActiveUser(value);
    setSelectedUser(true);
    setPage(0);
    (async () => {
      try {
        const response = await useFetch<IActiveUsers[]>("GetActiveUsers?id=" + value.id)
        setactiveUsers(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  function onNotificationSelect(event, value) {
    setPage(0);
    (async () => {
      try {
        const response = await useFetch<INotificationTypeList[]>("GetNotificationTypeList?notificationTypeId=" + value.notificationTypeId)
        setSelectNotificationType(value?.notificationTypeId === 10 ? 0 : value);
        setSelectedNotification(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  useEffect(() => {
    if (SelectNotificationType?.notificationTypeId === 10 || SelectNotificationType === undefined) {
      setNotifyId(true);
    } else {
      setNotifyId(false);
    }
  }, [NotifyId, SelectNotificationType])


  const RollAllBack = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      try {
        let request = {
          "rowsPerPage": (rowsPerPage),
          "searchtext": searchNotification ?? "",
          "startdate": (selectedFirstDate ? StartDate : ''),
          "enddate": (selectedEndDate ? EndDate : ''),
          "userid": (selectedUser ? SelectActiveUser.id : 0),
          "notificationtypeid": (NotifyId ? 0 : SelectNotificationType.notificationTypeId),
          "actiontaken": (action ? ActionTaken.actiontaken : 0),
          "rolledback": (rollBack ? RolledBack.rolledback : 0),
          "startingRecordNumber": (page * rowsPerPage) + 1,
        };
        const response = await usePost<IRollbackNotificationList[]>("RollbackAllNotification", request);
        getRollbackNotificationListAsync();
        setShowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })();
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  function openConfirmDialog() {
    setDialogOpen(true);
  }

  function RollBackAllNotification() {
    RollAllBack();
    setDialogOpen(false);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Grid container className={classes.grid} style={{ backgroundColor: 'whitesmoke' }}>
          <Grid item xs={3} sm={3}>
            <Typography variant="h4" gutterBottom style={{ color: "blue", textAlign: 'left' }}>
              <b>NOTIFICATION ROLLBACK</b>
            </Typography>
            <Snackbar open={open} className={classes.snackbar} anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Filter Successfully Triggered!
              </Alert>
            </Snackbar>
          </Grid>
          <Grid item xs={3} sm={6} >
            <input value={searchNotification}
              type='text' placeholder="Search Notification"
              className={classes.searchBox}
              onChange={e => setSearchNotification(e.target.value)}
              onKeyPress={event => { if (event.key === 'Enter') { initiateSearch(); } }}
              maxLength={255}
            />
            {StartDate > EndDate ? <Typography variant='h6' style={{ color: 'red' }}>Invalid End Date.</Typography> : ""}
          </Grid>
          <Grid item xs={3} sm={3} >
            <Box display="flex" justifyContent="flex-end">
              <Button id="NRB_RollBackAll" startIcon={<AutorenewIcon />} variant="contained" color="primary" onClick={openConfirmDialog} className={classes.rollbutton}>RollBack All</Button>
            </Box>
          </Grid>
          <Grid container spacing={1} className={classes.maingrid} justify='space-evenly'>
            <Grid item xs={5} sm={5} md={2} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    label="Start Date"
                    format={DateFormat}
                    className={classes.textField}
                    margin="normal"
                    id="NRB_StartDate"
                    value={StartDate}
                    onChange={handleFirstDateChange}
                    inputVariant="outlined"
                    name="startdate"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={5} sm={5} md={2} style={{ marginLeft: "-45px" }} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    label="End Date"
                    format={DateFormat}
                    className={classes.textField}
                    style={{ marginLeft: '20px' }}
                    margin="normal"
                    id="NRB_EndDate"
                    value={EndDate}
                    onChange={handleEndDateChange}
                    inputVariant="outlined"
                    name="enddate"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={5} sm={5} md={2}>
              <Autocomplete
                id="NRB_SelectUsers"
                autoComplete autoHighlight
                style={{ marginTop: "-4px", width: '290px', marginLeft: '15px' }}
                options={activeUsers} onChange={onUsersSelect}
                getOptionLabel={(option) => option.firstname + " " + option.lastname}
                renderInput={(params) => (
                  <TextField {...params} label="Users" margin="normal" variant="outlined" style={{ marginLeft: "-50px" }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {usersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5} sm={5} md={2}>
              <Autocomplete
                id="NRB_NotificationType"
                autoComplete autoHighlight
                style={{ marginTop: "-4px", width: '280px', marginLeft: '5px' }}
                options={NotificationType} onChange={onNotificationSelect}
                getOptionLabel={(option) => option.notificationName}
                renderInput={(params) => (
                  <TextField {...params} label="Notification Type" margin="normal" variant="outlined" style={{ marginLeft: "-50px" }}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5} sm={5} md={1}>
              <FormControl variant="filled" style={{ marginTop: "14px", width: "200px", marginLeft: "-40px" }} className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">Action Taken</InputLabel>
                <Select id="NRB_SelectAction" native value={ActionTaken.actiontaken} onChange={handleActionChange}
                  inputProps={{
                    name: 'actiontaken',
                    id: 'outlined-age-native-simple',
                  }}>
                  <option value={0}>All</option>
                  <option value={1}>No</option>
                  <option value={2}>Yes</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5} sm={5} md={1}>
              <FormControl variant="filled" style={{ marginTop: "14px", marginLeft: "15px", width: "200px", }} className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native">Rolled-Back</InputLabel>
                <Select id="NRB_SelectRolledBack" native value={RolledBack.rolledback} onChange={handleRolledbackChange} label="Age"
                  inputProps={{
                    name: 'rolledback',
                    id: 'NRB_ShowORhidden',
                  }}>
                  <option value={0}>All</option>
                  <option value={1}>Show</option>
                  <option value={2}>Hidden</option>
                </Select>
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={5} sm={6} md={1} className={classes.filter}>
                <Button id="NRB_Filter" className={classes.buttons} onClick={() => { handleFilter(); }} startIcon={<FilterAltIcon />} variant="contained" color="primary" style={{ marginLeft: "60px" }}>
                  Filter
                </Button>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid item xs={5} sm={6} md={1}>
                <IconButton id="NRB_FilterIcon" aria-label="Filter" onClick={() => { handleFilter(); }} style={{ color: 'blue' }} className={classes.filter} >
                  <FilterListIcon style={{ fontSize: 35 }} />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} component={Paper} style={{ marginTop: '10px' }}>
            <TableContainer component={Paper} style={{ marginBottom: "10px" }} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table id="rollClients" aria-label="collapsible table" size="small" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody >
                  {stableSort(rollbackNotification, getComparator(order, orderBy)).map(r => {
                    let CreatedDate = moment(r.createdDate).format(state.GlobalUtils?.settingValue)
                    return (
                      <React.Fragment>
                        <TableRow hover>
                          <TableCell scope="row">
                            {CreatedDate}
                          </TableCell>
                          <TableCell align='left'>
                            {r.notificationName}
                          </TableCell>
                          <TableCell align='left'>
                            {r.firstname + " " + r.lastname}
                          </TableCell>
                          <TableCell align='left' style={{ width: '200px' }}>
                            <Typography style={{ width: '900px' }} className={classes.descriptionText}>
                              <span className={`${searchNotification === r.notificationText ? classes.TextHighlight : ""}`}>
                                {r.notificationText?.replace('µ', ' ')}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell align='left'>
                            <NotificationTable row={r} onRollBack={getRollbackNotificationListAsync} />
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
              {mapped === true ?
                < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
                  No records to display..
                </Typography>
                : null}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
          <Footer />
        </Grid>
        <Dialog
          open={isDialogOpen}
          PaperProps={{ style: { borderRadius: 15 } }}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
            <Typography variant="h5" gutterBottom className={classes.titleheader}>
              ROLLBACK ALL
            </Typography>
          </DialogTitleHeader>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h5" style={{ marginTop: "15px", color: "black" }} gutterBottom>
                Are you sure, you want to Rollback All Notification?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { RollBackAllNotification(); }} variant="contained" className={classes.yesbutton} autoFocus>
              Yes
            </Button>
            <Button onClick={handleCloseDialog} className={classes.nobutton} variant="contained" color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment >
  )
}

export default NotificationRollback
