import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { IClientCodeName } from "../../../models/SendNotification/IClientCodeName";
import { IUserForClient } from "../../../models/SendNotification/IUserForClient";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { IInsertNotification } from '../../../models/SendNotification/IInsertNotification';
import { Backdrop } from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import Footer from '../../../components/Footer/Footer'
import SendIcon from '@material-ui/icons/Send';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonIcon from '@material-ui/icons/Person';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '0.2cm',
    backgroundColor: 'white',
    maxWidth: "90vw",
    marginBottom: "10px",
    border: '2px solid #215176',
    alignItems: "center",
    height: '76vh',
    borderRadius: 20,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  searchIcon: {
    borderRadius: 50,
    background: "white",
    marginTop: '0.3cm',
    width: "15vw",
    height: '45px',
    float:'left',
    marginLeft:'2.2cm',
    border: '2px #215176 solid',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    fontSize: 16,
    textIndent: '10px'
  },
  searchIcon1: {
    borderRadius: 50,
    background: "white",
    marginTop: '0.3cm',
    width: "15vw",
    height: '45px',
    border: '2px #215176 solid',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    fontSize: 16,
    textIndent: '10px'
  },
  description: {
    marginTop: "5px",
    border: '2px #215176 solid',
    width: "90%",
    marginLeft: "15px",
    borderRadius: 10,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  paper: {
    width: 276,
    height: 315,
    overflow: 'auto',
    overflowX: 'hidden',
    borderRadius: 10,
    borderTop: '2px #215176 solid',
    marginTop: '5px',
  },
  gridstyle: {
    border: '2px #215176 solid',
    marginTop: "18px",
    borderRadius: 10,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  div: {
    flexGrow: 1
  },
  grid: {
    padding: '0px',
    borderRadius: 8,
    marginTop: "5px"
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '20px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  sendNotification: {
    height: "44px",
    marginTop: '0.3cm',
    width: "15vw",
    borderRadius: 50,
    float:'right',
    marginRight:'2.2cm',
    fontSize: 16,
    fontWeight: 'bold'
  },
  clientname: {
    fontSize: 18,
    marginTop: '5px'
  },
  clientlist: {
    textAlign: 'left',
    fontSize: 16
  },
  clientcheckbox: {
    color: "#00e676",
    width: 50,
    height: 38
  },
  titleHeader: {
    color: "blue",
    textAlign: 'center',
    marginLeft: 20
  },
  selectallbutton: {
    marginTop: "3.5cm",
    width: "9vw",
    height: "40px",
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 20,
  },
  removeallbutton: {
    marginTop: "1cm",
    width: "9vw",
    height: "40px",
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 20,
  },
  snackbar1: {
    color: 'black',
    marginTop: '-0.5cm',
  },
  snackbarFont: {
    fontSize: 16,
  },
  label: {
    '&$focused': {
      color: 'white',
      border: '0px'
    },
  },
  focused: {},
  outlinedInput: {
    '&$focused $notchedOutline': {
      color: 'white',
      border: '0px'
    },
  },
  notchedOutline: {
    color: 'white',
    border: '0px'
  },
}));

export default function NativeSelects() {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState()
  const [message, setmessage] = useState<string>("");
  const [ClientCodes, setClientCodes] = useState<IClientCodeName[]>([]);
  const [UsersForClient, setUsersForClient] = useState<IUserForClient[]>([]);
  const [SelectedUsers, setSelectedUsers] = useState<IUserForClient[]>([]);
  const [SearchTerm, setSearchTerm] = useState("");
  const [UserSearchTerm, setUserSearchTerm] = useState("");
  const [SelectedClientCodes, setSelectedClientCodes] = useState<IClientCodeName[]>([]);
  const [Selected, setSelected] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const getUsersForClient = (clientCodes) => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": clientCodes
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setshowProgressBar(true);
        setUsersForClient(responseUser.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const getUsersClient = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": selectedClient
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setshowProgressBar(true);
        setUsersForClient(responseUser.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const handleClientCodeToggle = (client: IClientCodeName) => () => {
    let newSelectedClientCodes = [...SelectedClientCodes]

    if (newSelectedClientCodes.some(c => c.clientCode == client.clientCode)) {
      newSelectedClientCodes = newSelectedClientCodes.filter(c => c.clientCode != client.clientCode);
    } else {
      newSelectedClientCodes.push(client);
    }

    let clientcodes = "";
    for (var checkClientCode in newSelectedClientCodes) {
      clientcodes += newSelectedClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }

    setSelectedClientCodes(newSelectedClientCodes);
    getUsersForClient(clientcodes);
    setSelectedClient(clientcodes);
    setshowProgressBar(true);
  }

  const handleUserToggle = (user: IUserForClient) => () => {
    let newSelectedUsers = [...SelectedUsers];
    if (newSelectedUsers.some(u => u.id == user.id)) {
      newSelectedUsers = newSelectedUsers.filter(u => u.id != user.id);
    } else {
      newSelectedUsers.push(user);
    }
    setSelectedUsers(newSelectedUsers);
  }

  const handleAllRight = () => {
    let clientcodes = "";
    for (var checkClientCode in ClientCodes) {
      clientcodes += ClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    getUsersForClient(clientcodes);
    setSelectedClientCodes(ClientCodes)
    setshowProgressBar(false);
  };

  const handleAllLeftClient = () => {
    let clientcodes = "";
    getUsersForClient(clientcodes);
    setSelectedClientCodes([]);
    setshowProgressBar(false);
  }

  const handleCheckedRight = () => {
    setSelectedUsers(UsersForClient);
    setshowProgressBar(false);
  };

  const handleAllLeft = () => {
    setSelectedUsers([]);
    setshowProgressBar(false);
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let response = await useFetch<IClientCodeName[]>('GetClientCodeList');
        setshowProgressBar(true);
        setClientCodes(response.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])


  const clearClientLists = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let response = await useFetch<IClientCodeName[]>('GetClientCodeList');
        setClientCodes(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const GetClientLists = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await useFetch<IClientCodeName[]>(`GetClientSearchList?searchText=${searchText}`);
        setClientCodes(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const getClientUsersAsync = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": selectedClient,
          "name": SearchTerm
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setUsersForClient(responseUser.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const GetSelectedUserID = () => {
    let userIds = "";
    SelectedUsers.forEach((user) => {
      userIds += user.id;
      userIds += ","
    })
    return userIds.substr(0, userIds.length - 1);
  }

  function reset() {
    setmessage("");
  }

  const Insert = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const formData = new FormData();
        let dateTime = new Date();
        formData.append("TypeId", "2");
        formData.append("ExtraData1", GetSelectedUserID());
        formData.append("ExtraData2", "");
        formData.append("ExtraData3", message);
        formData.append("ExtraData4", "");
        const response = await usePost<IInsertNotification>('InsertAdminNotification', formData).then((r) => {
          if (r.status == 400) {
            dispatch({ type: GlobalStateAction.Error, error: r.statusText });
          }
        });
        setOpen(true);
        reset();
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  const clientCodeList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.clientname} >
        <b>CLIENTS</b>
      </Typography>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {ClientCodes.slice(0, 150).map((client, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleClientCodeToggle(client)}>
                <ListItemIcon>
                  <Checkbox
                    size="medium"
                    className={classes.clientcheckbox}
                    id="SA_ClientCode_Checkbox"
                    checked={SelectedClientCodes.some(c => c.clientCode == client.clientCode)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom className={classes.clientlist} >
                    <b>{client.clientCode}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  const usersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientname} gutterBottom color="secondary">
        <b>USERS</b>
      </Typography>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {UsersForClient.map((user, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleUserToggle(user)}>
                <ListItemIcon>
                  <Checkbox
                    className={classes.clientcheckbox}
                    id="NF_Users_Checkbox"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    checked={SelectedUsers.some(u => u.id == user.id)}
                  />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom className={classes.clientlist}>
                    <b>{user.firstName}{user.lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  const selectedUsersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientname} gutterBottom color="secondary" >
        <b>ADD TO NOTIFICATION</b>
      </Typography>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {SelectedUsers.map(({ firstName, lastName }, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem">
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom className={classes.clientlist}>
                    <b>{firstName}{lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  useEffect(() => {
    let EnableButton = GetSelectedUserID.length < 0 ? true : false;
    setSelected(EnableButton);
  }, [Selected])

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Typography variant="h4" gutterBottom className={classes.titleHeader}>
          <b> MESSAGE </b>
        </Typography>
        <Container fixed className={classes.container}>
          <Grid container spacing={1} className={classes.grid}>
            <Grid item xs={12}>
              <Typography variant="button" display="block" style={{ fontSize: 17 }} gutterBottom color="secondary" >
                <b>DESCRIPTION</b>
              </Typography>
              <TextField
                placeholder="Enter the Message..."
                size="medium"
                id="SN_M_Message"
                multiline
                rows={6}
                value={message} onChange={(e) => { setmessage(e.target.value); }}
                variant="outlined"
                inputProps={{ maxlength: 500 }}
                InputLabelProps={{ shrink: true }}
                className={classes.description}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    focused: classes.focused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <input value={searchText}
                type='text' placeholder="Search Clients"
                className={classes.searchIcon}
                onChange={e => setSearchText(e.target.value)}
                onKeyPress={event => { if (event.key === 'Enter') { GetClientLists(); } }}
                maxLength={255}
              />
            </Grid>
            <Grid item xs={4}>
                <input value={SearchTerm}
                  type='text' placeholder="Search Users"
                  className={classes.searchIcon1}
                  onChange={e => setSearchTerm(e.target.value)}
                  onKeyPress={event => { if (event.key === 'Enter') { getClientUsersAsync(); } }}
                  maxLength={255}
                />
            </Grid>
            <Grid item xs={4} >
              <Button id="SN_M_SendNotification" className={classes.sendNotification} endIcon={<SendIcon />}
                disabled={Selected || !message} variant="contained" color="primary" onClick={(_) => { Insert(); }} >
                Send Notification
              </Button>
            </Grid>
            <Grid container spacing={0} className={classes.maingrid} justify='space-evenly'>
              <Grid item xs={5} sm={6} md={2} className={classes.gridstyle}>
                {clientCodeList()}
              </Grid>
              <Grid item xs={5} sm={4} md={1}>
                <Button
                  id="NF_SelectAll_btn"
                  style={{}}
                  className={classes.selectallbutton}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAllRight}
                  aria-label="move all right" startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />}>
                  Select All
                </Button>
                <Button
                  id="NF_RemoveAllClient_btn"
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.removeallbutton}
                  onClick={handleAllLeftClient}
                  disabled={SelectedClientCodes.length === 0}
                  aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />} >
                  Remove All
                </Button>
              </Grid>
              <Grid item xs={5} sm={6} md={2} className={classes.gridstyle} >
                {usersList()}
              </Grid>
              <Grid item xs={5} sm={5} md={1}>
                <Button
                  id="NF_AddAll_btn"
                  variant="contained"
                  color="primary"
                  className={classes.selectallbutton}
                  size="small"
                  onClick={handleCheckedRight}
                  aria-label="move selected right" startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />}>
                  Add All
                </Button>
                <Button
                  id="NF_RemoveAll_btn"
                  variant="contained"
                  color="primary"
                  className={classes.removeallbutton}
                  size="small"
                  onClick={handleAllLeft}
                  aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />}>
                  Remove All
                </Button>
              </Grid>
              <Grid item xs={5} sm={5} md={2} className={classes.gridstyle}>
                {selectedUsersList()}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
      <Snackbar open={open} className={classes.snackbar1} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" className={classes.snackbarFont}>
          Message Sent Successfully !
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}