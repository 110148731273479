import {
    Box, Button, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText,
    Grid, IconButton, makeStyles, TextField, Typography
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import ReplayIcon from "@material-ui/icons/Replay";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { IGetRolledPlacement } from '../../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccount } from '../../../models/Inquiry/IPlacedAccount';
import { IPlacedAccountContract } from "../../../models/Inquiry/IPlacedAccountContract";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useDelete, useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const useStyles = makeStyles((theme) => ({
    button: {
        paddingBottom: "10px",
        paddingTop: "10px",
        width: 165,
        borderRadius: 10,
        marginTop: "-40px"
    },
    inputField: {
        height: '56px',
        fontSize: 17,
        borderRadius: '4px',
        position: 'relative',
        transition: '0.3s',
        marginLeft: '7cm',
        width: "360px",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    description: {
        width: '100%',
        marginLeft: "-1%",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    root2: {
        borderRadius: 8,
        height: "90%",
        marginTop: "7px",
        marginLeft: "-30%"
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    snackbar: {
        color: 'black',
        marginTop: '-0.5cm',
    },
    snackbarfont: {
        fontSize: 16,
    },
    confirmButton: {
        color: "white",
        marginTop: "7px",
        float: 'right',
        marginRight: "10px",
        borderRadius: 20,
        marginBottom: '0.3cm',
    },
    cancelButton: {
        background: "red",
        color: "white",
        marginRight: "30px",
        marginTop: "7px",
        marginBottom: '0.3cm',
        borderRadius: 20,
        '&:hover': {
            background: "red",
            color: "white",
        }
    },
}))

const CloseAccount: React.FC<{ dbtNumber: string, dbT_PRINCIPAL_DUE: number, Disable: IGetRolledPlacement, clientId: string, onEroll: () => void, onSubmit: () => void, closeSts: boolean, BlockAwaitAccess: boolean }> = (props) => {
    const { Disable } = props
    const classes = useStyles()
    const { state, dispatch } = useGlobalState()
    const [clicked, setClicked] = useState(false);
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [inputVal, setinputVal] = useState<any>('');
    const [CloseNote, setCloseNote] = useState<any>('');
    const [closeAcctBal, setcloseAcctBal] = useState<any>();
    const [closeAcctReason, setValue] = useState('');
    const canCloseAccount = state.userAccessContext?.closingAccounts;
    const handleDisable = Disable?.rolled;
    const CloseDisable = Disable?.removed;
    const [Removed, setRemoved] = useState(Disable?.closed);
    const UserName = state.userAccessContext?.id;
    const [open, setOpen] = React.useState(false);
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>(0);
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props.dbT_PRINCIPAL_DUE);
    const [principalPaymentDue, setPrincipalPaymentDue] = useState<number>(props.dbT_PRINCIPAL_DUE);
    const [placedAccounts, setPlacedAccounts] = useState<IPlacedAccount[]>([]);
    const [isValid, setIsValid] = useState(true);
    const [closeReason, setCloseReason] = useState(false);
    const [isReasonValid, setReasonValid] = useState('');
    const [close, setClose] = React.useState(false);
    const [undoClose, setUndoClose] = React.useState(false);
    const [principalAmount, setPrincipalAmount] = useState<any>(0);

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    function recursiveRemove(inputAmt: string, target: number) {
        if (inputAmt == undefined) return "";
        return parseFloat(inputAmt) > target ?
            recursiveRemove(inputAmt = inputAmt.slice(0, -1), target) :
            inputAmt.toString();
    }

    const handleOnValueChange = (value: string): void => {
        setPrincipalPaymentAmount(recursiveRemove(value, props.dbT_PRINCIPAL_DUE));
        setinputVal(Number(value) >= 0 && parseFloat(value) <= props.dbT_PRINCIPAL_DUE ? value : '');
        setcloseAcctBal(recursiveRemove(value, props.dbT_PRINCIPAL_DUE));
    };

    const handleReasonNote = (event) => {
        setCloseNote(event.target.value === '0' || '' ? setCloseReason(false) : event.target.value || "");
        setCloseReason((event.target.value === '0') || (event.target.value === '') ? false : true);
    };

    const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setReasonValid(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
        setCloseReason(false);
        setCloseNote('');
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
    };

    function emptyCheck() {
        setCloseNote('');
        setinputVal('');
        setValue('');
        setReasonValid('');
        setcloseAcctBal('');
        setClose(false);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setClose(false);
        setUndoClose(false);
    };

    useEffect(() => {
        setUpdatedPrincipalAmount(Number((principalPaymentDue - closeAcctBal || principalPaymentDue - principalPaymentAmount).toFixed(2)));
    }, [principalPaymentDue, closeAcctBal, principalPaymentAmount])

    useEffect(() => {
        (updatedPrincipalAmount < 0 || closeAcctBal < 0 || principalPaymentAmount < 0) ? setIsValid(false) : setIsValid(true);
    }, [updatedPrincipalAmount, closeAcctBal])

    useEffect(() => {
        let principal_amount_due = props.dbT_PRINCIPAL_DUE;
        for (let account of placedAccounts) {
            principal_amount_due = (principal_amount_due - account.prcpl_Adj_Amt - account.prpcl_Pay_Amt);
        }
        setPrincipalPaymentDue(Number(principal_amount_due.toFixed(2)));
    }, [placedAccounts])

    const PaymentAmountValidation = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${props.dbtNumber}`)
                setPlacedAccounts(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const CloseAccount = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let CloseBalance = parseInt(closeAcctBal);
                let request = {
                    "dbtNumber": props.dbtNumber,
                    "close_acct_note": CloseNote,
                    "close_acct_bal": CloseBalance,
                    "close_acct_reason": closeAcctReason,
                    "user_Id": UserName,
                    "isCloseRemove": false
                };

                if (closeAcctReason == 'Paid') {
                    let CloseBalance = parseInt(closeAcctBal);
                    let Principal = ((principalPaymentDue - CloseBalance).toFixed(2));
                    let requestPaid = {
                        "prcpl_Pay_Amt": Principal,
                        "close_acct_note": CloseNote,
                        "dbtNumber": props.dbtNumber,
                        "close_acct_reason": closeAcctReason,
                        "close_acct_bal": CloseBalance,
                        "user_Id": UserName,
                        "isCloseRemove": true
                    };
                    await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', requestPaid);
                    AccountClosed();
                    setRemoved(true);
                    props.onSubmit();
                    setPrincipalAmount(Principal);
                    setClose(true);
                }
                else {
                    await usePost<IPlacedAccountContract[]>('Account/EmptyPlacedAccounts', request);
                    AccountClosed();
                    setRemoved(true);
                    setClose(true);
                }
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const AccountClosed = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "dbt_No": props.dbtNumber,
                    "dbt_Client": props.clientId,
                    "user_Id": UserName
                };
                let loadHistory = {
                    "updatedBy": UserName,
                    "dbt_No": props.dbtNumber,
                    "operationType": 2,//Close Account
                    "transactionText": `"Closing Note: " ${CloseNote}, "Closing Balance:" ${closeAcctBal}, "Closing Reason:" ${closeAcctReason} `
                };
                await usePost<IPlacedAccountContract[]>('RollUpdateClosed', request)
                props.onEroll();
                setRemoved(true);
                setshowUndoButton(!showUndoButton);

                await usePost("Log/TransactionHistoy", loadHistory)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()

    }

    const undoCloseandRemove = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "DBTNumber": props.dbtNumber,
                    "isCloseRemove": false
                }
                await usePost("Account/PlacedAccounts?DBTNumber=", request).then((r) => {
                    if (r.status == 400) {
                        dispatch({ type: GlobalStateAction.Error, error: r.statusText });
                    }
                });
                await useDelete(`RollUpdateCloseUndo?DBTNumber=${props.dbtNumber}`);
                props.onEroll();
                setshowUndoButton(false);
                setUndoClose(true);
                undoAdjustment(principalAmount);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const undoAdjustment = (adjustmentAmount: number) => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "Dbt_No": props.dbtNumber,
                    "AdjustmentAmount": adjustmentAmount,
                    "AdjustmentType": "Principal Payment"
                }
                await usePost<any>("Account/UndoAdjustment", request);
                props.onSubmit();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <Button id="Inquiry_closeAccount" variant="contained" onClick={() => { setOpen(true); PaymentAmountValidation(); }} className={classes.button}
                startIcon={<AccountBalanceIcon />}
                disabled={!canCloseAccount || handleDisable || CloseDisable || Removed || props.closeSts || props.BlockAwaitAccess} color="primary"
                style={{ width: 175}}>
                {Removed ? "Account Closed" : "Close Account"}
            </Button>
            {(Removed) && (
                <IconButton
                    id="Inquiry_UndoCloseAccount"
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={() => {
                        setshowUndoButton(!showUndoButton);
                        setRemoved(false);
                        undoCloseandRemove();
                        setUndoClose(true);
                    }}
                    disabled={props.BlockAwaitAccess}
                    style={{ marginTop: "-1cm" }} >
                    <ReplayIcon />
                </IconButton>
            )}
            <Snackbar className={classes.snackbar} open={close} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className={classes.snackbarfont}>
                    Account Closed Successfully!
                </Alert>
            </Snackbar>
            <Snackbar className={classes.snackbar} open={undoClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className={classes.snackbarfont}>
                    Undo Close Account Successfully!
                </Alert>
            </Snackbar>
            <Dialog open={open} keepMounted onClose={handleClose} maxWidth={'md'}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" className={classes.titleheader}>
                        CLOSE ACCOUNT
                    </Typography>
                </DialogTitleHeader>
                <DialogTitle>
                    {!isValid ?
                        <Alert severity="error">Please enter a payment amount that is less than the amount due.</Alert> : null}
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-slide-description">
                        <CssBaseline />
                        <Container maxWidth="sm">
                            <Grid item xs={12} >
                                <Box p={1} width="80%" flexShrink={0} bgcolor="white" className={classes.root2} >
                                    <TextField
                                        id="I_CA_Description"
                                        placeholder="Why is the account being Closed?"
                                        size="medium"
                                        multiline
                                        rows={6}
                                        value={CloseNote} onChange={handleReasonNote}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        className={classes.description}
                                    />
                                </Box>
                                <Grid item xs={5} style={{ marginLeft: "2.5cm" }}>
                                    <form style={{ marginTop: "-4.5cm" }}>
                                        <CurrencyInput
                                            className={classes.inputField}
                                            id="I_CA_Balance"
                                            placeholder="$ Current Balance Amount in Your System"
                                            value={closeAcctBal}
                                            prefix='$'
                                            decimalsLimit={2}
                                            onValueChange={handleOnValueChange}
                                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                                            onKeyPress={preventMinus}
                                            decimalScale={2}
                                            maxLength={13}
                                        />
                                    </form>
                                    <FormControl component="fieldset" style={{ marginLeft: "7cm", marginTop: "10px" }}>
                                        <RadioGroup id="I_CA_Reason" aria-label="reason" name="reason" value={closeAcctReason} onChange={handleReasonChange}>
                                            <FormControlLabel value="Paid" control={<Radio />} label="Paid" />
                                            <FormControlLabel value="Bankruptcy" control={<Radio />} label="Bankruptcy" />
                                            <FormControlLabel value="Disputing" control={<Radio />} style={{ marginLeft: "5cm", marginTop: "-2.2cm" }} label="Disputing" />
                                            <FormControlLabel value="Other" control={<Radio />} style={{ marginLeft: "5cm" }} label="Other" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginLeft: "-3cm" }}>
                    <Button id="I_CA_Confirm" variant="contained" className={classes.confirmButton}
                        style={{ backgroundColor: !inputVal || !isValid || !isReasonValid ? "#e0e0e0" : "green" }}
                        onClick={() => { setOpen(false); setClicked(!clicked); setshowUndoButton(!showUndoButton); CloseAccount(); emptyCheck(); }}
                        disabled={!inputVal || !isValid || !isReasonValid}>
                        Confirm
                    </Button>
                    <Button id="I_CA_Close" variant="outlined" className={classes.cancelButton}
                        onClick={() => { handleClose(); setcloseAcctBal(''); }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}

export default CloseAccount


