import DateFnsUtils from '@date-io/date-fns';
import {
  Button, CircularProgress, FormControl, Grid, IconButton, makeStyles, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import clearIcon from '../../images/buttonIcons/clearIcon.svg';
import { IGetUsers } from "../../models/UploadLog/GetUsersList";
import { IGetLoginHistory } from '../../models/UserLoginHistory/GetLoginHistory';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 290,
  },
  container: {
    marginTop: "2cm",
    alignItems: "center"
  },
  actions: {
    width: "100%"
  },
  buttons: {
    borderRadius: 20,
  },
  textField: {
    marginRight: theme.spacing(1),
    marginLeft: '8px',
    width: 330,
    marginTop: "17px",

  },
  table: {
    minWidth: 700
  },
  grid: {
    padding: '8px'
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid gray'
  },
  filter: {
    marginTop: '0px',
  },
  root: {
    flexGrow: 1,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  progressBar: {
    height: '6px'
  },
  Title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  snackbar: {
    color: 'black',
    marginTop: '-0.5cm',
  },
  snackbarfont: {
    fontSize: 16,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tablebody: {
    maxHeight: 538
  }
}))

const UserLoginHistory = () => {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();

  const [loginHistory, setloginHistory] = useState<IGetLoginHistory[]>([]);
  const [SelectActiveUser, setSelectActiveUser] = useState<any>();
  const [userList, setuserList] = useState<IGetUsers[]>([]);

  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [totalRecords, setTotalRecords] = useState<number>();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [orderBy, setOrderBy] = React.useState(" ");
  const [order, setOrder] = React.useState("asc");

  const [selectedFirstDate, setSelectedFirstDate] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [ClearTrigger, setClearTrigger] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [mapped, setMapped] = useState(false);
  const [open, setOpen] = useState(false);

  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy"
  const usersLoading = userList.length === 0;

  const handleFirstDateChange = (date: Date | null) => {
    setStartDate(date);
    setSelectedFirstDate(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setClearTrigger(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await useFetch<IGetUsers[]>("UploadLog/UserGet")
        const GetUsers = response.data
        setuserList(GetUsers);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }, [])

  function onUsersSelect(event, value) {
    setSelectActiveUser(value);
    setSelectedUser(true);
    setPage(0);
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const response = await useFetch<IGetUsers[]>("UploadLog/UserGet?id=" + value.id)
        setuserList(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  useEffect(() => {
    getUserLoginListAsync();
  }, [rowsPerPage, page])

  function ClearUserLoginHistoryAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      try {
        let request = {
          "startdate": '',
          "enddate": '',
          "userid": '',
          "rowsPerPage": rowsPerPage,
          "startingRecordNumber": (page * rowsPerPage) + 1
        };
        const response = await usePost<{ userLoginHistories: IGetLoginHistory[], qty: number }>("UserLoginHistory/GetLoginHistory", request)
        const data = response.data.userLoginHistories.map((a) => {
          return { ...a, username: a.username }
        })
        setloginHistory(data);
        setMapped(!data.length ? true : false);
        setTotalRecords(response.data.qty);
        resetValues();
        setShowProgressBar(false);
        setClearTrigger(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })();
  }

  function resetValues() {
    setSelectedFirstDate(false);
    setSelectedEndDate(false);
    setSelectedUser(false);
    setPage(0);
  }

  const handleTrigger = () => {
    getUserLoginListAsync();
    setOpen(true);
  }

  async function getUserLoginListAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setShowProgressBar(true);
    try {
      let request = {
        "rowsPerPage": rowsPerPage,
        "startdate": (selectedFirstDate ? StartDate : ''),
        "enddate": (selectedEndDate ? EndDate : ''),
        "userid": (selectedUser ? (SelectActiveUser === null ? "" : SelectActiveUser.id) : ''),
        "startingRecordNumber": (page * rowsPerPage) + 1
      };
      const response = await usePost<{ userLoginHistories: IGetLoginHistory[], qty: number }>("UserLoginHistory/GetLoginHistory", request)
      const data = response.data.userLoginHistories.map((a) => {
        return { ...a, username: a.username }
      })
      setloginHistory(data);
      setMapped(!data.length ? true : false);
      setTotalRecords(response.data.qty);
      setShowProgressBar(false);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const headCells = [
    { id: "fullusername", numeric: false, disablePadding: false, label: "USER", sortable: true },
    { id: "username", numeric: false, disablePadding: false, label: "EMAIL", sortable: true, align: "center" },
    { id: "logindatetime", numeric: false, disablePadding: false, label: "LOGIN DATE", sortable: true, alignItem: "left" },
    { id: "logindatetime", numeric: false, disablePadding: false, label: "LOGIN TIME", sortable: true, alignItem: "center" },
  ];

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
              style={{ background: "#007FFF", color: "white", fontSize: "120%" }} >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ alignItems: `${headCell.alignItem}` }}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setShowProgressBar(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Grid container className={classes.grid} style={{ backgroundColor: 'whitesmoke' }}>
          <Grid item xs={5} className={classes.Title} >
            <Typography variant="h4" gutterBottom style={{ color: "blue" }}>
              <b> USER LOGIN HISTORY</b>
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.Title}>
            {StartDate > EndDate ? <Typography variant='h6' style={{ color: 'red' }}>Invalid End Date.</Typography> : ""}
          </Grid>
          <Snackbar open={open} className={classes.snackbar} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" className={classes.snackbarfont}>
              Filter Successfully Triggered!
            </Alert>
          </Snackbar>
          <Snackbar open={ClearTrigger} className={classes.snackbar} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" className={classes.snackbarfont}>
              Filter Cleared Successfully.
            </Alert>
          </Snackbar>
          <Grid container className={classes.maingrid} justify='space-evenly'>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    label="Start Date"
                    format={DateFormat}
                    margin="normal"
                    className={classes.textField}
                    id="USR_StartDate"
                    style={{ marginTop: "13px", }}
                    value={StartDate}
                    onChange={handleFirstDateChange}
                    inputVariant="outlined"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={5} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    label="End Date"
                    format={DateFormat}
                    margin="normal"
                    className={classes.textField}
                    id="USR_EndDate"
                    style={{ marginTop: "13px", }}
                    value={EndDate}
                    onChange={handleEndDateChange}
                    inputVariant="outlined"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={5} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                  id="USR_SelectUsers"
                  autoComplete autoHighlight
                  options={userList} onChange={onUsersSelect}
                  getOptionLabel={(option) => option.firstname + " " + option.lastname}
                  loading={usersLoading}
                  style={{ width: '400px', marginTop: '-5px' }}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span style={option.isActive === true ? { color: "green" } : { color: "#FA8072" }}> {option.fullname}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Users" margin="normal" variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {usersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={6} sm={5} md={3} className={classes.filter}>
                <Button id="USR_Filter_btn" className={classes.buttons} style={{ right: "5%", marginTop: '20px' }} onClick={() => { handleTrigger(); }} startIcon={<FilterAltIcon />} variant="contained" color="primary" disabled={StartDate > EndDate}>
                  Filter
                </Button>
                <Button id="UpLog_Filter_Button" className={classes.buttons} startIcon={<img src={clearIcon} alt="Icon for clear filter button"/>} style={{ marginLeft: "50px", marginTop: '20px' }}
                  onClick={() => { ClearUserLoginHistoryAsync(); }} variant="contained" color="primary" >
                  Clear Filter
                </Button>
              </Grid>
            </Hidden>

            <Hidden lgUp>
              <Grid item xs={6} sm={5} md={2}>
                <IconButton id="USR_FilterIcon_btn" aria-label="Filter" onClick={() => { handleTrigger(); }} style={{ color: 'blue' }} className={classes.filter} disabled={StartDate > EndDate}>
                  <FilterListIcon style={{ fontSize: 35 }} />
                </IconButton>
                <IconButton id="UpLog_Filter_IconButton" aria-label="Filter" onClick={() => { ClearUserLoginHistoryAsync(); }} style={{ color: 'blue' }} className={classes.filter}>
                  <ClearRoundedIcon style={{ fontSize: 35 }} />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} component={Paper} style={{ marginTop: '10px' }}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table aria-label="customized table" size="small" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(loginHistory, getComparator(order, orderBy)).map(
                    (row) => {
                      return (
                        <TableRow hover style={{ height: 49.8, fontSize: 17 }} >
                          <TableCell component="th" scope="row" >
                            {row.fullusername}
                          </TableCell>

                          <TableCell >
                            {row.username}
                          </TableCell>

                          <TableCell  >
                            {moment(row.logindatetime).format(state.GlobalUtils?.settingValue)}
                          </TableCell>

                          <TableCell >
                            {moment(row.logindatetime).format('HH:mm:ss')}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
              {mapped === true ?
                < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                  No records to display..
                </Typography>
                : ""}
            </TableContainer>
            <TablePagination
              id="USR_TablePagination"
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={totalRecords === undefined ? 0 : totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
          <Footer />
        </Grid>
      </div>
    </React.Fragment >
  )
}

export default UserLoginHistory
