import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { ITransactionHistory } from '../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IDebtor } from '../../models/Reports/IDebtor';
import { useGlobalState } from '../../store/GlobalStore';
import { Scrollbars } from 'react-custom-scrollbars';
import { RollSpinner } from '../GlobalStyles/Loading';

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        marginRight: "2px",
        marginTop: "0%",
        width: "15%",
    },
    root: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px"
    },
}))

const ReportAccountHistory: React.FC<{ debtors: IDebtor, histories: ITransactionHistory[], mapped: boolean }> = (props) => {
    const { debtors, histories, mapped } = props
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();

    return (
        <React.Fragment >
            <h3 style={{ marginTop: "-20px", color: "blue" }}> Notes</h3>
            <Box p={1} width="100%" flexShrink={0} bgcolor="white" className={classes.root} >
                <Scrollbars autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={10}
                    autoHeight
                    autoHeightMin={170}
                    autoHeightMax={150}
                    style={{ width: "100%" }}>
                    {histories.length > 0 ?
                        histories.map((Histories) => {
                            if (Histories.activityType !== "0") {
                                return (
                                    <p style={Histories.activityType === "T" ? { color: "#009900", fontSize: 17 } : { color: "black", fontSize: 16 }}>
                                        {moment(Histories.activityDt).format(state.GlobalUtils?.settingValue)}
                                        &nbsp;{Histories.activity}
                                    </p>
                                )
                            } else {
                                return (
                                    < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                        No Records Available
                                    </Typography>
                                )
                            }
                        })
                        : <RollSpinner />
                    }
                </Scrollbars>
            </Box>
        </React.Fragment>
    )
}

export default ReportAccountHistory
