import { Box, Checkbox, FormControlLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AddIcon from '@mui/icons-material/Add';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CreateIcon from '@mui/icons-material/Create';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { IFilter } from '../../models/Reports/IFilter';
import { IReportField } from '../../models/Reports/IReportField';
import { IReportOperator } from '../../models/Reports/IReportOperator';
import { IReportsList } from '../../models/Reports/ReportsList';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import FilterReport from './FilterReport';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
                marginBottom: "0%",
                margin: theme.spacing(2),
                width: '25ch',
                display: "flex",
            },
        },
        formControl: {
            width: 200,
            position: "absolute",
            marginTop: "14px"
        },
        textField: {
            marginLeft: theme.spacing(8),
            width: 210,
            marginTop: "14px"
        },
        placeholder: {
            width: 200
        },
        actions: {
            width: "100%",
            marginTop: "5px",
            marginLeft: '5px',
            maxHeight: 500,
            overflowX: 'hidden',
            overflowY: 'auto'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        snackbar: {
            color: 'black',
            marginTop: '-0.5cm',
        },
        snackbarfont: {
            fontSize: 16,
        },
        reportname: {
            margin: 8,
            width: '100%',
            borderBottom: '2px solid blue',
            borderRadius: '10px',
            boxShadow: '0px 0px 2px 0px rgba(103, 128, 159, 1)',
            marginTop: '25px'
        },
        checkboxText: {
            marginLeft: '3px',
            marginTop: '5px'
        },
        createReport: {
            borderRadius: '20px',
            marginBottom: "0.5cm",
            float: 'right',
            marginLeft: '25px'
        },
        filterbutton: {
            borderRadius: '20px',
            float: 'right',
            marginBottom: "0.5cm"
        },
        editbutton: {
            backgroundColor: 'green',
            "&:hover": {
                backgroundColor: 'green'
            }
        }
    })
);

const EditReport: React.FC<{
    reportRow?: IReportsList, clients?: any, getReports?: () => void, reportId?: any,
    states?: any, statuses?: any, phases?: any, isValid?: boolean, operators?: IReportOperator[], fieldLists?: IReportField[]
}> = (props) => {
    const [open, setOpen] = useState(false);
    const { reportId, reportRow, clients, states, statuses, phases } = props;
    const [ReportName, setReportName] = useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useRowStyles();
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;
    const [isDefault, setIsDefault] = useState(false);
    const [isEdit, setIsEdit] = useState(reportId != -1);
    const [defaultReport, setDefaultReport] = useState(false);
    const [Filters, setFilters] = useState<IFilter[]>([{ field_id: null, operator_id: null, value: "", index: 0 }]);
    const [error, setError] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [show, setShow] = React.useState(false);
    const [filter, setFilter] = React.useState(false);
    const [disable, setDisable] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);

    useEffect(() => {
        if (state?.userAccessContext?.role === 2) {
            setDisable(false);
        }
        else if (state?.userAccessContext?.role === 3) {
            setDisable(false);
        }
        else if (reportRow?.default_report === 0) {
            setDisable(false);
        }
        else {
            setDisable(true);
        }
    }, []);


    async function getFiltersForTheReportAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            let request = {
                "report_id": reportId,
                "user_id": UserName,
                "global_admin": (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ? true : false)
            };
            const response = await usePost<any>("ReportFieldsList", request);
            setCustomFilters(response.data.reportFilterResults);
            setReportName(response.data.reportFilterResults[0]["name"]);
            setIsDefault(response.data.reportFilterResults[0]["default_report"]);
            setOpen(true);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    function setCustomFilters(filters) {
        let newFilters = [];
        for (var i = 0; i < filters.length; i++) {
            let filter = {
                "field_id": filters[i].field_id,
                "operator_id": filters[i].operator_id,
                "value": filters[i].value,
                "index": i
            }
            newFilters.push(filter);
        }
        setFilters(newFilters);
    }

    async function createReportAsync() {
        if (areFiltersNotNull()) {
            dispatch({ type: GlobalStateAction.Busy })
            setShowProgressBar(true);
            try {
                let request = {
                    "name": ReportName,
                    "user_id": UserName,
                    "filters": Filters,
                    "date_created": (new Date()).toISOString(),
                    "report_id": reportId,
                    "default_report": isDefault
                }
                const response = await usePost<any>("CreateReportWithFilters", request)
                props.getReports();
                setShow(true);
                handleClose();
                setShowProgressBar(false);
            } catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        } else {
            alert("Please Fill all the data");
            setShowProgressBar(false);
        }
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShow(false);
    };

    const closeFilterSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setFilter(false);
    };

    const handleChange = (event) => {
        setIsDefault(event.target.checked);
    };

    const handleClickOpen = () => {
        if (reportId != -1) {
            getFiltersForTheReportAsync();
        }
    };

    const handleClose = () => {
        setFilters([{ field_id: null, operator_id: null, value: "", index: 0 }]);
        setReportName("");
        setOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const areFiltersNotNull = () => {
        if (!ReportName)
            return false;
        for (let i = 0; i < Filters.length; i++) {
            if (!Filters[i]["field_id"] || !Filters[i]["operator_id"] || !Filters[i]["value"])
                return false;
        }
        return true;
    }

    const addFilter = () => {
        let newFilters = _.cloneDeep(Filters);
        let lastElement = newFilters[newFilters.length - 1];
        newFilters.push({ field_id: -1, operator_id: null, value: "", index: lastElement.index + 1 });
        setFilters(newFilters);
        setFilter(true);
    }

    const handleDeleteFilter = (index) => {
        let newFilters = _.cloneDeep(Filters);
        if (Filters.length == 1)
            return;
        newFilters = newFilters.filter(filter => filter.index != index);
        newFilters.forEach((f, indexnum) => {
            f.index = indexnum
        });
        setFilters(newFilters);
    }

    const onChangeFilter = (index, filter) => {
        let newFilters = _.cloneDeep(Filters);
        newFilters[index] = filter;
        setFilters(newFilters);
    }

    const handleReportNameChange = (event) => {
        setReportName(event.target.value);
    }

    const HandleReportNameVal = (e) => {
        const newValue = e.target.value;
        if (!newValue.match(/[~`!@#^&*()+=%<>?.,:;{}|\\$'"]/)) {
            setError("");
            setIsValid(false);
            setReportName(newValue);
        } else {
            setError("please enter characters and numbers only");
            setIsValid(true);
        }
    };

    return (
        <React.Fragment>
            <div>
                <Button startIcon={<BorderColorIcon />} className={classes.editbutton} style={{ borderRadius: 20 }}
                    variant="contained" disabled={isEdit ? disable : null} color="primary" onClick={handleClickOpen}>
                    Edit
                </Button>
                <Snackbar className={classes.snackbar} open={show} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={3000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="success" className={classes.snackbarfont}>
                        Report Updated Successfully!
                    </Alert>
                </Snackbar>
                <Snackbar className={classes.snackbar} open={filter} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={3000} onClose={closeFilterSnackbar}>
                    <Alert onClose={closeFilterSnackbar} severity="success" className={classes.snackbarfont}>
                        Report Filter Added Successfully!
                    </Alert>
                </Snackbar>
            </div>
            <div>
                <Dialog
                    fullScreen={fullScreen} open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: 15 } }}
                    aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'md'} >
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                            EDIT REPORT
                        </Typography>
                    </DialogTitleHeader>
                    <DialogContent >
                        <Grid item xs={12}>
                            <TextField id="CR_Name"
                                className={classes.reportname}
                                onChange={(e) => { handleReportNameChange(e); HandleReportNameVal(e); }}
                                placeholder="Enter Report Name" fullWidth margin="normal" variant="outlined"
                                value={ReportName}
                                InputLabelProps={{
                                    shrink: true,
                                }} required helperText={error} error={!!error} inputProps={{ maxlength: 100 }} />
                            {state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ?
                                <FormControlLabel
                                    control={<Checkbox checked={isDefault} onChange={handleChange}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />}
                                    className={classes.checkboxText}
                                    label="Default report? (visible to all users)"
                                />
                                : null}
                            <div className={classes.actions}>
                                {Filters.map((filter) => {
                                    return (
                                        props.fieldLists?.length > 0 && props.operators?.length > 0 ?
                                            <FilterReport key={'id' + filter.field_id + 'index' + filter.index} fieldsList={props.fieldLists} OperatorsList={props.operators} filter={filter} handleDeleteFilter={handleDeleteFilter}
                                                onChangeFilter={onChangeFilter} states={props.states} statuses={props.statuses} phases={props.phases} clients={props.clients}></FilterReport> : null
                                    )
                                })}
                            </div>
                            <Box p={2} flexShrink={0} bgcolor="white">
                                <Button autoFocus color="primary" variant="contained" disabled={isValid || !ReportName}
                                    onClick={() => { createReportAsync(); }}
                                    className={classes.createReport} startIcon={<CreateIcon />}>
                                    Update Report
                                </Button>
                                <Button id="CR_AddFilter" autoFocus color="primary" variant="contained"
                                    className={classes.filterbutton}
                                    startIcon={<AddIcon />} onClick={addFilter}>
                                    add Filter
                                </Button>
                            </Box>
                        </Grid>

                    </DialogContent>
                </Dialog>
            </div>
        </React.Fragment >
    );
}

export default EditReport