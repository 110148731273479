import axios from 'axios'

const baseURL = 'https://webviewrpapi.azurewebsites.net/api/'
const baseURLDev = 'https://localhost:44316/api/'

export const instance = axios.create({
  baseURL: baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})
