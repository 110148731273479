import {
  Avatar, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, TextField, Toolbar, Typography
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, lighten, Theme } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import CloneUser from "../../components/GlobalAdmin/Users/CloneUser";
import GA_CreateUser from '../../components/GlobalAdmin/Users/GA_CreateUser';
import GA_EditUser from '../../components/GlobalAdmin/Users/GA_EditUser';
import ImpersonateUser from "../../components/GlobalAdmin/Users/ImpersonateUser";
import InviteUser from '../../components/GlobalAdmin/Users/InviteUser';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import LightTooltip from "../../components/GlobalStyles/LightTooltip";
import DeleteUser from "../../components/Settings/DeleteUser";
import GlobalAdmin from '../../images/users/ClientAdmin.svg';
import FileAdmin from '../../images/users/FileAdmin.svg';
import ClientAdmin from '../../images/users/GlobalAdmin.svg';
import RegularUser from '../../images/users/RegularUser.svg';
import SuperAdmin from '../../images/users/SuperAdmin.svg';
import { IGetAllDomains } from "../../models/accesscontrol/IGetAllDomains";
import { IInviteCount } from "../../models/accesscontrol/IInviteCount";
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    formControl: {
      minwidth: 300,
      position: "absolute",
    },
    clientAdmin: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    globalAdmin: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    superAdminColor: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    regularUser: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    fileAdmin: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid gray',
    },
    table: {
      minWidth: 700,
    },
    h2: {
      marginLeft: '1%',
      marginTop: '40px',
    },
    grid: {
      padding: '8px'
    },
    maingrid: {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    div: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    root1: {
      width: '100%',
    },
    headerFontStyle: {
      "fontFamily": `"Montserrat", Bold`,
      "fontSize": 18,
      "color": "#404040",
      "background": "#FFFFFF 0% 0% no-repeat padding-box",
      "borderRadius": "5px 5px 0px 0px",
      "opacity": "1"
    },
    progressBar: {
      height: '6px'
    },
    fab: {
      backgroundColor: 'transparent',
      fontSize: '15px',
      height: '18px',
      width: '35px',
      marginRight: theme.spacing(1),
      color: 'white'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    domainStyle: {
      borderRadius: '10px',
      border: '2px black solid',
      width: '100%',
      height: '60px',
    },
    searchBar: {
      borderRadius: "10px",
      border: '2px black solid',
      width: '100%',
      height: '60px',
      fontSize: 16,
      textIndent: '10px',
    },
    boxStyle: {
      height: '5px', marginTop: '-20px', marginLeft: '250px', borderRadius: '50px'
    },
    TextHighlight: {
      color: '#000000',
      fontWeight: 'bold',
      backgroundColor: 'yellow',
      fontSize: '16px',
    },
    TextNormal: {
      color: 'black',
      fontSize: 16
    },
    tablebody: {
      maxHeight: 545
    },
    invitationsend: {
      marginLeft: "10px",
      marginTop: '-8px'
    },
    invitationbutton: {
      borderRadius: 20,
      background: "#FF6666",
      color: "white",
      marginRight: '10px',
      marginTop: '-8px',
      '&:hover': {
        background: "#FF6666",
      }
    }
  })
);

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected, rowCount
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "firstName", disablePadding: false, label: "FIRST NAME", sortable: true },
    { id: "lastName", disablePadding: false, label: "LAST NAME", sortable: true },
    { id: "userName", disablePadding: false, label: "EMAIL", sortable: true },
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "Actions", disablePadding: false, label: "ACTIONS" },
    { id: "", disablePadding: false, label: "", sortable: false, order: false },
    { id: "Delete", disablePadding: false, label: "DELETE", sortable: false, order: false }
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ background: "#007FFF", color: "white" }} padding="checkbox">
          <Checkbox style={{ color: 'white' }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all Users' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ background: "#007FFF", color: "white", fontSize: "120%" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
  }),
);

export default function User() {
  const { state, dispatch } = useGlobalState();
  const [usersListResult, setUsersListResult] = useState<IUserInfo[]>([]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("userName");
  const [activeUsers, setactiveUsers] = useState<IGetAllDomains[]>([]);
  const classes = useRowStyles();
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [recordsPerPage, setRecordsPerPage] = useState<number>(25);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [InviteCount, setInviteCount] = useState<IInviteCount>({});
  const [searchText, setSearchText] = useState<string>('');
  const [TextSearch, setTextSearch] = useState(false);
  const [ProgressBar, setshowProgressBar] = useState(true);


  useEffect(GetUsers(), [activeUsers, pageNumber, order, orderBy, recordsPerPage])
  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <Toolbar style={{ borderRadius: '20px' }}
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">

          </Typography>
        )}
        {numSelected > 0 ? (
          <React.Fragment>
            <LightTooltip title="Bulk Invite">
              <IconButton aria-label="Bulk Invite" onClick={(_) => { handleClickOpen(); }}>
                <SendIcon />
              </IconButton>
            </LightTooltip>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogContent>
                <DialogContentText>
                  Are you sure, you want to Sent Invite to {numSelected} user ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={BulkInviteAsync} color="primary" autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        ) : (
          <div></div>
        )}
      </Toolbar>
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPageNumber(0);
    setshowProgressBar(true);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = usersListResult.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  function BulkInviteAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        let request = {
          "userIds": selected
        };
        const response = await usePost<any>("User/BulkInvite", request);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })();
  }

  const handleRowClicked = (event) => { };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleSaveWithUserInfo = (userInfo: IUserInfo) => {
    getUsersAsync();
  };

  const handleSave = () => {
    getUsersAsync();
  };

  const handleImpersonate = () => {
  };

  const handleDeleteSuccess = () => {
    getUsersAsync();
  };

  const handlePageChange = (event, page) => {
    setshowProgressBar(true);
    setPageNumber(page);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecordsPerPage(+event.target.value);
    setPageNumber(0);
  };



  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const ActiveUsersList = await useFetch<IGetAllDomains[]>("User/GetAllDomains")
        setactiveUsers(ActiveUsersList.data);
        setshowProgressBar(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
        setshowProgressBar(false);
      }
    })()
  }, [])

  function onDomainSelect(event, value) {
    if (value != null) {
      setSearchText(value["domains"]);
    }
    else {
      setSearchText("");
    }
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const response = await useFetch<IGetAllDomains[]>("User/GetAllDomains")
        setactiveUsers(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  function getUserInitials(name: string): string {
    const bySpace = name.split(' ')
    if (bySpace.length > 1) {
      return bySpace[0][0] + bySpace[1][0]
    } else return name.slice(0, 2).toUpperCase()
  }

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const UserInviteCount = await useFetch<IInviteCount>("User/GetInvitationFirstLoggOnCount")
        setInviteCount(UserInviteCount.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid} >
          <Grid item xs={3} sm={3}>
            <Typography variant="h4" gutterBottom style={{ color: "blue", textAlign: 'left' }}>
              <b> USERS LIST </b>
            </Typography>
          </Grid>
          <Grid item xs={3} sm={5}>
            <Box alignItems="center" display="flex" justifyContent="center" >
              <Box display="flex" p={1}>
                <Typography variant="h6" className={classes.invitationsend}>
                  Invitation Sent
                </Typography>
              </Box>
              <Box display="flex" p={1}>
                <Button className={classes.invitationbutton}
                  variant="contained" startIcon={<EmailIcon />} >
                  {InviteCount.invitationSentDate}
                </Button>
              </Box>
              <Box display="flex" p={1}>
                <Typography variant="h6" className={classes.invitationsend}>
                  Invitation Accepted
                </Typography>
              </Box>
              <Box display="flex" p={1}>
                <Button className={classes.invitationbutton} startIcon={<DoneAllIcon />}>
                  {InviteCount.firstLoggedOn}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} sm={4} direction="column">
            <Box className={classes.boxStyle} display="flex" p={1}>
              <EnhancedTableToolbar numSelected={selected.length} />
            </Box>
            <Box alignItems="flex-end" display="flex" justifyContent="flex-end" >
              <Box display="flex" p={1}>
                <GA_CreateUser onSave={handleSave} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <input value={searchText}
              type='text' placeholder="Search Users (First Name, Last Name or Email)"
              className={classes.searchBar}
              onChange={e => setSearchText(e.target.value)}
              onKeyPress={event => { if (event.key === 'Enter') { getUsersAsync(); } }}
              maxLength={255}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete style={{ borderRadius: '50px', paddingLeft: '5px' }}
              id="free-solo-demo"
              autoComplete autoHighlight
              options={activeUsers} onChange={onDomainSelect}
              getOptionLabel={(option) => option.domains}
              renderInput={(params) => (
                <TextField className={classes.domainStyle} placeholder="Select Domain"
                  {...params} variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }} />
              )}
            />
          </Grid>
          <Grid component={Paper} item xs={12} style={{ marginTop: '0.5cm' }}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table aria-label="customized table" size="small" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={usersListResult.length}
                />
                <TableBody>
                  {usersListResult.map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow key={index} hover selected={isItemSelected} onClick={(event) => handleRowClicked(event)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.global_admin === 3 ?
                              <AvatarTooltip title="Super Admin">
                                <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                              </AvatarTooltip>
                              : row.global_admin === 2 ?
                                <AvatarTooltip title="Global Admin">
                                  <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                </AvatarTooltip>
                                : row.global_admin === 1 ?
                                  <AvatarTooltip title="Client Admin">
                                    <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                  </AvatarTooltip>
                                  : row.global_admin === 0 ?
                                    <AvatarTooltip title="Regular User">
                                      <Avatar className={classes.regularUser} src={RegularUser}>{getUserInitials(row.userName)}</Avatar>
                                    </AvatarTooltip>
                                    : <AvatarTooltip title="File Admin">
                                      <Avatar className={classes.fileAdmin} src={FileAdmin}>{getUserInitials(row.userName)}</Avatar>
                                    </AvatarTooltip>
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className={`${searchText === "" ? classes.TextNormal : row.firstName.includes(searchText.charAt(0).toUpperCase() + searchText.slice(1)) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.firstName}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className={`${searchText === "" ? classes.TextNormal : row.lastName.includes(searchText.charAt(0).toUpperCase() + searchText.slice(1)) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.lastName}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span className={`${searchText === "" ? classes.TextNormal : row.userName.includes(searchText) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.userName}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <InviteUser userRow={row} onInvite={handleSave} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                          </TableCell>
                          <TableCell align="center">
                            <ImpersonateUser userRow={row} onImpersonate={handleImpersonate} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                          </TableCell>
                          <TableCell align="center">
                            <CloneUser userRow={row} onSave={handleSave} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                          </TableCell>
                          <TableCell align="center">
                            <GA_EditUser userRow={row} onSave={handleSaveWithUserInfo} onRemove={handleSave} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                          </TableCell>
                          <TableCell>
                            <DeleteUser userRow={row} onDelete={handleDeleteSuccess} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
              {TextSearch && !usersListResult.length ?
                <Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                  No records to display..
                </Typography> : null}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={totalRecords}
              rowsPerPage={recordsPerPage}
              page={pageNumber}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
          <Footer />
        </Grid>
      </div>
    </React.Fragment>
  )
  function GetUsers(): React.EffectCallback {
    return () => {
      getUsersAsync();
    };
  }

  function getUsersAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setshowProgressBar(true);
      try {
        let request = {
          "rowsPerPage": recordsPerPage,
          "searchParameters": searchText,
          "startingRecordNumber": (searchText !== "" ? (pageNumber * 0) + 1 : (pageNumber * recordsPerPage) + 1),
          "orderBy": orderBy,
          "orderDirection": order === "desc" ? 1 : 0
        }
        const response = await usePost<{ usersList: IUserInfo[], noOfUsers: number }>("User/GetAllUsers", request);
        const dat = response.data.usersList.map((a) => {
          return { ...a, id: a.id }
        })
        setTextSearch(true);
        setTotalRecords(response.data.noOfUsers);
        setUsersListResult(response.data["usersList"]);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })();
  }
}