import * as React from 'react';
import { IRollbackNotificationList } from '../../../models/NotificationRollBack/IRollbackNotificationList';
import { Button, createStyles, makeStyles, Snackbar, Theme, Typography } from '@material-ui/core';
import { useState } from 'react';
import { usePost } from '../../../utils/apiHelper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import moment from 'moment';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import RotateRightIcon from '@mui/icons-material/RotateRight';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootHidden: {
            display: "none"
        },
        button: {
            borderRadius: "20px",
            marginLeft: '-15px'
        },
        yesbutton: {
            backgroundColor: 'green',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            }
        },
        nobutton: {
            background: "red",
            borderRadius: "20px",
            color: 'white',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        snackbar1: {
            color: 'black',
            marginTop: '-0.5cm',
        },
        snackbarFont: {
            fontSize: 16,
        },
    })
);

const NotificationTable: React.FC<{ row: IRollbackNotificationList, onRollBack: () => void }> = (props) => {
    const { row } = props;
    const classes = useRowStyles();
    const { state, dispatch } = useGlobalState();
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [hide, sethide] = useState(row.permenant_Hidden);
    let CreatedDate = moment(row.createdDate).format(state.GlobalUtils?.settingValue)
    const [open, setOpen] = useState(false);


    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    function openConfirmDialog() {
        setDialogOpen(true);
    }

    const RollBack = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "userNotificationId": row.userNotificationId,
                };
                const response = await usePost<IRollbackNotificationList[]>('RollbackNotification', request)
                props.onRollBack();
                sethide(true);
                setOpen(true);
            }
            catch (ex) {
            }
            finally {
                setDialogOpen(false);
            }
        })()
    }

    return (
        <React.Fragment>
            <Button id="NRB_Rollback_btn" startIcon={<RotateRightIcon />} color="primary" variant="contained" onClick={openConfirmDialog} disabled={row.permenant_Hidden ? true : false || hide} className={classes.button} >
                Rollback
            </Button>
            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                open={isDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        ROLLBACK NOTIFICATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="h5" style={{ color: "black" }} gutterBottom>
                            Are you sure, you want to rollback this notification?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="NRB_ok_btn" variant="contained" color="primary" className={classes.yesbutton} onClick={() => { RollBack(); props.onRollBack(); }}>
                        Yes
                    </Button>
                    <Button id="NRB_cancel_btn" onClick={handleCloseDialog} className={classes.nobutton} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} className={classes.snackbar1} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className={classes.snackbarFont}>
                    Message RollBacked Successfully !
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default NotificationTable