import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
//Icons
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { usePost } from '../../utils/apiHelper';
import { Backdrop, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import PuffLoader from 'react-spinners/PuffLoader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(0.5),
            borderRadius: 20,
            fontSize: 15,
            fontWeight: 'bold'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(0),
            color: 'black',
        },
        snackbar: {
            color: 'black',
            marginTop: '-0.5cm',
        },
        snackbarfont: {
            fontSize: 16,
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

const CreateNewFolder = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [name, setName] = useState("");
    const [open, setOpen] = useState(false);
    const { state, dispatch } = useGlobalState();
    const [CName, setCName] = useState<string>();
    const [nameerror, setNameError] = useState("");
    const [inputVal, setInputVal] = useState<any>('');
    const [isDisableCN, setIsDisableCN] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState('CloudContainer');
    const [ProgressBar, setShowProgressBar] = React.useState(false);

    //Alert
    const [isCreate, setIsCreate] = useState(false);
    const [isCreated, setIsCreated] = useState(false);


    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCreate(false);
    };
    const closeSnackbar1 = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCreated(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const CreateNewContainer = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "containerName": CName,
                "containerType": value
            }
            let response = await usePost<any>("File/CreateContainer", request);
            let createContainer = await usePost<any>("File/InsertContainer", request);
            setCName(response.data);
            setCName(createContainer.data);
            if (response.status == 200) {
                setIsCreated(true);
                setShowProgressBar(false);
            }
            if (response.status == 400) {
                dispatch({ type: GlobalStateAction.Error, error: response.statusText })
                setShowProgressBar(false);
            }
        })().catch((err) => {
            if (err.response.status == 500) {
                setIsCreate(true);
                setShowProgressBar(false);
                dispatch({ type: GlobalStateAction.Error, error: err.statusText })
            }
        });
    }

    const HandleFirstNameVal = (e) => {
        const FirstNameVal = e.target.value;
        if (!FirstNameVal.match(/[~!@#%^&*()=+<>,. {};|?:A-Z[\]\\$'"]/)) {
            setNameError("");
            setIsDisableCN(false);
            setName(FirstNameVal); // only set when successful
        } else {
            setNameError("please enter lowercase characters only without white space");
            setIsDisableCN(true);
        }
    };

    const handleChange = (event) => {
        setInputVal(event.target.value);
    };

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={< CreateNewFolderIcon style={{ fontSize: 25 }} />}
                onClick={handleClickOpen}
            >
                New Folder
            </Button>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={100} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Dialog
                fullScreen={fullScreen}
                PaperProps={{ style: { borderRadius: 10 } }}
                open={open}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        CREATE NEW FOLDER
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <TextField autoComplete='off' id="CNF_TextField" label="Name" variant="outlined" onChange={(e) => { handleChange(e); setCName(e.target.value); HandleFirstNameVal(e); }} inputProps={{ maxlength: 50 }} helperText={nameerror} error={!!nameerror}
                        style={{ width: 550, boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)', marginTop: '20px' }} />
                    <FormControl component="fieldset" style={{ marginTop: '25px', }}>
                        <FormLabel component="legend">Select Folder Type</FormLabel>
                        <RadioGroup row value={value} onChange={handleRadioChange}>
                            <FormControlLabel value="CloudContainer" control={<Radio />} label="Cloud Folder" />
                            <FormControlLabel value="PaymentContainer" control={<Radio />} label="Payment Folder" />
                            <FormControlLabel value="AckContainer" control={<Radio />} label="Acknowledgement Folder" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions style={{ marginTop: "15px" }}>
                    <Button id="IM_Cancel_Btn" variant="contained" autoFocus style={{ background: "red", marginRight: "10px", borderRadius: 30 }} onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button id="IM_ok_btn" variant="contained" onClick={(_) => { handleClose(); CreateNewContainer(); }} disabled={!inputVal || isDisableCN} style={{ background: "green", marginRight: "10px", borderRadius: 30, color: 'white' }} color="primary" autoFocus >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar className={classes.snackbar} open={isCreate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className={classes.snackbarfont}>
                    Folder Already Exists.
                </Alert>
            </Snackbar>
            {<Snackbar className={classes.snackbar} open={isCreated} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar1}>
                <Alert onClose={closeSnackbar1} severity="success" className={classes.snackbarfont}>
                    Folder Created Successfully.
                </Alert>
            </Snackbar>}
        </React.Fragment>
    )
}

export default CreateNewFolder
