import * as React from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Box, Grid, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, TableSortLabel, Typography
} from '@material-ui/core';
import CreateNotification from "../../components/GlobalAdmin/GlobalMessage/CreateNotification";
import CssBaseline from '@material-ui/core/CssBaseline';
import EditNotification from '../../components/GlobalAdmin/GlobalMessage/EditNotification';
import DeleteNotification from '../../components/GlobalAdmin/GlobalMessage/DeleteNotification';
import { useEffect, useState } from 'react';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch } from '../../utils/apiHelper';
import { IGlobalMessage } from "../../models/GlobalMessage/IGlobalMessage";
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    div: {
      marginLeft: "30cm",
      marginTop: "4%"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    grid: {
      padding: '6px'
    },
    maingrid: {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    tableBody: {
      maxHeight: 630
    },
    headername: {
      color: "blue",
      textAlign: 'left'
    }
  }),
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "NAME" },
  { id: "start_date", numeric: false, disablePadding: false, label: "START DATE", sortable: true },
  { id: "end_date", numeric: false, disablePadding: false, label: "END DATE", sortable: true },
  { id: "date_created", numeric: false, disablePadding: false, label: "DATE CREATED", sortable: true, marginLeft:'-15px' },
  { id: "", numeric: false, disablePadding: false, label: "ACTIONS", sortable: false },
  { id: "", numeric: false, disablePadding: false, label: "DELETE", sortable: false }
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id} id="GM_TableHeader"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ background: "#007FFF", color: "white", fontSize: "120%" }}
          >
            <TableSortLabel
              id="GM_TableSortLable"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{ marginLeft: `${headCell.marginLeft}` }}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export default function GlobalMessage() {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const [GlobalMessageList, setGlobalMessageList] = useState<IGlobalMessage[]>([]);
  const [totalPages, SetTotalPages] = useState<number>(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date_created");
  const [ProgressBar, setshowProgressBar] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (GlobalMessageList.length <= 0) {
      getGlobalMessageAsync();
    }
  }, [GlobalMessageList]);

  async function getGlobalMessageAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    try {
      const response = await useFetch<IGlobalMessage[]>("GetGlobalMessage");
      const GlobalList = response.data;
      setshowProgressBar(true);
      setGlobalMessageList(GlobalList);
      SetTotalPages(GlobalList.length % 10 == 0 ? GlobalList.length / 10 : (Math.floor(GlobalList.length / 10)) + 1);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
      setshowProgressBar(false);
    }
  }

  const handleSaveWithGlobalMessageInfo = (GlobalMessage: IGlobalMessage) => {
    getGlobalMessageAsync();
  };

  const handleDeleteSuccess = () => {
    getGlobalMessageAsync();
  };

  const handleSave = () => {
    getGlobalMessageAsync();
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Grid container className={classes.grid} style={{ backgroundColor: 'whitesmoke' }}>
          <Grid item xs={4} sm={4}>
            <Typography variant="h4" gutterBottom className={classes.headername}>
              <b> GLOBAL MESSAGE </b>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8}>
            <CreateNotification onSave={handleSave} />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table" size='small' stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(GlobalMessageList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                      (row) => {
                        let startDate = moment(row.start_date).format(state.GlobalUtils?.settingValue);
                        let createdDate = moment(row.date_created).format(state.GlobalUtils?.settingValue);
                        let endDate = moment(row.end_date).format(state.GlobalUtils?.settingValue);
                        return (
                          <StyledTableRow hover >
                            <StyledTableCell component="th" scope="row">{row.name}</StyledTableCell>
                            <StyledTableCell >{startDate}</StyledTableCell>
                            <StyledTableCell >{endDate}</StyledTableCell>
                            <StyledTableCell >{createdDate}</StyledTableCell>
                            <StyledTableCell >
                              <EditNotification Messages={row} onSave={handleSaveWithGlobalMessageInfo} />
                            </StyledTableCell>
                            <StyledTableCell >
                              <DeleteNotification Messages={row} onDelete={handleDeleteSuccess} />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                id="GM_TablePagination"
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={GlobalMessageList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}