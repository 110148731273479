import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useFetch, usePost } from '../../utils/apiHelper';
import { makeStyles, Theme, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import LightTooltip from "../GlobalStyles/LightTooltip";
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';
import {
    Button, Checkbox, Container, Dialog, DialogActions, DialogContent,
    DialogContentText, Grid, ListSubheader, Paper, Typography, useMediaQuery, TableHead, TableRow, TableCell, TableSortLabel, TableContainer, Table, TableBody, TablePagination, Backdrop
} from '@material-ui/core';


//Icons
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

//Models
import { IBlobContainerNames } from '../../models/Files/IBlobContainerNames';
import { IBlobFolders } from '../../models/Files/IBlobFolders';
import { IBlobs } from '../../models/Files/IBlobs';

//component
import CreateNewFolder from './CreateNewFolder';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import PuffLoader from 'react-spinners/PuffLoader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        container: {
            maxWidth: "210vh",
            alignItems: "center",
            border: '2px solid gray',
            borderRadius: 10,
            backgroundColor: 'white',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            overflowY: 'hidden'
        },
        alignFlexStart: {
            display: 'flex',
            marginTop: '10px',
            marginBottom: '10px',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        alignFlexEnd: {
            display: 'flex',
            marginTop: '10px',
            marginBottom: '10px',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
        },
        button: {
            borderRadius: 20,
            fontSize: 15,
            fontWeight: 'bold',
            display: 'flex',
            marginLeft: '0.2cm',
            margin: theme.spacing(0.5),
        },
        buttonRemove: {
            borderRadius: 20,
            marginTop: '120px',
            marginLeft: '20px'
        },
        previewChip: {
            minWidth: 160,
            maxWidth: 210
        },
        snackbar: {
            color: 'black',
            marginTop: '-0.5cm',
        },
        snackbarfont: {
            fontSize: 16,
        },
        yesbutton: {
            backgroundColor: 'green',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            }
        },
        nobutton: {
            background: "red",
            borderRadius: "20px",
            color: 'white',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        },
        title: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        textStyle: {
            fontWeight: 'bold',
            marginTop: '25px',
            color: 'blue',
            textAlign: 'center'
        },
        textStyle1: {
            fontWeight: 'bold',
            marginTop: '25px',
            color: 'blue',
            textAlign: 'right',
            marginRight: '50px',
        },
        ActionCell: {
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'right'
        },
        tableBoxShadow: {
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        searchBox: {
            borderRadius: 30,
            border: '2px black solid',
            width: '100%',
            height: '43px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            fontSize: 16,
            textIndent: '10px',
            marginTop: '10px',
        },
        TextHighlight: {
            color: 'black',
            fontWeight: 'bold',
            background: "yellow",
            fontSize: '16px',
        },
        TextNormal: {
            color: 'black',
            fontSize: 14
        },
    }),
);

const ManageClientFolderAccess = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;

    const [SelectedContainer, setSelectedContianer] = useState<IBlobContainerNames[]>([]);
    const [ListContainers, setListContainers] = useState<IBlobContainerNames[]>([]);
    const [DeleteSelected, setDeleteSelected] = useState<IBlobContainerNames[]>([]);
    const [SelectedFolder, setSelectedFolder] = useState<IBlobFolders[]>([]);
    const [ListFolders, setListFolders] = useState<IBlobFolders[]>([]);
    const [selectedBlob, setSelectedBlob] = useState<IBlobs[]>([]);
    const [DeleteBlobs, setDeleteBlobs] = useState<IBlobs[]>([]);
    const [SelectedFiles, setSelectedFiles] = useState<any>();
    const [ListBlobs, setListBlobs] = useState([]);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fileUpload = React.useRef(null);

    const [DeleteContaineropen, setDeleteContaineropen] = React.useState(false);
    const [DeleteBlobopen, setDeleteBlobopen] = React.useState(false);
    const [disableUpload, setDisableUpload] = useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [CheckAccess, setCheckAccess] = useState(false);
    const [open, setOpen] = React.useState(false);

    const [totalRecords, setTotalRecords] = useState<number>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const [GetSelectedFileId, setGetSelectedFileId] = useState("");
    const [storeContainerName, setStoreContainerName] = useState("");
    const [orderBy, setOrderBy] = useState<string>("upload_Date");
    const [GetSelectedFile, setGetSelectedFile] = useState("");
    const [searchFiles, setSearchFiles] = useState("");
    const [order, setOrder] = useState<any>('desc');

    //Permissions
    const CreateFolderAccess = state.userAccessContext?.createfolder;
    const DeleteFolderAccess = state.userAccessContext?.deletefolder;
    const DownloadAccess = state.userAccessContext?.downloadfile;
    const DeleteFileAccess = state.userAccessContext?.deletefile;
    const UploadAcces = state.userAccessContext?.uploadfile;

    //Alert
    const [isDeleteFolder, setIsDeleteFolder] = useState(false);
    const [isDeleteFile, setIsDeleteFile] = useState(false);
    const [isDownload, setIsDownloaded] = useState(false);


    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsDownloaded(false);
        setIsDeleteFolder(false);
        setIsDeleteFile(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDialogDelete = () => {
        setDeleteContaineropen(false);
    }

    const handleFileDelete = () => {
        setDeleteBlobopen(false);
    }

    const handleDeleteContainerClick = () => {
        setDeleteContaineropen(true);
    };

    const handleDeleteContainerClose = () => {
        setDeleteContaineropen(false);
    };

    const handleDeleteBlobClick = () => {
        GetSelectedBlob();
        setDeleteBlobopen(true);
    };

    const handleDeleteBlobClose = () => {
        setDeleteBlobopen(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setshowProgressBar(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setshowProgressBar(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (state.userAccessContext?.role === 0 || state.userAccessContext?.role === 1 || state.userAccessContext?.role === 4) {
            if (ListContainers.length <= 0) {
                (async () => {
                    dispatch({ type: GlobalStateAction.Busy });
                    try {
                        const response = await useFetch<IBlobFolders[]>(`User/GetUserFolders?Id=${UserName}`);
                        setListFolders(response.data);
                        setCheckAccess(true);
                    }
                    catch (ex) {
                        dispatch({ type: GlobalStateAction.Error, error: ex });
                        dispatch({ type: GlobalStateAction.Idle });
                    }
                    finally {
                        dispatch({ type: GlobalStateAction.Idle });
                    }
                })()
            }
        }
    }, [ListContainers])

    useEffect(() => {
        getBlobs(storeContainerName);
    }, [rowsPerPage, page])

    const getBlobs = (CN) => {
        (async () => {
            setshowProgressBar(true);
            let request = {
                "containerName": CN,
                "searchParameter": searchFiles ?? "",
                "startingRecordNumber": (page * rowsPerPage) + 1,
                "rowsperPage": rowsPerPage,
                "userId": state.userAccessContext?.id
            }
            let responseClient = await usePost<IBlobs[]>("File/ListClientBlobs", request).then(r => {
                setListBlobs(r.data)
                setshowProgressBar(false);
            }).catch((err) => {
                if (err.response.status == 400 || err.response.status == 500) {
                    setshowProgressBar(false);
                    dispatch({ type: GlobalStateAction.Error, error: err.statusText })
                }
            })
        })()
    }

    function empty() {
        setSelectedContianer([]);
        setDeleteSelected([]);
    }

    const DeleteContainer = () => {
        (async () => {
            let request = {
                "containerName": GetSelectedContainers()[0],
            }
            let response = await usePost<any>("File/DeleteContainer", request);
            empty();
            HandleRefresh();
            if (response.status == 400) {
                dispatch({ type: GlobalStateAction.Error, error: response.statusText })
            }
            else {
                setIsDeleteFolder(true);
            }
        })()
    }

    function emptyBlob() {
        setDeleteBlobs([]);
    }

    const DeleteBlob = (FileName: string, File_Id: string) => {
        (async () => {
            let request = {
                "FileName": FileName,
                "containerName": GetSelectedFolder()[0],
                "fileId": File_Id
            }
            let response = await usePost<any>("File/DeleteBlob", request);
            getBlobs(GetSelectedFolder()[0]);
            emptyBlob();
            if (response.status == 400) {
                dispatch({ type: GlobalStateAction.Error, error: response.statusText })
            }
        })()
    }

    const handleContainerToggle = (CNames: IBlobContainerNames) => () => {
        let newSelectedContainers = [...SelectedContainer].filter(r => r.containerNames == r.containerNames[""])

        if (newSelectedContainers.some(c => c.containerNames == CNames.containerNames)) {
            newSelectedContainers = newSelectedContainers.filter(c => c.containerNames != CNames.containerNames);
        } else {
            newSelectedContainers.push(CNames);
        }

        let showBobs = "";
        for (var checkBlob in newSelectedContainers) {
            showBobs += newSelectedContainers[checkBlob].containerNames;
        }

        setSelectedContianer(newSelectedContainers);
        setDeleteSelected(newSelectedContainers);
        getBlobs(showBobs);
        setStoreContainerName(showBobs);
        setPage(0);
    }

    //Client Admin
    const handleFolderToggle = (FNames: IBlobFolders) => () => {
        let newSelectedContainers = [...SelectedFolder].filter(r => r.value == r.value[""])

        if (newSelectedContainers.some(c => c.value == FNames.value)) {
            newSelectedContainers = newSelectedContainers.filter(c => c.value != FNames.value);
        } else {
            newSelectedContainers.push(FNames);
        }

        let showBobs = "";
        for (var checkBlob in newSelectedContainers) {
            showBobs += newSelectedContainers[checkBlob].value;
        }

        setSelectedFolder(newSelectedContainers);
        getBlobs(showBobs);
    }

    function HandleDownload(FileId: string) {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const responseDownloadFile = await useFetch<any>(`File/FileDownload?fileId=${FileId}`);
                const arrayBuffer = base64ToArrayBuffer(responseDownloadFile.data.fileContent);
                createAndDownloadBlobFile(arrayBuffer, responseDownloadFile.data.fileName);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        const headCells = [
            { id: "file_Name", numeric: false, disablePadding: false, label: "FILE Name", sortable: true },
            { id: "upload_Date", numeric: false, disablePadding: false, label: "UPLOADED DATE", sortable: true },
            { id: "", numeric: false, disablePadding: false, label: "ACTION", sortable: false }
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            style={{ background: "#007FFF", color: "white", fontSize: "100%" }} >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)} >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const StyledTableCell = withStyles((theme: Theme) =>
        createStyles({
            head: {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 15,
            },
        }),
    )(TableCell);

    const StyledTableRow = withStyles((theme: Theme) =>
        createStyles({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.action.hover,
                },
                width: "100%",
                height: 30,
            },
        }),
    )(TableRow);

    const ListofContainers = () => (
        <React.Fragment>
            <List dense component="div" role="list"
                subheader={
                    <React.Fragment>
                        <Grid container style={{ display: 'flex' }}>
                            <Grid item xs={6}>
                                <ListSubheader component="div" id="nested-list-subheader" style={{ display: 'flex', position: 'relative' }} >
                                    Folders
                                </ListSubheader>
                            </Grid>
                            <Grid item xs={6} >
                                {DeleteSelected.length > 0 && DeleteFolderAccess ?
                                    <ListSubheader component="div" id="nested-list-subheader" style={{ display: 'flex', alignContent: 'flex-end', justifyContent: 'flex-end' }} >
                                        <LightTooltip title="Delete">
                                            <IconButton aria-label="delete" color="primary" onClick={handleDeleteContainerClick}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </LightTooltip>
                                    </ListSubheader>
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }>

                {!CheckAccess ?
                    ListContainers.map((CName, index) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleContainerToggle(CName)}>
                                <ListItemIcon>
                                    <Checkbox
                                        id="FV_Checkbox"
                                        checked={SelectedContainer.some(c => c.containerNames == CName.containerNames)}
                                        disabled={SelectedContainer.some(c => c.containerNames != CName.containerNames)}
                                        tabIndex={-1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemIcon >
                                    {SelectedContainer.some(c => c.containerNames == CName.containerNames) ? <FolderOpenIcon /> : <FolderIcon />}
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${CName.containerNames}`} />
                            </ListItem>
                        );
                    })
                    :
                    ListFolders.map((FName, index) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleFolderToggle(FName)}>
                                <ListItemIcon>
                                    <Checkbox
                                        id="FV_Checkbox"
                                        checked={SelectedFolder.some(c => c.value == FName.value)}
                                        disabled={SelectedFolder.some(c => c.value != FName.value && FName.id)}
                                        tabIndex={-1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemIcon >
                                    {SelectedFolder.some(c => c.value == FName.value) ? <FolderOpenIcon /> : <FolderIcon />}
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${FName.value}`} />
                            </ListItem>
                        );
                    })
                }
                <ListItem />
            </List>
        </React.Fragment>
    );

    const ListBlob = () => (
        <React.Fragment>
            <TableContainer component={Paper} className={classes.tableBoxShadow}>
                <Table size="small" stickyHeader>
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        <Backdrop className={classes.backdrop} open={ProgressBar}>
                            <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                        </Backdrop>
                        {state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ?
                            stableSort(ListBlobs, getComparator(order, orderBy)).map((row, index) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell scope="row">
                                            <span className={`${searchFiles === "" ? classes.TextNormal : row.file_Name.includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                                {row.file_Name}
                                            </span>
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            <span className={`${searchFiles === "" ? classes.TextNormal : row.upload_Date?.split('T')[0].includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                                {moment(row.upload_Date).format(state.GlobalUtils?.settingValue)}
                                            </span>
                                        </StyledTableCell>

                                        <StyledTableCell className={classes.ActionCell}>
                                            {DownloadAccess &&
                                                <ListItemIcon>
                                                    <IconButton aria-label="delete" color="primary" onClick={(_) => { HandleDownload(row.file_Id); }}>
                                                        <CloudDownloadIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                            {DeleteFileAccess &&
                                                <ListItemIcon>
                                                    <IconButton aria-label="delete" color="primary" onClick={(_) => { handleDeleteBlobClick(); setGetSelectedFile(row.file_Name); setGetSelectedFileId(row.file_Id); }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })
                            :
                            stableSort(ListBlobs, getComparator(order, orderBy)).map((row, index) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <span className={`${searchFiles === "" ? classes.TextNormal : row.file_Name.includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                                {row.file_Name}
                                            </span>
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            <span className={`${searchFiles === "" ? classes.TextNormal : row.upload_Date?.split('T')[0].includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                                {moment(row.upload_Date).format(state.GlobalUtils?.settingValue)}
                                            </span>
                                        </StyledTableCell>

                                        <StyledTableCell className={classes.ActionCell}>
                                            {DownloadAccess &&
                                                <ListItemIcon>
                                                    <IconButton aria-label="delete" color="primary" onClick={(_) => { HandleDownload(row.file_Id); }}>
                                                        <CloudDownloadIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                            {DeleteFileAccess &&
                                                <ListItemIcon>
                                                    <IconButton aria-label="delete" color="primary" onClick={(_) => { handleDeleteBlobClick(); setGetSelectedFileId(row.file_Id); setGetSelectedFile(row.file_Name); }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })
                        }
                        <ListItem />
                    </TableBody>
                </Table>
                {state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ?
                    <TablePagination
                        id="Files_TablePagination"
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={totalRecords === undefined ? 0 : totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    :
                    <TablePagination
                        id="Files_TablePagination"
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={ListBlobs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </TableContainer>
        </React.Fragment>
    );

    const HandleRefresh = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers");
                setListContainers(response.data);

            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const GetSelectedContainers = () => {
        let CNames = "";
        let CTypes = "";
        SelectedContainer.forEach((C) => {
            CNames += C.containerNames;
            CTypes += C.containerType;
        })
        return [CNames, CTypes];
    }

    const GetSelectedFolder = () => {
        let CNames = "";
        let CTypes = "";
        SelectedFolder.forEach((C) => {
            CNames += C.value;
        })
        return [CNames, CTypes];
    }

    const GetSelectedBlob = () => {
        let BlobName = "";
        selectedBlob.forEach((C) => {
            BlobName += C;
        })
        return BlobName;
    }

    //upload file to container
    useEffect(() => {
        if (GetSelectedContainers()[1] === "CloudContainer") {
            CloudFilesUpload(SelectedFiles);
        }
        else if (GetSelectedContainers()[1] === "PaymentContainer") {
            PaymentFileUpload(SelectedFiles);
        }
        else if (GetSelectedContainers()[1] === "AckContainer") {
            AckFileUpload(SelectedFiles);
        }
    }, [SelectedFiles])

    const CloudFilesUpload = (selectedFile: File) => {
        if (selectedFile) {
            if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
                (async () => {
                    dispatch({ type: GlobalStateAction.Busy })
                    const formData = new FormData();
                    let dateTime = new Date();
                    formData.append("FormFile", selectedFile);
                    formData.append("file_name", selectedFile.name);
                    formData.append("upload_date", dateTime.toString());
                    formData.append("file_size", selectedFile.size.toString());
                    formData.append("ContainerName", GetSelectedContainers()[0]);
                    formData.append("userid", UserName.toString());
                    formData.append("ClientId", "");
                    formData.append("rid", "");
                    try {
                        const ClientCode = state.userAccessContext?.clientCodes;
                        const UserName = state.userAccessContext?.userName;
                        let request = {
                            "clientId": ClientCode.toString(),
                            "userName": UserName,
                            "fileName": selectedFile.name,
                            "folderName": GetSelectedContainers()[0]
                        };
                        const response = await usePost<any>("File/UploadFile", formData)
                        getBlobs(GetSelectedContainers()[0]);
                        await usePost<any>("SendUploadDocEmail", request);
                    }
                    catch (ex) {
                        dispatch({ type: GlobalStateAction.Error, error: ex })
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                    finally {
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                })()
            }
            else {
                (async () => {
                    dispatch({ type: GlobalStateAction.Busy })
                    const formData = new FormData();
                    let dateTime = new Date();
                    let UserId = state.userAccessContext?.id;
                    formData.append("FormFile", selectedFile);
                    formData.append("file_name", `${UserId}/${selectedFile.name}`);
                    formData.append("upload_date", dateTime.toString());
                    formData.append("file_size", selectedFile.size.toString());
                    formData.append("ContainerName", GetSelectedFolder()[0]);
                    formData.append("userid", UserName.toString());
                    formData.append("ClientId", "");
                    formData.append("rid", "");
                    try {
                        const ClientCode = state.userAccessContext?.clientCodes;
                        const UserName = state.userAccessContext?.userName;
                        let request = {
                            "clientId": ClientCode.toString(),
                            "userName": UserName,
                            "fileName": selectedFile.name,
                            "folderName": GetSelectedFolder()[0]
                        };
                        const response = await usePost<any>("File/UploadFile", formData)
                        getBlobs(GetSelectedFolder()[0]);
                        await usePost<any>("SendUploadDocEmail", request);
                    }
                    catch (ex) {
                        dispatch({ type: GlobalStateAction.Error, error: ex })
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                    finally {
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                })()
            }
        }
    }

    const PaymentFileUpload = (selectedFile: File) => {
        if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
            (async () => {
                dispatch({ type: GlobalStateAction.Busy })
                const formData = new FormData();
                let dateTime = new Date();
                formData.append("FormFile", selectedFile);
                formData.append("file_name", selectedFile.name);
                formData.append("upload_date", dateTime.toString());
                formData.append("file_size", selectedFile.size.toString());
                formData.append("ContainerName", GetSelectedContainers()[0]);
                formData.append("userid", UserName.toString());
                try {
                    const ClientCode = state.userAccessContext?.clientCodes;
                    const UserName = state.userAccessContext?.userName;
                    let request = {
                        "clientId": ClientCode.toString(),
                        "userName": UserName,
                        "fileName": selectedFile.name,
                        "folderName": GetSelectedContainers()[0]
                    };
                    const response = await usePost<any>("File/UploadPaymentFile", formData)
                    getBlobs(GetSelectedContainers()[0]);
                    await usePost<any>("SendUploadDocEmail", request);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex })
                    dispatch({ type: GlobalStateAction.Idle })
                }
                finally {
                    dispatch({ type: GlobalStateAction.Idle })
                }
            })()
        }
        else {
            (async () => {
                dispatch({ type: GlobalStateAction.Busy })
                const formData = new FormData();
                let dateTime = new Date();
                let UserId = state.userAccessContext?.id;
                formData.append("FormFile", selectedFile);
                formData.append("file_name", `${UserId}/${selectedFile.name}`);
                formData.append("upload_date", dateTime.toString());
                formData.append("file_size", selectedFile.size.toString());
                formData.append("ContainerName", GetSelectedFolder()[0]);
                formData.append("userid", UserName.toString());
                try {
                    const ClientCode = state.userAccessContext?.clientCodes;
                    const UserName = state.userAccessContext?.userName;
                    let request = {
                        "clientId": ClientCode.toString(),
                        "userName": UserName,
                        "fileName": selectedFile.name,
                        "folderName": GetSelectedFolder()[0]
                    };
                    const response = await usePost<any>("File/UploadPaymentFile", formData)
                    getBlobs(GetSelectedFolder()[0]);
                    await usePost<any>("SendUploadDocEmail", request);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex })
                    dispatch({ type: GlobalStateAction.Idle })
                }
                finally {
                    dispatch({ type: GlobalStateAction.Idle })
                }
            })()
        }
    }

    const AckFileUpload = (selectedFile: File) => {
        if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3) {
            (async () => {
                dispatch({ type: GlobalStateAction.Busy })
                const formData = new FormData();
                let dateTime = new Date();
                formData.append("FormFile", selectedFile);
                formData.append("file_name", selectedFile.name);
                formData.append("upload_date", dateTime.toString());
                formData.append("file_size", selectedFile.size.toString());
                formData.append("ContainerName", GetSelectedContainers()[0]);
                formData.append("userid", UserName.toString());
                try {
                    const ClientCode = state.userAccessContext?.clientCodes;
                    const UserName = state.userAccessContext?.userName;
                    let request = {
                        "clientId": ClientCode.toString(),
                        "userName": UserName,
                        "fileName": selectedFile.name,
                        "folderName": GetSelectedContainers()[0]
                    };
                    const response = await usePost<any>("File/UploadACKFile", formData)
                    getBlobs(GetSelectedContainers()[0]);
                    await usePost<any>("SendUploadDocEmail", request);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex })
                    dispatch({ type: GlobalStateAction.Idle })
                }
                finally {
                    dispatch({ type: GlobalStateAction.Idle })
                }
            })()
        }
        else {
            (async () => {
                dispatch({ type: GlobalStateAction.Busy })
                const formData = new FormData();
                let dateTime = new Date();
                let UserId = state.userAccessContext?.id;
                formData.append("FormFile", selectedFile);
                formData.append("file_name", `${UserId}/${selectedFile.name}`);
                formData.append("upload_date", dateTime.toString());
                formData.append("file_size", selectedFile.size.toString());
                formData.append("ContainerName", GetSelectedFolder()[0]);
                formData.append("userid", UserName.toString());
                try {
                    const ClientCode = state.userAccessContext?.clientCodes;
                    const UserName = state.userAccessContext?.userName;
                    let request = {
                        "clientId": ClientCode.toString(),
                        "userName": UserName,
                        "fileName": selectedFile.name,
                        "folderName": GetSelectedFolder()[0]
                    };
                    const response = await usePost<any>("File/UploadACKFile", formData)
                    getBlobs(GetSelectedFolder()[0]);
                    await usePost<any>("SendUploadDocEmail", request);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex })
                    dispatch({ type: GlobalStateAction.Idle })
                }
                finally {
                    dispatch({ type: GlobalStateAction.Idle })
                }
            })()
        }
    }

    useEffect(() => {
        if (state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 || state.userAccessContext?.role === 4) {
            if (GetSelectedContainers()[0].length === 0) {
                setDisableUpload(true);
            } else {
                setDisableUpload(false);
            }
        }
        else {
            if (GetSelectedFolder()[0].length === 0) {
                setDisableUpload(true);
            } else {
                setDisableUpload(false);
            }
        }
    }, [disableUpload, SelectedContainer, SelectedFolder])

    const initiateSearch = () => {
        setTriggerSearch(!triggerSearch)
        getBlobs(GetSelectedContainers()[0]);
        setPage(0);
    }

    return (
        <React.Fragment>
            <Container fixed className={classes.container} component="div" >
                <Grid container>
                    <Grid item xs={3} className={classes.alignFlexStart} >
                        {CreateFolderAccess && <CreateNewFolder />}
                        {UploadAcces &&
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleClick}
                                startIcon={<CloudUploadIcon style={{ fontSize: 25 }} />}
                                disabled={disableUpload}
                            >
                                Upload
                            </Button>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <input value={searchFiles}
                            type='text' placeholder="Search Files"
                            className={classes.searchBox}
                            onChange={e => setSearchFiles(e.target.value.trim())}
                            onKeyPress={event => { if (event.key === 'Enter') { initiateSearch(); } }}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={5} className={classes.alignFlexEnd}>
                        <LightTooltip title="Refresh">
                            <IconButton size="small" onClick={HandleRefresh} >
                                <RefreshIcon style={{ fontSize: 35 }} />
                            </IconButton>
                        </LightTooltip>
                    </Grid>
                </Grid>

                <Grid container style={{ borderTop: '1px solid black' }}>
                    <Grid item xs={3} style={{ borderRight: '1px solid', }}>
                        <Scrollbars autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={10}
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={608}
                            style={{ width: "100%" }}>
                            {ListofContainers()}
                        </Scrollbars>
                    </Grid>

                    <Grid item xs={9}>
                        {ListBlob()}
                    </Grid>
                </Grid>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={'sm'}
                    PaperProps={{ style: { borderRadius: 15 } }}
                >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                            UPLOAD FILE
                        </Typography>
                    </DialogTitleHeader>
                    <DialogContent style={{ marginTop: '10px' }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container>
                                <Grid item xs={12}>
                                    <DropzoneArea
                                        onChange={(files) => setSelectedFiles(files[0])}
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        previewChipProps={{ classes: { root: classes.previewChip } }}
                                        previewText="Selected files"
                                        filesLimit={1}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="folder_close" variant="contained" autoFocus style={{ marginRight: "8px", borderRadius: 10, marginBottom: '0.3cm' }}
                            onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Container Dialog */}
                <Dialog
                    PaperProps={{ style: { borderRadius: 10 } }}
                    fullScreen={fullScreen}
                    open={DeleteContaineropen}
                    id="FV_DeleteContainerDialog"
                >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleDialogDelete}>
                        <Typography variant="h5" gutterBottom className={classes.title}>
                            DELETE FOLDER
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Typography variant="h6" style={{ color: 'black' }}>
                            Are you sure you want to delete {GetSelectedContainers()} folder ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(_) => { DeleteContainer(); handleDeleteContainerClose(); }} color="primary" variant="contained" className={classes.yesbutton} autoFocus>
                            Yes
                        </Button>
                        <Button autoFocus onClick={handleDeleteContainerClose} color="primary" variant="contained" className={classes.nobutton}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Blob Dialog */}
                <Dialog
                    PaperProps={{ style: { borderRadius: 15 } }}
                    fullScreen={fullScreen}
                    open={DeleteBlobopen}
                    id="FV_DeleteBlobDialog"
                >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleFileDelete}>
                        <Typography variant="h5" gutterBottom className={classes.title}>
                            DELETE FILE
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent >
                        <Typography variant="h6" style={{ color: 'black' }}>
                            Are you sure you want to delete {GetSelectedFile} file ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(_) => { DeleteBlob(GetSelectedFile, GetSelectedFileId); handleDeleteBlobClose(); }} color="primary" autoFocus variant="contained" className={classes.yesbutton}>
                            Yes
                        </Button>
                        <Button autoFocus onClick={handleDeleteBlobClose} color="primary" variant="contained" className={classes.nobutton}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar className={classes.snackbar} open={isDownload} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="success" className={classes.snackbarfont}>
                        File Downloaded Successfully.
                    </Alert>
                </Snackbar>

                <Snackbar className={classes.snackbar} open={isDeleteFolder} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" className={classes.snackbarfont}>
                        Folder Deleted Successfully.
                    </Alert>
                </Snackbar>

                <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={isDeleteFile} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" style={{ fontSize: 16 }}>
                        File Deleted Successfully.
                    </Alert>
                </Snackbar>
            </Container>
        </React.Fragment >
    )
}

export default ManageClientFolderAccess