export const columns = [
  { field: 'CLIENTID', type: 'string', width: 115, id: 'dbT_CLIENT', sortable: true },
  { field: 'AGENCYID', type: 'string', width: 130, id: 'dbT_NO', sortable: true },
  { field: 'ACCOUNTID', type: 'string', width: 150, id: 'dbT_CLIENT_ACNT1', sortable: true },
  { field: 'NAME', type: 'string', width: 120, id: 'dbT_NAME', sortable: true },
  { field: 'ACCOUNT AGING', type: 'string', width: 190, id: 'dbT_REFERRAL_DATE', sortable: true },
  { field: 'REFFERED AMOUNT', type: 'string', width: 200, id: 'dbT_REFERRAL_AMT', sortable: true },
  { field: 'BALANCE', type: 'string', width: 120, id: 'dbT_PRINCIPAL_DUE', sortable: true },
  { field: 'STATUS', type: 'string', width: 120, id: 'stS_DESC', sortable: true },
  { field: 'STATUS DATE', type: 'string', width: 150, id: 'dbT_STATUS_DATE', sortable: true },
  { field: 'ADVANCE TO CONT', type: 'string', width: 300, sortable: false },
  { field: 'UNDO', type: 'string', width: 30, sortable: false },
  { field: 'REMOVE', type: 'string', width: 30, sortable: false },
]

export const gridData = {
  rowPerPage: 10,
  startingNumber: 1,
}