import * as React from 'react';
import { Box, Button, Collapse, Grid, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { useEffect, useState } from 'react';
import Beginorder from "../../components/Dashboard/Beginorder";
import { IRollCount } from "../../models/IRollCount";
import { IDaysSincePlaced } from "../../models/dashboard/IDaysSincePlaced";
import TimerOffIcon from '@material-ui/icons/TimerOff';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { Link } from 'react-router-dom';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CardBody from '../Card/CardBody';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import Backdrop from '@material-ui/core/Backdrop';
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

export interface DisplayState extends SnackbarOrigin {
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cards: {
      display: "absolute",
      flexDirection: "row",
      marginRight: "150px",
      marginLeft: "-200px",
      justifyContent: 'space-around',
    },
    typography: {
      color: "black"
    },
    searchBox: {
      marginTop: "3%",
      borderRadius: 20,
      width: "-10%"
    },
    message: {
      color: "black",
    },
    button: {
      borderRadius: 20,
      color: "white"
    },
    notification: {
      marginTop: "2%",
    },
    paper: {
      textAlign: 'center',
      borderRadius: 20,
      color: theme.palette.text.secondary,
      marginTop: "-45%",
      width: "90%",
    },
    card: {
      color: theme.palette.text.secondary,
      height: '6cm'
    },
    root: {
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(3),
    },
    graphAlign: {
      left: '40%',
      right: '40%',
    },
    fonts: {
      fontFamily: 'Segoe UI',
    },
    bottomGrid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(2),
      color: theme.palette.info[500],
    },
    media: {
      height: 140,
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  }))

const UserLoginStatus = () => {
  const classes = useStyles()
  const [RollCount, setRollCount] = useState<IRollCount>({});
  const [DaysSincePlaced, setDaysSincePlaced] = useState<IDaysSincePlaced>();
  const [BalanceValue, setBalanceValue] = useState<number>(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(" ");
  const [ProgressBar, setshowProgressBar] = useState(true);

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
        width: 600
      },
    }),
  )(TableCell);

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        width: "100%"
      },
    }),
  )(TableRow);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <React.Fragment>
      <Grid container justify="flex-start" alignContent="flex-start" alignItems="center" spacing={1}>
        <Grid xs={12} item className={classes.searchBox} >
        </Grid>
        <Grid container className={classes.root} >
          {/* {state.userAccessContext?.unReadNotifications && <Grid item xs={12} sm={6} md={3} className={classes.grid} > */}
          <Grid item xs={12} sm={6} md={3} className={classes.grid} >
            <Card className={classes.card}>
              <CardHeader color="warning" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                <CardIcon color="warning">
                  <IconButton component={Link} to="/notifications" aria-label="delete" style={{ color: "white" }} >
                    <NotificationsActiveIcon fontSize="small" />
                  </IconButton>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ marginLeft: "-1.80cm" }}>
                {/* {DashboardNotificationCount.map((nf => {
                  return ( */}
                <Typography variant="h2" gutterBottom style={{ color: "black", marginTop: "-1cm" }} >
                  <b><Typography variant="h2" style={{ color: "black" }} gutterBottom > <b>0</b> </Typography></b>
                  {/* <b>{nf.count ? nf.count : <Typography variant="h2" style={{ color: "black" }} gutterBottom > <b>0</b> </Typography>}</b> */}
                </Typography>
                {/* )
                }))
                } */}
                <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center" }} gutterBottom>
                  Total Users count
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          {/* } */}

          {/* {state.userAccessContext?.flatFeeAcRem && <Grid item xs={12} sm={6} md={3} className={classes.grid}> */}
          <Grid item xs={12} sm={6} md={3} className={classes.grid}>
            <Card className={classes.card}>
              <CardHeader color="success" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                <CardIcon color="success">
                  < Beginorder />
                </CardIcon>
              </CardHeader>
              <CardBody style={{ marginLeft: "-1.80cm" }}>
                <Typography variant="h2" gutterBottom style={{ color: "black", marginTop: "-1cm" }} >
                  <b>{BalanceValue}</b>
                </Typography>
                <Typography variant="h6" style={{ color: "black", textAlign: "center" }} gutterBottom>
                  Remaining users to Invite
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          {/* } */}

          {/* {state.userAccessContext?.acWaitingtoMove && <Grid item xs={12} sm={6} md={3} className={classes.grid}> */}
          <Grid item xs={12} sm={6} md={3} className={classes.grid}>
            <Card className={classes.card}>
              <CardHeader color="danger" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                <CardIcon color="danger" style={{ backgroundColor: "pink" }}>
                  <IconButton component={Link} to="/roll" aria-label="delete" style={{ color: "white" }} >
                    <InsertInvitationIcon fontSize="small" />
                  </IconButton>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ marginLeft: "-1.80cm" }}>
                <Typography variant="h2" gutterBottom style={{ marginTop: "-1cm", color: "black" }} >
                  <b>{RollCount.remaining ? RollCount.remaining : <Typography variant="h2" style={{ color: "black" }} gutterBottom > <b>0</b> </Typography>}</b>
                </Typography>
                <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center" }} gutterBottom>
                  Already Invited users count
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          {/* } */}

          {/* {state.userAccessContext?.daysSincePlacmentofAc && <Grid item xs={12} sm={6} md={3} className={classes.grid}> */}
          <Grid item xs={12} sm={6} md={3} className={classes.grid}>
            <Card className={classes.card}>
              <CardHeader color="info" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                <CardIcon color="info">
                  <IconButton component={Link} to="/placement" aria-label="delete" style={{ color: "white" }} >
                    <TimerOffIcon fontSize="small" />
                  </IconButton>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ marginLeft: "-1.80cm" }}>
                <Typography variant="h2" gutterBottom style={{ marginTop: "-1cm" }} >
                  <b>{DaysSincePlaced ? DaysSincePlaced : <Typography variant="h2" style={{ color: "black" }} gutterBottom ><b>0</b></Typography>}</b>
                </Typography>
                <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center", }} gutterBottom>
                  Invitation expired count
                </Typography>
              </CardBody>
            </Card>
          </Grid>
          {/* } */}
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            <TableContainer component={Paper} style={{ width: "96%", marginLeft: "1cm" }}>
              <Table aria-label="customized table" size="small"  >
                <TableHead>
                  <TableRow >
                    <StyledTableCell style={{ background: "#007FFF", color: "white", fontSize: "120%" }}>USER NAME</StyledTableCell>
                    <StyledTableCell style={{ background: "#007FFF", color: "white", fontSize: "120%" }}>EMAIL</StyledTableCell>
                    <StyledTableCell style={{ background: "#007FFF", color: "white", fontSize: "120%" }}>USER TYPE</StyledTableCell>
                    <StyledTableCell style={{ background: "#007FFF", color: "white", fontSize: "120%" }} align="center">INVITED DATE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow  >
                    <StyledTableCell component="th" scope="row">
                    </StyledTableCell>

                    <StyledTableCell>
                    </StyledTableCell>

                    <StyledTableCell>
                    </StyledTableCell>

                    <StyledTableCell>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
              {/* <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={DaysSincePlaced}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              /> */}
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default UserLoginStatus