import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import {
    AppBar, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid, Hidden, IconButton, makeStyles, TextField, Toolbar, Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { msalConfig } from '../auth/authConfig';
import { AccessContext } from '../models/accesscontrol/AccessContext';
import { WidgetMap } from '../models/accesscontrol/WidgetMap';
import { IWidgetInfo } from '../models/accesscontrol/IWidgetInfo';
import Routes from '../Routes';
import { useGlobalState, GlobalStateAction } from '../store/GlobalStore';
import { getUserInitials } from '../utils/stringUtils';
import NavigationDrawer from './NavigationDrawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFetch, usePost } from '../utils/apiHelper';
import { GlobalDateTimeUtil } from '../models/GlobalUtil/GlobalDateTimeUtil';
import MenuIcon from '@material-ui/icons/Menu';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import SessionTimeout from './SessionTimeout';
import SuperAdmin from '../images/users/SuperAdmin.svg';
import ClientAdmin from '../images/users/GlobalAdmin.svg';
import GlobalAdmin from '../images/users/ClientAdmin.svg';
import RegularUser from '../images/users/RegularUser.svg';
import FileAdmin from '../images/users/FileAdmin.svg';
import { StyledBadge } from '../components/GlobalStyles/ActiveUser';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        width: 170
    },
    userName: {
        marginRight: 10
    },
    clientAdmin: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    globalAdmin: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    superAdminColor: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    regularUser: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    fileAdmin: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    logout: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        border: '1px solid'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 5,
        height: '100 %',
        backgroundColor: "rgba(255, 255, 255, .8)",
        margin: 0
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(2),
        color: theme.palette.info[500],
    },
    description: {
        width: '100%',
        marginTop: "15px",
    },
    text: {
        marginLeft: "35%"
    },
    cards: {
        display: "absolute",
        flexDirection: "row",
        marginRight: "150px",
        marginLeft: "-200px",
        justifyContent: 'space-around',
    },
    card: {
        color: theme.palette.text.secondary,
        height: '6cm'
    },
    MessageTextAlign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))

const Header = () => {
    let history = useHistory();
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);
    const allowedRoutes = Routes.filter(r => { return r.UserRole?.some(role => role == state.userAccessContext?.role) });
    const [finalRoutes, setFinalRoutes] = useState([]);
    const [message, setMessage] = useState<string>("");
    const [inputVal, setinputVal] = useState('');
    const [success, setSuccess] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const timer = React.useRef<number>();
    const [IsTriggered, setIsTriggered] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    function closeMobileDrawer() {
        setMobileDrawerOpen(false);
    }

    // Inspired by the microsoft spinners.
    const useProgressStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));

    useEffect(() => {
        (async () => {
            let filteredRoutes = allowedRoutes.filter(x => !x.noNavLink);
            let reportId = filteredRoutes.findIndex(x => x.title === "Reports");
            let placementId = filteredRoutes.findIndex(x => x.title === "Placement");
            let rollId = filteredRoutes.findIndex(x => x.title === "Roll");
            if (state.userAccessContext?.editReports === false) {
                delete filteredRoutes[reportId];
            }
            if (state.userAccessContext?.rollAccounts === false) {
                delete filteredRoutes[rollId];
            }
            if (state.userAccessContext?.placeAccounts === false) {
                delete filteredRoutes[placementId];
            }
            setFinalRoutes(filteredRoutes);
        })()
    }, [state.userAccessContext?.role])

    const signout = () => {
        instance.logout({
            postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        })
    }

    const handleSwitchToOriginalUser = () => {
        let requestGuid = { "objectId": state?.user?.objectId };
        usePost<AccessContext>("User/GetUserByObjectId", requestGuid).then((userObj) => {
            useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userObj.data.id).then((clientsWidgets) => {
                for (let index = 1; index <= WidgetMap.size; index++) {
                    const value = WidgetMap.get(index);
                    if (value && clientsWidgets.data.filter(w => w.widgetId === index).length === 1) {
                        userObj.data[value] = true;
                    }
                    dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userObj.data });
                };
                if (userObj.data.role === 2 || userObj.data.role === 3) {
                    history.push('/admin');
                } else if (userObj.data.role === 0 || userObj.data.role === 1) {
                    history.push('/dashboard')
                } else {
                    history.push('/ManageClientFolderAccess')
                }
            });
        });
    };

    //To-Do To be removed / validate
    const handleClick = () => {
        // return
        // history.push('/');
        // setAuth(!isAuthenticated);
    }

    function Progress(props) {
        const classes = useProgressStyles();
        return (
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={40}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={40}
                    thickness={4}
                    {...props}
                />
            </div>
        );
    }

    useEffect(() => {
        (async () => {
            await useFetch<GlobalDateTimeUtil>("GlobalSettings/GetDateSettings").then((r) => {
                dispatch({
                    type: GlobalStateAction.GlobalUtils, GlobalUtils: {
                        enumKey: r.data.enumKey,
                        settingName: r.data.settingName,
                        settingValue: r.data.settingValue
                    }
                });
            })
        })()
    }, [isAuthenticated])

    //To-Do To be removed
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success
    });

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, [message]);

    const handleDialogClose = () => {
        setOpen(false);
        setSuccess(false);
        setMessage("");
        setinputVal('');
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsTriggered(false);
    };

    const SendBeginReorderEmail = (username: string, body: string) => {
        (async () => {
            try {
                let request = { "Username": username, "Body": body }
                await usePost<any>("Account/SendBeginReorderEmail", request);
                setIsTriggered(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const handleSettlementOfferClick = (message) => {
        let msgBody = "";
        let clientName = state.user?.name;
        var Client = clientName.split("@")[0];
        let clientId = state.userAccessContext.clientCodes;
        msgBody += "<p>From:<br />Client Name: " + Client + "<br />Client ID: " + clientId + "</p><p>Message:<br />\"" + message + "\"</p><p>(to respond, you may reply directly to this email)</p>";
        msgBody += "</table><p>Thanks,<br/>" + Client + "</p>";
        SendBeginReorderEmail(clientName, msgBody);
        setOpen(false);
        handleDialogClose();
        setShowMessage(true);
    }

    return (
        <React.Fragment>
            <AppBar position="static" style={{ backgroundColor: 'white', color: 'black', marginLeft: '-10px', width: '101%' }}>
                <Toolbar component="nav" className="app-header">
                    <img alt="ARM_Solutions" className={classes.menuButton} src="https://www.armsolutions.com/website/wp-content/themes/arm-solutions/style/images/header-logo.png" />
                    <Box display="flex" alignItems="center" alignContent="center">
                        <Hidden smDown>
                            <Typography variant="h5" component="h5" style={{ marginRight: 10, marginTop: "5px" }}>
                                {!state?.userAccessContext?.firstName.length ? <Progress /> : state?.userAccessContext?.firstName + " " + state?.userAccessContext.lastName}
                            </Typography>
                        </Hidden>
                        <IconButton size="medium" aria-label="menu" style={{ marginLeft: 10 }} onClick={handleSwitchToOriginalUser} >
                            {state.userAccessContext?.role === 3 ?
                                <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                    <Avatar className={classes.superAdminColor} src={SuperAdmin}> {getUserInitials(state?.user?.name ?? "")} </Avatar>
                                </StyledBadge>
                                : state.userAccessContext?.role === 2 ?
                                    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                        <Avatar className={classes.globalAdmin} src={GlobalAdmin}>{getUserInitials(state?.user?.name ?? "")}</Avatar>
                                    </StyledBadge>
                                    : state.userAccessContext?.role === 1 ?
                                        <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                            <Avatar className={classes.clientAdmin} src={ClientAdmin}>{getUserInitials(state?.user?.name ?? "")}</Avatar>
                                        </StyledBadge>
                                        : state.userAccessContext?.role === 0 ?
                                            <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                                <Avatar className={classes.regularUser} src={RegularUser}>{getUserInitials(state?.user?.name ?? "")}</Avatar>
                                            </StyledBadge>
                                            : <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                                <Avatar className={classes.fileAdmin} src={FileAdmin}>{getUserInitials(state?.user?.name ?? "")}</Avatar>
                                            </StyledBadge>
                            }
                        </IconButton>
                        <LightTooltip title="Contact Us">
                            <IconButton id="Contact" className={classes.logout} onClick={() => { setOpen(true) }} >
                                <HeadsetMicIcon fontSize="inherit" />
                            </IconButton>
                        </LightTooltip>
                        <LightTooltip title="Sign-out">
                            <IconButton id="signout" className={classes.logout} onClick={signout} style={{ marginLeft: "10px" }}>
                                <ExitToAppIcon fontSize="inherit" />
                            </IconButton>
                        </LightTooltip>
                    </Box>
                    <Hidden mdUp>
                        <IconButton
                            className={classes.menuButton}
                            onClick={() => setMobileDrawerOpen(true)}
                            aria-label="Open Navigation"
                        >
                            <MenuIcon fontSize="large" color="primary" />
                        </IconButton>
                    </Hidden>
                </Toolbar>
                <Hidden smDown>
                    <Toolbar component="nav" className="app-header-navigation">
                        {finalRoutes.map((route, key) => {
                            return (
                                <Link
                                    id="Header_NavigationLink"
                                    className="header"
                                    to={route.path}
                                    key={route.title}
                                >
                                    {route.title}
                                </Link>
                            )
                        })}
                    </Toolbar>
                </Hidden>
                <NavigationDrawer
                    open={mobileDrawerOpen}
                    onClose={closeMobileDrawer}
                />
            </AppBar>

            <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} />

            <Dialog open={open} keepMounted onClose={handleDialogClose} maxWidth={'sm'}
                PaperProps={{ style: { borderRadius: 30 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogContent>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={handleDialogClose} style={{ top: "-0.1cm" }}><CloseIcon /></IconButton>
                    <Grid item xs={12} >
                        <TextField
                            id="BeginReorder_MessageBox"
                            variant="outlined"
                            placeholder="Contact us"
                            size="medium"
                            multiline
                            rows={6}
                            value={message}
                            onChange={(e) => { setMessage(e.target.value); setinputVal(e.target.value); }}
                            InputLabelProps={{ shrink: true }}
                            className={classes.description}
                        />
                    </Grid>
                    <Button
                        id="BeginReorder_SendNotifications"
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={!inputVal}
                        onClick={() => { handleSettlementOfferClick(message); }}
                        style={{ marginLeft: "5cm", marginTop: "0.5cm", paddingBottom: "10px", width: 165, borderRadius: 10 }}
                        endIcon={success ? "" : <SendIcon />}
                    >
                        Send Message
                    </Button>
                    <Typography variant="subtitle1" className={classes.text} gutterBottom>
                        Phone: (888) 772-6468
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                open={showMessage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogTitle id="responsive-dialog-title" className={classes.MessageTextAlign}>
                        {"Message"}
                    </DialogTitle>
                    <DialogContentText id="alert-dialog-description" className={classes.MessageTextAlign}>
                        <Typography variant="h6" style={{ marginTop: "15px", color: "black" }} gutterBottom>
                            Your message has been sent.
                        </Typography>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" className={classes.MessageTextAlign}>
                        <Typography variant="h6" style={{ marginTop: "15px", color: "black" }} gutterBottom>
                            Phone: (888) 772-6468
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="BR_Close" onClick={(_) => { setShowMessage(false); }} variant="contained" style={{ borderRadius: '20px', backgroundColor: "green" }} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={IsTriggered} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success">
                    Message Sent Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default Header

