import React, { useEffect, useState } from "react";
import { createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { Backdrop, CircularProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import { IUserInfo } from '../../../models/accesscontrol/IUserInfo';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { AccessContext } from "../../../models/accesscontrol/AccessContext";
import { WidgetMap } from "../../../models/accesscontrol/WidgetMap";
import { IWidgetInfo } from "../../../models/accesscontrol/IWidgetInfo";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import PuffLoader from "react-spinners/PuffLoader";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    formControl: {
      minwidth: 300,
      position: "absolute",
    },
    button: {
      borderRadius: "10px",
      marginBottom: '0.3cm'
    },
    titleheader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white'
    },
    username: {
      minWidth: 240,
      boxShadow: '0px 1px 4px  rgba(0, 0, 0, 0.4)',
      borderRadius: '3px'
    },
    email: {
      minWidth: 510,
      boxShadow: '0px 1px 4px  rgba(0, 0, 0, 0.4)',
      borderRadius: '3px'
    },
    yesButton: {
      background: "green",
      color: 'white',
      bottom: '0.2cm',
      marginTop: '0.3cm',
      fontWeight: 'bold',
      right: '15px',
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      display: 'flex',
      marginTop: '0.3cm',
      bottom: '0.2cm',
      fontWeight: 'bold',
      '&:hover': {
        background: "red",
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    clonebutton:{
      borderRadius: 20,
      background: "#215176",
      '&:hover': {
        background: "#215176",
      }
    }
  })
);

const CloneUser: React.FC<{ userRow: IUserInfo, onSave: () => void, checkSuperAdmin: boolean }> = (props) => {
  const { userRow } = props;
  const { state, dispatch } = useGlobalState()
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [localState, setLocalState] = React.useState(newUser);
  var newUser: AccessContext = new AccessContext();
  const classes = useRowStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstnameerror, setFirstNameError] = useState("");
  const [Lastnameerror, setLastNameError] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [phoneerror, setPhoneError] = useState("");
  const [Emailerror, setEmailError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [cloneUser, setCloneUser] = useState(false);
  const [NameVal, setNameVal] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    useFetch<AccessContext>("User/GetUser?userId=" + userRow.id).then((r) => {
      var response = r.data;
      useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userRow.id).then((r) => {
        for (let index = 1; index <= WidgetMap.size; index++) {
          const value = WidgetMap.get(index);
          if (value && r.data.filter(w => w.widgetId === index).length === 1) {
            response[value] = true;
          }
        };
        setLocalState(response);
      });
    });
  }

  const handleClose = () => {
    setOpen(false);
    setFirstName("");
    setLastName("");
  };

  const handleCreate = async () => {

    localState.id = 0;
    let selectedWidgets: number[] = [];
    for (let index = 1; index <= WidgetMap.size; index++) {
      var value = WidgetMap.get(index);
      if (value && localState[value]) {
        selectedWidgets.push(index);
      }
    }
    setShowProgressBar(true);
    usePost<{ userInfo: AccessContext, totalRecordCount: number }>("User/SaveUser", localState).then(response => {
      if (response.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: response.statusText })
      }
      else {
        const data = {
          'userId': response.data,
          'widgets': selectedWidgets.join(',')
        };
        usePost("User/AddWidgetsForClient", data).then((r) => {
          if (r.status == 400) {
            dispatch({ type: GlobalStateAction.Error, error: r.statusText })
          }
          if (r.status == 200) {
            props.onSave();
            setCloneUser(true);
            setOpen(false);
            setShowProgressBar(false);
          }
        });
      }
    }).catch((err) => {
      if (err.response.status == 400) {
        alert(err.response.data);
        setShowProgressBar(false);
        dispatch({ type: GlobalStateAction.Error, error: err.statusText })
      }
    })
  }


  const handleChange = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value
    });
  };

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;
    if (FirstNameVal === "") {
      setFirstNameError("First Name is Required");
      setIsDisable(true);
    }
    else if (!FirstNameVal.match(/[~!@#%^&*()-=+<>,{};|?:[\]\\$'"]/)) {
      setFirstNameError("");
      setIsDisable(false);
      setFirstName(FirstNameVal); // only set when successful
    } else {
      setFirstNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;
    if (LastNameVal === "") {
      setLastNameError("please enter characters only");
      setIsDisable(true);
    }
    else if (!LastNameVal.match(/[~!@#%^&*()-=+<>,{};|?:[\]\\$'"]/)) {
      setLastNameError("");
      setIsDisable(false);
      setLastName(LastNameVal); // only set when successful
    } else {
      setLastNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  const emailRegex = /^([A-Za-z0-9]+(?:[._-][A-Za-z0-9]+)*)@([A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[a-z]{2,6})$/;

  const validateEmail = (e) => {
    const email = e.target.value;
    if (emailRegex.test(email)) {
      setEmailError("");
      setIsDisable(false);
      setEmailVal(email);
    } else {
      setEmailError("please enter valid mail address");
      setIsDisable(true);
    }
  };

  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  const phonenumber = (e) => {
    const phoneValidation = e.target.value;
    if (phoneValidation === "") {
      setPhoneError("");
      setIsDisable(false);
    }
    else if (phoneRegex.test(phoneValidation)) {
      setPhoneError("");
      setIsDisable(false);
    }
    else {
      setPhoneError("please enter valid phone number");
      setIsDisable(true);
    }
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setCloneUser(false);
  };

  useEffect(() => {
    if (firstName === "" || lastName === "") {
      setNameVal(true);
    } else {
      setNameVal(false);
    }
  }, [firstName, lastName, localState])

  function resetValues() {
    setFirstName("");
    setLastName("");
  }


  return (
    <React.Fragment>
      <div>
        <Button id="U_Clone_btn" className={classes.clonebutton} startIcon={<PeopleAltIcon />} variant="contained" color="primary" onClick={handleClickOpen} disabled={props.checkSuperAdmin && state.userAccessContext?.role === 2}>
          Clone
        </Button>
      </div>
      <div>
        <Dialog
          PaperProps={{ style: { borderRadius: 10 } }}
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h5" gutterBottom className={classes.titleheader}>
              CLONE USER {!localState?.firstName.length ?
                <React.Fragment>
                  <Backdrop className={classes.backdrop} open={!localState?.firstName.length}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                  </Backdrop>
                </React.Fragment>
                :
                `(${props.userRow?.firstName + " " + props.userRow?.lastName})`}
            </Typography>
          </DialogTitleHeader>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={100} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <DialogContent >
            <div>
              <form className={classes.root} noValidate autoComplete="off" >
                <TextField id="U_FirstName" label="First Name" variant="outlined" onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }} helperText={firstnameerror} error={!!firstnameerror} name="firstName" className={classes.username} />
                <TextField id="U_LastName" label="Last Name" variant="outlined" onChange={(e) => { handleChange(e); HandleLastNameVal(e); }}  helperText={Lastnameerror} error={!!Lastnameerror} name="lastName" className={classes.username} />
              </form>
            </div>
            <div>
              <form className={classes.root} noValidate autoComplete="off" >
                <TextField id="U_Email" label="Email" variant="outlined" onChange={(e) => { handleChange(e); validateEmail(e) }} helperText={Emailerror} inputProps={{ maxlength: 255 }} error={!!Emailerror} name="userName" className={classes.email} />
              </form>
              <form className={classes.root} noValidate autoComplete="off" >
                <TextField id="U_Phone" label="Phone" variant="outlined"  onChange={(e) => { handleChange(e); phonenumber(e) }} inputProps={{ maxlength: 12 }} helperText={phoneerror} error={!!phoneerror} name="phone" className={classes.email} />
              </form>
            </div>
          </DialogContent>
          <DialogActions style={{ marginRight: '40px' }}>
            <Button id="U_CreateClone_btn" startIcon={<DoneAllIcon />} className={classes.yesButton} variant="contained" onClick={(_) => { handleCreate(); resetValues(); }} color="primary" disabled={((!firstName && !lastName && !emailVal) || isDisable) || NameVal}>
              Clone User
            </Button>
            <Button id="U_Cancel_btn" startIcon={<CancelPresentationIcon />} className={classes.noButton} variant="contained" onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={cloneUser} style={{ color: 'black', marginTop: '-0.5cm' }} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
            User has been cloned Successfully!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}

export default CloneUser