import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import YouTube from '@u-wave/react-youtube';
import React, { useState } from 'react';
import LightTooltip from "../GlobalStyles/LightTooltip";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "100px",
    position: "relative"
  },
  input: {
    width: 42,
  },
  box: {
    display: "flex",
    marginTop: "-40px"
  },
  centerBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  root: {
    width: 250,
  },
  dropdown: {
    padding: 12,
    width: "80%",
    borderRadius: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
}))

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const VideoPlayer: React.FC<{ video: string, title: string }> = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [suggestedQuality, setSuggestedQuality] = useState('auto');
  const [volume, setVolume] = useState(1);
  const [paused, setPaused] = useState(false);
  const qualities = ['auto', '240', '380', '480', '720', '1080', '1440', '2160'];

  function handlePause(event) {
    setPaused(event.target.checked);
  }

  function handlePlayerPause() {
    setPaused(true);
  }

  function handlePlayerPlay() {
    setPaused(false);
  }

  function handleVolume(event) {
    setVolume(parseFloat(event.target.value));
  }

  function handleQuality(event) {
    setSuggestedQuality(qualities[event.target.selectedIndex]);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleBlur = () => {
    if (volume < 0) {
      setVolume(0);
    } else if (volume > 100) {
      setVolume(100);
    }
  };
  return (
    <div>
      <Box component="span" m={1} //margin
        className={`${classes.centerBox} ${classes.box}`} >
        <LightTooltip title="Help Video">
          <IconButton aria-label="help_video" onClick={handleClickOpen}>
            <LiveHelpIcon style={{ fontSize: 33 }} />
          </IconButton>
        </LightTooltip>
      </Box>
      <Dialog onClose={handleClose} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'md'} open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h5" gutterBottom style={{ textAlign: "center", color: "#3333FF", }}>
            <b>{props.title}</b>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col s4">
              <div className="col s8 center-align">
                <YouTube
                  video={props.video}
                  width={930}
                  height={480}
                  autoplay
                  controls={true}
                  showRelatedVideos={false}
                  suggestedQuality={suggestedQuality}
                  volume={volume}
                  paused={paused}
                  onPause={handlePlayerPause}
                  onPlaying={handlePlayerPlay}
                />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VideoPlayer
