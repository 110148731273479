import {
    Backdrop,
    Box, Button, Checkbox, Container, Dialog, DialogContent, DialogContentText, FormControlLabel, Grid, IconButton, makeStyles, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import red from "@material-ui/core/colors/red";
import UpdateIcon from '@material-ui/icons/Update';
import React, { useEffect, useState } from 'react';
import Caution from '../../../images/ADF/complain.png';
import DEVImage from '../../../images/ADF/DEV.png';
import PRODImage from '../../../images/ADF/PROD.png';
import UATImage from '../../../images/ADF/UAT.png';
import { IAdfEnv } from "../../../models/GLobalSettings/IAdfEnv";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '100vw',
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    gridcontainer: {
        maxWidth: '62vw',
        height: '58vh',
        alignItems: "center",
        backgroundColor: '#215176',
        justifyContent: "center",
        borderRadius: 50,
        display: "flex",
        border: '2px solid #215176',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    button: {
        borderRadius: 30,
        fontSize: 21,
        fontWeight: 'bold',
        width: '230px',
        height: '55px',
        marginLeft: '-10px',
        backgroundColor: '#e65100',
        color: 'white',
        border: '2px solid #215176',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
        '&:hover': {
            background: "white",
            color: '#e65100',
            fontWeight: 'bold',
        }
    },
    button1: {
        fontSize: 19,
        color: 'white',
        textTransform: "none",
        fontWeight: 'bold',
        marginBottom: '0.4cm',
        backgroundColor: 'green',
        width: 100,
        height: 40,
        borderRadius: 10,
        padding: 5,
        marginLeft: '10px',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    button2: {
        fontSize: 19,
        color: 'white',
        textTransform: "none",
        backgroundColor: 'red',
        fontWeight: 'bold',
        marginBottom: '0.4cm',
        width: 100,
        height: 40,
        borderRadius: 10,
        marginLeft: '10px',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    title: {
        color: "white",
        textAlign: "center",
        fontWeight: 'bold',
        fontSize: 40
    },
    text: {
        color: "black",
        textAlign: "center",
        marginTop: '10px',
        fontWeight: 'bold',
        fontSize: 19
    },
    card1: {
        display: 'block',
        width: '8cm',
        height: '6cm',
        backgroundColor: 'white',
        marginLeft: '1cm',
        textDecoration: 'none',
        borderRadius: 20,
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
        '&:hover': {
            backgroundColor: 'white',
            borderRadius: 20,
            transform: 'scale(1.1)',
            cursor: 'pointer',
            border: '2px blue solid',
            boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
        },
    },
    IconButtonripple: {
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    image: {
        marginTop: '-0.5cm',
        width: '9vw',
        height: '17vh',
    },
    image1: {
        marginTop: '0.2cm',
        width: '100px',
        height: '100px',
    },
    checkbox: {
        transform: "scale(1.3)",
        color: 'blue'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 20,
        height: '100 %',
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        margin: 0,
        backdropFilter: 'blur(5px)',
    },
    dialogbox: {
        height: '100%',
        border: "3px solid #0D324D",
        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
    },
    dialogheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: '#e65100',
        textAlign: 'center',
        fontSize: 35
    },
    timeout: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black',
        marginTop: '0.5cm',
        fontSize: 27
    },
    countdown: {
        color: red[700],
        marginLeft: '5px'
    },
    divdialog: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}))

const AdfControl = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [adfActivePipeline, setAdfActivePipeline] = useState<IAdfEnv[]>([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [EnableButton, setEnableButton] = React.useState(true);
    const [ChangeEnvProd, setChangeEnvProd] = useState(false);
    const [ChangeEnvUat, setChangeEnvUat] = useState(false);
    const [ChangeEnvDev, setChangeEnvDev] = useState(false);
    const [open, setOpen] = React.useState(false);

    const [seconds, setSeconds] = useState(20);

    useEffect(() => {
        let interval = setInterval(() => {
            seconds > 0 && setSeconds(seconds => seconds - 1);
        }, 1000);
        if (seconds === 0) {
            setEnableButton(false);
        }
        return () => clearInterval(interval);
    }, [seconds])

    function reset() {
        setSeconds(20);
    }

    const handleCheckProd = (event) => {
        setChangeEnvProd(event.target.checked)
    }

    const handleCheckUat = (event) => {
        setChangeEnvUat(event.target.checked)
    }

    const handleCheckDev = (event) => {
        setChangeEnvDev(event.target.checked)
    }

    const handleClickOpen = () => {
        setSeconds(20);
        setOpen(true);
    };

    const handleClose = () => {
        reset();
        setOpen(false);
        setEnableButton(true);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IAdfEnv[]>("GlobalSettings/GetADFEnvironmentDetails")
                setAdfActivePipeline(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [ChangeEnvProd, ChangeEnvUat, ChangeEnvDev])

    async function OnUpdateEnv() {
        dispatch({ type: GlobalStateAction.Busy })
        try {
            let request = {
                "isProduction": ChangeEnvProd,
                "isUAT": ChangeEnvUat,
                "isDEV": ChangeEnvDev,
                "updatedDate": new Date()
            };
            await usePost<any>("GlobalSettings/UpdateADFEnvironment", request)
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    return (
        <React.Fragment>
            <Container fixed className={classes.container}>
                <Grid container spacing={0} className={classes.gridcontainer}>
                    <Grid item xs={12} sm={6} md={4} lg={12} >
                        <Typography variant="h4" gutterBottom className={classes.title}>
                            <b> ADF Environment To Sync With CSS-Impact</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} >
                        <Box className={classes.card1}>
                            <IconButton id="Admin_Page_GlobalMessage_Button" aria-label="global_message" className={classes.IconButtonripple}>
                                <img src={PRODImage} alt="Uat_Production" className={classes.image} />
                            </IconButton>
                            <Box>
                                <FormControlLabel
                                    id="GS_PROD_MODE_Checkbox"
                                    control={<Checkbox className={classes.checkbox} onChange={handleCheckProd} checked={ChangeEnvProd} value={adfActivePipeline[0]?.isProduction} name="isProduction" disabled={true} />}
                                    label={<Typography variant="button" className={classes.text}>PRODUCTION</Typography>}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} >
                        <Box className={classes.card1}>
                            <IconButton id="Admin_Page_GlobalMessage_Button" aria-label="global_message" className={classes.IconButtonripple}>
                                <img src={UATImage} alt="User_Acceptance_Test"  className={classes.image} />
                            </IconButton>
                            <Box>
                                <FormControlLabel
                                    id="GS_UAT_MODE_Checkbox"
                                    control={<Checkbox className={classes.checkbox} onChange={handleCheckUat} checked={ChangeEnvUat} value={adfActivePipeline[0]?.isUAT} name="isUAT"
                                        color="primary" disabled={true} />}
                                    label={<Typography variant="button" className={classes.text}>USER ACCEPTANCE TEST</Typography>}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} >
                        <Box className={classes.card1}>
                            <IconButton id="Admin_Page_GlobalMessage_Button" aria-label="global_message" className={classes.IconButtonripple}>
                                <img src={DEVImage} alt="Development" className={classes.image} />
                            </IconButton>
                            <Box>
                                <FormControlLabel
                                    id="GS_DEV_MODE_Checkbox"
                                    control={<Checkbox className={classes.checkbox} onChange={handleCheckDev} checked={ChangeEnvDev} value={adfActivePipeline[0]?.isDEV} name="isDEV"
                                        color="primary" size="medium" disabled={true} />}
                                    label={<Typography variant="button" className={classes.text}>DEVELOPMENT</Typography>}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Button id="ADF_UpdateButton" className={classes.button} endIcon={<UpdateIcon style={{ fontSize: 30 }} />} variant="contained" color="primary" onClick={handleClickOpen}>
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <Backdrop
                className={classes.backdrop}
                open={open}
            >
                <Dialog
                    PaperProps={{
                        style: {
                            borderRadius: 20,
                        }
                    }}
                    className={classes.dialogbox}
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={'md'}
                >
                    <DialogContent>
                        <Box>
                            <Typography variant="h4" gutterBottom className={classes.dialogheader}>
                                ADF Environment To Sync With CSS-Impact
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <img src={Caution} alt="Caution for Update Environment" className={classes.image1} />
                        </Box>
                        <DialogContentText>
                            <Typography variant="h5" className={classes.timeout}>
                                Are you sure, you want to update the Environment ?
                                <span className={classes.countdown}>{seconds}</span> seconds.
                            </Typography>
                        </DialogContentText>
                        <div className={classes.divdialog}>
                            <Button id="IM_ok_btn" variant="contained" onClick={handleClose} className={classes.button1} autoFocus disabled={EnableButton}>
                                Yes
                            </Button>
                            <Button id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.button2} onClick={handleClose} >
                                No
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </Backdrop>
        </React.Fragment>
    )
}

export default AdfControl


