import { Backdrop, Box, Grid, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Autocomplete } from '@material-ui/lab';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { AccessContext } from '../../../models/accesscontrol/AccessContext';
import { WidgetMap } from '../../../models/accesscontrol/WidgetMap';
import { IClientInfo } from '../../../models/client/IClientInfo';
import { IBlobContainerNames } from "../../../models/Files/IBlobContainerNames";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import CurrencyInput from 'react-currency-input-field';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '24ch',
        marginTop: '20px'
      },
    },
    multiSelectRoot: {
      '& > *': {
        borderBottom: 'unset',
        marginTop: '35px',
        marginBottom: "0%",
        margin: theme.spacing(2),
      },
    },
    formControl: {
      minwidth: 300,
    },
    grid: {
      flexGrow: 1,
      gridGap: theme.spacing(2),
    },
    boxview: {
      display: 'block',
      width: '8.2cm',
      height: '5.5cm',
      marginLeft: '-10px',
      marginTop: '-0.3cm',
      borderRadius: '10px',
      borderColor: 'black',
      '&:hover': {
        color: 'green',
        backgroundColor: 'white',
        transform: 'scale(1)',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px'
      },
    },
    boxview1: {
      display: 'block',
      width: '8.5cm',
      height: '3.5cm',
      marginLeft: '0px',
      marginTop: '10px',
      borderRadius: '10px',
      borderColor: 'black',
      '&:hover': {
        color: 'green',
        backgroundColor: 'white',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',
      },
    },
    boxview2: {
      display: 'block',
      width: '8.5cm',
      height: '4cm',
      marginLeft: '0px',
      marginTop: '1.4cm',
      borderRadius: '10px',
      borderColor: 'black',
      '&:hover': {
        color: 'green',
        backgroundColor: 'white',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',
      },
    },
    boxviewNotifications: {
      display: 'block',
      width: '10.5cm',
      height: '8cm',
      marginLeft: '10px',
      borderRadius: '10px',
      borderColor: 'black',
      '&:hover': {
        backgroundColor: 'white',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',
      },
    },
    boxviewWidgets: {
      display: 'block',
      width: '10.5cm',
      height: '8cm',
      marginLeft: '10px',
      borderRadius: '10px',
      borderColor: 'black',
      '&:hover': {
        backgroundColor: 'white',
        transform: 'scale(1)',
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: '5px',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    titleHeader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white'
    },
    phoneNumberFieldStyle: {
      borderBottom: '3px solid blue',
      borderRadius: '5px',
      minWidth: 410,
      height: '55px',
      fontSize: 16
    },
    dialogactions: {
      float: 'right',
      marginTop: '10px',
      marginBottom: '10'
    },
    submitbtn: {
      borderRadius: 20,
      backgroundColor: 'green',
      "&:hover": {
        backgroundColor: 'green',
      }
    },
    cancelbtn: {
      borderRadius: 20,
      backgroundColor: 'red',
      "&:hover": {
        backgroundColor: 'red',
      }
    }
  })
);

const GA_CreateUser: React.FC<{ onSave: () => void }> = (props) => {
  const { state, dispatch } = useGlobalState();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const multiselectRef = useRef<Multiselect>();
  const [confirmedClients, setConfirmedClients] = React.useState([]);
  var newUser: AccessContext = new AccessContext();
  const [localState, setState] = React.useState(newUser);
  const [isValid, setIsValid] = useState(true);
  const classes = useRowStyles()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstnameerror, setFirstNameError] = useState("");
  const [Lastnameerror, setLastNameError] = useState("");
  const [phoneerror, setPhoneError] = useState("");
  const [Emailerror, setEmailError] = useState("");
  const [isDisableFN, setIsDisableFN] = useState(false);
  const [isDisableLN, setIsDisableLN] = useState(false);
  const [isDisableEmail, setIsDisableEmail] = useState(false);
  const [isDisablePhone, setIsDisablePhone] = useState(false);
  const [ListContainers, setListContainers] = useState<IBlobContainerNames[]>([]);
  const [ListPaymentContainers, setPaymentListContainers] = useState<IBlobContainerNames[]>([]);
  const [ListACKContainers, setACKListContainers] = useState<IBlobContainerNames[]>([]);
  const [showFilePermisson, setShowFilePermission] = useState(false);
  const [showAdfLogs, setShowAdfLogs] = useState(false);
  const [showSendEmailAttachment, setshowSendEmailAttachment] = useState(false);
  const [NameVal, setNameVal] = useState(false);
  const [createUser, setCreateUser] = React.useState(false);
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [multiClientOption, setMultiClientOption] = useState(false);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setCreateUser(false);
  };

  useEffect(() => {
    (confirmedClients.length <= 0) ? setIsValid(false) : setIsValid(false);
  }, [confirmedClients])

  const handleClickOpen = () => {
    setOpen(true);
    useFetch<IClientInfo[]>("Client/GetClientCodeAdmin?userId=" + state?.userAccessContext?.id).then((r) => {
      setConfirmedClients(r.data);
      setShowProgressBar(false);
    })
  };

  const handleClose = () => {
    setOpen(false);
    setIsValid(true);
  };

  const handleCreate = async () => {
    let selectedWidgets: number[] = [];
    for (let index = 1; index <= WidgetMap.size; index++) {
      var value = WidgetMap.get(index);
      if (value && localState[value]) {
        selectedWidgets.push(index);
      }
    }

    let user = localState;
    user.clientCodes = multiselectRef.current.getSelectedItems().map(r => r.client_Code);
    if (multiselectRef.current?.getSelectedItems().map(r => r.client_Code).length <= 0) {
      alert(" Please select atleast one client code");
    } else {
      setShowProgressBar(true);
      usePost<{ userInfo: AccessContext, totalRecordCount: number }>("User/SaveUser", user).then(response => {
        if (response.status == 400) {
          setShowProgressBar(false);
          dispatch({ type: GlobalStateAction.Error, error: response.statusText })
        }
        else {
          const data = {
            'userId': response.data,
            'widgets': selectedWidgets.join(',')
          };

          usePost("User/AddWidgetsForClient", data).then((r) => {
            if (r.status == 400) {
              setShowProgressBar(false);
              alert(r.statusText);
              dispatch({ type: GlobalStateAction.Error, error: r.statusText })
            }
            if (r.status == 200) {
              props.onSave();
              setOpen(false);
              setCreateUser(true);
              setShowProgressBar(false);
              setCreateUser(true);
            }
          });
        }
      }).catch((err) => {
        if (err.response.status == 400) {
          alert(err.response.data);
          setShowProgressBar(false);
          dispatch({ type: GlobalStateAction.Error, error: err.statusText })
        }
      });
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...localState,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
    (localState.role == 0 || localState.role == null) ? setMultiClientOption(false) : setMultiClientOption(true)
  }, [localState.role, multiClientOption])

  const handleChecked = (event) => {
    const name = event.target.name;
    setState({
      ...localState,
      [name]: event.target.checked,
    });
  };

  const handleChangeshowpermission = (event) => {
    setShowFilePermission(event.target.checked)
  }

  const handleChangeShowAdfLogs = (event) => {
    setShowAdfLogs(event.target.checked)
  }

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;
    if (FirstNameVal === "") {
      setFirstNameError("First Name is Required");
      setIsDisableFN(true);
    }
    else if (!FirstNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
      setFirstNameError("");
      setIsDisableFN(false);
      setFirstName(FirstNameVal); // only set when successful
    } else {
      setFirstNameError("please enter characters only");
      setIsDisableFN(true);
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;
    if (LastNameVal === "") {
      setLastNameError("Last Name is Required");
      setIsDisableLN(true);
    }
    else if (!LastNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
      setLastNameError("");
      setIsDisableLN(false);
      setLastName(LastNameVal); // only set when successful
    } else {
      setLastNameError("please enter characters only");
      setIsDisableLN(true);
    }
  };

  const emailRegex = /^([A-Za-z0-9]+(?:[._-][A-Za-z0-9]+)*)@([A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[a-z]{2,6})$/;

  const validateEmail = (e) => {
    const email = e.target.value;
    if (emailRegex.test(email)) {
      setEmailError("");
      setIsDisableEmail(false);
    } else {
      setEmailError("please enter valid mail address");
      setIsDisableEmail(true);
    }
  };

  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

  const phonenumber = (e) => {
    const phoneValidation = e.target.value;

    if (phoneRegex.test(phoneValidation)) {
      setPhoneError("");
      setIsDisablePhone(false);
    }
    else {
      setPhoneError("please enter valid phone number");
      setIsDisablePhone(true);
    }
  }

  const getDefaultContainer = () => {
    let defaultContainer: IBlobContainerNames = {
      id: -1,
      containerNames: 'None',
      containerType: 'Default'
    };
    return defaultContainer;
  }

  async function onContainerSelect(event, value) {
    dispatch({ type: GlobalStateAction.Busy });
    setState({ ...localState, ftpFolder: value?.containerNames });
    try {
      const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers?Id=" + value.id + "&ContainerType=CloudContainer")
      setListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function onPaymentFileSelect(event, value) {
    dispatch({ type: GlobalStateAction.Busy });
    setState({ ...localState, paymentFileFolder: value?.containerNames });
    try {
      const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers?Id=" + value.id + "&ContainerType=PaymentContainer")
      setPaymentListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function onAckFileSelect(event, value) {
    dispatch({ type: GlobalStateAction.Busy });
    setState({ ...localState, ackFileFolder: value?.containerNames });
    try {
      const response = await useFetch<IBlobContainerNames[]>("File/GetAllContainers?Id=" + value.id + "&ContainerType=AckContainer")
      setACKListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function OnOpenCloudFolders() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const response = await useFetch<any>("File/GetAllContainers?ContainerType=CloudContainer");
      response.data.unshift(getDefaultContainer());
      setListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function OnOpenPaymentFolders() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const response = await useFetch<any>("File/GetAllContainers?ContainerType=PaymentContainer");
      response.data.unshift(getDefaultContainer());
      setPaymentListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function OnOpenAckFolders() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      const response = await useFetch<any>("File/GetAllContainers?ContainerType=AckContainer");
      response.data.unshift(getDefaultContainer());
      setACKListContainers(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  const handleshowSendEmailToAdmin = (event) => {
    setshowSendEmailAttachment(event.target.checked);
    if (showSendEmailAttachment === false) {
      setState({ ...localState, sendEmailtoAdmin: false });
    }
  }

  useEffect(() => {
    if (firstName === "" || lastName === "") {
      setNameVal(true);
    } else {
      setNameVal(false);
    }
  }, [firstName, lastName, localState])

  return (
    <React.Fragment>
      <Button id="U_CreateUser" startIcon={<PersonAddIcon />} variant="contained" color="primary" onClick={handleClickOpen} style={{ borderRadius: 20, fontSize: '12px' }}>
        Create New User
      </Button>
      <Dialog
        PaperProps={{ style: { borderRadius: 15 } }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
        className={`${"scrollbox"} ${"on-scrollbar"}`}
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h5" gutterBottom className={classes.titleHeader}>
            CREATE NEW USER
          </Typography>
        </DialogTitleHeader>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogContent >
          <Grid container className={classes.grid}>
            <Grid item xs={3} >
              <form className={classes.root} noValidate autoComplete="off" >
                <TextField id="U_CU_FirstName" required label="First Name" variant="outlined" name="firstName" onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }}
                  helperText={firstnameerror} error={!!firstnameerror} inputProps={{ maxlength: 25 }}
                  style={{ borderBottom: '3px solid blue', borderRadius: '10px' }}
                  autoComplete='off' />
                <TextField id="U_CU_LastName" required label="Last Name" variant="outlined" name="lastName"
                  onChange={(e) => { handleChange(e); HandleLastNameVal(e); }} helperText={Lastnameerror}
                  error={!!Lastnameerror} inputProps={{ maxlength: 25 }}
                  style={{ borderBottom: '3px solid blue', borderRadius: '10px' }}
                  autoComplete='off' />
              </form>

              <div>
                <form className={classes.root} noValidate autoComplete="off" >
                  <TextField id="U_CU_Email" required label="Email" variant="outlined" name="userName" onChange={(e) => { handleChange(e); validateEmail(e) }} helperText={Emailerror} error={!!Emailerror} inputProps={{ maxlength: 50 }} style={{ borderBottom: '3px solid blue', borderRadius: '10px', minWidth: 410 }}
                    autoComplete='off' />
                </form>
                <form className={classes.root} noValidate autoComplete="off" >
                  <CurrencyInput
                    className={classes.phoneNumberFieldStyle}
                    id="U_CU_Phone"
                    placeholder="Phone Number"
                    allowNegativeValue={false}
                    maxLength={12}
                    onChange={(e) => { handleChange(e); phonenumber(e); }}
                    autoComplete='off'
                    name="phone"
                    disableGroupSeparators={true}
                    allowDecimals={false}
                  />
                </form>
              </div>
              <div style={{ width: 410 }} >
                <FormControl variant="filled" className={classes.multiSelectRoot} style={{ minWidth: '410px' }}>
                  <Multiselect id="clientCode"
                    ref={multiselectRef}
                    options={confirmedClients}
                    closeOnSelect={false}
                    showArrow={true}
                    displayValue="client_Code"
                    placeholder="Select Client"
                    label="Select Client"
                    showCheckbox={true}
                    required
                  />
                </FormControl>
                {isValid ?
                  <Typography variant="subtitle1" style={{ color: "red", marginLeft: '13px' }} display="block" gutterBottom>
                    Please select atleast one client code
                  </Typography> : null
                }
              </div>
              {state.userAccessContext?.role === 3 && localState.role == 3 ?
                <React.Fragment>
                  <DialogTitle id="responsive-dialog-title" style={{ marginTop: '-12px' }} >{"ADMIN PERMISSION"}</DialogTitle>
                  <FormControl component="fieldset">
                    <Box className={classes.boxview1} style={{ marginTop: '-10px', marginBottom: '-50px' }}>
                      <FormGroup style={{ marginLeft: '0.5cm' }}>
                        {state.userAccessContext?.role === 3 ?
                          <FormControlLabel
                            id="U_CU_IsGlobalSettings"
                            control={<Checkbox onChange={(e) => { handleChecked(e); handleChangeShowAdfLogs(e); }} name="isGlobalSettings" />}
                            label="Global Settings"
                          />
                          : null
                        }
                        {showAdfLogs && state.userAccessContext?.role === 3 || localState.isGlobalSettings && localState.role == 3 ?
                          <React.Fragment>
                            <FormControlLabel style={{ marginTop: '-10px', marginLeft: '10px' }}
                              id="U_CU_IsAdfLogs"
                              control={<Checkbox onChange={handleChecked} name="isAdfLogs" />}
                              label="ADF Logs"
                            />
                            <FormControlLabel style={{ marginTop: '-10px', marginLeft: '10px' }}
                              id="U_CU_IsAzureFunc"
                              control={<Checkbox onChange={handleChecked} name="isAzureFunc" />}
                              label="Azure Functions"
                            />
                            <FormControlLabel style={{ marginTop: '-10px', marginLeft: '10px' }}
                              id="U_CU_IsUserLoginStatus"
                              control={<Checkbox onChange={handleChecked} name="isUserLoginStatus" />}
                              label="User Login Status"
                            />
                          </React.Fragment>
                          :
                          null
                        }
                      </FormGroup>
                    </Box>
                  </FormControl>
                </React.Fragment>
                : null
              }
              {showFilePermisson ?
                <React.Fragment>
                  <Box className={classes.boxview2}>
                    <DialogTitle id="responsive-dialog-title">{"FILE PERMISSIONS"}</DialogTitle>
                    <FormControl component="fieldset" >
                      <FormGroup>
                        <FormControlLabel style={{ marginLeft: '5px', marginTop: '-20px' }}
                          id="U_CU_UploadFile"
                          control={<Checkbox onChange={handleChecked} name="uploadfile" />}
                          label="Upload"
                        />
                        <FormControlLabel style={{ marginLeft: '5px', marginTop: '-10px' }}
                          id="U_CU_Download"
                          control={<Checkbox onChange={handleChecked} name="downloadfile" />}
                          label="Download"
                        />
                        <FormControlLabel style={{ marginLeft: '5px', marginTop: '-10px' }}
                          id="U_CU_Create"
                          control={<Checkbox onChange={handleChecked} name="createfolder" />}
                          label="Create Folder"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" >
                      <FormGroup>
                        <FormControlLabel style={{ marginTop: '-15px' }}
                          id="U_CU_DeleteFile"
                          control={<Checkbox onChange={handleChecked} name="deletefile" />}
                          label="Delete File"
                        />
                        <FormControlLabel
                          id="U_CU_DeleteFolder"
                          control={<Checkbox onChange={handleChecked} name="deletefolder" />}
                          label="Delete Folder"
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </React.Fragment>
                : null
              }

            </Grid>
            <Grid item xs={2} >
              <DialogTitle id="responsive-dialog-title" >{"PERMISSION"}</DialogTitle>
              <FormControl component="fieldset">
                <FormGroup >
                  <FormControlLabel style={{ marginTop: '-10px' }}
                    id="U_CU_paymentUpdates"
                    control={<Checkbox onChange={handleChecked} name="paymentUpdates" />}
                    label="Payment Updates"
                  />
                  <FormControlLabel
                    id="U_CU_closingAccounts"
                    control={<Checkbox onChange={handleChecked} name="closingAccounts" />}
                    label="Close Accounts"
                  />
                  <FormControlLabel
                    id="U_CU_rollAccounts"
                    control={<Checkbox onChange={handleChecked} name="rollAccounts" />}
                    label="Roll Accounts"
                  />
                  <FormControlLabel
                    id="U_CU_placeAccounts"
                    control={<Checkbox onChange={handleChecked} name="placeAccounts" />}
                    label="Place Accounts"
                  />
                  <FormControlLabel
                    id="U_CU_editReports"
                    control={<Checkbox onChange={handleChecked} name="editReports" />}
                    label="Edit Reports"
                  />
                  <FormControlLabel
                    id="U_CU_createNewUsers"
                    control={<Checkbox onChange={handleChecked} name="createNewUsers" />}
                    label="Create New Users"
                  />
                  <FormControlLabel
                    id="U_CU_uploadUnmappedFile"
                    control={<Checkbox onChange={handleChecked} name="uploadUnmappedFile" />}
                    label="Upload Unmapped File"
                  />
                  <FormControlLabel
                    id="U_CU_sendEmailOnAttachements"
                    control={<Checkbox onChange={(e) => { handleChecked(e); handleshowSendEmailToAdmin(e); }} name="sendEmailOnAttachements" />}
                    label="Send Email on attachments"
                  />
                  <FormControlLabel
                    id="U_CU_sendEmailtoAdmin"
                    control={<Checkbox onChange={handleChecked} name="sendEmailtoAdmin" disabled={!showSendEmailAttachment} />}
                    label="Send Email To Admin"
                  />
                  <FormControlLabel
                    id="U_CU_multiplePlacementAccess"
                    control={<Checkbox onChange={handleChecked} name="multiplePlacementAccess" />}
                    label="Multiphase Placement Access"
                  />
                  <FormControlLabel
                    id="U_CU_multiClientCodePlacementAccess"
                    control={<Checkbox onChange={handleChecked} name="multiClientCodePlacementAccess" disabled={!multiClientOption} />}
                    label="Multi-Client Code Placement Access"
                  />
                  <FormControlLabel
                    id="U_CU_clientConfirmation"
                    control={<Checkbox onChange={handleChecked} name="clientConfirmation" />}
                    label="Client Confirmation"
                  />
                  <FormControlLabel
                    id="U_CU_FileAccess"
                    control={<Checkbox onChange={(e) => { handleChecked(e); handleChangeshowpermission(e); }} name="isFileAccess" />}
                    label="Manage File Access"
                  />

                  <FormControl variant="outlined" className={classes.formControl} style={{ borderBottom: '3px solid blue', borderRadius: '10px', marginTop: '15px' }}>
                    <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                    <Select native
                      id="U_CU_adminLevel"
                      onChange={handleChange}
                      value={localState.role}
                      label="Admin Level"
                      inputProps={{
                        name: 'role',
                        id: 'outlined-age-native-simple',
                      }}
                    >
                      <option value={0}>Regular User</option>
                      <option value={1}>Client Admin</option>
                      <option value={2}>Global Admin</option>
                      {state.userAccessContext?.role === 3 ?
                        <React.Fragment>
                          <option value={3}>Super Admin</option>
                          <option value={4}>File Admin</option>
                        </React.Fragment>
                        : null
                      }

                    </Select>
                  </FormControl>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} >
              <Box className={classes.boxviewNotifications}>
                <DialogTitle id="responsive-dialog-title">{"NOTIFICATIONS"}</DialogTitle>
                <FormControl component="fieldset" >
                  <FormGroup>
                    <FormControlLabel style={{ marginLeft: '5px', marginTop: '-20px' }}
                      id="U_CU_disputeValidationNotification"
                      control={<Checkbox onChange={handleChecked} name="disputeValidationNotification" />}
                      label="Dispute Validation Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_paymentVerificationNotification"
                      control={<Checkbox onChange={handleChecked} name="paymentVerificationNotification" />}
                      label="Payment Verification Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_fileIssuesNotification"
                      control={<Checkbox onChange={handleChecked} name="fileIssuesNotification" />}
                      label="File Issues Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_backupDocumentRequestNotification"
                      control={<Checkbox onChange={handleChecked} name="backupDocumentRequestNotification" />}
                      label="Backup Document Request Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_settlementRequestsNotification"
                      control={<Checkbox onChange={handleChecked} name="settlementRequestsNotification" />}
                      label="Settlement Requests Notification"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_generalNotification"
                      control={<Checkbox onChange={handleChecked} name="generalNotification" />}
                      label="General Notifications"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box className={classes.boxviewWidgets}>
                <DialogTitle id="responsive-dialog-title">{"WIDGETS"}</DialogTitle>
                <FormControl component="fieldset" >
                  <FormGroup>
                    <FormControlLabel style={{ marginLeft: '5px', marginTop: '-20px' }}
                      id="U_CU_flatFeeAcRem"
                      control={<Checkbox onChange={handleChecked} name="flatFeeAcRem" checked={localState.flatFeeAcRem} />}
                      label="Flat Fee A/C Rem"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_acWaitingtoMove"
                      control={<Checkbox onChange={handleChecked} checked={localState.acWaitingtoMove} name="acWaitingtoMove" />}
                      label="A/C waiting to move"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_daysSincePlacmentofAc"
                      control={<Checkbox onChange={handleChecked} checked={localState.daysSincePlacmentofAc} name="daysSincePlacmentofAc" />}
                      label="Days Since Placement of A/C"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_placementHistoryofMonthAc"
                      control={<Checkbox onChange={handleChecked} checked={localState.placementHistoryofMonthAc} name="placementHistoryofMonthAc" />}
                      label="Placement History of 6 Month A/C"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_ageImpactofonAcResolution"
                      control={<Checkbox onChange={handleChecked} checked={localState.ageImpactofonAcResolution} name="ageImpactofonAcResolution" />}
                      label="Age Impact of on A/C resolution"
                    />
                    <FormControlLabel style={{ marginLeft: '5px' }}
                      id="U_CU_unReadNotifications"
                      control={<Checkbox onChange={handleChecked} checked={localState.unReadNotifications} name="unReadNotifications" />}
                      label="Unread Notifications"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={3} >
              <DialogTitle id="responsive-dialog-title">{"FOLDER PERMISSIONS"}</DialogTitle>
              <Box className={classes.boxview}>
                <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px', marginLeft: '15px' }}>Cloud File Folder</Typography>
                <Autocomplete
                  id="U_CU_Cloud_Dropdown"
                  style={{ width: '280px', marginTop: '-10px' }}
                  autoComplete autoHighlight
                  options={ListContainers} onChange={onContainerSelect} loading={true} onOpen={OnOpenCloudFolders}
                  getOptionLabel={(option) => option.containerNames}
                  getOptionSelected={(option, value) => option.containerNames === value.containerNames}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Cloud Folder" margin="normal" variant="outlined" style={{ borderBottom: '3px solid black', borderRadius: '10px', marginLeft: '15px' }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField id="outlined-basic" label="Colud File Contains" variant="outlined" name="ftpRegex" onChange={(e) => { handleChange(e); }} style={{ width: '280px', marginTop: '20px', borderBottom: '3px solid blue', borderRadius: '10px', marginLeft: '15px' }} />
              </Box>
              <Box className={classes.boxview}>
                <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px', marginLeft: '15px' }}>Payment File Folder</Typography>
                <Autocomplete
                  id="U_CU_Payment_Dropdown"
                  style={{ width: '280px', marginTop: '-10px' }}
                  autoComplete autoHighlight
                  options={ListPaymentContainers} onChange={onPaymentFileSelect} loading={true} onOpen={OnOpenPaymentFolders}
                  getOptionLabel={(option) => option.containerNames}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Payment Folder" margin="normal" variant="outlined" style={{ borderBottom: '3px solid black', borderRadius: '10px', marginLeft: '15px' }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField id="outlined-basic" label="Payment File Contains" name="paymentRegex" variant="outlined" onChange={(e) => { handleChange(e); }} style={{ width: '280px', marginTop: '20px', borderBottom: '3px solid blue', borderRadius: '10px', marginLeft: '15px' }} />
              </Box>
              <Box className={classes.boxview}>
                <Typography variant="subtitle1" gutterBottom style={{ marginTop: '10px', marginLeft: '17px' }}>Acknowledgement File Folder</Typography>
                <Autocomplete
                  id="U_CU_ACK_Dropdown"
                  style={{ width: '280px', marginTop: '-10px', marginLeft: '15px' }}
                  autoComplete autoHighlight
                  options={ListACKContainers} onChange={onAckFileSelect} loading={true} onOpen={OnOpenAckFolders}
                  getOptionLabel={(option) => option.containerNames}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Acknowledgement Folder" margin="normal" variant="outlined" style={{ borderBottom: '3px solid black', borderRadius: '10px' }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField id="outlined-basic" label="Acknowledgement File Contains" variant="outlined" name="reportRegex" onChange={(e) => { handleChange(e); }} style={{ width: '280px', marginTop: '20px', borderBottom: '3px solid blue', borderRadius: '10px', marginLeft: '15px' }} />
              </Box>
            </Grid>
          </Grid>
          <DialogActions>
            <Button id="U_CU_Submit_btn" startIcon={<DoubleArrowIcon />} onClick={handleCreate} className={classes.submitbtn}
              disabled={(!localState || isValid || isDisableFN || isDisableLN || isDisableEmail || isDisablePhone) || NameVal}
              color="primary" variant="contained" >
              Submit
            </Button>
            <Button id="U_CU_Close_btn" startIcon={<CancelPresentationIcon />} onClick={handleClose} color="primary" variant="contained" className={classes.cancelbtn}>
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={createUser} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
          User {localState.firstName}{localState.lastName} is Created Successfully!
        </Alert>
      </Snackbar>
    </React.Fragment >
  );
}

export default GA_CreateUser