import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IUserInfo } from '../../../models/accesscontrol/IUserInfo';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch } from '../../../utils/apiHelper';
import { AccessContext } from '../../../models/accesscontrol/AccessContext'
import { IWidgetInfo } from '../../../models/accesscontrol/IWidgetInfo';
import { WidgetMap } from '../../../models/accesscontrol/WidgetMap';
import { useHistory } from 'react-router-dom'
import { DialogTitle, Typography } from '@material-ui/core';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    button: {
      borderRadius: "20px",
      background: "#215176",
      '&:hover': {
        background: "#215176",
      }
    },
    yesButton: {
      background: "green",
      color: 'white',
      borderRadius: 20,
      bottom: '0.1cm',
      right: '15px',
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      borderRadius: 20,
      display: 'flex',
      right: '10px',
      bottom: '0.1cm',
      '&:hover': {
        background: "red",
      }
    }
  })
);

const ImpersonateUser: React.FC<{ userRow: IUserInfo, onImpersonate: () => void, checkSuperAdmin: boolean }> = (props) => {
  const { state, dispatch } = useGlobalState()
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles()
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    useFetch<AccessContext>("User/GetUser?userId=" + props.userRow.id).then((r) => {
      var response = r.data;
      useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + response.id).then((r) => {
        for (let index = 1; index <= WidgetMap.size; index++) {
          const value = WidgetMap.get(index);
          if (value && r.data.filter(w => w.widgetId === index).length === 1) {
            response[value] = true;
          }
          dispatch({
            type: GlobalStateAction.SetPermissions, userAccessContext: response
          })
        };
        setOpen(false);
        props.onImpersonate();
        if (response.role === 2 || response.role === 3) {
          history.push('/admin')
        } else if (response.role === 0 || response.role === 1) {
          history.push('/dashboard');
        }
        else {
          history.push('/ManageClientFolderAccess');
        }
      });
    });
  }

  return (
    <div>
      <Button id="U_Impersonate" startIcon={<SupervisedUserCircleIcon />} className={classes.button} variant="contained" color="primary" onClick={handleClickOpen} disabled={props.checkSuperAdmin && state.userAccessContext?.role === 2}>
        Impersonate
      </Button>
      <Dialog
        PaperProps={{ style: { borderRadius: 15 } }}
        maxWidth={'md'}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
            IMPERSONATE
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h5" style={{ marginTop: "10px", color: "black" }} gutterBottom>
              Are you sure, you want to Impersonate {props.userRow.userName} user ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="IM_ok_btn" variant="contained" onClick={handleOk} className={classes.yesButton} color="primary" autoFocus>
            Yes
          </Button>
          <Button id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.noButton} onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImpersonateUser