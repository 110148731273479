import { createStyles } from '@material-ui/core';

const cardBodyStyle = createStyles({
  cardBody: {
    padding: '50px',
    flex: '1 1 auto',
    WebkitBoxFlex: 1,
    position: 'absolute',
    marginTop: '-40px'
  },
  cardBodyPlain: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  cardBodyProfile: {
    marginTop: '-10px'
  }
});

export default cardBodyStyle;
