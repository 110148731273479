import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React from 'react';
import { INotificationCenter } from "../../models/NotificationCenter/INotificationCenter";
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import LightTooltip from '../GlobalStyles/LightTooltip';

const useStyles = makeStyles((theme) => ({
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    button: {
        borderRadius: "20px",
    },
    button1: {
        background: "red",
        borderRadius: "20px",
        color: 'white'
    }
}))

const HideNotification: React.FC<{ Notify: INotificationCenter, onHide: () => void }> = (props) => {
    const { Notify } = props;
    const { state, dispatch } = useGlobalState();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const HIDENOF = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "UserNotificationId": Notify.userNotificationId,
                };
                await usePost("HideNotification", request)
                props.onHide();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()

    }
    return (
        <React.Fragment>
            <LightTooltip title="Hide">
                <IconButton id="NC_HideIcon" color="secondary" aria-label="upload picture" component="span" disabled={Notify.hiddenFlag ? true : false} onClick={handleClickOpen}>
                    <VisibilityOffIcon />
                </IconButton>
            </LightTooltip>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                PaperProps={{ style: { borderRadius: 10 } }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        FILE ISSUE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" style={{ color: "black" }} gutterBottom>
                        Are you sure, you want to Hide {Notify.notificationCategoryTitle} Notification ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} variant="contained" color="primary" className={classes.button}>
                        No
                    </Button>
                    <Button onClick={(_) => { HIDENOF(); handleClose(); }} color="primary" variant="contained" autoFocus className={classes.button1}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}

export default HideNotification
