import * as React from 'react'
import { IReportsList } from '../../models/Reports/ReportsList';
import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DeleteReport from './DeleteReport';
import ReportShare from './ReportShare';
import moment from 'moment';
import { useGlobalState } from '../../store/GlobalStore';
import { IReportOperator } from '../../models/Reports/IReportOperator';
import { IReportField } from '../../models/Reports/IReportField';
import EditReport from "./EditReport";

const useStyles = makeStyles((theme) => ({
    dateFieldStyle: {
        marginLeft: '15px',
        color: 'black'
    }
}))

const ReportsList: React.FC<{
    row: IReportsList, selected: boolean, onClick: (id: string) => void, onCreate: () => void,
    onDelete: () => void, clients?: any, states?: any, statuses?: any, phases?: any, isValid?: boolean,
    operators?: IReportOperator[], fieldLists?: IReportField[]
}> = (props) => {
    const classes = useStyles()
    const { row, clients, states, statuses, phases, isValid, operators, fieldLists } = props;
    const { state, dispatch } = useGlobalState();
    let path = `/reportsBuilder/${row.id.toString()}/${row.name.toString()}`;
    const createdDate = moment(row.date_created).format(state.GlobalUtils?.settingValue);

    return (
        <React.Fragment>
            <TableRow style={{ height: '5px' }}>
                <TableCell onClick={(_) => props.onClick(row.id)} style={{ width: 550, }}>
                    <Typography style={{ color: 'black', height: '10px' }} component={Link} to={path}>{row.name}</Typography>
                </TableCell>

                <TableCell onClick={(_) => props.onClick(row.id)}  >
                    <Typography className={classes.dateFieldStyle} component={Link} to={path}>{createdDate}</Typography>
                </TableCell>

                <TableCell>
                    <EditReport getReports={props.onCreate} reportId={row.id} reportRow={row}
                        clients={clients} states={states} statuses={statuses} phases={phases} isValid={isValid}
                        operators={operators} fieldLists={fieldLists} />
                </TableCell>

                <TableCell align="left" >
                    <ReportShare reportRow={row} reportName={row.name} />
                </TableCell>

                <TableCell>
                    <DeleteReport reportRow={row} onDelete={props.onDelete} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default ReportsList