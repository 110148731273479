import DateFnsUtils from '@date-io/date-fns';
import {
  Backdrop, Button, CircularProgress, FormControl, Grid, IconButton, makeStyles, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../../components/Footer/Footer';
import clearIcon from '../../../images/buttonIcons/clearIcon.svg';
import { IPipelineDetails } from '../../../models/GlobalUtil/IPipelineDetails';
import { IPipelineLogDetails } from '../../../models/GlobalUtil/IPipelineLogDetails';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 290,
  },
  buttons: {
    borderRadius: 20,
    marginLeft: '-1.5cm'
  },
  textField: {
    //marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "10px",
    marginLeft: '20px',
    width: 350
  },
  grid: {
    padding: '8px'
  },
  snackbar: {
    width: "120%"
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid gray'
  },
  filter: {
    marginTop: '20px'
  },
  root: {
    flexGrow: 1,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  Title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tablebody: {
    maxHeight: 535
  }
}))

const AdfPipelineLogs = () => {
  const classes = useStyles()
  const { state, dispatch } = useGlobalState()
  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

  const [PipelineDetails, setPipelineDetails] = useState<IPipelineDetails[]>([]);
  const [AdfLogsHistory, setAdfLogsHistory] = useState<IPipelineLogDetails[]>([]);
  const [SelectPipeline, setSelectPipeline] = useState<any>();

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const [StartDate, setStartDateFrom] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [selectedFirstDate, setSelectedFirstDate] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [clearFilter, setClearFilter] = React.useState(false);
  const [mapped, setMapped] = useState(false);

  const [orderBy, setOrderBy] = React.useState(" ");
  const [order, setOrder] = React.useState("asc");

  const pipelineLoading = PipelineDetails.length === 0;

  function resetvalues() {
    setStartDateFrom(null);
    setEndDate(null);
    setSelectPipeline(null);
    setPage(0);
    setSelectedPipeline(false);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setClearFilter(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setshowProgressBar(true);
    setPage(newPage);
  };

  const handleFirstDateChange = (date: Date | null) => {
    setStartDateFrom(date);
    setSelectedFirstDate(true);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setshowProgressBar(true);
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        let request = {
          "runDateFrom": null,
          "runDateTo": null,
          "pipelineName": null,
          "startRecordNumber": (page * rowsPerPage) + 1,
          "rowperpage": rowsPerPage,
        };
        const response = await usePost<{ adfLogs: IPipelineLogDetails[], qty: number }>("GlobalSettings/GetADFPipelineLogs", request)
        setAdfLogsHistory(response.data["adfLogs"]);
        setshowProgressBar(true);
        setTotalRecords(response.data.qty);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
        setshowProgressBar(false);
      }
    })()
  }, [])

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const GetPipelineOptions = await useFetch<IPipelineDetails[]>("GlobalSettings/GetADFPipelineDetails")
        setPipelineDetails(GetPipelineOptions.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }, [])

  const onPipelineSelect = (event, selected) => {
    if (selected) {
      const selectedValue = PipelineDetails.find(x => x.pipelineName === selected.pipelineName);
      setSelectPipeline(selectedValue.pipelineName);
      setSelectedPipeline(true);
      setPage(0);
    }
    else {
      setSelectPipeline({ pipelineName: "", activityType: "" });
    }
  };

  useEffect(() => {
    getADFHistoryAsync()
  }, [page, rowsPerPage, order, orderBy])

  const TriggerFilter = () => {
    getADFHistoryAsync();
    setOpen(true);
  }

  async function getADFHistoryAsync() {
    dispatch({ type: GlobalStateAction.Busy })
    setshowProgressBar(true);
    try {
      let request = {
        "runDateFrom": (selectedFirstDate ? StartDate : null),
        "runDateTo": (selectedEndDate ? EndDate : null),
        "pipelineName": (selectedPipeline ? (SelectPipeline.pipelineName === "" ? null : SelectPipeline) : null),
        "startRecordNumber": (page * rowsPerPage) + 1,
        "rowperpage": rowsPerPage,
      };
      const response = await usePost<{ adfLogs: IPipelineLogDetails[], qty: number }>("GlobalSettings/GetADFPipelineLogs", request)
      setAdfLogsHistory(response.data["adfLogs"]);
      setMapped(!response.data["adfLogs"].length ? true : false);
      setTotalRecords(response.data.qty);
      setshowProgressBar(false);

    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function ClearADFHistoryAsync() {
    dispatch({ type: GlobalStateAction.Busy })
    setshowProgressBar(true);
    try {
      let request = {
        "runDateFrom": null,
        "runDateTo": null,
        "pipelineName": null,
        "startRecordNumber": (page * rowsPerPage) + 1,
        "rowperpage": rowsPerPage,
      };
      const response = await usePost<{ adfLogs: IPipelineLogDetails[], qty: number }>("GlobalSettings/GetADFPipelineLogs", request)
      if (response.status == 200) {
        setClearFilter(true);
      }
      setAdfLogsHistory(response.data["adfLogs"]);
      setMapped(!response.data["adfLogs"].length ? true : false);
      setTotalRecords(response.data.qty);
      resetvalues();
      setshowProgressBar(false);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ background: "#007FFF", color: "white", fontSize: "120%" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ marginLeft: `${headCell.marginLeft}` }}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const headCells = [
    { id: "triggername", numeric: false, disablePadding: false, label: "TRIGGER NAME" },
    { id: "pipeline", numeric: false, disablePadding: false, label: "PIPELINE NAME", sortable: true },
    { id: "triggertime", numeric: false, disablePadding: false, label: "TRIGGER DATE TIME", sortable: true, marginLeft: '-10px' },
    { id: "triggertype", numeric: false, disablePadding: false, label: "TRIGGER TYPE", sortable: true },
    { id: "adfname", numeric: false, disablePadding: false, label: "ADF NAME", sortable: true },
  ];

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Grid container className={classes.grid} style={{ backgroundColor: 'whitesmoke' }}>
          <Grid item xs={5} className={classes.Title} >
            <Typography variant="h4" gutterBottom style={{ color: "blue" }}>
              <b> ADF ACTIVITY LOGS</b>
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.Title}>
            {StartDate > EndDate ? <Typography variant='h6' style={{ color: 'red' }}>Invalid End Date.</Typography> : ""}
          </Grid>
          <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }}
            open={open} className={classes.snackbar} anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" style={{ fontSize: 16 }}>
              Filter Successfully Triggered!
            </Alert>
          </Snackbar>
          <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }}
            open={clearFilter} className={classes.snackbar} anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" style={{ fontSize: 16 }}>
              Filter Cleared Successfully !
            </Alert>
          </Snackbar>
          <Grid container className={classes.maingrid} justify='space-evenly' >
            <Grid item xs={6} sm={5} md={3} >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    inputVariant="outlined"
                    variant="inline"
                    label="Start Date"
                    format={DateFormat}
                    className={classes.textField}
                    margin="normal"
                    id="UpHis_StartDate"
                    value={StartDate}
                    onChange={handleFirstDateChange}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={5} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    variant="inline"
                    label="End Date"
                    format={DateFormat}
                    className={classes.textField}
                    margin="normal"
                    id="UpHis_EndDate"
                    value={EndDate}
                    onChange={handleEndDateChange}
                    inputVariant="outlined"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={5} md={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                  id="UpHis_SelectUsers"
                  autoComplete autoHighlight
                  options={PipelineDetails} onChange={onPipelineSelect}
                  getOptionLabel={(option) => option.pipelineName}
                  style={{ width: '430px', marginTop: '-6px' }}
                  loading={pipelineLoading}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span> {option.pipelineName}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Pipeline Name" margin="normal" variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {pipelineLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={6} sm={5} md={2} className={classes.filter}>
                <Button id="UpHis_Filter_btn" className={classes.buttons}
                  onClick={() => { TriggerFilter(); }} variant="contained" startIcon={<FilterAltIcon />} color="primary" disabled={StartDate > EndDate}>
                  Filter
                </Button>
                <Button id="UpLog_Filter_Button" className={classes.buttons} startIcon={<img src={clearIcon} alt="Icon for clear filter" />} style={{ marginLeft: '50px', marginTop: '-1px' }}
                  onClick={() => { ClearADFHistoryAsync(); setshowProgressBar(true); }} variant="contained" color="primary" >
                  Clear Filter
                </Button>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid item xs={6} sm={5} md={2} alignContent='flex-end'>
                <IconButton id="UpHis_FilterIcon_btn" aria-label="Filter" style={{ color: 'blue' }} className={classes.filter} disabled={StartDate > EndDate} >
                  <FilterListIcon style={{ fontSize: 35 }} />
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
          <Grid component={Paper} item xs={12} style={{ marginTop: '10px' }}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table aria-label="customized table" size="small" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(AdfLogsHistory, getComparator(order, orderBy)).map(
                    (row) => {
                      return (
                        <TableRow style={{ height: 55, fontSize: 17 }} >
                          <TableCell component="th" scope="row">
                            {row.triggerName}
                          </TableCell>

                          <TableCell>
                            {row.pipelineName}
                          </TableCell>

                          <TableCell>
                            {moment(row.triggerTime).format(state.GlobalUtils?.settingValue)}
                            {moment(row.triggerTime).format(' HH:mm:ss')}
                          </TableCell>

                          <TableCell>
                            {row.triggerType}
                          </TableCell>

                          <TableCell>
                            {row.adfName}
                          </TableCell>

                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {mapped === true ?
                < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                  No records to display..
                </Typography>
                : null}
            </TableContainer>
            <TablePagination
              id="UpHis_TablePagination"
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        <Footer />
      </div>
    </React.Fragment >
  )
}

export default AdfPipelineLogs
