import React, { useEffect, useState } from 'react';
import {
    AppBar,
    Backdrop,
    Box, Button, ButtonGroup, Container, CssBaseline, Dialog, DialogActions, DialogContent,
    DialogContentText,  Grid, IconButton, makeStyles, Slide, Toolbar, Typography
} from '@material-ui/core';
import { useDelete, useFetch, usePost } from '../../../utils/apiHelper';
import { useGlobalState, GlobalStateAction } from '../../../store/GlobalStore';
import { TransitionProps } from '@material-ui/core/transitions';
import PaidLetter from "../../Inquiry/LetterDetails/PaidLetter";
import CreditReportNotMentioned from "../../Inquiry/LetterDetails/CreditReportingNotMentioned";
import CreditReportNotAffected from "../../Inquiry/LetterDetails/CreditReportingNotAffected";
import CreditReportUpdated from "../../Inquiry/LetterDetails/CreditReportUpdated";
import { IFileGet } from "../../../models/Roll/IFileGet";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { DropzoneArea } from 'material-ui-dropzone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { IClosedLetterDetails } from '../../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { IDocumentDownloadFileResult } from '../../../models/UploadLog/IDocumentDownloadFileResult';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import PuffLoader from 'react-spinners/PuffLoader';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const useStyles = makeStyles((theme) => ({
    head: {
        display: "flex",
        flexDirection: "column",
        borderSpacing: "1cm",
        width: 375,
        color: "primary",
        marginLeft: '2.5cm'
    },
    groupedOutlined: {
        "&:not(:first-child)": {
            marginLeft: -1
        },
        "&:not(:last-child)": {
            borderRightColor: "rgba(0, 0, 0, 0.23)"
        }
    },
    button: {
        paddingBottom: "10px",
        paddingTop: "10px",
        width: 165,
        borderRadius: 10,
        marginTop: "-40px",
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    appBar: {
        position: 'relative',
    },
    Documents: {
        border: "2px solid black",
        borderRadius: 8,
        height: "60%",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    cancelButton: {
        backgroundColor: "red",
        color: "white",
        right: "50px",
        borderRadius: 20,
        fontSize: 15,
        marginBottom: '0.3cm',
        '&:hover': {
            backgroundColor: "red",
            color: "white",
        }

    },
    snackbar: {
        color: 'black',
        marginTop: '-0.5cm',
    },
    snackbarfont: {
        fontSize: 16,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PVDocumentUpload: React.FC<{ clientId: string, letterDetails: IClosedLetterDetails, agencyId: string, accountId: string, onHideNotification: (dbtNo: string) => void, BlockAwaitAccess: boolean }> = (props) => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;
    const [FileDisplay, setFileDisplay] = useState<IFileGet[]>([]);
    const [open, setOpen] = React.useState(false);
    const [undoUpload, setUndoUpload] = React.useState(false);
    const [openDocs, setOpenDocs] = React.useState(false);
    const NameofUser = state.userAccessContext?.userName;
    const base = ("data:application/pdf;base64,");
    const [serviceURL, setServiceURL] = useState('');
    const [ProgressBar, setshowProgressBar] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDocs = () => {
        setOpenDocs(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setUndoUpload(false);
    };

    const onSubmit = (selectedFile: File) => {
        if (selectedFile) {
            if (selectedFile?.type === 'image/jpeg' || selectedFile?.type === 'application/pdf') {
                (async () => {
                    dispatch({ type: GlobalStateAction.Busy })
                    const formData = new FormData();
                    let dateTime = new Date(Date.now());
                    let userId = UserName.toString();
                    const currentDate = moment(dateTime).format('MMDDyy');
                    const fileExtension = selectedFile.name.split('.').pop();
                    const fileName = `${props.agencyId}-${currentDate}${props.clientId}-BU.${fileExtension}`;
                    formData.append("FormFile", selectedFile);
                    formData.append("ClientId", props.clientId);
                    formData.append("file_name", fileName);
                    formData.append("userid", userId);
                    formData.append("rid", props.agencyId)
                    formData.append("upload_date", dateTime.toString());
                    formData.append("file_size", "100");
                    try {
                        const response = await usePost<any>("File/FileInsert", formData)
                        props.onHideNotification(props.agencyId);
                        getFileDisplayAsync();

                        //Email will trigger when a document is uploaded
                        let sendEmail = {
                            "debtorNumber": props.agencyId,
                            "clientId": props.clientId,
                            "clientNo": props.accountId,
                            "userName": NameofUser,
                            "fileName": selectedFile.name
                        }
                        await usePost<any>("SendDocumentEmail", sendEmail);
                    }
                    catch (ex) {
                        dispatch({ type: GlobalStateAction.Error, error: ex })
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                    finally {
                        dispatch({ type: GlobalStateAction.Idle })
                    }
                })()
            }
            else {
                alert('Invalid File Type. Please select a PDF or JPG file.')
            }
        }
    }

    async function getFileDisplayAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            const responseFile = await useFetch<IFileGet[]>("File/FileDetails?DBTNumber=" + props.agencyId);
            setFileDisplay(responseFile.data);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    async function FileDeleteAsync(file_Id: string) {
        dispatch({ type: GlobalStateAction.Busy });
        setshowProgressBar(true);
        try {
            await useDelete<any>("File/FileDelete?fileId=" + file_Id);
            getFileDisplayAsync();
            setUndoUpload(false);
            setshowProgressBar(false);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    function getPDFFileAsync(file_Id: string) {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            setshowProgressBar(true);
            try {
                const responseDownloadFile = await useFetch<IDocumentDownloadFileResult>(`DocumentUploadLog/DocumentFileDownload?fileId=${file_Id}`);
                let url = URL.createObjectURL(base64toBlob(base + responseDownloadFile.data.fileContent));
                setServiceURL(url);
                setOpenDocs(true);
                setshowProgressBar(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })();

        const base64toBlob = (data: string) => {
            // Cut the prefix `data:application/pdf;base64` from the raw base 64
            const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
            const bytes = window.atob(base64WithoutPrefix);
            let length = bytes.length;
            let out = new Uint8Array(length);
            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }
            return new Blob([out], { type: 'application/pdf' });
        };
    }
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <React.Fragment>
            <Button id="I_DocumentUpload" variant="contained" color="primary" startIcon={<UploadFileIcon/>} className={classes.button}
                onClick={(_) => { getFileDisplayAsync(); handleClickOpen(); }} disabled={props.BlockAwaitAccess}
                style={{ color: "white" }} >
                Document
            </Button>
            <Snackbar className={classes.snackbar} open={undoUpload} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className={classes.snackbarfont}>
                    File Deleted Successfully!
                </Alert>
            </Snackbar>
            {openDocs ?
                <Dialog fullScreen open={openDocs} onClose={handleCloseDocs} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={() => setOpenDocs(false)} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Document Viewer
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js">
                        <Viewer
                            fileUrl={serviceURL}
                            plugins={[defaultLayoutPluginInstance]}
                        />
                    </Worker>
                </Dialog> : null}
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth={'lg'}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" className={classes.titleheader}>
                        DOCUMENT UPLOAD
                    </Typography>
                </DialogTitleHeader>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent style={{ marginTop: '1cm' }}>
                    <DialogContentText id="alert-dialog-slide-description">
                        <CssBaseline />
                        <Container>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <DropzoneArea
                                        onChange={(files) => onSubmit(files[0])}
                                        acceptedFiles={['image/*', 'application/pdf']}
                                        showPreviews={false}
                                        showPreviewsInDropzone={false}
                                        filesLimit={1}
                                    />
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="h5" style={{ color: "black", textAlign: 'center' }}> <b>Documents</b></Typography>
                                    <Box p={0} width="100%" flexShrink={0} bgcolor="white"  >
                                        <Scrollbars autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={20}
                                            autoHeight
                                            className={classes.Documents}
                                            autoHeightMin={160}
                                            autoHeightMax={160}
                                            style={{ width: "100%" }}>
                                            {!state.busy && !FileDisplay.length ?
                                                < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                                    No Documents to display..
                                                </Typography>
                                                : null}
                                            {FileDisplay.map((f) => {
                                                let enable = f.file_Name.split('.').pop() === 'pdf' ? true : f.file_Name.split('.').pop() === 'PDF' ? true : false
                                                return (
                                                    <React.Fragment>
                                                        <Typography variant="subtitle2" style={{ marginLeft: "1cm", color: "black", marginTop: '10px' }}> {f.file_Name} - {moment(f.upload_Date).format(state.GlobalUtils?.settingValue)}
                                                            {state?.userAccessContext?.role === 3 ?
                                                                <IconButton color="primary" aria-label="upload picture" style={{ fontSize: 10 }} onClick={() => { FileDeleteAsync(f.file_Id); setUndoUpload(true); }}>
                                                                    <LightTooltip title="Delete">
                                                                        <CloseIcon />
                                                                    </LightTooltip>
                                                                </IconButton>
                                                                : null
                                                            }
                                                            <IconButton disabled={!enable} aria-label="delete" onClick={handleClickOpen}>
                                                                <LightTooltip title="Preview">
                                                                    <VisibilityIcon fontSize="large" onClick={() => { getPDFFileAsync(f.file_Id); }}
                                                                        style={!enable ? { fontSize: 24, color: '#AEAEAE' } : { fontSize: 24, color: '#1E90FF' }} />
                                                                </LightTooltip>
                                                            </IconButton>
                                                        </Typography>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Scrollbars>
                                    </Box>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="h5" style={{ color: "black", textAlign: 'center', marginLeft: '2cm' }}> <b>Request a letter</b> </Typography>
                                    <ButtonGroup
                                        id="I_D_Letters"
                                        fullWidth
                                        aria-label="full width outlined button group"
                                        className={classes.head}
                                        classes={{ groupedOutlined: classes.groupedOutlined }} >
                                        <PaidLetter dbtNumber={props.agencyId} letterDetails={props.letterDetails} />
                                        <CreditReportNotMentioned dbtNumber={props.agencyId} letterDetails={props.letterDetails} />
                                        <CreditReportUpdated dbtNumber={props.agencyId} letterDetails={props.letterDetails} />
                                        <CreditReportNotAffected dbtNumber={props.agencyId} letterDetails={props.letterDetails} />
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Container>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button className={classes.cancelButton} id="I_D_Close_btn" variant="outlined" onClick={handleClose} >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}

export default PVDocumentUpload


