
import { Backdrop, Box, Button, Grid, IconButton, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PersonIcon from '@material-ui/icons/Person';
import SendIcon from '@material-ui/icons/Send';
import { Autocomplete } from '@material-ui/lab';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import React, { useEffect, useRef, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import Footer from '../../../components/Footer/Footer';
import { IClientCodeName } from "../../../models/SendNotification/IClientCodeName";
import { IInsertNotification } from '../../../models/SendNotification/IInsertNotification';
import { IStaticText } from "../../../models/SendNotification/IStaticText";
import { IUserForClient } from "../../../models/SendNotification/IUserForClient";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  root2: {
    border: "2px solid #215176",
    borderRadius: 8,
    height: "19vh",
    width: '28vw',
    marginLeft: "10px",
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  container: {
    marginTop: '0.1cm',
    backgroundColor: 'white',
    maxWidth: "98vw",
    marginBottom: "10px",
    border: '2px solid #215176',
    alignItems: "center",
    height: '76vh',
    borderRadius: 30,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  ulstyle: {
    listStyleType: "none",
  },
  gridstyle: {
    border: '2px #215176 solid',
    marginTop: "0.6cm",
    borderRadius: 10,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
  },
  searchIcon: {
    borderRadius: 50,
    background: "white",
    marginTop: '0.3cm',
    width: "16vw",
    height: '50px',
    border: '2px #215176 solid',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    fontSize: 16,
    textIndent: '10px',
    float: 'left',
    marginLeft: '1.2cm',
  },
  searchIcon1: {
    borderRadius: 50,
    background: "white",
    marginTop: '0.3cm',
    width: "16vw",
    height: '50px',
    border: '2px #215176 solid',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    fontSize: 16,
    textIndent: '10px'
  },
  paper: {
    width: 300,
    height: 300,
    overflow: 'auto',
    overflowX: 'hidden',
    borderRadius: 10,
    borderTop: '2px #215176 solid',
    marginTop: '10px',
  },
  description: {
    width: '27vw',
    border: '2px #215176 solid',
    borderRadius: 10,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  div: {
    flexGrow: 1
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  selectReason: {
    width: "27vw",
    marginLeft: "10px",
    marginTop: '0px',
    border: '2px #215176 solid',
    borderRadius: 10,
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  sendNotification: {
    height: "44px",
    marginTop: '0.3cm',
    width: "16vw",
    borderRadius: 50,
    float:'right',
    marginRight:'1.3cm',
    // marginLeft: '4.6cm',
    fontSize: 16,
    fontWeight: 'bold'
  },
  clientname: {
    fontSize: 18,
    marginTop: '5px'
  },
  clientlist: {
    textAlign: 'left',
    fontSize: 16
  },
  clientcheckbox: {
    color: "#00e676",
    width: 50,
    height: 38
  },
  titleHeader: {
    color: "blue",
    textAlign: 'center',
  },
  selectallbutton: {
    marginTop: "3.5cm",
    width: "9vw",
    height: "40px",
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 20
  },
  removeallbutton: {
    marginTop: "1cm",
    width: "9vw",
    height: "40px",
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 20
  },
  snackbar1: {
    color: 'black',
    marginTop: '-0.5cm',
  },
  snackbarFont: {
    fontSize: 16
  },
  label: {
    '&$focused': {
      color: 'white',
      border: '0px'
    },
  },
  focused: {},
  outlinedInput: {
    '&$focused $notchedOutline': {
      color: 'white',
      border: '0px'
    },
  },
  notchedOutline: {
    color: 'white',
    border: '0px'
  },
}));

export default function NativeSelects() {
  const classes = useStyles();
  const fileUpload = useRef(null);
  const { state, dispatch } = useGlobalState();
  const [ClientCodes, setClientCodes] = useState<IClientCodeName[]>([]);
  const [UsersForClient, setUsersForClient] = useState<IUserForClient[]>([]);
  const [SelectedClientCodes, setSelectedClientCodes] = useState<IClientCodeName[]>([]);
  const [SelectedUsers, setSelectedUsers] = useState<IUserForClient[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [Description, setDescription] = useState<string>("");
  const [SearchTerm, setSearchTerm] = useState("");
  const [UserSearchTerm, setUserSearchTerm] = useState("");
  const [SelectStaticText, setSelectStaticText] = useState<IStaticText[]>([]);
  const [SelectTextType, setSelectTextType] = useState<any>();
  const [searchText, setSearchText] = useState<string>('');
  const [isValidReason, setIsValidReason] = useState(true);
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const getUsersForClient = (clientCodes) => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": clientCodes
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setshowProgressBar(true);
        setUsersForClient(responseUser.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const handleClientCodeToggle = (client: IClientCodeName) => () => {
    let newSelectedClientCodes = [...SelectedClientCodes]

    if (newSelectedClientCodes.some(c => c.clientCode == client.clientCode)) {
      newSelectedClientCodes = newSelectedClientCodes.filter(c => c.clientCode != client.clientCode);
    } else {
      newSelectedClientCodes.push(client);
    }

    let clientcodes = "";
    for (var checkClientCode in newSelectedClientCodes) {
      clientcodes += newSelectedClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }

    setSelectedClientCodes(newSelectedClientCodes);
    getUsersForClient(clientcodes);
    setSelectedClient(clientcodes);
    setshowProgressBar(true);
  }

  const handleUserToggle = (user: IUserForClient) => () => {
    let newSelectedUsers = [...SelectedUsers];
    if (newSelectedUsers.some(u => u.id == user.id)) {
      newSelectedUsers = newSelectedUsers.filter(u => u.id != user.id);
    } else {
      newSelectedUsers.push(user);
    }
    setSelectedUsers(newSelectedUsers);
  }

  const handleAllRight = () => {
    let clientcodes = "";
    for (var checkClientCode in ClientCodes) {
      clientcodes += ClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    getUsersForClient(clientcodes);
    setSelectedClientCodes(ClientCodes)
    setshowProgressBar(false);
  };

  const handleAllLeftClient = () => {
    let clientcodes = "";
    getUsersForClient(clientcodes);
    setSelectedClientCodes([]);
    setshowProgressBar(false);
  }

  const handleCheckedRight = () => {
    setSelectedUsers(UsersForClient);
    setshowProgressBar(false);
  };

  const handleAllLeft = () => {
    setSelectedUsers([]);
    setshowProgressBar(false);
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let response = await useFetch<IStaticText[]>('GetStaticText');
        setSelectStaticText(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  const GetClientLists = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const response = await useFetch<IClientCodeName[]>(`GetClientSearchList?searchText=${searchText}`);
        setClientCodes(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  async function getClientAsync() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      let response = await useFetch<IClientCodeName[]>('GetClientCodeList');
      setClientCodes(response.data);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  async function onStaticTextSelect(event, value) {
    try {
      const response = await useFetch<IStaticText[]>('GetStaticTextById?id=' + value.id)
      setIsValidReason(false);
      setSelectTextType(value.statictext);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
    }
  }

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let response = await useFetch<IClientCodeName[]>('GetClientCodeList');
        setshowProgressBar(true);
        setClientCodes(response.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  const clearClientLists = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let response = await useFetch<IClientCodeName[]>('GetClientCodeList');
        setClientCodes(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const getUsersClient = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": selectedClient
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setshowProgressBar(true);
        setUsersForClient(responseUser.data);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const getClientUsersAsync = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      try {
        let request = {
          "client_code": selectedClient,
          "name": SearchTerm
        }
        let responseUser = await usePost<IUserForClient[]>("GetUsersForClient", request);
        setUsersForClient(responseUser.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const GetSelectedUserID = () => {
    let userIds = "";
    SelectedUsers.forEach((user) => {
      userIds += user.id;
      userIds += ","
    })
    return userIds.substr(0, userIds.length - 1);
  }

  function reset() {
    setDescription("");
    setSelectedFile(null);
  }

  const onSubmit = () => {
    if (selectedFile) {
      (async () => {
        dispatch({ type: GlobalStateAction.Busy })
        const formData = new FormData();
        formData.append("TypeId", "3");
        formData.append("ExtraData1", GetSelectedUserID());
        formData.append("ExtraData2", SelectTextType);
        formData.append("ExtraData3", Description);
        formData.append("ExtraData4", "");
        formData.append("File", selectedFile);
        try {
          dispatch({ type: GlobalStateAction.Busy })
          const response = await usePost<IInsertNotification>("InsertAdminNotification", formData)
          setOpen(true);
          reset();
        }
        catch (ex) {
          dispatch({ type: GlobalStateAction.Error, error: ex })
          dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
          dispatch({ type: GlobalStateAction.Idle })
        }
      })()
    }
  }

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.target.files && setSelectedFile(event.target.files[0])
  }

  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  const clientCodeList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" gutterBottom color="secondary" style={{ fontSize: 17 }} >
        <b>CLIENTS</b>
      </Typography>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {ClientCodes.slice(0, 150).map((client, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleClientCodeToggle(client)}>
                <ListItemIcon>
                  <Checkbox
                    size="medium"
                    style={{ color: "#00e676", width: 50, height: 38 }}
                    id="SA_ClientCode_Checkbox"
                    checked={SelectedClientCodes.some(c => c.clientCode == client.clientCode)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom style={{ textAlign: 'left', fontSize: 16 }}>
                    <b>{client.clientCode}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  const usersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" style={{ fontSize: 17 }} gutterBottom color="secondary">
        <b>USERS</b>
      </Typography>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {UsersForClient.filter(({ firstName, lastName }) => (firstName + lastName).toLowerCase().includes(UserSearchTerm.toLowerCase())).map((user, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleUserToggle(user)}>
                <ListItemIcon>
                  <Checkbox
                    style={{ color: "#00e676", width: 50, height: 38 }}
                    id="NF_Users_Checkbox"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    checked={SelectedUsers.some(u => u.id == user.id)}
                  />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom style={{ textAlign: 'left', fontSize: 16, width: 500, }}>
                    <b>{user.firstName}{user.lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  const selectedUsersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" style={{ fontSize: 17, borderRadius: '10px' }} gutterBottom color="secondary" >
        <b>ADD TO NOTIFICATION</b>
      </Typography>
      <Paper className={classes.paper} style={{ marginTop: "10px" }}>
        <List dense component="div" role="list">
          {SelectedUsers.map(({ firstName, lastName }, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem">
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <Typography variant="body2" gutterBottom style={{ textAlign: 'left', fontSize: 16 }}>
                    <b>{firstName}{lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Typography variant="h4" gutterBottom className={classes.titleHeader}>
          <b> NEW FILE UPLOAD</b>
        </Typography>
        <Container fixed className={classes.container}>
          <Grid container spacing={0} style={{ marginTop: '1cm' }}>
            <Grid item xs={4} >
              <TextField
                id="NF_Description"
                placeholder="Enter the Description..."
                size="medium"
                multiline
                rows={7}
                value={Description}
                onChange={handleChange}
                inputProps={{ maxlength: 256 }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                className={classes.description}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    focused: classes.focused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} >
              <Box alignItems="center" display="flex" justifyContent="center" >
                <Typography variant="button" display="block" gutterBottom className={classes.root2}>
                  <IconButton aria-label="delete" disabled={true} style={{ color: "black" }}>
                    <CloudUploadIcon fontSize="large" />
                  </IconButton>
                  <Typography variant="button" display="block" gutterBottom >
                    Select a file
                  </Typography>
                  <ul className={classes.ulstyle}>
                    <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                      <Button style={{ borderRadius: 20, marginRight: "1cm", background: "#007FFF", color: "white" }} component="label"
                        variant="contained" startIcon={<CloudUploadIcon />}>
                        Choose File
                        <input
                          id="NF_SelectFile"
                          onChange={changeHandler}
                          name="file"
                          ref={fileUpload}
                          hidden
                          multiple type="file"
                        />
                      </Button>
                    </form>
                  </ul>
                  <Typography variant="subtitle1" gutterBottom style={{ marginTop: "-7px" }}>
                    {selectedFile ? selectedFile.name : ""}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} >
              <Autocomplete
                id="NF_SelectReason"
                autoComplete autoHighlight
                options={SelectStaticText}
                onChange={onStaticTextSelect}
                getOptionLabel={(option) => option.statictext}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Reason" margin="normal" variant="outlined"
                    className={classes.selectReason}
                    inputProps={{
                      ...params.inputProps,
                      classes: {
                        root: classes.outlinedInput,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline,
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: '0.3cm' }}>
            <Grid item xs={4}>
              <input value={searchText}
                type='text' placeholder="Search Clients"
                className={classes.searchIcon}
                onChange={e => setSearchText(e.target.value)}
                onKeyPress={event => { if (event.key === 'Enter') { GetClientLists(); } }}
                maxLength={255}
              />
            </Grid>
            <Grid item xs={4}>
              <input value={SearchTerm}
                type='text' placeholder="Search Users"
                className={classes.searchIcon1}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyPress={event => { if (event.key === 'Enter') { getClientUsersAsync(); } }}
                maxLength={255}
              />
            </Grid>
            <Grid item xs={4}>
              <Button id="NF_SendNotification" className={classes.sendNotification} endIcon={<SendIcon />}
                disabled={!Description || !selectedFile || !SelectedUsers || isValidReason} variant="contained" color="primary"
                onClick={(_) => { onSubmit(); }} >
                Send Notification
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={0} justify='space-evenly'>
            <Grid item xs={5} sm={6} md={2} className={classes.gridstyle} >
              {clientCodeList()}
            </Grid>
            <Grid item xs={5} sm={4} md={2}>
              <Button
                id="NF_SelectAll_btn"
                className={classes.selectallbutton}
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAllRight}
                aria-label="move all right"
                startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />} >
                Select All
              </Button>
              <Button
                id="NF_RemoveAllClient_btn"
                variant="contained"
                color="primary"
                size="small"
                className={classes.removeallbutton}
                onClick={handleAllLeftClient}
                disabled={SelectedClientCodes.length === 0}
                aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />} >
                Remove All
              </Button>
            </Grid>
            <Grid item xs={5} sm={6} md={2} className={classes.gridstyle} >
              {usersList()}
            </Grid>
            <Grid item xs={5} sm={5} md={2}>
              <Button
                id="NF_AddAll_btn"
                variant="contained"
                color="primary"
                className={classes.selectallbutton}
                size="small"
                onClick={handleCheckedRight}
                aria-label="move selected right" startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />}>
                Add All
              </Button>
              <Button
                id="NF_RemoveAll_btn"
                variant="contained"
                color="primary"
                className={classes.removeallbutton}
                size="small"
                onClick={handleAllLeft}
                aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />}>
                Remove All
              </Button>
            </Grid>
            <Grid item xs={5} sm={5} md={2} className={classes.gridstyle} >
              {selectedUsersList()}
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
      <Snackbar open={open} className={classes.snackbar1} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" className={classes.snackbarFont}>
          File Uploaded Successfully !
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}