import {
  Avatar,
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TableSortLabel, Toolbar, Tooltip, Typography
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, lighten, Theme, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import CreateUser from "../../components/Settings/CreateUser";
import DeleteUser from "../../components/Settings/DeleteUser";
import EditUser from "../../components/Settings/EditUser";
import InviteUsersforCA from "../../components/Settings/InviteUsersforCA";
import GlobalAdmin from '../../images/users/ClientAdmin.svg';
import FileAdmin from '../../images/users/FileAdmin.svg';
import ClientAdmin from '../../images/users/GlobalAdmin.svg';
import RegularUser from '../../images/users/RegularUser.svg';
import SuperAdmin from '../../images/users/SuperAdmin.svg';
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';


const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    clientAdmin: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    globalAdmin: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    superAdminColor: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    regularUser: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    fileAdmin: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid gray',
    },
    formControl: {
      minwidth: 300,
      position: "absolute",
    },
    table: {
      minWidth: 700,
    },
    h2: {
      marginLeft: '1%',
      marginTop: '40px',
    },
    grid: {
      padding: '10px'
    },
    maingrid: {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    div: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    searchBox: {
      borderRadius: 20,
      background: "white",
      border: '2px black solid',
      width: '35cm',
      height: '43px',
      fontSize: 16,
      textIndent: '10px'
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    TextHighlight: {
      color: 'black',
      fontWeight: 'bold',
      background: "yellow",
      fontSize: 16,
    },
    TextNormal: {
      color: 'black',
      fontSize: 16
    },
    tablebody: {
      maxHeight: 615
    },
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected, rowCount
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: "", disablePadding: false, label: "", sortable: false },
    { id: "firstName", disablePadding: false, label: "First Name", sortable: true },
    { id: "lastName", disablePadding: false, label: "Last Name", sortable: true },
    { id: "userName", disablePadding: false, label: "Email", sortable: true },
    { id: "", disablePadding: false, label: "", sortable: false },
    { id: "", disablePadding: false, label: "Actions", sortable: false },
    { id: "", disablePadding: false, label: "Delete", sortable: false },
  ]

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all Users' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ background: "#007FFF", color: "white", fontSize: "120%" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function UserAccessControl() {
  const classes = useRowStyles();
  const { state, dispatch } = useGlobalState();
  const [usersList, setUsersList] = useState<IUserInfo[]>([])
  const currentUserClientCodes = state.userAccessContext?.clientCodes.join(",");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("userName");
  const [searchText, setSearchText] = useState<string>('')
  const [selected, setSelected] = React.useState<number[]>([]);
  const [ProgressBar, setshowProgressBar] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = usersList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    (newSelected);
  };

  const handleRowClicked = (event) => { };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSaveWithUserInfo = (userInfo: IUserInfo) => {
    getUsersAsync();
  };

  const handleSave = () => {
    getUsersAsync();
  };

  const handleDeleteSuccess = () => {
    getUsersAsync();
  };

  useEffect(GetUsers(), [])

  function GetUsers(): React.EffectCallback {
    return () => {
      getUsersAsync();
    };
  }

  function getUsersAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const response = await useFetch<IUserInfo[]>("User/UsersForClientAdmin?clientCodes=" + currentUserClientCodes + "&SearchParameters=" + searchText);
        setshowProgressBar(true);
        setUsersList(response.data);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
        setshowProgressBar(false);
      }
    })();
  }

  function getUserInitials(name: string): string {
    const bySpace = name.split(' ')
    if (bySpace.length > 1) {
      return bySpace[0][0] + bySpace[1][0]
    } else return name.slice(0, 2).toUpperCase()
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container spacing={1} className={classes.grid} style={{ backgroundColor: 'whitesmoke' }} wrap="wrap">
          <Grid item xs={6} sm={2}>
            <Typography variant="h4" gutterBottom style={{ color: "blue", textAlign: 'left' }}>
              <b> MANAGE USERS</b>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={8}>
            <input value={searchText}
              type='text' placeholder="Search Users"
              className={classes.searchBox}
              onChange={e => setSearchText(e.target.value.trim())}
              onKeyPress={event => { if (event.key === 'Enter') { getUsersAsync(); } }}
              maxLength={255}
            />
          </Grid>
          <Grid item xs={6} sm={2}>
            <Box display="flex" justifyContent="flex-end">
              <CreateUser onSave={handleSave} />
            </Box>
          </Grid>
          <Grid item xs={12} component={Paper}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table size="small" aria-label="customized table" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={usersList.length}
                />
                <TableBody>
                  {stableSort(usersList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow key={index} hover selected={isItemSelected} onClick={(event) => handleRowClicked(event)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.global_Admin === 3 ?
                              <AvatarTooltip title="Super Admin">
                                <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                              </AvatarTooltip>
                              : row.global_Admin === 2 ?
                                <AvatarTooltip title="Global Admin">
                                  <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                </AvatarTooltip>
                                : row.global_Admin === 1 ?
                                  <AvatarTooltip title="Client Admin">
                                    <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                  </AvatarTooltip>
                                  : row.global_Admin === 0 ?
                                    <AvatarTooltip title="Regular User">
                                      <Avatar className={classes.regularUser} src={RegularUser}>{getUserInitials(row.userName)}</Avatar>
                                    </AvatarTooltip>
                                    : <AvatarTooltip title="File Admin">
                                      <Avatar className={classes.fileAdmin} src={FileAdmin}>{getUserInitials(row.userName)}</Avatar>
                                    </AvatarTooltip>
                            }
                          </TableCell>
                          <StyledTableCell component="th" scope="row">
                            <span className={`${searchText === "" ? classes.TextNormal : row.firstName.includes(searchText.charAt(0).toUpperCase() + searchText.slice(1)) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.firstName}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            <span className={`${searchText === "" ? classes.TextNormal : row.lastName.includes(searchText.charAt(0).toUpperCase() + searchText.slice(1)) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.lastName}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell component="th" align="left">
                            <span className={`${searchText === "" ? classes.TextNormal : row.userName.includes(searchText) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.userName}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <InviteUsersforCA userRow={row} onInvite={handleSave} />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <EditUser userRow={row} onSave={handleSaveWithUserInfo} onRemove={handleSave} />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <DeleteUser userRow={row} onDelete={handleDeleteSuccess} checkSuperAdmin={row.global_admin === 3} />
                          </StyledTableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              id="UAC_TablePagination"
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={usersList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        <Footer />
      </div>
    </React.Fragment>
  )
}
