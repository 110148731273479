import DateFnsUtils from '@date-io/date-fns';
import { Backdrop, Box, Card, CardContent, DialogActions, Grid, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { IGlobalMessage } from "../../../models/GlobalMessage/IGlobalMessage";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
        display: "flex",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 280,
      marginTop: '-7px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    titleHeader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white'
    },
    card: {
      width: '350px',
      height: '280px',
      padding: '3px',
      marginLeft: "90px",
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    Notificationbtn: {
      borderRadius: 20,
      marginRight: "10px"
    },
    createNotification: {
      borderRadius: '20px',
      marginBottom: '0.5cm',
      marginTop: '0.3cm'
    },
    snackbar: {
      color: 'black',
      marginTop: '-0.5cm'
    },
    notificationName: {
      minWidth: 450,
      boxShadow: '0px 1px 4px  rgba(103, 128, 159, 1)',
      borderRadius: '3px',
      marginLeft: '20px',
    },
    notificationMessage: {
      minWidth: 450,
      boxShadow: '0px 1px 4px  rgba(103, 128, 159, 1)',
      borderRadius: '3px',
      marginLeft: '20px',
      marginTop: '20px'
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateNotification: React.FC<{ onSave: () => void }> = (props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useRowStyles()
  const { state, dispatch } = useGlobalState()
  const [MessageName, setMessageName] = useState<string>("");
  const [Message, setMessage] = useState<string>("");
  const [StartDate, setStartDate] = useState<any>();
  const [EndDate, setEndDate] = useState<any>();
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isValidDate, setValidDate] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

  let dateTime = new Date(Date.now());

  const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsCreate(false);
  };
  const ErrorMessage = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsError(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    emptyCheck();
  };

  function emptyCheck() {
    setMessageName('');
    setMessage('');
    setStartDate(new Date());
    setEndDate(new Date());
  }

  useEffect(() => {
    if (EndDate < StartDate) {
      setValidDate(true);
    }
    else if (StartDate === EndDate) {
      setValidDate(false);
    }
    else {
      setValidDate(false);
    }
  }, [StartDate, EndDate])

  const createNotification = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      try {
        let request = {
          "name": MessageName,
          "message": Message,
          "start_date": StartDate,
          "end_date": EndDate,
          "date_created": dateTime
        };
        const response = await usePost<IGlobalMessage>('CreateGlobalMessage', request);
        if (response.status == 400) {
          setIsError(true);
          dispatch({ type: GlobalStateAction.Error, error: response.statusText })
        }
        props.onSave();
        setIsCreate(true);
        setShowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  const HandleMessageVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,:;{}/|[\]\\$'"]/)) {
      setError("");
      setIsValid(false);
      setMessageName(newValue);
    } else {
      setError("please enter characters and numbers only");
      setIsValid(true);
    }
  };
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };
  const handleLastDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end">
        <Button id="GM_CreateNotification" startIcon={<NotificationAddIcon />} className={classes.Notificationbtn} variant="contained" color="primary" onClick={handleClickOpen}>
          Create Notification
        </Button>
      </Box>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={100} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Dialog fullScreen={fullScreen} open={open} onClose={(_) => { handleClose(); emptyCheck(); }} aria-labelledby="responsive-dialog-title"
        fullWidth={true} maxWidth={'md'} TransitionComponent={Transition} PaperProps={{ style: { borderRadius: 15 } }}
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h5" gutterBottom className={classes.titleHeader}>
            CREATE NEW NOTIFICATION
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Grid container spacing={0} >
            <Grid xs={6} style={{ marginTop: '25px' }}>
              <form noValidate autoComplete="off"  >
                <TextField id="GM_Notification_Name" label="Notification Name" variant="outlined" value={MessageName}
                  onChange={(e) => { setMessageName(e.target.value); HandleMessageVal(e); }} className={classes.notificationName}
                  helperText={error} inputProps={{ maxlength: 100 }}
                  error={!!error} />
              </form>
              <TextField id="GM_Message" label="Message" variant="outlined" value={Message} onChange={(e) => setMessage(e.target.value)}
                className={classes.notificationMessage} multiline rows={9} inputProps={{ maxlength: 512 }} />
            </Grid>
            <Grid xs={6} style={{ marginTop: '25px' }}>
              <Card className={classes.card} >
                <CardContent>
                  <h3 className={classes.textField}>From:</h3>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      label="Start Date"
                      variant="inline"
                      inputVariant="outlined"
                      format={DateFormat}
                      disableToolbar
                      autoOk={true}
                      margin="normal"
                      id="GM_StartDate"
                      value={StartDate}
                      onChange={handleStartDateChange}
                      className={classes.textField}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <h3>To:</h3>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      label="End Date"
                      variant="inline"
                      inputVariant="outlined"
                      format={DateFormat}
                      disableToolbar
                      autoOk={true}
                      margin="normal"
                      id="GM_EndDate"
                      value={EndDate}
                      onChange={handleLastDateChange}
                      className={classes.textField}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      inputProps={(params) => (
                        <TextField {...params} helperText={params?.inputProps?.placeholder} />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </CardContent>
                {isValidDate ?
                  <Typography variant="subtitle2" style={{ color: 'red', textAlign: 'center' }}>
                    Please enter a date after the previous value
                  </Typography>
                  : null
                }
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginRight: '28px' }}>
          <Button id="GM_Create_btn" startIcon={<NotificationAddIcon />} autoFocus onClick={(_) => { createNotification(); handleClose(); setMessageName(""); setMessage(""); setStartDate(""), setEndDate(""); emptyCheck(); }} color="primary" variant="contained" disabled={!Message || !MessageName || !StartDate || !EndDate || isValid || isValidDate}
            className={classes.createNotification}>
            Create Notification
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar className={classes.snackbar} open={isCreate} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={UpdateMessage}>
        <Alert onClose={UpdateMessage} severity="success" style={{ fontSize: 16 }}>
          Notification Created Successfully.
        </Alert>
      </Snackbar>
      <Snackbar className={classes.snackbar} open={isError} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={ErrorMessage}>
        <Alert onClose={ErrorMessage} severity="error" style={{ fontSize: 16 }}>
          Notification Not Created .
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default CreateNotification