import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { usePost, useFetch } from '../../../utils/apiHelper';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore'
import { IUserInfo } from '../../../models/accesscontrol/IUserInfo';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
                marginBottom: "0%",
                margin: theme.spacing(2),
                width: '25ch',
            },
        },
        formControl: {
            minwidth: 300,
            position: "absolute",
        },
        button:{
            borderRadius: 20,
            background: "#215176",
            '&:hover': {
              background: "#215176",
            }
          }

    })
);

const InviteUser: React.FC<{ userRow: IUserInfo, onInvite: () => void, checkSuperAdmin: boolean }> = (props) => {
    const theme = useTheme();
    const classes = useRowStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state, dispatch } = useGlobalState();
    const [sendInvite, setInvite] = useState(false);

    async function getInviteUsersAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            const response = await useFetch<IUserInfo>("User/InviteUser?userId=" + props.userRow.id);
            props.onInvite();
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setInvite(false);
    };

    return (
        <div>
            <Button id="InviteUser_btn" className={classes.button} startIcon={props.userRow.portalUserLogOn ? <DoneAllIcon /> : props.userRow.portalUserExist ? <ContactMailIcon /> : <ContactMailIcon />} variant="contained" color="primary" onClick={(_) => { getInviteUsersAsync(); setInvite(true); }} disabled={props.userRow.portalUserLogOn || (props.checkSuperAdmin && state.userAccessContext?.role === 2)} style={{ borderRadius: 20 }}>
                {props.userRow.portalUserLogOn ? "Invited" : props.userRow.portalUserExist ? "Re-Invite" : "Invite"}
            </Button>
            <Snackbar open={sendInvite} style={{ color: 'black', marginTop: '-0.5cm' }} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
                    Invitation Sent Successfully!
                </Alert>
            </Snackbar>
        </div>
    );

}

export default InviteUser



