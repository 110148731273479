//DBT_COMPANY
export const FIRSTSTP = '1STP';
export const PREC = 'PREC';
export const CONT = 'CONT';

//Awaiting Amt Payment
export const AwaitingPayment338 = "338";
export const AwaitingPayment324 = "324";

//NopaymentMade
export const NoPaymentMade = "https://webviewrpfunctions.azurewebsites.net/api/funcnopaymentmade";

//Placement Cloud Option
export const CsvFile = ".csv";
export const ValZipCode = /^\d{5}[-\s]?(?:\d{4})?$/;

//Regex validation
export const AllowOnlyAlphabetsWithSpace = /^[a-zA-Z+ ]+$/i;
export const AllowOnlyDigits = /^[0-9]*$/;
export const AllowAddress = /^[#.0-9a-zA-Z\s,-]+$/;
export const AllowCity = /^[a-zA-Z\u0080-\u024F\s\/\-\)\(\`\.\"\']+$/;
export const AllowState = /^[a-zA-Z]+|[a-zA-Z]+\\s[a-zA-Z]+$/;
export const AllowAmount = /^[0-9]+(\.[0-9][0-9])?$/;
export const AllowOnlyAlphabets = /^[a-zA-Z]+$/i;
