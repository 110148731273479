import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, TableCell, Typography,
  useMediaQuery, useTheme, makeStyles, Link, IconButton, DialogTitle
} from '@material-ui/core';
import React from 'react';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch } from '../../utils/apiHelper';
import { IDocumentDownloadFileResult } from '../../models/UploadLog/IDocumentDownloadFileResult';
import { IGetUploadLogData } from '../../models/UploadLog/GetUploadLogResult';
import { DialogDownloadHeader } from '../../components/GlobalStyles/DialogStyle';
import Backdrop from '@material-ui/core/Backdrop';
import PuffLoader from "react-spinners/PuffLoader";

const useStyles = makeStyles((theme) => ({
  fileDownloadLink: {
    fontSize: 15,
    width: 600,
    color: "blue",
    height: 40
  },
  yesbutton: {
    backgroundColor: 'green',
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const DownloadDocumentUploadFile: React.FC<{ row: IGetUploadLogData }> = (props) => {
  const classes = useStyles();
  const { row } = props
  const { state, dispatch } = useGlobalState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [ProgressBar, setShowProgressBar] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  function handleClose(isDownload: boolean) {
    setOpen(false);
    if (isDownload) {
      getDownloadFileAsync();
    }
  };

  function getDownloadFileAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      try {
        const responseDownloadFile = await useFetch<IDocumentDownloadFileResult>(`UploadLog/DownloadDocumentFile?fileId=${row.fileId}`);
        const arrayBuffer = base64ToArrayBuffer(responseDownloadFile.data.fileContent);
        createAndDownloadBlobFile(arrayBuffer, responseDownloadFile.data.fileName);
        setShowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })();
  }

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function createAndDownloadBlobFile(body, filename) {
    const blob = new Blob([body]);
    const fileName = `${filename}`;
    // if (navigator.msSaveBlob) {
    //   // IE 10+
    //   navigator.msSaveBlob(blob, fileName);
    // } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <React.Fragment>
      <TableCell className={classes.fileDownloadLink}>
        <Typography variant="body1" display="block" gutterBottom>
          <Link href="#" onClick={handleClickOpen} color="inherit">
            {row.fileName}
          </Link>
        </Typography>
      </TableCell>
      <Dialog
        maxWidth={'sm'}
        fullScreen={fullScreen}
        PaperProps={{ style: { borderRadius: 15 } }}
        open={open}
        onClose={(_) => { handleClose(false); }}
        aria-labelledby="responsive-dialog-title"
      >
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogDownloadHeader id="responsive-dialog-title" onClose={handleClose1}>
          <Typography variant="h5" gutterBottom className={classes.titleheader}>
            DOWNLOAD FILE
          </Typography>
        </DialogDownloadHeader>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h5" style={{ color: "black" }} gutterBottom>
              Are you sure, you want to Download this file ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={(_) => { handleClose(true); }} className={classes.yesbutton} color="primary" autoFocus>
            Yes
          </Button>
          <Button variant="contained" autoFocus className={classes.nobutton} onClick={(_) => { handleClose(false); }} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DownloadDocumentUploadFile