import { Collapse, Grid, IconButton, Typography } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import Beginorder from "../components/Dashboard/Beginorder";
import ChartImage from '../components/Dashboard/flatFeeChart.png';
import { IDashboardChart } from "../models/dashboard/IDashboardChart";
import { INotification } from '../models/dashboard/INotification';
import { INotificationCount } from "../models/dashboard/INotificationCount";
import BarChart from '../pages/Dashboard/BarChart';
import LineChart from '../pages/Dashboard/LineChart';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import Card from './Card/Card';
import CardBody from './Card/CardBody';
import CardHeader from './Card/CardHeader';
import CardIcon from './Card/CardIcon';
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import Footer from '../components/Footer/Footer'
import Fab from '@material-ui/core/Fab';
import HelpIcon from '@material-ui/icons/Help';
import { DialogTitleHeader } from '../components/GlobalStyles/DialogStyle';
import Learn from '../images/buttonIcons/learn.svg'

export interface DisplayState extends SnackbarOrigin {
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBox: {
      marginTop: "1.2cm",
      borderRadius: 20,
      width: "-10%"
    },
    card: {
      color: theme.palette.text.secondary,
      maxwidth: '11cm',
      height: '6cm',
      boxShadow: '0px 1px 5px 1px rgba(103, 128, 159, 1)',
      '&:hover': {
        transform: 'scale(1.1)',
        cursor: 'pointer',
        border: '2px blue solid',
        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
      },
    },
    root: {
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(3),
      height: '6.8cm'
    },
    graphAlign: {
      left: '40%',
      right: '40%',
    },
    fonts: {
      fontFamily: 'Segoe UI',
    },
    bottomGrid: {
      height: '50px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(2),
      color: theme.palette.info[500],
    },
    media: {
      height: 140,
    },
    div: {
      flexGrow: 1,
      backgroundColor: 'whitesmoke',
      overflowY: 'hidden',
      overflowX: 'hidden',
      marginTop: "-40px"
    },
    div1: {
      marginLeft: '3cm'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#ffff',
    },
    iconbutton: {
      border: '2px #215176 solid',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(2),
    },
    chartimg: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '35vw',
      textAlign: 'center'
    },
    dialogHeader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white',
      textAlign: "center"
    }
  }))

const Dashboard = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();

  const [notificationCount, setNotificationCount] = useState<INotificationCount[]>([]);
  const [DashboardChart, setDashboardChart] = useState<IDashboardChart[]>([]);
  const [Notification, setNotification] = useState<INotification[]>([]);

  const clientCode = state.userAccessContext?.clientCodes.join(",");
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const UserName = state.userAccessContext?.id;

  const notificationCard = notificationCount.map((c) => { return (c.notificationCount) });
  const rollCount = notificationCount.map((c) => { return (c.remaining) });
  const placementCount = notificationCount.map((c) => { return (c.daysSincePlaced) });
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [chartopen, setchartOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickChartOpen = () => {
    setchartOpen(true);
  };

  const handleClosechart = () => {
    setchartOpen(false);
  };

  useEffect(() => {
    dispatch({ type: GlobalStateAction.Busy });
    (async () => {
      try {
        let request = { clients: clientCode, userId: UserName };
        await usePost<INotificationCount[]>("Account/GetDashboardCount", request).then((getDashboardCount) => {
          setNotificationCount(getDashboardCount.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  useEffect(() => {
    dispatch({ type: GlobalStateAction.Busy });
    (async () => {
      try {
        let request = { clients: clientCode, userId: UserName };
        await usePost<IDashboardChart[]>("Account/GetDashboardChart", request).then((dashBoardChart) => {
          setDashboardChart(dashBoardChart.data);
        });
        setShowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  useEffect(() => {
    dispatch({ type: GlobalStateAction.Busy });
    (async () => {
      try {
        await useFetch<INotification[]>("DashboardNotification").then((NotificationData) => {
          setNotification(NotificationData.data);
        });
        setOpen(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container justify="flex-start" alignContent="flex-start" alignItems="center" spacing={1}>
          <Grid xs={12} item className={classes.searchBox} >
            {Notification.map((n => {
              return (
                <Collapse in={open}>
                  <Alert
                    style={{ marginBottom: "10px" }}
                    action={
                      <IconButton
                        aria-label="Dashboard_Alert_Close_Button"
                        id="Dashboard_Alert_Close_Button"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }>
                    {n.message}
                  </Alert>
                </Collapse>
              )
            }))
            }
          </Grid>
          <Grid container className={classes.root} >
            {state.userAccessContext?.unReadNotifications && <Grid item xs={12} sm={6} md={3} className={classes.grid} >
              <Link to="/notifications">
                <Card className={classes.card}>
                  <CardHeader color="warning" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                    <CardIcon color="warning">
                      <IconButton id="Notification_Button_Icon" component={Link} to="/notifications" aria-label="Notification_Button_Icon" style={{ color: "white" }} >
                        <NotificationsActiveIcon fontSize="small" />
                      </IconButton>
                    </CardIcon>
                  </CardHeader>
                  <CardBody style={{ marginLeft: "-1.80cm" }}>
                    <Typography variant="h2" gutterBottom style={{ color: "black", marginTop: "-1cm" }} >
                      <b>{notificationCard.length > 0 ? notificationCard : <Typography variant="h2" style={{ color: "black" }} gutterBottom > <b>0</b> </Typography>}</b>
                    </Typography>
                    <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center" }} gutterBottom>
                      Total Unread Notifications
                    </Typography>
                  </CardBody>
                </Card>
              </Link>
            </Grid>}

            {state.userAccessContext?.flatFeeAcRem && <Grid item xs={12} sm={6} md={3} className={classes.grid}>
              <Beginorder />
            </Grid>}

            {state.userAccessContext?.acWaitingtoMove && state.userAccessContext?.rollAccounts ?
              <Grid item xs={12} sm={6} md={3} className={classes.grid}>
                <Card className={classes.card}>
                  <Link to="/roll">
                    <CardHeader color="danger" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                      <CardIcon color="danger" style={{ backgroundColor: "pink" }}>
                        <IconButton id="Dashboard_RollButton" aria-label="Dashboard_RollButton" component={Link} to="/roll" style={{ color: "white" }} >
                          <TouchAppIcon fontSize="small" />
                        </IconButton>
                      </CardIcon>
                    </CardHeader>
                    <CardBody style={{ marginLeft: "-1.80cm" }}>
                      <Typography variant="h2" gutterBottom style={{ marginTop: "-0.5cm", color: "black" }} >
                        <b>{rollCount.length > 0 ? rollCount : <Typography variant="h2" style={{ color: "black" }} gutterBottom > <b>0</b> </Typography>}</b>
                      </Typography>
                      <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center" }} gutterBottom>
                        Accounts Waiting to Move to Contingency Collections
                      </Typography>
                    </CardBody>
                  </Link>
                </Card>
              </Grid>
              : state.userAccessContext?.acWaitingtoMove ?
                <React.Fragment>
                  <Grid item xs={12} sm={6} md={3} className={classes.grid}>
                    <Card className={classes.card}>
                      <CardHeader color="danger" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                        <CardIcon color="danger" style={{ backgroundColor: "pink" }}>
                          <IconButton id="Dashboard_RollButton" disabled={true} aria-label="Dashboard_RollButton" style={{ color: "white" }} >
                            <TouchAppIcon fontSize="small" />
                          </IconButton>
                        </CardIcon>
                      </CardHeader>
                      <CardBody style={{ marginLeft: "-1.80cm" }}>
                        <Typography variant="h2" gutterBottom style={{ marginTop: "-1cm", color: "black" }} >
                          <b>{rollCount.length > 0 ? rollCount : <Typography variant="h2" style={{ color: "black" }} gutterBottom > <b>0</b> </Typography>}</b>
                        </Typography>
                        <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center" }} gutterBottom>
                          Accounts Waiting to Move to Contingency Collections
                        </Typography>
                      </CardBody>
                    </Card>
                  </Grid>
                </React.Fragment> : null
            }

            {state.userAccessContext?.daysSincePlacmentofAc && <Grid item xs={12} sm={6} md={3} className={classes.grid}>
              {state.userAccessContext?.placeAccounts ?
                <Link to="/placement" >
                  <Card className={classes.card}>
                    <CardHeader color="info" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                      <CardIcon color="info">
                        <IconButton id="Dashboard_PlacementButton" component={Link} to="/placement" aria-label="Dashboard_PlacementButton" style={{ color: "white" }} >
                          <DateRangeIcon fontSize="small" />
                        </IconButton>
                      </CardIcon>
                    </CardHeader>
                    <CardBody style={{ marginLeft: "-1.80cm" }}>
                      <Typography variant="h2" gutterBottom style={{ marginTop: "-1cm", color: "black" }} >
                        <b>{placementCount.length > 0 ? placementCount : <Typography variant="h2" style={{ color: "black" }} gutterBottom ><b>0</b></Typography>}</b>
                      </Typography>
                      <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center", }} gutterBottom>
                        Days Since Your Last Placement of Accounts
                      </Typography>
                    </CardBody>
                  </Card>
                </Link>
                :
                <Card className={classes.card}>
                  <CardHeader color="info" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                    <CardIcon color="info">
                      <IconButton id="Dashboard_PlacementButton" aria-label="Dashboard_PlacementButton" style={{ color: "white" }} >
                        <DateRangeIcon fontSize="small" />
                      </IconButton>
                    </CardIcon>
                  </CardHeader>
                  <CardBody style={{ marginLeft: "-1.80cm" }}>
                    <Typography variant="h2" gutterBottom style={{ marginTop: "-1cm", color: "black" }} >
                      <b>{placementCount.length > 0 ? placementCount : <Typography variant="h2" style={{ color: "black" }} gutterBottom ><b>0</b></Typography>}</b>
                    </Typography>
                    <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center", }} gutterBottom>
                      Days Since Your Last Placement of Accounts
                    </Typography>
                  </CardBody>
                </Card>
              }
            </Grid>}
          </Grid>

          <Grid container justify="flex-start" alignContent="flex-start" alignItems="center" spacing={1}>
            {state.userAccessContext?.placementHistoryofMonthAc &&
              <Grid item xs={12} sm={6} >
                <BarChart chartData={DashboardChart} loading={ProgressBar} />
              </Grid>
            }

            {state.userAccessContext?.ageImpactofonAcResolution &&
              <Grid item xs={12} sm={6} className={classes.graphAlign} >
                <LineChart chartData={DashboardChart} loading={ProgressBar} />
              </Grid>
            }
          </Grid>
          <Grid item xs={11} >
            <div className={classes.div1}>
              <Footer />
            </div>
          </Grid>
          <Grid item xs={1} className={classes.bottomGrid}>
            <LightTooltip title="Click here to Learn more about the impact of age on account resolution">
              <Fab aria-label="fab Icon" size="small" className={classes.fab} color="primary" onClick={handleClickChartOpen}>
                <img width="25px" height="25px" src={Learn} alt="Learn more about the impact of age on account resolution" />
              </Fab>
            </LightTooltip >
            <Dialog
              PaperProps={{ style: { borderRadius: 15 } }}
              fullScreen={fullScreen}
              open={chartopen}
              onClose={handleClosechart}
              aria-labelledby="responsive-dialog-title"
              maxWidth={'md'}
            >
              <DialogTitleHeader id="responsive-dialog-title" onClose={handleClosechart}>
                <Typography variant="h5" gutterBottom className={classes.dialogHeader}>
                  Learn more about Impact of age on Account Resolution
                </Typography>
              </DialogTitleHeader>

              <DialogContent>
                <img alt="Chart Infotmation" src={ChartImage} className={classes.chartimg} />
                <DialogContentText style={{ color: "black" }}>
                  The age at which your accounts are sent to collections is the single most important factor in the performance on your accounts.When your internal efforts begin to show a decline in effectiveness, this is the point when 3rd Party intervention is needed.Generally, 60-75 days past due is the "sweet spot" for accounts to be submitted for collections.
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    </React.Fragment >
  )
}

export default Dashboard