import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { usePost, useFetch } from '../../utils/apiHelper';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore'
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
                marginBottom: "0%",
                margin: theme.spacing(2),
                width: '25ch',
            },
        },
        formControl: {
            minwidth: 300,
            position: "absolute",
        },
        button: {
            borderRadius: 20,
            background: "#215176",
            '&:hover': {
                background: "#215176",
            }
        }
    })
);

const InviteUserforCA: React.FC<{ userRow: IUserInfo, onInvite: () => void }> = (props) => {
    const theme = useTheme();
    const classes = useRowStyles();
    const { state, dispatch } = useGlobalState();

    async function getInviteUsersAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            const response = await useFetch<IUserInfo>("User/InviteUser?userId=" + props.userRow.id);
            props.onInvite();
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    return (
        <div>
            <Button id="InviteUser_btn" variant="contained" className={classes.button} startIcon={props.userRow.portalUserLogOn ? <DoneAllIcon /> : props.userRow.portalUserExist ? <ContactMailIcon /> : <ContactMailIcon />} color="primary" onClick={(_) => { getInviteUsersAsync(); }} disabled={props.userRow.portalUserLogOn} style={{ borderRadius: 20 }}>
                {props.userRow.portalUserLogOn ? "Invited" : props.userRow.portalUserExist ? "Re-Invite" : "Invite"}
            </Button>
        </div>
    );
}

export default InviteUserforCA



