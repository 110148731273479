import {
  Backdrop,
  Box, CssBaseline, Grid, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, TableSortLabel, Typography
} from '@material-ui/core';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { usePost } from '../../utils/apiHelper';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { IDebtor } from '../../models/Reports/IDebtor';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExpandableRow from './ReportExpandableRow'
import PrintIcon from '@material-ui/icons/Print';
import ReportBuilderShare from "./ReportBuilderShare";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import { useParams } from 'react-router';
import LightTooltip from "../GlobalStyles/LightTooltip";
import PuffLoader from 'react-spinners/PuffLoader';
import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: '8px'
  },
  maingrid: {
    borderRadius: '10px',
    height: '55px'
  },
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tableHeaderStyle: {
    background: "#007FFF",
    color: "white",
    fontSize: "11px"
  },
  tablebody: {
    maxHeight: 618,
    overflowX: 'hidden',
  },
}))


export default function ReportsBuilder(props) {
  const { state, dispatch } = useGlobalState();
  const [selected, setSelected] = React.useState<string>();
  const [reports, setreports] = useState<IDebtor[]>([]);
  const [exportCSV, setExportCSV] = useState<IDebtor[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const classes = useStyles();
  let params = useParams();
  const UserName = state.userAccessContext?.id;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(null);
  const [isValid, setIsValid] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [print, setPrint] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const pageStyle = `{ size: 3in 6in}`;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const ClientCode = state.userAccessContext?.clientCodes;
  const wrapperRef = useRef(null);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [mapped, setMapped] = useState(false);
  const [GetdebtorNo, setGetdebtorNo] = useState("");

  const handleRowClick = (accountId: string) => {
    setSelected(selected == accountId ? "" : accountId);
    setGetdebtorNo(accountId);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleRelease();
    }
  };

  const handleRelease = () => {
    if (GetdebtorNo !== undefined && GetdebtorNo !== null) {
      (async () => {
        dispatch({ type: GlobalStateAction.Busy });
        try {
          let YieldRelease = {
            "debtorNumber": GetdebtorNo,
            "userId": state.userAccessContext?.id
          }
          const release = await usePost("InquiryReleaseLock", YieldRelease);
        }
        catch (ex) {
          dispatch({ type: GlobalStateAction.Error, error: ex })
          dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
          dispatch({ type: GlobalStateAction.Idle })
          sessionStorage.removeItem('key');
        }
      })()
    } else {
      null;
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setshowProgressBar(true);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        let request = {
          "clients": ClientCode.join(),
          "reportId": params["reportId"],
          "orderBy": orderBy,
          "orderDirection": order,
          "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
          "rowsPerPage": recordsPerPage,
          "userId": UserName
        }
        const response = await usePost<{ reports: IDebtor[], totalRecordCount: number }>("ReportDisplay", request)
        const data = response.data.reports.map((a) => {
          return { ...a, phase: a.phase }
        })
        setreports(data);
        setTotalRecords(response.data.totalRecordCount);
        setIsDisable(true);
        getCSVFile();
        setshowProgressBar(true);
        setMapped(!data.length ? true : false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
        setshowProgressBar(false);
      }
    })()
  }, [pageNumber, recordsPerPage, order])

  const getCSVFile = () => {
    (async () => {
      try {
        let request = {
          "clients": ClientCode.join(),
          "reportId": params["reportId"],
          "orderBy": orderBy,
          "orderDirection": order,
          "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
          "rowsPerPage": 6000,
          "userId": UserName
        }
        const response = await usePost<{ reports: IDebtor[], totalRecordCount: number }>("ReportDisplay", request)
        const data = response.data.reports.map((a) => {
          return { ...a, phase: a.phase }
        })
        setExportCSV(data);
        setIsValid(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
      }
    })()
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  const handlePageChange = (event, page) => {
    setshowProgressBar(true);
    setPageNumber(page);
  }

  const headers = [
    {
      label: "Agency Id",
      key: "agencyID"
    },
    {
      label: "ClientAcc1",
      key: "clntAcct1"
    },
    {
      label: "Client Name1",
      key: "client_Name_1"
    },
    {
      label: "Client Name2",
      key: "client_Name_2"
    },
    {
      label: "Name",
      key: "name"
    },
    {
      label: "Address",
      key: "address"
    },
    {
      label: "Phone Number",
      key: "phoneNumber"
    },
    {
      label: "Principal Due",
      key: "principalDue"
    },
    {
      label: "Fee",
      key: "fee"
    },
    {
      label: "Total Paid",
      key: "principalReceived"
    },
    {
      label: "Total Due",
      key: "referralAmt"
    },
    {
      label: "Status",
      key: "status"
    },
    {
      label: "Status Date",
      key: "statusDate"
    },
    {
      label: "Referral Date",
      key: "referralDate"
    }
  ];

  function EnhancedTableHead(props) {
    const {
      classes,
      order,
      orderBy,
      onRequestSort
    } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    const headCells = [
      { id: "agencyID", disablePadding: false, label: "AGENCY ID", sortable: true },
      { id: "clntAcct1", disablePadding: false, label: "CLNT ACC1", sortable: true },
      { id: "client_Name_1", disablePadding: false, label: "CLIENT NAME1", sortable: true },
      { id: "client_Name_2", disablePadding: false, label: "CLIENT NAME2", sortable: true },
      { id: "name", disablePadding: false, label: "NAME", sortable: true },
      { id: "address", disablePadding: false, label: "ADDRESS", sortable: true },
      { id: "phoneNumber", disablePadding: false, label: "PHONE NUMBER", sortable: true },
      { id: "principalDue", disablePadding: false, label: "PRINCIPAL DUE", sortable: true },
      { id: "fee", disablePadding: false, label: "FEE", sortable: true },
      { id: "principalReceived", disablePadding: false, label: "TOTAL PAID", sortable: true },
      { id: "referralAmt", disablePadding: false, label: "TOTAL DUE", sortable: true },
      { id: "status", disablePadding: false, label: "STATUS", sortable: true },
      { id: "statusDate", disablePadding: false, label: "STATUS DATE", sortable: true },
      { id: "referralDate", disablePadding: false, label: "REFERRAL DATE", sortable: true },
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.tableHeaderStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label || headCell.sortable}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
      <React.Fragment>
        <div className={classes.root} ref={wrapperRef}>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={100} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <Grid container className={classes.grid} style={{ backgroundColor: 'whitesmoke' }}>
            <CssBaseline />
            <Grid container spacing={1} className={classes.maingrid} justify='space-evenly'>
              <Box p={0} flexShrink={0} width="40%" style={{ textAlign: 'left', marginLeft: '-35px' }}>
                <Typography variant="h4" gutterBottom style={{ color: "blue", marginTop: "3px" }}>
                  <b> {params["reportName"]}</b>
                </Typography>
              </Box>
              <Box p={0} flexShrink={0} style={{ marginLeft: "40%" }} >
                <ReactToPrint pageStyle={pageStyle}
                  onBeforeGetContent={() => { setPrint(true); }}
                  trigger={() =>
                    <LightTooltip title="Print Reports List">
                      <IconButton disabled={!isDisable} color="primary" id="RB_PrintIcon" aria-label="print" component="label">
                        <PrintIcon style={{ fontSize: 30 }} fontSize="large" />
                      </IconButton>
                    </LightTooltip>
                  }
                  content={() => componentRef.current}
                  onAfterPrint={() => { setPrint(false); }}
                />

              </Box>
              <Box p={0} flexShrink={0} style={{ marginLeft: "1%" }} >
                <LightTooltip title="Download Reports List">
                  <IconButton id="RB_CSV" disabled={isValid} >
                    <CSVLink
                      data={exportCSV}
                      headers={headers}
                      enclosingCharacter={`'`}
                      filename={`${params["reportName"]}.csv`}
                      target="_blank" >
                      <CloudDownloadIcon fontSize="large" style={isValid ? { color: "#a1a1a1" } : { color: "blue" }} />
                    </CSVLink>
                  </IconButton>
                </LightTooltip>
              </Box>
              <Box p={0} flexShrink={0} >
                <ReportBuilderShare valid={isDisable} />
              </Box>
            </Grid>
            <Grid item xs={12} component={Paper} style={{ marginTop: '10px' }}>
              <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`} >
                <div ref={componentRef}>
                  <Table id="ReportsDetailList" aria-label="customized table" size="small" stickyHeader>
                    {!print ?
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      :
                      <TableHead style={{ fontSize: '12px' }}>
                        <TableRow style={{ height: '1cm' }}>
                          <TableCell style={{ background: "#2377E4", color: "white" }}>AGENCY ID</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">CLNTACC1</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">CLIENTNAME1</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">CLIENTNAME2</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">NAME</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">ADDRESS</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">PRINCIPALDUE</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">FEE</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">TOTALPAID</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">TOTALDUE</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">STATUS</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">STATUSDATE</TableCell>
                          <TableCell style={{ background: "#2377E4", color: "white" }} align="inherit">REFERRALDATE</TableCell>
                        </TableRow>
                      </TableHead>}
                    <TableBody>
                      {stableSort(reports.slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                        row => {
                          return (
                            <ExpandableRow onClick={handleRowClick} selected={selected === row.agencyID} key={row.agencyID} row={row} selectedDBTNumber={selected} />
                          );
                        })}
                    </TableBody>
                  </Table>
                  {mapped === true ?
                    < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                      No records to display..
                    </Typography>
                    : null}
                </div>
              </TableContainer>
              <TablePagination
                id="Report_TablePagination"
                rowsPerPageOptions={[10]}
                component="div"
                count={totalRecords}
                rowsPerPage={10}
                page={pageNumber}
                onChangePage={handlePageChange}
              />
            </Grid>
          </Grid>
          <Footer />
        </div>
      </React.Fragment >
  )
}