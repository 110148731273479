import React from 'react'
import Typography from '@material-ui/core/Typography';
import { Box, LinearProgress } from '@material-ui/core';
import { useGlobalState } from '../../store/GlobalStore';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

//components
import FolderView from "../../components/FileAccess/FolderView";
import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: 8
        },
        paper: {
            color: theme.palette.text.secondary,
        },
        alignFlexStart: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        progressBar: {
            height: '6px',
            marginTop: '-0.4cm'
        },
        title: {
            alignContent: "center",
            color: "blue",
        },
    }),
);

const ManageFileAccess = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Box display='flex' alignItems='flex-start' justifyContent='flex-start'>
                    <Typography variant="h4" gutterBottom className={classes.title} style={{ color: "blue" }}>
                        <b> Manage File Access</b>
                    </Typography>
                </Box>
                <Box display='flex' >
                    <FolderView />
                </Box>
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default ManageFileAccess
