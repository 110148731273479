import * as React from 'react';
import InquiryDetail from './DvInquiryDetail';
import { IDebtor } from '../../../models/NotificationCenter/IDebtor';
import { Box, Button, Collapse, Divider, InputAdornment, makeStyles, Snackbar, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { Columns } from '../../../models/Inquiry/config';
import { useEffect, useRef, useState } from 'react';
import { INotificationCenter } from '../../../models/NotificationCenter/INotificationCenter';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import moment from 'moment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { IHideForAllUsers } from '../../../models/NotificationCenter/IHideForAllUsers';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useRowStyles = makeStyles({
    root: {
        fontSize: 200
    },
    rootHidden: {
        display: "none"
    },
    buttons: {
        borderRadius: 20,
    },
    snackbar: {
        width: "120%",
        marginTop: '-0.5cm',
    },
})

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const DvExpandableRow: React.FC<{ dv: INotificationCenter, selected: boolean, onClick: (accountId: string) => void, onHideAll: () => void, selectedDBTNumber: string }> = (props) => {
    const { dv } = props
    const { state, dispatch } = useGlobalState();
    const classes = useRowStyles()
    const fileUpload = React.useRef(null);
    const [debtors, setDebtors] = useState<IDebtor[]>([]);
    const UserName = state.userAccessContext?.id;
    const [selectedFile, setSelectedFile] = useState<File>();
    const [isActiveStatus, setGetActivestatus] = useState(false);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const componentRef = useRef();
    const prevCount = usePrevious(props.selectedDBTNumber);
    const [open, setOpen] = useState(false);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        dispatch({ type: GlobalStateAction.Busy })
    }, [props.selected])

    const getAccountdetails = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const DebtorDetils = await useFetch<IDebtor[]>("GetNotificationDetails?DBTNumber=" + dv.extraData_1)
                setDebtors(DebtorDetils.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }


    const onSubmit = () => {
        if (selectedFile.type === 'image/jpeg' || selectedFile.type === 'application/pdf') {
            (async () => {
                const formData = new FormData();
                let dateTime = new Date();
                let userId = UserName.toString();
                const currentDate = moment(dateTime).format('MMDDyy');
                const fileExtension = selectedFile.name.split('.').pop();
                const fileName = `${props.dv.extraData_1}-${currentDate}${props.dv.clT_Code}-BU.${fileExtension}`;
                formData.append("FormFile", selectedFile);
                formData.append("ClientId", props.dv.clT_Code);
                formData.append("file_name", fileName);
                formData.append("userid", UserName.toString());
                formData.append("rid", props.dv.extraData_1)
                formData.append("upload_date", dateTime.toString());
                formData.append("file_size", "100");
                formData.append("ContainerName", "dispute-validation");
                try {
                    const response = await usePost<any>("File/DisputeDocument", formData)
                    setOpen(true);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex });
                    dispatch({ type: GlobalStateAction.Idle });
                }
                finally {
                    hidenotificationForAllUsers();
                    dispatch({ type: GlobalStateAction.Idle });
                }
            })()
        }
        else {
            alert('Invalid File Type. Please select a PDF or JPG file.')
        }
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        event.target.files && setSelectedFile(event.target.files[0])

    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": dv.userNotificationId,
                    "userid": UserName,
                    "debtorid": dv.extraData_1,
                    "typeId": 7
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
                props.onHideAll();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const setYieldLock = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const response = await useFetch<any>(`InquiryYieldLock?debtorNumber=${dv.extraData_1}&userId=${state.userAccessContext?.id.toString()}`);
                setGetActivestatus(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(dv.extraData_1);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [dv.extraData_1, prevCount, props.selectedDBTNumber])

    const setYieldRelease = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let YieldRelease = {
                    "debtorNumber": GetDebtorNo,
                    "userId": UserName
                }
                const release = await usePost("InquiryReleaseLock", YieldRelease)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root} hover>
                <TableCell>
                    <Box display="flex" p={1} bgcolor="white">
                        <Box p={1} width="90%" bgcolor="white" >
                            <Typography style={{ textAlign: "left" }} variant="subtitle1" gutterBottom>
                                <b>{moment(dv.createdDate).format(state.GlobalUtils?.settingValue)} : Dispute Validation : Client Acct #{dv.extraData_2} / Agency Acct #{dv.extraData_1}</b>
                            </Typography>
                            <Typography variant="subtitle1" style={{ textAlign: "left" }} gutterBottom>
                                <b>Client Code: {dv.clT_Code} = {dv.clT_Name}</b>
                            </Typography>
                            <Typography variant="subtitle1" style={{ textAlign: "left", color: 'blue', cursor: 'pointer' }} gutterBottom onClick={(_) => { props.onClick(dv.extraData_1); getAccountdetails(); (dv.extraData_1 !== props.selectedDBTNumber ? setYieldRelease() : setGetDebtorNo("")); (props.selected === true ? setYieldRelease() : setYieldLock()); }}>
                                View Account Details
                            </Typography>
                        </Box>
                        <Box p={0} flexShrink={0} bgcolor="white" >
                            <Typography variant="subtitle1" gutterBottom >
                                {selectedFile ? selectedFile.name : ""}
                            </Typography>
                            <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                                <Button style={{ borderRadius: 20, marginRight: "1cm", background: "#007FFF", color: "white" }} component="label"
                                    variant="contained" startIcon={<CloudUploadIcon />}>
                                    Choose File
                                    <input
                                        id="NF_SelectFile"
                                        onChange={changeHandler}
                                        name="file"
                                        ref={fileUpload}
                                        hidden
                                        multiple type="file"
                                        accept="image/jpeg,image/jpg,application/pdf"
                                    />
                                </Button>
                            </form>
                            <Box p={1} flexShrink={0} bgcolor="white" alignItems="center" justifyContent="center">
                                <Button variant="contained" color="primary" className={classes.buttons} onClick={(_) => { onSubmit(); }} disabled={!selectedFile}>
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Divider style={{ marginTop: "5px", height: '2px', background: "black" }} />
                </TableCell>
            </TableRow>
            {debtors.map((sr) => {
                return (
                    <TableRow className={props.selected ? classes.root : classes.rootHidden} ref={componentRef}>
                        <TableCell scope="row" colSpan={Columns.length}>
                            <Collapse in={props.selected} timeout="auto" unmountOnExit >
                                <InquiryDetail inquiryDetail={sr} isBlocked={isActiveStatus} onCheck={setYieldLock} />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                );
            })}
            <Snackbar open={open} className={classes.snackbar} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    File Submitted Successfully !
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default DvExpandableRow