import { Backdrop, createStyles, IconButton, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from '@material-ui/core/Snackbar';
import { Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useDelete } from '../../utils/apiHelper';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleheader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white'
    },
    yesButton: {
      background: "green",
      color: 'white',
      borderRadius: 20,
      right: '15px',
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      borderRadius: 20,
      display: 'flex',
      right: '10px',
      '&:hover': {
        background: "red",
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

const DeleteUser: React.FC<{ userRow: IUserInfo, onDelete: () => void, checkSuperAdmin: boolean }> = (props) => {
  const { userRow } = props
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { state, dispatch } = useGlobalState();
  const [DeleteUser, setDeleteUser] = useState(false);
  const classes = useRowStyles();
  const [ProgressBar, setShowProgressBar] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setShowProgressBar(true);
    useDelete("User/DeleteUser?userId=" + userRow.id).then((r) => {
      if (r.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: r.statusText })
      }
      props.onDelete();
      setShowProgressBar(false);
      setDeleteUser(true);
    });
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteUser(false);
  };

  return (
    <React.Fragment>
      <div>
        <IconButton id="U_DeleteIcon" aria-label="delete" onClick={handleClickOpen} disabled={props.checkSuperAdmin && state.userAccessContext?.role === 2}>
          <DeleteIcon style={{ color: 'red' }} />
        </IconButton>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Dialog
          fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth={'sm'}
          aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h5" gutterBottom className={classes.titleheader}>
              DELETE USER
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h5" style={{ marginTop: "15px", color: "black" }} gutterBottom>
                Are you sure, you want to delete this user ?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Button id="DU_ok_btn" variant="contained" onClick={(e) => { handleOk(); }} className={classes.yesButton}>
              Yes
            </Button>
            <Button id="DU_Cancel_btn" variant="contained" autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={DeleteUser} style={{ marginTop: '-0.5cm' }} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" style={{ fontSize: 16 }}>
            User has been Deleted Successfully!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}

export default DeleteUser