import { createStyles, Grid, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Autocomplete } from '@material-ui/lab';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { IFilter } from '../../models/Reports/IFilter';
import { IReportField } from '../../models/Reports/IReportField';
import { IReportOperator } from '../../models/Reports/IReportOperator';
import { IReportOperatorValue } from '../../models/Reports/IReportOperatorValue';
import * as constants from '../../constants/Constant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(8),
      width: 210,
      marginTop: "14px"
    },
    placeholder: {
      width: 200
    },
    addColumn: {
      width: 220
    },
    reportStyle: {
      width: 350,
    },
    reportStyle1: {
      width: 350,
      marginTop: '15.50px'
    },
    dropDown: {
      borderBottom: '3px solid blue',
      borderRadius: '10px',
      boxShadow: '0px 0px 5px 0px rgba(103, 128, 159, 1)',
    },
    delete: {
      marginTop: "13px"
    },
    addFilterGrid: {
      maxHeight: 300,
    },
    ErrorMessageStyle: {
      color: 'red',
      fontSize: '18px',
      textAlign: 'center',
      fontWeight: 'bold'
    },
  })
);

const FilterReport: React.FC<{
  fieldsList: IReportField[], OperatorsList: IReportOperator[], filter: IFilter, handleDeleteFilter: any,
  onChangeFilter: any, states: any[], statuses: any[], phases: any[], clients: any[]
}> = (props) => {
  const classes = useStyles();
  const { fieldsList, OperatorsList, handleDeleteFilter, onChangeFilter, filter, states, statuses, phases, clients } = props;
  const [ShowOperators, setShowOperators] = useState<IReportOperatorValue[]>([]);
  const [FilterValues, setFilterValues] = useState([]);
  const [Filter, setFilter] = useState(filter);
  const [isDateValue, setisDateValue] = useState(false);
  const [IsSpace, setIsSpace] = useState('');
  const clientsLoading = clients.length === 0;
  const [Field, setField] = useState<IReportField>({ "id": null, "name": "string", "data_type": "string" });
  const [Operator, setOperator] = useState<IReportOperatorValue>({ "id": "string", "name": "", "sqlKeyword": "string" });
  const [Value, setValue] = useState<any>({ "id": filter === undefined ? "" : filter.value, "value": filter === undefined ? "" : filter.value });
  const [FieldMaxLen, setFieldMaxLen] = useState<number>(0);
  const [FieldDateVal, setFieldDateVal] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [fieldValue, setFieldValue] = useState<string>("");

  let dummValue = {
    "id": filter === undefined ? "" : filter.value,
    "name": filter === undefined ? "" : filter.value
  }

  useEffect(() => {
    onValueSelect(null, Value);
  }, [Value]);

  useEffect(() => {
    let newField = fieldsList.find(field => field.id == filter.field_id);
    setField(newField);
    if (newField && newField.id != null) {
      OnFieldsSelect(null, newField);
    }
  }, [fieldsList]);

  function OnFieldsSelect(event, value) {
    if (value?.name == "Client Code") {
      setValue(clients.find(client_code => client_code?.id == filter.value));
      setFilterValues(clients);
      setIsSpace(value.name);
    } else if (value?.name == "State") {
      setValue(states.find(states => states.id == filter.value));
      setFilterValues(states);
      setIsSpace(value.name);
    } else if (value?.name == "Status") {
      setValue(statuses.find(status => status.id == filter.value));
      setFilterValues(statuses);
      setIsSpace('empty');
    } else if (value?.name == "Phase") {
      setValue(phases.find(phase => phase.id == filter.value));
      setFilterValues(phases);
      setIsSpace(value.name);
    } else {
      setFilterValues([dummValue]);
      setValue(dummValue);
      setIsSpace('empty');
    }

    let operatorList;
    if (value == null) {
      return;
    }
    setField(value);
    setisDateValue(value.data_type == "date");
    for (var i = 0; i < OperatorsList.length; i++) {
      if (OperatorsList[i].operatorType == value.data_type) {
        operatorList = OperatorsList[i];
      }

    }
    setOperator(operatorList.operatorValues.find(operator => parseInt(operator.id) == Filter.operator_id));
    setShowOperators(operatorList.operatorValues);
    let newFilter = _.cloneDeep(Filter);
    newFilter.field_id = value?.id;
    setFilter(newFilter);
    onChangeFilter(Filter.index, newFilter);
    GetFieldLength(event, value);
  }

  function onOperatorSelect(event, value) {
    let newFilter = _.cloneDeep(Filter);
    newFilter.operator_id = value?.id;
    setOperator(value);
    setFilter(newFilter);
    onChangeFilter(newFilter.index, newFilter);
  }

  function onValueSelect(event, value) {
    let newFilter = _.cloneDeep(Filter);
    newFilter.value = value?.id == null ? value : value?.id;
    setFilter(newFilter);
    onChangeFilter(Filter.index, newFilter);
  }

  function fieldValidations(event) {
    const newValue = event.target?.value;
    fieldsList.filter(x => {

      // String Field
      if (fieldValue === "Agency ID") {
        if (constants.AllowOnlyDigits.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter numbers only");
        }
      }
      else if (fieldValue == 'Account ID') {
        if (constants.AllowOnlyDigits.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter numbers only");
        }
      }
      else if (fieldValue == 'Name') {
        if (constants.AllowOnlyAlphabetsWithSpace.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter Alphabets and space only");
        }
      }
      else if (fieldValue == 'Address') {
        if (constants.AllowAddress.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please Enter Valid Address");
        }
      }
      else if (fieldValue == 'City') {
        if (constants.AllowCity.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please Enter Valid City ");
        }
      }
      else if (fieldValue == 'State') {
        if (constants.AllowState.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please Enter Valid State ");
        }
      }
      else if (fieldValue == 'Zip') {
        if (constants.ValZipCode.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("Zip Code Should be 5 or 9 ");
        }
      }
      // money Field
      else if (fieldValue == 'Principal Due') {
        if (constants.AllowAmount.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter numbers with 2 digit only");
        }
      }
      else if (fieldValue == 'Total Paid') {
        if (constants.AllowAmount.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter numbers with 2 digit only");
        }
      }
      else if (fieldValue == 'Total Due') {
        if (constants.AllowAmount.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter numbers with 2 digit only");
        }
      }
      else if (fieldValue == 'Fee') {
        if (constants.AllowAmount.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter numbers with 2 digit only");
        }
      }
      // Status Field
      else if (fieldValue == 'Status') {
        if (constants.AllowOnlyAlphabets.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter Alphabets only");
        }
      }
      //Client Code Field
      else if (fieldValue == 'Client Code') {
        if (constants.AllowOnlyAlphabets.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter Alphabets only");
        }
      }
      //Phase Field
      else if (fieldValue == 'Phase') {
        if (constants.AllowOnlyAlphabets.test(newValue)) {
          setErrorMessage("");
        } else {
          setErrorMessage("please enter Alphabets only");
        }
      }
    });
  }

  function GetFieldLength(event, value) {
    let FieldLength = 0;
    //String Field
    if (value.name == 'Agency ID') { FieldLength = 9 }
    else if (value.name == 'Account ID') { FieldLength = 20 }
    else if (value.name == 'Name') { FieldLength = 50 }
    else if (value.name == 'Address') { FieldLength = 255 }
    else if (value.name == 'City') { FieldLength = 50 }
    else if (value.name == 'State') { FieldLength = 20 }
    else if (value.name == 'Zip') { FieldLength = 9 }
    //money Field
    else if (value.name == 'Principal Due') { FieldLength = 13 }
    else if (value.name == 'Total Paid') { FieldLength = 13 }
    else if (value.name == 'Total Due') { FieldLength = 13 }
    else if (value.name == 'Fee') { FieldLength = 13 }
    //Status Field
    else if (value.name == 'Status') { FieldLength = 40 }
    //Date Field
    else if (value.name == 'Status Change Date') { setFieldDateVal("9999-12-31"); }
    else if (value.name == 'Date') { setFieldDateVal("9999-12-31"); }
    else if (value.name == 'Referral Date') { setFieldDateVal("9999-12-31"); }
    //Client Code Field
    else if (value.name == 'Client Code') { FieldLength = 50 }
    //Phase Field
    else if (value.name == 'Phase') { FieldLength = 50 }
    setFieldMaxLen(FieldLength);
    setFieldValue(value.name);
  }

  return (
    <div className={classes.addFilterGrid}>
      <span className={classes.ErrorMessageStyle}>
        {errorMessage}
      </span>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={3}>
          <Autocomplete
            autoComplete autoHighlight disableClearable
            options={fieldsList}
            className={classes.addColumn}
            getOptionLabel={(option) => option?.name}
            value={Field}
            onChange={(e, value) => { OnFieldsSelect(e, value) }}
            renderInput={(params) => (
              <TextField {...params} margin="normal" variant="outlined"
                className={classes.dropDown}
                inputProps={{
                  ...params.inputProps,
                  maxlength: 10,
                }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            autoComplete autoHighlight
            className={classes.addColumn}
            options={ShowOperators}
            getOptionLabel={(option) => option?.name}
            value={Operator}
            onChange={onOperatorSelect}
            key={ShowOperators.length > 0 ? ShowOperators[0].name.toString() : "empty"}
            renderInput={(params) => (
              <TextField {...params} margin="normal" variant="outlined"
                className={classes.dropDown}
                inputProps={{
                  ...params.inputProps,
                  maxlength: 50,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          {IsSpace === "Client Code" ?
            <Autocomplete
              id="clientCode-select"
              className={classes.reportStyle1}
              options={clients}
              autoHighlight
              loading={clientsLoading}
              value={Value}
              onChange={onValueSelect}
              getOptionLabel={(option) => option?.id + "--" + option?.name}
              getOptionSelected={(option, value) => option?.id === value?.id}
              renderOption={(option) => (
                <React.Fragment>
                  {option?.id + "--" + option?.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select client"
                  variant="outlined"
                  className={classes.dropDown}
                  inputProps={{
                    ...params.inputProps,
                    maxlength: FieldMaxLen,
                    autoComplete: 'new-password'// disable autocomplete and autofill
                  }}
                />
              )}
            />
            : IsSpace === "State" || IsSpace === "statuses" || IsSpace === "Phase" && IsSpace.length > 0 ?
              <Autocomplete
                id="clientCode-select"
                className={classes.reportStyle1}
                options={IsSpace === "State" ? states : IsSpace === "Phase" ? phases : statuses}
                autoHighlight disableClearable
                value={Value}
                onChange={onValueSelect}
                getOptionLabel={(option) => option?.name}
                getOptionSelected={(option, value) => option?.id === value?.id}
                renderOption={(option) => (
                  <React.Fragment>
                    {option?.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    className={classes.dropDown}
                    {...params}
                    label={IsSpace === "State" ? "Select State" : IsSpace === "Phase" ? "Select Phase" : "Select Account Status"}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      maxlength: FieldMaxLen,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              :
              <Autocomplete
                id="FR_ValueSelect"
                freeSolo
                autoComplete autoHighlight
                options={FilterValues}
                className={classes.reportStyle}
                onChange={onValueSelect}
                onInputChange={onValueSelect}
                getOptionLabel={(option) => option?.name === undefined ? "" : option?.name}
                value={Value}
                key={FilterValues?.length > 0 ? FilterValues[0]?.name?.toString() : "empty"}
                renderInput={(params) => (
                  <TextField {...params} type={isDateValue ? "date" : null} margin="normal" variant="outlined"
                    onChange={(e) => fieldValidations(e)}
                    error={!!errorMessage}
                    className={classes.dropDown}
                    inputProps={{
                      ...params.inputProps,
                      maxlength: FieldMaxLen,
                      max: `${isDateValue ? FieldDateVal : null}`
                    }}
                  />
                )}
              />}
        </Grid>
        <Grid item xs={12} sm={1}>
          <IconButton id="FR_DeleteIcon" aria-label="delete" onClick={() => handleDeleteFilter(filter.index)}
            className={classes.delete}>
            <HighlightOffIcon fontSize="large" style={{ color: "red" }} />
          </IconButton>
        </Grid>
      </Grid>
    </div >
  )
}

export default FilterReport;