import { Backdrop, Box, Grid, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { ClientUserAccessContext } from '../../models/accesscontrol/ClientUserAccessContext';
import { IUserPermissions } from '../../models/accesscontrol/IUserPermissions';
import { IClientInfo } from '../../models/client/IClientInfo';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import CurrencyInput from 'react-currency-input-field';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
                marginBottom: "0%",
                margin: theme.spacing(2),
                width: '25ch',
            },
        },
        multiSelectRoot: {
            '& > *': {
                borderBottom: 'unset',
                marginBottom: "0%",
                margin: theme.spacing(2),
                width: "425px"
            },
        },
        formControl: {
            width: "425px",
            margin: theme.spacing(2),
            minWidth: 425,
            borderBottom: '3px solid blue',
            borderRadius: '10px'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        close1: {
            float: 'right',
            marginTop: "0.8cm",
            marginBottom: '0.3cm',
            marginRight: '5px',
            borderRadius: '50px',
            backgroundColor: 'red',
            '&:hover': {
                backgroundColor: 'red',
            }
        },
        create: {
            float: 'right',
            marginTop: "0.8cm",
            marginLeft: '-15%',
            marginBottom: '0.3cm',
            marginRight: '38px',
            borderRadius: '50px'
        },
        snackbar: {
            width: "120%",
            marginTop: '-0.5cm',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        phoneNumberFieldStyle: {
            borderBottom: '3px solid blue',
            borderRadius: '5px',
            minWidth: 410,
            height: '55px',
            fontSize: 16
        }
    })
);

const CreateUser: React.FC<{ onSave: () => void }> = (props) => {
    const { onSave } = props
    const { state, dispatch } = useGlobalState();
    const [open, setOpen] = React.useState(false);
    const [confirmedClients, setConfirmedClients] = React.useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const multiselectRef = useRef<Multiselect>();
    var newUser: ClientUserAccessContext = new ClientUserAccessContext();
    const [localState, setState] = React.useState(newUser);
    const classes = useRowStyles()
    const canCreateUser = state.userAccessContext?.createNewUsers;
    const [firstnameerror, setFirstNameError] = useState("");
    const [Lastnameerror, setLastNameError] = useState("");
    const [phoneerror, setPhoneError] = useState("");
    const [Emailerror, setEmailError] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isDisable, setIsDisable] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);

    const handleRemove = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClickOpen = () => {
        setOpen(true);
        useFetch<IClientInfo[]>("Client/GetUsersClientCode?userId=" + state?.userAccessContext?.id).then((r) => {
            setConfirmedClients(r.data);
        })
    };
    const handleClose = () => {
        setOpen(false);
        setIsValid(true);
    };

    const handleValid = () => {
        setIsValid(false);
    };

    const handleCreate = async () => {
        setOpen(false);
        setShowProgressBar(true);
        let user = localState;
        user.clientCodes = multiselectRef.current.getSelectedItems().map(r => r.client_Code);
        await usePost<{ userInfo: IUserPermissions, totalRecordCount: number }>("User/SaveUser", user).then(r => {
            if (r.status == 200) {
                setSnackbarOpen(true);
                onSave();
                setShowProgressBar(false);
            }
        }).catch((err) => {
            if (err.response.status == 400) {
                alert(err.response.data);
                dispatch({ type: GlobalStateAction.Error, error: err.statusText })
                setShowProgressBar(false);
            }
        });
    };

    const HandleFirstNameVal = (e) => {
        const FirstNameVal = e.target.value;
        if (!FirstNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
            setFirstNameError("");
            setIsDisable(false);
        } else {
            setFirstNameError("please enter characters only");
            setIsDisable(true);
        }
    };

    const HandleLastNameVal = (e) => {
        const LastNameVal = e.target.value;
        if (!LastNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
            setLastNameError("");
            setIsDisable(false);
        } else {
            setLastNameError("please enter characters only");
            setIsDisable(true);
        }
    };

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const validateEmail = (e) => {
        const email = e.target.value;
        if (emailRegex.test(email)) {
            setEmailError("");
            setIsDisable(false);
        } else {
            setEmailError("please enter valid mail address");
            setIsDisable(true);
        }
    };

    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    const phonenumber = (e) => {
        const phoneValidation = e.target.value;

        if (phoneRegex.test(phoneValidation)) {
            setPhoneError("");
            setIsDisable(false);
        }
        else {
            setPhoneError("please enter valid phone number");
            setIsDisable(true);
        }
    }

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...localState,
            [name]: event.target.value,
        });
    };

    const handleChecked = (event) => {
        const name = event.target.name;
        setState({
            ...localState,
            [name]: event.target.checked,
        });
    };

    return (
        <React.Fragment>
            <div>
                <Button id="CA_CreateUSer" variant="contained" startIcon={<PersonAddIcon />} color="primary" disabled={!canCreateUser} onClick={handleClickOpen} style={{ borderRadius: 20 }}>
                    Create New User
                </Button>
            </div>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={100} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                PaperProps={{ style: { borderRadius: 15 } }}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                        CREATE NEW USER
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Grid container>
                        <Grid item xs={7} style={{ marginTop: '15px' }}>
                            <div>
                                <form className={classes.root} noValidate autoComplete="off" >
                                    <TextField id="CAU_FirstName" label="First Name" variant="outlined" name="firstName" onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }} helperText={firstnameerror}
                                        error={!!firstnameerror} inputProps={{ maxlength: 25 }} style={{ borderBottom: '3px solid blue', borderRadius: '10px', }} />
                                    <TextField id="CAU_LastName" label="Last Name" variant="outlined" name="lastName" onChange={(e) => { handleChange(e); HandleLastNameVal(e); }} helperText={Lastnameerror}
                                        error={!!Lastnameerror} inputProps={{ maxlength: 25 }} style={{ borderBottom: '3px solid blue', borderRadius: '10px', }} />
                                </form>
                            </div>
                            <div>
                                <form className={classes.root} noValidate autoComplete="off" >
                                    <TextField id="CAU_Email" label="Email" variant="outlined" name="userName" type="email" onChange={(e) => { handleChange(e); validateEmail(e) }}
                                        helperText={Emailerror} error={!!Emailerror} inputProps={{ maxlength: 50 }} style={{ minWidth: 425, borderBottom: '3px solid blue', borderRadius: '10px', }} />
                                </form>
                            </div>
                            <form className={classes.root} noValidate autoComplete="off" >
                                <CurrencyInput
                                    className={classes.phoneNumberFieldStyle}
                                    id="U_CU_Phone"
                                    placeholder="Phone Number"
                                    allowNegativeValue={false}
                                    maxLength={12}
                                    onChange={(e) => { handleChange(e); phonenumber(e); }}
                                    autoComplete='off'
                                    name="phone"
                                    disableGroupSeparators={true}
                                    allowDecimals={false}
                                />
                            </form>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                                <Select native
                                    id="CAU_AdminLevel"
                                    onChange={handleChange}
                                    value={localState.role}
                                    label="Template"
                                    inputProps={{
                                        name: 'role',
                                        id: 'AdminLevel',
                                    }}>
                                    <option value={0}>Regular User</option>
                                    <option value={1}>Client Admin</option>
                                </Select>
                            </FormControl>
                            <FormControl variant="filled" className={classes.multiSelectRoot}>
                                <Multiselect id="clientCode"
                                    ref={multiselectRef}
                                    options={confirmedClients}
                                    onSelect={handleValid}
                                    displayValue="client_Code"
                                    placeholder="Select Client"
                                    style={{ borderBottom: '3px solid blue', borderRadius: '10px' }}
                                    closeOnSelect={false}
                                    showArrow={true}
                                    showCheckbox={true}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} >
                            <DialogTitle id="responsive-dialog-title" style={{ marginLeft: '15%' }}>{"PERMISSIONS"}</DialogTitle>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <FormControl component="fieldset" >
                                    <FormGroup>
                                        <FormControlLabel
                                            id="CAU_paymentUpdates"
                                            control={<Checkbox onChange={handleChecked} name="paymentUpdates" />}
                                            labelPlacement="end"
                                            label="Payment Updates"
                                        />
                                        <FormControlLabel
                                            id="CAU_closingAccounts"
                                            control={<Checkbox onChange={handleChecked} name="closingAccounts" />}
                                            labelPlacement="end"
                                            label="Close Accounts"
                                        />
                                        <FormControlLabel
                                            id="CAU_rollAccounts"
                                            control={<Checkbox onChange={handleChecked} name="rollAccounts" />}
                                            labelPlacement="end"
                                            label="Roll Accounts"
                                        />
                                        <FormControlLabel
                                            id="CAU_placeAccounts"
                                            control={<Checkbox onChange={handleChecked} name="placeAccounts" />}
                                            labelPlacement="end"
                                            label="Place Accounts"
                                        />
                                        <FormControlLabel
                                            id="CAU_editReports"
                                            control={<Checkbox onChange={handleChecked} name="editReports" />}
                                            labelPlacement="end"
                                            label="Edit Reports"
                                        />
                                        <FormControlLabel
                                            id="CAU_createNewUsers"
                                            control={<Checkbox onChange={handleChecked} name="createNewUsers" />}
                                            labelPlacement="end"
                                            label="Create New Users"
                                        />
                                        <FormControlLabel
                                            id="CAU_uploadUnmappedFile"
                                            control={<Checkbox onChange={handleChecked} name="uploadUnmappedFile" />}
                                            labelPlacement="end"
                                            label="Upload Unmapped File"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Button className={classes.close1} id="U_CU_Close_btn" onClick={handleClose} color="primary" variant="contained" startIcon={<CloseIcon />}>
                                Close
                            </Button>
                            <Button className={classes.create} id="CAU_CreateNewUser" autoFocus disabled={isValid === true || isDisable} onClick={handleCreate} color="primary" variant="contained" startIcon={<AccountCircleIcon />}>
                                Create User
                            </Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
            <Snackbar open={snackbarOpen} className={classes.snackbar} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleRemove}>
                <Alert onClose={handleRemove} severity="success">
                    User {localState.firstName}{localState.lastName} is Created Successfully! !
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}

export default CreateUser