import moment from 'moment';
import SaveTemplate from '../SaveTemplate';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@material-ui/core/Backdrop';
import PuffLoader from "react-spinners/PuffLoader";
import React, { useEffect, useState } from 'react';
import { usePost } from '../../../utils/apiHelper';
import Container from '@material-ui/core/Container';
import { Link, useLocation } from 'react-router-dom';
import Processfile from '../../../images/header/process.svg';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { useGlobalState, GlobalStateAction } from '../../../store/GlobalStore';
import { IClientList } from '../../../models/Admin/ClientPlacements/IClientList';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "130vh",
    marginTop: '1cm',
    height: '75vh',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    overflowY: 'hidden'
  },
  actions: {
    width: "100%",
    marginTop: "1cm",
    marginRight: theme.spacing(2)
  },
  buttons: {
    borderRadius: 20,
    fontSize: 16,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "0.3cm",
  },
  div: {
    flexGrow: 1
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //marginTop: "1cm",
  },
  title: {
    alignContent: "center",
    color: "blue",
    marginTop: '0.2cm',
    textAlign: 'center'
  },
  finish: {
    top: '50%',
    marginTop: '1cm'
  },
  box: {
    maxWidth: "100vh",
    marginTop: '0.3cm',
    height: '20vh',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: 'white',
    overflowY: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  image: {
    height: 150,
    width: 150,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Finish = (props) => {
  const classes = useStyles();
  let location = useLocation();
  const { state, dispatch } = useGlobalState();

  const [enable, setEnable] = useState(true);
  const [savingEnable, setSavingEnable] = useState(true);
  const [ProgressBar, setshowProgressBar] = useState(false);

  let UserId = state.userAccessContext?.id;
  let clientCode: string = props.location.state?.clientCode;
  let uploadedFile: any = props.location.state.uploadedFile;
  let phaseNumber: string = props.location.state.phaseNumber;
  let isCSVUpload: boolean = props.location.state?.isCSVUpload;
  let clientList: IClientList[] = props.location.state?.clientList;
  let IsManualUpload: boolean = props.location.state?.IsManualUpload;
  let selectedHeaderList: string[] = props.location?.state?.selectedHeaderList;
  const [feeValue, setFeeValue] = useState<any>(props.location?.state?.feeValue);
  const [mBalanceValue, setMBalanceValue] = useState<any>(props.location?.state?.mBalanceValue);
  const [ignoreRowNumber, setIgnoreRowNumber] = useState<any>(props.location?.state?.ignoreRows);

  useEffect(() => {
    dispatch({ type: GlobalStateAction.Busy });
    (async () => {
      let date = new Date(Date.now());
      let filename = `${clientCode}${moment(date).format("YYYYMMDDhhmmss")}${uploadedFile && uploadedFile?.name}`;
      const formData = new FormData();
      formData.append("FormFile", uploadedFile);
      formData.append("file_name", filename);
      formData.append("file_size", uploadedFile && uploadedFile?.size);
      formData.append("ContainerName", "upload-csv");
      formData.append("ClientId", (clientCode == "-2" ? "MULC" : clientCode));
      formData.append("UploadMethod", '0');
      formData.append("Phase", phaseNumber.toString());
      formData.append("userid", UserId.toString());
      dispatch({ type: GlobalStateAction.Busy })
      try {
        const NotificationData = await usePost<any>("File/UploadUnmappedFile", formData);
        setshowProgressBar(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
        setshowProgressBar(false);
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container justify="flex-start" alignContent="flex-start" alignItems="center" spacing={1}>
          <CssBaseline />
          <Container fixed className={classes.container} component="div" >
            <Box p={1} flexShrink={0} bgcolor="white" className={classes.finish}>
              <Typography variant="h5" gutterBottom style={{ color: 'green', fontSize: 60 }}>
                <b> Thank You! </b>
              </Typography>
            </Box>
            <Box p={1} flexShrink={0} bgcolor="white" >
              <img src={Processfile} alt="Processing file for Placment Document" className={classes.image} />
            </Box>
            <div className={classes.box}>
              <Box p={1} flexShrink={0} bgcolor="white" >
                <Typography variant="h5" gutterBottom style={{ marginTop: "40px", fontSize: 30 }}>
                  We will process your accounts shortly and you can expect your acknowledgment report in 24 - 48 hours!
                </Typography>
              </Box>
            </div>
            <Box p={1} flexShrink={0} bgcolor="white" flexDirection="row" className={classes.buttonbox}>
              <Button id="Finish_Submit_btn" endIcon={<SendIcon />} className={classes.buttons} component={Link} to="/placement"
                variant="contained" color="primary">
                Submit Additional Accounts
              </Button>
              {isCSVUpload === true ?
                <SaveTemplate fees={feeValue} balance={mBalanceValue}
                  clientCode={clientCode} checkEnable={enable} selectedHeaderList={selectedHeaderList} savingTempEnable={savingEnable}
                  ignoreRowNumber={ignoreRowNumber} clientList={clientList} /> : null
              }
            </Box>
          </Container>
        </Grid>
      </div>
    </React.Fragment>
  )
}

export default Finish
