import React, { useEffect, useState } from 'react';
import {
  Box, Button, Grid, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import * as _ from 'lodash';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { IClientList } from '../../../models/Admin/ClientPlacements/IClientList';
import { IClientCodeMapping } from '../../../models/Placements/IClientCodeMapping';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PuffLoader from "react-spinners/PuffLoader";
import Backdrop from '@material-ui/core/Backdrop';
import * as constant from '../../../constants/Constant'
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  tableLayout: {
    tableLayout: 'auto',
  },
  searchBox: {
    marginTop: "3%",
    borderRadius: 20
  },
  container: {
    maxWidth: "98vw",
    maxHeight: '78vh',
    minHeight: 680,
    marginTop: '0.2cm',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    display: 'fixed',
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
  },
  formControl: {
    minWidth: 280,
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  actions: {
    width: "100%",
    marginRight: theme.spacing(2)
  },
  buttons: {
    borderRadius: 20,
    fontSize: 15,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "0.3cm",
    float: 'right'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  error: {
    color: 'red',
  },
  div: {
    flexGrow: 1,
  },
  grid: {
    padding: '15px'
  },
  smallFont: {
    fontSize: '10px'
  },
  progressBar: {
    height: '6px'
  },
  title: {
    alignContent: "center",
    color: "blue",
    marginTop: '0.2cm'
  },
  tableHead: {
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
  },
  tableLayout1: {
    marginBottom: '1cm'
  },
  buttonbox: {
    float: 'right'
  },
  tableBody: {
    border: '1px solid #364F6B',
  },
  tableBody1: {
    border: '1px solid #364F6B',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tablebody: {
    maxHeight: 565,
    display: 'flex',
    marginTop: '0.3cm',
    marginBottom: '0.4cm',
    backgroundColor: 'whitesmoke',
  },
}))

function CSVPreviewData(props) {
  let history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  const { state, dispatch } = useGlobalState();
  const [isPreview, setIsPreview] = useState(true);
  const [clientCodeMappingList, setClientCodeMappingList] = useState<IClientCodeMapping[]>(props.location?.state?.clientCodeMappingList);
  const [mappingValue1STP, setMappingValue1STP] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValue1STP);
  const [mappingValuePrec, setMappingValuePrec] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValuePrec);
  const [mappingValueCont, setMappingValueCont] = useState<string>(isPreview === false ? "" : props.location?.state?.mappingValueCont);
  const [placementAccountCSV, setPlacementAccountCSV] = useState<IPlacementAccount[]>(props.location.state.placementAccountCSV);
  const [checkCont, setCheckCont] = useState<boolean>(isPreview === false ? false : props.location?.state?.checkCont);
  const [check1STP, setCheck1STP] = useState<boolean>(isPreview === false ? false : props.location?.state?.check1STP);
  const [checkPrec, setCheckPrec] = useState<boolean>(isPreview === false ? false : props.location?.state?.checkPrec);
  const [feeChecked, setFeeChecked] = useState(isPreview === false ? false : props.location?.state?.feeChecked);
  const [ignoreRowNumber, setIgnoreRowNumber] = useState<any>(props.location?.state?.ignoreRows);
  const [mBalanceChecked, setMBalanceChecked] = useState(props.location?.state?.mBalanceChecked);
  const [mBalanceValue, setMBalanceValue] = useState<any>(props.location?.state?.mBalanceValue);
  let placementAccountList: IPlacementAccount[] = props.location.state.placementAccountList;
  let placementAccountCSVData: string[][] = props.location?.state?.placementAccountCSVData;
  const [feeValue, setFeeValue] = useState<any>(props.location?.state?.feeValue);
  let mappedClientCodeValue: string = props.location.state.mappedClientCodeValue;
  let selectedHeaderList: string[] = props.location?.state?.selectedHeaderList;
  let filteredHeaderList: string[] = props.location?.state?.filteredHeaderList;
  let filteredCSVData: string[][] = props.location?.state?.filteredCSVData;
  let templateList: ITemplateField[] = props.location.state.templateList;
  let clientList: IClientList[] = props.location.state.clientList;
  let phaseNumber: number = props.location.state.phaseNumber;
  let uploadedFile: any = props.location.state.uploadedFile;
  const [accountMsg, setAccountMsg] = useState<string>("");
  let clientCode: string = props.location.state.clientCode;
  const [IsCSVUpload, setIsCSVUpload] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [phase, setPhase] = useState<string>('');

  useEffect(() => {
    if (!filteredCSVData.length) {
      setAccountMsg("There are no accounts to submit. Please go back to the placement page screen and correct mapping errors.");
      setIsSubmit(true);
    }
    else {
      setIsSubmit(false);
    }
  }, [isSubmit])

  function getCellData(cellValue: string, rowIndex: number, columnIndex: number): string {
    if (filteredHeaderList.findIndex(x => x == "phase")) {
      if (filteredHeaderList.findIndex(x => x == "phase") == columnIndex) {
        return placementAccountList[rowIndex].phase;
      }
    }
    if (filteredHeaderList.findIndex(x => x == "client_code")) {
      if (filteredHeaderList.findIndex(x => x == "client_code") == columnIndex) {
        return placementAccountList[rowIndex].client_code;
      }
    }
    return cellValue;
  }

  const getPhaseString = (phaseNum: number) => {
    switch (+phaseNum) {
      case 1:
        return "1STP";
      case 2:
        return "PREC";
      case 3:
        return "CONT";
      default:
        return "";
    }
  }

  useEffect(() => {
    if (phaseNumber === 1) {
      setPhase(constant.FIRSTSTP);
    }
    else if (phaseNumber === 2) {
      setPhase(constant.PREC);
    }
    else if (phaseNumber === 3) {
      setPhase(constant.CONT);
    }
    else {
      setPhase("MULP");
    }
  }, [])

  async function handleSubmit() {
    if (+phaseNumber != 4) {
      placementAccountList.forEach(x => {
        x.phase = getPhaseString(phaseNumber);
      });
    }
    if (clientCode != "-1") {
      placementAccountList.forEach(x => {
        x.client_code = clientCode;
      });
    }

    dispatch({ type: GlobalStateAction.Busy });
    try {
      const response = await usePost(`Placements/ClientUpload`, placementAccountList);
      if (response.status !== 202) {
        dispatch({ type: GlobalStateAction.Error, error: response.statusText });
      }
      history.push(`/finish`, {
        clientCode: clientCode, clientList: clientList, selectedHeaderList: selectedHeaderList,
        feeValue: feeValue, mBalanceValue: Number(mBalanceValue), ignoreRows: ignoreRowNumber,
        isCSVUpload: IsCSVUpload, uploadedFile: uploadedFile, phaseNumber: phase
      });
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  };

  const handleBackClick = () => {
    history.push('/csvfieldnames', {
      placementAccountCSVData: placementAccountCSVData, filteredCSVData: filteredCSVData, selectedHeaderList: selectedHeaderList, placementAccountList: placementAccountList,
      clientCode: clientCode, phaseNumber: phaseNumber, clientList: clientList, isPreview: isPreview, ignoreRows: ignoreRowNumber, placementAccountCSV: placementAccountCSV,
      mBalanceValue: mBalanceValue, mBalanceChecked: mBalanceChecked, filteredHeaderList: filteredHeaderList, feeChecked: feeChecked, feeValue: feeValue, mappingValuePrec: mappingValuePrec,
      mappingValue1STP: mappingValue1STP, mappingValueCont: mappingValueCont, checkCont: checkCont, check1STP: check1STP, checkPrec: checkPrec, clientCodeMappingList: clientCodeMappingList,
      uploadedFile: uploadedFile, mappedClientCodeValue: mappedClientCodeValue
    });
  };

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid} style={{ backgroundColor: 'whitesmoke' }} spacing={1}>
          <CssBaseline />
          <Container fixed className={classes.container} component="div" >
            <Grid container spacing={2}>
              <Grid item xs={4} >
                <Box p={1} flexShrink={0} bgcolor="white" >
                  {isSubmit === true ?
                    <Typography variant="body1" gutterBottom style={{ color: "red", fontSize: 18 }}>
                      {accountMsg}
                    </Typography> : null}
                </Box>
              </Grid>
              <Grid item xs={4} >
                <Box p={1} flexShrink={0} bgcolor="white" >
                  <Typography variant="h4" gutterBottom className={classes.title} >
                    <b> 3. PREVIEW DATA </b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box p={1} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="CSVPD_submit_btn" className={classes.buttons} variant="contained" endIcon={<ArrowForwardIosIcon />}
                    color="primary" onClick={handleSubmit} disabled={!filteredCSVData.length}> Submit Accounts </Button>
                </Box>
                <Box p={1} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                  <Button id="CSVPD_Back_btn" className={classes.buttons} variant="contained" startIcon={<ArrowBackIosIcon />}
                    color="primary" onClick={handleBackClick}>Back </Button>
                </Box>
              </Grid>
            </Grid>
            <TableContainer component={Paper} className={classes.tablebody}>
              <Table aria-label="collapsible table" size="medium" stickyHeader>
                <TableHead >
                  <TableRow  >
                    {filteredCSVData[0]?.map((headCell, index) => (
                      <TableCell style={{ color: "white", fontSize: 16, backgroundColor: '#364F6B' }}
                        align={"left"} key={index} >
                        {templateList?.find((x) => x?.field_name == filteredHeaderList[index])?.display_name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={{ marginBottom: "10px" }}>
                  {filteredCSVData?.map((row, rowIndex) => (
                    <TableRow key={"row" + rowIndex}>
                      {filteredCSVData[rowIndex]?.map((cell, cellIndex) => (
                        <TableCell className={classes.tableBody1} key={"cell" + rowIndex + "_" + cellIndex}>
                          {getCellData(cell, rowIndex, cellIndex)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Grid>
      </div>
    </React.Fragment >
  )
}

export default withRouter(CSVPreviewData)
