import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

// colors
const primary = '#2377E4'
const secondary = '#007FFF'
const darkBlack = 'rgb(36, 40, 44)'
const background = '#E2E8EF'
const warningLight = 'rgba(253, 200, 69, .3)'
const warningMain = 'rgba(253, 200, 69, .5)'
const warningDark = 'rgba(253, 200, 69, .7)'

// spacing
const spacing = 8

// border
const borderWidth = 2
const borderColor = 'rgba(0, 0, 0, 0.13)'

const theme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
    },
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: 'static',
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        // [`@media (max-width:  ${theme.breakpoints.values.sm}px)`]: {
        //   paddingLeft: spacing,
        //   paddingRight: spacing,
        // },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: '100%',
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack,
      },
    },
    MuiExpansionPanelDetails: {
      // root: {
      //   [`@media (max-width:  ${sm}px)`]: {
      //     paddingLeft: spacing,
      //     paddingRight: spacing,
      //   },
      // },
    },
  },
})

export default responsiveFontSizes(theme)
