import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { loginRequest } from '../auth/authConfig';
import ARMlogo from '../images/header/header-logo.svg';
import { AccessContext } from '../models/accesscontrol/AccessContext';
import { IWidgetInfo } from '../models/accesscontrol/IWidgetInfo';
import { WidgetMap } from '../models/accesscontrol/WidgetMap';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0, bottom: 0, left: 0, right: 0,
    margin: 'auto',
    maxWidth: '100vw',
    height: '100vh',
    alignItems: "center",
    backgroundColor: '#215176',
    display: "flex",
    justifyContent: "center",
  },
  gridcontainer: {
    maxWidth: '600px',
    height: '500px',
    alignItems: "center",
    backgroundColor: 'white',
    justifyContent: "center",
    borderRadius: 30,
    display: "flex",
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
  },

  image: {
    width: '100%',
    height: '100%'
  },
  logo: {
    width: '500px',
    height: '160px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-0.7cm',
    objectFit: 'cover',

  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'black',
    fontSize: 43,
    marginTop: '25px'
  },
  loginbutton: {
    backgroundColor: '#283593',
    color: 'white',
    borderRadius: '30px',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '280px',
    height: '60px',
    //boxShadow: '2px 1px 5px 2px rgba(103, 128, 159, 1)',
    '&:hover': {
      border: 'none',
      fontSize: '20px',
      fontWeight: 'bold',
      backgroundColor: '#3bb78f',
      boxShadow: '0px 15px 20px rgba(46, 229, 157, 0.4)',
      color: '#fff',
    }
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 8,
    marginTop: '-0.5cm',
  },
  divider: {
    width: '180px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ff9800',
    fontWeight: 'bold',
    border: '2px solid #ff9800',
    borderRadius: '20px',
  }
}));

const Login: React.FC<{ isLoading: boolean, isAuth?: boolean }> = (props) => {
  const { state, dispatch } = useGlobalState();
  const { accounts, instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  let history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (isAuthenticated) {
      let account = accounts[0]
      instance.ssoSilent({ ...loginRequest, loginHint: account.username }).then(a => {
        dispatch({
          type: GlobalStateAction.LoggedIn, user: {
            id: account.homeAccountId ?? "",
            name: account.username ?? "",
            profileImage: "./images/header-logo.png",
            email: account.username ?? "",
            userToken: a.accessToken,
            homeAccountId: account.homeAccountId ?? "",
            localAccountId: account.localAccountId ?? "",
            objectId: a.uniqueId
          }
        });

        let requestGuid = { "objectId": a.uniqueId };
        usePost<AccessContext>("User/GetUserByObjectId", requestGuid).then((userObj) => {
          useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userObj.data.id).then((clientsWidgets) => {
            for (let index = 1; index <= WidgetMap.size; index++) {
              const value = WidgetMap.get(index);
              if (value && clientsWidgets.data.filter(w => w.widgetId === index).length === 1) {
                userObj.data[value] = true;
              }
              dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userObj.data })
            };
          });
        });
      })
    }
  }, [isAuthenticated])

  const handleSignin = () => {
    if (!inProgress && accounts.length > 0) {
      const account = accounts[0]
      if (account) {
        try {
          instance.ssoSilent(loginRequest).then(a => {
            dispatch({
              type: GlobalStateAction.LoggedIn, user: {
                id: account.homeAccountId ?? "",
                name: a.account?.name ?? "",
                profileImage: "./images/header-logo.png",
                email: account.username ?? "",
                userToken: a.accessToken,
                homeAccountId: account.homeAccountId ?? "",
                localAccountId: account.localAccountId ?? "",
                objectId: a.uniqueId
              }
            })
          })
        }
        catch (exception) {
          instance.loginPopup(loginRequest)
        }
      }
    }
    else {
      instance.loginPopup(loginRequest).then(a => {
        dispatch({
          type: GlobalStateAction.LoggedIn, user: {
            id: a.account?.homeAccountId ?? "",
            name: a.account?.name ?? "",
            profileImage: "./images/header-logo.png",
            email: a.account?.username ?? "",
            userToken: a.accessToken,
            homeAccountId: a.account?.homeAccountId ?? "",
            localAccountId: a.account?.localAccountId ?? "",
            objectId: a.uniqueId
          }
        });
        if (a.account !== null) {
          let requestGuid = { "objectId": a.uniqueId };
          usePost<AccessContext>("User/GetUserByObjectId", requestGuid).then((userObj) => {
            useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userObj.data.id).then((clientsWidgets) => {
              for (let index = 1; index <= WidgetMap.size; index++) {
                const value = WidgetMap.get(index);
                if (value && clientsWidgets.data.filter(w => w.widgetId === index).length === 1) {
                  userObj.data[value] = true;
                }
              };
              dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userObj.data })
            });
            if (userObj.data.role === 2 || userObj.data.role === 3) {
              history.push('/admin');
            } else if (userObj.data.role === 0 || userObj.data.role === 1) {
              history.push('/dashboard');
            } else {
              history.push('/ManageClientFolderAccess')
            }
          })
        }
      })
    }
  }

  return (
    <React.Fragment>
      <Container className={classes.container} component="div" >
        <Grid container spacing={0} className={classes.gridcontainer}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Welcome Back
            </Typography>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
          <Grid item xs={12}>
            <img src={ARMlogo} alt="ARM_Solutions" className={classes.logo} />
          </Grid>
          <Grid item xs={12} className={classes.buttonbox}>
            <Button color="primary" variant="outlined" startIcon={<VpnKeyIcon style={{ fontSize: 30 }} />} className={classes.loginbutton}
              onClick={handleSignin} disabled={props.isLoading || props.isAuth}>Login</Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Login
