import {
    Box, Button, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Skeleton from '@material-ui/lab/Skeleton';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from "react-csv";
import PuffLoader from "react-spinners/PuffLoader";
import '../App.css';
import Footer from '../components/Footer/Footer';
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import RollExpandableRow from '../components/Roll/RollExpandableRow';
import VideoPlayer from '../components/YouTubePlayer/VideoPlayer';
import { IDebtor } from '../models/Roll/IDebtor';
import { IRollAll } from "../models/Roll/IRollAll";
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { usePost } from '../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    div: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    grid: {
        padding: '15px',
    },
    progressBar: {
        height: '6px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    gridStyle: {
        marginTop: '-0.3cm',
    },
    searchBar: {
        borderRadius: 50,
        width: "30cm",
        marginLeft: '-380px',
        border: '2px black solid',
        height: '43px',
        fontSize: 16,
        textIndent: '10px'
    },
    tablebody: {
        maxHeight: 608,
        overflowX: 'hidden'
    },
}))

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const headCells = [
    { id: "dbT_CLIENT", numeric: false, disablePadding: false, label: "CLIENT ID", sortable: true },
    { id: "dbT_NO", numeric: false, disablePadding: false, label: "AGENCY ID", sortable: true },
    { id: "dbT_CLNT_ACNT1", numeric: false, disablePadding: false, label: "ACCOUNT ID", sortable: true },
    { id: "dbT_NAME", numeric: false, disablePadding: false, label: "NAME", sortable: true },
    { id: "deM_PHONE1", numeric: false, disablePadding: false, label: "PHONE NUMBER", sortable: true },
    { id: "dbT_REFERRAL_DATE", numeric: false, disablePadding: false, label: "ACCOUNT AGING", sortable: true },
    { id: "dbT_REFERRAL_AMT", numeric: false, disablePadding: false, label: "REFERRED AMOUNT", sortable: true },
    { id: "dbT_PRINCIPAL_DUE", numeric: false, disablePadding: false, label: "BALANCE", sortable: true },
    { id: "stS_DESC", numeric: false, disablePadding: false, label: "STATUS", sortable: true },
    { id: "dbT_STATUS_DATE", numeric: false, disablePadding: false, label: "STATUS DATE", sortable: true },
    { id: "", numeric: false, disablePadding: false, label: "ADVANCE TO CONTINGENCY", sortable: false, },
    { id: "", numeric: false, disablePadding: false, label: "UNDO", sortable: false },
    { id: "", numeric: false, disablePadding: false, label: "REMOVE", sortable: false }
];

function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ background: "#007FFF", color: "white", fontSize: "90%", height: '30px' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label || headCell.sortable}
                            {orderBy === headCell.id || headCell.sortable ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function ComparatorSortingGrid() {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [selected, setSelected] = React.useState<string>();
    const [rollList, setRollList] = useState<IDebtor[]>([]);
    const [count, setCount] = useState<number>(0);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [orderBy, setOrderBy] = useState<string>("DBT_REFERRAL_DATE");
    const [order, setOrder] = useState<any>('desc');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const canRollAccount = state.userAccessContext?.rollAccounts;
    const [isValid, setIsValid] = useState(true);
    const ClientCode = state.userAccessContext?.clientCodes.join(",");
    const userName = state.userAccessContext?.id;
    const [totalRecords, setTotalRecords] = useState<number>();
    const video = 'PLa-ROHbLmU';
    const title = 'A R M WebView: Rolling Accounts from Flat Fee to Contingency';
    const [mapcount, setMapCount] = useState(false);
    const [searchText, setSearchText] = useState<string>('');
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [rollAllTriggered, setRollAllTriggered] = useState(false);
    const wrapperRef = useRef(null);
    const [GetdebtorNo, setGetdebtorNo] = useState("");

    const handleRowClick = (accountId: string) => {
        setSelected(selected == accountId ? "" : accountId);
        setGetdebtorNo(accountId);
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "clients": ClientCode,
                    "searchText": searchText ?? "",
                    "orderBy": orderBy,
                    "to": rowsPerPage,
                    "orderDirection": order,
                    "startingRecordNumber": (searchText !== "" ? (page * 0) + 1 : (page * rowsPerPage) + 1)
                };
                await usePost<{ rollList: IDebtor[], qty: number, remaining: number }>("RollList", request).then((rollList) => {
                    setRollList(rollList.data["rollList"]);
                    setCount(rollList.data?.remaining);
                    setMapCount(!rollList.data.qty.toString() === undefined ? false : true);
                    setTotalRecords(rollList.data.qty === undefined ? 0 : rollList.data.qty);
                });
                setIsValid(false);
                setshowProgressBar(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
                setshowProgressBar(false);
            }
        })()
    }, [rowsPerPage, page, orderBy, rollAllTriggered, order]);

    async function getRollListAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "clients": ClientCode,
                    "searchText": searchText ?? "",
                    "orderBy": orderBy,
                    "to": rowsPerPage,
                    "orderDirection": order === "desc" ? 1 : 0,
                    "startingRecordNumber": (searchText !== "" ? (page * 0) + 1 : (page * rowsPerPage) + 1)
                };
                await usePost<{ rollList: IDebtor[], qty: number, remaining: number }>("RollList", request).then((rollList) => {
                    setRollList(rollList.data["rollList"]);
                    setCount(rollList.data?.remaining);
                    setMapCount(!rollList.data.qty.toString() === undefined ? false : true);
                    setTotalRecords(rollList.data.qty === undefined ? 0 : rollList.data.qty);
                });
                setIsValid(false);
                setshowProgressBar(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
                setshowProgressBar(false);
            }
        })()
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            handleRelease();
        }
    };

    const handleRelease = () => {
        if (GetdebtorNo !== undefined && GetdebtorNo !== null) {
            (async () => {
                dispatch({ type: GlobalStateAction.Busy });
                try {
                    let YieldRelease = {
                        "debtorNumber": GetdebtorNo,
                        "userId": state.userAccessContext?.id
                    }
                    await usePost("InquiryReleaseLock", YieldRelease);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex })
                    dispatch({ type: GlobalStateAction.Idle })
                }
                finally {
                    dispatch({ type: GlobalStateAction.Idle })
                }
            })()
        }
    }

    const handleRequestSort = (event, property) => {
        setshowProgressBar(true);
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setshowProgressBar(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setshowProgressBar(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const initiateSearch = () => {
        setTriggerSearch(!triggerSearch)
        setshowProgressBar(true);
        getRollListAsync();
    }

    const RollAll = () => {
        (async () => {
            setshowProgressBar(true);
            try {
                let request = {
                    "clientCode": ClientCode,
                    "user_Id": userName,
                };
                await usePost<IRollAll[]>('RollAll', request);
                setRollAllTriggered(true);
                setshowProgressBar(false);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <div className={classes.div} ref={wrapperRef}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <CssBaseline />
                <Grid container className={classes.grid} spacing={2}>
                    <Grid item xs={6} sm={4}>
                        <Typography variant="h4" gutterBottom style={{ color: "blue", textAlign: 'left' }}>
                            <b>ROLL LIST</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={4}>
                        <input value={searchText}
                            type='text' placeholder="Search (account number, name, SSN, phone)"
                            className={classes.searchBar}
                            onChange={e => setSearchText(e.target.value)}
                            onKeyPress={event => { if (event.key === 'Enter') { initiateSearch(); } }}
                            maxLength={255}
                        />
                    </Grid>
                    <Grid item xs={3} sm={4}>
                        <Box alignItems="flex-end" display="flex" justifyContent="flex-end" style={{ marginTop: '-23px' }}>
                            <Box display="flex" p={1} style={{ marginTop: '-30px' }}>
                                <Button id="Roll_RollCount" style={{ borderRadius: 20, background: "#FF0000", color: "white" }} variant="contained">
                                    {state.busy && !count ? <Skeleton variant="circle" width={20} height={20} animation="wave" /> : count} Left
                                </Button>
                            </Box>
                            <Box display="flex" p={1}>
                                <Button id="Roll_RollAll_Button" startIcon={<SupervisorAccountIcon />} style={{ borderRadius: 20 }} variant="contained" color="primary"
                                    onClick={(_) => { RollAll(); getRollListAsync(); }} disabled={!canRollAccount}>
                                    Roll All
                                </Button>
                            </Box>
                            <Box display="flex">
                                <VideoPlayer video={video} title={title} />
                            </Box>
                            <Box style={{ marginTop: '2px' }}>
                                <LightTooltip title="Download Roll List">
                                    <IconButton id="Roll_DownloadCSV" aria-label="Roll_DownloadCSV" style={{ marginTop: "5px" }} disabled={isValid} onClick={() => { setIsValid(true); }} >
                                        <CSVLink
                                            data={rollList}
                                            filename={"RollList.csv"}
                                            className="btn btn-primary"
                                            disabled={isValid}
                                            target="_blank" >
                                            <CloudDownloadIcon style={{ color: "black" }} fontSize="large" />
                                        </CSVLink>
                                    </IconButton>
                                </LightTooltip>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={12} item className={classes.gridStyle}>
                        <Paper style={{ width: '100%' }}>
                            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                                <Table id="rollClients" aria-label="collapsible table" size="small" stickyHeader >
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>{stableSort(rollList, getComparator(order, orderBy)).map(
                                        (row) => {
                                            return (
                                                <RollExpandableRow onClick={handleRowClick} selected={selected === row.dbT_NO}
                                                    key={row.dbT_NO} onDelete={getRollListAsync} onRollRemove={getRollListAsync}
                                                    onUndoCloseRemove={getRollListAsync} onEroll={getRollListAsync} row={row}
                                                    selectedDBTNumber={selected} searchWord={searchText} />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                {searchText && !rollList.length ?
                                    < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                        No records to display..
                                    </Typography>
                                    : null}
                            </TableContainer>
                            <TablePagination
                                id="Roll_TablePagination"
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={mapcount === true ? totalRecords : 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid >
                <Footer />
            </div>
        </React.Fragment >
    )
}