import {
  Button, Grid, makeStyles, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import TableContainer from '@mui/material/TableContainer';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import * as constant from '../../constants/Constant';
import { IClientCodeConfirmation } from '../../models/client/IClientCodeConfirmation';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
  searchBox: {
    borderRadius: 30,
    border: '2px black solid',
    width: '100%',
    height: '43px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    fontSize: 16,
    textIndent: '10px'
  },
  grid: {
    padding: '8px'
  },
  div: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  TextHighlight: {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: "yellow",
    fontSize: '16px',
  },
  TextNormal: {
    color: 'black',
    fontSize: 16
  },
  tableBody: {
    maxHeight: 615,
  },
  tablegrid: {
    marginTop: '0.1cm'
  },
  confirmText: {
    marginTop: '10px',
    float: 'right',
    marginRight: '-3cm'
  },
  confirmButton: {
    borderRadius: 20,
    marginTop: "5px",
    float: 'right',
    marginRight: '-0.5cm',
    background: "#008000",
    color: "white",
    '&:hover': {
      backgroundColor: "#008000"
    }
  },
  confirmText1: {
    marginTop: '10px',
    float: 'right',
    marginRight: '-2.3cm'
  },
  confirmButton1: {
    borderRadius: 20,
    marginTop: '5px',
    float: 'right',
    marginLeft: '15px',
    background: "#FF6666",
    color: "white",
    '&:hover': {
      backgroundColor: '#FF6666'
    }
  },
  nobutton: {
    background: "red",
    marginRight: "10px",
    borderRadius: 30,
    color: "white",
    '&:hover': {
      backgroundColor: 'red'
    }
  },
  yesbutton: {
    background: "green",
    marginRight: "10px",
    borderRadius: 30,
    color: "white",
    '&:hover': {
      backgroundColor: 'green'
    }
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white'
  },
  unlockbutton: {
    display: 'flex',
    width: '150px'
  }
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      width: "",
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      height: '1cm',
    },
  }),
)(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const headCells = [
    { id: "clientCode", disablePadding: false, label: "CLIENT CODE", sortable: true },
    { id: "clientName", disablePadding: false, label: "CLIENT NAME", sortable: true },
    { id: "phase", disablePadding: false, label: "PHASE", sortable: true },
    { id: "confirmedBy", disablePadding: false, label: "CONFIRMED BY", sortable: true },
    { id: "confirmedDate", disablePadding: false, label: "CONFIRMED DATE", sortable: true },
    { id: "", disablePadding: false, label: "ACTION", sortable: false },
  ]
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ background: "#007FFF", color: "white", fontSize: "120%" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ClientInformation = () => {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const [confirmsuccessdialog, setConfirmSuccessDialog] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState(" ");
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [getRow, setGetRow] = useState<IClientCodeConfirmation>();
  const [clientConfirmation, setClientInformationTrigger] = useState(false);
  const [searchClient, setSearchClient] = useState<string>('');
  const [GetClients, setGetClients] = useState<IClientCodeConfirmation[]>([]);
  const [totalRecordCount, settotalrecordCount] = useState(0);
  const [confirmedCountone, setConfirmedCountone] = useState(0);
  const [notconfirmedCountone, setNotconfirmedCountone] = useState(0);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setClientInformationTrigger(false);
  };

  const handleRequestSort = (event, property) => {
    setShowProgressBar(true)
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setShowProgressBar(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setShowProgressBar(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowProgressBar(true);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleCloseSuccessDialog() {
    setConfirmSuccessDialog(false);
  }
  function openUnlockDialog() {
    setConfirmSuccessDialog(true);
  }

  useEffect(() => {
    GetClientInformation();
  }, [rowsPerPage, page])



  async function GetClientInformation() {
    dispatch({ type: GlobalStateAction.Busy })
    try {
      let request = {
        "user_Id": state.userAccessContext?.id,
        "isAdmin": true,
        "rowsPerPage": rowsPerPage,
        "searchParameters": searchClient ?? "",
        "startingRecordNumber": (page * rowsPerPage) + 1
      }
      const response = await usePost<IClientCodeConfirmation[]>("Client/SearchClientConfirmation", request);
      setGetClients(response.data["clientCodeConfirmations"]);
      settotalrecordCount(response.data["count"]);
      setConfirmedCountone(response.data["clientConfirmationTypes"][0].typeCount);
      setNotconfirmedCountone(response.data["clientConfirmationTypes"][1].typeCount);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
      setShowProgressBar(false);
    }
  }

  async function SearchClientInformation() {
    dispatch({ type: GlobalStateAction.Busy })
    setShowProgressBar(true);
    try {
      let request = {
        "user_Id": state.userAccessContext?.id,
        "isAdmin": true,
        "rowsPerPage": rowsPerPage,
        "searchParameters": searchClient ?? "",
        "startingRecordNumber": (page * rowsPerPage) + 1
      }
      const response = await usePost<IClientCodeConfirmation[]>("Client/SearchClientConfirmation", request);
      setGetClients(response.data["clientCodeConfirmations"]);
      settotalrecordCount(response.data["count"]);
      setConfirmedCountone(response.data["clientConfirmationTypes"][0].typeCount);
      setNotconfirmedCountone(response.data["clientConfirmationTypes"][1].typeCount);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex })
      dispatch({ type: GlobalStateAction.Idle })
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle })
      setShowProgressBar(false);
      setPage(0);
    }
  }

  async function unlockClient(event, row: IClientCodeConfirmation) {
    if (row != null) {
      row.isConfirmed = "No";
      const response = await usePost<{ clientCodeConfirmation: IClientCodeConfirmation }>("Client/ClientStatusConfirmation", row)
      if (response.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: response.statusText });
      } else {
        setClientInformationTrigger(true);
        setConfirmSuccessDialog(false);
      }
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container spacing={1} className={classes.grid} style={{ backgroundColor: 'whitesmoke', }} wrap="wrap">
          <Grid item xs={6} sm={5} md={2}>
            <Typography variant="h5" gutterBottom style={{ color: "blue", fontSize: 28, marginTop: '5px' }}>
              <b> CLIENT INFORMATION </b>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={5} md={5}>
            <input value={searchClient}
              type='text' placeholder="Search (client code ,client name)"
              className={classes.searchBox}
              onChange={e => setSearchClient(e.target.value)}
              onKeyPress={event => { if (event.key === 'Enter') { SearchClientInformation(); } }}
              maxLength={255}
            />
          </Grid>
          <Grid item xs >
            <Typography variant="h6" gutterBottom className={classes.confirmText}>
              <b> CONFIRMED: </b>
            </Typography>
          </Grid>
          <Grid item xs >
            <Button id="Cinfo_ConfCount" className={classes.confirmButton}
              variant="contained" startIcon={<NotificationsActiveIcon />} >
              {confirmedCountone}
            </Button>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" gutterBottom className={classes.confirmText1}>
              <b> NOT CONFIRMED : </b>
            </Typography>
          </Grid>
          <Grid item xs>
            <Button id="Cinto_NotConfCount" className={classes.confirmButton1}
              variant="contained" startIcon={<NotificationsActiveIcon />} >
              {notconfirmedCountone}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.tablegrid}>
            <Paper>
              <TableContainer className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table size="small" stickyHeader aria-label="sticky table" >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody >
                    {stableSort(GetClients, getComparator(order, orderBy)).map(
                      (row) => {
                        let confirmedDate = moment(row.confirm_Date).format(state.GlobalUtils?.settingValue);
                        let phase = (row.phase == constant.PREC ? "Flat Fee" : row.phase == constant.CONT ? "Contingency" : "1st Party");
                        return (
                          <StyledTableRow style={{ padding: 20 }}>
                            <StyledTableCell align="left">
                              <span className={`${searchClient.toUpperCase() === "" ? classes.TextNormal : row.clientCode.includes(searchClient.toUpperCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                                {row.clientCode}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <span className={`${searchClient === "" ? classes.TextNormal : row.clientName.includes(searchClient) ? classes.TextHighlight : classes.TextNormal}`}>
                                {row.clientName}
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {phase}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.confirmed_By}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {confirmedDate === 'Invalid date' ? null : confirmedDate}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Button className={classes.unlockbutton} style={{ borderRadius: 20, color: "white", display: row.isConfirmed === "Yes" ? "block" : "none" }}
                                variant="contained" color="primary" onClick={(e) => { setGetRow(row); openUnlockDialog(); }} >
                                Unlock
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                id="Cinfo_TablePagination"
                rowsPerPageOptions={[10, 15, 25]}
                component="div"
                count={totalRecordCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
            <Footer />
          </Grid>
          <Dialog PaperProps={{ style: { borderRadius: 15 } }} open={confirmsuccessdialog} onClose={handleCloseSuccessDialog}
            aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
            <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseSuccessDialog}>
              <Typography variant="h5" gutterBottom className={classes.titleheader}>
                CLIENT INFORMATION
              </Typography>
            </DialogTitleHeader>
            <DialogContent>
              <Typography variant="h5" style={{ color: "black" }} gutterBottom>
                Are you sure you want to Unlock client Information?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button id="Conf_Okay_btn" className={classes.yesbutton} onClick={(e) => { unlockClient(e, getRow) }} autoFocus>
                Yes
              </Button>
              <Button id="Conf_Cancel_btn" onClick={handleCloseSuccessDialog} className={classes.nobutton}>
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={clientConfirmation} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} autoHideDuration={4000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
              Client Unlocked Successfully.
            </Alert>
          </Snackbar>
        </Grid>
      </div>
    </React.Fragment >
  )
}

export default ClientInformation
