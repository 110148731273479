import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import VisibilityIcon from '@material-ui/icons/Visibility';
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { IGetDocumentUploadLogData } from '../../models/DocumentUploadLog/GetDocumentUploadLog';
import { useFetch } from '../../utils/apiHelper';
import CloseIcon from '@material-ui/icons/Close';
import { IDocumentDownloadFileResult } from '../../models/UploadLog/IDocumentDownloadFileResult';
import React, { useState } from 'react';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { AppBar, Backdrop, Dialog, IconButton, makeStyles, Slide, TableCell, Toolbar, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import PuffLoader from "react-spinners/PuffLoader";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconvisible: {
    float: 'left',
    marginLeft: '15px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const DocumentPDFViewer: React.FC<{ row: IGetDocumentUploadLogData, enable: boolean }> = (props) => {
  const base = ("data:application/pdf;base64,");
  const { state, dispatch } = useGlobalState();
  const [serviceURL, setServiceURL] = useState('');
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [ProgressBar, setshowProgressBar] = useState(false);

  const handleClickOpen = () => {
    setshowProgressBar(true);
    getPDFFileAsync();
  };

  const handleClose = () => {
    setOpen(false);
  }

  function getPDFFileAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        const responseDownloadFile = await useFetch<IDocumentDownloadFileResult>(`DocumentUploadLog/DocumentFileDownload?fileId=${props.row.file_id}`);
        let url = URL.createObjectURL(base64toBlob(base + responseDownloadFile.data.fileContent));
        setServiceURL(url);
        setOpen(true);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex })
        dispatch({ type: GlobalStateAction.Idle })
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle })
        setshowProgressBar(false);
      }
    })();
  }

  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    const bytes = window.atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <React.Fragment>
      <div>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={100} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <IconButton disabled={!props.enable} aria-label="delete" onClick={handleClickOpen} className={classes.iconvisible}>
          <VisibilityIcon fontSize="large" style={!props.enable ? { fontSize: 24, color: '#AEAEAE' } : { fontSize: 24, color: '#1E90FF' }} />
        </IconButton>
        {open ?
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Document Viewer
                </Typography>
              </Toolbar>
            </AppBar>
            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js">
              <Viewer
                fileUrl={serviceURL}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </Dialog>
          : null}
      </div>
    </React.Fragment >
  );
};

export default DocumentPDFViewer;