import DateFnsUtils from '@date-io/date-fns';
import {
    Button, CircularProgress, createStyles, FormControl, Grid, IconButton, InputLabel, makeStyles, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel,
    TextField, Theme, withStyles
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import PuffLoader from "react-spinners/PuffLoader";
import DownloadDocumentLogFile from ".././pages/DocumentUploadLog/DownloadDocumentLogFile";
import Footer from '../components/Footer/Footer';
import LightTooltip from "../components/GlobalStyles/LightTooltip";
import clearIcon from '../images/buttonIcons/clearIcon.svg';
import { UserRole } from '../models/accesscontrol/ClientUserAccessContext';
import { IGetDocumentUploadLogData } from '../models/DocumentUploadLog/GetDocumentUploadLog';
import { IGetDocumentUploadLogCSVData } from '../models/DocumentUploadLog/IGetDocumentUploadLogCSVData';
import { IAdminGetClients } from "../models/UploadLog/AdminGetClients";
import { IGetUsers } from "../models/UploadLog/GetUsersList";
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import DocumentPDFViewer from './DocumentUploadLog/DocumentPDFViewer';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "2cm",
        alignItems: "center"
    },
    actions: {
        width: "100%",
        marginTop: "1cm"
    },
    buttons: {
        borderRadius: 20,
        background: "#007FFF",
        color: "white",
        marginTop: "20px",
    },
    textField: {
        marginRight: theme.spacing(1),
        width: 250,
        marginTop: "12px"
    },
    table: {
        minWidth: 700
    },
    grid: {
        padding: '10px'
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '10px',
        border: '1px solid gray'
    },
    root: {
        flexGrow: 1,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    progressBar: {
        height: '6px'
    },
    Title: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    snackbar: {
        color: 'black',
        marginTop: '-0.5cm',
    },
    snackbarfont: {
        fontSize: 16,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tablebody: {
        maxHeight: 536
    }
}))

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 15,
            width: 600
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            width: "100%",
            height: 48,
        },
    }),
)(TableRow);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const DocumentUploadlog = () => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();

    const [csvData, setCSVData] = useState<IGetDocumentUploadLogCSVData[]>([]);
    const [ListLog, setListLog] = useState<IGetDocumentUploadLogData[]>([]);
    const [clientList, setclientList] = useState<IAdminGetClients[]>([]);
    const [activeUsers, setactiveUsers] = useState<IGetUsers[]>([]);;

    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());

    const [SelectActiveUser, setSelectActiveUser] = useState<any>();
    const [SelectClients, setSelectClients] = useState<any>();

    const [orderBy, setOrderBy] = React.useState(" ");
    const [order, setOrder] = React.useState("asc");

    const [selectedFirstDate, setSelectedFirstDate] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(false);
    const [selectedClient, setSelectedClient] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(true);
    const [selectedUser, setSelectedUser] = useState(false);
    const [clearFilter, setClearFilter] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isValid, setIsValid] = useState(true);
    const [mapped, setMapped] = useState(false);

    const usersLoading = activeUsers.length === 0;
    const clientsLoading = clientList.length === 0;

    const [totalRecords, setTotalRecords] = useState<number>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy"

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedFirstDate(true);
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setClearFilter(false);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedEndDate(true);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setShowProgressBar(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (activeUsers.length <= 0) {
            (async () => {
                dispatch({ type: GlobalStateAction.Busy });
                try {
                    await useFetch<IGetUsers[]>("UploadLog/UserGet").then((GetUsersList) => {
                        setactiveUsers(GetUsersList.data);
                    });

                    const AdminGetClients = await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                        setclientList(AdminGetClients.data);
                    });
                    setShowProgressBar(true);
                }
                catch (ex) {
                    dispatch({ type: GlobalStateAction.Error, error: ex })
                    dispatch({ type: GlobalStateAction.Idle })
                }
                finally {
                    dispatch({ type: GlobalStateAction.Idle })
                    setShowProgressBar(false);
                }
            })()
        }
    }, [activeUsers])

    useEffect(() => {
        getUploadLogListAsync();
    }, [rowsPerPage, page])

    const HandleFilter = () => {
        setPage(0);
        getUploadLogListAsync();
        setOpen(true);
    }

    async function getUploadLogListAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        setShowProgressBar(true);
        try {
            let request = {
                "rowsPerPage": rowsPerPage,
                "startdate": (selectedFirstDate ? StartDate : ''),
                "enddate": (selectedEndDate ? EndDate : ''),
                "userid": (selectedUser ? (SelectActiveUser === null ? '' : SelectActiveUser.id) : ''),
                "clientid": (selectedClient ? (SelectClients === null ? '' : SelectClients.client_code) : ''),
                "usertype": (PortalUser.role === 10 ? null : PortalUser.role),
                "startingRecordNumber": (page * rowsPerPage) + 1,
            };
            await usePost<{ documentUploadLog: IGetDocumentUploadLogData[], qty: number }>("DocumentUploadLog/GetDocumentUploadLog", request).then((GetDocUploadLog) => {
                setListLog(GetDocUploadLog.data["documentUploadLog"]);
                setMapped(!GetDocUploadLog.data.documentUploadLog.length ? true : false);
                setTotalRecords(GetDocUploadLog.data.qty);
            });
            setShowProgressBar(false);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    const handleClearFilter = () => {
        setPage(0);
        ClearUploadLogListAsync();
        setClearFilter(true);
    }

    async function ClearUploadLogListAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        setShowProgressBar(true);
        try {
            let request = {
                "rowsPerPage": rowsPerPage,
                "startdate": '',
                "enddate": '',
                "userid": '',
                "clientid": '',
                "usertype": '',
                "startingRecordNumber": (page * rowsPerPage) + 1,
            };
            await usePost<{ documentUploadLog: IGetDocumentUploadLogData[], qty: number }>("DocumentUploadLog/GetDocumentUploadLog", request).then((GetDocUploadLog) => {
                setListLog(GetDocUploadLog.data["documentUploadLog"]);
                setMapped(!GetDocUploadLog.data.documentUploadLog.length ? true : false);
                setTotalRecords(GetDocUploadLog.data.qty);
            });
            setOpen(true);
            setShowProgressBar(false);
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    function onUsersSelect(event, value) {
        setSelectActiveUser(value);
        setSelectedUser(true);
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const response = await useFetch<IGetUsers[]>("UploadLog/UserGet?id=" + value.id)
                setactiveUsers(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    function onClientsSelect(event, value) {
        setSelectedClient(true);
        setSelectClients(value);
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const response = await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((GetClients) => {
                    setclientList(GetClients.data);
                });
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    var Users: IGetDocumentUploadLogData = {
        file_id: "",
        file_name: "",
        upload_date: "",
        rid: "",
        clientId: "",
        userid: null,
        fullusername: "",
        global_admin: null,
        bloburi: "",
        role: UserRole.SelectRole
    }

    const [PortalUser, setPortalUser] = useState(Users);

    const handleNotificationTypeChange = (event) => {
        const name = event.target.name;
        setPortalUser({
            ...PortalUser,
            [name]: event.target.value
        });
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        const headCells = [
            { id: "fullusername", disablePadding: false, label: "USER", sortable: true },
            { id: "role", disablePadding: false, label: "USERTYPE", sortable: true },
            { id: "clientId", disablePadding: false, label: "CLIENT ID", sortable: true },
            { id: "rid", disablePadding: false, label: "DEBTOR ID", sortable: true },
            { id: "upload_date", disablePadding: false, label: "DATE", sortable: true, marginLeft: "20px" },
            { id: "upload_date", disablePadding: false, label: "TIME", sortable: true },
            { id: "file_id", disablePadding: false, label: "FILE", sortable: true },
            { id: "file_id", disablePadding: false, label: "PREVIEW", sortable: true }
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            id="DocUpLog_TableHeader"
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ background: "#007FFF", color: "white", fontSize: "120%" }}>
                            <TableSortLabel
                                id="DocUpLog_TableHeader_SortLable"
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                style={{ marginLeft: `${headCell.marginLeft}` }}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container className={classes.grid} style={{ backgroundColor: 'white' }} wrap="wrap">
                    <Grid item xs={5} className={classes.Title} >
                        <Typography variant="h4" gutterBottom style={{ color: "blue" }}>
                            <b> DOCUMENT UPLOAD LOG </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.Title}>
                        {StartDate > EndDate ? <Typography variant='h6' style={{ color: 'red' }}>Invalid End Date.</Typography> : ""}
                    </Grid>
                    <Snackbar className={classes.snackbar} open={open} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} autoHideDuration={4000} onClose={handleClose}>
                        <Alert className={classes.snackbarfont} onClose={handleClose} severity="success">
                            Filter Successfully Triggered!
                        </Alert>
                    </Snackbar>

                    <Snackbar className={classes.snackbar} open={clearFilter} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} autoHideDuration={4000} onClose={handleClose}>
                        <Alert className={classes.snackbarfont} onClose={handleClose} severity="success">
                            Filter Cleared Successfully!
                        </Alert>
                    </Snackbar>
                    <Grid container spacing={1} className={classes.maingrid} justify='space-evenly' >
                        <Grid item xs={5} sm={6} md={2} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        label="Start Date"
                                        variant="inline"
                                        inputVariant="outlined"
                                        format={DateFormat}
                                        className={classes.textField}
                                        disableToolbar
                                        autoOk={true}
                                        margin="normal"
                                        id="DocUpLog_StartDate"
                                        value={StartDate}
                                        onChange={handleFirstDateChange}
                                        style={{ marginLeft: '-5px', }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={5} sm={6} md={2} style={{ marginLeft: '-15px' }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        label="End Date"
                                        disableToolbar
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        format={DateFormat}
                                        className={classes.textField}
                                        margin="normal"
                                        id="DocUpLog_EndDate"
                                        value={EndDate}
                                        onChange={handleEndDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} >
                            <Autocomplete
                                id="DocUpLog_SelectUser_DropDown"
                                autoComplete autoHighlight
                                options={activeUsers} onChange={onUsersSelect}
                                getOptionLabel={(option) => option.fullname}
                                style={{ marginTop: "-4px", width: '250px' }}
                                loading={usersLoading}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        <span style={option.isActive === true ? { color: "green" } : { color: "#FA8072" }}> {option.fullname}</span>
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Users" margin="normal" variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {usersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={5} sm={5} md={2} style={{ marginLeft: '-10px' }}>
                            <Autocomplete
                                id="DocUpLog_SelectClientCode_DropDown"
                                autoComplete autoHighlight
                                options={clientList} onChange={onClientsSelect}
                                getOptionLabel={(option) => option.client_code}
                                style={{ marginTop: "-4px", width: '250px', }}
                                loading={clientsLoading}
                                renderInput={(params) => (
                                    <TextField {...params} label="Clients" margin="normal" variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {clientsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }} />
                                )} />
                        </Grid>
                        <Grid item xs={5} sm={5} md={1} style={{ marginTop: '15px', marginLeft: '-10px' }}>
                            <FormControl variant="outlined">
                                <InputLabel id="demo-simple-select-label" variant="standard" style={{ marginLeft: "13px", marginTop: "-5px" }}> User Type</InputLabel>
                                <Select native label="User Type"
                                    /* labelId="demo-simple-select-label" */
                                    value={PortalUser.role} onChange={handleNotificationTypeChange}
                                    style={{ marginTop: "-4px", width: '230px' }}
                                    inputProps={{
                                        name: 'role',
                                        id: 'DocUpLog_SelectRole_DropDown',
                                    }}>
                                    <option value={10}>--Select User Type--</option>
                                    <option value={0}>Regular User</option>
                                    <option value={1}>Client Admin</option>
                                    <option value={2}>Global Admin</option>
                                    <option value={3}>Super Admin</option>
                                    <option value={4}>File Admin</option>
                                </Select>
                            </FormControl>

                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={5} sm={6} md={3} style={{ marginLeft: '30px' }}>
                                <Button id="DocUpLog_Filter_Button" className={classes.buttons} startIcon={<FilterAltIcon />} style={{ marginLeft: "10px", marginTop: "8px" }} onClick={() => { HandleFilter(); }} variant="contained" color="primary" disabled={StartDate > EndDate} >
                                    Filter
                                </Button>
                                <Button id="UpLog_Filter_Button" className={classes.buttons} startIcon={<img src={clearIcon} alt="Icon for clear filter button" />} style={{ marginLeft: "10px", marginTop: "8px" }}
                                    onClick={() => { handleClearFilter(); }} variant="contained" color="primary" >
                                    Clear Filter
                                </Button>
                                <LightTooltip title="Download Log Files">
                                    <IconButton id="DocUpLog_DownloadCSV_Button" aria-label="delete" disabled={isValid} style={{ marginLeft: '10px', marginTop: '8px' }}>
                                        <CSVLink style={{ marginLeft: '0px' }}
                                            data={csvData}
                                            filename={"DocumentLogFiles.csv"}
                                            className="btn btn-primary"
                                            disabled={isValid}
                                            target="_blank">
                                            <CloudDownloadIcon style={isValid ? { color: "#a1a1a1" } : { color: "black" }} fontSize="large" />
                                        </CSVLink>
                                    </IconButton>
                                </LightTooltip>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item xs={5} sm={6} md={2} alignContent='flex-start'>
                                <IconButton id="DocUpLog_Filter_IconButton" aria-label="Filter" onClick={HandleFilter} style={{ color: 'blue' }} disabled={StartDate > EndDate} >
                                    <FilterListIcon style={{ fontSize: 35 }} />
                                </IconButton>
                                <IconButton id="UpLog_Filter_IconButton" aria-label="Filter" onClick={() => { setClearFilter(true); }} style={{ color: 'blue' }} >
                                    <ClearRoundedIcon style={{ fontSize: 35 }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={5} sm={6} md={1} alignContent='flex-end'>
                                <IconButton id="DocUpLog_DownloadCSV_IconButton_MB" aria-label="CSVDownload" disabled={isValid} onClick={() => { setIsValid(true); }} >
                                    <CSVLink
                                        data={ListLog}
                                        filename={"DocumentLogFiles.csv"}
                                        className="btn btn-primary"
                                        disabled={isValid}
                                        target="_blank">
                                        <CloudDownloadIcon style={{ color: "black" }} fontSize="large" />
                                    </CSVLink>
                                </IconButton>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Paper>
                            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                                <Table id="DocUpLogData" aria-label="customized table" size="small" stickyHeader>
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort} />
                                    <TableBody>
                                        {stableSort(ListLog, getComparator(order, orderBy)).map(
                                            (row) => {
                                                return (
                                                    <StyledTableRow>
                                                        <StyledTableCell component="th" scope="row">
                                                            {row.fullusername}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {(row.global_admin === 0) ? "Regular User" : (row.global_admin === 1) ? "Client Admin" : (row.global_admin === 2) ? "Global Admin" : (row.global_admin === 3) ? "Super Admin" : "File Admin"}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {row.clientId}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {row.rid}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {moment(row.upload_date).format(state.GlobalUtils?.settingValue)}
                                                        </StyledTableCell>

                                                        <StyledTableCell>
                                                            {moment(row.upload_date).format('HH:mm:ss')}
                                                        </StyledTableCell>

                                                        <DownloadDocumentLogFile row={row} />

                                                        <StyledTableCell>
                                                            <DocumentPDFViewer row={row} enable={row.file_name.split('.').pop() === 'pdf' ? true : row.file_name.split('.').pop() === 'PDF' ? true : false} />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {mapped === true ?
                                    < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                        No records to display..
                                    </Typography>
                                    : null}
                            </TableContainer>
                            <TablePagination
                                id="DocUpLog_Pagination"
                                rowsPerPageOptions={[10, 15, 25]}
                                component="div"
                                count={totalRecords === undefined ? 0 : totalRecords}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <Footer />
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    )
}

export default DocumentUploadlog
