import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { IDebtor } from '../../models/Roll/IDebtor';
import { Collapse } from '@material-ui/core';
import DocumentUpload from './DocumentUpload';
import CloseAndRemove from "./CloseAndRemove";
import CloseAccount from "./CloseAccount";;
import Submit from "./Submit";
import ReplayIcon from "@material-ui/icons/Replay";
import { useDelete, useFetch, usePost } from '../../utils/apiHelper';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { IPlacedAccount } from '../../models/Inquiry/IPlacedAccount';
import { IRollPlacement } from '../../models/Inquiry/IRollPlacement';
import { ILetterDetails } from '../../models/Inquiry/LetterDetails/ILetterDetails';
import CurrencyInput from 'react-currency-input-field';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { IGetRolledPlacement } from '../../models/Inquiry/IGetRolledPlacement';
import { IPlacedAccountContract } from '../../models/Inquiry/IPlacedAccountContract';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import moment from 'moment';
import * as constant from '../../constants/Constant'
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(2),
            },
        },
        rootHidden: {
            display: "none"
        },
        button: {
            paddingBottom: "10px",
            paddingTop: "10px",
            width: 155,
            borderRadius: 10,
            marginTop: "-40px",
            fontSize: 13,
            fontWeight: 'bold'
        },
        div: {
            flexGrow: 1
        },
        typographystyle: {
            marginTop: "-45px",
            textAlign: 'right',
            marginRight: '32px',
            color: '#800080',
        },
        typographystyle1: {
            marginTop: "-45px",
            textAlign: 'right',
            marginRight: '32px',
            color: '#800080',
        },
        inputField: {
            width: '90%',
            height: '56px',
            fontSize: 20,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginTop: '5px',
            marginBottom: '10px',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold'
        },
        inputField1: {
            width: '90%',
            height: '56px',
            fontSize: 20,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginBottom: '14px',
            textDecoration: 'bold',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold'
        },
        inputField2: {
            width: '90%',
            height: '56px',
            fontSize: 20,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginTop: '5px',
            marginBottom: '10px',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold'
        },
        inputField3: {
            width: '90%',
            height: '56px',
            fontSize: 20,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginBottom: '14px',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold'
        },
        currentamountStyle: {
            marginTop: "-45px",
            textAlign: 'right',
            color: '#800080',
            marginRight: '30px',
        },
        currentamountStyle1: {
            textAlign: 'right',
            marginTop: "15px",
            marginRight: '30px',
        },
        currentamountStyle2: {
            textAlign: 'right',
            marginTop: "26px",
            marginLeft: "20px",
            marginRight: '30px',
        },
        currentamountStyle3: {
            color: 'green',
            textAlign: 'right',
            marginTop: "20px",
            marginRight: '30px',
        },
        textStyle: {
            marginTop: "8px",
            marginRight: '-60px',
        },
        textStyle1: {
            marginTop: "23px",
            marginRight: '-60px',
        },
        textStyle2: {
            marginTop: "23px",
            marginRight: '-60px',
        },
        updateamountstyle: {
            marginTop: "-45px",
            textAlign: 'left',
            marginLeft: '15px',
            textDecoration: 'bold',
            color: '#800080'
        },
        updateamountstyle1: {
            textAlign: 'right',
            marginRight: '60px',
            marginTop: "15px",
        },
        updateamountstyle2: {
            textAlign: 'right',
            marginRight: '60px',
            marginTop: "25px",
        },
        updateamountstyle3: {
            color: 'green',
            textAlign: 'right',
            marginRight: '60px',
            marginTop: "25px",
        },
        yesButton: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            bottom: '0.1cm',
            right: '15px',
            '&:hover': {
                background: "green",
            }
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            right: '10px',
            bottom: '0.1cm',
            '&:hover': {
                background: "red",
            }
        },
        typographyStyle: {
            marginTop: '-15px',
            color: 'green'
        },
        typographyStyle1: {
            marginTop: '-5px',
            height: "10px"
        },
        typographyStyle2: {
            marginTop: '15px',
            height: "10px"
        },
        typographyStyle3: {
            color: 'green',
            marginTop: '8px'
        },
        typographyStyle4: {
            marginTop: '-5px',
            height: "10px"
        },
        typographyStyle5: {
            marginTop: '-30px',
            fontSize: '20px'
        },
    }),
)

const AccountDetails: React.FC<{ debtor: IDebtor, onUndo: () => void, onRollRemove: () => void, onUndoCloseRemove: () => void, onEroll: () => void, onDelete: () => void, letterDetails: IClosedLetterDetails, isEnable: IGetRolledPlacement, onHideAll: () => void, BlockAwaitUsers: boolean }> = (props) => {
    const { debtor, onRollRemove, onUndoCloseRemove, BlockAwaitUsers } = props
    const theme = useTheme();
    const classes = useStyles()
    const falseObject = {
        "DocUpload": false,
        "CloseRemove": false,
        "CloseAcc": false,
        "SubmitValue": false
    }
    const [object, setObject] = useState(falseObject);
    const [clicked, setClicked] = useState(false);
    const { state, dispatch } = useGlobalState()
    const [showUndoButton, setshowUndoButton] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [inputVal, setInputVal] = useState<any>('');
    const [principalPaymentAmount, setPrincipalPaymentAmount] = useState<any>('');
    const [principalAdjustmentAmount, setPrincipalAdjustmentAmount] = useState<any>('');
    const [latePaymentAmount, setLatePaymentAmount] = useState<any>('');
    const [lateAdjustmentAmount, setLateAdjustmentAmount] = useState<any>('');
    const [updatedPrincipalAmount, setUpdatedPrincipalAmount] = useState(props.debtor.dbT_PRINCIPAL_DUE);
    const [updatedLateAmount, setUpdatedLateAmount] = useState(props.debtor.dbT_MISC1_DUE);
    const [updatedTotalAmount, setUpdatedTotalAmount] = useState(updatedPrincipalAmount + updatedLateAmount);
    const [placedAccounts, setPlacedAccounts] = useState<IPlacedAccount[]>([]);
    const [callGetPlacement, setCallGetPlacement] = useState(true);
    const [principalPaymentDue, setPrincipalPaymentDue] = useState<number>(props.debtor.dbT_PRINCIPAL_DUE);
    const [LateFeeDue, setLateFeeDue] = useState<number>(props.debtor.dbT_MISC1_DUE);
    const [EarlyRollRolled, setEarlyRollRolled] = useState(props.debtor.rolled);
    const [letterDetails, setLetterDetails] = useState<ILetterDetails[]>([]);
    const canUpdatePayment = state.userAccessContext?.paymentUpdates;
    const canRollAccount = state.userAccessContext?.rollAccounts;
    const UserName = state.userAccessContext?.id
    const [disable, setDisable] = useState(false);
    const [ERoll, setERoll] = useState(false);
    const [paymentSubmit, setPaymentSubmit] = useState(false);
    const [ERollUndo, setERollUndo] = useState(false);
    const [service, setService] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const TotalBalance = principalPaymentDue + LateFeeDue;
    const DBO = debtor?.deM_DOB === null ? "" : debtor.deM_DOB;
    const canMakePayment = state.userAccessContext?.paymentUpdates;
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [checkFullAmount, setCheckFullAmount] = useState(false);
    const [ConfirmDialog, setConfirmDialog] = useState(false);
    const [paymentVal, setPaymentVal] = useState(false);
    const phoneNumber = debtor.deM_PHONE1;
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleConfirmDialogCLose = () => {
        setConfirmDialog(false);
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
    }

    const handleCheckFullAmountDialog = () => {
        setPaymentVal(true);
        setCheckFullAmount(false);
    }

    function recursiveRemove(inputAmt: string, target: number) {
        if (inputAmt == undefined) return "";
        return parseFloat(inputAmt) > target ?
            recursiveRemove(inputAmt = inputAmt.slice(0, -1), target) :
            inputAmt.toString();
    }

    const handleOnValueChange = (value: string): void => {
        setDisable(true);
        setPrincipalPaymentAmount(recursiveRemove(value, debtor.dbT_PRINCIPAL_DUE));
        setInputVal(Number(value) > 0 && parseFloat(value) <= debtor.dbT_PRINCIPAL_DUE ? value : principalAdjustmentAmount || lateAdjustmentAmount || latePaymentAmount);
    };

    const handleFlatfeeValueChange = (value: string): void => {
        setDisable(true);
        setLatePaymentAmount(recursiveRemove(value, debtor.dbT_MISC1_DUE));
        setInputVal(Number(value) > 0 ? value : lateAdjustmentAmount || principalAdjustmentAmount || principalPaymentAmount);
    };

    const PricipalAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setPrincipalAdjustmentAmount(value === '' ? '' : value || '');
        setInputVal(value === '' ? principalPaymentAmount : value || principalPaymentAmount || latePaymentAmount);
    };

    const LateAdjValueChange = (value: string | undefined): void => {
        setDisable(true);
        setLateAdjustmentAmount(value === '' ? '' : value || '');
        setInputVal(value === '' ? latePaymentAmount : value || latePaymentAmount || principalPaymentAmount);
    };

    useEffect(() => {
        let principal_amount_due = debtor.dbT_PRINCIPAL_DUE;
        for (let account of placedAccounts) {
            if (account.prcpl_Adj_Amt && account.prpcl_Pay_Amt === 0) {
                principal_amount_due = ((principal_amount_due) + (account.prpcl_Pay_Amt + account.prcpl_Adj_Amt));
            }
            else if (account.prcpl_Adj_Amt > 0 && account.prpcl_Pay_Amt > 0) {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
            else {
                principal_amount_due = ((principal_amount_due) - (account.prpcl_Pay_Amt - account.prcpl_Adj_Amt));
            }
        }
        let late_fee_due = debtor.dbT_MISC1_DUE;
        for (let account of placedAccounts) {
            if (account.fee_Adj_Amt && account.fee_Pay_Amt === 0) {
                late_fee_due = ((late_fee_due) + (account.fee_Pay_Amt + account.fee_Adj_Amt));
            }
            else if (account.fee_Adj_Amt > 0 && account.fee_Pay_Amt > 0) {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
            else {
                late_fee_due = ((late_fee_due) - (account.fee_Pay_Amt - account.fee_Adj_Amt));
            }
        }
        setPrincipalPaymentDue(Number(principal_amount_due.toFixed(2)));
        setLateFeeDue(Number(late_fee_due.toFixed(2)));
    }, [placedAccounts])

    useEffect(() => {
        setUpdatedPrincipalAmount((Number((principalPaymentDue - principalPaymentAmount) + Number(principalAdjustmentAmount))));
        setUpdatedLateAmount((Number((LateFeeDue - latePaymentAmount) + Number(lateAdjustmentAmount))));
    }, [LateFeeDue, principalPaymentDue, principalPaymentAmount, principalAdjustmentAmount, latePaymentAmount, lateAdjustmentAmount])

    useEffect(() => {
        setUpdatedTotalAmount(Number((updatedPrincipalAmount + updatedLateAmount).toFixed(2)));
        (updatedPrincipalAmount < 0 || updatedLateAmount < 0) ? setIsValid(false) : setIsValid(true);
    }, [updatedPrincipalAmount, updatedLateAmount])

    function emptyCheck() {
        setInputVal("");
        setPrincipalPaymentAmount('');
        setLatePaymentAmount('');
        setPrincipalAdjustmentAmount('');
        setLateAdjustmentAmount('');
    }

    async function CheckPreviousPayment(dbt_NO: string) {
        dispatch({ type: GlobalStateAction.Busy })
        setButtonLoading(true);
        try {
            // Check if the full account balance is adjusted to zero without payment.
            if (updatedTotalAmount === 0.00 && ((principalPaymentAmount === "0.00" || principalPaymentAmount === "") && (latePaymentAmount === "" || latePaymentAmount === "0.00"))) {
                setCheckFullAmount(true);
            } else {
                setCheckFullAmount(false);
                // Check if payment matches previous pending payment.
                const response = await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${dbt_NO}`);
                if (response.data.length === 0 ? true : false) {
                    submitPayment();
                } else {
                    setConfirmDialog(true);
                }
            }
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    const submitPayment = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                if (btnDisabled === false) {
                    let request = {
                        "dbt_No": props.debtor.dbT_NO,
                        "prcpl_Pay_Amt": principalPaymentAmount === "" ? 0.00 : parseFloat(principalPaymentAmount),
                        "prcpl_Adjst_Amt": principalAdjustmentAmount === "" ? 0.00 : parseFloat(principalAdjustmentAmount),
                        "fee_Pay_Amt": latePaymentAmount === "" ? 0.00 : parseFloat(latePaymentAmount),
                        "fee_Adjst_Amt": lateAdjustmentAmount === "" ? 0.00 : parseFloat(lateAdjustmentAmount),
                        "user_Id": UserName
                    };
                    let loadHistory = {
                        "updatedBy": UserName,
                        "dbt_No": props.debtor.dbT_NO,
                        "operationType": 1,//Submit Payment
                        "transactionText": `"Payment to Principal:" ${principalPaymentAmount}, "Payment to Late Fee:" ${latePaymentAmount}, "Adjustment to Principal:" ${principalAdjustmentAmount}, "Adjustment to Late Fee:" ${lateAdjustmentAmount} `
                    };
                    const response = await usePost<any>('Account/SubmitPayment', request);
                    hidenotificationForAllUsers();
                    GetPayment();
                    const TransHistory = await usePost("Log/TransactionHistoy", loadHistory)
                    setDisable(true);
                    setButtonLoading(false);
                    setPaymentSubmit(true);
                    setPaymentVal(false);
                }
                else {
                    let loadHistory = {
                        "updatedBy": UserName,
                        "dbt_No": debtor.dbT_NO,
                        "operationType": 2,//No Payment Made
                        "transactionText": 'No Payment Received'
                    };
                    const TransHistory = await usePost("Log/TransactionHistoy", loadHistory)

                    let request = {
                        "dbtNumber": debtor.dbT_NO,
                        "close_acct_note": null,
                        "close_acct_bal": null,
                        "close_acct_reason": "No Payment Received",
                        "user_Id": UserName,
                    };
                    const response = await usePost<IPlacedAccountContract[]>('Account/ClosedPlacedAccounts', request);

                    let nopaymentMadeRequest = {
                        "username": state.userAccessContext?.userName,
                        "dbtNo": debtor.dbT_NO
                    }
                    // await usePost("SendNopaymentMade", nopaymentMadeRequest);
                    const nopayment = await axios.post(`${constant.NoPaymentMade}/${debtor.dbT_NO}?`);
                    hidenotificationForAllUsers();
                    setBtnDisabled(false);
                    emptyCheck();
                    setPaymentVal(false);
                }
                props.onHideAll();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                emptyCheck();
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": debtor.dbT_NO,
                    "typeId": 5
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const EarlyRoll = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = {
                    "dbt_No": props.debtor.dbT_NO,
                    "dbt_Client": props.debtor.dbT_CLIENT,
                    "user_Id": UserName
                };
                let loadHistory = {
                    "updatedBy": UserName,
                    "dbt_No": props.debtor.dbT_NO,
                    "operationType": 5,//Early Rolled
                    "transactionText": "Early Roll"
                };
                const tobeEarlyroll = await usePost<IRollPlacement[]>('RolledPlacement', request)
                setEarlyRollRolled(true);
                props.onEroll();
                setshowUndoButton(true);
                const TransHistory = await usePost("Log/TransactionHistoy", loadHistory)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const undoRoll = () => {
        (async () => {
            try {
                dispatch({ type: GlobalStateAction.Busy })
                const response = await useDelete('RolledPlacement?DBTNumber=' + props.debtor.dbT_NO).then((r) => {
                    if (r.status == 400) {
                        dispatch({ type: GlobalStateAction.Error, error: r.statusText })
                    }
                });
                setEarlyRollRolled(false);
                props.onDelete();
                props.onUndoCloseRemove();
                setshowUndoButton(false);

                let loadHistory = {
                    "updatedBy": UserName,
                    "dbt_No": props.debtor.dbT_NO,
                    "operationType": 4,//Undone Rolled account
                    "transactionText": "Manually rolled debtor undone"
                };
                const TransHistory = await usePost("Log/TransactionHistoy", loadHistory)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${props.debtor.dbT_NO}`);
                setPlacedAccounts(response.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                setCallGetPlacement(false);
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()

    }, [callGetPlacement])

    const GetPayment = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                const response = await useFetch<IPlacedAccount[]>(`Account/PlacedAccounts?DBTNumber=${props.debtor.dbT_NO}`)
                setPlacedAccounts(response.data);
                setCallGetPlacement(false)
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                let request = { "debtorId": props.debtor.dbT_NO };
                const response = await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request)
                const data = response.data.letterDetails.map((a) => {
                    return { ...a, clT_NAME_1: a.clT_NAME_1 }
                })
                setLetterDetails(data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }, [])

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setERoll(false);
        setPaymentSubmit(false);
        setERollUndo(false);
    };

    useEffect(() => {
        if (debtor.dbT_COMPANY === constant.CONT) {
            setService('Contingency');
        }
        if (debtor.dbT_COMPANY === constant.PREC) {
            setService('Flat Fee');
        }
        else if (debtor.dbT_COMPANY === constant.FIRSTSTP) {
            setService('1st Party');
        }
    }, [service])

    const hideDocNotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": 0,
                    "userid": UserName,
                    "debtorid": debtor.dbT_NO,
                    "typeId": 6
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }


    const handleCheckboxChange = (event) => {
        setBtnDisabled(event.target.checked)
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
            e.preventDefault();
        }
    };

    return (
        <div className={classes.div}>
            <Grid container className={classes.root}>
                <Grid item xs={'auto'} direction='column' >
                    <Typography variant="h6" gutterBottom className={classes.textStyle}><b>Principal</b></Typography>
                    <Typography variant="h6" gutterBottom className={classes.textStyle1}><b>Late Fee</b></Typography>
                    <Typography variant="h6" gutterBottom className={classes.textStyle2}><b>Total</b></Typography>
                </Grid>
                <Grid item xs={2} style={{ flex: 1 }} direction='column' >
                    <Typography variant="h6" gutterBottom className={classes.currentamountStyle}><b>Current Amount Due</b></Typography>
                    <Typography variant="h5" gutterBottom className={classes.currentamountStyle1}><b>${principalPaymentDue.toFixed(2)}</b></Typography>
                    <Typography variant="h5" gutterBottom className={classes.currentamountStyle2}><b>${LateFeeDue.toFixed(2)}</b></Typography>
                    <Typography variant="h5" gutterBottom className={classes.currentamountStyle3}><b>${TotalBalance.toFixed(2)}</b></Typography>
                </Grid>
                <Grid item xs={3} style={{ flex: 1 }} direction='column'>
                    <Typography variant="h6" gutterBottom className={classes.typographystyle}>
                        <b>Payment Amount</b>
                    </Typography>
                    <CurrencyInput
                        className={classes.inputField}
                        id="Roll_PrincipalPaymentAmount"
                        placeholder="$ Payment Amount"
                        value={principalPaymentAmount}
                        allowNegativeValue={false}
                        prefix='$'
                        decimalsLimit={2}
                        onValueChange={handleOnValueChange}
                        disabled={BlockAwaitUsers || !canMakePayment}
                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                        onKeyPress={preventMinus}
                        decimalScale={2}
                        maxLength={13}
                    />
                    <CurrencyInput
                        className={classes.inputField1}
                        id="Roll_latePaymentAmount"
                        value={latePaymentAmount}
                        allowNegativeValue={false}
                        placeholder="$ late fee Payment mount"
                        prefix='$'
                        decimalsLimit={2}
                        onValueChange={handleFlatfeeValueChange}
                        disabled={BlockAwaitUsers || !canMakePayment}
                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                        onKeyPress={preventMinus}
                        decimalScale={2}
                        maxLength={13}
                    />
                </Grid>
                <Grid item xs={3} style={{ flex: 1 }} direction='column'>
                    <Typography variant="h6" gutterBottom className={classes.typographystyle1}>
                        <b>Adjustment Amount</b>
                    </Typography>
                    <CurrencyInput
                        className={classes.inputField2}
                        id="Roll_principalAdjustmentAmount"
                        value={principalAdjustmentAmount}
                        placeholder="$ Adjustment Amount"
                        prefix='$'
                        decimalsLimit={2}
                        onValueChange={PricipalAdjValueChange}
                        disabled={BlockAwaitUsers || !canMakePayment}
                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                        decimalScale={2}
                        maxLength={13}
                    />
                    <CurrencyInput
                        className={classes.inputField3}
                        id="Roll_lateAdjustmentAmount"
                        value={lateAdjustmentAmount}
                        placeholder="$ late fee Adjustment Amount"
                        prefix='$'
                        decimalsLimit={2}
                        onValueChange={LateAdjValueChange}
                        disabled={BlockAwaitUsers || !canMakePayment}
                        intlConfig={{ locale: 'en-US', currency: 'USD' }}
                        decimalScale={2}
                        maxLength={13}
                    />
                </Grid>
                <Grid item xs={2} style={{ flex: 1 }} direction='column' >
                    <Typography variant="h6" gutterBottom className={classes.updateamountstyle}><b>Updated Amount Due</b></Typography>
                    <Typography variant="h5" gutterBottom className={classes.updateamountstyle1}><b> ${updatedPrincipalAmount.toFixed(2)}</b></Typography>
                    <Typography variant="h5" gutterBottom className={classes.updateamountstyle2}><b>${updatedLateAmount.toFixed(2)}</b></Typography>
                    <Typography variant="h5" gutterBottom className={classes.updateamountstyle3}><b>${updatedTotalAmount.toFixed(2)}</b></Typography>
                </Grid>
                <Grid item xs={10} sm={2} style={{ flex: 1 }} direction='column'>
                    <React.Fragment>
                        {debtor.dbT_Status === constant.AwaitingPayment338 || debtor.dbT_Status === constant.AwaitingPayment324 ?
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={btnDisabled}
                                        onChange={handleCheckboxChange}
                                        name="checkedB"
                                        color="primary"
                                        disabled={!state.userAccessContext?.paymentUpdates}
                                    />
                                }
                                label="No Payment Made"
                            />
                            :
                            null
                        }
                        <Typography component="h4" gutterBottom className={classes.typographyStyle5}><b>Phone Number : {phoneNumber}</b></Typography>
                        <Typography variant="h4" gutterBottom className={classes.typographyStyle3}>{service}</Typography>
                        <Typography variant="h6" gutterBottom className={classes.typographyStyle}>{props.debtor.stS_DESC.replace('|', " ")}</Typography>
                        {DBO === undefined ? null : DBO.length > 0 ? <Typography variant="subtitle1" align="left" gutterBottom className={classes.typographyStyle1}><b>DOB: {moment(DBO).format(state.GlobalUtils?.settingValue)}</b></Typography> : null}
                        <Typography variant="subtitle1" gutterBottom className={classes.typographyStyle4}><b>Referral Date: {moment(props.debtor.dbT_REFERRAL_DATE).format(state.GlobalUtils?.settingValue)}</b></Typography>
                        <Typography variant="subtitle1" gutterBottom className={classes.typographyStyle2}><b>Referral Amount: ${props.debtor.dbT_REFERRAL_AMT.toFixed(2)}</b></Typography>
                    </React.Fragment>
                </Grid>
                <Grid container style={{ marginBottom: "-50px" }}>
                    <Grid item xs={6} sm={2}>
                        <Button id="Roll_submit" type="submit" variant="contained" color="primary" disabled={!disable || !canUpdatePayment || !isValid || props.debtor.removed || !inputVal || BlockAwaitUsers}
                            startIcon={<CheckCircleIcon />}
                            onClick={(_) => { setObject({ ...falseObject, "SubmitValue": !object.SubmitValue }); CheckPreviousPayment(debtor.dbT_NO); }}
                            className={classes.button}
                            style={{ background: "#009900", marginLeft: "-2px" }}>
                            Submit
                            {buttonLoading === true ? <CircularProgress style={{ color: 'white', height: "25px", width: "25px", marginLeft: '20px' }} /> : null}
                        </Button>
                        <Submit clientId={props.debtor.dbT_CLNT_ACNT1} dbtNo={props.debtor.dbT_NO} placedAccounts={placedAccounts} callGetPlacement={() => setCallGetPlacement(true)} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} />
                        <Collapse in={object.SubmitValue} timeout="auto">
                        </Collapse>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <CloseAccount dbtNumber={props.debtor.dbT_NO} closed={props.debtor.closed} rolled={props.debtor.rolled} removed={props.debtor.removed} onUndoCloseRemove={onUndoCloseRemove} clientId={props.debtor.dbT_CLIENT} dbT_PRINCIPAL_DUE={props.debtor.dbT_PRINCIPAL_DUE} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Button id="Roll_EarlyRoll" variant="contained" color="primary"
                            startIcon={<ReplayIcon />}
                            className={classes.button}
                            onClick={() => { setClicked(!clicked); setshowUndoButton(!showUndoButton); EarlyRoll(); setERoll(true); }}
                            disabled={!canRollAccount || EarlyRollRolled || props.debtor.removed || props.debtor.rolled}
                            style={{ marginLeft: "-140px", color: "white" }}>
                            {EarlyRollRolled || props.debtor.rolled ? "Rolled" : "Early Roll"}
                        </Button>
                        {(EarlyRollRolled || props.debtor.rolled) && (
                            <IconButton color="primary" aria-label="add to shopping cart"
                                onClick={() => {
                                    setClicked(!clicked);
                                    undoRoll();
                                    setEarlyRollRolled(false);
                                }}
                                style={{ marginTop: "-1cm" }}>
                                <ReplayIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <CloseAndRemove dbtNumber={props.debtor.dbT_NO} clientId={props.debtor.dbT_CLIENT} removed={props.debtor.removed} rolled={props.debtor.rolled} onRollRemove={onRollRemove} onUndoCloseRemove={onUndoCloseRemove} dbT_PRINCIPAL_DUE={props.debtor.dbT_PRINCIPAL_DUE} onSubmit={GetPayment} BlockAwaitAccess={BlockAwaitUsers} />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <DocumentUpload clientId={props.debtor.dbT_CLIENT} letterDetails={props.letterDetails} agencyId={props.debtor.dbT_NO} accountId={props.debtor.dbT_CLNT_ACNT1} onHideNotification={hideDocNotificationForAllUsers} BlockAwaitAccess={BlockAwaitUsers} />
                    </Grid>
                </Grid>
                <span style={{ marginTop: "45px", marginLeft: "-2px", marginBottom: "-35px" }}>
                    {!isValid ?
                        <Typography variant="h5" style={{ color: "red" }} display="block" gutterBottom>
                            Please enter a payment amount that is less than the amount due.
                        </Typography> : null}
                    {paymentVal ?
                        <Typography variant="h6" style={{ color: "red" }} display="block" gutterBottom>
                            Please enter any payment amounts in the payment boxes.
                        </Typography> : null}
                </span>
            </Grid>

            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'md'}
                fullScreen={fullScreen}
                open={ConfirmDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleConfirmDialogCLose}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" style={{ marginTop: "10px", color: "black" }} gutterBottom>
                            One or more of your payments/adjustments matches the value of a pending update.  <br />
                            Are you sure you want to add this additional update to the account?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" variant="contained" onClick={() => { setConfirmDialog(false); submitPayment(); }} className={classes.yesButton} color="primary" autoFocus>
                        Ok
                    </Button>
                    <Button id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.noButton} onClick={handleConfirmDialogCLose} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'md'}
                fullScreen={fullScreen}
                open={checkFullAmount}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCheckFullAmountDialog}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
                        PAYMENT CONFIRMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h5" style={{ marginTop: "10px", color: "black" }} gutterBottom>
                            The account balance is being changed to $0.00.  Was a payment made?<br /><br />
                            If Yes, click OK.<br />
                            If No, Cancel.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" variant="contained" onClick={handleCheckFullAmountDialog} className={classes.yesButton} color="primary" autoFocus>
                        OK
                    </Button>
                    <Button id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.noButton} onClick={() => { setCheckFullAmount(false); submitPayment(); }} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={paymentSubmit} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
                    Payment Submitted Successfully.
                </Alert>
            </Snackbar>
            <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={ERoll} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" style={{ fontSize: 16 }}>
                    Account has been Rolled successfully Kindly Look on the first page.
                </Alert>
            </Snackbar>
            <Snackbar style={{ color: 'black', marginTop: '-0.5cm' }} open={ERollUndo} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" style={{ fontSize: 16 }}>
                    Account has been undone successfully.
                </Alert>
            </Snackbar>
        </div>
    )
}
export default AccountDetails