import { Box, IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArchiveIcon from '@material-ui/icons/Archive';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import React, { useState } from 'react';
import { IHideForAllUsers } from '../../../models/NotificationCenter/IHideForAllUsers';
import { INotificationCenter } from "../../../models/NotificationCenter/INotificationCenter";
import { IDocumentDownloadFileResult } from '../../../models/UploadLog/IDocumentDownloadFileResult';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import LightTooltip from '../../GlobalStyles/LightTooltip';

const useStyles = makeStyles((theme) => ({
    topRightBox: {
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    box: {
        display: "flex",
    },
    button: {
        borderRadius: '20px'
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
}))

const AckFileNotification: React.FC<{ AckInfo: INotificationCenter, onRefresh: () => void }> = (props) => {
    const { AckInfo } = props
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const hidenotificationForAllUsers = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "usernotificationid": AckInfo.userNotificationId,
                    "userid": UserName,
                    "debtorid": AckInfo.extraData_1,
                    "typeId": 7
                }
                const responseFile = await usePost<IHideForAllUsers>("HideForAllUsers", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    function getDownloadFileAsync() {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const responseDownloadFile = await useFetch<IDocumentDownloadFileResult>(`ReportFileDownload?fileId=${AckInfo?.fileID}`);
                const arrayBuffer = base64ToArrayBuffer(responseDownloadFile.data.fileContent);
                createAndDownloadBlobFile(arrayBuffer, responseDownloadFile.data.fileName);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }


    const handleArchieve = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "notificationId": AckInfo.notificationid,
                    "userid": UserName
                }
                await usePost<any>("ArchivePaymentFile", request);
                hidenotificationForAllUsers();
                props.onRefresh();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <Box display="flex" p={1} bgcolor="white">
                <Box p={1} width="80%" bgcolor="white" >
                    <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                        {moment(AckInfo.createdDate).format(state.GlobalUtils?.settingValue)} : Acknowledgement File
                    </Typography>
                    <Typography style={{ textAlign: "left" }} variant="subtitle2" gutterBottom>
                        {AckInfo.extraData_2}
                    </Typography>
                    {AckInfo.hiddenFlag ? null :
                        <Typography variant="subtitle2" style={{ textAlign: "left", color: 'red' }} gutterBottom>
                            This file will automatically archived in 30 days.
                        </Typography>
                    }
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" style={{ marginTop: '30px' }}>
                    <IconButton color="primary" component="span" className={`${classes.topRightBox} ${classes.box} ${classes.button}`} onClick={getDownloadFileAsync}>
                        <LightTooltip title="Download">
                            <CloudDownloadIcon fontSize='large' />
                        </LightTooltip>
                    </IconButton>
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" style={{ marginTop: '30px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={`${classes.topRightBox} ${classes.box} ${classes.button}`}
                        startIcon={<ArchiveIcon />}
                        onClick={handleClickOpen}
                        disabled={AckInfo.hiddenFlag}
                    >
                        Archive Now
                    </Button>
                </Box>
            </Box>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ style: { borderRadius: 15 } }}
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" className={classes.titleheader}>
                        ACKNOWLEDGEMENT FILE NOTIFICATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ marginTop: '-25px' }}>
                    <Typography variant="h5" style={{ color: "black" }} gutterBottom>
                        Are you sure, you want to Archive this File ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button id="ACK_Cancel_Btn" variant="contained" autoFocus style={{ background: "red", marginRight: "10px", borderRadius: 30 }} onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button id="ACK_ok_btn" variant="contained" onClick={(_) => { handleArchieve(); handleClose(); }} style={{ background: "green", marginRight: "10px", borderRadius: 30 }} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default AckFileNotification
