import React, { useEffect, useState } from 'react'
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, makeStyles, Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SendIcon from "@material-ui/icons/Send";
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import Card from '../../pages/Card/Card';
import CardHeader from '../../pages/Card/CardHeader';
import CardIcon from '../../pages/Card/CardIcon';
import CardBody from '../../pages/Card/CardBody';
import { IBlockBalance } from '../../models/dashboard/IBlockBalance';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    button: {
        paddingBottom: "10px",
        paddingTop: "10px",
        width: 165,
        borderRadius: 10,
        marginTop: "-40px"
    },
    root1: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1)
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(2),
        color: theme.palette.info[500],
    },
    description: {
        width: '100%',
        marginTop: "15px",
    },
    text: {
        marginLeft: "35%"
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    cards: {
        display: "absolute",
        flexDirection: "row",
        marginRight: "150px",
        marginLeft: "-200px",
        justifyContent: 'space-around',
    },
    card: {
        color: theme.palette.text.secondary,
        height: '6cm',
        boxShadow: '0px 1px 5px 1px rgba(103, 128, 159, 1)',
        '&:hover': {
            transform: 'scale(1.1)',
            cursor: 'pointer',
            border: '2px blue solid',
            boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
        },
    },
    fonts: {
        fontFamily: 'Segoe UI',
    },
    MessageTextAlign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    snackbar: {
        color: 'black',
        marginTop: '-0.5cm',
    },
    snackbarfont: {
        fontSize: 16,
    }
}))

const Beginorder = () => {
    const [open, setOpen] = React.useState(false);
    const { state, dispatch } = useGlobalState()
    const UserName = state.userAccessContext?.id;
    const [message, setMessage] = useState<string>("")
    const [inputVal, setinputVal] = useState('');
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef<number>();
    const classes = useStyles()
    const [BlockBalance, setBlockBalance] = useState<IBlockBalance[]>([]);
    const [IsTriggered, setIsTriggered] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                const BlockBalanceData = await useFetch<IBlockBalance[]>("Account/BlockBalanceByUser?userId=" + UserName);
                setBlockBalance(BlockBalanceData.data);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }, [])

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success
    });

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, [message]);

    const handleClose = () => {
        setOpen(false);
        setSuccess(false);
        setMessage("");
        setinputVal('');
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsTriggered(false);
    };

    const SendBeginReorderEmail = (username: string, body: string) => {
        (async () => {
            try {
                let request = {
                    "Username": username,
                    "Body": body
                }
                const responseSendEmail = await usePost<any>("Account/SendBeginReorderEmail", request);
                setIsTriggered(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    const handleSettlementOfferClick = (message) => {
        let msgBody = "";
        let clientName = state.user?.name;
        var Client = clientName.split("@")[0];
        let clientId = state.userAccessContext.clientCodes;
        msgBody += "<p>From:<br />Client Name: " + Client + "<br />Client ID: " + clientId + "</p><p>Message:<br />\"" + message + "\"</p><p>(to respond, you may reply directly to this email)</p>";
        msgBody += "</table><p>Thanks,<br/>" + Client + "</p>";
        SendBeginReorderEmail(clientName, msgBody);
        setOpen(false);
        handleClose();
        setShowMessage(true);
    }


    return (
        <React.Fragment>
            <Card className={classes.card} onClick={() => { setOpen(true); }} >
                <CardHeader color="success" stats={true} icon={true} style={{ padding: "10px", marginTop: "-1cm" }}>
                    <CardIcon color="success">
                        <IconButton id="Dashboard_BeginReorder" onClick={() => { setOpen(true); }} aria-label="Mail" style={{ color: "white" }} >
                            <MailOutlineIcon fontSize="small" />
                        </IconButton>
                    </CardIcon>
                </CardHeader>
                <CardBody style={{ marginLeft: "-1.80cm" }}>
                    <Typography variant="h2" gutterBottom style={{ color: "black", marginTop: "-1cm" }} >
                        <b>{BlockBalance.reduce((sum, curr) => sum + curr.balance, 0)}</b>
                    </Typography>
                    <Typography variant="h6" className={classes.fonts} style={{ color: "black", textAlign: "center" }}>
                        Flat Fee Account Remaining
                    </Typography>
                </CardBody>
            </Card>

            <Dialog open={open} keepMounted onClose={handleClose} maxWidth={'sm'}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogContent>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose} style={{ top: "-0.1cm" }}><CloseIcon /></IconButton>
                    <Grid item xs={12} >
                        <TextField
                            id="BeginReorder_MessageBox"
                            variant="outlined"
                            placeholder="Contact us"
                            size="medium"
                            multiline
                            rows={6}
                            value={message}
                            onChange={(e) => { setMessage(e.target.value); setinputVal(e.target.value); }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxlength: 512 }}
                            className={classes.description}
                        />
                    </Grid>
                    <Button
                        id="BeginReorder_SendNotifications"
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={!inputVal || loading}
                        onClick={() => { handleSettlementOfferClick(message); }}
                        style={{
                            marginLeft: "5cm", marginTop: "0.5cm", paddingBottom: "10px",

                            width: 165,
                            borderRadius: 10,
                        }}
                        endIcon={
                            <React.Fragment>
                                {success ? "" : <SendIcon />}
                            </React.Fragment>
                        }
                    >
                        Send Message
                    </Button>
                    <Typography variant="subtitle1" className={classes.text} gutterBottom>
                        Phone: (888) 772-6468
                    </Typography>

                </DialogContent>
            </Dialog>
            <Snackbar className={classes.snackbar} open={IsTriggered} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className={classes.snackbarfont}>
                    Message Sent Successfully!
                </Alert>
            </Snackbar>

            <Dialog
                PaperProps={{ style: { borderRadius: 15 } }}
                open={showMessage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogTitle id="responsive-dialog-title" className={classes.MessageTextAlign}>
                        {"Message"}
                    </DialogTitle>
                    <DialogContentText id="alert-dialog-description" className={classes.MessageTextAlign}>
                        <Typography variant="h6" style={{ marginTop: "15px", color: "black" }} gutterBottom>
                            Your message has been sent.
                        </Typography>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" className={classes.MessageTextAlign}>
                        <Typography variant="h6" style={{ marginTop: "15px", color: "black" }} gutterBottom>
                            Phone: (888) 772-6468
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="BR_Close" onClick={(_) => { setShowMessage(false); }} variant="contained" style={{ borderRadius: '20px', backgroundColor: "green" }} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment >
    )
}

export default Beginorder





