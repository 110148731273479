import {
    Box, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../components/Footer/Footer';
import CreateReport from "../components/Report/CreateReport";
import ExpandableRow from '../components/Report/ReportsList';
import VideoPlayer from '../components/YouTubePlayer/VideoPlayer';
import { IReportField } from '../models/Reports/IReportField';
import { IReportFieldsValues } from '../models/Reports/IReportFieldsValues';
import { IReportOperator } from '../models/Reports/IReportOperator';
import { IReportsList } from '../models/Reports/ReportsList';
import { IAdminGetClients } from '../models/UploadLog/AdminGetClients';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch } from '../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    box: {
        display: "flex",
        marginLeft: "-10px",
        marginTop: '-0.2cm'
    },
    box1: {
        display: "flex",
        marginTop: "23px",
        marginLeft: "100px"
    },
    grid: {
        padding: '10px'
    },
    div: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tableBody: {
        maxHeight: 625,
        marginTop: '-0.9cm',
    },
}))

const Reports = () => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [selected, setSelected] = React.useState<string>();
    const [ReportsList, setReportsList] = useState<IReportsList[]>([]);
    const [fieldsList, setFieldsList] = useState<IReportField[]>([]);
    const [OperatorsList, setOperatorsList] = useState<IReportOperator[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const UserName = state?.userAccessContext?.id;
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState(" ");
    const video = 'GEWp0isvFNg';
    const title = 'A.R.M. Webview: Using Reports';
    const [isValid, setIsValid] = useState(false);
    const [Clients, setClientsList] = useState([]);
    const [Phases, setPhases] = useState([]);
    const [Statuses, setStatuses] = useState([]);
    const [States, setStates] = useState([]);
    const [ProgressBar, setshowProgressBar] = useState(true);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy })
            try {
                if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
                    await useFetch<IAdminGetClients[]>("UploadLog/ClientCodeGetPlacement").then((AdminGetClients) => {
                        setClientsList(AdminGetClients.data.map(clients => { return { "id": clients.client_code, "name": clients.client_name + ` (${clients.client_code})` } }));
                    });
                }
                else if (state.userAccessContext.role === 1) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((AdminGetClients) => {
                        setClientsList(AdminGetClients.data.map(clients => { return { "id": clients.client_code, "name": clients.client_name + ` (${clients.client_code})` } }));
                    });
                }
                else if (state.userAccessContext.role === 0) {
                    await useFetch<IAdminGetClients[]>(`UploadLog/ClientGetPlacement?userId=${UserName}`).then((AdminGetClients) => {
                        setClientsList(AdminGetClients.data.map(clients => { return { "id": clients.client_code, "name": clients.client_name + ` (${clients.client_code})` } }));
                    });
                }

                await useFetch<IReportFieldsValues>("ReportOperatorsFieldsValues").then((reportField) => {
                    setStates(reportField.data.states.map(stateCode => { return { "id": stateCode.id, "name": stateCode.name + ` (${stateCode.id})` } }));
                    setPhases(reportField.data.phases.map(phase => { return { "id": phase.id, "name": phase.name + ` (${phase.id})` } }));
                    setStatuses(reportField.data.statuses.map(status => { return { "id": status.status_class_id, "name": status.status_class } }));
                    setOperatorsList(reportField.data.operators);
                    setFieldsList(reportField.data.fields);
                });
                setshowProgressBar(true);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
                setIsValid(true);
                setshowProgressBar(false);
            }
        })()
    }, [])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (accountId: string) => {
        setSelected(selected == accountId ? "" : accountId)
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const headCells = [
        { id: "name", numeric: false, disablePadding: false, label: "NAME", sortable: true },
        { id: "date_created", numeric: false, disablePadding: false, label: "DATE CREATED", sortable: true },
        { id: "action", numeric: false, disablePadding: false, label: "ACTION", sortable: false },
        { id: "share", numeric: false, disablePadding: false, label: "SHARE", sortable: false },
        { id: "remove", numeric: false, disablePadding: false, label: "REMOVE", sortable: false },
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ background: "#007FFF", color: "white", fontSize: "120%" }} >
                            <TableSortLabel active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    useEffect(() => {
        if (ReportsList.length <= 0) {
            getReportsAsync();
        }
    }, [ReportsList]);

    async function getReportsAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            const response = await useFetch<IReportsList[]>("ReportGetByUser?userid=" + UserName).then((reportList) => {
                setReportsList(reportList.data);
            });
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <CssBaseline />
                <Grid container className={classes.grid} alignItems="center" spacing={1}>
                    <Grid container className={classes.grid} spacing={1}>
                        <Grid item xs={6} sm={2} >
                            <Box component="span" m={1} //margin
                                className={`${classes.box}`} >
                                <Typography variant="h4" gutterBottom style={{ color: "blue" }}>
                                    <b> REPORTS LIST </b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={9}>
                            <Box component="span" m={2} //margin
                                style={{ float: 'right', marginRight: '-2.3cm', marginTop: '-0.1cm' }} >
                                <CreateReport getReports={getReportsAsync} reportId="-1" states={States} statuses={Statuses} phases={Phases} clients={Clients} />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={1}>
                            <Box component="span" m={2} //margin
                                className={`${classes.box1}`} >
                                <VideoPlayer video={video} title={title} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} component={Paper} >
                        <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table stickyHeader size="small" aria-label="customized table" >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(ReportsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                                        (row) => {
                                            return (
                                                <ExpandableRow onClick={handleRowClick} onDelete={getReportsAsync} selected={selected === row.id} key={row.id}
                                                    onCreate={getReportsAsync} row={row} clients={Clients} states={States} statuses={Statuses} phases={Phases}
                                                    isValid={isValid} operators={OperatorsList} fieldLists={fieldsList} />
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            id="Report_TablePagination"
                            rowsPerPageOptions={[10, 15, 25]}
                            component="div"
                            count={ReportsList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <Footer />
            </div>
        </React.Fragment >
    )
}

export default Reports
